import React from "react";
import PageLayout from "../../shared/PageLayout";
import CaseStatusLayout from "./CaseStatusLayout";

const CaseStatus: React.FC = () => {
    return (
        <PageLayout>
            <CaseStatusLayout />
        </PageLayout>
    );
};

export default CaseStatus;
