import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import KatakomButton from "../../../../shared/KatakomButton";
import KatakomTable from "../../../../shared/KatakomTable";
import KatakomModal from "../../../../shared/KatakomModal";
import AssetModalComponent from "./AssetModalComponent";
import { useState } from "react";
import { deleteNepokretnost } from "../../../../../store/CaseReducer";
import { Container, Row } from "../../../style";

type Props = {
    setCurrentStep: () => void;
};

const AddAssets: React.FC<Props> = ({ setCurrentStep }) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const dispatch: AppDispatch = useAppDispatch();
    const nepokretnostKN = useSelector((state: RootState) => state.case.nepokretnostKN);
    const [editingNepokretnost, setEditingNepokretnost] = useState<any>(null);

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const handleEditNepokretnost = (nepokretnost: any) => {
        setEditingNepokretnost(nepokretnost);
        setModalIsOpen(true);
    };

    const handleDeleteNepokretnost = (nepokretnost: any) => {
        dispatch(deleteNepokretnost(nepokretnost.id));
    };

    const columns = [
        { label: "Ид", value: "id" },
        { label: "Врста непокретности", value: "tip" },
        { label: "Катастарска општина", value: "koNaziv" },
        { label: "Број парцеле", value: "brojParcele" },
        { label: "Подброј парцеле", value: "podBrojParcele" },
        { label: "Број објекта", value: "brojObjekta" },
        { label: "Број посебног дела објекта", value: "evidencijskiBroj" },
        { label: "", value: "edit" },
        { label: "", value: "delete" },
    ];

    return (
        <Container>
            <KatakomModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
                <AssetModalComponent
                    closeModal={handleCloseModal}
                    nepokretnostId={editingNepokretnost ? editingNepokretnost.id : undefined}
                    setEditingNepokretnost={setEditingNepokretnost}
                    modalState={modalIsOpen}
                />
            </KatakomModal>
            {nepokretnostKN.length !== 0 ? (
                <div style={{ margin: "15px" }}>
                    <KatakomTable
                        columns={columns}
                        data={nepokretnostKN}
                        onEdit={handleEditNepokretnost}
                        onDelete={handleDeleteNepokretnost}
                    />
                </div>
            ) : null}

            <Row style={{ display: "flex", justifyContent: "center" }}>
                <KatakomButton
                    label={"Додај непокретност"}
                    size="l"
                    style={{ marginTop: "30px" }}
                    onClick={() => {
                        setModalIsOpen(true);
                    }}
                ></KatakomButton>
            </Row>
        </Container>
    );
};

export default AddAssets;
