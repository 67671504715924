import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../store";
import { Container, Row } from "../../style";
import { useState } from "react";
import { resetState, updateBasicInfo } from "../../../../store/CaseAdvokatiReducer";
import { vrstaZahtevaAdvokatiOptions } from "../../../../register/register";
import AdvokatiCustomSelect from "../../../customComponent/AdvokatiCustomSelect";

import useMobileCheck from "../../../../hooks/useMobileChecker";

const AddBasicInfoAdvokati = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const basicInfo = useSelector((state: RootState) => state.caseAdvokati.basicInfo);
    const isMobile = useMobileCheck();

    const [selectedOption, setSelectedOption] = useState(
        vrstaZahtevaAdvokatiOptions.find((item) => item.value === basicInfo?.vrstaIspraveId) || vrstaZahtevaAdvokatiOptions[0]
    );

    return (
        <Container>
            <Row style={{ marginLeft: "20px", marginTop: 0 }}>
                <AdvokatiCustomSelect
                    options={vrstaZahtevaAdvokatiOptions}
                    value={selectedOption.value}
                    name={"vrstaZahteva"}
                    error={false}
                    theme="advokati"
                    size="xl"
                    onChange={(e) => {
                        //@ts-ignore
                        setSelectedOption(e);
                        dispatch(resetState());
                        dispatch(updateBasicInfo({ vrstaIspraveId: e.value }));
                    }}
                />
            </Row>
        </Container>
    );
};

export default AddBasicInfoAdvokati;
