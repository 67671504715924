import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useNavigate } from "react-router-dom";
import { useSidebar } from "../../widgets/sidebar/SidebarContext";
import { ButtonGroup, Card, CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper } from "../style";
import ProgressBar from "../../widgets/progressbar/ProgressBar";
import KatakomButton from "../../shared/KatakomButton";
import { checkStepValidationAdvokati } from "../../../utils/checkStepValidation";
import AddBasicInfoAdvokati from "./caseSteps/AddBasicInfo";
import AddAplicantsAdvokati from "./caseSteps/addAplicants/AddAplicantsAdvokati";
import AddDocumentsAdvokati from "./caseSteps/addDocument/AddDocuments";
import AddAssetsAdvokati from "./caseSteps/addAssets/AddAssetsAdvokati";
import { pdf } from "@react-pdf/renderer";
import GeneratePDFAdvokati from "../../pdfs/AdvokatiPDF";
import moment from "moment";
import KatakomModal from "../../shared/KatakomModal";
import { SuccessMessageModal } from "../../customComponent/SuccessMessageModal";
import { ErrorMessageModal } from "../../customComponent/ErrorMessageModal";

import useMobileCheck from "../../../hooks/useMobileChecker";

const NewCaseAdvokatiLayout: React.FC = () => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const steps = ["врста исправе", "непокретности", "странке", "документа"];
    const slucaj = useSelector((state: RootState) => state.caseAdvokati);
    const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
    const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
    const isMobile = useMobileCheck();

    let navigate = useNavigate();
    const { sidebar } = useSidebar();
    const { disabled, tooltipMessage } = checkStepValidationAdvokati(currentStep, slucaj);

    const routeChange = (path: string) => {
        navigate(path);
    };

    const incrementStep = () => {
        if (currentStep < steps.length) {
            setCurrentStep(currentStep + 1);
        }
        if (currentStep === steps.length) {
            routeChange("/noviPredmetAdvokati/pregled");
        }
        if (currentStep === 3) {
            downloadPdf();
        }
    };

    const decrementStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleStepClick = (stepIndex: number) => {
        setCurrentStep(stepIndex);
    };

    const componentToRender = () => {
        switch (currentStep) {
            case 1:
                return <AddBasicInfoAdvokati />;
            case 2:
                return <AddAssetsAdvokati />;
            case 3:
                return <AddAplicantsAdvokati />;
            case 4:
                return <AddDocumentsAdvokati />;
            default:
                return <AddBasicInfoAdvokati />;
        }
    };

    const downloadPdf = async () => {
        try {
            const blob = await pdf(<GeneratePDFAdvokati pdfData={slucaj} />).toBlob();
            // Create a URL for the blob
            const url = URL.createObjectURL(blob);

            const fileName = slucaj.ucesnici?.[0]?.ime
                ? slucaj.ucesnici?.[0]?.ime + " " + slucaj.ucesnici?.[0]?.prezime
                : slucaj.ucesnici?.[0]?.imePravnogLica;

            const a = document.createElement("a");
            a.href = url;
            if (slucaj.basicInfo.vrstaIspraveId === "6105") {
                a.download = `${moment().format("DD_MM_YYYY")}`;
            } else {
                a.download = `${fileName}_${moment().format("DD_MM_YYYY")}`;
            }
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            setSuccessModalOpen(true);
        } catch (e) {
            setErrorModalOpen(true);
        }
    };

    return (
        <LayoutWraper sidebarOpen={sidebar} tabIndex={50}>
            <CardWrapper>
                <KatakomModal isOpen={successModalOpen} setIsOpen={setSuccessModalOpen}>
                    <SuccessMessageModal
                        closeModal={() => {
                            setSuccessModalOpen(false);
                        }}
                        message="Документ успешно преузет!"
                    />
                </KatakomModal>

                <KatakomModal isOpen={errorModalOpen} setIsOpen={setErrorModalOpen}>
                    <ErrorMessageModal
                        closeModal={() => {
                            setErrorModalOpen(false);
                        }}
                        message="Дошло је до грешке приликом преузимања документа!"
                    />
                </KatakomModal>
                <Card>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>НОВИ ПРЕДМЕТ</CardTitle>
                        </CardHeader>
                        {isMobile ? null : (
                            <>
                                <ProgressBar
                                    steps={steps}
                                    currentStep={currentStep}
                                    onStepClick={handleStepClick}
                                    validateStep={checkStepValidationAdvokati}
                                    slucaj={slucaj}
                                />
                            </>
                        )}

                        {componentToRender()}
                        <ButtonGroup>
                            <KatakomButton size="l" label={"Назад"} onClick={decrementStep} />
                            <KatakomButton
                                size="l"
                                label={"Следеће"}
                                onClick={incrementStep}
                                disabled={disabled}
                                tooltipMessage={tooltipMessage}
                            />
                        </ButtonGroup>
                    </CardContainer>
                </Card>
            </CardWrapper>
        </LayoutWraper>
    );
};

export default NewCaseAdvokatiLayout;
