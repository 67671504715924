import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import { useEffect, useState } from "react";
import { deleteUcesnikTip2 } from "../../../../../store/CaseTip2Reducer";
import KatakomButton from "../../../../shared/KatakomButton";
import KatakomTable from "../../../../shared/KatakomTable";
import KatakomModal from "../../../../shared/KatakomModal";
import AplicantsModalComponent from "./AplicantsModalComponentTip2";
import { Container, Row } from "../../../style";

const AddAplicantsTip2 = () => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const dispatch: AppDispatch = useAppDispatch();
    const ucesnici = useSelector((state: RootState) => state.caseTip2.ucesnici);
    const [editingUcesnik, setEditingUcesnik] = useState<any>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (ucesnici.length === 1) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [ucesnici]);

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const handleEditUcesnik = (ucesnik: any) => {
        setEditingUcesnik(ucesnik);
        setModalIsOpen(true);
    };

    const handledeleteUcesnik = (ucesnik: any) => {
        dispatch(deleteUcesnikTip2(ucesnik.id));
    };

    const columns = [
        { label: "Ид", value: "id" },
        { label: "Име", value: "ime/imePravnogLica" },
        { label: "Презиме", value: "prezime" },
        { label: "Врста лица", value: "vrstaLica" },
        { label: "ЈМБГ/Матични број", value: "jmbg/maticniBroj/brojUpisaURegistarDrzaveSedista/ssn" },
        { label: "", value: "edit" },
        { label: "", value: "delete" },
    ];

    return (
        <Container>
            <KatakomModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
                <AplicantsModalComponent
                    closeModal={handleCloseModal}
                    ucesnikId={editingUcesnik ? editingUcesnik.id : undefined}
                    setEditingUcesnik={setEditingUcesnik}
                    modalState={modalIsOpen}
                />
            </KatakomModal>
            {ucesnici.length !== 0 ? (
                <div style={{ margin: "15px" }}>
                    <KatakomTable columns={columns} data={ucesnici} onEdit={handleEditUcesnik} onDelete={handledeleteUcesnik} />
                </div>
            ) : null}

            <Row style={{ display: "flex", justifyContent: "center" }}>
                <KatakomButton
                    label={"Додај странку"}
                    size="l"
                    disabled={disabled}
                    tooltipMessage="Можете додати највише једну странку!"
                    onClick={() => {
                        setModalIsOpen(true);
                    }}
                ></KatakomButton>
            </Row>
        </Container>
    );
};

export default AddAplicantsTip2;
