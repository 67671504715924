import { createSlice } from "@reduxjs/toolkit";

export interface PDFState {
    data: any;
}

const initialState: PDFState = {
    data: {},
};

export const pdfSlice = createSlice({
    name: "pdf",
    initialState,
    reducers: {
        setPdf: (state, action) => {
            state.data = action.payload;
        },
        resetState: () => initialState,
    },
});

export const { resetState, setPdf } = pdfSlice.actions;
export default pdfSlice.reducer;
