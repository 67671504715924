import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { themeColors } from "../../constants/constants";
import { CaseState } from "../../store/CaseReducer";

const styles = StyleSheet.create({
    document: {
        flexDirection: "column",
        backgroundColor: themeColors.pdfBackgroundColor,
        fontFamily: "Montserrat",
        fontWeight: 400,
        lineHeight: 1.5,
    },
    header: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    headerRepublikaSrbija: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 20,
    },

    grbSrbije: {
        width: 120,
        marginRight: "auto",
        marginLeft: 70,
    },

    profesionalniKorisnik: {
        position: "absolute",
        top: "60px",
        right: "100px",
        fontSize: "11px",
        whiteSpace: "nowrap",
    },

    headerSignature: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "60%",
        marginLeft: "40%",
    },
    headerPotpisMinistra: {
        marginTop: 30,
    },
    headerText: {
        fontSize: 12,
        textAlign: "center",
        marginTop: 0,
        fontWeight: 500,
    },
    header2Text: {
        ontSize: 12,
        textAlign: "center",
        marginTop: 20,
        marginBottom: 20,
        fontWeight: 500,
    },
    page: {
        flexDirection: "column",
        fontSize: 12,
        backgroundColor: themeColors.pdfBackgroundColor,
        padding: 40,
    },
    body: {
        display: "flex",
        fontSize: 12,
        flexDirection: "column",
        alignItems: "center",
        marginTop: 30,
    },
    boldTitle: {
        fontWeight: 600,
        fontSize: 16,
        marginTop: 50,
    },
    boldText: {
        fontWeight: 500,
        fontSize: 12,
        marginBottom: 20,
        textAlign: "center",
    },
    boldJustifyText: {
        fontWeight: 500,
        fontSize: 12,
        marginBottom: 20,
        textAlign: "justify",
    },
    boldData: {
        fontWeight: 500,
        fontSize: 12,
    },
    centerText: {
        textAlign: "center",
        fontWeight: 400,
    },
    justifyText: {
        textAlign: "justify",
        fontWeight: 400,
    },
    bodySection: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        textAlign: "left",
        alignItems: "flex-start",
        width: "100%",
    },
    leftText: {
        textAlign: "left",
        fontWeight: 400,
        minWidth: "430px",
        maxWidth: "430px",
    },
    boldLeftText: {
        fontWeight: 500,
        fontSize: 12,
        marginBottom: 20,
        textAlign: "left",
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    atributeName: {
        textAlign: "left",
        fontWeight: 400,
        minWidth: "140px",
        maxWidth: "140px",
    },
    section: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "12px",
    },
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Regular.ttf`,
    fontWeight: 400,
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Medium.ttf`,
    fontWeight: 500,
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-SemiBold.ttf`,
    fontWeight: 600,
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Bold.ttf`,
    fontWeight: 700,
});

interface GeneratePDFIzvrsiteljProps {
    pdfData: CaseState;
}

const GeneratePDFIzvrsitelj: React.FC<GeneratePDFIzvrsiteljProps> = ({ pdfData }) => {
    return (
        <Document style={styles.document}>
            <Page style={styles.page}>
                <View style={styles.headerRepublikaSrbija}>
                    <View style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Text style={styles.headerText}>
                            Република Србија
                            {"\n"}
                            РЕПУБЛИЧКИ ГЕОДЕТСКИ ЗАВОД{"\n"}
                            СЛУЖБА ЗА КАТАСТАР НЕПОКРЕТНОСТИ_{pdfData.nepokretnostKN[0].koNaziv}
                            {"\n"}
                        </Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default GeneratePDFIzvrsitelj;
