import React from "react";
import PageLayout from "../../../shared/PageLayout";
import PretragaPoListuNepokretnostiLayout from "./PretragaPoListuNepokretnostiLayout";

const PretragaPoListuNepokretnosti: React.FC = () => {
    return (
        <PageLayout>
            <PretragaPoListuNepokretnostiLayout />
        </PageLayout>
    );
};

export default PretragaPoListuNepokretnosti;
