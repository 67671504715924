import { ChangeEvent } from "react";
import { svojstvoLicaOption } from "../../../../../../register/register";
import { Aplicant } from "../../../../../../store/CaseReducer";
import KatakomInput from "../../../../../shared/KatakomInput";
import KatakomSelect, { SelectOption } from "../../../../../shared/KatakomSelect";
import { Row } from "../../../../style";

export const FizickoStranac = ({ formData, setFormData }: { formData: Aplicant; setFormData: (aplicant: Aplicant) => void }) => {
    return (
        <>
            <Row>
                <KatakomSelect
                    options={svojstvoLicaOption}
                    value={formData.svojstvoLica || ""}
                    name="svojstvoLica"
                    label="Својство лица*"
                    size="s"
                    error={false}
                    onChange={(option: SelectOption) => setFormData({ ...formData, svojstvoLica: String(option.value) })}
                    style={{ margin: "0 30px 0 0 " }}
                />

                <KatakomInput
                    type="text"
                    value={formData.ime || ""}
                    name="ime"
                    label="Име*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, ime: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.prezime || ""}
                    name="prezime"
                    label="Презиме*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, prezime: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.imeRoditelja || ""}
                    name="imeRoditelja"
                    label="Име родитеља*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, imeRoditelja: e.target.value })}
                />
            </Row>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.brojPasosa || ""}
                    name="brojPasosa"
                    label="Број пасоша*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, brojPasosa: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.ssn || ""}
                    name="ssn"
                    label="SSN(Social Security Number)"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, ssn: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.drzavaNaziv || ""}
                    name="drzavaNaziv"
                    label="Држава*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, drzavaNaziv: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.mestoNaziv || ""}
                    name="mestoNaziv"
                    label="Место*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, mestoNaziv: e.target.value })}
                />
            </Row>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.ulicaRucno || ""}
                    name="ulicaRucno"
                    label="Улица*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, ulicaRucno: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.kucniBroj || ""}
                    name="kucniBroj"
                    label="Кућни број*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, kucniBroj: e.target.value })}
                />
            </Row>
        </>
    );
};
