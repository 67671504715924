import React from "react";
import { TabSwitcher } from "../../../shared/KatakomTabs";
import { CardWrapper } from "../../style";
import { Parcela } from "./Parcela";
import { Objekat } from "./Objekat";
import { DeoParcele } from "./DeoParcele";
import styled from "styled-components";
import { setParcela } from "../../../../store/KatastarReducer";
import { AppDispatch, useAppDispatch } from "../../../../store";

interface RenderKatastarDataProps {
    data: any;
    nelegalni: any;
    redirectToRGZ: () => void;
    redirectToDRR: () => void;
    redirectToNelegalni: () => void;
    activeTab: number;
    setActiveTab: (number: number) => void;
    koid?: any;
    brOpstine?: any;
    brojParcele?: any;
    podbrojParcele?: any;
}

export const RenderKatastarData: React.FC<RenderKatastarDataProps> = ({
    data,
    nelegalni,
    redirectToRGZ,
    redirectToDRR,
    redirectToNelegalni,
    activeTab,
    setActiveTab,
    koid,
    brOpstine,
    brojParcele,
    podbrojParcele,
}) => {
    const dispatch: AppDispatch = useAppDispatch();

    if (Object.keys(data.Parcele).length !== 0) {
        dispatch(setParcela(data));

        return (
            <CardWrapper>
                <TabSwitcher tabs={["ПАРЦЕЛА", "ДЕО ПАРЦЕЛЕ", "ОБЈЕКАТ"]} activeTab={activeTab} setActiveTab={setActiveTab}>
                    <Parcela
                        data={data}
                        nelegalni={nelegalni}
                        redirectToRGZ={redirectToRGZ}
                        redirectToDRR={redirectToDRR}
                        redirectToNelegalni={redirectToNelegalni}
                        setActiveTab={setActiveTab}
                        koid={koid}
                        brOpstine={brOpstine}
                        brojParcele={brojParcele}
                        podbrojParcele={podbrojParcele}
                    />
                    <DeoParcele data={data} redirectToRGZ={redirectToRGZ} redirectToDRR={redirectToDRR} setActiveTab={setActiveTab} />
                    <Objekat data={data} redirectToRGZ={redirectToRGZ} redirectToDRR={redirectToDRR} setActiveTab={setActiveTab} />
                </TabSwitcher>
            </CardWrapper>
        );
    } else {
        return <DataNotFound>*Нема података за унету вредност</DataNotFound>;
    }
};

const DataNotFound = styled.div`
    display: flex;
    margin: 20px 50px 0px 30px;
    font-size: 15px;
    font-weight: 600;
`;
