export const getSize = (size?: string) => {
    switch (size) {
        case "xs":
            return "120px";
        case "s":
            return "170px";
        case "m":
            return "240px";
        case "l":
            return "300px";
        case "xl":
            return "400px";
        case "fullSize":
            return "860px";

        default:
            return "240px"; // Default width if size is not provided or invalid
    }
};
