export const INTERNAL_SERVER_ERROR = {
    statusCode: 500,
    message: "Internal server error",
    code: "10.5",
};
export const BAD_REQUEST = {
    statusCode: 400,
    message: "Bad request.",
    code: "10.4",
};
export const FORBIDDEN = {
    statusCode: 403,
    message: "Forbidden.",
    code: "10.4.3",
};
export const NOT_FOUND = {
    statusCode: 404,
    message: "Resource not found.",
    code: "10.4.4",
};
export const ERROR_CALLING_SOAP_CLIENT = {
    statusCode: 500,
    message: "Error calling soap client",
    code: "10.500",
};
export const INVALID_USER_NAME_OR_PASSWORD = {
    statusCode: 401,
    message: "Invalid username or password",
    code: "10.401",
};
export const USER_NOT_FOUND = {
    statusCode: 404,
    message: "User not found",
    code: "10.404",
};
export const USER_NOT_ACTIVE = {
    statusCode: 403,
    message: "User not active",
    code: "10.403",
};
