import React, { createContext, useContext, useEffect, useState } from "react";

import useMobileCheck from "../../../hooks/useMobileChecker";

interface SidebarContextProps {
    sidebar: boolean;
    showSidebar: () => void;
}

const SidebarContext = createContext<SidebarContextProps>({
    sidebar: true,
    showSidebar: () => {},
});

export const SidebarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [sidebar, setSidebar] = useState(true);
    const isMobile = useMobileCheck();

    //This is hacky solution for hiding sidebar on first render so it scroll's with transition
    useEffect(() => {
        if (
            window.location.hash !== "#/pocetna" &&
            window.location.hash !== "#/podesavanja" &&
            window.location.hash !== "#/podesavanja/podesavanjaNaloga"
        ) {
            setSidebar(false);
        }

        if (localStorage.getItem("sidebarAutoCollaps") === "0" ? true : false) {
            setSidebar(true);
        }

        if (isMobile) {
            setSidebar(false);
        }
    }, []);

    const showSidebar = () => setSidebar(!sidebar);

    return <SidebarContext.Provider value={{ sidebar, showSidebar }}>{children}</SidebarContext.Provider>;
};

export const useSidebar = () => useContext(SidebarContext);
