import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { themeColors } from "../../../../../constants/constants";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import { addDocument, resetState } from "../../../../../store/CaseReducer";
import KatakomButton from "../../../../shared/KatakomButton";
import { useSidebar } from "../../../../widgets/sidebar/SidebarContext";
import { ButtonGroup, Card, CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper } from "../../../style";
import { RenderDokumenta } from "./RenderDokumenta";
import { RenderNepokretnosti } from "./RenderNepokretnosti";
import { RenderOsnovniPodaci } from "./RenderOsnovniPodaci";
import { RenderPodnosioci } from "./RenderPodnosioci";
import api from "../../../../../services/api";

const PreviewLayout: React.FC = () => {
    const slucaj = useSelector((state: RootState) => state.case);
    const tempDokumenta = useSelector((state: RootState) => state.case.tempDokumenta);

    const dispatch: AppDispatch = useAppDispatch();

    let navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    };

    const submitFiles = async () => {
        const documents = [];
        for (const dok of tempDokumenta) {
            const response = await api.post(`api/v1/posalji-fajl`, {
                base64Document: dok.file,
            });
            documents.push({ id_prijem_fajl_esalter: response?.data.id_prijem_fajl_esalter });
        }

        dispatch(addDocument(documents));
    };

    useEffect(() => {
        if (slucaj.dokumenta.length > 0) {
            handleFinish();
        }
    }, [slucaj.dokumenta]);

    const handleFinish = async () => {
        const slucajWithoutId = {
            ...slucaj,
        };
        slucajWithoutId.ucesnici = slucajWithoutId.ucesnici.map(createNewObjectWithoutId);
        slucajWithoutId.nepokretnostKN = slucajWithoutId.nepokretnostKN.map(createNewObjectWithoutId);
        slucajWithoutId.tempDokumenta = slucajWithoutId.tempDokumenta.map(createNewObjectWithoutId);

        await api.post(`api/v1/posalji-podatke`, {
            ...slucajWithoutId.basicInfo,
            upisnik: slucajWithoutId.basicInfo.upisnik.value,
            maticniBrojSuda: slucajWithoutId.basicInfo.maticniBrojSuda.value,
            vrstaIspraveId: slucajWithoutId.basicInfo.vrstaIspraveId.value,
            ucesnici: slucajWithoutId.ucesnici,
            nepokretnostKN: slucajWithoutId.nepokretnostKN,
            dokumenta: slucajWithoutId.dokumenta,
        });

        routeChange("/pocetna");
        dispatch(resetState());
    };

    const { sidebar } = useSidebar();

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <Card style={{ minHeight: "10vh" }}>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>ПРЕГЛЕД</CardTitle>
                        </CardHeader>
                        <PreviewTitle>oсновни подаци</PreviewTitle>
                        <RenderOsnovniPodaci />
                    </CardContainer>
                </Card>
            </CardWrapper>

            <CardWrapper>
                <Card style={{ minHeight: "10vh" }}>
                    <CardContainer>
                        <PreviewTitle>непокретности</PreviewTitle>
                        <RenderNepokretnosti />
                    </CardContainer>
                </Card>
            </CardWrapper>

            <CardWrapper>
                <Card style={{ minHeight: "10vh" }}>
                    <CardContainer>
                        <PreviewTitle>странке</PreviewTitle>
                        <RenderPodnosioci />
                    </CardContainer>
                </Card>
            </CardWrapper>

            <CardWrapper>
                <Card style={{ minHeight: "10vh" }}>
                    <CardContainer>
                        <PreviewTitle>документа</PreviewTitle>
                        <RenderDokumenta />
                    </CardContainer>
                </Card>
            </CardWrapper>

            <ButtonGroup>
                <KatakomButton
                    size="l"
                    label={"Назад"}
                    onClick={() => {
                        routeChange("/noviPredmet");
                    }}
                />
                <KatakomButton size="l" label={"Пошаљи"} onClick={submitFiles} />
            </ButtonGroup>
        </LayoutWraper>
    );
};

function createNewObjectWithoutId(obj: any) {
    const newObj = { ...obj };
    delete newObj.id;
    return newObj;
}

export default PreviewLayout;

const PreviewTitle = styled.div`
    display: flex;
    font-size: 20px;
    font-weight: 500;
    padding: 0 0 0 30px;
    align-items: center;
    background-color: ${themeColors.previewTitleBackgroundColor};
    color: ${themeColors.previewTitleColor};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: calc(100% -30px);
    height: 36px;
`;
