import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useNavigate } from "react-router-dom";
import { useSidebar } from "../../widgets/sidebar/SidebarContext";
import { ButtonGroup, Card, CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper } from "../style";
import ProgressBar from "../../widgets/progressbar/ProgressBar";
import KatakomButton from "../../shared/KatakomButton";
import { checkStepValidationTip2 } from "../../../utils/checkStepValidation";
import AddAplicantsTip2 from "./caseSteps/addAplicants/AddAplicantsTip2";
import AddAssetsTip2 from "./caseSteps/addAssets/AddAssetsTip2";
import AddDocumentsTip2 from "./caseSteps/addDocument/AddDocuments";
import GeneratePDFTip2 from "../../pdfs/Tip2PDF";
import { pdf } from "@react-pdf/renderer";
import AddBasicInfoTip2 from "./caseSteps/AddBasicInfo";

import useMobileCheck from "../../../hooks/useMobileChecker";

const NewCaseTip2Layout: React.FC = () => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const steps = ["основни подаци", "непокретности", "странке", "документа"];
    const slucaj = useSelector((state: RootState) => state.caseTip2);
    const isMobile = useMobileCheck();

    let navigate = useNavigate();
    const { sidebar } = useSidebar();
    const { disabled, tooltipMessage } = checkStepValidationTip2(currentStep, slucaj);

    const routeChange = (path: string) => {
        navigate(path);
    };

    const incrementStep = () => {
        if (currentStep < steps.length) {
            setCurrentStep(currentStep + 1);
        }
        if (currentStep === steps.length) {
            routeChange("/noviPredmetKonverzija/pregled");
        }
        // if (currentStep === 2) {
        //     downloadPdf();
        // }
    };

    const decrementStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleStepClick = (stepIndex: number) => {
        setCurrentStep(stepIndex);
    };

    const downloadPdf = async () => {
        const blob = await pdf(<GeneratePDFTip2 pdfData={slucaj} />).toBlob();
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "your_document.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };
    const componentToRender = () => {
        switch (currentStep) {
            case 1:
                return <AddBasicInfoTip2 />;
            case 2:
                return <AddAssetsTip2 />;
            case 3:
                return <AddAplicantsTip2 />;
            case 4:
                return <AddDocumentsTip2 />;
            default:
                return <AddBasicInfoTip2 />;
        }
    };

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <Card>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>НОВИ ПРЕДМЕТ/КОНВЕРЗИЈА</CardTitle>
                        </CardHeader>
                        {isMobile ? null : (
                            <>
                                <ProgressBar
                                    steps={steps}
                                    currentStep={currentStep}
                                    onStepClick={handleStepClick}
                                    validateStep={checkStepValidationTip2}
                                    slucaj={slucaj}
                                />
                            </>
                        )}

                        {componentToRender()}
                        <ButtonGroup>
                            <KatakomButton size="l" label={"Назад"} onClick={decrementStep} />
                            <KatakomButton
                                size="l"
                                label={"Следеће"}
                                onClick={incrementStep}
                                disabled={disabled}
                                tooltipMessage={tooltipMessage}
                            />
                        </ButtonGroup>
                    </CardContainer>
                </Card>
            </CardWrapper>
        </LayoutWraper>
    );
};

export default NewCaseTip2Layout;
