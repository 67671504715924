import { SelectOption } from "../components/shared/KatakomSelect";

//Uverenje o imovinskom stanju

export const sluzbeOptions: SelectOption[] = [
    { label: "ШАБАЦ", value: "1" },
    { label: "БОГАТИЋ", value: "2" },
    { label: "КОЦЕЉЕВА", value: "3" },
    { label: "КРУПАЊ", value: "4" },
    { label: "ЛАЈКОВАЦ", value: "5" },
    { label: "ЛОЗНИЦА", value: "6" },
    { label: "БЕОГРАД-БАРАЈЕВО", value: "7" },
    { label: "МИОНИЦА", value: "8" },
    { label: "ОСЕЧИНА", value: "9" },
    { label: "ЉИГ", value: "10" },
    { label: "ЉУБОВИЈА", value: "11" },
    { label: "УБ", value: "12" },
    { label: "ВАЉЕВО", value: "13" },
    { label: "ВЛАДИМИРЦИ", value: "14" },
    { label: "БЕОГРАД-ПАЛИЛУЛА", value: "15" },
    { label: "БЕОГРАД-ЗЕМУН", value: "16" },
    { label: "ЖАБАРИ", value: "17" },
    { label: "ЖАГУБИЦА", value: "18" },
    { label: "ЋУПРИЈА", value: "19" },
    { label: "АРАНЂЕЛОВАЦ", value: "20" },
    { label: "ДЕСПОТОВАЦ", value: "21" },
    { label: "БЕОГРАД-СОПОТ", value: "22" },
    { label: "ЈАГОДИНА", value: "23" },
    { label: "КНИЋ", value: "24" },
    { label: "КРАГУЈЕВАЦ", value: "25" },
    { label: "БАТОЧИНА", value: "26" },
    { label: "КУЧЕВО", value: "27" },
    { label: "ПАРАЋИН", value: "28" },
    { label: "ПЕТРОВАЦ", value: "29" },
    { label: "ПОЖАРЕВАЦ", value: "30" },
    { label: "РАЧА", value: "31" },
    { label: "РЕКОВАЦ", value: "32" },
    { label: "СМЕДЕРЕВО", value: "33" },
    { label: "СМЕДЕРЕВСКА ПАЛАНКА", value: "34" },
    { label: "СВИЛАЈНАЦ", value: "35" },
    { label: "ТОПОЛА", value: "36" },
    { label: "ВЕЛИКА ПЛАНА", value: "37" },
    { label: "ВЕЛИКО ГРАДИШТЕ", value: "38" },
    { label: "ЖИТОРАЂА", value: "39" },
    { label: "ЋИЋЕВАЦ", value: "40" },
    { label: "АЛЕКСАНДРОВАЦ", value: "41" },
    { label: "БЛАЦЕ", value: "42" },
    { label: "БРУС", value: "43" },
    { label: "КРАЉЕВО", value: "44" },
    { label: "КРУШЕВАЦ", value: "45" },
    { label: "КУРШУМЛИЈА", value: "46" },
    { label: "НОВИ ПАЗАР", value: "47" },
    { label: "ПРОКУПЉЕ", value: "48" },
    { label: "РАЖАЊ", value: "49" },
    { label: "РАШКА", value: "50" },
    { label: "ТРСТЕНИК", value: "51" },
    { label: "ТУТИН", value: "52" },
    { label: "ВАРВАРИН", value: "53" },
    { label: "ВРЊАЧКА БАЊА", value: "54" },
    { label: "АЛЕКСИНАЦ", value: "55" },
    { label: "БАБУШНИЦА", value: "56" },
    { label: "БЕЛА ПАЛАНКА", value: "57" },
    { label: "БОЈНИК", value: "58" },
    { label: "БОСИЛЕГРАД", value: "59" },
    { label: "БУЈАНОВАЦ", value: "60" },
    { label: "ДИМИТРОВГРАД", value: "61" },
    { label: "ДОЉЕВАЦ", value: "62" },
    { label: "ГАЏИН ХАН", value: "63" },
    { label: "ЛЕБАНЕ", value: "64" },
    { label: "ЛЕСКОВАЦ", value: "65" },
    { label: "МЕДВЕЂА", value: "66" },
    { label: "МЕРОШИНА", value: "67" },
    { label: "НИШ", value: "68" },
    { label: "ПИРОТ", value: "69" },
    { label: "ПРЕШЕВО", value: "70" },
    { label: "СУРДУЛИЦА", value: "71" },
    { label: "СВРЉИГ", value: "72" },
    { label: "БЕОГРАД-ГРОЦКА", value: "73" },
    { label: "ВЛАДИЧИН ХАН", value: "74" },
    { label: "ВЛАСОТИНЦЕ", value: "75" },
    { label: "ВРАЊЕ", value: "76" },
    { label: "ЖАБАЉ", value: "77" },
    { label: "ШИД", value: "78" },
    { label: "АПАТИН", value: "79" },
    { label: "БЕОГРАД-ЛАЗАРЕВАЦ", value: "80" },
    { label: "БАЧКА ПАЛАНКА", value: "81" },
    { label: "БАЧКА ТОПОЛА", value: "82" },
    { label: "БАЧКИ ПЕТРОВАЦ", value: "83" },
    { label: "БЕЧЕЈ", value: "84" },
    { label: "БЕОГРАД-МЛАДЕНОВАЦ", value: "85" },
    { label: "ИНЂИЈА", value: "86" },
    { label: "БЕОГРАД-ОБРЕНОВАЦ", value: "87" },
    { label: "КУЛА", value: "88" },
    { label: "СОКО БАЊА", value: "89" },
    { label: "НОВИ САД 1", value: "90" },
    { label: "ЗАЈЕЧАР", value: "91" },
    { label: "ОЏАЦИ", value: "92" },
    { label: "ПЕЋИНЦИ", value: "93" },
    { label: "РУМА", value: "94" },
    { label: "СОМБОР", value: "95" },
    { label: "ШТРПЦЕ", value: "96" },
    { label: "СРЕМСКА МИТРОВИЦА", value: "97" },
    { label: "СТАРА ПАЗОВА", value: "98" },
    { label: "СУБОТИЦА", value: "99" },
    { label: "ТЕМЕРИН", value: "100" },
    { label: "ВРБАС", value: "102" },
    { label: "ЖИТИШТЕ", value: "103" },
    { label: "НОВИ КНЕЖЕВАЦ", value: "104" },
    { label: "АЛИБУНАР", value: "105" },
    { label: "КАЊИЖА", value: "106" },
    { label: "КИКИНДА", value: "107" },
    { label: "КОВИН", value: "108" },
    { label: "БЕЛА ЦРКВА", value: "109" },
    { label: "НОВИ БЕЧЕЈ", value: "110" },
    { label: "ПАНЧЕВО", value: "111" },
    { label: "КОВАЧИЦА", value: "112" },
    { label: "СЕЧАЊ", value: "113" },
    { label: "СЕНТА", value: "114" },
    { label: "ВРШАЦ", value: "115" },
    { label: "ЗРЕЊЂН", value: "116" },
    { label: "КОСОВСКА КАМЕНИЦА", value: "121" },
    { label: "КОСОВСКА МИТРОВИЦА", value: "122" },
    { label: "ЛЕПОСАВИЋ", value: "123" },
    { label: "ПРИШТИНА", value: "128" },
    { label: "ЗУБИН ПОТОК", value: "135" },
    { label: "ЧАЧАК", value: "136" },
    { label: "ЧАЈЕТИНА", value: "137" },
    { label: "АРИЉЕ", value: "138" },
    { label: "БАЈИНА БАШТА", value: "139" },
    { label: "ГОРЊИ МИЛАНОВАЦ", value: "140" },
    { label: "ГУЧА-ЛУЧАНИ", value: "141" },
    { label: "ИВАЊИЦА", value: "142" },
    { label: "КОСЈЕРИЋ", value: "143" },
    { label: "НОВА ВАРОШ", value: "144" },
    { label: "ПОЖЕГА", value: "145" },
    { label: "ПРИБОЈ", value: "146" },
    { label: "ПРИЈЕПОЉЕ", value: "147" },
    { label: "СЈЕНИЦА", value: "148" },
    { label: "УЖИЦЕ", value: "149" },
    { label: "БОЉЕВАЦ", value: "150" },
    { label: "БОР", value: "151" },
    { label: "КЛАДОВО", value: "152" },
    { label: "КЊАЖЕВАЦ", value: "153" },
    { label: "МАЈДАНПЕК", value: "154" },
    { label: "НЕГОТИН", value: "155" },
    { label: "МАЛИ ЗВОРНИК", value: "200" },
    { label: "ГОЛУБАЦ", value: "201" },
    { label: "ЛАПОВО", value: "202" },
    { label: "МАЛО ЦРНИЋЕ", value: "203" },
    { label: "ТРГОВИШТЕ", value: "204" },
    { label: "ЦРНА ТРАВА", value: "205" },
    { label: "БАЧ", value: "206" },
    { label: "БЕОЧИН", value: "207" },
    { label: "ИРИГ", value: "208" },
    { label: "МАЛИ ИЂОШ", value: "209" },
    { label: "СРБОБРАН", value: "210" },
    { label: "ТИТЕЛ", value: "211" },
    { label: "СРЕМСКИ КАРЛОВЦИ", value: "212" },
    { label: "ЧОКА", value: "213" },
    { label: "НОВА ЦРЊА", value: "214" },
    { label: "ОПОВО", value: "215" },
    { label: "ПЛАНДИШТЕ", value: "216" },
    { label: "АДА", value: "217" },
    { label: "БЕОГРАД-СУРЧИН", value: "223" },
    { label: "БЕОГРАД-ЧУКАРИЦА", value: "224" },
    { label: "БЕОГРАД-НОВИ БЕОГРАД", value: "225" },
    { label: "НОВИ САД 2", value: "226" },
    { label: "ДОЊИ МИЛАНОВАЦ", value: "227" },
    { label: "БЕОГРАД-ВРАЧАР", value: "228" },
    { label: "БЕОГРАД-САВСКИ ВЕНАЦ", value: "229" },
    { label: "БЕОГРАД-ЗВЕЗДАРА", value: "230" },
    { label: "БЕОГРАД-ВОЖДОВАЦ", value: "231" },
    { label: "БЕОГРАД-РАКОВИЦА", value: "232" },
    { label: "БЕОГРАД-СТАРИ ГРАД", value: "233" },
    { label: "НОВИ САД 3", value: "234" },
    { label: "ОДЕЉЕЊЕ АРХИВА", value: "300" },
];

export const mandatoryDocuments = {
    "6031": { docs: ["405", "27", "401"], groupType: "1" },
    "6032": { docs: ["405", "27", "401"], groupType: "1" },
    "6033": { docs: ["405", "27", "401"], groupType: "1" },
    "6016": { docs: ["405", "27", "401"], groupType: "1" },
    "6021": { docs: ["405", "27", "401"], groupType: "1" },
    "6022": { docs: ["405", "27", "401"], groupType: "1" },
    "6023": { docs: ["405", "27", "401"], groupType: "1" },
    "6024": { docs: ["405", "27", "401"], groupType: "1" },
    "6025": { docs: ["405", "27", "601"], groupType: "1" },
    "6026": { docs: ["405", "27", "401"], groupType: "1" },
    "6027": { docs: ["405", "27", "401"], groupType: "1" },
    "6028": { docs: ["405", "27", "401"], groupType: "1" },
    "6029": { docs: ["405", "27", "401"], groupType: "1" },
    "6030": { docs: ["405", "27", "401"], groupType: "1" },
    "6003": { docs: ["405", "27", "401"], groupType: "1" },
    "6004": { docs: ["405", "27", "401"], groupType: "1" },
    "6005": { docs: ["405", "27", "401"], groupType: "1" },
    "6006": { docs: ["405", "27", "401"], groupType: "1" },
    "6007": { docs: ["405", "27", "401"], groupType: "1" },
    "6017": { docs: ["405", "27", "401"], groupType: "1" },
    "6008": { docs: ["405", "27", "401"], groupType: "1" },
    "6009": { docs: ["405", "27", "401"], groupType: "1" },
    "6018": { docs: ["405", "27", "401"], groupType: "1" },
    "6012": { docs: ["405", "27", "401"], groupType: "1" },
    "6013": { docs: ["405", "27", "401"], groupType: "1" },
    "6011": { docs: ["405", "27", "401"], groupType: "1" },
    "6101": { docs: [], groupType: "2" },
    "6106": { docs: ["27", "404"], groupType: "2" },
    "6107": { docs: ["27", "404"], groupType: "2" },
    "6108": { docs: ["27", "404"], groupType: "2" },
    "6105": { docs: ["27", "404"], groupType: "2" },
    "6103": { docs: ["27", "404"], groupType: "2" },
    "6121": { docs: ["27", "404"], groupType: "2" },
    "6122": { docs: ["27", "404"], groupType: "2" },
    "6123": { docs: ["27", "404"], groupType: "2" },
    "6124": { docs: ["27", "404"], groupType: "2" },
    "6125": { docs: ["27", "404"], groupType: "2" },
    "6104": { docs: [], groupType: "2" },
    "6201": { docs: [], groupType: "3" },
    "6202": { docs: [], groupType: "3" },
    "6203": { docs: [], groupType: "3" },
    "6204": { docs: [], groupType: "3" },
    "6205": { docs: [], groupType: "3" },
    "6206": { docs: ["27", "404"], groupType: "3" },
    "6301": { docs: ["405", "27", "401"], groupType: "4" },
    "6302": { docs: ["405", "27", "401"], groupType: "4" },
    "6303": { docs: ["405", "27", "401"], groupType: "4" },
    "6304": { docs: ["405", "27", "401"], groupType: "4" },
    "6305": { docs: ["405", "27", "401"], groupType: "4" },
};

export const vrstaZahtevaAdvokatiOptions = [
    {
        value: "6031",
        label: "Промена имаоца права на катастарској парцели",
        groupType: "1",
        typeStep3: "Парцела",
    },
    {
        value: "6032",
        label: "Промена имаоца права на уписаном објекту",
        groupType: "1",
        typeStep3: "Парцела/Објекат",
    },
    {
        value: "6033",
        label: "Промена имаоца права на уписаном посебном делу објекта",
        groupType: "1",
        typeStep3: "Објекат/Посебан део",
    },
    {
        value: "6016",
        label: "Промена врсте права на земљишту (претварање државине у право својине)",
        groupType: "1",
        typeStep3: "Парцела",
    },
    {
        value: "6021",
        label: "Захтев за исправку грешке  у одржавању катастра непокретности- Исправка врсте/имаоца права",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6022",
        label: "Захтев за исправку грешке  у одржавању катастра непокретности-Исправка личних података",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6023",
        label: "Захтев за исправку грешке  у одржавању катастра непокретности-Исправка података о хипотеци",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6024",
        label: "Захтев за исправку грешке  у одржавању катастра непокретности-Исправка података о забележби и другим стварним правима",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6030",
        label: "Захтев за исправку грешке  у одржавању катастра непокретности-Исправка грешке у подацима о уписаном објекту/посебном делу објекта на основу исправе надлежног органа",
        groupType: "1",
        typeStep3: "Објекат/Посебан део",
    },
    {
        value: "6025",
        label: "Упис ЈМБГ-а односно идентификационог броја из путне исправе",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6026",
        label: "Захтев за упис површине посебног дела објекта",
        groupType: "1",
        typeStep3: "Посебан део",
    },
    {
        value: "6028",
        label: "Упис посебног дела објекта на основу уверења надлежног органа",
        groupType: "1",
        typeStep3: "Објекат",
    },
    {
        value: "6029",
        label: "Промена правног статуса објекта и промена врсте права",
        groupType: "1",
        typeStep3: "Објекат",
    },
    {
        value: "6003",
        label: "Утврђивање, измена и гашење кућног броја",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6004",
        label: "Промена културе и класе",
        groupType: "1",
        typeStep3: "Парцела",
    },
    {
        value: "6005",
        label: "Промена намене земљишта",
        groupType: "1",
        typeStep3: "Парцела",
    },
    {
        value: "6006",
        label: "Упис хипотеке",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6007",
        label: "Упис забележбе",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6017",
        label: "Упис других стварних и облигационих права",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6008",
        label: "Брисање хипотеке",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6009",
        label: "Брисање забележби",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6018",
        label: "Брисање других стварних и облигационих права",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6012",
        label: "Захтев за промену индикација правног лица",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6013",
        label: "Захтев за промену индикација физичког лица",
        groupType: "1",
        typeStep3: "Парцела/Објекат/Посебан део",
    },
    {
        value: "6011",
        label: "Предбележба објекта у изградњи",
        groupType: "1",
        typeStep3: "Парцела",
    },
    {
        value: "6101",
        label: "Извод из базе података катастра непокретности",
        groupType: "2",
        typeStep3: null,
    },
    {
        value: "6106",
        label: "Уверење о кретању власништва на непокретностима",
        groupType: "2",
        typeStep3: null,
    },
    {
        value: "6107",
        label: "Уверење о последњем стању уписа у КН (уверење о забележбама)",
        groupType: "2",
        typeStep3: null,
    },
    {
        value: "6108",
        label: "Увид у списе предмета из одржавања КН-а са доставом исправа из списа предмета",
        groupType: "2",
        typeStep3: null,
    },
    {
        value: "6105",
        label: "Уверење о имовинском стању за лице",
        groupType: "2",
        typeStep3: null,
    },
    {
        value: "6103",
        label: "Уверење о кућном броју",
        groupType: "2",
        typeStep3: null,
    },
    {
        value: "6121",
        label: "Уверење о називу улице",
        groupType: "2",
        typeStep3: null,
    },
    {
        value: "6122",
        label: "Уверењe о историјату кућног броја",
        groupType: "2",
        typeStep3: null,
    },
    {
        value: "6123",
        label: "Уверењe о историјату назива улице",
        groupType: "2",
        typeStep3: null,
    },
    {
        value: "6124",
        label: "Обавештење о адреси објекта",
        groupType: "2",
        typeStep3: null,
    },
    {
        value: "6125",
        label: "Уверење о идентификацији катастарске парцеле",
        groupType: "2",
        typeStep3: null,
    },
    {
        value: "6104",
        label: "Копија плана парцеле",
        groupType: "2",
        typeStep3: null,
    },
    {
        value: "6201",
        label: "Катастарски план у аналогном облику",
        groupType: "3",
        typeStep3: null,
    },
    {
        value: "6202",
        label: "Катастарски план у растерском облику (геореференцирани)",
        groupType: "3",
        typeStep3: null,
    },
    {
        value: "6203",
        label: "Катастарски план у векторском облику",
        groupType: "3",
        typeStep3: null,
    },
    {
        value: "6204",
        label: "Извод из базе података катастра инфраструктуре за један вод  (Лист водова за један вод)",
        groupType: "3",
        typeStep3: null,
    },
    {
        value: "6205",
        label: "Извод из базе података катастра инфраструктуре за имаоца права",
        groupType: "3",
        typeStep3: null,
    },
    {
        value: "6206",
        label: "Уверење о подацима последњег стања",
        groupType: "3",
        typeStep3: null,
    },
    {
        value: "6301",
        label: "Промена власника",
        groupType: "4",
        typeStep3: null,
    },
    {
        value: "6302",
        label: "Промена права",
        groupType: "4",
        typeStep3: null,
    },
    {
        value: "6303",
        label: "Упис и брисање хипотеке на воду",
        groupType: "4",
        typeStep3: null,
    },
    {
        value: "6304",
        label: "Упис и брисање забележбе на воду",
        groupType: "4",
        typeStep3: null,
    },
    {
        value: "6305",
        label: "Промена података о уписаном имаоцу права",
        groupType: "4",
        typeStep3: null,
    },
];

export const groupTitles: { [key: string]: string } = {
    "1": "01. Захтев за упис у катастар непокретности",
    "2": "02. Захтев за издавање података из катастра непокретности",
    "3": "03. Захтев за издавање података из катастра инфраструктурних и подземних објеката",
    "4": "04. Захтев за упис у катастар инфраструктурних и подземних објеката",
};

export const upisnikOptions: SelectOption[] = [
    { value: "И.И", label: "И.И" },
    { value: "И.Ив", label: "И.Ив" },
    { value: "И.нт", label: "И.нт" },
    { value: "И.Ивк", label: "И.Ивк" },
    { value: "О.Пм", label: "О.Пм" },
    { value: "О.Прм", label: "О.Прм" },
    { value: "И.И-ПОМ.", label: "И.И-ПОМ." },
    { value: "И.Ив-ПОМ", label: "И.Ив-ПОМ" },
    { value: "И.нт-ПОМ", label: "И.нт-ПОМ" },
    { value: "И.Ивк-ПОМ", label: "И.Ивк-ПОМ" },
    { value: "О.Пм-ПОМ", label: "О.Пм-ПОМ" },
    { value: "О.Прм-ПОМ", label: "О.Прм-ПОМ" },
    { value: "3ИО2011-И-ИВК-А", label: "3ИО2011-И-ИВК-А" },
    { value: "3ИО2011-И-И-А", label: "3ИО2011-И-И-А" },
    { value: "3ИО2011-И.НТ-А", label: "3ИО2011-И.НТ-А" },
    { value: "3ИО2011-ОПР.М-А", label: "3ИО2011-ОПР.М-А" },
    { value: "3ИО2011-ОПР-А", label: "3ИО2011-ОПР-А" },
    { value: "3ИО2011-И.ИВК-А", label: "3ИО2011-И.ИВК-А" },
    { value: "И.ИВ.А", label: "И.ИВ.А" },
    { value: "И.И.А", label: "И.И.А" },
];

export const vrstaUgovoraOptions: SelectOption[] = [
    { value: "50015", label: "Решење о извршењу" },
    { value: "50017", label: "Закључак о предаји непокретности" },
    { value: "50020", label: "Закључак о спровођењу на непокретности" },
    { value: "50026", label: "Решење о привременој мери" },
    { value: "50028", label: "Решење о претходној мери" },
    { value: "50030", label: "Закључак о стављању ван снаге закључка о спровођењу на непокретности" },
    { value: "50036", label: "Решење о укидању привремене мере" },
    { value: "50037", label: "Решење о обустави поступка" },
    { value: "50038", label: "Решење о укидању претходне мере" },
    { value: "60160", label: "Конверзија" },
];

export const vrstaNepokretnostiOptions: SelectOption[] = [
    { value: "Парцела", label: "Парцела" },
    { value: "Објекат", label: "Објекат" },
    { value: "Посебан део", label: "Посебан део" },
];

export const svojstvoLicaOption: SelectOption[] = [
    { value: "Поверилац", label: "Поверилац" },
    { value: "Дужник", label: "Дужник" },
    { value: "Друго лице", label: "Друго лице" },
];

export const sudOptions: SelectOption[] = [
    {
        label: "Основни суд у Брусу",
        value: "172",
    },
    {
        label: "Основни суд у Мајданпеку",
        value: "173",
    },
    {
        label: "Основни суд у Димитровграду",
        value: "174",
    },
    {
        label: "Основни суд у Сјеници",
        value: "175",
    },
    {
        label: "Основни суд у Трстенику",
        value: "176",
    },
    {
        label: "Основни суд у Рашкој",
        value: "177",
    },
    {
        label: "Основни суд у Сурдулици",
        value: "178",
    },
    {
        label: "Основни суд у Књажевцу",
        value: "179",
    },
    {
        label: "Основни суд у Великој Плани",
        value: "180",
    },
    {
        label: "ВИШИ СУД У БЕОГРАДУ",
        value: "801",
    },
    {
        label: "Виши суд у Београду - Посебно одељење за организовани криминал и Одељење за ратне злочине",
        value: "802",
    },
    {
        label: "ВИШИ СУД У ВАЉЕВУ",
        value: "803",
    },
    {
        label: "ВИШИ СУД У ВРАЊУ",
        value: "804",
    },
    {
        label: "ВИШИ СУД У ЗАЈЕЧАРУ",
        value: "805",
    },
    {
        label: "ВИШИ СУД У ЗРЕЊАНИНУ",
        value: "806",
    },
    {
        label: "ВИШИ СУД У ЈАГОДИНИ",
        value: "807",
    },
    {
        label: "ВИШИ СУД У КРАГУЈЕВЦУ",
        value: "808",
    },
    {
        label: "ВИШИ СУД У КРАЉЕВУ",
        value: "809",
    },
    {
        label: "ВИШИ СУД У КРУШЕВЦУ",
        value: "810",
    },
    {
        label: "ВИШИ СУД У ЛЕСКОВЦУ",
        value: "811",
    },
    {
        label: "ВИШИ СУД У НЕГОТИНУ",
        value: "812",
    },
    {
        label: "ВИШИ СУД У НИШУ",
        value: "813",
    },
    {
        label: "ВИШИ СУД У НОВОМ ПАЗАРУ",
        value: "814",
    },
    {
        label: "ВИШИ СУД У НОВОМ САДУ",
        value: "815",
    },
    {
        label: "ВИШИ СУД У ПАНЧЕВУ",
        value: "816",
    },
    {
        label: "ВИШИ СУД У ПИРОТУ",
        value: "817",
    },
    {
        label: "ВИШИ СУД У ПОЖАРЕВЦУ",
        value: "818",
    },
    {
        label: "ВИШИ СУД У ПРОКУПЉУ",
        value: "819",
    },
    {
        label: "ВИШИ СУД У СМЕДЕРЕВУ",
        value: "820",
    },
    {
        label: "ВИШИ СУД У СОМБОРУ",
        value: "821",
    },
    {
        label: "ВИШИ СУД У СРЕМСКОЈ МИТРОВИЦИ",
        value: "822",
    },
    {
        label: "ВИШИ СУД У СУБОТИЦИ",
        value: "823",
    },
    {
        label: "ВИШИ СУД У УЖИЦУ",
        value: "824",
    },
    {
        label: "ВИШИ СУД У ЧАЧКУ",
        value: "825",
    },
    {
        label: "ВИШИ СУД У ШАПЦУ",
        value: "826",
    },
    {
        label: "ДИРЕКЦИЈА ЗА УПРАВЉАЊЕ ОДУЗЕТОМ ИМОВИНОМ",
        value: "827",
    },
    {
        label: "ДРУГИ ОСНОВНИ СУД У БЕОГРАДУ",
        value: "828",
    },
    {
        label: "ОСНОВНИ СУД У АЛЕКСИНЦУ",
        value: "829",
    },
    {
        label: "Основни суд у Алексинцу - судска јединица у Сокобањи ",
        value: "830",
    },
    {
        label: "ОСНОВНИ СУД У АРАНЂЕЛОВЦУ",
        value: "831",
    },
    {
        label: "Основни суд у Аранђеловцу - судска јединица у Тополи  ",
        value: "832",
    },
    {
        label: "ОСНОВНИ СУД У БАЧКОЈ ПАЛАНЦИ",
        value: "833",
    },
    {
        label: "ОСНОВНИ СУД У БЕЧЕЈУ",
        value: "834",
    },
    {
        label: "Основни суд у Бечеју - судска јединица у Новом Бечеју",
        value: "835",
    },
    {
        label: "ОСНОВНИ СУД У БОРУ",
        value: "836",
    },
    {
        label: "Основни суд у Брусу - судска јединица у Александровцу",
        value: "837",
    },
    {
        label: "ОСНОВНИ СУД У БУЈАНОВЦУ",
        value: "838",
    },
    {
        label: "ОСНОВНИ СУД У ВАЉЕВУ",
        value: "839",
    },
    {
        label: "Основни суд у Великој Плани - судска јединица у Смедеревској Паланци",
        value: "840",
    },
    {
        label: "ОСНОВНИ СУД У ВЕЛИКОМ ГРАДИШТУ",
        value: "841",
    },
    {
        label: "ОСНОВНИ СУД У ВРАЊУ",
        value: "842",
    },
    {
        label: "ОСНОВНИ СУД У ВРБАСУ",
        value: "843",
    },
    {
        label: "Основни суд у Врбасу - судска јединица у Кули",
        value: "844",
    },
    {
        label: "ОСНОВНИ СУД У ВРШЦУ",
        value: "845",
    },
    {
        label: "Основни суд у Вршцу - судска јединица у Белој Цркви",
        value: "846",
    },
    {
        label: "ОСНОВНИ СУД У ГОРЊЕМ МИЛАНОВЦУ",
        value: "847",
    },
    {
        label: "ОСНОВНИ СУД У ДЕСПОТОВЦУ",
        value: "848",
    },
    {
        label: "Основни суд у Деспотовцу - судска јединица у Свилајнцу",
        value: "849",
    },
    {
        label: "ОСНОВНИ СУД У ЗАЈЕЧАРУ",
        value: "850",
    },
    {
        label: "ОСНОВНИ СУД У ЗРЕЊАНИНУ",
        value: "851",
    },
    {
        label: "ОСНОВНИ СУД У ИВАЊИЦИ",
        value: "852",
    },
    {
        label: "ОСНОВНИ СУД У ЈАГОДИНИ",
        value: "853",
    },
    {
        label: "ОСНОВНИ СУД У КИКИНДИ",
        value: "854",
    },
    {
        label: "Основни суд у Кикинди - судска јединица у Новом Кнежевцу",
        value: "855",
    },
    {
        label: "ОСНОВНИ СУД У КРАГУЈЕВЦУ",
        value: "856",
    },
    {
        label: "ОСНОВНИ СУД У КРАЉЕВУ",
        value: "857",
    },
    {
        label: "Основни суд у Краљеву - судска јединица у Врњачкој Бањи",
        value: "858",
    },
    {
        label: "ОСНОВНИ СУД У КРУШЕВЦУ",
        value: "859",
    },
    {
        label: "Основни суд у Крушевцу - судска јединица у Варварину",
        value: "860",
    },
    {
        label: "ОСНОВНИ СУД У КУРШУМЛИЈИ",
        value: "861",
    },
    {
        label: "ОСНОВНИ СУД У ЛАЗАРЕВЦУ",
        value: "862",
    },
    {
        label: "ОСНОВНИ СУД У ЛЕБАНУ",
        value: "863",
    },
    {
        label: "ОСНОВНИ СУД У ЛЕСКОВЦУ",
        value: "864",
    },
    {
        label: "Основни суд у Лесковцу - судска јединица у Власотинцу",
        value: "865",
    },
    {
        label: "ОСНОВНИ СУД У ЛОЗНИЦИ",
        value: "866",
    },
    {
        label: "Основни суд у Лозници - судска јединица у Љубовији ",
        value: "867",
    },
    {
        label: "ОСНОВНИ СУД У МИОНИЦИ",
        value: "868",
    },
    {
        label: "ОСНОВНИ СУД У МЛАДЕНОВЦУ",
        value: "869",
    },
    {
        label: "Основни суд у Младеновцу - судска јединица у Сопоту",
        value: "870",
    },
    {
        label: "ОСНОВНИ СУД У НЕГОТИНУ",
        value: "871",
    },
    {
        label: "Основни суд у Неготину - судска јединица у Кладову",
        value: "872",
    },
    {
        label: "ОСНОВНИ СУД У НИШУ",
        value: "873",
    },
    {
        label: "ОСНОВНИ СУД У НОВОМ ПАЗАРУ",
        value: "874",
    },
    {
        label: "Основни суд у Новом Пазару - судска јединица у Тутину",
        value: "875",
    },
    {
        label: "ОСНОВНИ СУД У НОВОМ САДУ",
        value: "876",
    },
    {
        label: "ОСНОВНИ СУД У ОБРЕНОВЦУ",
        value: "877",
    },
    {
        label: "ОСНОВНИ СУД У ПАНЧЕВУ",
        value: "878",
    },
    {
        label: "Основни суд у Панчеву - судска јединица у Ковачици",
        value: "879",
    },
    {
        label: "ОСНОВНИ СУД У ПАРАЋИНУ",
        value: "880",
    },
    {
        label: "Основни суд у Параћину - судска јединица у Ћуприји",
        value: "881",
    },
    {
        label: "ОСНОВНИ СУД У ПЕТРОВЦУ НА МЛАВИ",
        value: "882",
    },
    {
        label: "Основни суд у Петровцу на Млави - судска јединица у Жагубици",
        value: "883",
    },
    {
        label: "ОСНОВНИ СУД У ПИРОТУ",
        value: "884",
    },
    {
        label: "ОСНОВНИ СУД У ПОЖАРЕВЦУ",
        value: "885",
    },
    {
        label: "Основни суд у Пожаревцу - судска јединица у Кучеву",
        value: "886",
    },
    {
        label: "ОСНОВНИ СУД У ПОЖЕГИ",
        value: "887",
    },
    {
        label: "ОСНОВНИ СУД У ПРИБОЈУ",
        value: "888",
    },
    {
        label: "ОСНОВНИ СУД У ПРИЈЕПОЉУ",
        value: "889",
    },
    {
        label: "Основни суд у Пријепољу - судска јединица у Новој Вароши ",
        value: "890",
    },
    {
        label: "ОСНОВНИ СУД У ПРОКУПЉУ",
        value: "891",
    },
    {
        label: "ОСНОВНИ СУД У РУМИ",
        value: "892",
    },
    {
        label: "ОСНОВНИ СУД У СЕНТИ",
        value: "893",
    },
    {
        label: "ОСНОВНИ СУД У СМЕДЕРЕВУ",
        value: "894",
    },
    {
        label: "Основни суд у Смедереву - судска јединица у Ковину",
        value: "895",
    },
    {
        label: "ОСНОВНИ СУД У СОМБОРУ",
        value: "896",
    },
    {
        label: "ОСНОВНИ СУД У СРЕМСКОЈ МИТРОВИЦИ",
        value: "897",
    },
    {
        label: "ОСНОВНИ СУД У СТАРОЈ ПАЗОВИ",
        value: "898",
    },
    {
        label: "Основни суд у Старој Пазови - судска јединица у Инђији",
        value: "899",
    },
    {
        label: "ОСНОВНИ СУД У СУБОТИЦИ",
        value: "900",
    },
    {
        label: "Основни суд у Суботици - судска јединица у Бачкој Тополи",
        value: "901",
    },
    {
        label: "Основни суд у Сурдулици - судска јединица у Босилеграду",
        value: "902",
    },
    {
        label: "Основни суд у Сурдулици - судска јединица у Владичином Хану ",
        value: "903",
    },
    {
        label: "ОСНОВНИ СУД У УБУ",
        value: "904",
    },
    {
        label: "ОСНОВНИ СУД У УЖИЦУ",
        value: "905",
    },
    {
        label: "Основни суд у Ужицу - судска јединица у Бајиној Башти ",
        value: "906",
    },
    {
        label: "ОСНОВНИ СУД У ЧАЧКУ",
        value: "907",
    },
    {
        label: "ОСНОВНИ СУД У ШАПЦУ",
        value: "908",
    },
    {
        label: "Основни суд у Шапцу - судска јединица у Богатићу ",
        value: "909",
    },
    {
        label: "Основни суд у Шапцу - судска јединица у Коцељеви",
        value: "910",
    },
    {
        label: "ОСНОВНИ СУД У ШИДУ",
        value: "911",
    },
    {
        label: "ПРВИ ОСНОВНИ СУД У БЕОГРАДУ",
        value: "912",
    },
    {
        label: "ПРИВРЕДНИ СУД У БЕОГРАДУ",
        value: "913",
    },
    {
        label: "ПРИВРЕДНИ СУД У ВАЉЕВУ",
        value: "914",
    },
    {
        label: "Привредни суд у Ваљеву - судска јединица у Лозници",
        value: "915",
    },
    {
        label: "Привредни суд у Ваљеву - судска јединица у Шапцу",
        value: "916",
    },
    {
        label: "ПРИВРЕДНИ СУД У ЗАЈЕЧАРУ",
        value: "917",
    },
    {
        label: "ПРИВРЕДНИ СУД У ЗРЕЊАНИНУ",
        value: "918",
    },
    {
        label: "ПРИВРЕДНИ СУД У КРАГУЈЕВЦУ",
        value: "919",
    },
    {
        label: "Привредни суд у Крагујевцу - судска јединица у Јагодини",
        value: "920",
    },
    {
        label: "ПРИВРЕДНИ СУД У КРАЉЕВУ",
        value: "921",
    },
    {
        label: "Привредни суд у Краљеву - судска јединица у Крушевцу",
        value: "922",
    },
    {
        label: "Привредни суд у Краљеву - судска јединица у Новом Пазару",
        value: "923",
    },
    {
        label: "ПРИВРЕДНИ СУД У ЛЕСКОВЦУ",
        value: "924",
    },
    {
        label: "Привредни суд у Лесковцу - судска јединица у Врању",
        value: "925",
    },
    {
        label: "ПРИВРЕДНИ СУД У НИШУ",
        value: "926",
    },
    {
        label: "ПРИВРЕДНИ СУД У НОВОМ САДУ",
        value: "927",
    },
    {
        label: "ПРИВРЕДНИ СУД У ПАНЧЕВУ",
        value: "928",
    },
    {
        label: "ПРИВРЕДНИ СУД У ПОЖАРЕВЦУ",
        value: "929",
    },
    {
        label: "Привредни суд у Пожаревцу - судска јединица у Смедереву",
        value: "930",
    },
    {
        label: "ПРИВРЕДНИ СУД У СОМБОРУ",
        value: "931",
    },
    {
        label: "ПРИВРЕДНИ СУД У СРЕМСКОЈ МИТРОВИЦИ",
        value: "932",
    },
    {
        label: "ПРИВРЕДНИ СУД У СУБОТИЦИ",
        value: "933",
    },
    {
        label: "ПРИВРЕДНИ СУД У УЖИЦУ",
        value: "934",
    },
    {
        label: "ПРИВРЕДНИ СУД У ЧАЧКУ",
        value: "935",
    },
    {
        label: "ТРЕЋИ ОСНОВНИ СУД У БЕОГРАДУ",
        value: "936",
    },
    {
        label: "УПРАВНИ СУД",
        value: "937",
    },
];

export const kancelarijeOptions: SelectOption[] = [
    {
        label: "Далибор Станојковић-Јавни извршитељ",
        value: "1637",
    },
    {
        label: "Снежана Бранковић-Јавни извршитељ",
        value: "1639",
    },
    {
        label: "Александар Вуловић-Јавни извршитељ",
        value: "2001",
    },
    {
        label: "Александар Вучковић-Јавни извршитељ",
        value: "2002",
    },
    {
        label: "Александар Николић-Јавни извршитељ",
        value: "2003",
    },
    {
        label: "Александар Николић-Јавни извршитељ",
        value: "2004",
    },
    {
        label: "Александар Павловић-Јавни извршитељ",
        value: "2005",
    },
    {
        label: "Александар Ристовић-Јавни извршитељ",
        value: "2006",
    },
    {
        label: "Александар Такић-Јавни извршитељ",
        value: "2007",
    },
    {
        label: "Александар Тодоровић-Јавни извршитељ",
        value: "2008",
    },
    {
        label: "Александар Требовац-Јавни извршитељ",
        value: "2009",
    },
    {
        label: "Александра Антић-Јавни извршитељ",
        value: "2010",
    },
    {
        label: "Александра Трешњев-Јавни извршитељ",
        value: "2011",
    },
    {
        label: "Ана Костић Мишковић-Јавни извршитељ",
        value: "2012",
    },
    {
        label: "Ана Перендић-Јавни извршитељ",
        value: "2013",
    },
    {
        label: "Андрија Анђелковић-Јавни извршитељ",
        value: "2014",
    },
    {
        label: "Андријана Додић Коцић-Јавни извршитељ",
        value: "2015",
    },
    {
        label: "Анђелија Летић-Јавни извршитељ",
        value: "2016",
    },
    {
        label: "Биљана Ђерић-Јавни извршитељ",
        value: "2017",
    },
    {
        label: "Биљана Петровић-Јавни извршитељ",
        value: "2018",
    },
    {
        label: "Биљана Пешевска-Јавни извршитељ",
        value: "2019",
    },
    {
        label: "Бисерка Тошовић-Јавни извршитељ",
        value: "2020",
    },
    {
        label: "Бојан Ђурић-Јавни извршитељ",
        value: "2021",
    },
    {
        label: "Бојан Костић-Јавни извршитељ",
        value: "2022",
    },
    {
        label: "Бојан Товаришић-Јавни извршитељ",
        value: "2023",
    },
    {
        label: "Бојана Милановић Недељковић-Јавни извршитељ",
        value: "2024",
    },
    {
        label: "Борис Папић-Јавни извршитељ",
        value: "2025",
    },
    {
        label: "Бранислав Дардић-Јавни извршитељ",
        value: "2026",
    },
    {
        label: "Бранислав Радојчић-Јавни извршитељ",
        value: "2027",
    },
    {
        label: "Бранислав Чантрић-Јавни извршитељ",
        value: "2028",
    },
    {
        label: "Бранко Плавшић-Јавни извршитељ",
        value: "2029",
    },
    {
        label: "Братислав Филиповић-Јавни извршитељ",
        value: "2030",
    },
    {
        label: "Валентина Секулић-Јавни извршитељ",
        value: "2031",
    },
    {
        label: "Вељко Губеринић-Јавни извршитељ",
        value: "2032",
    },
    {
        label: "Вељко Раичевић-Јавни извршитељ",
        value: "2033",
    },
    {
        label: "Весна Буха-Јавни извршитељ",
        value: "2034",
    },
    {
        label: "Весна Крстојевић-Јавни извршитељ",
        value: "2035",
    },
    {
        label: "Весна Марковић-Јавни извршитељ",
        value: "2036",
    },
    {
        label: "Видоје Стојковић-Јавни извршитељ",
        value: "2037",
    },
    {
        label: "Владимир Трифуновић-Јавни извршитељ",
        value: "2038",
    },
    {
        label: "Војислав Милајић-Јавни извршитељ",
        value: "2039",
    },
    {
        label: "Војка Јањић-Јавни извршитељ",
        value: "2040",
    },
    {
        label: "Вук Терзић-Јавни извршитељ",
        value: "2041",
    },
    {
        label: "Горан Арсеновић-Јавни извршитељ",
        value: "2042",
    },
    {
        label: "Горан Брстина-Јавни извршитељ",
        value: "2043",
    },
    {
        label: "Горан Веселиновић-Јавни извршитељ",
        value: "2044",
    },
    {
        label: "Горан Милосавић-Јавни извршитељ",
        value: "2045",
    },
    {
        label: "Горан Шкеро-Јавни извршитељ",
        value: "2046",
    },
    {
        label: "Гордана Дамјановић-Јавни извршитељ",
        value: "2047",
    },
    {
        label: "Гордана Ђорђевић-Јавни извршитељ",
        value: "2048",
    },
    {
        label: "Далибор Лазић-Јавни извршитељ",
        value: "2049",
    },
    {
        label: "Дамир Шаботић-Јавни извршитељ",
        value: "2050",
    },
    {
        label: "Дамир Шите-Јавни извршитељ",
        value: "2051",
    },
    {
        label: "Данијел Томашевић-Јавни извршитељ",
        value: "2052",
    },
    {
        label: "Даница Чоловић-Јавни извршитељ",
        value: "2053",
    },
    {
        label: "Данка Ристановић-Јавни извршитељ",
        value: "2054",
    },
    {
        label: "Данка Станојевић-Јавни извршитељ",
        value: "2055",
    },
    {
        label: "Даринка Гелић-Јавни извршитељ",
        value: "2056",
    },
    {
        label: "Дарко Крунић-Јавни извршитељ",
        value: "2057",
    },
    {
        label: "Дејан Панић-Јавни извршитељ",
        value: "2058",
    },
    {
        label: "Дејан Пашић-Јавни извршитељ",
        value: "2059",
    },
    {
        label: "Десанка Ромић-Јавни извршитељ",
        value: "2060",
    },
    {
        label: "Димитрије Милошевић-Јавни извршитељ",
        value: "2061",
    },
    {
        label: "Добрила Павлица-Јавни извршитељ",
        value: "2062",
    },
    {
        label: "Драган Драговић-Јавни извршитељ",
        value: "2063",
    },
    {
        label: "Драган Лековић-Јавни извршитељ",
        value: "2064",
    },
    {
        label: "Драган Маричић-Јавни извршитељ",
        value: "2065",
    },
    {
        label: "Драган Николић-Јавни извршитељ",
        value: "2066",
    },
    {
        label: "Драган Петровић-Јавни извршитељ",
        value: "2067",
    },
    {
        label: "Драгана Добриловић-Јавни извршитељ",
        value: "2068",
    },
    {
        label: "Драгана Војводић-Јавни извршитељ",
        value: "2069",
    },
    {
        label: "Драгана Чвркић-Јавни извршитељ",
        value: "2070",
    },
    {
        label: "Ђорђе Доганџић-Јавни извршитељ",
        value: "2071",
    },
    {
        label: "Ђорђе Стојадиновић-Јавни извршитељ",
        value: "2072",
    },
    {
        label: "Жарко Димитријевић-Јавни извршитељ",
        value: "2073",
    },
    {
        label: "Жарко Радовић-Јавни извршитељ",
        value: "2074",
    },
    {
        label: "Жељка Кесић-Јавни извршитељ",
        value: "2075",
    },
    {
        label: "Жељко Кесић-Јавни извршитељ",
        value: "2076",
    },
    {
        label: "Жељко Чавић-Јавни извршитељ",
        value: "2077",
    },
    {
        label: "Живан Милинов-Јавни извршитељ",
        value: "2078",
    },
    {
        label: "Звездана Рајовић-Јавни извршитељ",
        value: "2079",
    },
    {
        label: "Зоран Богдановић-Јавни извршитељ",
        value: "2080",
    },
    {
        label: "Зоран Дукић-Јавни извршитељ",
        value: "2081",
    },
    {
        label: "Зоран Ђукић-Јавни извршитељ",
        value: "2082",
    },
    {
        label: "Зоран Јовић-Јавни извршитељ",
        value: "2083",
    },
    {
        label: "Зорица Милошевић-Јавни извршитељ",
        value: "2084",
    },
    {
        label: "Зорица Селечанин-Јавни извршитељ",
        value: "2085",
    },
    {
        label: "Иван Јездовић-Јавни извршитељ",
        value: "2086",
    },
    {
        label: "Иван Миловановић-Јавни извршитељ",
        value: "2087",
    },
    {
        label: "Иван Петровић-Јавни извршитељ",
        value: "2088",
    },
    {
        label: "Иван Ракочевић-Јавни извршитељ",
        value: "2089",
    },
    {
        label: "Ивана Букарица Биуковић-Јавни извршитељ",
        value: "2090",
    },
    {
        label: "Ивана Жугић-Јавни извршитељ",
        value: "2091",
    },
    {
        label: "Ирена Живковић-Јавни извршитељ",
        value: "2092",
    },
    {
        label: "Исидора Ранковић-Јавни извршитељ",
        value: "2093",
    },
    {
        label: "Јасмина Крстић-Јавни извршитељ",
        value: "2094",
    },
    {
        label: "Јасмина Трбовић Станковић-Јавни извршитељ",
        value: "2095",
    },
    {
        label: "Јасна Анђелић-Јавни извршитељ",
        value: "2096",
    },
    {
        label: "Јасна Брчкаловић-Јавни извршитељ",
        value: "2097",
    },
    {
        label: "Јелена Алексић-Јавни извршитељ",
        value: "2098",
    },
    {
        label: "Јелена Виторовић-Јавни извршитељ",
        value: "2099",
    },
    {
        label: "Јелена Вучковић-Јавни извршитељ",
        value: "2100",
    },
    {
        label: "Јелена Драговић-Јавни извршитељ",
        value: "2101",
    },
    {
        label: "Јелена Живаљевић-Јавни извршитељ",
        value: "2102",
    },
    {
        label: "Јелена Јевтић-Јавни извршитељ",
        value: "2103",
    },
    {
        label: "Јелена Марић-Јавни извршитељ",
        value: "2104",
    },
    {
        label: "Јелена Огњановић-Јавни извршитељ",
        value: "2105",
    },
    {
        label: "Јелена Поповић-Јавни извршитељ",
        value: "2106",
    },
    {
        label: "Јелена Радовановић-Јавни извршитељ",
        value: "2107",
    },
    {
        label: "Јелена Рајковић-Јавни извршитељ",
        value: "2108",
    },
    {
        label: "Јелена Станковић Миковић-Јавни извршитељ",
        value: "2109",
    },
    {
        label: "Јелена Стојановић-Јавни извршитељ",
        value: "2110",
    },
    {
        label: "Јован Пјешчић-Јавни извршитељ",
        value: "2111",
    },
    {
        label: "Коста Алексић-Јавни извршитељ",
        value: "2112",
    },
    {
        label: "Коста Брдарић-Јавни извршитељ",
        value: "2113",
    },
    {
        label: "Костадин Радовић-Јавни извршитељ",
        value: "2114",
    },
    {
        label: "Лазар Секулић-Јавни извршитељ",
        value: "2115",
    },
    {
        label: "Љиљана Живковић-Јавни извршитељ",
        value: "2116",
    },
    {
        label: "Љупка Станковић-Јавни извршитељ",
        value: "2117",
    },
    {
        label: "Маја Дукић Матић-Јавни извршитељ",
        value: "2118",
    },
    {
        label: "Марија Гачић-Јавни извршитељ",
        value: "2119",
    },
    {
        label: "Марија Радовић-Јавни извршитељ",
        value: "2120",
    },
    {
        label: "Маријана Дишић Гвозденовић-Јавни извршитељ",
        value: "2121",
    },
    {
        label: "Марина Антонић-Јавни извршитељ",
        value: "2122",
    },
    {
        label: "Марина Јеремић-Јавни извршитељ",
        value: "2123",
    },
    {
        label: "Марина Галовић-Јавни извршитељ",
        value: "2124",
    },
    {
        label: "Марко Вукићевић-Јавни извршитељ",
        value: "2125",
    },
    {
        label: "Марко Марјановић-Јавни извршитељ",
        value: "2126",
    },
    {
        label: "Марко Николић-Јавни извршитељ",
        value: "2127",
    },
    {
        label: "Марко Паламаревић-Јавни извршитељ",
        value: "2128",
    },
    {
        label: "Мила Милосављевић-Јавни извршитељ",
        value: "2129",
    },
    {
        label: "Милан Галоња-Јавни извршитељ",
        value: "2130",
    },
    {
        label: "Милан Жугић-Јавни извршитељ",
        value: "2131",
    },
    {
        label: "Милан Јовичић-Јавни извршитељ",
        value: "2132",
    },
    {
        label: "Милан Кесић-Јавни извршитељ",
        value: "2133",
    },
    {
        label: "Милан Лојаничић-Јавни извршитељ",
        value: "2134",
    },
    {
        label: "Миланка Савић-Јавни извршитељ",
        value: "2135",
    },
    {
        label: "Миле Симојловић-Јавни извршитељ",
        value: "2136",
    },
    {
        label: "Милица Вујичић-Јавни извршитељ",
        value: "2137",
    },
    {
        label: "Милица Митровић-Јавни извршитељ",
        value: "2138",
    },
    {
        label: "Милица Ристић Бачкуља-Јавни извршитељ",
        value: "2139",
    },
    {
        label: "Милица Џаковић Бојић-Јавни извршитељ",
        value: "2140",
    },
    {
        label: "Мило Дурутовић-Јавни извршитељ",
        value: "2141",
    },
    {
        label: "Милован Ђиновић-Јавни извршитељ",
        value: "2142",
    },
    {
        label: "Милош Јоксимовић-Јавни извршитељ",
        value: "2143",
    },
    {
        label: "Милош Митровић-Јавни извршитељ",
        value: "2144",
    },
    {
        label: "Милош Перић-Јавни извршитељ",
        value: "2145",
    },
    {
        label: "Миљан Трајковић-Јавни извршитељ",
        value: "2146",
    },
    {
        label: "Миодраг Драгићевић-Јавни извршитељ",
        value: "2147",
    },
    {
        label: "Мирјана Банић-Јавни извршитељ",
        value: "2148",
    },
    {
        label: "Мирјана Димитријевић-Јавни извршитељ",
        value: "2149",
    },
    {
        label: "Мирко Ратковић-Јавни извршитељ",
        value: "2150",
    },
    {
        label: "Мирослав Ињац-Јавни извршитељ",
        value: "2151",
    },
    {
        label: "Мића Ивковић-Јавни извршитељ",
        value: "2152",
    },
    {
        label: "Михаило Драговић-Јавни извршитељ",
        value: "2153",
    },
    {
        label: "Младен Пецељ-Јавни извршитељ",
        value: "2154",
    },
    {
        label: "мр Вујадин Масникоса-Јавни извршитељ",
        value: "2155",
    },
    {
        label: "Наташа Михајловић-Јавни извршитељ",
        value: "2156",
    },
    {
        label: "Наташа Радоњић-Јавни извршитељ",
        value: "2157",
    },
    {
        label: "Небојша Вујанац-Јавни извршитељ",
        value: "2158",
    },
    {
        label: "Невена Спасић-Јавни извршитељ",
        value: "2159",
    },
    {
        label: "Немања Протић-Јавни извршитељ",
        value: "2160",
    },
    {
        label: "Ненад Гвозденовић-Јавни извршитељ",
        value: "2161",
    },
    {
        label: "Ненад Јовановић-Јавни извршитељ",
        value: "2162",
    },
    {
        label: "Ненад Лукић-Јавни извршитељ",
        value: "2163",
    },
    {
        label: "Ненад Младеновић-Јавни извршитељ",
        value: "2164",
    },
    {
        label: "Никола Жунић-Јавни извршитељ",
        value: "2165",
    },
    {
        label: "Никола Јовановић-Јавни извршитељ",
        value: "2166",
    },
    {
        label: "Никола Кмезић-Јавни извршитељ",
        value: "2167",
    },
    {
        label: "Никола Новаковић-Јавни извршитељ",
        value: "2168",
    },
    {
        label: "Николина Шендер-Јавни извршитељ",
        value: "2169",
    },
    {
        label: "Нина Киурски-Јавни извршитељ",
        value: "2170",
    },
    {
        label: "Миодраг Грујовић-Јавни извршитељ",
        value: "2171",
    },
    {
        label: "Небојша Спасојевић-Јавни извршитељ",
        value: "2172",
    },
    {
        label: "ОД ИЗВРШИТЕЉИ Београд-Вождовац - Милош Думић",
        value: "2173",
    },
    {
        label: "ОД ИЗВРШИТЕЉИ Узелац и Смиљанић Нови Сад - Мирела Смиљанић Личина",
        value: "2174",
    },
    {
        label: "ОД ИЗВРШИТЕЉИ Узелац и Смиљанић Нови Сад - Милан Узелац",
        value: "2175",
    },
    {
        label: "ОД Јавни извршитељи Цветковић-Илић Зајечар - Душан Илић",
        value: "2176",
    },
    {
        label: "ОД Јавни извршитељи Цветковић-Илић Зајечар - Никола Цветковић",
        value: "2177",
    },
    {
        label: "Омер Екић-Јавни извршитељ",
        value: "2178",
    },
    {
        label: "Павле Милајић-Јавни извршитељ",
        value: "2179",
    },
    {
        label: "Петар Видачић-Јавни извршитељ",
        value: "2180",
    },
    {
        label: "Петар Петровић-Јавни извршитељ",
        value: "2181",
    },
    {
        label: "Предраг Врачарић-Јавни извршитељ",
        value: "2182",
    },
    {
        label: "Предраг Костић-Јавни извршитељ",
        value: "2183",
    },
    {
        label: "Предраг Филијовић-Јавни извршитељ",
        value: "2184",
    },
    {
        label: "Раденко Ракоњац-Јавни извршитељ",
        value: "2185",
    },
    {
        label: "Радован Тошић-Јавни извршитељ",
        value: "2186",
    },
    {
        label: "Ранка Tривић-Јавни извршитељ",
        value: "2187",
    },
    {
        label: "Ратко Видовић-Јавни извршитељ",
        value: "2188",
    },
    {
        label: "Саво Загорчић-Јавни извршитељ",
        value: "2189",
    },
    {
        label: "Сандра Кутлача-Јавни извршитељ",
        value: "2190",
    },
    {
        label: "Сања Спасић-Јавни извршитељ",
        value: "2191",
    },
    {
        label: "Саша Станковић-Јавни извршитељ",
        value: "2192",
    },
    {
        label: "Саша Шекуљица-Јавни извршитељ",
        value: "2193",
    },
    {
        label: "Светлана Гарчев Петерка-Јавни извршитељ",
        value: "2194",
    },
    {
        label: "Светлана Деврња-Јавни извршитељ",
        value: "2195",
    },
    {
        label: "Светлана Манић-Јавни извршитељ",
        value: "2196",
    },
    {
        label: "Славица Периз-Јавни извршитељ",
        value: "2197",
    },
    {
        label: "Слободан Живанов-Јавни извршитељ",
        value: "2198",
    },
    {
        label: "Смиљана Зубановић-Јавни извршитељ",
        value: "2199",
    },
    {
        label: "Снежана Петровић-Јавни извршитељ",
        value: "2200",
    },
    {
        label: "Соња Ковачевић-Јавни извршитељ",
        value: "2201",
    },
    {
        label: "Срђан Јовановић-Јавни извршитељ",
        value: "2202",
    },
    {
        label: "Срђан Миладинов-Јавни извршитељ",
        value: "2203",
    },
    {
        label: "Стана Жунић-Јавни извршитељ",
        value: "2204",
    },
    {
        label: "Станко Филиповић-Јавни извршитељ",
        value: "2205",
    },
    {
        label: "Стеван Петровић-Јавни извршитељ",
        value: "2206",
    },
    {
        label: "Стево Видовић-Јавни извршитељ",
        value: "2207",
    },
    {
        label: "Страхиња Милиновић-Јавни извршитељ",
        value: "2208",
    },
    {
        label: "Тамара Гуцуња-Јавни извршитељ",
        value: "2209",
    },
    {
        label: "Тамара Лучић-Јавни извршитељ",
        value: "2210",
    },
    {
        label: "Тамара Пајковић-Јавни извршитељ",
        value: "2211",
    },
    {
        label: "Тања Ђерић-Јавни извршитељ",
        value: "2212",
    },
    {
        label: "Уна Сучевић-Јавни извршитељ",
        value: "2213",
    },
    {
        label: "Филип Љујић-Јавни извршитељ",
        value: "2214",
    },
    {
        label: "Филип Станковић-Јавни извршитељ",
        value: "2215",
    },
    {
        label: "Марија Лазовић-Јавни извршитељ",
        value: "2216",
    },
    {
        label: "Мирјана Ринчић-Јавни извршитељ",
        value: "2217",
    },
    {
        label: "Јавни извршитељ-Ненад Милић",
        value: "2218",
    },
    {
        label: "Милан Николић-Јавни извршитељ",
        value: "2220",
    },
    {
        label: "Ђорђе Ђурић-Јавни извршитељ",
        value: "2221",
    },
    {
        label: "Мирослав Селаковић-Јавни извршитељ",
        value: "2222",
    },
    {
        label: "Дарко Чолевић-Јавни извршитељ",
        value: "2223",
    },
    {
        label: "Зоран Неранџић-Јавни извршитељ",
        value: "2224",
    },
    {
        label: "Урош Васиљевић-Јавни извршитељ",
        value: "2225",
    },
    {
        label: "Војислава Драговић Тубић-Јавни извршитељ",
        value: "2226",
    },
    {
        label: "Весна Лазовић-Јавни извршитељ",
        value: "2227",
    },
    {
        label: "Душан Трбојевић-Јавни извршитељ",
        value: "2228",
    },
    {
        label: "Марија Јовић-Јавни извршитељ",
        value: "2229",
    },
    {
        label: "Владимир Бојичић-Јавни извршитељ",
        value: "2230",
    },
    {
        label: "Радиша Петрић-Јавни извршитељ",
        value: "2231",
    },
    {
        label: "Душица Вујошевић Манић-Јавни извршитељ",
        value: "2232",
    },
    {
        label: "Данијела Лукић-Јавни извршитељ",
        value: "2233",
    },
    {
        label: "Жељко Ракић-Јавни извршитељ",
        value: "2234",
    },
    {
        label: "Александар Крџић - Јавни извршитељ",
        value: "3213",
    },
    {
        label: "Петар Мустур-Јавни извршитељ",
        value: "3224",
    },
    {
        label: "Сандра Трајковић-Јавни извршитељ",
        value: "3225",
    },
    {
        label: "Бранкица Степић-Јавни извршитељ",
        value: "3226",
    },
    {
        label: "Катарина Симић-Јавни извршитељ",
        value: "3227",
    },
    {
        label: "Мина Јовановић-Јавни извршитељ",
        value: "3232",
    },
    {
        label: "Борислав Петров-Јавни извршитељ",
        value: "3240",
    },
    {
        label: "Стеван Цветковић-Јавни извршитељ",
        value: "55100",
    },
    {
        label: "Бојан Тодоровић-Јавни извршитељ",
        value: "55190",
    },
    {
        label: "Петар Рундић-Јавни извршитељ",
        value: "55321",
    },
    {
        label: "Ана Томовић пр Привремени преузималац послова јавног извршитеља",
        value: "55391",
    },
    {
        label: "ИЗВРШИТЕЉ ТЕСТ",
        value: "55419",
    },
    {
        label: "Милан Милановић - привремени преузималац послова ЈИ Небојше Спасојевића",
        value: "56011",
    },
    {
        label: "Вера Максимовић-Јавни извршитељ",
        value: "56068",
    },
];

export const katastarskeOpstineOptions = [
    {
        label: "АДА, АДА",
        value: "АДА_АДА_80012_803448",
    },
    {
        label: "АДА, МОЛ",
        value: "АДА_МОЛ_80012_803588",
    },
    {
        label: "АДА, ОБОРЊАЧА",
        value: "АДА_ОБОРЊАЧА_80012_803634",
    },
    {
        label: "АДА, УТРИНЕ",
        value: "АДА_УТРИНЕ_80012_803642",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, АЛЕКСАНДРОВАЦ",
        value: "АЛЕКСАНДРОВАЦ_АЛЕКСАНДРОВАЦ_70017_700029",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, БЗЕНИЦЕ",
        value: "АЛЕКСАНДРОВАЦ_БЗЕНИЦЕ_70017_700037",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, БОБОТЕ",
        value: "АЛЕКСАНДРОВАЦ_БОБОТЕ_70017_700045",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, БОТУРИЋИ",
        value: "АЛЕКСАНДРОВАЦ_БОТУРИЋИ_70017_700053",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, БРАТИЋИ",
        value: "АЛЕКСАНДРОВАЦ_БРАТИЋИ_70017_700061",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ВЕЛИКА ВРБНИЦА ГОРЊА",
        value: "АЛЕКСАНДРОВАЦ_ВЕЛИКА ВРБНИЦА ГОРЊА_70017_700070",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ВЕЛИКА ВРБНИЦА ДОЊА",
        value: "АЛЕКСАНДРОВАЦ_ВЕЛИКА ВРБНИЦА ДОЊА_70017_720585",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ВЕЉА ГЛАВА",
        value: "АЛЕКСАНДРОВАЦ_ВЕЉА ГЛАВА_70017_700088",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ВИТКОВО",
        value: "АЛЕКСАНДРОВАЦ_ВИТКОВО_70017_700096",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ВРАЖОГРНЦИ",
        value: "АЛЕКСАНДРОВАЦ_ВРАЖОГРНЦИ_70017_700100",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ВРАНШТИЦА",
        value: "АЛЕКСАНДРОВАЦ_ВРАНШТИЦА_70017_700118",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ГАРЕВИНА",
        value: "АЛЕКСАНДРОВАЦ_ГАРЕВИНА_70017_700126",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ГОРЊА ЗЛЕГИЊА",
        value: "АЛЕКСАНДРОВАЦ_ГОРЊА ЗЛЕГИЊА_70017_700134",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ГОРЊИ ВРАТАРИ",
        value: "АЛЕКСАНДРОВАЦ_ГОРЊИ ВРАТАРИ_70017_700142",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ГОРЊИ СТУПАЊ",
        value: "АЛЕКСАНДРОВАЦ_ГОРЊИ СТУПАЊ_70017_700169",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ГРЧАК",
        value: "АЛЕКСАНДРОВАЦ_ГРЧАК_70017_700177",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ДАШНИЦА",
        value: "АЛЕКСАНДРОВАЦ_ДАШНИЦА_70017_700185",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ДОБРОЉУПЦИ",
        value: "АЛЕКСАНДРОВАЦ_ДОБРОЉУПЦИ_70017_700193",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ДОЊА ЗЛЕГИЊА",
        value: "АЛЕКСАНДРОВАЦ_ДОЊА ЗЛЕГИЊА_70017_700207",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ДОЊИ ВРАТАРИ",
        value: "АЛЕКСАНДРОВАЦ_ДОЊИ ВРАТАРИ_70017_700215",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ДОЊИ СТУПАЊ",
        value: "АЛЕКСАНДРОВАЦ_ДОЊИ СТУПАЊ_70017_720801",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ДРЕНЧА",
        value: "АЛЕКСАНДРОВАЦ_ДРЕНЧА_70017_700223",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ЈЕЛАКЦИ",
        value: "АЛЕКСАНДРОВАЦ_ЈЕЛАКЦИ_70017_700231",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, КОЖЕТИН",
        value: "АЛЕКСАНДРОВАЦ_КОЖЕТИН_70017_700240",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, КОЗНИЦА",
        value: "АЛЕКСАНДРОВАЦ_КОЗНИЦА_70017_700258",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ЛАТКОВАЦ",
        value: "АЛЕКСАНДРОВАЦ_ЛАТКОВАЦ_70017_700266",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ЛАЋИСЛЕД",
        value: "АЛЕКСАНДРОВАЦ_ЛАЋИСЛЕД_70017_721018",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ЛЕСЕНОВЦИ",
        value: "АЛЕКСАНДРОВАЦ_ЛЕСЕНОВЦИ_70017_700274",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ЛЕСКОВИЦА",
        value: "АЛЕКСАНДРОВАЦ_ЛЕСКОВИЦА_70017_700282",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ЉУБИНЦИ",
        value: "АЛЕКСАНДРОВАЦ_ЉУБИНЦИ_70017_700304",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, МРМОШ",
        value: "АЛЕКСАНДРОВАЦ_МРМОШ_70017_721204",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, НОВАЦИ",
        value: "АЛЕКСАНДРОВАЦ_НОВАЦИ_70017_700312",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ПАРЧИН",
        value: "АЛЕКСАНДРОВАЦ_ПАРЧИН_70017_700339",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ПЛЕШ",
        value: "АЛЕКСАНДРОВАЦ_ПЛЕШ_70017_700347",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ПЛОЧА",
        value: "АЛЕКСАНДРОВАЦ_ПЛОЧА_70017_700355",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ПУХОВАЦ",
        value: "АЛЕКСАНДРОВАЦ_ПУХОВАЦ_70017_700363",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, РАКЉА",
        value: "АЛЕКСАНДРОВАЦ_РАКЉА_70017_700371",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, РАТАЈЕ",
        value: "АЛЕКСАНДРОВАЦ_РАТАЈЕ_70017_700380",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, РЖАНИЦА",
        value: "АЛЕКСАНДРОВАЦ_РЖАНИЦА_70017_700398",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, РОГАВЧИНА",
        value: "АЛЕКСАНДРОВАЦ_РОГАВЧИНА_70017_700401",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, РОКЦИ",
        value: "АЛЕКСАНДРОВАЦ_РОКЦИ_70017_700410",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, РУДЕНИЦЕ",
        value: "АЛЕКСАНДРОВАЦ_РУДЕНИЦЕ_70017_700428",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, СТАЊЕВО",
        value: "АЛЕКСАНДРОВАЦ_СТАЊЕВО_70017_700436",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, СТАРЦИ-ПОПОВЦИ",
        value: "АЛЕКСАНДРОВАЦ_СТАРЦИ-ПОПОВЦИ_70017_700444",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, СТРМЕНИЦА",
        value: "АЛЕКСАНДРОВАЦ_СТРМЕНИЦА_70017_700452",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, СТУБАЛ",
        value: "АЛЕКСАНДРОВАЦ_СТУБАЛ_70017_700479",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, СУБОТИЦА",
        value: "АЛЕКСАНДРОВАЦ_СУБОТИЦА_70017_700487",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ТРЖАЦ",
        value: "АЛЕКСАНДРОВАЦ_ТРЖАЦ_70017_700495",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ТРНАВЦИ",
        value: "АЛЕКСАНДРОВАЦ_ТРНАВЦИ_70017_700509",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ТУЛЕШ",
        value: "АЛЕКСАНДРОВАЦ_ТУЛЕШ_70017_700517",
    },
    {
        label: "АЛЕКСАНДРОВАЦ, ШЉИВОВО",
        value: "АЛЕКСАНДРОВАЦ_ШЉИВОВО_70017_700525",
    },
    {
        label: "АЛЕКСИНАЦ, АЛЕКСИНАЦ ВАН ВАРОШ",
        value: "АЛЕКСИНАЦ_АЛЕКСИНАЦ ВАН ВАРОШ_70025_700533",
    },
    {
        label: "АЛЕКСИНАЦ, АЛЕКСИНАЦ ВАРОШ",
        value: "АЛЕКСИНАЦ_АЛЕКСИНАЦ ВАРОШ_70025_700541",
    },
    {
        label: "АЛЕКСИНАЦ, АЛЕКСИНАЧКИ БУЈМИР",
        value: "АЛЕКСИНАЦ_АЛЕКСИНАЧКИ БУЈМИР_70025_700550",
    },
    {
        label: "АЛЕКСИНАЦ, БАНКОВАЦ",
        value: "АЛЕКСИНАЦ_БАНКОВАЦ_70025_700851",
    },
    {
        label: "АЛЕКСИНАЦ, БЕЛИ БРЕГ",
        value: "АЛЕКСИНАЦ_БЕЛИ БРЕГ_70025_700568",
    },
    {
        label: "АЛЕКСИНАЦ, БЕЉА",
        value: "АЛЕКСИНАЦ_БЕЉА_70025_700860",
    },
    {
        label: "АЛЕКСИНАЦ, БОБОВИШТЕ",
        value: "АЛЕКСИНАЦ_БОБОВИШТЕ_70025_700576",
    },
    {
        label: "АЛЕКСИНАЦ, БОВАН",
        value: "АЛЕКСИНАЦ_БОВАН_70025_700584",
    },
    {
        label: "АЛЕКСИНАЦ, БРАДАРАЦ",
        value: "АЛЕКСИНАЦ_БРАДАРАЦ_70025_700592",
    },
    {
        label: "АЛЕКСИНАЦ, ВАКУП",
        value: "АЛЕКСИНАЦ_ВАКУП_70025_700606",
    },
    {
        label: "АЛЕКСИНАЦ, ВЕЛИКИ ДРЕНОВАЦ",
        value: "АЛЕКСИНАЦ_ВЕЛИКИ ДРЕНОВАЦ_70025_700878",
    },
    {
        label: "АЛЕКСИНАЦ, ВИТКОВАЦ",
        value: "АЛЕКСИНАЦ_ВИТКОВАЦ_70025_700886",
    },
    {
        label: "АЛЕКСИНАЦ, ВРЕЛО",
        value: "АЛЕКСИНАЦ_ВРЕЛО_70025_700622",
    },
    {
        label: "АЛЕКСИНАЦ, ВРЋЕНОВИЦА",
        value: "АЛЕКСИНАЦ_ВРЋЕНОВИЦА_70025_700894",
    },
    {
        label: "АЛЕКСИНАЦ, ВУКАЊА",
        value: "АЛЕКСИНАЦ_ВУКАЊА_70025_700908",
    },
    {
        label: "АЛЕКСИНАЦ, ВУКАШИНОВАЦ",
        value: "АЛЕКСИНАЦ_ВУКАШИНОВАЦ_70025_700649",
    },
    {
        label: "АЛЕКСИНАЦ, ГЛОГОВИЦА",
        value: "АЛЕКСИНАЦ_ГЛОГОВИЦА_70025_700657",
    },
    {
        label: "АЛЕКСИНАЦ, ГОЛЕШНИЦА",
        value: "АЛЕКСИНАЦ_ГОЛЕШНИЦА_70025_700916",
    },
    {
        label: "АЛЕКСИНАЦ, ГОРЊА ПЕШЧАНИЦА",
        value: "АЛЕКСИНАЦ_ГОРЊА ПЕШЧАНИЦА_70025_700924",
    },
    {
        label: "АЛЕКСИНАЦ, ГОРЊЕ СУХОТНО",
        value: "АЛЕКСИНАЦ_ГОРЊЕ СУХОТНО_70025_700932",
    },
    {
        label: "АЛЕКСИНАЦ, ГОРЊИ АДРОВАЦ",
        value: "АЛЕКСИНАЦ_ГОРЊИ АДРОВАЦ_70025_700959",
    },
    {
        label: "АЛЕКСИНАЦ, ГОРЊИ КРУПАЦ",
        value: "АЛЕКСИНАЦ_ГОРЊИ КРУПАЦ_70025_700665",
    },
    {
        label: "АЛЕКСИНАЦ, ГОРЊИ ЉУБЕШ",
        value: "АЛЕКСИНАЦ_ГОРЊИ ЉУБЕШ_70025_700967",
    },
    {
        label: "АЛЕКСИНАЦ, ГРЕДЕТИН",
        value: "АЛЕКСИНАЦ_ГРЕДЕТИН_70025_700975",
    },
    {
        label: "АЛЕКСИНАЦ, ГРЕЈАЧ",
        value: "АЛЕКСИНАЦ_ГРЕЈАЧ_70025_700983",
    },
    {
        label: "АЛЕКСИНАЦ, ДАШНИЦА",
        value: "АЛЕКСИНАЦ_ДАШНИЦА_70025_700991",
    },
    {
        label: "АЛЕКСИНАЦ, ДЕЛИГРАД",
        value: "АЛЕКСИНАЦ_ДЕЛИГРАД_70025_700673",
    },
    {
        label: "АЛЕКСИНАЦ, ДОБРУЈЕВАЦ",
        value: "АЛЕКСИНАЦ_ДОБРУЈЕВАЦ_70025_700681",
    },
    {
        label: "АЛЕКСИНАЦ, ДОЊА ПЕШЧАНИЦА",
        value: "АЛЕКСИНАЦ_ДОЊА ПЕШЧАНИЦА_70025_701009",
    },
    {
        label: "АЛЕКСИНАЦ, ДОЊЕ СУХОТНО",
        value: "АЛЕКСИНАЦ_ДОЊЕ СУХОТНО_70025_701017",
    },
    {
        label: "АЛЕКСИНАЦ, ДОЊИ АДРОВАЦ",
        value: "АЛЕКСИНАЦ_ДОЊИ АДРОВАЦ_70025_701025",
    },
    {
        label: "АЛЕКСИНАЦ, ДОЊИ КРУПАЦ",
        value: "АЛЕКСИНАЦ_ДОЊИ КРУПАЦ_70025_700690",
    },
    {
        label: "АЛЕКСИНАЦ, ДОЊИ ЉУБЕШ",
        value: "АЛЕКСИНАЦ_ДОЊИ ЉУБЕШ_70025_701033",
    },
    {
        label: "АЛЕКСИНАЦ, ДРАЖЕВАЦ",
        value: "АЛЕКСИНАЦ_ДРАЖЕВАЦ_70025_700703",
    },
    {
        label: "АЛЕКСИНАЦ, ЖИТКОВАЦ",
        value: "АЛЕКСИНАЦ_ЖИТКОВАЦ_70025_701050",
    },
    {
        label: "АЛЕКСИНАЦ, ЈАКОВЉЕ",
        value: "АЛЕКСИНАЦ_ЈАКОВЉЕ_70025_701068",
    },
    {
        label: "АЛЕКСИНАЦ, ЈАСЕЊЕ",
        value: "АЛЕКСИНАЦ_ЈАСЕЊЕ_70025_700711",
    },
    {
        label: "АЛЕКСИНАЦ, КАМЕНИЦА",
        value: "АЛЕКСИНАЦ_КАМЕНИЦА_70025_701076",
    },
    {
        label: "АЛЕКСИНАЦ, КАТУН",
        value: "АЛЕКСИНАЦ_КАТУН_70025_700720",
    },
    {
        label: "АЛЕКСИНАЦ, КОПРИВНИЦА",
        value: "АЛЕКСИНАЦ_КОПРИВНИЦА_70025_701084",
    },
    {
        label: "АЛЕКСИНАЦ, КОРМАН",
        value: "АЛЕКСИНАЦ_КОРМАН_70025_701092",
    },
    {
        label: "АЛЕКСИНАЦ, КРАЉЕВО",
        value: "АЛЕКСИНАЦ_КРАЉЕВО_70025_700738",
    },
    {
        label: "АЛЕКСИНАЦ, КРУШЈЕ",
        value: "АЛЕКСИНАЦ_КРУШЈЕ_70025_701106",
    },
    {
        label: "АЛЕКСИНАЦ, КУЛИНА",
        value: "АЛЕКСИНАЦ_КУЛИНА_70025_701114",
    },
    {
        label: "АЛЕКСИНАЦ, ЛИПОВАЦ",
        value: "АЛЕКСИНАЦ_ЛИПОВАЦ_70025_700746",
    },
    {
        label: "АЛЕКСИНАЦ, ЛОЗНАЦ",
        value: "АЛЕКСИНАЦ_ЛОЗНАЦ_70025_701122",
    },
    {
        label: "АЛЕКСИНАЦ, ЛОЋИКА",
        value: "АЛЕКСИНАЦ_ЛОЋИКА_70025_701149",
    },
    {
        label: "АЛЕКСИНАЦ, ЛУЖАНЕ",
        value: "АЛЕКСИНАЦ_ЛУЖАНЕ_70025_701157",
    },
    {
        label: "АЛЕКСИНАЦ, ЉУПТЕН",
        value: "АЛЕКСИНАЦ_ЉУПТЕН_70025_701165",
    },
    {
        label: "АЛЕКСИНАЦ, МАЛИ ДРЕНОВАЦ",
        value: "АЛЕКСИНАЦ_МАЛИ ДРЕНОВАЦ_70025_701173",
    },
    {
        label: "АЛЕКСИНАЦ, МОЗГОВО",
        value: "АЛЕКСИНАЦ_МОЗГОВО_70025_700754",
    },
    {
        label: "АЛЕКСИНАЦ, МОРАВАЦ",
        value: "АЛЕКСИНАЦ_МОРАВАЦ_70025_701181",
    },
    {
        label: "АЛЕКСИНАЦ, МОРАВСКИ БУЈМИР",
        value: "АЛЕКСИНАЦ_МОРАВСКИ БУЈМИР_70025_701190",
    },
    {
        label: "АЛЕКСИНАЦ, НОЗРИНА",
        value: "АЛЕКСИНАЦ_НОЗРИНА_70025_701203",
    },
    {
        label: "АЛЕКСИНАЦ, ПОРОДИН",
        value: "АЛЕКСИНАЦ_ПОРОДИН_70025_701211",
    },
    {
        label: "АЛЕКСИНАЦ, ПРЕКОНОЗИ",
        value: "АЛЕКСИНАЦ_ПРЕКОНОЗИ_70025_740659",
    },
    {
        label: "АЛЕКСИНАЦ, ПРЋИЛОВИЦА",
        value: "АЛЕКСИНАЦ_ПРЋИЛОВИЦА_70025_701220",
    },
    {
        label: "АЛЕКСИНАЦ, ПРУГОВАЦ",
        value: "АЛЕКСИНАЦ_ПРУГОВАЦ_70025_700789",
    },
    {
        label: "АЛЕКСИНАЦ, РАДЕВЦЕ",
        value: "АЛЕКСИНАЦ_РАДЕВЦЕ_70025_701238",
    },
    {
        label: "АЛЕКСИНАЦ, РСОВАЦ",
        value: "АЛЕКСИНАЦ_РСОВАЦ_70025_740683",
    },
    {
        label: "АЛЕКСИНАЦ, РУТЕВАЦ",
        value: "АЛЕКСИНАЦ_РУТЕВАЦ_70025_700797",
    },
    {
        label: "АЛЕКСИНАЦ, СРЕЗОВАЦ",
        value: "АЛЕКСИНАЦ_СРЕЗОВАЦ_70025_701254",
    },
    {
        label: "АЛЕКСИНАЦ, СТАНЦИ",
        value: "АЛЕКСИНАЦ_СТАНЦИ_70025_700819",
    },
    {
        label: "АЛЕКСИНАЦ, СТУБЛИНА",
        value: "АЛЕКСИНАЦ_СТУБЛИНА_70025_701262",
    },
    {
        label: "АЛЕКСИНАЦ, СУБОТИНАЦ",
        value: "АЛЕКСИНАЦ_СУБОТИНАЦ_70025_700827",
    },
    {
        label: "АЛЕКСИНАЦ, ТЕШИЦА",
        value: "АЛЕКСИНАЦ_ТЕШИЦА_70025_701297",
    },
    {
        label: "АЛЕКСИНАЦ, ТРЊАНЕ",
        value: "АЛЕКСИНАЦ_ТРЊАНЕ_70025_701319",
    },
    {
        label: "АЛЕКСИНАЦ, ЋИЋИНА",
        value: "АЛЕКСИНАЦ_ЋИЋИНА_70025_700835",
    },
    {
        label: "АЛЕКСИНАЦ, ЦРНА БАРА",
        value: "АЛЕКСИНАЦ_ЦРНА БАРА_70025_700843",
    },
    {
        label: "АЛЕКСИНАЦ, ЧЕСТА",
        value: "АЛЕКСИНАЦ_ЧЕСТА_70025_701327",
    },
    {
        label: "АЛЕКСИНАЦ, ЧУКУРОВАЦ",
        value: "АЛЕКСИНАЦ_ЧУКУРОВАЦ_70025_701335",
    },
    {
        label: "АЛЕКСИНАЦ, ШУРИЋ",
        value: "АЛЕКСИНАЦ_ШУРИЋ_70025_701343",
    },
    {
        label: "АЛИБУНАР, АЛИБУНАР",
        value: "АЛИБУНАР_АЛИБУНАР_80039_802310",
    },
    {
        label: "АЛИБУНАР, БАНАТСКИ КАРЛОВАЦ",
        value: "АЛИБУНАР_БАНАТСКИ КАРЛОВАЦ_80039_800309",
    },
    {
        label: "АЛИБУНАР, ВЛАДИМИРОВАЦ",
        value: "АЛИБУНАР_ВЛАДИМИРОВАЦ_80039_802522",
    },
    {
        label: "АЛИБУНАР, ДОБРИЦА",
        value: "АЛИБУНАР_ДОБРИЦА_80039_802646",
    },
    {
        label: "АЛИБУНАР, ИЛАНЏА",
        value: "АЛИБУНАР_ИЛАНЏА_80039_802395",
    },
    {
        label: "АЛИБУНАР, ЈАНОШИК",
        value: "АЛИБУНАР_ЈАНОШИК_80039_802425",
    },
    {
        label: "АЛИБУНАР, ЛОКВЕ",
        value: "АЛИБУНАР_ЛОКВЕ_80039_802450",
    },
    {
        label: "АЛИБУНАР, НИКОЛИНЦИ",
        value: "АЛИБУНАР_НИКОЛИНЦИ_80039_800538",
    },
    {
        label: "АЛИБУНАР, НОВИ КОЗЈАК",
        value: "АЛИБУНАР_НОВИ КОЗЈАК_80039_802743",
    },
    {
        label: "АЛИБУНАР, СЕЛЕУШ",
        value: "АЛИБУНАР_СЕЛЕУШ_80039_802506",
    },
    {
        label: "АПАТИН, АПАТИН",
        value: "АПАТИН_АПАТИН_80047_803812",
    },
    {
        label: "АПАТИН, КУПУСИНА",
        value: "АПАТИН_КУПУСИНА_80047_803910",
    },
    {
        label: "АПАТИН, ПРИГРЕВИЦА",
        value: "АПАТИН_ПРИГРЕВИЦА_80047_803936",
    },
    {
        label: "АПАТИН, СВИЛОЈЕВО",
        value: "АПАТИН_СВИЛОЈЕВО_80047_804029",
    },
    {
        label: "АПАТИН, СОНТА",
        value: "АПАТИН_СОНТА_80047_803979",
    },
    {
        label: "АРАНЂЕЛОВАЦ, АРАНЂЕЛОВАЦ",
        value: "АРАНЂЕЛОВАЦ_АРАНЂЕЛОВАЦ_70033_701351",
    },
    {
        label: "АРАНЂЕЛОВАЦ, БАЊА",
        value: "АРАНЂЕЛОВАЦ_БАЊА_70033_701360",
    },
    {
        label: "АРАНЂЕЛОВАЦ, БОСУТА",
        value: "АРАНЂЕЛОВАЦ_БОСУТА_70033_726974",
    },
    {
        label: "АРАНЂЕЛОВАЦ, БРЕЗОВАЦ",
        value: "АРАНЂЕЛОВАЦ_БРЕЗОВАЦ_70033_701408",
    },
    {
        label: "АРАНЂЕЛОВАЦ, БУКОВИК",
        value: "АРАНЂЕЛОВАЦ_БУКОВИК_70033_701416",
    },
    {
        label: "АРАНЂЕЛОВАЦ, ВЕНЧАНЕ",
        value: "АРАНЂЕЛОВАЦ_ВЕНЧАНЕ_70033_722979",
    },
    {
        label: "АРАНЂЕЛОВАЦ, ВРБИЦА",
        value: "АРАНЂЕЛОВАЦ_ВРБИЦА_70033_701459",
    },
    {
        label: "АРАНЂЕЛОВАЦ, ВУКОСАВЦИ",
        value: "АРАНЂЕЛОВАЦ_ВУКОСАВЦИ_70033_701467",
    },
    {
        label: "АРАНЂЕЛОВАЦ, ГАРАШИ",
        value: "АРАНЂЕЛОВАЦ_ГАРАШИ_70033_701475",
    },
    {
        label: "АРАНЂЕЛОВАЦ, ГОРЊА ТРЕШЊЕВИЦА",
        value: "АРАНЂЕЛОВАЦ_ГОРЊА ТРЕШЊЕВИЦА_70033_701483",
    },
    {
        label: "АРАНЂЕЛОВАЦ, ЈЕЛОВИК",
        value: "АРАНЂЕЛОВАЦ_ЈЕЛОВИК_70033_701548",
    },
    {
        label: "АРАНЂЕЛОВАЦ, КОПЉАРЕ",
        value: "АРАНЂЕЛОВАЦ_КОПЉАРЕ_70033_701556",
    },
    {
        label: "АРАНЂЕЛОВАЦ, МИСАЧА",
        value: "АРАНЂЕЛОВАЦ_МИСАЧА_70033_701645",
    },
    {
        label: "АРАНЂЕЛОВАЦ, ОРАШАЦ",
        value: "АРАНЂЕЛОВАЦ_ОРАШАЦ_70033_701661",
    },
    {
        label: "АРАНЂЕЛОВАЦ, ПАРТИЗАНИ",
        value: "АРАНЂЕЛОВАЦ_ПАРТИЗАНИ_70033_723134",
    },
    {
        label: "АРАНЂЕЛОВАЦ, ПРОГОРЕОЦИ",
        value: "АРАНЂЕЛОВАЦ_ПРОГОРЕОЦИ_70033_723177",
    },
    {
        label: "АРАНЂЕЛОВАЦ, РАНИЛОВИЋ",
        value: "АРАНЂЕЛОВАЦ_РАНИЛОВИЋ_70033_728624",
    },
    {
        label: "АРАНЂЕЛОВАЦ, СТОЈНИК",
        value: "АРАНЂЕЛОВАЦ_СТОЈНИК_70033_701696",
    },
    {
        label: "АРАНЂЕЛОВАЦ, ТУЛЕЖ",
        value: "АРАНЂЕЛОВАЦ_ТУЛЕЖ_70033_723258",
    },
    {
        label: "АРИЉЕ, АРИЉЕ",
        value: "АРИЉЕ_АРИЉЕ_70041_701734",
    },
    {
        label: "АРИЉЕ, БЈЕЛУША",
        value: "АРИЉЕ_БЈЕЛУША_70041_701742",
    },
    {
        label: "АРИЉЕ, БОГОЈЕВИЋИ",
        value: "АРИЉЕ_БОГОЈЕВИЋИ_70041_701769",
    },
    {
        label: "АРИЉЕ, БРЕКОВО",
        value: "АРИЉЕ_БРЕКОВО_70041_701777",
    },
    {
        label: "АРИЉЕ, ВИГОШТЕ-ПОГЛЕД",
        value: "АРИЉЕ_ВИГОШТЕ-ПОГЛЕД_70041_701785",
    },
    {
        label: "АРИЉЕ, ВИРОВО",
        value: "АРИЉЕ_ВИРОВО_70041_726460",
    },
    {
        label: "АРИЉЕ, ВИСОКА",
        value: "АРИЉЕ_ВИСОКА_70041_701793",
    },
    {
        label: "АРИЉЕ, ВРАНЕ",
        value: "АРИЉЕ_ВРАНЕ_70041_701807",
    },
    {
        label: "АРИЉЕ, ГРДОВИЋИ",
        value: "АРИЉЕ_ГРДОВИЋИ_70041_701815",
    },
    {
        label: "АРИЉЕ, ГРИВСКА",
        value: "АРИЉЕ_ГРИВСКА_70041_701823",
    },
    {
        label: "АРИЉЕ, ДОБРАЧЕ",
        value: "АРИЉЕ_ДОБРАЧЕ_70041_701831",
    },
    {
        label: "АРИЉЕ, ДРАГОЈЕВАЦ",
        value: "АРИЉЕ_ДРАГОЈЕВАЦ_70041_726605",
    },
    {
        label: "АРИЉЕ, ЂЕДОВАЦ",
        value: "АРИЉЕ_ЂЕДОВАЦ_70041_701840",
    },
    {
        label: "АРИЉЕ, КРУШЧИЦА",
        value: "АРИЉЕ_КРУШЧИЦА_70041_701858",
    },
    {
        label: "АРИЉЕ, ЛАТВИЦА",
        value: "АРИЉЕ_ЛАТВИЦА_70041_701866",
    },
    {
        label: "АРИЉЕ, МИРОСАЉЦИ",
        value: "АРИЉЕ_МИРОСАЉЦИ_70041_726796",
    },
    {
        label: "АРИЉЕ, РАДОБУЂА",
        value: "АРИЉЕ_РАДОБУЂА_70041_701904",
    },
    {
        label: "АРИЉЕ, РАДОШЕВО",
        value: "АРИЉЕ_РАДОШЕВО_70041_701912",
    },
    {
        label: "АРИЉЕ, СЕВЕРОВО",
        value: "АРИЉЕ_СЕВЕРОВО_70041_701939",
    },
    {
        label: "АРИЉЕ, СТУПЧЕВИЋИ",
        value: "АРИЉЕ_СТУПЧЕВИЋИ_70041_701947",
    },
    {
        label: "АРИЉЕ, ТРЕШЊЕВИЦА",
        value: "АРИЉЕ_ТРЕШЊЕВИЦА_70041_726923",
    },
    {
        label: "АРИЉЕ, ЦЕРОВА",
        value: "АРИЉЕ_ЦЕРОВА_70041_726940",
    },
    {
        label: "БАБУШНИЦА, АЛЕКСАНДРОВАЦ",
        value: "БАБУШНИЦА_АЛЕКСАНДРОВАЦ_70050_701971",
    },
    {
        label: "БАБУШНИЦА, БАБУШНИЦА",
        value: "БАБУШНИЦА_БАБУШНИЦА_70050_701980",
    },
    {
        label: "БАБУШНИЦА, БЕРДУЈ",
        value: "БАБУШНИЦА_БЕРДУЈ_70050_701998",
    },
    {
        label: "БАБУШНИЦА, БЕРИН ИЗВОР",
        value: "БАБУШНИЦА_БЕРИН ИЗВОР_70050_702005",
    },
    {
        label: "БАБУШНИЦА, БОГДАНОВАЦ",
        value: "БАБУШНИЦА_БОГДАНОВАЦ_70050_702013",
    },
    {
        label: "БАБУШНИЦА, БРАТИШЕВАЦ",
        value: "БАБУШНИЦА_БРАТИШЕВАЦ_70050_702021",
    },
    {
        label: "БАБУШНИЦА, БРЕСТОВ ДОЛ",
        value: "БАБУШНИЦА_БРЕСТОВ ДОЛ_70050_702030",
    },
    {
        label: "БАБУШНИЦА, ВАВА",
        value: "БАБУШНИЦА_ВАВА_70050_702048",
    },
    {
        label: "БАБУШНИЦА, ВАЛНИШ",
        value: "БАБУШНИЦА_ВАЛНИШ_70050_702056",
    },
    {
        label: "БАБУШНИЦА, ВЕЛИКО БОЊИНЦЕ",
        value: "БАБУШНИЦА_ВЕЛИКО БОЊИНЦЕ_70050_702064",
    },
    {
        label: "БАБУШНИЦА, ВРЕЛО",
        value: "БАБУШНИЦА_ВРЕЛО_70050_702072",
    },
    {
        label: "БАБУШНИЦА, ВУЧИ ДЕЛ",
        value: "БАБУШНИЦА_ВУЧИ ДЕЛ_70050_702099",
    },
    {
        label: "БАБУШНИЦА, ГОРЊЕ КРЊИНО",
        value: "БАБУШНИЦА_ГОРЊЕ КРЊИНО_70050_702102",
    },
    {
        label: "БАБУШНИЦА, ГОРЧИНЦЕ",
        value: "БАБУШНИЦА_ГОРЧИНЦЕ_70050_702129",
    },
    {
        label: "БАБУШНИЦА, ГРНЧАР",
        value: "БАБУШНИЦА_ГРНЧАР_70050_702137",
    },
    {
        label: "БАБУШНИЦА, ДОЛ",
        value: "БАБУШНИЦА_ДОЛ_70050_702145",
    },
    {
        label: "БАБУШНИЦА, ДОЊЕ КРЊИНО",
        value: "БАБУШНИЦА_ДОЊЕ КРЊИНО_70050_702153",
    },
    {
        label: "БАБУШНИЦА, ДРАГИНАЦ",
        value: "БАБУШНИЦА_ДРАГИНАЦ_70050_702161",
    },
    {
        label: "БАБУШНИЦА, ДУЧЕВАЦ",
        value: "БАБУШНИЦА_ДУЧЕВАЦ_70050_702170",
    },
    {
        label: "БАБУШНИЦА, ЗАВИДИНЦЕ",
        value: "БАБУШНИЦА_ЗАВИДИНЦЕ_70050_702188",
    },
    {
        label: "БАБУШНИЦА, ЗВОНЦЕ",
        value: "БАБУШНИЦА_ЗВОНЦЕ_70050_702196",
    },
    {
        label: "БАБУШНИЦА, ИЗВОР",
        value: "БАБУШНИЦА_ИЗВОР_70050_702200",
    },
    {
        label: "БАБУШНИЦА, ЈАСЕНОВ ДЕЛ",
        value: "БАБУШНИЦА_ЈАСЕНОВ ДЕЛ_70050_702218",
    },
    {
        label: "БАБУШНИЦА, КАЛУЂЕРОВО",
        value: "БАБУШНИЦА_КАЛУЂЕРОВО_70050_702226",
    },
    {
        label: "БАБУШНИЦА, КАМБЕЛЕВАЦ",
        value: "БАБУШНИЦА_КАМБЕЛЕВАЦ_70050_702234",
    },
    {
        label: "БАБУШНИЦА, КИЈЕВАЦ",
        value: "БАБУШНИЦА_КИЈЕВАЦ_70050_702242",
    },
    {
        label: "БАБУШНИЦА, ЛЕСКОВИЦА",
        value: "БАБУШНИЦА_ЛЕСКОВИЦА_70050_702269",
    },
    {
        label: "БАБУШНИЦА, ЛИНОВО",
        value: "БАБУШНИЦА_ЛИНОВО_70050_702277",
    },
    {
        label: "БАБУШНИЦА, ЉУБЕРАЂА",
        value: "БАБУШНИЦА_ЉУБЕРАЂА_70050_702285",
    },
    {
        label: "БАБУШНИЦА, МАЛО БОЊИНЦЕ",
        value: "БАБУШНИЦА_МАЛО БОЊИНЦЕ_70050_702293",
    },
    {
        label: "БАБУШНИЦА, МАСУРОВАЦ",
        value: "БАБУШНИЦА_МАСУРОВАЦ_70050_702307",
    },
    {
        label: "БАБУШНИЦА, МЕЗГРАЈА",
        value: "БАБУШНИЦА_МЕЗГРАЈА_70050_702315",
    },
    {
        label: "БАБУШНИЦА, МОДРА СТЕНА",
        value: "БАБУШНИЦА_МОДРА СТЕНА_70050_702323",
    },
    {
        label: "БАБУШНИЦА, НАШУШКОВИЦА",
        value: "БАБУШНИЦА_НАШУШКОВИЦА_70050_702331",
    },
    {
        label: "БАБУШНИЦА, ОСТАТОВИЦА",
        value: "БАБУШНИЦА_ОСТАТОВИЦА_70050_702340",
    },
    {
        label: "БАБУШНИЦА, ПРЕСЕКА",
        value: "БАБУШНИЦА_ПРЕСЕКА_70050_702358",
    },
    {
        label: "БАБУШНИЦА, ПРОВАЉЕНИК",
        value: "БАБУШНИЦА_ПРОВАЉЕНИК_70050_702366",
    },
    {
        label: "БАБУШНИЦА, РАДИЊИНЦЕ",
        value: "БАБУШНИЦА_РАДИЊИНЦЕ_70050_702374",
    },
    {
        label: "БАБУШНИЦА, РАДОСИН",
        value: "БАБУШНИЦА_РАДОСИН_70050_702382",
    },
    {
        label: "БАБУШНИЦА, РАДОШЕВАЦ",
        value: "БАБУШНИЦА_РАДОШЕВАЦ_70050_702404",
    },
    {
        label: "БАБУШНИЦА, РАКИТА",
        value: "БАБУШНИЦА_РАКИТА_70050_702412",
    },
    {
        label: "БАБУШНИЦА, РАКОВ ДОЛ",
        value: "БАБУШНИЦА_РАКОВ ДОЛ_70050_702439",
    },
    {
        label: "БАБУШНИЦА, РАЉИН",
        value: "БАБУШНИЦА_РАЉИН_70050_702447",
    },
    {
        label: "БАБУШНИЦА, РЕСНИК",
        value: "БАБУШНИЦА_РЕСНИК_70050_702455",
    },
    {
        label: "БАБУШНИЦА, СТОЛ",
        value: "БАБУШНИЦА_СТОЛ_70050_702463",
    },
    {
        label: "БАБУШНИЦА, СТРЕЛАЦ",
        value: "БАБУШНИЦА_СТРЕЛАЦ_70050_702471",
    },
    {
        label: "БАБУШНИЦА, СТРИЖЕВАЦ",
        value: "БАБУШНИЦА_СТРИЖЕВАЦ_70050_702480",
    },
    {
        label: "БАБУШНИЦА, СТУДЕНА",
        value: "БАБУШНИЦА_СТУДЕНА_70050_702498",
    },
    {
        label: "БАБУШНИЦА, СУРАЧЕВО",
        value: "БАБУШНИЦА_СУРАЧЕВО_70050_702501",
    },
    {
        label: "БАБУШНИЦА, ЦРВЕНА ЈАБУКА",
        value: "БАБУШНИЦА_ЦРВЕНА ЈАБУКА_70050_702510",
    },
    {
        label: "БАБУШНИЦА, ШТРБОВАЦ",
        value: "БАБУШНИЦА_ШТРБОВАЦ_70050_702528",
    },
    {
        label: "БАЈИНА БАШТА, БАЈИНА БАШТА",
        value: "БАЈИНА БАШТА_БАЈИНА БАШТА_70068_702536",
    },
    {
        label: "БАЈИНА БАШТА, БЕСЕРОВИНА",
        value: "БАЈИНА БАШТА_БЕСЕРОВИНА_70068_702544",
    },
    {
        label: "БАЈИНА БАШТА, ВИШЕСАВА",
        value: "БАЈИНА БАШТА_ВИШЕСАВА_70068_702552",
    },
    {
        label: "БАЈИНА БАШТА, ГВОЗДАЦ",
        value: "БАЈИНА БАШТА_ГВОЗДАЦ_70068_702579",
    },
    {
        label: "БАЈИНА БАШТА, ГОРЊЕ ЗАРОЖЈЕ",
        value: "БАЈИНА БАШТА_ГОРЊЕ ЗАРОЖЈЕ_70068_702587",
    },
    {
        label: "БАЈИНА БАШТА, ДОБРОТИН",
        value: "БАЈИНА БАШТА_ДОБРОТИН_70068_702595",
    },
    {
        label: "БАЈИНА БАШТА, ДОЊЕ ЗАРОЖЈЕ",
        value: "БАЈИНА БАШТА_ДОЊЕ ЗАРОЖЈЕ_70068_702609",
    },
    {
        label: "БАЈИНА БАШТА, ДРАКСИН",
        value: "БАЈИНА БАШТА_ДРАКСИН_70068_702617",
    },
    {
        label: "БАЈИНА БАШТА, ДУБ",
        value: "БАЈИНА БАШТА_ДУБ_70068_702625",
    },
    {
        label: "БАЈИНА БАШТА, ЗАГЛАВАК",
        value: "БАЈИНА БАШТА_ЗАГЛАВАК_70068_702633",
    },
    {
        label: "БАЈИНА БАШТА, ЗАОВИНЕ",
        value: "БАЈИНА БАШТА_ЗАОВИНЕ_70068_702641",
    },
    {
        label: "БАЈИНА БАШТА, ЗАУГЛИНЕ",
        value: "БАЈИНА БАШТА_ЗАУГЛИНЕ_70068_702650",
    },
    {
        label: "БАЈИНА БАШТА, ЗЛОДОЛ",
        value: "БАЈИНА БАШТА_ЗЛОДОЛ_70068_702668",
    },
    {
        label: "БАЈИНА БАШТА, ЈАГОШТИЦА",
        value: "БАЈИНА БАШТА_ЈАГОШТИЦА_70068_702676",
    },
    {
        label: "БАЈИНА БАШТА, ЈАКАЉ",
        value: "БАЈИНА БАШТА_ЈАКАЉ_70068_702684",
    },
    {
        label: "БАЈИНА БАШТА, ЈЕЛОВИК",
        value: "БАЈИНА БАШТА_ЈЕЛОВИК_70068_702692",
    },
    {
        label: "БАЈИНА БАШТА, КОЊСКА РЕКА",
        value: "БАЈИНА БАШТА_КОЊСКА РЕКА_70068_702706",
    },
    {
        label: "БАЈИНА БАШТА, КОСТОЈЕВИЋИ",
        value: "БАЈИНА БАШТА_КОСТОЈЕВИЋИ_70068_702714",
    },
    {
        label: "БАЈИНА БАШТА, ЛУГ",
        value: "БАЈИНА БАШТА_ЛУГ_70068_702722",
    },
    {
        label: "БАЈИНА БАШТА, ЉЕШТАНСКО",
        value: "БАЈИНА БАШТА_ЉЕШТАНСКО_70068_702749",
    },
    {
        label: "БАЈИНА БАШТА, МАЛА РЕКА",
        value: "БАЈИНА БАШТА_МАЛА РЕКА_70068_702757",
    },
    {
        label: "БАЈИНА БАШТА, ОБАЈГОРА",
        value: "БАЈИНА БАШТА_ОБАЈГОРА_70068_702765",
    },
    {
        label: "БАЈИНА БАШТА, ОВЧИЊА",
        value: "БАЈИНА БАШТА_ОВЧИЊА_70068_702773",
    },
    {
        label: "БАЈИНА БАШТА, ОКЛЕТАЦ",
        value: "БАЈИНА БАШТА_ОКЛЕТАЦ_70068_702781",
    },
    {
        label: "БАЈИНА БАШТА, ПЕРУЋАЦ",
        value: "БАЈИНА БАШТА_ПЕРУЋАЦ_70068_702790",
    },
    {
        label: "БАЈИНА БАШТА, ПИЛИЦА",
        value: "БАЈИНА БАШТА_ПИЛИЦА_70068_702803",
    },
    {
        label: "БАЈИНА БАШТА, ПРИДОЛИ",
        value: "БАЈИНА БАШТА_ПРИДОЛИ_70068_702811",
    },
    {
        label: "БАЈИНА БАШТА, РАСТИШТЕ",
        value: "БАЈИНА БАШТА_РАСТИШТЕ_70068_702820",
    },
    {
        label: "БАЈИНА БАШТА, РАЧА",
        value: "БАЈИНА БАШТА_РАЧА_70068_702838",
    },
    {
        label: "БАЈИНА БАШТА, РОГАЧИЦА",
        value: "БАЈИНА БАШТА_РОГАЧИЦА_70068_702846",
    },
    {
        label: "БАЈИНА БАШТА, СВОЈДРУГ",
        value: "БАЈИНА БАШТА_СВОЈДРУГ_70068_702854",
    },
    {
        label: "БАЈИНА БАШТА, СИЈЕРАЧ",
        value: "БАЈИНА БАШТА_СИЈЕРАЧ_70068_702862",
    },
    {
        label: "БАЈИНА БАШТА, СОЛОТУША",
        value: "БАЈИНА БАШТА_СОЛОТУША_70068_702889",
    },
    {
        label: "БАЈИНА БАШТА, СТРМОВО",
        value: "БАЈИНА БАШТА_СТРМОВО_70068_702897",
    },
    {
        label: "БАЈИНА БАШТА, ЦРВИЦА",
        value: "БАЈИНА БАШТА_ЦРВИЦА_70068_702919",
    },
    {
        label: "БАРАЈЕВО, АРНАЈЕВО",
        value: "БАРАЈЕВО_АРНАЈЕВО_70092_703451",
    },
    {
        label: "БАРАЈЕВО, БАРАЈЕВО",
        value: "БАРАЈЕВО_БАРАЈЕВО_70092_703460",
    },
    {
        label: "БАРАЈЕВО, БАЋЕВАЦ",
        value: "БАРАЈЕВО_БАЋЕВАЦ_70092_703478",
    },
    {
        label: "БАРАЈЕВО, БЕЉИНА",
        value: "БАРАЈЕВО_БЕЉИНА_70092_703508",
    },
    {
        label: "БАРАЈЕВО, БОЖДАРЕВАЦ",
        value: "БАРАЈЕВО_БОЖДАРЕВАЦ_70092_703516",
    },
    {
        label: "БАРАЈЕВО, ВЕЛИКИ БОРАК",
        value: "БАРАЈЕВО_ВЕЛИКИ БОРАК_70092_703567",
    },
    {
        label: "БАРАЈЕВО, ВРАНИЋ",
        value: "БАРАЈЕВО_ВРАНИЋ_70092_703630",
    },
    {
        label: "БАРАЈЕВО, ГУНЦАТИ",
        value: "БАРАЈЕВО_ГУНЦАТИ_70092_703672",
    },
    {
        label: "БАРАЈЕВО, ЛИСОВИЋ",
        value: "БАРАЈЕВО_ЛИСОВИЋ_70092_703826",
    },
    {
        label: "БАРАЈЕВО, МАНИЋ",
        value: "БАРАЈЕВО_МАНИЋ_70092_703869",
    },
    {
        label: "БАРАЈЕВО, МЕЉАК",
        value: "БАРАЈЕВО_МЕЉАК_70092_703877",
    },
    {
        label: "БАРАЈЕВО, РОЖАНЦИ",
        value: "БАРАЈЕВО_РОЖАНЦИ_70092_703974",
    },
    {
        label: "БАРАЈЕВО, ШИЉАКОВАЦ",
        value: "БАРАЈЕВО_ШИЉАКОВАЦ_70092_704091",
    },
    {
        label: "БАТОЧИНА, БАДЊЕВАЦ",
        value: "БАТОЧИНА_БАДЊЕВАЦ_70076_736414",
    },
    {
        label: "БАТОЧИНА, БАТОЧИНА (ВАРОШИЦА)",
        value: "БАТОЧИНА_БАТОЧИНА (ВАРОШИЦА)_70076_719129",
    },
    {
        label: "БАТОЧИНА, БАТОЧИНА (СЕЛО)",
        value: "БАТОЧИНА_БАТОЧИНА (СЕЛО)_70076_719137",
    },
    {
        label: "БАТОЧИНА, БРЗАН",
        value: "БАТОЧИНА_БРЗАН_70076_719161",
    },
    {
        label: "БАТОЧИНА, ГРАДАЦ",
        value: "БАТОЧИНА_ГРАДАЦ_70076_736503",
    },
    {
        label: "БАТОЧИНА, ДОБРОВОДИЦА",
        value: "БАТОЧИНА_ДОБРОВОДИЦА_70076_719218",
    },
    {
        label: "БАТОЧИНА, ЖИРОВНИЦА",
        value: "БАТОЧИНА_ЖИРОВНИЦА_70076_719269",
    },
    {
        label: "БАТОЧИНА, КИЈЕВО",
        value: "БАТОЧИНА_КИЈЕВО_70076_719307",
    },
    {
        label: "БАТОЧИНА, МИЛАТОВАЦ",
        value: "БАТОЧИНА_МИЛАТОВАЦ_70076_719366",
    },
    {
        label: "БАТОЧИНА, НИКШИЋ",
        value: "БАТОЧИНА_НИКШИЋ_70076_719382",
    },
    {
        label: "БАТОЧИНА, ПРЊАВОР",
        value: "БАТОЧИНА_ПРЊАВОР_70076_719455",
    },
    {
        label: "БАТОЧИНА, ЦРНИ КАО",
        value: "БАТОЧИНА_ЦРНИ КАО_70076_736694",
    },
    {
        label: "БАЧ, БАЧ",
        value: "БАЧ_БАЧ_80055_800171",
    },
    {
        label: "БАЧ, БАЧКО НОВО СЕЛО",
        value: "БАЧ_БАЧКО НОВО СЕЛО_80055_800180",
    },
    {
        label: "БАЧ, БОЂАНИ",
        value: "БАЧ_БОЂАНИ_80055_800198",
    },
    {
        label: "БАЧ, ВАЈСКА",
        value: "БАЧ_ВАЈСКА_80055_800252",
    },
    {
        label: "БАЧ, ПЛАВНА",
        value: "БАЧ_ПЛАВНА_80055_800236",
    },
    {
        label: "БАЧ, СЕЛЕНЧА",
        value: "БАЧ_СЕЛЕНЧА_80055_800112",
    },
    {
        label: "БАЧКА ПАЛАНКА, БАЧКА ПАЛАНКА",
        value: "БАЧКА ПАЛАНКА_БАЧКА ПАЛАНКА_80063_800015",
    },
    {
        label: "БАЧКА ПАЛАНКА, БАЧКА ПАЛАНКА-ГРАД",
        value: "БАЧКА ПАЛАНКА_БАЧКА ПАЛАНКА-ГРАД_80063_800163",
    },
    {
        label: "БАЧКА ПАЛАНКА, ВИЗИЋ",
        value: "БАЧКА ПАЛАНКА_ВИЗИЋ_80063_805165",
    },
    {
        label: "БАЧКА ПАЛАНКА, ГАЈДОБРА",
        value: "БАЧКА ПАЛАНКА_ГАЈДОБРА_80063_800031",
    },
    {
        label: "БАЧКА ПАЛАНКА, ДЕСПОТОВО",
        value: "БАЧКА ПАЛАНКА_ДЕСПОТОВО_80063_802093",
    },
    {
        label: "БАЧКА ПАЛАНКА, МЛАДЕНОВО",
        value: "БАЧКА ПАЛАНКА_МЛАДЕНОВО_80063_800210",
    },
    {
        label: "БАЧКА ПАЛАНКА, НЕШТИН",
        value: "БАЧКА ПАЛАНКА_НЕШТИН_80063_805092",
    },
    {
        label: "БАЧКА ПАЛАНКА, НОВА ГАЈДОБРА",
        value: "БАЧКА ПАЛАНКА_НОВА ГАЈДОБРА_80063_800058",
    },
    {
        label: "БАЧКА ПАЛАНКА, НОВА ПАЛАНКА",
        value: "БАЧКА ПАЛАНКА_НОВА ПАЛАНКА_80063_800228",
    },
    {
        label: "БАЧКА ПАЛАНКА, ОБРОВАЦ",
        value: "БАЧКА ПАЛАНКА_ОБРОВАЦ_80063_800066",
    },
    {
        label: "БАЧКА ПАЛАНКА, ПАРАГЕ",
        value: "БАЧКА ПАЛАНКА_ПАРАГЕ_80063_800074",
    },
    {
        label: "БАЧКА ПАЛАНКА, ПИВНИЦЕ",
        value: "БАЧКА ПАЛАНКА_ПИВНИЦЕ_80063_800082",
    },
    {
        label: "БАЧКА ПАЛАНКА, СИЛБАШ",
        value: "БАЧКА ПАЛАНКА_СИЛБАШ_80063_800139",
    },
    {
        label: "БАЧКА ПАЛАНКА, ТОВАРИШЕВО",
        value: "БАЧКА ПАЛАНКА_ТОВАРИШЕВО_80063_800155",
    },
    {
        label: "БАЧКА ПАЛАНКА, ЧЕЛАРЕВО",
        value: "БАЧКА ПАЛАНКА_ЧЕЛАРЕВО_80063_800023",
    },
    {
        label: "БАЧКА ТОПОЛА, БАЈША",
        value: "БАЧКА ТОПОЛА_БАЈША_80071_804355",
    },
    {
        label: "БАЧКА ТОПОЛА, БАЧКА ТОПОЛА",
        value: "БАЧКА ТОПОЛА_БАЧКА ТОПОЛА_80071_801437",
    },
    {
        label: "БАЧКА ТОПОЛА, БАЧКА ТОПОЛА-ГРАД",
        value: "БАЧКА ТОПОЛА_БАЧКА ТОПОЛА-ГРАД_80071_803529",
    },
    {
        label: "БАЧКА ТОПОЛА, ГОРЊА РОГАТИЦА",
        value: "БАЧКА ТОПОЛА_ГОРЊА РОГАТИЦА_80071_804428",
    },
    {
        label: "БАЧКА ТОПОЛА, ГУНАРОШ",
        value: "БАЧКА ТОПОЛА_ГУНАРОШ_80071_803502",
    },
    {
        label: "БАЧКА ТОПОЛА, МАЛИ БЕОГРАД",
        value: "БАЧКА ТОПОЛА_МАЛИ БЕОГРАД_80071_804452",
    },
    {
        label: "БАЧКА ТОПОЛА, НОВО ОРАХОВО",
        value: "БАЧКА ТОПОЛА_НОВО ОРАХОВО_80071_803600",
    },
    {
        label: "БАЧКА ТОПОЛА, ЊЕГОШЕВО",
        value: "БАЧКА ТОПОЛА_ЊЕГОШЕВО_80071_803618",
    },
    {
        label: "БАЧКА ТОПОЛА, ПАЧИР",
        value: "БАЧКА ТОПОЛА_ПАЧИР_80071_804487",
    },
    {
        label: "БАЧКА ТОПОЛА, СТАРА МОРАВИЦА",
        value: "БАЧКА ТОПОЛА_СТАРА МОРАВИЦА_80071_804509",
    },
    {
        label: "БАЧКИ ПЕТРОВАЦ, БАЧКИ ПЕТРОВАЦ",
        value: "БАЧКИ ПЕТРОВАЦ_БАЧКИ ПЕТРОВАЦ_80080_802042",
    },
    {
        label: "БАЧКИ ПЕТРОВАЦ, ГЛОЖАН",
        value: "БАЧКИ ПЕТРОВАЦ_ГЛОЖАН_80080_802107",
    },
    {
        label: "БАЧКИ ПЕТРОВАЦ, КУЛПИН",
        value: "БАЧКИ ПЕТРОВАЦ_КУЛПИН_80080_802131",
    },
    {
        label: "БАЧКИ ПЕТРОВАЦ, МАГЛИЋ",
        value: "БАЧКИ ПЕТРОВАЦ_МАГЛИЋ_80080_800040",
    },
    {
        label: "БЕЛА ПАЛАНКА, БАБИН КАЛ I",
        value: "БЕЛА ПАЛАНКА_БАБИН КАЛ I_70084_702927",
    },
    {
        label: "БЕЛА ПАЛАНКА, БАБИН КАЛ II",
        value: "БЕЛА ПАЛАНКА_БАБИН КАЛ II_70084_702935",
    },
    {
        label: "БЕЛА ПАЛАНКА, БЕЖИШТЕ",
        value: "БЕЛА ПАЛАНКА_БЕЖИШТЕ_70084_702943",
    },
    {
        label: "БЕЛА ПАЛАНКА, БЕЛА ПАЛАНКА-ВАН ВАРОШ",
        value: "БЕЛА ПАЛАНКА_БЕЛА ПАЛАНКА-ВАН ВАРОШ_70084_702951",
    },
    {
        label: "БЕЛА ПАЛАНКА, БЕЛА ПАЛАНКА-ВАРОШ",
        value: "БЕЛА ПАЛАНКА_БЕЛА ПАЛАНКА-ВАРОШ_70084_702960",
    },
    {
        label: "БЕЛА ПАЛАНКА, БУКУРОВАЦ",
        value: "БЕЛА ПАЛАНКА_БУКУРОВАЦ_70084_702978",
    },
    {
        label: "БЕЛА ПАЛАНКА, ВЕТА",
        value: "БЕЛА ПАЛАНКА_ВЕТА_70084_702986",
    },
    {
        label: "БЕЛА ПАЛАНКА, ВИТАНОВАЦ",
        value: "БЕЛА ПАЛАНКА_ВИТАНОВАЦ_70084_702994",
    },
    {
        label: "БЕЛА ПАЛАНКА, ВРАНДОЛ",
        value: "БЕЛА ПАЛАНКА_ВРАНДОЛ_70084_703001",
    },
    {
        label: "БЕЛА ПАЛАНКА, ВРГУДИНАЦ",
        value: "БЕЛА ПАЛАНКА_ВРГУДИНАЦ_70084_703010",
    },
    {
        label: "БЕЛА ПАЛАНКА, ГЛОГОВАЦ",
        value: "БЕЛА ПАЛАНКА_ГЛОГОВАЦ_70084_703028",
    },
    {
        label: "БЕЛА ПАЛАНКА, ГОРЊА ГЛАМА",
        value: "БЕЛА ПАЛАНКА_ГОРЊА ГЛАМА_70084_703036",
    },
    {
        label: "БЕЛА ПАЛАНКА, ГОРЊА КОРИТНИЦА",
        value: "БЕЛА ПАЛАНКА_ГОРЊА КОРИТНИЦА_70084_703044",
    },
    {
        label: "БЕЛА ПАЛАНКА, ГОРЊИ РИЊ",
        value: "БЕЛА ПАЛАНКА_ГОРЊИ РИЊ_70084_703052",
    },
    {
        label: "БЕЛА ПАЛАНКА, ГРАДИШТЕ I",
        value: "БЕЛА ПАЛАНКА_ГРАДИШТЕ I_70084_703079",
    },
    {
        label: "БЕЛА ПАЛАНКА, ГРАДИШТЕ II",
        value: "БЕЛА ПАЛАНКА_ГРАДИШТЕ II_70084_703087",
    },
    {
        label: "БЕЛА ПАЛАНКА, ДИВЉАНА",
        value: "БЕЛА ПАЛАНКА_ДИВЉАНА_70084_703095",
    },
    {
        label: "БЕЛА ПАЛАНКА, ДОЛ",
        value: "БЕЛА ПАЛАНКА_ДОЛ_70084_703109",
    },
    {
        label: "БЕЛА ПАЛАНКА, ДОЛАЦ",
        value: "БЕЛА ПАЛАНКА_ДОЛАЦ_70084_703117",
    },
    {
        label: "БЕЛА ПАЛАНКА, ДОЊА ГЛАМА",
        value: "БЕЛА ПАЛАНКА_ДОЊА ГЛАМА_70084_703125",
    },
    {
        label: "БЕЛА ПАЛАНКА, ДОЊА КОРИТНИЦА",
        value: "БЕЛА ПАЛАНКА_ДОЊА КОРИТНИЦА_70084_703133",
    },
    {
        label: "БЕЛА ПАЛАНКА, ДОЊИ РИЊ",
        value: "БЕЛА ПАЛАНКА_ДОЊИ РИЊ_70084_703141",
    },
    {
        label: "БЕЛА ПАЛАНКА, ДРАЖЕВО",
        value: "БЕЛА ПАЛАНКА_ДРАЖЕВО_70084_703150",
    },
    {
        label: "БЕЛА ПАЛАНКА, КЛЕЊЕ",
        value: "БЕЛА ПАЛАНКА_КЛЕЊЕ_70084_703168",
    },
    {
        label: "БЕЛА ПАЛАНКА, КЛИСУРА",
        value: "БЕЛА ПАЛАНКА_КЛИСУРА_70084_703176",
    },
    {
        label: "БЕЛА ПАЛАНКА, КОЗЈА",
        value: "БЕЛА ПАЛАНКА_КОЗЈА_70084_703184",
    },
    {
        label: "БЕЛА ПАЛАНКА, КОСМОВАЦ",
        value: "БЕЛА ПАЛАНКА_КОСМОВАЦ_70084_703192",
    },
    {
        label: "БЕЛА ПАЛАНКА, КРЕМЕНИЦА",
        value: "БЕЛА ПАЛАНКА_КРЕМЕНИЦА_70084_703206",
    },
    {
        label: "БЕЛА ПАЛАНКА, КРУПАЦ",
        value: "БЕЛА ПАЛАНКА_КРУПАЦ_70084_703214",
    },
    {
        label: "БЕЛА ПАЛАНКА, ЛАНИШТЕ",
        value: "БЕЛА ПАЛАНКА_ЛАНИШТЕ_70084_703222",
    },
    {
        label: "БЕЛА ПАЛАНКА, ЛЕСКОВИК",
        value: "БЕЛА ПАЛАНКА_ЛЕСКОВИК_70084_703249",
    },
    {
        label: "БЕЛА ПАЛАНКА, ЉУБАТОВИЦА",
        value: "БЕЛА ПАЛАНКА_ЉУБАТОВИЦА_70084_703257",
    },
    {
        label: "БЕЛА ПАЛАНКА, МИРАНОВАЦ",
        value: "БЕЛА ПАЛАНКА_МИРАНОВАЦ_70084_703265",
    },
    {
        label: "БЕЛА ПАЛАНКА, МОКЛИШТЕ",
        value: "БЕЛА ПАЛАНКА_МОКЛИШТЕ_70084_703273",
    },
    {
        label: "БЕЛА ПАЛАНКА, МОКРА",
        value: "БЕЛА ПАЛАНКА_МОКРА_70084_703281",
    },
    {
        label: "БЕЛА ПАЛАНКА, НОВО СЕЛО",
        value: "БЕЛА ПАЛАНКА_НОВО СЕЛО_70084_703290",
    },
    {
        label: "БЕЛА ПАЛАНКА, ОРЕОВАЦ",
        value: "БЕЛА ПАЛАНКА_ОРЕОВАЦ_70084_703303",
    },
    {
        label: "БЕЛА ПАЛАНКА, ПАЈЕЖ",
        value: "БЕЛА ПАЛАНКА_ПАЈЕЖ_70084_703311",
    },
    {
        label: "БЕЛА ПАЛАНКА, СИЊАЦ",
        value: "БЕЛА ПАЛАНКА_СИЊАЦ_70084_703320",
    },
    {
        label: "БЕЛА ПАЛАНКА, ТАМЊАНИЦА",
        value: "БЕЛА ПАЛАНКА_ТАМЊАНИЦА_70084_703338",
    },
    {
        label: "БЕЛА ПАЛАНКА, ТЕЛОВАЦ",
        value: "БЕЛА ПАЛАНКА_ТЕЛОВАЦ_70084_703346",
    },
    {
        label: "БЕЛА ПАЛАНКА, ТОПОНИЦА",
        value: "БЕЛА ПАЛАНКА_ТОПОНИЦА_70084_703354",
    },
    {
        label: "БЕЛА ПАЛАНКА, ТРЕШЊАНЦИ",
        value: "БЕЛА ПАЛАНКА_ТРЕШЊАНЦИ_70084_703362",
    },
    {
        label: "БЕЛА ПАЛАНКА, ЦРВЕНА РЕКА",
        value: "БЕЛА ПАЛАНКА_ЦРВЕНА РЕКА_70084_703389",
    },
    {
        label: "БЕЛА ПАЛАНКА, ЦРВЕНИ БРЕГ",
        value: "БЕЛА ПАЛАНКА_ЦРВЕНИ БРЕГ_70084_703397",
    },
    {
        label: "БЕЛА ПАЛАНКА, ЦРНЧЕ",
        value: "БЕЛА ПАЛАНКА_ЦРНЧЕ_70084_703419",
    },
    {
        label: "БЕЛА ПАЛАНКА, ЧИФЛИК",
        value: "БЕЛА ПАЛАНКА_ЧИФЛИК_70084_703427",
    },
    {
        label: "БЕЛА ПАЛАНКА, ШЉИВОВИК",
        value: "БЕЛА ПАЛАНКА_ШЉИВОВИК_70084_703435",
    },
    {
        label: "БЕЛА ПАЛАНКА, ШПАЈ",
        value: "БЕЛА ПАЛАНКА_ШПАЈ_70084_703443",
    },
    {
        label: "БЕЛА ЦРКВА, БАНАТСКА ПАЛАНКА I",
        value: "БЕЛА ЦРКВА_БАНАТСКА ПАЛАНКА I_80098_800317",
    },
    {
        label: "БЕЛА ЦРКВА, БАНАТСКА ПАЛАНКА II",
        value: "БЕЛА ЦРКВА_БАНАТСКА ПАЛАНКА II_80098_800325",
    },
    {
        label: "БЕЛА ЦРКВА, БАНАТСКА СУБОТИЦА",
        value: "БЕЛА ЦРКВА_БАНАТСКА СУБОТИЦА_80098_800660",
    },
    {
        label: "БЕЛА ЦРКВА, БЕЛА ЦРКВА",
        value: "БЕЛА ЦРКВА_БЕЛА ЦРКВА_80098_800333",
    },
    {
        label: "БЕЛА ЦРКВА, ВРАЧЕВ ГАЈ I",
        value: "БЕЛА ЦРКВА_ВРАЧЕВ ГАЈ I_80098_800643",
    },
    {
        label: "БЕЛА ЦРКВА, ВРАЧЕВ ГАЈ II",
        value: "БЕЛА ЦРКВА_ВРАЧЕВ ГАЈ II_80098_800732",
    },
    {
        label: "БЕЛА ЦРКВА, ГРЕБЕНАЦ",
        value: "БЕЛА ЦРКВА_ГРЕБЕНАЦ_80098_800449",
    },
    {
        label: "БЕЛА ЦРКВА, ДОБРИЧЕВО",
        value: "БЕЛА ЦРКВА_ДОБРИЧЕВО_80098_800678",
    },
    {
        label: "БЕЛА ЦРКВА, ДУПЉАЈА",
        value: "БЕЛА ЦРКВА_ДУПЉАЈА_80098_800392",
    },
    {
        label: "БЕЛА ЦРКВА, ЈАСЕНОВО",
        value: "БЕЛА ЦРКВА_ЈАСЕНОВО_80098_800465",
    },
    {
        label: "БЕЛА ЦРКВА, КАЈТАСОВО",
        value: "БЕЛА ЦРКВА_КАЈТАСОВО_80098_800473",
    },
    {
        label: "БЕЛА ЦРКВА, КАЛУЂЕРОВО I",
        value: "БЕЛА ЦРКВА_КАЛУЂЕРОВО I_80098_800481",
    },
    {
        label: "БЕЛА ЦРКВА, КАЛУЂЕРОВО II",
        value: "БЕЛА ЦРКВА_КАЛУЂЕРОВО II_80098_800686",
    },
    {
        label: "БЕЛА ЦРКВА, КАЛУЂЕРОВО III",
        value: "БЕЛА ЦРКВА_КАЛУЂЕРОВО III_80098_800694",
    },
    {
        label: "БЕЛА ЦРКВА, КРУШЧИЦА",
        value: "БЕЛА ЦРКВА_КРУШЧИЦА_80098_800503",
    },
    {
        label: "БЕЛА ЦРКВА, КУСИЋ I",
        value: "БЕЛА ЦРКВА_КУСИЋ I_80098_800708",
    },
    {
        label: "БЕЛА ЦРКВА, КУСИЋ II",
        value: "БЕЛА ЦРКВА_КУСИЋ II_80098_800716",
    },
    {
        label: "БЕЛА ЦРКВА, КУСИЋ III",
        value: "БЕЛА ЦРКВА_КУСИЋ III_80098_800724",
    },
    {
        label: "БЕЛА ЦРКВА, ЦРВЕНА ЦРКВА",
        value: "БЕЛА ЦРКВА_ЦРВЕНА ЦРКВА_80098_800341",
    },
    {
        label: "БЕЛА ЦРКВА, ЧЕШКО СЕЛО",
        value: "БЕЛА ЦРКВА_ЧЕШКО СЕЛО_80098_800350",
    },
    {
        label: "БЕОЧИН, БАНОШТОР",
        value: "БЕОЧИН_БАНОШТОР_80101_804967",
    },
    {
        label: "БЕОЧИН, БЕОЧИН",
        value: "БЕОЧИН_БЕОЧИН_80101_804975",
    },
    {
        label: "БЕОЧИН, ГРАБОВО",
        value: "БЕОЧИН_ГРАБОВО_80101_805041",
    },
    {
        label: "БЕОЧИН, ЛУГ",
        value: "БЕОЧИН_ЛУГ_80101_805068",
    },
    {
        label: "БЕОЧИН, РАКОВАЦ",
        value: "БЕОЧИН_РАКОВАЦ_80101_803260",
    },
    {
        label: "БЕОЧИН, СВИЛОШ",
        value: "БЕОЧИН_СВИЛОШ_80101_805149",
    },
    {
        label: "БЕОЧИН, СУСЕК",
        value: "БЕОЧИН_СУСЕК_80101_805122",
    },
    {
        label: "БЕОЧИН, ЧЕРЕВИЋ",
        value: "БЕОЧИН_ЧЕРЕВИЋ_80101_805009",
    },
    {
        label: "БЕЧЕЈ, БАЧКО ГРАДИШТЕ",
        value: "БЕЧЕЈ_БАЧКО ГРАДИШТЕ_80110_803456",
    },
    {
        label: "БЕЧЕЈ, БАЧКО ПЕТРОВО СЕЛО",
        value: "БЕЧЕЈ_БАЧКО ПЕТРОВО СЕЛО_80110_803464",
    },
    {
        label: "БЕЧЕЈ, БЕЧЕЈ",
        value: "БЕЧЕЈ_БЕЧЕЈ_80110_803472",
    },
    {
        label: "БЕЧЕЈ, МИЛЕШЕВО",
        value: "БЕЧЕЈ_МИЛЕШЕВО_80110_803570",
    },
    {
        label: "БЕЧЕЈ, РАДИЧЕВИЋ",
        value: "БЕЧЕЈ_РАДИЧЕВИЋ_80110_803685",
    },
    {
        label: "БЛАЦЕ, АЛАБАНА",
        value: "БЛАЦЕ_АЛАБАНА_70262_704172",
    },
    {
        label: "БЛАЦЕ, БАРБАТОВАЦ",
        value: "БЛАЦЕ_БАРБАТОВАЦ_70262_704199",
    },
    {
        label: "БЛАЦЕ, БЛАЦЕ",
        value: "БЛАЦЕ_БЛАЦЕ_70262_704202",
    },
    {
        label: "БЛАЦЕ, ВИШЕ СЕЛО",
        value: "БЛАЦЕ_ВИШЕ СЕЛО_70262_704229",
    },
    {
        label: "БЛАЦЕ, ВРБОВАЦ",
        value: "БЛАЦЕ_ВРБОВАЦ_70262_704237",
    },
    {
        label: "БЛАЦЕ, ГОРЊА ДРАГУША",
        value: "БЛАЦЕ_ГОРЊА ДРАГУША_70262_704245",
    },
    {
        label: "БЛАЦЕ, ГОРЊА ЈОШАНИЦА",
        value: "БЛАЦЕ_ГОРЊА ЈОШАНИЦА_70262_704253",
    },
    {
        label: "БЛАЦЕ, ГОРЊИ КАШЕВАР",
        value: "БЛАЦЕ_ГОРЊИ КАШЕВАР_70262_704261",
    },
    {
        label: "БЛАЦЕ, ГРГУРЕ",
        value: "БЛАЦЕ_ГРГУРЕ_70262_704270",
    },
    {
        label: "БЛАЦЕ, ДОЊА ДРАГУША",
        value: "БЛАЦЕ_ДОЊА ДРАГУША_70262_704288",
    },
    {
        label: "БЛАЦЕ, ДОЊА ЈОШАНИЦА",
        value: "БЛАЦЕ_ДОЊА ЈОШАНИЦА_70262_704296",
    },
    {
        label: "БЛАЦЕ, ДОЊА РАШИЦА",
        value: "БЛАЦЕ_ДОЊА РАШИЦА_70262_704300",
    },
    {
        label: "БЛАЦЕ, ДОЊИ КАШЕВАР",
        value: "БЛАЦЕ_ДОЊИ КАШЕВАР_70262_704318",
    },
    {
        label: "БЛАЦЕ, ЂУРЕВАЦ",
        value: "БЛАЦЕ_ЂУРЕВАЦ_70262_704326",
    },
    {
        label: "БЛАЦЕ, КАЧАПОР",
        value: "БЛАЦЕ_КАЧАПОР_70262_704334",
    },
    {
        label: "БЛАЦЕ, КРИВАЈА",
        value: "БЛАЦЕ_КРИВАЈА_70262_704342",
    },
    {
        label: "БЛАЦЕ, КУТЛОВАЦ",
        value: "БЛАЦЕ_КУТЛОВАЦ_70262_704369",
    },
    {
        label: "БЛАЦЕ, ЛАЗАРЕВАЦ",
        value: "БЛАЦЕ_ЛАЗАРЕВАЦ_70262_704377",
    },
    {
        label: "БЛАЦЕ, МЕЂУХАНА",
        value: "БЛАЦЕ_МЕЂУХАНА_70262_704385",
    },
    {
        label: "БЛАЦЕ, МУЗАЋЕ",
        value: "БЛАЦЕ_МУЗАЋЕ_70262_704393",
    },
    {
        label: "БЛАЦЕ, ПОПОВА",
        value: "БЛАЦЕ_ПОПОВА_70262_704407",
    },
    {
        label: "БЛАЦЕ, ПРЕБРЕЗА",
        value: "БЛАЦЕ_ПРЕБРЕЗА_70262_704415",
    },
    {
        label: "БЛАЦЕ, ПРЕТРЕШЊА",
        value: "БЛАЦЕ_ПРЕТРЕШЊА_70262_704423",
    },
    {
        label: "БЛАЦЕ, ПРИДВОРИЦА",
        value: "БЛАЦЕ_ПРИДВОРИЦА_70262_704431",
    },
    {
        label: "БЛАЦЕ, РАШИЦА",
        value: "БЛАЦЕ_РАШИЦА_70262_704440",
    },
    {
        label: "БЛАЦЕ, СВАРЧЕ",
        value: "БЛАЦЕ_СВАРЧЕ_70262_704458",
    },
    {
        label: "БЛАЦЕ, СИБНИЦА",
        value: "БЛАЦЕ_СИБНИЦА_70262_704466",
    },
    {
        label: "БЛАЦЕ, СТУБАЛ",
        value: "БЛАЦЕ_СТУБАЛ_70262_704474",
    },
    {
        label: "БЛАЦЕ, СУВАЈА",
        value: "БЛАЦЕ_СУВАЈА_70262_704482",
    },
    {
        label: "БЛАЦЕ, СУВИ ДО",
        value: "БЛАЦЕ_СУВИ ДО_70262_704504",
    },
    {
        label: "БЛАЦЕ, ТРБУЊЕ",
        value: "БЛАЦЕ_ТРБУЊЕ_70262_704512",
    },
    {
        label: "БЛАЦЕ, ЧУНГУЛА",
        value: "БЛАЦЕ_ЧУНГУЛА_70262_704539",
    },
    {
        label: "БЛАЦЕ, ЧУЧАЛЕ",
        value: "БЛАЦЕ_ЧУЧАЛЕ_70262_704547",
    },
    {
        label: "БЛАЦЕ, ЏЕПНИЦА",
        value: "БЛАЦЕ_ЏЕПНИЦА_70262_704555",
    },
    {
        label: "БЛАЦЕ, ШИЉОМАНА",
        value: "БЛАЦЕ_ШИЉОМАНА_70262_704563",
    },
    {
        label: "БОГАТИЋ, БАДОВИНЦИ",
        value: "БОГАТИЋ_БАДОВИНЦИ_70289_704571",
    },
    {
        label: "БОГАТИЋ, БАНОВО ПОЉЕ",
        value: "БОГАТИЋ_БАНОВО ПОЉЕ_70289_704580",
    },
    {
        label: "БОГАТИЋ, БЕЛОТИЋ",
        value: "БОГАТИЋ_БЕЛОТИЋ_70289_704601",
    },
    {
        label: "БОГАТИЋ, БОГАТИЋ",
        value: "БОГАТИЋ_БОГАТИЋ_70289_704610",
    },
    {
        label: "БОГАТИЋ, ГЛОГОВАЦ",
        value: "БОГАТИЋ_ГЛОГОВАЦ_70289_704628",
    },
    {
        label: "БОГАТИЋ, ГЛУШЦИ",
        value: "БОГАТИЋ_ГЛУШЦИ_70289_704636",
    },
    {
        label: "БОГАТИЋ, ДУБЉЕ",
        value: "БОГАТИЋ_ДУБЉЕ_70289_704652",
    },
    {
        label: "БОГАТИЋ, КЛЕЊЕ",
        value: "БОГАТИЋ_КЛЕЊЕ_70289_704695",
    },
    {
        label: "БОГАТИЋ, МЕТКОВИЋ",
        value: "БОГАТИЋ_МЕТКОВИЋ_70289_704725",
    },
    {
        label: "БОГАТИЋ, ОЧАГЕ",
        value: "БОГАТИЋ_ОЧАГЕ_70289_704733",
    },
    {
        label: "БОГАТИЋ, САЛАШ ЦРНОБАРСКИ",
        value: "БОГАТИЋ_САЛАШ ЦРНОБАРСКИ_70289_704784",
    },
    {
        label: "БОГАТИЋ, СОВЉАК",
        value: "БОГАТИЋ_СОВЉАК_70289_704806",
    },
    {
        label: "БОГАТИЋ, УЗВЕЋЕ",
        value: "БОГАТИЋ_УЗВЕЋЕ_70289_704822",
    },
    {
        label: "БОГАТИЋ, ЦРНА БАРА",
        value: "БОГАТИЋ_ЦРНА БАРА_70289_704849",
    },
    {
        label: "БОЈНИК, БОЈНИК",
        value: "БОЈНИК_БОЈНИК_70297_723355",
    },
    {
        label: "БОЈНИК, БОРИНЦЕ",
        value: "БОЈНИК_БОРИНЦЕ_70297_723363",
    },
    {
        label: "БОЈНИК, БРЕСТОВАЦ",
        value: "БОЈНИК_БРЕСТОВАЦ_70297_723398",
    },
    {
        label: "БОЈНИК, ВУЈАНОВО",
        value: "БОЈНИК_ВУЈАНОВО_70297_723452",
    },
    {
        label: "БОЈНИК, ГОРЊЕ БРИЈАЊЕ",
        value: "БОЈНИК_ГОРЊЕ БРИЈАЊЕ_70297_724815",
    },
    {
        label: "БОЈНИК, ГОРЊЕ КОЊУВЦЕ",
        value: "БОЈНИК_ГОРЊЕ КОЊУВЦЕ_70297_723541",
    },
    {
        label: "БОЈНИК, ГРАНИЦА",
        value: "БОЈНИК_ГРАНИЦА_70297_723550",
    },
    {
        label: "БОЈНИК, ДОЊЕ КОЊУВЦЕ",
        value: "БОЈНИК_ДОЊЕ КОЊУВЦЕ_70297_723622",
    },
    {
        label: "БОЈНИК, ДРАГОВАЦ",
        value: "БОЈНИК_ДРАГОВАЦ_70297_723649",
    },
    {
        label: "БОЈНИК, ДУБРАВА",
        value: "БОЈНИК_ДУБРАВА_70297_723673",
    },
    {
        label: "БОЈНИК, ЂИНЂУША",
        value: "БОЈНИК_ЂИНЂУША_70297_725099",
    },
    {
        label: "БОЈНИК, ЗЕЛЕТОВО",
        value: "БОЈНИК_ЗЕЛЕТОВО_70297_723703",
    },
    {
        label: "БОЈНИК, ЗОРОВАЦ",
        value: "БОЈНИК_ЗОРОВАЦ_70297_723711",
    },
    {
        label: "БОЈНИК, ИВАЊЕ",
        value: "БОЈНИК_ИВАЊЕ_70297_723720",
    },
    {
        label: "БОЈНИК, КАМЕНИЦА",
        value: "БОЈНИК_КАМЕНИЦА_70297_723738",
    },
    {
        label: "БОЈНИК, КАЦАБАЋ I",
        value: "БОЈНИК_КАЦАБАЋ I_70297_725269",
    },
    {
        label: "БОЈНИК, КАЦАБАЋ II",
        value: "БОЈНИК_КАЦАБАЋ II_70297_725277",
    },
    {
        label: "БОЈНИК, КОСАНЧИЋ",
        value: "БОЈНИК_КОСАНЧИЋ_70297_725293",
    },
    {
        label: "БОЈНИК, ЛАПОТИНЦЕ",
        value: "БОЈНИК_ЛАПОТИНЦЕ_70297_725331",
    },
    {
        label: "БОЈНИК, ЛОЗАНЕ",
        value: "БОЈНИК_ЛОЗАНЕ_70297_723843",
    },
    {
        label: "БОЈНИК, МАГАШ-ДОБРА ВОДА",
        value: "БОЈНИК_МАГАШ-ДОБРА ВОДА_70297_723860",
    },
    {
        label: "БОЈНИК, МАЈКОВАЦ",
        value: "БОЈНИК_МАЈКОВАЦ_70297_723878",
    },
    {
        label: "БОЈНИК, МИЈАЈЛИЦА",
        value: "БОЈНИК_МИЈАЈЛИЦА_70297_723967",
    },
    {
        label: "БОЈНИК, МРВЕШ",
        value: "БОЈНИК_МРВЕШ_70297_723975",
    },
    {
        label: "БОЈНИК, ОБИЛИЋ",
        value: "БОЈНИК_ОБИЛИЋ_70297_725501",
    },
    {
        label: "БОЈНИК, ОБРАЖДА",
        value: "БОЈНИК_ОБРАЖДА_70297_724025",
    },
    {
        label: "БОЈНИК, ОРАНЕ",
        value: "БОЈНИК_ОРАНЕ_70297_724033",
    },
    {
        label: "БОЈНИК, ПЛАВЦЕ",
        value: "БОЈНИК_ПЛАВЦЕ_70297_724084",
    },
    {
        label: "БОЈНИК, ПРИДВОРИЦА",
        value: "БОЈНИК_ПРИДВОРИЦА_70297_724149",
    },
    {
        label: "БОЈНИК, РЕЧИЦА",
        value: "БОЈНИК_РЕЧИЦА_70297_724220",
    },
    {
        label: "БОЈНИК, САВИНАЦ",
        value: "БОЈНИК_САВИНАЦ_70297_724246",
    },
    {
        label: "БОЈНИК, СЛАВНИК",
        value: "БОЈНИК_СЛАВНИК_70297_724327",
    },
    {
        label: "БОЈНИК, СТУБЛА",
        value: "БОЈНИК_СТУБЛА_70297_725706",
    },
    {
        label: "БОЈНИК, ТУРЈАНЕ",
        value: "БОЈНИК_ТУРЈАНЕ_70297_724408",
    },
    {
        label: "БОЈНИК, ЋУКОВАЦ",
        value: "БОЈНИК_ЋУКОВАЦ_70297_725765",
    },
    {
        label: "БОЈНИК, ЦРКВИЦЕ",
        value: "БОЈНИК_ЦРКВИЦЕ_70297_724432",
    },
    {
        label: "БОЉЕВАЦ, БАЧЕВИЦА",
        value: "БОЉЕВАЦ_БАЧЕВИЦА_70319_704873",
    },
    {
        label: "БОЉЕВАЦ, БОГОВИНА",
        value: "БОЉЕВАЦ_БОГОВИНА_70319_704881",
    },
    {
        label: "БОЉЕВАЦ, БОЉЕВАЦ",
        value: "БОЉЕВАЦ_БОЉЕВАЦ_70319_704890",
    },
    {
        label: "БОЉЕВАЦ, ВАЛАКОЊЕ",
        value: "БОЉЕВАЦ_ВАЛАКОЊЕ_70319_704903",
    },
    {
        label: "БОЉЕВАЦ, ВРБОВАЦ",
        value: "БОЉЕВАЦ_ВРБОВАЦ_70319_704911",
    },
    {
        label: "БОЉЕВАЦ, ДОБРО ПОЉЕ",
        value: "БОЉЕВАЦ_ДОБРО ПОЉЕ_70319_704920",
    },
    {
        label: "БОЉЕВАЦ, ДОБРУЈЕВАЦ",
        value: "БОЉЕВАЦ_ДОБРУЈЕВАЦ_70319_704938",
    },
    {
        label: "БОЉЕВАЦ, ИЛИНО",
        value: "БОЉЕВАЦ_ИЛИНО_70319_705004",
    },
    {
        label: "БОЉЕВАЦ, ЈАБЛАНИЦА",
        value: "БОЉЕВАЦ_ЈАБЛАНИЦА_70319_705012",
    },
    {
        label: "БОЉЕВАЦ, КРИВИ ВИР",
        value: "БОЉЕВАЦ_КРИВИ ВИР_70319_705039",
    },
    {
        label: "БОЉЕВАЦ, ЛУКОВО",
        value: "БОЉЕВАЦ_ЛУКОВО_70319_705055",
    },
    {
        label: "БОЉЕВАЦ, МАЛИ ИЗВОР",
        value: "БОЉЕВАЦ_МАЛИ ИЗВОР_70319_705063",
    },
    {
        label: "БОЉЕВАЦ, МИРОВО",
        value: "БОЉЕВАЦ_МИРОВО_70319_705071",
    },
    {
        label: "БОЉЕВАЦ, ОСНИЋ",
        value: "БОЉЕВАЦ_ОСНИЋ_70319_705080",
    },
    {
        label: "БОЉЕВАЦ, ПОДГОРАЦ I",
        value: "БОЉЕВАЦ_ПОДГОРАЦ I_70319_705101",
    },
    {
        label: "БОЉЕВАЦ, ПОДГОРАЦ II",
        value: "БОЉЕВАЦ_ПОДГОРАЦ II_70319_705110",
    },
    {
        label: "БОЉЕВАЦ, РУЈИШТЕ",
        value: "БОЉЕВАЦ_РУЈИШТЕ_70319_740705",
    },
    {
        label: "БОЉЕВАЦ, САВИНАЦ",
        value: "БОЉЕВАЦ_САВИНАЦ_70319_705128",
    },
    {
        label: "БОЉЕВАЦ, СУМРАКОВАЦ",
        value: "БОЉЕВАЦ_СУМРАКОВАЦ_70319_705136",
    },
    {
        label: "БОР, БОР I",
        value: "БОР_БОР I_70327_715662",
    },
    {
        label: "БОР, БОР II",
        value: "БОР_БОР II_70327_715689",
    },
    {
        label: "БОР, БРЕСТОВАЦ",
        value: "БОР_БРЕСТОВАЦ_70327_715697",
    },
    {
        label: "БОР, БУЧЈЕ",
        value: "БОР_БУЧЈЕ_70327_715719",
    },
    {
        label: "БОР, ГОРЊАНЕ",
        value: "БОР_ГОРЊАНЕ_70327_727695",
    },
    {
        label: "БОР, ДОЊА БЕЛА РЕКА",
        value: "БОР_ДОЊА БЕЛА РЕКА_70327_715808",
    },
    {
        label: "БОР, ЗЛОТ I",
        value: "БОР_ЗЛОТ I_70327_704946",
    },
    {
        label: "БОР, ЗЛОТ II",
        value: "БОР_ЗЛОТ II_70327_704954",
    },
    {
        label: "БОР, ЗЛОТ III",
        value: "БОР_ЗЛОТ III_70327_704962",
    },
    {
        label: "БОР, ЗЛОТ IV",
        value: "БОР_ЗЛОТ IV_70327_704989",
    },
    {
        label: "БОР, ЗЛОТ V",
        value: "БОР_ЗЛОТ V_70327_704997",
    },
    {
        label: "БОР, КРИВЕЉ",
        value: "БОР_КРИВЕЉ_70327_715867",
    },
    {
        label: "БОР, ЛУКА",
        value: "БОР_ЛУКА_70327_705241",
    },
    {
        label: "БОР, МЕТОВНИЦА",
        value: "БОР_МЕТОВНИЦА_70327_715905",
    },
    {
        label: "БОР, ОШТРЕЉ",
        value: "БОР_ОШТРЕЉ_70327_715921",
    },
    {
        label: "БОР, СЛАТИНА",
        value: "БОР_СЛАТИНА_70327_715956",
    },
    {
        label: "БОР, ТАНДА",
        value: "БОР_ТАНДА_70327_727776",
    },
    {
        label: "БОР, ТОПЛА",
        value: "БОР_ТОПЛА_70327_715964",
    },
    {
        label: "БОР, ШАРБАНОВАЦ",
        value: "БОР_ШАРБАНОВАЦ_70327_705144",
    },
    {
        label: "БОСИЛЕГРАД, БАРЈЕ",
        value: "БОСИЛЕГРАД_БАРЈЕ_70335_705373",
    },
    {
        label: "БОСИЛЕГРАД, БЕЛУТ",
        value: "БОСИЛЕГРАД_БЕЛУТ_70335_705381",
    },
    {
        label: "БОСИЛЕГРАД, БИСТАР",
        value: "БОСИЛЕГРАД_БИСТАР_70335_705390",
    },
    {
        label: "БОСИЛЕГРАД, БОСИЛЕГРАД I",
        value: "БОСИЛЕГРАД_БОСИЛЕГРАД I_70335_705411",
    },
    {
        label: "БОСИЛЕГРАД, БОСИЛЕГРАД II",
        value: "БОСИЛЕГРАД_БОСИЛЕГРАД II_70335_705420",
    },
    {
        label: "БОСИЛЕГРАД, БРАНКОВЦИ",
        value: "БОСИЛЕГРАД_БРАНКОВЦИ_70335_705438",
    },
    {
        label: "БОСИЛЕГРАД, БРЕСНИЦА",
        value: "БОСИЛЕГРАД_БРЕСНИЦА_70335_705446",
    },
    {
        label: "БОСИЛЕГРАД, БУЦЕЉЕВО",
        value: "БОСИЛЕГРАД_БУЦЕЉЕВО_70335_705454",
    },
    {
        label: "БОСИЛЕГРАД, ГЛОЖЈЕ",
        value: "БОСИЛЕГРАД_ГЛОЖЈЕ_70335_705462",
    },
    {
        label: "БОСИЛЕГРАД, ГОЛЕШ",
        value: "БОСИЛЕГРАД_ГОЛЕШ_70335_705489",
    },
    {
        label: "БОСИЛЕГРАД, ГОРЊА ЛИСИНА",
        value: "БОСИЛЕГРАД_ГОРЊА ЛИСИНА_70335_705497",
    },
    {
        label: "БОСИЛЕГРАД, ГОРЊА ЉУБАТА",
        value: "БОСИЛЕГРАД_ГОРЊА ЉУБАТА_70335_705519",
    },
    {
        label: "БОСИЛЕГРАД, ГОРЊА РЖАНА",
        value: "БОСИЛЕГРАД_ГОРЊА РЖАНА_70335_705527",
    },
    {
        label: "БОСИЛЕГРАД, ГОРЊЕ ТЛАМИНО",
        value: "БОСИЛЕГРАД_ГОРЊЕ ТЛАМИНО_70335_705535",
    },
    {
        label: "БОСИЛЕГРАД, ГРУЈИНЦИ",
        value: "БОСИЛЕГРАД_ГРУЈИНЦИ_70335_705551",
    },
    {
        label: "БОСИЛЕГРАД, ДОГАНИЦА",
        value: "БОСИЛЕГРАД_ДОГАНИЦА_70335_705560",
    },
    {
        label: "БОСИЛЕГРАД, ДОЊА ЛИСИНА",
        value: "БОСИЛЕГРАД_ДОЊА ЛИСИНА_70335_705578",
    },
    {
        label: "БОСИЛЕГРАД, ДОЊА ЉУБАТА",
        value: "БОСИЛЕГРАД_ДОЊА ЉУБАТА_70335_705586",
    },
    {
        label: "БОСИЛЕГРАД, ДОЊА РЖАНА",
        value: "БОСИЛЕГРАД_ДОЊА РЖАНА_70335_705594",
    },
    {
        label: "БОСИЛЕГРАД, ДОЊЕ ТЛАМИНО",
        value: "БОСИЛЕГРАД_ДОЊЕ ТЛАМИНО_70335_705608",
    },
    {
        label: "БОСИЛЕГРАД, ДУКАТ",
        value: "БОСИЛЕГРАД_ДУКАТ_70335_705624",
    },
    {
        label: "БОСИЛЕГРАД, ЖЕРАВИНО",
        value: "БОСИЛЕГРАД_ЖЕРАВИНО_70335_705632",
    },
    {
        label: "БОСИЛЕГРАД, ЗЛИ ДОЛ",
        value: "БОСИЛЕГРАД_ЗЛИ ДОЛ_70335_705659",
    },
    {
        label: "БОСИЛЕГРАД, ИЗВОР",
        value: "БОСИЛЕГРАД_ИЗВОР_70335_705667",
    },
    {
        label: "БОСИЛЕГРАД, ЈАРЕШНИК",
        value: "БОСИЛЕГРАД_ЈАРЕШНИК_70335_705675",
    },
    {
        label: "БОСИЛЕГРАД, КАРАМАНИЦА",
        value: "БОСИЛЕГРАД_КАРАМАНИЦА_70335_705683",
    },
    {
        label: "БОСИЛЕГРАД, МИЛЕВЦИ",
        value: "БОСИЛЕГРАД_МИЛЕВЦИ_70335_705721",
    },
    {
        label: "БОСИЛЕГРАД, МЛЕКОМИНЦИ",
        value: "БОСИЛЕГРАД_МЛЕКОМИНЦИ_70335_705730",
    },
    {
        label: "БОСИЛЕГРАД, МУСУЉ",
        value: "БОСИЛЕГРАД_МУСУЉ_70335_705748",
    },
    {
        label: "БОСИЛЕГРАД, НАЗАРИЦА",
        value: "БОСИЛЕГРАД_НАЗАРИЦА_70335_705756",
    },
    {
        label: "БОСИЛЕГРАД, ПАРАЛОВО",
        value: "БОСИЛЕГРАД_ПАРАЛОВО_70335_705772",
    },
    {
        label: "БОСИЛЕГРАД, ПЛОЧА",
        value: "БОСИЛЕГРАД_ПЛОЧА_70335_705799",
    },
    {
        label: "БОСИЛЕГРАД, РАДИЧЕВЦИ",
        value: "БОСИЛЕГРАД_РАДИЧЕВЦИ_70335_705802",
    },
    {
        label: "БОСИЛЕГРАД, РАЈЧИЛОВЦИ",
        value: "БОСИЛЕГРАД_РАЈЧИЛОВЦИ_70335_705829",
    },
    {
        label: "БОСИЛЕГРАД, РЕСЕН",
        value: "БОСИЛЕГРАД_РЕСЕН_70335_705837",
    },
    {
        label: "БОСИЛЕГРАД, РИБАРЦИ",
        value: "БОСИЛЕГРАД_РИБАРЦИ_70335_705845",
    },
    {
        label: "БОСИЛЕГРАД, РИКАЧЕВО",
        value: "БОСИЛЕГРАД_РИКАЧЕВО_70335_705853",
    },
    {
        label: "БОСИЛЕГРАД, ЦРНОШТИЦА",
        value: "БОСИЛЕГРАД_ЦРНОШТИЦА_70335_705896",
    },
    {
        label: "БРУС, БАТОТЕ",
        value: "БРУС_БАТОТЕ_70343_705900",
    },
    {
        label: "БРУС, БЕЛО ПОЉЕ",
        value: "БРУС_БЕЛО ПОЉЕ_70343_705918",
    },
    {
        label: "БРУС, БЛАЖЕВО",
        value: "БРУС_БЛАЖЕВО_70343_705926",
    },
    {
        label: "БРУС, БОГИШЕ",
        value: "БРУС_БОГИШЕ_70343_705934",
    },
    {
        label: "БРУС, БОЗОЉИН",
        value: "БРУС_БОЗОЉИН_70343_705942",
    },
    {
        label: "БРУС, БОРАНЦИ",
        value: "БРУС_БОРАНЦИ_70343_705969",
    },
    {
        label: "БРУС, БОТУЊА",
        value: "БРУС_БОТУЊА_70343_705977",
    },
    {
        label: "БРУС, БРЂАНИ",
        value: "БРУС_БРЂАНИ_70343_705985",
    },
    {
        label: "БРУС, БРЗЕЋЕ",
        value: "БРУС_БРЗЕЋЕ_70343_705993",
    },
    {
        label: "БРУС, БРУС",
        value: "БРУС_БРУС_70343_706019",
    },
    {
        label: "БРУС, БУДИЛОВИНА",
        value: "БРУС_БУДИЛОВИНА_70343_706027",
    },
    {
        label: "БРУС, ВЕЛИКА ГРАБОВНИЦА",
        value: "БРУС_ВЕЛИКА ГРАБОВНИЦА_70343_706035",
    },
    {
        label: "БРУС, ВИТОШЕ",
        value: "БРУС_ВИТОШЕ_70343_706043",
    },
    {
        label: "БРУС, ВЛАЈКОВЦИ",
        value: "БРУС_ВЛАЈКОВЦИ_70343_706051",
    },
    {
        label: "БРУС, ГОРЊЕ ЛЕВИЋЕ",
        value: "БРУС_ГОРЊЕ ЛЕВИЋЕ_70343_706060",
    },
    {
        label: "БРУС, ГОРЊИ ЛИПОВАЦ",
        value: "БРУС_ГОРЊИ ЛИПОВАЦ_70343_706078",
    },
    {
        label: "БРУС, ГОЧМАНЦИ",
        value: "БРУС_ГОЧМАНЦИ_70343_706086",
    },
    {
        label: "БРУС, ГРАД",
        value: "БРУС_ГРАД_70343_706094",
    },
    {
        label: "БРУС, ГРАДАЦ",
        value: "БРУС_ГРАДАЦ_70343_706108",
    },
    {
        label: "БРУС, ГРАШЕВЦИ",
        value: "БРУС_ГРАШЕВЦИ_70343_706116",
    },
    {
        label: "БРУС, ДОМИШЕВИНА",
        value: "БРУС_ДОМИШЕВИНА_70343_706124",
    },
    {
        label: "БРУС, ДОЊЕ ЛЕВИЋЕ",
        value: "БРУС_ДОЊЕ ЛЕВИЋЕ_70343_706132",
    },
    {
        label: "БРУС, ДОЊИ ЛИПОВАЦ",
        value: "БРУС_ДОЊИ ЛИПОВАЦ_70343_706159",
    },
    {
        label: "БРУС, ДРЕНОВА",
        value: "БРУС_ДРЕНОВА_70343_706167",
    },
    {
        label: "БРУС, ДРТЕВЦИ",
        value: "БРУС_ДРТЕВЦИ_70343_706175",
    },
    {
        label: "БРУС, ДУПЦИ",
        value: "БРУС_ДУПЦИ_70343_706183",
    },
    {
        label: "БРУС, ЂЕРЕКАРЕ",
        value: "БРУС_ЂЕРЕКАРЕ_70343_706191",
    },
    {
        label: "БРУС, ЖАРЕВО",
        value: "БРУС_ЖАРЕВО_70343_706205",
    },
    {
        label: "БРУС, ЖИЛИНЦИ",
        value: "БРУС_ЖИЛИНЦИ_70343_706213",
    },
    {
        label: "БРУС, ЖИЉЦИ",
        value: "БРУС_ЖИЉЦИ_70343_706221",
    },
    {
        label: "БРУС, ЖУЊЕ",
        value: "БРУС_ЖУЊЕ_70343_706230",
    },
    {
        label: "БРУС, ЗЛАТАРИ",
        value: "БРУС_ЗЛАТАРИ_70343_706248",
    },
    {
        label: "БРУС, ИГРОШ",
        value: "БРУС_ИГРОШ_70343_706256",
    },
    {
        label: "БРУС, ИРИЧИЋИ",
        value: "БРУС_ИРИЧИЋИ_70343_706264",
    },
    {
        label: "БРУС, КНЕЖЕВО",
        value: "БРУС_КНЕЖЕВО_70343_706272",
    },
    {
        label: "БРУС, КОБИЉЕ",
        value: "БРУС_КОБИЉЕ_70343_706299",
    },
    {
        label: "БРУС, КОВИЗЛЕ",
        value: "БРУС_КОВИЗЛЕ_70343_706302",
    },
    {
        label: "БРУС, КОЧИНЕ",
        value: "БРУС_КОЧИНЕ_70343_706329",
    },
    {
        label: "БРУС, КРИВА РЕКА",
        value: "БРУС_КРИВА РЕКА_70343_706337",
    },
    {
        label: "БРУС, ЛЕПЕНАЦ",
        value: "БРУС_ЛЕПЕНАЦ_70343_706345",
    },
    {
        label: "БРУС, ЛИВАЂЕ",
        value: "БРУС_ЛИВАЂЕ_70343_706353",
    },
    {
        label: "БРУС, МАЛА ВРБНИЦА",
        value: "БРУС_МАЛА ВРБНИЦА_70343_706361",
    },
    {
        label: "БРУС, МАЛА ГРАБОВНИЦА",
        value: "БРУС_МАЛА ГРАБОВНИЦА_70343_706370",
    },
    {
        label: "БРУС, МАЧКОВАЦ",
        value: "БРУС_МАЧКОВАЦ_70343_706388",
    },
    {
        label: "БРУС, МИЛЕНТИЈА",
        value: "БРУС_МИЛЕНТИЈА_70343_706396",
    },
    {
        label: "БРУС, ОСРЕДЦИ",
        value: "БРУС_ОСРЕДЦИ_70343_706400",
    },
    {
        label: "БРУС, ПАЉЕВШТИЦА",
        value: "БРУС_ПАЉЕВШТИЦА_70343_706418",
    },
    {
        label: "БРУС, ПОКРП",
        value: "БРУС_ПОКРП_70343_706426",
    },
    {
        label: "БРУС, РАВНИ",
        value: "БРУС_РАВНИ_70343_706434",
    },
    {
        label: "БРУС, РАВНИШТЕ",
        value: "БРУС_РАВНИШТЕ_70343_706442",
    },
    {
        label: "БРУС, РАДМАНОВО",
        value: "БРУС_РАДМАНОВО_70343_706469",
    },
    {
        label: "БРУС, РАДУЊЕ",
        value: "БРУС_РАДУЊЕ_70343_706477",
    },
    {
        label: "БРУС, РАЗБОЈНА",
        value: "БРУС_РАЗБОЈНА_70343_706485",
    },
    {
        label: "БРУС, РИБАРИ",
        value: "БРУС_РИБАРИ_70343_706493",
    },
    {
        label: "БРУС, СТАНУЛОВИЋИ",
        value: "БРУС_СТАНУЛОВИЋИ_70343_706507",
    },
    {
        label: "БРУС, СТРОЈИНЦИ",
        value: "БРУС_СТРОЈИНЦИ_70343_706515",
    },
    {
        label: "БРУС, СУДИМЉА",
        value: "БРУС_СУДИМЉА_70343_706523",
    },
    {
        label: "БРУС, ТРШАНОВЦИ",
        value: "БРУС_ТРШАНОВЦИ_70343_706531",
    },
    {
        label: "БРУС, ЦРВЕНА ЈАБУКА",
        value: "БРУС_ЦРВЕНА ЈАБУКА_70343_706540",
    },
    {
        label: "БРУС, ЧОКОТАР",
        value: "БРУС_ЧОКОТАР_70343_706558",
    },
    {
        label: "БРУС, ШОШИЋЕ",
        value: "БРУС_ШОШИЋЕ_70343_706566",
    },
    {
        label: "БУЈАНОВАЦ, БАРАЉЕВАЦ",
        value: "БУЈАНОВАЦ_БАРАЉЕВАЦ_70351_706582",
    },
    {
        label: "БУЈАНОВАЦ, БИЉАЧА",
        value: "БУЈАНОВАЦ_БИЉАЧА_70351_706639",
    },
    {
        label: "БУЈАНОВАЦ, БОГДАНОВАЦ",
        value: "БУЈАНОВАЦ_БОГДАНОВАЦ_70351_706647",
    },
    {
        label: "БУЈАНОВАЦ, БОЖИЊЕВАЦ I",
        value: "БУЈАНОВАЦ_БОЖИЊЕВАЦ I_70351_706655",
    },
    {
        label: "БУЈАНОВАЦ, БОЖИЊЕВАЦ II",
        value: "БУЈАНОВАЦ_БОЖИЊЕВАЦ II_70351_706663",
    },
    {
        label: "БУЈАНОВАЦ, БОРОВАЦ",
        value: "БУЈАНОВАЦ_БОРОВАЦ_70351_706671",
    },
    {
        label: "БУЈАНОВАЦ, БРАТОСЕЛЦЕ",
        value: "БУЈАНОВАЦ_БРАТОСЕЛЦЕ_70351_706680",
    },
    {
        label: "БУЈАНОВАЦ, БРЕЗНИЦА",
        value: "БУЈАНОВАЦ_БРЕЗНИЦА_70351_706698",
    },
    {
        label: "БУЈАНОВАЦ, БРЊАРЕ",
        value: "БУЈАНОВАЦ_БРЊАРЕ_70351_706701",
    },
    {
        label: "БУЈАНОВАЦ, БУЈАНОВАЦ",
        value: "БУЈАНОВАЦ_БУЈАНОВАЦ_70351_706710",
    },
    {
        label: "БУЈАНОВАЦ, ВЕЛИКИ ТРНОВАЦ",
        value: "БУЈАНОВАЦ_ВЕЛИКИ ТРНОВАЦ_70351_706787",
    },
    {
        label: "БУЈАНОВАЦ, ВОГАНЦЕ",
        value: "БУЈАНОВАЦ_ВОГАНЦЕ_70351_706809",
    },
    {
        label: "БУЈАНОВАЦ, ВРБАН",
        value: "БУЈАНОВАЦ_ВРБАН_70351_706817",
    },
    {
        label: "БУЈАНОВАЦ, ГОРЊЕ НОВО СЕЛО",
        value: "БУЈАНОВАЦ_ГОРЊЕ НОВО СЕЛО_70351_706850",
    },
    {
        label: "БУЈАНОВАЦ, ГРАМАДА",
        value: "БУЈАНОВАЦ_ГРАМАДА_70351_706876",
    },
    {
        label: "БУЈАНОВАЦ, ДОБРОСИН",
        value: "БУЈАНОВАЦ_ДОБРОСИН_70351_706892",
    },
    {
        label: "БУЈАНОВАЦ, ДОЊЕ НОВО СЕЛО",
        value: "БУЈАНОВАЦ_ДОЊЕ НОВО СЕЛО_70351_706914",
    },
    {
        label: "БУЈАНОВАЦ, ДРЕЖНИЦА",
        value: "БУЈАНОВАЦ_ДРЕЖНИЦА_70351_706922",
    },
    {
        label: "БУЈАНОВАЦ, ЂОРЂЕВАЦ",
        value: "БУЈАНОВАЦ_ЂОРЂЕВАЦ_70351_706949",
    },
    {
        label: "БУЈАНОВАЦ, ЖБЕВАЦ",
        value: "БУЈАНОВАЦ_ЖБЕВАЦ_70351_706957",
    },
    {
        label: "БУЈАНОВАЦ, ЖУЖЕЉИЦА",
        value: "БУЈАНОВАЦ_ЖУЖЕЉИЦА_70351_706965",
    },
    {
        label: "БУЈАНОВАЦ, ЗАРБИНЦЕ",
        value: "БУЈАНОВАЦ_ЗАРБИНЦЕ_70351_706990",
    },
    {
        label: "БУЈАНОВАЦ, ЈАБЛАНИЦА",
        value: "БУЈАНОВАЦ_ЈАБЛАНИЦА_70351_707015",
    },
    {
        label: "БУЈАНОВАЦ, ЈАСТРЕБАЦ",
        value: "БУЈАНОВАЦ_ЈАСТРЕБАЦ_70351_707023",
    },
    {
        label: "БУЈАНОВАЦ, КАРАДНИК",
        value: "БУЈАНОВАЦ_КАРАДНИК_70351_707031",
    },
    {
        label: "БУЈАНОВАЦ, КЛЕНИКЕ",
        value: "БУЈАНОВАЦ_КЛЕНИКЕ_70351_707040",
    },
    {
        label: "БУЈАНОВАЦ, КЛИНОВАЦ",
        value: "БУЈАНОВАЦ_КЛИНОВАЦ_70351_707058",
    },
    {
        label: "БУЈАНОВАЦ, КОНЧУЉ",
        value: "БУЈАНОВАЦ_КОНЧУЉ_70351_707066",
    },
    {
        label: "БУЈАНОВАЦ, КОШАРНО",
        value: "БУЈАНОВАЦ_КОШАРНО_70351_707074",
    },
    {
        label: "БУЈАНОВАЦ, КРШЕВИЦА",
        value: "БУЈАНОВАЦ_КРШЕВИЦА_70351_707082",
    },
    {
        label: "БУЈАНОВАЦ, КУШТИЦА",
        value: "БУЈАНОВАЦ_КУШТИЦА_70351_707112",
    },
    {
        label: "БУЈАНОВАЦ, ЛЕВОСОЈЕ",
        value: "БУЈАНОВАЦ_ЛЕВОСОЈЕ_70351_707139",
    },
    {
        label: "БУЈАНОВАЦ, ЛЕТОВИЦА",
        value: "БУЈАНОВАЦ_ЛЕТОВИЦА_70351_707147",
    },
    {
        label: "БУЈАНОВАЦ, ЛОПАРДИНЦЕ",
        value: "БУЈАНОВАЦ_ЛОПАРДИНЦЕ_70351_707155",
    },
    {
        label: "БУЈАНОВАЦ, ЛУКАРЦЕ",
        value: "БУЈАНОВАЦ_ЛУКАРЦЕ_70351_707163",
    },
    {
        label: "БУЈАНОВАЦ, ЛУЧАНЕ",
        value: "БУЈАНОВАЦ_ЛУЧАНЕ_70351_707171",
    },
    {
        label: "БУЈАНОВАЦ, ЉИЉАНЦЕ",
        value: "БУЈАНОВАЦ_ЉИЉАНЦЕ_70351_707198",
    },
    {
        label: "БУЈАНОВАЦ, МАЛО БУШТРАЊЕ",
        value: "БУЈАНОВАЦ_МАЛО БУШТРАЊЕ_70351_707228",
    },
    {
        label: "БУЈАНОВАЦ, МУХОВАЦ",
        value: "БУЈАНОВАЦ_МУХОВАЦ_70351_707252",
    },
    {
        label: "БУЈАНОВАЦ, НЕГОВАЦ",
        value: "БУЈАНОВАЦ_НЕГОВАЦ_70351_707279",
    },
    {
        label: "БУЈАНОВАЦ, НЕСАЛЦЕ",
        value: "БУЈАНОВАЦ_НЕСАЛЦЕ_70351_707287",
    },
    {
        label: "БУЈАНОВАЦ, ОСЛАРЕ",
        value: "БУЈАНОВАЦ_ОСЛАРЕ_70351_707325",
    },
    {
        label: "БУЈАНОВАЦ, ПЕТКА",
        value: "БУЈАНОВАЦ_ПЕТКА_70351_707333",
    },
    {
        label: "БУЈАНОВАЦ, ПРЕТИНА",
        value: "БУЈАНОВАЦ_ПРЕТИНА_70351_707368",
    },
    {
        label: "БУЈАНОВАЦ, ПРИБОВЦЕ",
        value: "БУЈАНОВАЦ_ПРИБОВЦЕ_70351_707384",
    },
    {
        label: "БУЈАНОВАЦ, РАВНО БУЧЈЕ",
        value: "БУЈАНОВАЦ_РАВНО БУЧЈЕ_70351_707392",
    },
    {
        label: "БУЈАНОВАЦ, РАКОВАЦ",
        value: "БУЈАНОВАЦ_РАКОВАЦ_70351_707422",
    },
    {
        label: "БУЈАНОВАЦ, РУСЦЕ",
        value: "БУЈАНОВАЦ_РУСЦЕ_70351_707465",
    },
    {
        label: "БУЈАНОВАЦ, САМОЉИЦА",
        value: "БУЈАНОВАЦ_САМОЉИЦА_70351_707473",
    },
    {
        label: "БУЈАНОВАЦ, СЕБРАТ",
        value: "БУЈАНОВАЦ_СЕБРАТ_70351_707490",
    },
    {
        label: "БУЈАНОВАЦ, СЕЈАЦЕ",
        value: "БУЈАНОВАЦ_СЕЈАЦЕ_70351_707503",
    },
    {
        label: "БУЈАНОВАЦ, СПАНЧЕВАЦ",
        value: "БУЈАНОВАЦ_СПАНЧЕВАЦ_70351_707538",
    },
    {
        label: "БУЈАНОВАЦ, СРПСКА КУЋА",
        value: "БУЈАНОВАЦ_СРПСКА КУЋА_70351_707546",
    },
    {
        label: "БУЈАНОВАЦ, СТАРАЦ",
        value: "БУЈАНОВАЦ_СТАРАЦ_70351_707562",
    },
    {
        label: "БУЈАНОВАЦ, СУХАРНО",
        value: "БУЈАНОВАЦ_СУХАРНО_70351_707597",
    },
    {
        label: "БУЈАНОВАЦ, ТРЕЈАК",
        value: "БУЈАНОВАЦ_ТРЕЈАК_70351_707619",
    },
    {
        label: "БУЈАНОВАЦ, ТУРИЈА",
        value: "БУЈАНОВАЦ_ТУРИЈА_70351_707635",
    },
    {
        label: "БУЈАНОВАЦ, УЗОВО",
        value: "БУЈАНОВАЦ_УЗОВО_70351_707643",
    },
    {
        label: "БУЈАНОВАЦ, ЧАР",
        value: "БУЈАНОВАЦ_ЧАР_70351_707686",
    },
    {
        label: "ВАЉЕВО, БАБИНА ЛУКА",
        value: "ВАЉЕВО_БАБИНА ЛУКА_70360_707716",
    },
    {
        label: "ВАЉЕВО, БАЛИНОВИЋ",
        value: "ВАЉЕВО_БАЛИНОВИЋ_70360_707724",
    },
    {
        label: "ВАЉЕВО, БАЧЕВЦИ",
        value: "ВАЉЕВО_БАЧЕВЦИ_70360_707732",
    },
    {
        label: "ВАЉЕВО, БЕЛИЋ",
        value: "ВАЉЕВО_БЕЛИЋ_70360_707759",
    },
    {
        label: "ВАЉЕВО, БЕЛОШЕВАЦ",
        value: "ВАЉЕВО_БЕЛОШЕВАЦ_70360_707767",
    },
    {
        label: "ВАЉЕВО, БЕОМУЖЕВИЋ",
        value: "ВАЉЕВО_БЕОМУЖЕВИЋ_70360_731862",
    },
    {
        label: "ВАЉЕВО, БЛИЗОЊЕ",
        value: "ВАЉЕВО_БЛИЗОЊЕ_70360_707775",
    },
    {
        label: "ВАЉЕВО, БОБОВА",
        value: "ВАЉЕВО_БОБОВА_70360_731889",
    },
    {
        label: "ВАЉЕВО, БОГАТИЋ",
        value: "ВАЉЕВО_БОГАТИЋ_70360_707783",
    },
    {
        label: "ВАЉЕВО, БРАНГОВИЋ",
        value: "ВАЉЕВО_БРАНГОВИЋ_70360_707791",
    },
    {
        label: "ВАЉЕВО, БРАНКОВИНА",
        value: "ВАЉЕВО_БРАНКОВИНА_70360_707805",
    },
    {
        label: "ВАЉЕВО, БРЕЗОВИЦЕ",
        value: "ВАЉЕВО_БРЕЗОВИЦЕ_70360_707813",
    },
    {
        label: "ВАЉЕВО, БУЈАЧИЋ",
        value: "ВАЉЕВО_БУЈАЧИЋ_70360_707821",
    },
    {
        label: "ВАЉЕВО, ВАЉЕВО",
        value: "ВАЉЕВО_ВАЉЕВО_70360_707830",
    },
    {
        label: "ВАЉЕВО, ВЕСЕЛИНОВАЦ",
        value: "ВАЉЕВО_ВЕСЕЛИНОВАЦ_70360_707848",
    },
    {
        label: "ВАЉЕВО, ВЛАШЧИЋ",
        value: "ВАЉЕВО_ВЛАШЧИЋ_70360_731919",
    },
    {
        label: "ВАЉЕВО, ВРАГОЧАНИЦА",
        value: "ВАЉЕВО_ВРАГОЧАНИЦА_70360_731927",
    },
    {
        label: "ВАЉЕВО, ВУЈИНОВАЧА",
        value: "ВАЉЕВО_ВУЈИНОВАЧА_70360_707856",
    },
    {
        label: "ВАЉЕВО, ГОЛА ГЛАВА",
        value: "ВАЉЕВО_ГОЛА ГЛАВА_70360_731935",
    },
    {
        label: "ВАЉЕВО, ГОРЊА БУКОВИЦА",
        value: "ВАЉЕВО_ГОРЊА БУКОВИЦА_70360_731943",
    },
    {
        label: "ВАЉЕВО, ГОРЊЕ ЛЕСКОВИЦЕ",
        value: "ВАЉЕВО_ГОРЊЕ ЛЕСКОВИЦЕ_70360_707864",
    },
    {
        label: "ВАЉЕВО, ГРАБОВИЦА",
        value: "ВАЉЕВО_ГРАБОВИЦА_70360_707872",
    },
    {
        label: "ВАЉЕВО, ДЕГУРИЋ",
        value: "ВАЉЕВО_ДЕГУРИЋ_70360_707899",
    },
    {
        label: "ВАЉЕВО, ДИВЦИ",
        value: "ВАЉЕВО_ДИВЦИ_70360_707902",
    },
    {
        label: "ВАЉЕВО, ДИВЧИБАРЕ",
        value: "ВАЉЕВО_ДИВЧИБАРЕ_70360_707929",
    },
    {
        label: "ВАЉЕВО, ДОЊА БУКОВИЦА",
        value: "ВАЉЕВО_ДОЊА БУКОВИЦА_70360_731960",
    },
    {
        label: "ВАЉЕВО, ДОЊЕ ЛЕСКОВИЦЕ",
        value: "ВАЉЕВО_ДОЊЕ ЛЕСКОВИЦЕ_70360_707937",
    },
    {
        label: "ВАЉЕВО, ДРАЧИЋ",
        value: "ВАЉЕВО_ДРАЧИЋ_70360_707945",
    },
    {
        label: "ВАЉЕВО, ДУПЉАЈ",
        value: "ВАЉЕВО_ДУПЉАЈ_70360_707953",
    },
    {
        label: "ВАЉЕВО, ЖАБАРИ",
        value: "ВАЉЕВО_ЖАБАРИ_70360_707961",
    },
    {
        label: "ВАЉЕВО, ЗАБРДИЦА",
        value: "ВАЉЕВО_ЗАБРДИЦА_70360_707970",
    },
    {
        label: "ВАЉЕВО, ЗАРУБЕ",
        value: "ВАЉЕВО_ЗАРУБЕ_70360_707988",
    },
    {
        label: "ВАЉЕВО, ЗЛАТАРИЋ",
        value: "ВАЉЕВО_ЗЛАТАРИЋ_70360_707996",
    },
    {
        label: "ВАЉЕВО, ЈАСЕНИЦА",
        value: "ВАЉЕВО_ЈАСЕНИЦА_70360_708003",
    },
    {
        label: "ВАЉЕВО, ЈОШЕВА",
        value: "ВАЉЕВО_ЈОШЕВА_70360_708011",
    },
    {
        label: "ВАЉЕВО, КАМЕНИЦА",
        value: "ВАЉЕВО_КАМЕНИЦА_70360_731994",
    },
    {
        label: "ВАЉЕВО, КЛАНИЦА",
        value: "ВАЉЕВО_КЛАНИЦА_70360_708020",
    },
    {
        label: "ВАЉЕВО, КЛИНЦИ",
        value: "ВАЉЕВО_КЛИНЦИ_70360_708038",
    },
    {
        label: "ВАЉЕВО, КОВАЧИЦЕ",
        value: "ВАЉЕВО_КОВАЧИЦЕ_70360_708046",
    },
    {
        label: "ВАЉЕВО, КОЗЛИЧИЋ",
        value: "ВАЉЕВО_КОЗЛИЧИЋ_70360_708054",
    },
    {
        label: "ВАЉЕВО, КОТЕШИЦА",
        value: "ВАЉЕВО_КОТЕШИЦА_70360_708062",
    },
    {
        label: "ВАЉЕВО, КУНИЦЕ",
        value: "ВАЉЕВО_КУНИЦЕ_70360_708089",
    },
    {
        label: "ВАЉЕВО, ЛЕЛИЋ",
        value: "ВАЉЕВО_ЛЕЛИЋ_70360_708097",
    },
    {
        label: "ВАЉЕВО, ЛОЗНИЦА",
        value: "ВАЉЕВО_ЛОЗНИЦА_70360_708119",
    },
    {
        label: "ВАЉЕВО, ЛУКАВАЦ",
        value: "ВАЉЕВО_ЛУКАВАЦ_70360_708127",
    },
    {
        label: "ВАЉЕВО, МАЈИНОВИЋ",
        value: "ВАЉЕВО_МАЈИНОВИЋ_70360_732010",
    },
    {
        label: "ВАЉЕВО, МИЛИЧИНИЦА",
        value: "ВАЉЕВО_МИЛИЧИНИЦА_70360_732028",
    },
    {
        label: "ВАЉЕВО, МРЧИЋ",
        value: "ВАЉЕВО_МРЧИЋ_70360_728128",
    },
    {
        label: "ВАЉЕВО, ОГЛАЂЕНОВАЦ",
        value: "ВАЉЕВО_ОГЛАЂЕНОВАЦ_70360_732036",
    },
    {
        label: "ВАЉЕВО, ОСЛАДИЋ",
        value: "ВАЉЕВО_ОСЛАДИЋ_70360_732052",
    },
    {
        label: "ВАЉЕВО, ПАКЉЕ",
        value: "ВАЉЕВО_ПАКЉЕ_70360_708135",
    },
    {
        label: "ВАЉЕВО, ПАУНЕ",
        value: "ВАЉЕВО_ПАУНЕ_70360_728179",
    },
    {
        label: "ВАЉЕВО, ПЕТНИЦА",
        value: "ВАЉЕВО_ПЕТНИЦА_70360_708143",
    },
    {
        label: "ВАЉЕВО, ПОПУЧКЕ",
        value: "ВАЉЕВО_ПОПУЧКЕ_70360_708151",
    },
    {
        label: "ВАЉЕВО, ПРИЈЕЗДИЋ",
        value: "ВАЉЕВО_ПРИЈЕЗДИЋ_70360_708160",
    },
    {
        label: "ВАЉЕВО, ПРИЧЕВИЋ",
        value: "ВАЉЕВО_ПРИЧЕВИЋ_70360_732095",
    },
    {
        label: "ВАЉЕВО, РАБАС",
        value: "ВАЉЕВО_РАБАС_70360_708178",
    },
    {
        label: "ВАЉЕВО, РАВЊЕ",
        value: "ВАЉЕВО_РАВЊЕ_70360_708186",
    },
    {
        label: "ВАЉЕВО, РАЂЕВО СЕЛО",
        value: "ВАЉЕВО_РАЂЕВО СЕЛО_70360_708194",
    },
    {
        label: "ВАЉЕВО, РЕБЕЉ",
        value: "ВАЉЕВО_РЕБЕЉ_70360_708208",
    },
    {
        label: "ВАЉЕВО, РОВНИ",
        value: "ВАЉЕВО_РОВНИ_70360_708216",
    },
    {
        label: "ВАЉЕВО, САНДАЉ",
        value: "ВАЉЕВО_САНДАЉ_70360_708224",
    },
    {
        label: "ВАЉЕВО, СЕДЛАРИ",
        value: "ВАЉЕВО_СЕДЛАРИ_70360_708232",
    },
    {
        label: "ВАЉЕВО, СИТАРИЦЕ",
        value: "ВАЉЕВО_СИТАРИЦЕ_70360_732117",
    },
    {
        label: "ВАЉЕВО, СОВАЧ",
        value: "ВАЉЕВО_СОВАЧ_70360_708267",
    },
    {
        label: "ВАЉЕВО, СТАНИНА РЕКА",
        value: "ВАЉЕВО_СТАНИНА РЕКА_70360_732125",
    },
    {
        label: "ВАЉЕВО, СТАПАР",
        value: "ВАЉЕВО_СТАПАР_70360_732133",
    },
    {
        label: "ВАЉЕВО, СТРМНА ГОРА",
        value: "ВАЉЕВО_СТРМНА ГОРА_70360_708275",
    },
    {
        label: "ВАЉЕВО, СТУБО",
        value: "ВАЉЕВО_СТУБО_70360_708283",
    },
    {
        label: "ВАЉЕВО, СУВОДАЊЕ",
        value: "ВАЉЕВО_СУВОДАЊЕ_70360_732141",
    },
    {
        label: "ВАЉЕВО, СУШИЦА",
        value: "ВАЉЕВО_СУШИЦА_70360_708291",
    },
    {
        label: "ВАЉЕВО, ТАОР",
        value: "ВАЉЕВО_ТАОР_70360_719056",
    },
    {
        label: "ВАЉЕВО, ТУБРАВИЋ",
        value: "ВАЉЕВО_ТУБРАВИЋ_70360_708305",
    },
    {
        label: "ВАЉЕВО, ТУПАНЦИ",
        value: "ВАЉЕВО_ТУПАНЦИ_70360_732168",
    },
    {
        label: "ВАРВАРИН, БАЧИНА",
        value: "ВАРВАРИН_БАЧИНА_70378_708313",
    },
    {
        label: "ВАРВАРИН, БОШЊАНЕ",
        value: "ВАРВАРИН_БОШЊАНЕ_70378_708321",
    },
    {
        label: "ВАРВАРИН, ВАРВАРИН (ВАРОШ)",
        value: "ВАРВАРИН_ВАРВАРИН (ВАРОШ)_70378_708330",
    },
    {
        label: "ВАРВАРИН, ВАРВАРИН (СЕЛО)",
        value: "ВАРВАРИН_ВАРВАРИН (СЕЛО)_70378_708348",
    },
    {
        label: "ВАРВАРИН, ГОРЊИ КАТУН",
        value: "ВАРВАРИН_ГОРЊИ КАТУН_70378_708356",
    },
    {
        label: "ВАРВАРИН, ГОРЊИ КРЧИН",
        value: "ВАРВАРИН_ГОРЊИ КРЧИН_70378_708364",
    },
    {
        label: "ВАРВАРИН, ДОЊИ КАТУН",
        value: "ВАРВАРИН_ДОЊИ КАТУН_70378_708372",
    },
    {
        label: "ВАРВАРИН, ДОЊИ КРЧИН",
        value: "ВАРВАРИН_ДОЊИ КРЧИН_70378_708399",
    },
    {
        label: "ВАРВАРИН, ЗАЛОГОВАЦ",
        value: "ВАРВАРИН_ЗАЛОГОВАЦ_70378_708402",
    },
    {
        label: "ВАРВАРИН, ИЗБЕНИЦА",
        value: "ВАРВАРИН_ИЗБЕНИЦА_70378_708429",
    },
    {
        label: "ВАРВАРИН, КАРАНОВАЦ",
        value: "ВАРВАРИН_КАРАНОВАЦ_70378_708437",
    },
    {
        label: "ВАРВАРИН, МАЛА КРУШЕВИЦА",
        value: "ВАРВАРИН_МАЛА КРУШЕВИЦА_70378_708445",
    },
    {
        label: "ВАРВАРИН, МАРЕНОВО",
        value: "ВАРВАРИН_МАРЕНОВО_70378_708453",
    },
    {
        label: "ВАРВАРИН, МАСКАРЕ",
        value: "ВАРВАРИН_МАСКАРЕ_70378_708461",
    },
    {
        label: "ВАРВАРИН, ОБРЕЖ",
        value: "ВАРВАРИН_ОБРЕЖ_70378_708470",
    },
    {
        label: "ВАРВАРИН, ОРАШЈЕ",
        value: "ВАРВАРИН_ОРАШЈЕ_70378_708488",
    },
    {
        label: "ВАРВАРИН, ПАЈКОВАЦ",
        value: "ВАРВАРИН_ПАЈКОВАЦ_70378_708496",
    },
    {
        label: "ВАРВАРИН, ПАРЦАНЕ",
        value: "ВАРВАРИН_ПАРЦАНЕ_70378_708500",
    },
    {
        label: "ВАРВАРИН, СУВАЈА",
        value: "ВАРВАРИН_СУВАЈА_70378_708542",
    },
    {
        label: "ВАРВАРИН, ТОЉЕВАЦ",
        value: "ВАРВАРИН_ТОЉЕВАЦ_70378_708569",
    },
    {
        label: "ВАРВАРИН, ЦЕРНИЦА",
        value: "ВАРВАРИН_ЦЕРНИЦА_70378_708577",
    },
    {
        label: "ВЕЛИКА ПЛАНА, ВЕЛИКА ПЛАНА I",
        value: "ВЕЛИКА ПЛАНА_ВЕЛИКА ПЛАНА I_70386_708585",
    },
    {
        label: "ВЕЛИКА ПЛАНА, ВЕЛИКА ПЛАНА II",
        value: "ВЕЛИКА ПЛАНА_ВЕЛИКА ПЛАНА II_70386_708593",
    },
    {
        label: "ВЕЛИКА ПЛАНА, ВЕЛИКО ОРАШЈЕ",
        value: "ВЕЛИКА ПЛАНА_ВЕЛИКО ОРАШЈЕ_70386_708607",
    },
    {
        label: "ВЕЛИКА ПЛАНА, ДОЊА ЛИВАДИЦА",
        value: "ВЕЛИКА ПЛАНА_ДОЊА ЛИВАДИЦА_70386_714194",
    },
    {
        label: "ВЕЛИКА ПЛАНА, КРЊЕВО",
        value: "ВЕЛИКА ПЛАНА_КРЊЕВО_70386_708615",
    },
    {
        label: "ВЕЛИКА ПЛАНА, ЛОЗОВИК",
        value: "ВЕЛИКА ПЛАНА_ЛОЗОВИК_70386_708623",
    },
    {
        label: "ВЕЛИКА ПЛАНА, МАРКОВАЦ",
        value: "ВЕЛИКА ПЛАНА_МАРКОВАЦ_70386_708640",
    },
    {
        label: "ВЕЛИКА ПЛАНА, МИЛОШЕВАЦ",
        value: "ВЕЛИКА ПЛАНА_МИЛОШЕВАЦ_70386_708658",
    },
    {
        label: "ВЕЛИКА ПЛАНА, НОВО СЕЛО I",
        value: "ВЕЛИКА ПЛАНА_НОВО СЕЛО I_70386_745049",
    },
    {
        label: "ВЕЛИКА ПЛАНА, НОВО СЕЛО II",
        value: "ВЕЛИКА ПЛАНА_НОВО СЕЛО II_70386_745057",
    },
    {
        label: "ВЕЛИКА ПЛАНА, РАДОВАЊЕ",
        value: "ВЕЛИКА ПЛАНА_РАДОВАЊЕ_70386_708674",
    },
    {
        label: "ВЕЛИКА ПЛАНА, РАКИНАЦ",
        value: "ВЕЛИКА ПЛАНА_РАКИНАЦ_70386_708682",
    },
    {
        label: "ВЕЛИКА ПЛАНА, СТАРО СЕЛО",
        value: "ВЕЛИКА ПЛАНА_СТАРО СЕЛО_70386_708712",
    },
    {
        label: "ВЕЛИКА ПЛАНА, ТРНОВЧЕ",
        value: "ВЕЛИКА ПЛАНА_ТРНОВЧЕ_70386_708739",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, БИСКУПЉЕ",
        value: "ВЕЛИКО ГРАДИШТЕ_БИСКУПЉЕ_70394_708747",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ВЕЛИКО ГРАДИШТЕ",
        value: "ВЕЛИКО ГРАДИШТЕ_ВЕЛИКО ГРАДИШТЕ_70394_708763",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ГАРЕВО",
        value: "ВЕЛИКО ГРАДИШТЕ_ГАРЕВО_70394_708771",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ДЕСИНА",
        value: "ВЕЛИКО ГРАДИШТЕ_ДЕСИНА_70394_708780",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ДОЉАШНИЦА",
        value: "ВЕЛИКО ГРАДИШТЕ_ДОЉАШНИЦА_70394_708798",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ЂУРАКОВО",
        value: "ВЕЛИКО ГРАДИШТЕ_ЂУРАКОВО_70394_708801",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ЗАТОЊЕ",
        value: "ВЕЛИКО ГРАДИШТЕ_ЗАТОЊЕ_70394_708810",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, КАМИЈЕВО",
        value: "ВЕЛИКО ГРАДИШТЕ_КАМИЈЕВО_70394_708828",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, КИСИЉЕВО",
        value: "ВЕЛИКО ГРАДИШТЕ_КИСИЉЕВО_70394_708836",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, КУМАНЕ",
        value: "ВЕЛИКО ГРАДИШТЕ_КУМАНЕ_70394_708852",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, КУРЈАЧЕ",
        value: "ВЕЛИКО ГРАДИШТЕ_КУРЈАЧЕ_70394_708879",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, КУСИЋЕ",
        value: "ВЕЛИКО ГРАДИШТЕ_КУСИЋЕ_70394_708887",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ЉУБИЊЕ",
        value: "ВЕЛИКО ГРАДИШТЕ_ЉУБИЊЕ_70394_708895",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, МАЈИЛОВАЦ",
        value: "ВЕЛИКО ГРАДИШТЕ_МАЈИЛОВАЦ_70394_708909",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, МАКЦЕ",
        value: "ВЕЛИКО ГРАДИШТЕ_МАКЦЕ_70394_708917",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ОСТРОВО",
        value: "ВЕЛИКО ГРАДИШТЕ_ОСТРОВО_70394_708941",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ПЕЧАНИЦА",
        value: "ВЕЛИКО ГРАДИШТЕ_ПЕЧАНИЦА_70394_708950",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ПОЖЕЖЕНО",
        value: "ВЕЛИКО ГРАДИШТЕ_ПОЖЕЖЕНО_70394_708968",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ПОПОВАЦ",
        value: "ВЕЛИКО ГРАДИШТЕ_ПОПОВАЦ_70394_708976",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, РАМ",
        value: "ВЕЛИКО ГРАДИШТЕ_РАМ_70394_708992",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, СИРАКОВО",
        value: "ВЕЛИКО ГРАДИШТЕ_СИРАКОВО_70394_709018",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, СРЕДЊЕВО",
        value: "ВЕЛИКО ГРАДИШТЕ_СРЕДЊЕВО_70394_709026",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ТОПОЛОВНИК",
        value: "ВЕЛИКО ГРАДИШТЕ_ТОПОЛОВНИК_70394_709034",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ТРИБРОДЕ",
        value: "ВЕЛИКО ГРАДИШТЕ_ТРИБРОДЕ_70394_709042",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ЦАРЕВАЦ",
        value: "ВЕЛИКО ГРАДИШТЕ_ЦАРЕВАЦ_70394_709069",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ, ЧЕШЉЕВА БАРА",
        value: "ВЕЛИКО ГРАДИШТЕ_ЧЕШЉЕВА БАРА_70394_709077",
    },
    {
        label: "ВЛАДИМИРЦИ, БЕЛОТИЋ",
        value: "ВЛАДИМИРЦИ_БЕЛОТИЋ_70408_709085",
    },
    {
        label: "ВЛАДИМИРЦИ, БЕЉИН",
        value: "ВЛАДИМИРЦИ_БЕЉИН_70408_709093",
    },
    {
        label: "ВЛАДИМИРЦИ, БОБОВИК",
        value: "ВЛАДИМИРЦИ_БОБОВИК_70408_709107",
    },
    {
        label: "ВЛАДИМИРЦИ, ВЛАДИМИРЦИ",
        value: "ВЛАДИМИРЦИ_ВЛАДИМИРЦИ_70408_709131",
    },
    {
        label: "ВЛАДИМИРЦИ, ВЛАДИМИРЦИ СЕЛО",
        value: "ВЛАДИМИРЦИ_ВЛАДИМИРЦИ СЕЛО_70408_745227",
    },
    {
        label: "ВЛАДИМИРЦИ, ВЛАСАНИЦА",
        value: "ВЛАДИМИРЦИ_ВЛАСАНИЦА_70408_709158",
    },
    {
        label: "ВЛАДИМИРЦИ, ВУКОШИЋ",
        value: "ВЛАДИМИРЦИ_ВУКОШИЋ_70408_709166",
    },
    {
        label: "ВЛАДИМИРЦИ, ВУЧЕВИЦА",
        value: "ВЛАДИМИРЦИ_ВУЧЕВИЦА_70408_709174",
    },
    {
        label: "ВЛАДИМИРЦИ, ДЕБРЦ",
        value: "ВЛАДИМИРЦИ_ДЕБРЦ_70408_709239",
    },
    {
        label: "ВЛАДИМИРЦИ, ДРАГОЈЕВАЦ",
        value: "ВЛАДИМИРЦИ_ДРАГОЈЕВАЦ_70408_744743",
    },
    {
        label: "ВЛАДИМИРЦИ, ЗВЕЗД",
        value: "ВЛАДИМИРЦИ_ЗВЕЗД_70408_709263",
    },
    {
        label: "ВЛАДИМИРЦИ, ЈАЗОВНИК",
        value: "ВЛАДИМИРЦИ_ЈАЗОВНИК_70408_709271",
    },
    {
        label: "ВЛАДИМИРЦИ, ЈАЛОВИК",
        value: "ВЛАДИМИРЦИ_ЈАЛОВИК_70408_709280",
    },
    {
        label: "ВЛАДИМИРЦИ, КАОНА",
        value: "ВЛАДИМИРЦИ_КАОНА_70408_709301",
    },
    {
        label: "ВЛАДИМИРЦИ, КОЗАРИЦА",
        value: "ВЛАДИМИРЦИ_КОЗАРИЦА_70408_709310",
    },
    {
        label: "ВЛАДИМИРЦИ, КРНИЋ",
        value: "ВЛАДИМИРЦИ_КРНИЋ_70408_709344",
    },
    {
        label: "ВЛАДИМИРЦИ, КРНУЛЕ",
        value: "ВЛАДИМИРЦИ_КРНУЛЕ_70408_709352",
    },
    {
        label: "ВЛАДИМИРЦИ, КУЈАВИЦА",
        value: "ВЛАДИМИРЦИ_КУЈАВИЦА_70408_709379",
    },
    {
        label: "ВЛАДИМИРЦИ, ЛОЈАНИЦЕ",
        value: "ВЛАДИМИРЦИ_ЛОЈАНИЦЕ_70408_709387",
    },
    {
        label: "ВЛАДИМИРЦИ, МАТИЈЕВАЦ",
        value: "ВЛАДИМИРЦИ_МАТИЈЕВАЦ_70408_709417",
    },
    {
        label: "ВЛАДИМИРЦИ, МЕСАРЦИ",
        value: "ВЛАДИМИРЦИ_МЕСАРЦИ_70408_709425",
    },
    {
        label: "ВЛАДИМИРЦИ, МЕХОВИНЕ",
        value: "ВЛАДИМИРЦИ_МЕХОВИНЕ_70408_709433",
    },
    {
        label: "ВЛАДИМИРЦИ, МРОВСКА",
        value: "ВЛАДИМИРЦИ_МРОВСКА_70408_709441",
    },
    {
        label: "ВЛАДИМИРЦИ, НОВО СЕЛО",
        value: "ВЛАДИМИРЦИ_НОВО СЕЛО_70408_709450",
    },
    {
        label: "ВЛАДИМИРЦИ, ПЕЈИНОВИЋ",
        value: "ВЛАДИМИРЦИ_ПЕЈИНОВИЋ_70408_709468",
    },
    {
        label: "ВЛАДИМИРЦИ, ПРОВО",
        value: "ВЛАДИМИРЦИ_ПРОВО_70408_709476",
    },
    {
        label: "ВЛАДИМИРЦИ, РИЂАКЕ",
        value: "ВЛАДИМИРЦИ_РИЂАКЕ_70408_709484",
    },
    {
        label: "ВЛАДИМИРЦИ, СКУПЉЕН",
        value: "ВЛАДИМИРЦИ_СКУПЉЕН_70408_709506",
    },
    {
        label: "ВЛАДИМИРЦИ, СУВО СЕЛО",
        value: "ВЛАДИМИРЦИ_СУВО СЕЛО_70408_709522",
    },
    {
        label: "ВЛАДИМИРЦИ, ТРБУШАЦ",
        value: "ВЛАДИМИРЦИ_ТРБУШАЦ_70408_744999",
    },
    {
        label: "ВЛАДИЧИН ХАН, БАЛИНОВЦЕ",
        value: "ВЛАДИЧИН ХАН_БАЛИНОВЦЕ_70416_709565",
    },
    {
        label: "ВЛАДИЧИН ХАН, БАЧВИШТЕ",
        value: "ВЛАДИЧИН ХАН_БАЧВИШТЕ_70416_709573",
    },
    {
        label: "ВЛАДИЧИН ХАН, БЕЛАНОВЦЕ",
        value: "ВЛАДИЧИН ХАН_БЕЛАНОВЦЕ_70416_709581",
    },
    {
        label: "ВЛАДИЧИН ХАН, БЕЛИШЕВО",
        value: "ВЛАДИЧИН ХАН_БЕЛИШЕВО_70416_709590",
    },
    {
        label: "ВЛАДИЧИН ХАН, БОГОШЕВО",
        value: "ВЛАДИЧИН ХАН_БОГОШЕВО_70416_709603",
    },
    {
        label: "ВЛАДИЧИН ХАН, БРЕСТОВО",
        value: "ВЛАДИЧИН ХАН_БРЕСТОВО_70416_709611",
    },
    {
        label: "ВЛАДИЧИН ХАН, ВЛАДИЧИН ХАН",
        value: "ВЛАДИЧИН ХАН_ВЛАДИЧИН ХАН_70416_709638",
    },
    {
        label: "ВЛАДИЧИН ХАН, ВРБОВО",
        value: "ВЛАДИЧИН ХАН_ВРБОВО_70416_709646",
    },
    {
        label: "ВЛАДИЧИН ХАН, ГАРИЊЕ",
        value: "ВЛАДИЧИН ХАН_ГАРИЊЕ_70416_709654",
    },
    {
        label: "ВЛАДИЧИН ХАН, ГОРЊЕ ЈАБУКОВО",
        value: "ВЛАДИЧИН ХАН_ГОРЊЕ ЈАБУКОВО_70416_709662",
    },
    {
        label: "ВЛАДИЧИН ХАН, ГРАМАЂЕ",
        value: "ВЛАДИЧИН ХАН_ГРАМАЂЕ_70416_709689",
    },
    {
        label: "ВЛАДИЧИН ХАН, ДЕКУТИНЦЕ",
        value: "ВЛАДИЧИН ХАН_ДЕКУТИНЦЕ_70416_709697",
    },
    {
        label: "ВЛАДИЧИН ХАН, ДОЊА КОЗНИЦА",
        value: "ВЛАДИЧИН ХАН_ДОЊА КОЗНИЦА_70416_709719",
    },
    {
        label: "ВЛАДИЧИН ХАН, ДОЊЕ ЈАБУКОВО",
        value: "ВЛАДИЧИН ХАН_ДОЊЕ ЈАБУКОВО_70416_709727",
    },
    {
        label: "ВЛАДИЧИН ХАН, ДУПЉАНЕ",
        value: "ВЛАДИЧИН ХАН_ДУПЉАНЕ_70416_709735",
    },
    {
        label: "ВЛАДИЧИН ХАН, ЖИТОРАЂЕ",
        value: "ВЛАДИЧИН ХАН_ЖИТОРАЂЕ_70416_709743",
    },
    {
        label: "ВЛАДИЧИН ХАН, ЗЕБИНЦЕ",
        value: "ВЛАДИЧИН ХАН_ЗЕБИНЦЕ_70416_709751",
    },
    {
        label: "ВЛАДИЧИН ХАН, ЈАГЊИЛО",
        value: "ВЛАДИЧИН ХАН_ЈАГЊИЛО_70416_709760",
    },
    {
        label: "ВЛАДИЧИН ХАН, ЈАСТРЕБАЦ",
        value: "ВЛАДИЧИН ХАН_ЈАСТРЕБАЦ_70416_709778",
    },
    {
        label: "ВЛАДИЧИН ХАН, ЈОВАЦ",
        value: "ВЛАДИЧИН ХАН_ЈОВАЦ_70416_709786",
    },
    {
        label: "ВЛАДИЧИН ХАН, КАЛИМАНЦЕ",
        value: "ВЛАДИЧИН ХАН_КАЛИМАНЦЕ_70416_709794",
    },
    {
        label: "ВЛАДИЧИН ХАН, КАЦАПУН",
        value: "ВЛАДИЧИН ХАН_КАЦАПУН_70416_709808",
    },
    {
        label: "ВЛАДИЧИН ХАН, КОПИТАРЦЕ",
        value: "ВЛАДИЧИН ХАН_КОПИТАРЦЕ_70416_709816",
    },
    {
        label: "ВЛАДИЧИН ХАН, КОСТОМЛАТИЦА",
        value: "ВЛАДИЧИН ХАН_КОСТОМЛАТИЦА_70416_709832",
    },
    {
        label: "ВЛАДИЧИН ХАН, КРЖИНЦЕ",
        value: "ВЛАДИЧИН ХАН_КРЖИНЦЕ_70416_709859",
    },
    {
        label: "ВЛАДИЧИН ХАН, КУКАВИЦА",
        value: "ВЛАДИЧИН ХАН_КУКАВИЦА_70416_709867",
    },
    {
        label: "ВЛАДИЧИН ХАН, КУНОВО",
        value: "ВЛАДИЧИН ХАН_КУНОВО_70416_709875",
    },
    {
        label: "ВЛАДИЧИН ХАН, ЛЕБЕТ",
        value: "ВЛАДИЧИН ХАН_ЛЕБЕТ_70416_741019",
    },
    {
        label: "ВЛАДИЧИН ХАН, ЛЕПЕНИЦА",
        value: "ВЛАДИЧИН ХАН_ЛЕПЕНИЦА_70416_709883",
    },
    {
        label: "ВЛАДИЧИН ХАН, ЛЕТОВИШТЕ",
        value: "ВЛАДИЧИН ХАН_ЛЕТОВИШТЕ_70416_709891",
    },
    {
        label: "ВЛАДИЧИН ХАН, ЉУТЕЖ",
        value: "ВЛАДИЧИН ХАН_ЉУТЕЖ_70416_709905",
    },
    {
        label: "ВЛАДИЧИН ХАН, МАЗАРАЋ",
        value: "ВЛАДИЧИН ХАН_МАЗАРАЋ_70416_709913",
    },
    {
        label: "ВЛАДИЧИН ХАН, МАНАЈЛЕ",
        value: "ВЛАДИЧИН ХАН_МАНАЈЛЕ_70416_709921",
    },
    {
        label: "ВЛАДИЧИН ХАН, МАЊАК",
        value: "ВЛАДИЧИН ХАН_МАЊАК_70416_709930",
    },
    {
        label: "ВЛАДИЧИН ХАН, МРТВИЦА",
        value: "ВЛАДИЧИН ХАН_МРТВИЦА_70416_709956",
    },
    {
        label: "ВЛАДИЧИН ХАН, ОСТРОВИЦА",
        value: "ВЛАДИЧИН ХАН_ОСТРОВИЦА_70416_709964",
    },
    {
        label: "ВЛАДИЧИН ХАН, ПОЛОМ",
        value: "ВЛАДИЧИН ХАН_ПОЛОМ_70416_709972",
    },
    {
        label: "ВЛАДИЧИН ХАН, ПРЕКОДОЛЦЕ",
        value: "ВЛАДИЧИН ХАН_ПРЕКОДОЛЦЕ_70416_710016",
    },
    {
        label: "ВЛАДИЧИН ХАН, ПРИБОЈ",
        value: "ВЛАДИЧИН ХАН_ПРИБОЈ_70416_710024",
    },
    {
        label: "ВЛАДИЧИН ХАН, РАВНА РЕКА",
        value: "ВЛАДИЧИН ХАН_РАВНА РЕКА_70416_710032",
    },
    {
        label: "ВЛАДИЧИН ХАН, РДОВО",
        value: "ВЛАДИЧИН ХАН_РДОВО_70416_710059",
    },
    {
        label: "ВЛАДИЧИН ХАН, РЕПИНЦЕ",
        value: "ВЛАДИЧИН ХАН_РЕПИНЦЕ_70416_710067",
    },
    {
        label: "ВЛАДИЧИН ХАН, РЕПИШТЕ",
        value: "ВЛАДИЧИН ХАН_РЕПИШТЕ_70416_710075",
    },
    {
        label: "ВЛАДИЧИН ХАН, РУЖИЋ",
        value: "ВЛАДИЧИН ХАН_РУЖИЋ_70416_710083",
    },
    {
        label: "ВЛАДИЧИН ХАН, СОЛАЧКА СЕНА",
        value: "ВЛАДИЧИН ХАН_СОЛАЧКА СЕНА_70416_710091",
    },
    {
        label: "ВЛАДИЧИН ХАН, СРНЕЋИ ДОЛ",
        value: "ВЛАДИЧИН ХАН_СРНЕЋИ ДОЛ_70416_710105",
    },
    {
        label: "ВЛАДИЧИН ХАН, СТУБАЛ",
        value: "ВЛАДИЧИН ХАН_СТУБАЛ_70416_710113",
    },
    {
        label: "ВЛАДИЧИН ХАН, СУВА МОРАВА",
        value: "ВЛАДИЧИН ХАН_СУВА МОРАВА_70416_710121",
    },
    {
        label: "ВЛАДИЧИН ХАН, ТЕГОВИШТЕ",
        value: "ВЛАДИЧИН ХАН_ТЕГОВИШТЕ_70416_710148",
    },
    {
        label: "ВЛАДИЧИН ХАН, УРВИЧ",
        value: "ВЛАДИЧИН ХАН_УРВИЧ_70416_710156",
    },
    {
        label: "ВЛАДИЧИН ХАН, ЏЕП",
        value: "ВЛАДИЧИН ХАН_ЏЕП_70416_710172",
    },
    {
        label: "ВЛАСОТИНЦЕ, АЛЕКСИНЕ",
        value: "ВЛАСОТИНЦЕ_АЛЕКСИНЕ_70424_710199",
    },
    {
        label: "ВЛАСОТИНЦЕ, БАТУЛОВЦЕ",
        value: "ВЛАСОТИНЦЕ_БАТУЛОВЦЕ_70424_710237",
    },
    {
        label: "ВЛАСОТИНЦЕ, БОЉАРЕ",
        value: "ВЛАСОТИНЦЕ_БОЉАРЕ_70424_710253",
    },
    {
        label: "ВЛАСОТИНЦЕ, БОРИН ДО",
        value: "ВЛАСОТИНЦЕ_БОРИН ДО_70424_710261",
    },
    {
        label: "ВЛАСОТИНЦЕ, БРЕЗОВИЦА",
        value: "ВЛАСОТИНЦЕ_БРЕЗОВИЦА_70424_710288",
    },
    {
        label: "ВЛАСОТИНЦЕ, ВЛАСОТИНЦЕ-ВАН ВАРОШ",
        value: "ВЛАСОТИНЦЕ_ВЛАСОТИНЦЕ-ВАН ВАРОШ_70424_710318",
    },
    {
        label: "ВЛАСОТИНЦЕ, ВЛАСОТИНЦЕ-ВАРОШ",
        value: "ВЛАСОТИНЦЕ_ВЛАСОТИНЦЕ-ВАРОШ_70424_710326",
    },
    {
        label: "ВЛАСОТИНЦЕ, ГЛОЖАНЕ",
        value: "ВЛАСОТИНЦЕ_ГЛОЖАНЕ_70424_710334",
    },
    {
        label: "ВЛАСОТИНЦЕ, ГОРЊА ЛОМНИЦА",
        value: "ВЛАСОТИНЦЕ_ГОРЊА ЛОМНИЦА_70424_710342",
    },
    {
        label: "ВЛАСОТИНЦЕ, ГОРЊА ЛОПУШЊА",
        value: "ВЛАСОТИНЦЕ_ГОРЊА ЛОПУШЊА_70424_710369",
    },
    {
        label: "ВЛАСОТИНЦЕ, ГОРЊИ ОРАХ",
        value: "ВЛАСОТИНЦЕ_ГОРЊИ ОРАХ_70424_710385",
    },
    {
        label: "ВЛАСОТИНЦЕ, ГОРЊИ ПРИСЈАН",
        value: "ВЛАСОТИНЦЕ_ГОРЊИ ПРИСЈАН_70424_710393",
    },
    {
        label: "ВЛАСОТИНЦЕ, ГРАДИШТЕ",
        value: "ВЛАСОТИНЦЕ_ГРАДИШТЕ_70424_710407",
    },
    {
        label: "ВЛАСОТИНЦЕ, ГУЊЕТИНА",
        value: "ВЛАСОТИНЦЕ_ГУЊЕТИНА_70424_710440",
    },
    {
        label: "ВЛАСОТИНЦЕ, ДАДИНЦЕ",
        value: "ВЛАСОТИНЦЕ_ДАДИНЦЕ_70424_710458",
    },
    {
        label: "ВЛАСОТИНЦЕ, ДЕЈАН",
        value: "ВЛАСОТИНЦЕ_ДЕЈАН_70424_710482",
    },
    {
        label: "ВЛАСОТИНЦЕ, ДОБРОВИШ",
        value: "ВЛАСОТИНЦЕ_ДОБРОВИШ_70424_710504",
    },
    {
        label: "ВЛАСОТИНЦЕ, ДОЊА ЛОМНИЦА",
        value: "ВЛАСОТИНЦЕ_ДОЊА ЛОМНИЦА_70424_710547",
    },
    {
        label: "ВЛАСОТИНЦЕ, ДОЊА ЛОПУШЊА",
        value: "ВЛАСОТИНЦЕ_ДОЊА ЛОПУШЊА_70424_710555",
    },
    {
        label: "ВЛАСОТИНЦЕ, ДОЊЕ ГАРЕ",
        value: "ВЛАСОТИНЦЕ_ДОЊЕ ГАРЕ_70424_710563",
    },
    {
        label: "ВЛАСОТИНЦЕ, ДОЊИ ПРИСЈАН",
        value: "ВЛАСОТИНЦЕ_ДОЊИ ПРИСЈАН_70424_710571",
    },
    {
        label: "ВЛАСОТИНЦЕ, ЈАВОРЈЕ",
        value: "ВЛАСОТИНЦЕ_ЈАВОРЈЕ_70424_710598",
    },
    {
        label: "ВЛАСОТИНЦЕ, ЈАКОВЉЕВО",
        value: "ВЛАСОТИНЦЕ_ЈАКОВЉЕВО_70424_710601",
    },
    {
        label: "ВЛАСОТИНЦЕ, ЈАСТРЕБАЦ",
        value: "ВЛАСОТИНЦЕ_ЈАСТРЕБАЦ_70424_710610",
    },
    {
        label: "ВЛАСОТИНЦЕ, КОЗИЛО",
        value: "ВЛАСОТИНЦЕ_КОЗИЛО_70424_710652",
    },
    {
        label: "ВЛАСОТИНЦЕ, КОМАРИЦА",
        value: "ВЛАСОТИНЦЕ_КОМАРИЦА_70424_710679",
    },
    {
        label: "ВЛАСОТИНЦЕ, КОНОПНИЦА",
        value: "ВЛАСОТИНЦЕ_КОНОПНИЦА_70424_710687",
    },
    {
        label: "ВЛАСОТИНЦЕ, КРУШЕВИЦА",
        value: "ВЛАСОТИНЦЕ_КРУШЕВИЦА_70424_710717",
    },
    {
        label: "ВЛАСОТИНЦЕ, ЛАДОВИЦА",
        value: "ВЛАСОТИНЦЕ_ЛАДОВИЦА_70424_710725",
    },
    {
        label: "ВЛАСОТИНЦЕ, ЛИПОВИЦА",
        value: "ВЛАСОТИНЦЕ_ЛИПОВИЦА_70424_710733",
    },
    {
        label: "ВЛАСОТИНЦЕ, ОРАШЈЕ",
        value: "ВЛАСОТИНЦЕ_ОРАШЈЕ_70424_710776",
    },
    {
        label: "ВЛАСОТИНЦЕ, ОСТРЦ",
        value: "ВЛАСОТИНЦЕ_ОСТРЦ_70424_710792",
    },
    {
        label: "ВЛАСОТИНЦЕ, ПРЖОЈНЕ",
        value: "ВЛАСОТИНЦЕ_ПРЖОЈНЕ_70424_710849",
    },
    {
        label: "ВЛАСОТИНЦЕ, ПРИЛЕПАЦ",
        value: "ВЛАСОТИНЦЕ_ПРИЛЕПАЦ_70424_710857",
    },
    {
        label: "ВЛАСОТИНЦЕ, РАВНА ГОРА",
        value: "ВЛАСОТИНЦЕ_РАВНА ГОРА_70424_710865",
    },
    {
        label: "ВЛАСОТИНЦЕ, РАВНИ ДЕЛ",
        value: "ВЛАСОТИНЦЕ_РАВНИ ДЕЛ_70424_710881",
    },
    {
        label: "ВЛАСОТИНЦЕ, САМАРНИЦА",
        value: "ВЛАСОТИНЦЕ_САМАРНИЦА_70424_710920",
    },
    {
        label: "ВЛАСОТИНЦЕ, СВОЂЕ",
        value: "ВЛАСОТИНЦЕ_СВОЂЕ_70424_710938",
    },
    {
        label: "ВЛАСОТИНЦЕ, СКРАПЕЖ",
        value: "ВЛАСОТИНЦЕ_СКРАПЕЖ_70424_710954",
    },
    {
        label: "ВЛАСОТИНЦЕ, СРЕДОР",
        value: "ВЛАСОТИНЦЕ_СРЕДОР_70424_710962",
    },
    {
        label: "ВЛАСОТИНЦЕ, СТАЈКОВЦЕ",
        value: "ВЛАСОТИНЦЕ_СТАЈКОВЦЕ_70424_710989",
    },
    {
        label: "ВЛАСОТИНЦЕ, СТРАЊЕВО",
        value: "ВЛАСОТИНЦЕ_СТРАЊЕВО_70424_710997",
    },
    {
        label: "ВЛАСОТИНЦЕ, СТРЕШКОВАЦ",
        value: "ВЛАСОТИНЦЕ_СТРЕШКОВАЦ_70424_711004",
    },
    {
        label: "ВЛАСОТИНЦЕ, ЋЕЛИШТЕ",
        value: "ВЛАСОТИНЦЕ_ЋЕЛИШТЕ_70424_711039",
    },
    {
        label: "ВЛАСОТИНЦЕ, ЦРНА БАРА",
        value: "ВЛАСОТИНЦЕ_ЦРНА БАРА_70424_711055",
    },
    {
        label: "ВЛАСОТИНЦЕ, ЦРНАТОВО",
        value: "ВЛАСОТИНЦЕ_ЦРНАТОВО_70424_711071",
    },
    {
        label: "ВЛАСОТИНЦЕ, ШИШАВЕ",
        value: "ВЛАСОТИНЦЕ_ШИШАВЕ_70424_711080",
    },
    {
        label: "ВОЖДОВАЦ, БЕЛИ ПОТОК",
        value: "ВОЖДОВАЦ_БЕЛИ ПОТОК_70106_703494",
    },
    {
        label: "ВОЖДОВАЦ, ВОЖДОВАЦ",
        value: "ВОЖДОВАЦ_ВОЖДОВАЦ_70106_703621",
    },
    {
        label: "ВОЖДОВАЦ, ЗУЦЕ",
        value: "ВОЖДОВАЦ_ЗУЦЕ_70106_703753",
    },
    {
        label: "ВОЖДОВАЦ, ЈАЈИНЦИ",
        value: "ВОЖДОВАЦ_ЈАЈИНЦИ_70106_703761",
    },
    {
        label: "ВОЖДОВАЦ, КУМОДРАЖ",
        value: "ВОЖДОВАЦ_КУМОДРАЖ_70106_703800",
    },
    {
        label: "ВОЖДОВАЦ, ПИНОСАВА",
        value: "ВОЖДОВАЦ_ПИНОСАВА_70106_703915",
    },
    {
        label: "ВОЖДОВАЦ, РАКОВИЦА СЕЛО",
        value: "ВОЖДОВАЦ_РАКОВИЦА СЕЛО_70106_703931",
    },
    {
        label: "ВОЖДОВАЦ, РИПАЊ",
        value: "ВОЖДОВАЦ_РИПАЊ_70106_703958",
    },
    {
        label: "ВРАЊЕ, АЛЕКСАНДРОВАЦ",
        value: "ВРАЊЕ_АЛЕКСАНДРОВАЦ_70432_711098",
    },
    {
        label: "ВРАЊЕ, БАРБАРУШИНЦЕ",
        value: "ВРАЊЕ_БАРБАРУШИНЦЕ_70432_711128",
    },
    {
        label: "ВРАЊЕ, БАРЕЛИЋ",
        value: "ВРАЊЕ_БАРЕЛИЋ_70432_711136",
    },
    {
        label: "ВРАЊЕ, БЕЛИ БРЕГ",
        value: "ВРАЊЕ_БЕЛИ БРЕГ_70432_711144",
    },
    {
        label: "ВРАЊЕ, БОЈИН ДЕЛ",
        value: "ВРАЊЕ_БОЈИН ДЕЛ_70432_711152",
    },
    {
        label: "ВРАЊЕ, БРЕСНИЦА",
        value: "ВРАЊЕ_БРЕСНИЦА_70432_711179",
    },
    {
        label: "ВРАЊЕ, БУЉЕСОВЦЕ",
        value: "ВРАЊЕ_БУЉЕСОВЦЕ_70432_711195",
    },
    {
        label: "ВРАЊЕ, БУНУШЕВЦЕ",
        value: "ВРАЊЕ_БУНУШЕВЦЕ_70432_711209",
    },
    {
        label: "ВРАЊЕ, БУШТРАЊЕ",
        value: "ВРАЊЕ_БУШТРАЊЕ_70432_711217",
    },
    {
        label: "ВРАЊЕ, ВИШЕВЦЕ",
        value: "ВРАЊЕ_ВИШЕВЦЕ_70432_711225",
    },
    {
        label: "ВРАЊЕ, ВЛАСЕ",
        value: "ВРАЊЕ_ВЛАСЕ_70432_711233",
    },
    {
        label: "ВРАЊЕ, ВРАЊЕ I",
        value: "ВРАЊЕ_ВРАЊЕ I_70432_711241",
    },
    {
        label: "ВРАЊЕ, ВРАЊЕ II",
        value: "ВРАЊЕ_ВРАЊЕ II_70432_711250",
    },
    {
        label: "ВРАЊЕ, ГОЛЕМО СЕЛО",
        value: "ВРАЊЕ_ГОЛЕМО СЕЛО_70432_711276",
    },
    {
        label: "ВРАЊЕ, ГОРЊА ОТУЉА",
        value: "ВРАЊЕ_ГОРЊА ОТУЉА_70432_711306",
    },
    {
        label: "ВРАЊЕ, ГОРЊЕ ЖАПСКО",
        value: "ВРАЊЕ_ГОРЊЕ ЖАПСКО_70432_711322",
    },
    {
        label: "ВРАЊЕ, ГОРЊЕ ПУНУШЕВЦЕ",
        value: "ВРАЊЕ_ГОРЊЕ ПУНУШЕВЦЕ_70432_711349",
    },
    {
        label: "ВРАЊЕ, ГОРЊЕ ТРЕБЕШИЊЕ",
        value: "ВРАЊЕ_ГОРЊЕ ТРЕБЕШИЊЕ_70432_711357",
    },
    {
        label: "ВРАЊЕ, ГОРЊИ ВРТОГОШ",
        value: "ВРАЊЕ_ГОРЊИ ВРТОГОШ_70432_711365",
    },
    {
        label: "ВРАЊЕ, ГОРЊИ НЕРАДОВАЦ",
        value: "ВРАЊЕ_ГОРЊИ НЕРАДОВАЦ_70432_711373",
    },
    {
        label: "ВРАЊЕ, ГРАДЊА",
        value: "ВРАЊЕ_ГРАДЊА_70432_711390",
    },
    {
        label: "ВРАЊЕ, ГУМЕРИШТЕ",
        value: "ВРАЊЕ_ГУМЕРИШТЕ_70432_711403",
    },
    {
        label: "ВРАЊЕ, ДАВИДОВАЦ",
        value: "ВРАЊЕ_ДАВИДОВАЦ_70432_711411",
    },
    {
        label: "ВРАЊЕ, ДОБРЕЈАНЦЕ",
        value: "ВРАЊЕ_ДОБРЕЈАНЦЕ_70432_711438",
    },
    {
        label: "ВРАЊЕ, ДОЊА ОТУЉА",
        value: "ВРАЊЕ_ДОЊА ОТУЉА_70432_711446",
    },
    {
        label: "ВРАЊЕ, ДОЊЕ ЖАПСКО",
        value: "ВРАЊЕ_ДОЊЕ ЖАПСКО_70432_711462",
    },
    {
        label: "ВРАЊЕ, ДОЊЕ ПУНУШЕВЦЕ",
        value: "ВРАЊЕ_ДОЊЕ ПУНУШЕВЦЕ_70432_711489",
    },
    {
        label: "ВРАЊЕ, ДОЊЕ ТРЕБЕШИЊЕ",
        value: "ВРАЊЕ_ДОЊЕ ТРЕБЕШИЊЕ_70432_711497",
    },
    {
        label: "ВРАЊЕ, ДОЊИ ВРТОГОШ",
        value: "ВРАЊЕ_ДОЊИ ВРТОГОШ_70432_711519",
    },
    {
        label: "ВРАЊЕ, ДОЊИ НЕРАДОВАЦ",
        value: "ВРАЊЕ_ДОЊИ НЕРАДОВАЦ_70432_711527",
    },
    {
        label: "ВРАЊЕ, ДРЕНОВАЦ",
        value: "ВРАЊЕ_ДРЕНОВАЦ_70432_711543",
    },
    {
        label: "ВРАЊЕ, ДУБНИЦА",
        value: "ВРАЊЕ_ДУБНИЦА_70432_711551",
    },
    {
        label: "ВРАЊЕ, ДУЛАН",
        value: "ВРАЊЕ_ДУЛАН_70432_711578",
    },
    {
        label: "ВРАЊЕ, ДУПЕЉЕВО",
        value: "ВРАЊЕ_ДУПЕЉЕВО_70432_711594",
    },
    {
        label: "ВРАЊЕ, ЗЛАТОКОП",
        value: "ВРАЊЕ_ЗЛАТОКОП_70432_711624",
    },
    {
        label: "ВРАЊЕ, КАТУН",
        value: "ВРАЊЕ_КАТУН_70432_711667",
    },
    {
        label: "ВРАЊЕ, КЛАШЊИЦЕ",
        value: "ВРАЊЕ_КЛАШЊИЦЕ_70432_711675",
    },
    {
        label: "ВРАЊЕ, КОПАЊАНЕ",
        value: "ВРАЊЕ_КОПАЊАНЕ_70432_711705",
    },
    {
        label: "ВРАЊЕ, КОЋУРА",
        value: "ВРАЊЕ_КОЋУРА_70432_711730",
    },
    {
        label: "ВРАЊЕ, КРУШЕВА ГЛАВА",
        value: "ВРАЊЕ_КРУШЕВА ГЛАВА_70432_711756",
    },
    {
        label: "ВРАЊЕ, КУПИНИНЦЕ",
        value: "ВРАЊЕ_КУПИНИНЦЕ_70432_711772",
    },
    {
        label: "ВРАЊЕ, ЛАЛИНЦЕ",
        value: "ВРАЊЕ_ЛАЛИНЦЕ_70432_711799",
    },
    {
        label: "ВРАЊЕ, ЛЕПЧИНЦЕ",
        value: "ВРАЊЕ_ЛЕПЧИНЦЕ_70432_711829",
    },
    {
        label: "ВРАЊЕ, ЛУКОВО",
        value: "ВРАЊЕ_ЛУКОВО_70432_711853",
    },
    {
        label: "ВРАЊЕ, МЕЧКОВАЦ",
        value: "ВРАЊЕ_МЕЧКОВАЦ_70432_711870",
    },
    {
        label: "ВРАЊЕ, МИЈАКОВЦЕ",
        value: "ВРАЊЕ_МИЈАКОВЦЕ_70432_711888",
    },
    {
        label: "ВРАЊЕ, МИЈОВЦЕ",
        value: "ВРАЊЕ_МИЈОВЦЕ_70432_711896",
    },
    {
        label: "ВРАЊЕ, МИЛАНОВО",
        value: "ВРАЊЕ_МИЛАНОВО_70432_711900",
    },
    {
        label: "ВРАЊЕ, МИЛИВОЈЦЕ",
        value: "ВРАЊЕ_МИЛИВОЈЦЕ_70432_711918",
    },
    {
        label: "ВРАЊЕ, МОШТАНИЦА",
        value: "ВРАЊЕ_МОШТАНИЦА_70432_711926",
    },
    {
        label: "ВРАЊЕ, НАСТАВЦЕ",
        value: "ВРАЊЕ_НАСТАВЦЕ_70432_711934",
    },
    {
        label: "ВРАЊЕ, НОВА БРЕЗОВИЦА",
        value: "ВРАЊЕ_НОВА БРЕЗОВИЦА_70432_711969",
    },
    {
        label: "ВРАЊЕ, ОБЛИЧКА СЕНА",
        value: "ВРАЊЕ_ОБЛИЧКА СЕНА_70432_711985",
    },
    {
        label: "ВРАЊЕ, ОСТРА ГЛАВА",
        value: "ВРАЊЕ_ОСТРА ГЛАВА_70432_711993",
    },
    {
        label: "ВРАЊЕ, ПАВЛОВАЦ",
        value: "ВРАЊЕ_ПАВЛОВАЦ_70432_712019",
    },
    {
        label: "ВРАЊЕ, ПРЕОБРАЖЕЊЕ",
        value: "ВРАЊЕ_ПРЕОБРАЖЕЊЕ_70432_712051",
    },
    {
        label: "ВРАЊЕ, РАНУТОВАЦ",
        value: "ВРАЊЕ_РАНУТОВАЦ_70432_712086",
    },
    {
        label: "ВРАЊЕ, РАТАЈЕ I",
        value: "ВРАЊЕ_РАТАЈЕ I_70432_712094",
    },
    {
        label: "ВРАЊЕ, РАТАЈЕ II",
        value: "ВРАЊЕ_РАТАЈЕ II_70432_712108",
    },
    {
        label: "ВРАЊЕ, РИБИНЦЕ",
        value: "ВРАЊЕ_РИБИНЦЕ_70432_712116",
    },
    {
        label: "ВРАЊЕ, РИСТОВАЦ",
        value: "ВРАЊЕ_РИСТОВАЦ_70432_712124",
    },
    {
        label: "ВРАЊЕ, РОЖДАЦЕ",
        value: "ВРАЊЕ_РОЖДАЦЕ_70432_712132",
    },
    {
        label: "ВРАЊЕ, РУСЦЕ",
        value: "ВРАЊЕ_РУСЦЕ_70432_712159",
    },
    {
        label: "ВРАЊЕ, СИКИРЈЕ",
        value: "ВРАЊЕ_СИКИРЈЕ_70432_712175",
    },
    {
        label: "ВРАЊЕ, СМИЉЕВИЋ",
        value: "ВРАЊЕ_СМИЉЕВИЋ_70432_712191",
    },
    {
        label: "ВРАЊЕ, СОДЕРЦЕ",
        value: "ВРАЊЕ_СОДЕРЦЕ_70432_712205",
    },
    {
        label: "ВРАЊЕ, СРЕДЊИ ДЕЛ",
        value: "ВРАЊЕ_СРЕДЊИ ДЕЛ_70432_712213",
    },
    {
        label: "ВРАЊЕ, СРПСКО МАРГАНЦЕ",
        value: "ВРАЊЕ_СРПСКО МАРГАНЦЕ_70432_712221",
    },
    {
        label: "ВРАЊЕ, СТАНЦЕ",
        value: "ВРАЊЕ_СТАНЦЕ_70432_712230",
    },
    {
        label: "ВРАЊЕ, СТАРА БРЕЗОВИЦА",
        value: "ВРАЊЕ_СТАРА БРЕЗОВИЦА_70432_712248",
    },
    {
        label: "ВРАЊЕ, СТРЕШАК",
        value: "ВРАЊЕ_СТРЕШАК_70432_712264",
    },
    {
        label: "ВРАЊЕ, СТРОПСКО",
        value: "ВРАЊЕ_СТРОПСКО_70432_712272",
    },
    {
        label: "ВРАЊЕ, СТРУГАНИЦА",
        value: "ВРАЊЕ_СТРУГАНИЦА_70432_712299",
    },
    {
        label: "ВРАЊЕ, СТУДЕНА",
        value: "ВРАЊЕ_СТУДЕНА_70432_712302",
    },
    {
        label: "ВРАЊЕ, СУВИ ДОЛ",
        value: "ВРАЊЕ_СУВИ ДОЛ_70432_712329",
    },
    {
        label: "ВРАЊЕ, СУРДУЛ",
        value: "ВРАЊЕ_СУРДУЛ_70432_712337",
    },
    {
        label: "ВРАЊЕ, ТЕСОВИШТЕ",
        value: "ВРАЊЕ_ТЕСОВИШТЕ_70432_712353",
    },
    {
        label: "ВРАЊЕ, ТИБУЖДЕ",
        value: "ВРАЊЕ_ТИБУЖДЕ_70432_712361",
    },
    {
        label: "ВРАЊЕ, ТРСТЕНА",
        value: "ВРАЊЕ_ТРСТЕНА_70432_712396",
    },
    {
        label: "ВРАЊЕ, ТУМБА",
        value: "ВРАЊЕ_ТУМБА_70432_712400",
    },
    {
        label: "ВРАЊЕ, ЋУКОВАЦ",
        value: "ВРАЊЕ_ЋУКОВАЦ_70432_712418",
    },
    {
        label: "ВРАЊЕ, ЋУРКОВИЦА",
        value: "ВРАЊЕ_ЋУРКОВИЦА_70432_712426",
    },
    {
        label: "ВРАЊЕ, УРМАНИЦА",
        value: "ВРАЊЕ_УРМАНИЦА_70432_710164",
    },
    {
        label: "ВРАЊЕ, УШЕВЦЕ",
        value: "ВРАЊЕ_УШЕВЦЕ_70432_712434",
    },
    {
        label: "ВРАЊЕ, ЦРНИ ЛУГ",
        value: "ВРАЊЕ_ЦРНИ ЛУГ_70432_712485",
    },
    {
        label: "ВРАЊЕ, ЧЕСТЕЛИН",
        value: "ВРАЊЕ_ЧЕСТЕЛИН_70432_712507",
    },
    {
        label: "ВРАЊСКА БАЊА, БАБИНА ПОЉАНА",
        value: "ВРАЊСКА БАЊА_БАБИНА ПОЉАНА_71358_711101",
    },
    {
        label: "ВРАЊСКА БАЊА, БУЈКОВАЦ",
        value: "ВРАЊСКА БАЊА_БУЈКОВАЦ_71358_711187",
    },
    {
        label: "ВРАЊСКА БАЊА, ВРАЊСКА БАЊА",
        value: "ВРАЊСКА БАЊА_ВРАЊСКА БАЊА_71358_711268",
    },
    {
        label: "ВРАЊСКА БАЊА, ДУГА ЛУКА",
        value: "ВРАЊСКА БАЊА_ДУГА ЛУКА_71358_711560",
    },
    {
        label: "ВРАЊСКА БАЊА, ИЗУМНО",
        value: "ВРАЊСКА БАЊА_ИЗУМНО_71358_711632",
    },
    {
        label: "ВРАЊСКА БАЊА, КЛИСУРИЦА",
        value: "ВРАЊСКА БАЊА_КЛИСУРИЦА_71358_711683",
    },
    {
        label: "ВРАЊСКА БАЊА, КОРБЕВАЦ",
        value: "ВРАЊСКА БАЊА_КОРБЕВАЦ_71358_711713",
    },
    {
        label: "ВРАЊСКА БАЊА, КОРБУЛ",
        value: "ВРАЊСКА БАЊА_КОРБУЛ_71358_711721",
    },
    {
        label: "ВРАЊСКА БАЊА, КРИВА ФЕЈА",
        value: "ВРАЊСКА БАЊА_КРИВА ФЕЈА_71358_711748",
    },
    {
        label: "ВРАЊСКА БАЊА, КУМАРЕВО",
        value: "ВРАЊСКА БАЊА_КУМАРЕВО_71358_711764",
    },
    {
        label: "ВРАЊСКА БАЊА, ЛЕВА РЕКА",
        value: "ВРАЊСКА БАЊА_ЛЕВА РЕКА_71358_711802",
    },
    {
        label: "ВРАЊСКА БАЊА, ЛИПОВАЦ",
        value: "ВРАЊСКА БАЊА_ЛИПОВАЦ_71358_711845",
    },
    {
        label: "ВРАЊСКА БАЊА, НЕСВРТА",
        value: "ВРАЊСКА БАЊА_НЕСВРТА_71358_711942",
    },
    {
        label: "ВРАЊСКА БАЊА, ПАНЕВЉЕ",
        value: "ВРАЊСКА БАЊА_ПАНЕВЉЕ_71358_712027",
    },
    {
        label: "ВРАЊСКА БАЊА, ПРВОНЕК",
        value: "ВРАЊСКА БАЊА_ПРВОНЕК_71358_712035",
    },
    {
        label: "ВРАЊСКА БАЊА, ПРЕВАЛАЦ",
        value: "ВРАЊСКА БАЊА_ПРЕВАЛАЦ_71358_712043",
    },
    {
        label: "ВРАЊСКА БАЊА, СЕБЕВРАЊЕ",
        value: "ВРАЊСКА БАЊА_СЕБЕВРАЊЕ_71358_712167",
    },
    {
        label: "ВРАЊСКА БАЊА, СЛИВНИЦА",
        value: "ВРАЊСКА БАЊА_СЛИВНИЦА_71358_712183",
    },
    {
        label: "ВРАЊСКА БАЊА, СТАРИ ГЛОГ",
        value: "ВРАЊСКА БАЊА_СТАРИ ГЛОГ_71358_712256",
    },
    {
        label: "ВРАЊСКА БАЊА, ТОПЛАЦ",
        value: "ВРАЊСКА БАЊА_ТОПЛАЦ_71358_712370",
    },
    {
        label: "ВРАЊСКА БАЊА, ЦРНИ ВРХ",
        value: "ВРАЊСКА БАЊА_ЦРНИ ВРХ_71358_712477",
    },
    {
        label: "ВРАЧАР, ВРАЧАР",
        value: "ВРАЧАР_ВРАЧАР_70114_703648",
    },
    {
        label: "ВРБАС, БАЧКО ДОБРО ПОЉЕ",
        value: "ВРБАС_БАЧКО ДОБРО ПОЉЕ_80462_802069",
    },
    {
        label: "ВРБАС, ВРБАС",
        value: "ВРБАС_ВРБАС_80462_801534",
    },
    {
        label: "ВРБАС, ВРБАС-ГРАД",
        value: "ВРБАС_ВРБАС-ГРАД_80462_801569",
    },
    {
        label: "ВРБАС, ЗМАЈЕВО",
        value: "ВРБАС_ЗМАЈЕВО_80462_802301",
    },
    {
        label: "ВРБАС, КОСАНЧИЋ",
        value: "ВРБАС_КОСАНЧИЋ_80462_801615",
    },
    {
        label: "ВРБАС, КУЦУРА",
        value: "ВРБАС_КУЦУРА_80462_801631",
    },
    {
        label: "ВРБАС, РАВНО СЕЛО",
        value: "ВРБАС_РАВНО СЕЛО_80462_802204",
    },
    {
        label: "ВРБАС, САВИНО СЕЛО",
        value: "ВРБАС_САВИНО СЕЛО_80462_801682",
    },
    {
        label: "ВРЊАЧКА БАЊА, ВРАНЕШИ",
        value: "ВРЊАЧКА БАЊА_ВРАНЕШИ_70459_719609",
    },
    {
        label: "ВРЊАЧКА БАЊА, ВРЊАЧКА БАЊА",
        value: "ВРЊАЧКА БАЊА_ВРЊАЧКА БАЊА_70459_741558",
    },
    {
        label: "ВРЊАЧКА БАЊА, ВУКУШИЦА",
        value: "ВРЊАЧКА БАЊА_ВУКУШИЦА_70459_719633",
    },
    {
        label: "ВРЊАЧКА БАЊА, ГОЧ",
        value: "ВРЊАЧКА БАЊА_ГОЧ_70459_741604",
    },
    {
        label: "ВРЊАЧКА БАЊА, ГРАЧАЦ",
        value: "ВРЊАЧКА БАЊА_ГРАЧАЦ_70459_719668",
    },
    {
        label: "ВРЊАЧКА БАЊА, ЛИПОВА",
        value: "ВРЊАЧКА БАЊА_ЛИПОВА_70459_741701",
    },
    {
        label: "ВРЊАЧКА БАЊА, НОВО СЕЛО",
        value: "ВРЊАЧКА БАЊА_НОВО СЕЛО_70459_741809",
    },
    {
        label: "ВРЊАЧКА БАЊА, ОТРОЦИ",
        value: "ВРЊАЧКА БАЊА_ОТРОЦИ_70459_719943",
    },
    {
        label: "ВРЊАЧКА БАЊА, ПОДУНАВЦИ",
        value: "ВРЊАЧКА БАЊА_ПОДУНАВЦИ_70459_719960",
    },
    {
        label: "ВРЊАЧКА БАЊА, РСАВЦИ",
        value: "ВРЊАЧКА БАЊА_РСАВЦИ_70459_741957",
    },
    {
        label: "ВРЊАЧКА БАЊА, РУЂИНЦИ",
        value: "ВРЊАЧКА БАЊА_РУЂИНЦИ_70459_741965",
    },
    {
        label: "ВРЊАЧКА БАЊА, СТАНИШИНЦИ",
        value: "ВРЊАЧКА БАЊА_СТАНИШИНЦИ_70459_741990",
    },
    {
        label: "ВРЊАЧКА БАЊА, ШТУЛАЦ",
        value: "ВРЊАЧКА БАЊА_ШТУЛАЦ_70459_742082",
    },
    {
        label: "ВРШАЦ, ВАТИН",
        value: "ВРШАЦ_ВАТИН_80128_804894",
    },
    {
        label: "ВРШАЦ, ВЕЛИКО СРЕДИШТЕ",
        value: "ВРШАЦ_ВЕЛИКО СРЕДИШТЕ_80128_804908",
    },
    {
        label: "ВРШАЦ, ВЛАЈКОВАЦ",
        value: "ВРШАЦ_ВЛАЈКОВАЦ_80128_804916",
    },
    {
        label: "ВРШАЦ, ВОЈВОДИНЦИ",
        value: "ВРШАЦ_ВОЈВОДИНЦИ_80128_804924",
    },
    {
        label: "ВРШАЦ, ВРШАЦ",
        value: "ВРШАЦ_ВРШАЦ_80128_804932",
    },
    {
        label: "ВРШАЦ, ГУДУРИЦА",
        value: "ВРШАЦ_ГУДУРИЦА_80128_804754",
    },
    {
        label: "ВРШАЦ, ЗАГАЈИЦА",
        value: "ВРШАЦ_ЗАГАЈИЦА_80128_800651",
    },
    {
        label: "ВРШАЦ, ИЗБИШТЕ",
        value: "ВРШАЦ_ИЗБИШТЕ_80128_800457",
    },
    {
        label: "ВРШАЦ, ЈАБЛАНКА",
        value: "ВРШАЦ_ЈАБЛАНКА_80128_804762",
    },
    {
        label: "ВРШАЦ, КУШТИЉ",
        value: "ВРШАЦ_КУШТИЉ_80128_804789",
    },
    {
        label: "ВРШАЦ, МАЛИ ЖАМ",
        value: "ВРШАЦ_МАЛИ ЖАМ_80128_804797",
    },
    {
        label: "ВРШАЦ, МАЛО СРЕДИШТЕ",
        value: "ВРШАЦ_МАЛО СРЕДИШТЕ_80128_804827",
    },
    {
        label: "ВРШАЦ, МАРКОВАЦ",
        value: "ВРШАЦ_МАРКОВАЦ_80128_804819",
    },
    {
        label: "ВРШАЦ, МЕСИЋ",
        value: "ВРШАЦ_МЕСИЋ_80128_804835",
    },
    {
        label: "ВРШАЦ, ОРЕШАЦ",
        value: "ВРШАЦ_ОРЕШАЦ_80128_800546",
    },
    {
        label: "ВРШАЦ, ПАВЛИШ",
        value: "ВРШАЦ_ПАВЛИШ_80128_804843",
    },
    {
        label: "ВРШАЦ, ПАРТА",
        value: "ВРШАЦ_ПАРТА_80128_800562",
    },
    {
        label: "ВРШАЦ, ПОТПОРАЊ",
        value: "ВРШАЦ_ПОТПОРАЊ_80128_804851",
    },
    {
        label: "ВРШАЦ, РИТИШЕВО",
        value: "ВРШАЦ_РИТИШЕВО_80128_804860",
    },
    {
        label: "ВРШАЦ, СОЧИЦА I",
        value: "ВРШАЦ_СОЧИЦА I_80128_804878",
    },
    {
        label: "ВРШАЦ, СОЧИЦА II",
        value: "ВРШАЦ_СОЧИЦА II_80128_805823",
    },
    {
        label: "ВРШАЦ, СТРАЖА",
        value: "ВРШАЦ_СТРАЖА_80128_800619",
    },
    {
        label: "ВРШАЦ, УЉМА",
        value: "ВРШАЦ_УЉМА_80128_800635",
    },
    {
        label: "ВРШАЦ, ШУШАРА",
        value: "ВРШАЦ_ШУШАРА_80128_800627",
    },
    {
        label: "ГАЏИН ХАН, ВЕЛИКИ ВРТОП",
        value: "ГАЏИН ХАН_ВЕЛИКИ ВРТОП_70467_729116",
    },
    {
        label: "ГАЏИН ХАН, ВЕЛИКИ КРЧИМИР",
        value: "ГАЏИН ХАН_ВЕЛИКИ КРЧИМИР_70467_729124",
    },
    {
        label: "ГАЏИН ХАН, ВИЛАНДРИЦА",
        value: "ГАЏИН ХАН_ВИЛАНДРИЦА_70467_729132",
    },
    {
        label: "ГАЏИН ХАН, ГАРЕ",
        value: "ГАЏИН ХАН_ГАРЕ_70467_729205",
    },
    {
        label: "ГАЏИН ХАН, ГАЏИН ХАН",
        value: "ГАЏИН ХАН_ГАЏИН ХАН_70467_729191",
    },
    {
        label: "ГАЏИН ХАН, ГОРЊЕ ВЛАСЕ",
        value: "ГАЏИН ХАН_ГОРЊЕ ВЛАСЕ_70467_729256",
    },
    {
        label: "ГАЏИН ХАН, ГОРЊЕ ДРАГОВЉЕ",
        value: "ГАЏИН ХАН_ГОРЊЕ ДРАГОВЉЕ_70467_729264",
    },
    {
        label: "ГАЏИН ХАН, ГОРЊИ БАРБЕШ",
        value: "ГАЏИН ХАН_ГОРЊИ БАРБЕШ_70467_729299",
    },
    {
        label: "ГАЏИН ХАН, ГОРЊИ ДУШНИК",
        value: "ГАЏИН ХАН_ГОРЊИ ДУШНИК_70467_729302",
    },
    {
        label: "ГАЏИН ХАН, ГРКИЊА",
        value: "ГАЏИН ХАН_ГРКИЊА_70467_729345",
    },
    {
        label: "ГАЏИН ХАН, ДОЊЕ ДРАГОВЉЕ",
        value: "ГАЏИН ХАН_ДОЊЕ ДРАГОВЉЕ_70467_729400",
    },
    {
        label: "ГАЏИН ХАН, ДОЊИ БАРБЕШ",
        value: "ГАЏИН ХАН_ДОЊИ БАРБЕШ_70467_729426",
    },
    {
        label: "ГАЏИН ХАН, ДОЊИ ДУШНИК",
        value: "ГАЏИН ХАН_ДОЊИ ДУШНИК_70467_729434",
    },
    {
        label: "ГАЏИН ХАН, ДУГА ПОЉАНА",
        value: "ГАЏИН ХАН_ДУГА ПОЉАНА_70467_729485",
    },
    {
        label: "ГАЏИН ХАН, ДУКАТ",
        value: "ГАЏИН ХАН_ДУКАТ_70467_729493",
    },
    {
        label: "ГАЏИН ХАН, ЈАГЛИЧЈЕ",
        value: "ГАЏИН ХАН_ЈАГЛИЧЈЕ_70467_729507",
    },
    {
        label: "ГАЏИН ХАН, КАЛЕТИНАЦ",
        value: "ГАЏИН ХАН_КАЛЕТИНАЦ_70467_729531",
    },
    {
        label: "ГАЏИН ХАН, КОПРИВНИЦА",
        value: "ГАЏИН ХАН_КОПРИВНИЦА_70467_729582",
    },
    {
        label: "ГАЏИН ХАН, КРАСТАВЧЕ",
        value: "ГАЏИН ХАН_КРАСТАВЧЕ_70467_729612",
    },
    {
        label: "ГАЏИН ХАН, ЛИЧЈЕ",
        value: "ГАЏИН ХАН_ЛИЧЈЕ_70467_729671",
    },
    {
        label: "ГАЏИН ХАН, МАЛИ ВРТОП",
        value: "ГАЏИН ХАН_МАЛИ ВРТОП_70467_729680",
    },
    {
        label: "ГАЏИН ХАН, МАЛИ КРЧИМИР",
        value: "ГАЏИН ХАН_МАЛИ КРЧИМИР_70467_729698",
    },
    {
        label: "ГАЏИН ХАН, МАРИНА КУТИНА",
        value: "ГАЏИН ХАН_МАРИНА КУТИНА_70467_729728",
    },
    {
        label: "ГАЏИН ХАН, МИЉКОВАЦ",
        value: "ГАЏИН ХАН_МИЉКОВАЦ_70467_729752",
    },
    {
        label: "ГАЏИН ХАН, НОВО СЕЛО",
        value: "ГАЏИН ХАН_НОВО СЕЛО_70467_729833",
    },
    {
        label: "ГАЏИН ХАН, ОВСИЊИНАЦ",
        value: "ГАЏИН ХАН_ОВСИЊИНАЦ_70467_729850",
    },
    {
        label: "ГАЏИН ХАН, РАВНА ДУБРАВА",
        value: "ГАЏИН ХАН_РАВНА ДУБРАВА_70467_710873",
    },
    {
        label: "ГАЏИН ХАН, СЕМЧЕ",
        value: "ГАЏИН ХАН_СЕМЧЕ_70467_730017",
    },
    {
        label: "ГАЏИН ХАН, СОПОТНИЦА",
        value: "ГАЏИН ХАН_СОПОТНИЦА_70467_730033",
    },
    {
        label: "ГАЏИН ХАН, ТАСКОВИЋИ",
        value: "ГАЏИН ХАН_ТАСКОВИЋИ_70467_730041",
    },
    {
        label: "ГАЏИН ХАН, ТОПОНИЦА",
        value: "ГАЏИН ХАН_ТОПОНИЦА_70467_730050",
    },
    {
        label: "ГАЏИН ХАН, ЋЕЛИЈЕ",
        value: "ГАЏИН ХАН_ЋЕЛИЈЕ_70467_730076",
    },
    {
        label: "ГАЏИН ХАН, ЧАГРОВАЦ",
        value: "ГАЏИН ХАН_ЧАГРОВАЦ_70467_730114",
    },
    {
        label: "ГАЏИН ХАН, ШЕБЕТ",
        value: "ГАЏИН ХАН_ШЕБЕТ_70467_730181",
    },
    {
        label: "ГОЛУБАЦ, БАРИЧ",
        value: "ГОЛУБАЦ_БАРИЧ_70475_712540",
    },
    {
        label: "ГОЛУБАЦ, БИКИЊЕ",
        value: "ГОЛУБАЦ_БИКИЊЕ_70475_712558",
    },
    {
        label: "ГОЛУБАЦ, БРАНИЧЕВО",
        value: "ГОЛУБАЦ_БРАНИЧЕВО_70475_712566",
    },
    {
        label: "ГОЛУБАЦ, БРЊИЦА",
        value: "ГОЛУБАЦ_БРЊИЦА_70475_712574",
    },
    {
        label: "ГОЛУБАЦ, ВИНЦИ",
        value: "ГОЛУБАЦ_ВИНЦИ_70475_712582",
    },
    {
        label: "ГОЛУБАЦ, ВОЈИЛОВО",
        value: "ГОЛУБАЦ_ВОЈИЛОВО_70475_712604",
    },
    {
        label: "ГОЛУБАЦ, ГОЛУБАЦ",
        value: "ГОЛУБАЦ_ГОЛУБАЦ_70475_712639",
    },
    {
        label: "ГОЛУБАЦ, ДВОРИШТЕ",
        value: "ГОЛУБАЦ_ДВОРИШТЕ_70475_712647",
    },
    {
        label: "ГОЛУБАЦ, ДОБРА",
        value: "ГОЛУБАЦ_ДОБРА_70475_712655",
    },
    {
        label: "ГОЛУБАЦ, ДОЊА КРУШЕВИЦА",
        value: "ГОЛУБАЦ_ДОЊА КРУШЕВИЦА_70475_712663",
    },
    {
        label: "ГОЛУБАЦ, ДУШМАНИЋ",
        value: "ГОЛУБАЦ_ДУШМАНИЋ_70475_712671",
    },
    {
        label: "ГОЛУБАЦ, ЖИТКОВИЦА",
        value: "ГОЛУБАЦ_ЖИТКОВИЦА_70475_712680",
    },
    {
        label: "ГОЛУБАЦ, КЛЕЊЕ",
        value: "ГОЛУБАЦ_КЛЕЊЕ_70475_712701",
    },
    {
        label: "ГОЛУБАЦ, КРИВАЧА",
        value: "ГОЛУБАЦ_КРИВАЧА_70475_712710",
    },
    {
        label: "ГОЛУБАЦ, КУДРЕШ",
        value: "ГОЛУБАЦ_КУДРЕШ_70475_712728",
    },
    {
        label: "ГОЛУБАЦ, МАЛЕШЕВО",
        value: "ГОЛУБАЦ_МАЛЕШЕВО_70475_712736",
    },
    {
        label: "ГОЛУБАЦ, МИЉЕВИЋ",
        value: "ГОЛУБАЦ_МИЉЕВИЋ_70475_712744",
    },
    {
        label: "ГОЛУБАЦ, МРЧКОВАЦ",
        value: "ГОЛУБАЦ_МРЧКОВАЦ_70475_712752",
    },
    {
        label: "ГОЛУБАЦ, ПОНИКВЕ",
        value: "ГОЛУБАЦ_ПОНИКВЕ_70475_712779",
    },
    {
        label: "ГОЛУБАЦ, РАДОШЕВАЦ",
        value: "ГОЛУБАЦ_РАДОШЕВАЦ_70475_712787",
    },
    {
        label: "ГОЛУБАЦ, СЛАДИНАЦ",
        value: "ГОЛУБАЦ_СЛАДИНАЦ_70475_712795",
    },
    {
        label: "ГОЛУБАЦ, СНЕГОТИН",
        value: "ГОЛУБАЦ_СНЕГОТИН_70475_712809",
    },
    {
        label: "ГОЛУБАЦ, УСИЈЕ",
        value: "ГОЛУБАЦ_УСИЈЕ_70475_712817",
    },
    {
        label: "ГОЛУБАЦ, ШУВАЈИЋ",
        value: "ГОЛУБАЦ_ШУВАЈИЋ_70475_712825",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, БЕЛО ПОЉЕ",
        value: "ГОРЊИ МИЛАНОВАЦ_БЕЛО ПОЉЕ_70483_712841",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, БЕРШИЋИ",
        value: "ГОРЊИ МИЛАНОВАЦ_БЕРШИЋИ_70483_712850",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, БОГДАНИЦА",
        value: "ГОРЊИ МИЛАНОВАЦ_БОГДАНИЦА_70483_743232",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, БОЉКОВЦИ",
        value: "ГОРЊИ МИЛАНОВАЦ_БОЉКОВЦИ_70483_726966",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, БРАЈИЋИ",
        value: "ГОРЊИ МИЛАНОВАЦ_БРАЈИЋИ_70483_712868",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, БРЂАНИ",
        value: "ГОРЊИ МИЛАНОВАЦ_БРЂАНИ_70483_744328",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, БРЕЗНА",
        value: "ГОРЊИ МИЛАНОВАЦ_БРЕЗНА_70483_712876",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, БРЕЗОВИЦА",
        value: "ГОРЊИ МИЛАНОВАЦ_БРЕЗОВИЦА_70483_727008",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, БРУСНИЦА",
        value: "ГОРЊИ МИЛАНОВАЦ_БРУСНИЦА_70483_712884",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ВЕЛЕРЕЧ",
        value: "ГОРЊИ МИЛАНОВАЦ_ВЕЛЕРЕЧ_70483_712892",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ВРАЋЕВШНИЦА",
        value: "ГОРЊИ МИЛАНОВАЦ_ВРАЋЕВШНИЦА_70483_712906",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ВРНЧАНИ",
        value: "ГОРЊИ МИЛАНОВАЦ_ВРНЧАНИ_70483_712914",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ГОЈНА ГОРА",
        value: "ГОРЊИ МИЛАНОВАЦ_ГОЈНА ГОРА_70483_743305",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ГОРЊА ВРБАВА",
        value: "ГОРЊИ МИЛАНОВАЦ_ГОРЊА ВРБАВА_70483_712957",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ГОРЊА ЦРНУЋА",
        value: "ГОРЊИ МИЛАНОВАЦ_ГОРЊА ЦРНУЋА_70483_712973",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ГОРЊИ БАЊАНИ",
        value: "ГОРЊИ МИЛАНОВАЦ_ГОРЊИ БАЊАНИ_70483_712922",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ГОРЊИ БРАНЕТИЋИ",
        value: "ГОРЊИ МИЛАНОВАЦ_ГОРЊИ БРАНЕТИЋИ_70483_712949",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ГОРЊИ МИЛАНОВАЦ",
        value: "ГОРЊИ МИЛАНОВАЦ_ГОРЊИ МИЛАНОВАЦ_70483_712965",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ГРАБОВИЦА",
        value: "ГОРЊИ МИЛАНОВАЦ_ГРАБОВИЦА_70483_712981",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ДАВИДОВИЦА",
        value: "ГОРЊИ МИЛАНОВАЦ_ДАВИДОВИЦА_70483_727059",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ДОЊА ВРБАВА",
        value: "ГОРЊИ МИЛАНОВАЦ_ДОЊА ВРБАВА_70483_712990",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ДОЊА ЦРНУЋА",
        value: "ГОРЊИ МИЛАНОВАЦ_ДОЊА ЦРНУЋА_70483_713015",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ДОЊИ БРАНЕТИЋИ",
        value: "ГОРЊИ МИЛАНОВАЦ_ДОЊИ БРАНЕТИЋИ_70483_713007",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ДРАГОЉ",
        value: "ГОРЊИ МИЛАНОВАЦ_ДРАГОЉ_70483_727083",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ДРЕНОВА",
        value: "ГОРЊИ МИЛАНОВАЦ_ДРЕНОВА_70483_713023",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ДРУЖЕТИЋИ",
        value: "ГОРЊИ МИЛАНОВАЦ_ДРУЖЕТИЋИ_70483_743364",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ЗАГРАЂЕ",
        value: "ГОРЊИ МИЛАНОВАЦ_ЗАГРАЂЕ_70483_727105",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ЈАБЛАНИЦА",
        value: "ГОРЊИ МИЛАНОВАЦ_ЈАБЛАНИЦА_70483_713031",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, КАЛИМАНИЋИ",
        value: "ГОРЊИ МИЛАНОВАЦ_КАЛИМАНИЋИ_70483_713040",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, КЛАТИЧЕВО",
        value: "ГОРЊИ МИЛАНОВАЦ_КЛАТИЧЕВО_70483_713058",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, КОШТУНИЋИ",
        value: "ГОРЊИ МИЛАНОВАЦ_КОШТУНИЋИ_70483_713066",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, КРИВА РЕКА",
        value: "ГОРЊИ МИЛАНОВАЦ_КРИВА РЕКА_70483_727156",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ЛЕУШИЋИ",
        value: "ГОРЊИ МИЛАНОВАЦ_ЛЕУШИЋИ_70483_713074",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ЛИПОВАЦ",
        value: "ГОРЊИ МИЛАНОВАЦ_ЛИПОВАЦ_70483_713082",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ЛОЗАЊ",
        value: "ГОРЊИ МИЛАНОВАЦ_ЛОЗАЊ_70483_713104",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ЛОЧЕВЦИ",
        value: "ГОРЊИ МИЛАНОВАЦ_ЛОЧЕВЦИ_70483_713112",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ЛУЊЕВИЦА",
        value: "ГОРЊИ МИЛАНОВАЦ_ЛУЊЕВИЦА_70483_713139",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ЉЕВАЈА",
        value: "ГОРЊИ МИЛАНОВАЦ_ЉЕВАЈА_70483_713147",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ЉУТОВНИЦА",
        value: "ГОРЊИ МИЛАНОВАЦ_ЉУТОВНИЦА_70483_713155",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, МАЈДАН",
        value: "ГОРЊИ МИЛАНОВАЦ_МАЈДАН_70483_713163",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, МУТАЊ",
        value: "ГОРЊИ МИЛАНОВАЦ_МУТАЊ_70483_727237",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, НАКУЧАНИ",
        value: "ГОРЊИ МИЛАНОВАЦ_НАКУЧАНИ_70483_713171",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, НЕВАДЕ",
        value: "ГОРЊИ МИЛАНОВАЦ_НЕВАДЕ_70483_713180",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ОЗРЕМ",
        value: "ГОРЊИ МИЛАНОВАЦ_ОЗРЕМ_70483_713198",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ПОЛОМ",
        value: "ГОРЊИ МИЛАНОВАЦ_ПОЛОМ_70483_713201",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ПРАЊАНИ",
        value: "ГОРЊИ МИЛАНОВАЦ_ПРАЊАНИ_70483_713210",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ПРЊАВОР",
        value: "ГОРЊИ МИЛАНОВАЦ_ПРЊАВОР_70483_713228",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, РЕЉИНЦИ",
        value: "ГОРЊИ МИЛАНОВАЦ_РЕЉИНЦИ_70483_727253",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, РУДНИК",
        value: "ГОРЊИ МИЛАНОВАЦ_РУДНИК_70483_727261",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, РУЧИЋИ",
        value: "ГОРЊИ МИЛАНОВАЦ_РУЧИЋИ_70483_727270",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, СВРАЧКОВЦИ",
        value: "ГОРЊИ МИЛАНОВАЦ_СВРАЧКОВЦИ_70483_713236",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, СЕМЕДРАЖ",
        value: "ГОРЊИ МИЛАНОВАЦ_СЕМЕДРАЖ_70483_713244",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, СИНОШЕВИЋИ",
        value: "ГОРЊИ МИЛАНОВАЦ_СИНОШЕВИЋИ_70483_713252",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, СРЕЗОЈЕВЦИ",
        value: "ГОРЊИ МИЛАНОВАЦ_СРЕЗОЈЕВЦИ_70483_713279",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ТАКОВО",
        value: "ГОРЊИ МИЛАНОВАЦ_ТАКОВО_70483_713287",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ТЕОЧИН",
        value: "ГОРЊИ МИЛАНОВАЦ_ТЕОЧИН_70483_713295",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ТРУДЕЉ",
        value: "ГОРЊИ МИЛАНОВАЦ_ТРУДЕЉ_70483_727288",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, УГРИНОВЦИ",
        value: "ГОРЊИ МИЛАНОВАЦ_УГРИНОВЦИ_70483_727296",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ЦЕРОВА",
        value: "ГОРЊИ МИЛАНОВАЦ_ЦЕРОВА_70483_727300",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ШАРАНИ",
        value: "ГОРЊИ МИЛАНОВАЦ_ШАРАНИ_70483_713309",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ, ШИЛОПАЈ",
        value: "ГОРЊИ МИЛАНОВАЦ_ШИЛОПАЈ_70483_727318",
    },
    {
        label: "ГРОЦКА, БЕГАЉИЦА",
        value: "ГРОЦКА_БЕГАЉИЦА_70122_703486",
    },
    {
        label: "ГРОЦКА, БОЛЕЧ",
        value: "ГРОЦКА_БОЛЕЧ_70122_703524",
    },
    {
        label: "ГРОЦКА, БРЕСТОВИК",
        value: "ГРОЦКА_БРЕСТОВИК_70122_703532",
    },
    {
        label: "ГРОЦКА, ВИНЧА",
        value: "ГРОЦКА_ВИНЧА_70122_703605",
    },
    {
        label: "ГРОЦКА, ВРЧИН",
        value: "ГРОЦКА_ВРЧИН_70122_703656",
    },
    {
        label: "ГРОЦКА, ГРОЦКА",
        value: "ГРОЦКА_ГРОЦКА_70122_703664",
    },
    {
        label: "ГРОЦКА, ДРАЖАЊ",
        value: "ГРОЦКА_ДРАЖАЊ_70122_703699",
    },
    {
        label: "ГРОЦКА, ЗАКЛОПАЧА",
        value: "ГРОЦКА_ЗАКЛОПАЧА_70122_703737",
    },
    {
        label: "ГРОЦКА, КАЛУЂЕРИЦА",
        value: "ГРОЦКА_КАЛУЂЕРИЦА_70122_703770",
    },
    {
        label: "ГРОЦКА, КАМЕНДОЛ",
        value: "ГРОЦКА_КАМЕНДОЛ_70122_703788",
    },
    {
        label: "ГРОЦКА, ЛЕШТАНЕ",
        value: "ГРОЦКА_ЛЕШТАНЕ_70122_703818",
    },
    {
        label: "ГРОЦКА, ПУДАРЦИ",
        value: "ГРОЦКА_ПУДАРЦИ_70122_703923",
    },
    {
        label: "ГРОЦКА, РИТОПЕК",
        value: "ГРОЦКА_РИТОПЕК_70122_703966",
    },
    {
        label: "ГРОЦКА, УМЧАРИ",
        value: "ГРОЦКА_УМЧАРИ_70122_745243",
    },
    {
        label: "ДЕСПОТОВАЦ, БАЛАЈНАЦ",
        value: "ДЕСПОТОВАЦ_БАЛАЈНАЦ_70491_713317",
    },
    {
        label: "ДЕСПОТОВАЦ, БЕЉАЈКА",
        value: "ДЕСПОТОВАЦ_БЕЉАЈКА_70491_713325",
    },
    {
        label: "ДЕСПОТОВАЦ, БОГАВА",
        value: "ДЕСПОТОВАЦ_БОГАВА_70491_713333",
    },
    {
        label: "ДЕСПОТОВАЦ, БРЕСТОВО",
        value: "ДЕСПОТОВАЦ_БРЕСТОВО_70491_713341",
    },
    {
        label: "ДЕСПОТОВАЦ, БУКОВАЦ",
        value: "ДЕСПОТОВАЦ_БУКОВАЦ_70491_713350",
    },
    {
        label: "ДЕСПОТОВАЦ, ВЕЛИКИ ПОПОВИЋ",
        value: "ДЕСПОТОВАЦ_ВЕЛИКИ ПОПОВИЋ_70491_713368",
    },
    {
        label: "ДЕСПОТОВАЦ, ВИТАНЦЕ",
        value: "ДЕСПОТОВАЦ_ВИТАНЦЕ_70491_713384",
    },
    {
        label: "ДЕСПОТОВАЦ, ВОЈНИК",
        value: "ДЕСПОТОВАЦ_ВОЈНИК_70491_713422",
    },
    {
        label: "ДЕСПОТОВАЦ, ГРАБОВИЦА",
        value: "ДЕСПОТОВАЦ_ГРАБОВИЦА_70491_713392",
    },
    {
        label: "ДЕСПОТОВАЦ, ДВОРИШТЕ",
        value: "ДЕСПОТОВАЦ_ДВОРИШТЕ_70491_713406",
    },
    {
        label: "ДЕСПОТОВАЦ, ДЕСПОТОВАЦ",
        value: "ДЕСПОТОВАЦ_ДЕСПОТОВАЦ_70491_713414",
    },
    {
        label: "ДЕСПОТОВАЦ, ЖИДИЉЕ",
        value: "ДЕСПОТОВАЦ_ЖИДИЉЕ_70491_713449",
    },
    {
        label: "ДЕСПОТОВАЦ, ЗЛАТОВО",
        value: "ДЕСПОТОВАЦ_ЗЛАТОВО_70491_732842",
    },
    {
        label: "ДЕСПОТОВАЦ, ЈАСЕНОВО",
        value: "ДЕСПОТОВАЦ_ЈАСЕНОВО_70491_738620",
    },
    {
        label: "ДЕСПОТОВАЦ, ЈЕЗЕРО",
        value: "ДЕСПОТОВАЦ_ЈЕЗЕРО_70491_713465",
    },
    {
        label: "ДЕСПОТОВАЦ, ЈЕЛОВАЦ",
        value: "ДЕСПОТОВАЦ_ЈЕЛОВАЦ_70491_713473",
    },
    {
        label: "ДЕСПОТОВАЦ, ЛИПОВИЦА",
        value: "ДЕСПОТОВАЦ_ЛИПОВИЦА_70491_713490",
    },
    {
        label: "ДЕСПОТОВАЦ, ЛОМНИЦА",
        value: "ДЕСПОТОВАЦ_ЛОМНИЦА_70491_713503",
    },
    {
        label: "ДЕСПОТОВАЦ, МЕДВЕЂА",
        value: "ДЕСПОТОВАЦ_МЕДВЕЂА_70491_713511",
    },
    {
        label: "ДЕСПОТОВАЦ, МИЛИВА",
        value: "ДЕСПОТОВАЦ_МИЛИВА_70491_713520",
    },
    {
        label: "ДЕСПОТОВАЦ, ПАЊЕВАЦ",
        value: "ДЕСПОТОВАЦ_ПАЊЕВАЦ_70491_713538",
    },
    {
        label: "ДЕСПОТОВАЦ, ПЛАЖАНЕ",
        value: "ДЕСПОТОВАЦ_ПЛАЖАНЕ_70491_713546",
    },
    {
        label: "ДЕСПОТОВАЦ, ПОПОВЊАК",
        value: "ДЕСПОТОВАЦ_ПОПОВЊАК_70491_713554",
    },
    {
        label: "ДЕСПОТОВАЦ, РАВНА РЕКА",
        value: "ДЕСПОТОВАЦ_РАВНА РЕКА_70491_713562",
    },
    {
        label: "ДЕСПОТОВАЦ, РЕСАВИЦА",
        value: "ДЕСПОТОВАЦ_РЕСАВИЦА_70491_713589",
    },
    {
        label: "ДЕСПОТОВАЦ, СЕЊСКИ РУДНИК",
        value: "ДЕСПОТОВАЦ_СЕЊСКИ РУДНИК_70491_732591",
    },
    {
        label: "ДЕСПОТОВАЦ, СЛАДАЈА",
        value: "ДЕСПОТОВАЦ_СЛАДАЈА_70491_713597",
    },
    {
        label: "ДЕСПОТОВАЦ, СТЕЊЕВАЦ",
        value: "ДЕСПОТОВАЦ_СТЕЊЕВАЦ_70491_713619",
    },
    {
        label: "ДЕСПОТОВАЦ, СТРМОСТЕН",
        value: "ДЕСПОТОВАЦ_СТРМОСТЕН_70491_713627",
    },
    {
        label: "ДЕСПОТОВАЦ, ТРУЋЕВАЦ",
        value: "ДЕСПОТОВАЦ_ТРУЋЕВАЦ_70491_713635",
    },
    {
        label: "ДИМИТРОВГРАД, БАЉЕВ ДОЛ",
        value: "ДИМИТРОВГРАД_БАЉЕВ ДОЛ_70505_713686",
    },
    {
        label: "ДИМИТРОВГРАД, БАЊСКИ ДОЛ",
        value: "ДИМИТРОВГРАД_БАЊСКИ ДОЛ_70505_713643",
    },
    {
        label: "ДИМИТРОВГРАД, БАРЈЕ",
        value: "ДИМИТРОВГРАД_БАРЈЕ_70505_713651",
    },
    {
        label: "ДИМИТРОВГРАД, БАЧЕВО",
        value: "ДИМИТРОВГРАД_БАЧЕВО_70505_713660",
    },
    {
        label: "ДИМИТРОВГРАД, БИЛО",
        value: "ДИМИТРОВГРАД_БИЛО_70505_713678",
    },
    {
        label: "ДИМИТРОВГРАД, БРАЋЕВЦИ",
        value: "ДИМИТРОВГРАД_БРАЋЕВЦИ_70505_713694",
    },
    {
        label: "ДИМИТРОВГРАД, БРЕБЕВНИЦА",
        value: "ДИМИТРОВГРАД_БРЕБЕВНИЦА_70505_713708",
    },
    {
        label: "ДИМИТРОВГРАД, ВЕРЗАР",
        value: "ДИМИТРОВГРАД_ВЕРЗАР_70505_713716",
    },
    {
        label: "ДИМИТРОВГРАД, ВИСОЧКИ ОДОРОВЦИ",
        value: "ДИМИТРОВГРАД_ВИСОЧКИ ОДОРОВЦИ_70505_713724",
    },
    {
        label: "ДИМИТРОВГРАД, ВЛКОВИЈА",
        value: "ДИМИТРОВГРАД_ВЛКОВИЈА_70505_713759",
    },
    {
        label: "ДИМИТРОВГРАД, ВРАПЧА",
        value: "ДИМИТРОВГРАД_ВРАПЧА_70505_713767",
    },
    {
        label: "ДИМИТРОВГРАД, ГОЈИН ДОЛ",
        value: "ДИМИТРОВГРАД_ГОЈИН ДОЛ_70505_713775",
    },
    {
        label: "ДИМИТРОВГРАД, ГОРЊА НЕВЉА",
        value: "ДИМИТРОВГРАД_ГОРЊА НЕВЉА_70505_713791",
    },
    {
        label: "ДИМИТРОВГРАД, ГОРЊА ПЛАНИНИЦА",
        value: "ДИМИТРОВГРАД_ГОРЊА ПЛАНИНИЦА_70505_714011",
    },
    {
        label: "ДИМИТРОВГРАД, ГОРЊИ КРИВОДОЛ",
        value: "ДИМИТРОВГРАД_ГОРЊИ КРИВОДОЛ_70505_713805",
    },
    {
        label: "ДИМИТРОВГРАД, ГРАДИЊЕ",
        value: "ДИМИТРОВГРАД_ГРАДИЊЕ_70505_713813",
    },
    {
        label: "ДИМИТРОВГРАД, ГРАПА",
        value: "ДИМИТРОВГРАД_ГРАПА_70505_713821",
    },
    {
        label: "ДИМИТРОВГРАД, ГУЛЕНОВЦИ",
        value: "ДИМИТРОВГРАД_ГУЛЕНОВЦИ_70505_713830",
    },
    {
        label: "ДИМИТРОВГРАД, ДИМИТРОВГРАД",
        value: "ДИМИТРОВГРАД_ДИМИТРОВГРАД_70505_713848",
    },
    {
        label: "ДИМИТРОВГРАД, ДОЊА НЕВЉА",
        value: "ДИМИТРОВГРАД_ДОЊА НЕВЉА_70505_713856",
    },
    {
        label: "ДИМИТРОВГРАД, ДОЊИ КРИВОДОЛ",
        value: "ДИМИТРОВГРАД_ДОЊИ КРИВОДОЛ_70505_713864",
    },
    {
        label: "ДИМИТРОВГРАД, ДРАГОВИТА",
        value: "ДИМИТРОВГРАД_ДРАГОВИТА_70505_713872",
    },
    {
        label: "ДИМИТРОВГРАД, ЖЕЉУША",
        value: "ДИМИТРОВГРАД_ЖЕЉУША_70505_713899",
    },
    {
        label: "ДИМИТРОВГРАД, ИЗАТОВЦИ",
        value: "ДИМИТРОВГРАД_ИЗАТОВЦИ_70505_713902",
    },
    {
        label: "ДИМИТРОВГРАД, ИСКРОВЦИ",
        value: "ДИМИТРОВГРАД_ИСКРОВЦИ_70505_713929",
    },
    {
        label: "ДИМИТРОВГРАД, КАМЕНИЦА",
        value: "ДИМИТРОВГРАД_КАМЕНИЦА_70505_713937",
    },
    {
        label: "ДИМИТРОВГРАД, КУСА ВРАНА",
        value: "ДИМИТРОВГРАД_КУСА ВРАНА_70505_713945",
    },
    {
        label: "ДИМИТРОВГРАД, ЛУКАВИЦА",
        value: "ДИМИТРОВГРАД_ЛУКАВИЦА_70505_713953",
    },
    {
        label: "ДИМИТРОВГРАД, МАЗГОШ",
        value: "ДИМИТРОВГРАД_МАЗГОШ_70505_713961",
    },
    {
        label: "ДИМИТРОВГРАД, МОЈИНЦИ",
        value: "ДИМИТРОВГРАД_МОЈИНЦИ_70505_713970",
    },
    {
        label: "ДИМИТРОВГРАД, ПАСКАШИЈА",
        value: "ДИМИТРОВГРАД_ПАСКАШИЈА_70505_713988",
    },
    {
        label: "ДИМИТРОВГРАД, ПЕТАЧИНЦИ",
        value: "ДИМИТРОВГРАД_ПЕТАЧИНЦИ_70505_713996",
    },
    {
        label: "ДИМИТРОВГРАД, ПЕТРЛАШ",
        value: "ДИМИТРОВГРАД_ПЕТРЛАШ_70505_714003",
    },
    {
        label: "ДИМИТРОВГРАД, ПОГАНОВО",
        value: "ДИМИТРОВГРАД_ПОГАНОВО_70505_714020",
    },
    {
        label: "ДИМИТРОВГРАД, ПРАЧА",
        value: "ДИМИТРОВГРАД_ПРАЧА_70505_714038",
    },
    {
        label: "ДИМИТРОВГРАД, ПРОТОПОПИНЦИ",
        value: "ДИМИТРОВГРАД_ПРОТОПОПИНЦИ_70505_714046",
    },
    {
        label: "ДИМИТРОВГРАД, РАДЕЈНА",
        value: "ДИМИТРОВГРАД_РАДЕЈНА_70505_714054",
    },
    {
        label: "ДИМИТРОВГРАД, СЕНОКОС",
        value: "ДИМИТРОВГРАД_СЕНОКОС_70505_714062",
    },
    {
        label: "ДИМИТРОВГРАД, СКРВЕНИЦА",
        value: "ДИМИТРОВГРАД_СКРВЕНИЦА_70505_714089",
    },
    {
        label: "ДИМИТРОВГРАД, СЛИВНИЦА",
        value: "ДИМИТРОВГРАД_СЛИВНИЦА_70505_714119",
    },
    {
        label: "ДИМИТРОВГРАД, СМИЛОВЦИ",
        value: "ДИМИТРОВГРАД_СМИЛОВЦИ_70505_714127",
    },
    {
        label: "ДИМИТРОВГРАД, ТРНСКИ ОДОРОВЦИ",
        value: "ДИМИТРОВГРАД_ТРНСКИ ОДОРОВЦИ_70505_714135",
    },
    {
        label: "ДОЉЕВАЦ, БЕЛОТИНАЦ",
        value: "ДОЉЕВАЦ_БЕЛОТИНАЦ_70513_729051",
    },
    {
        label: "ДОЉЕВАЦ, ДОЉЕВАЦ",
        value: "ДОЉЕВАЦ_ДОЉЕВАЦ_70513_714992",
    },
    {
        label: "ДОЉЕВАЦ, КЛИСУРА",
        value: "ДОЉЕВАЦ_КЛИСУРА_70513_729558",
    },
    {
        label: "ДОЉЕВАЦ, КНЕЖИЦА",
        value: "ДОЉЕВАЦ_КНЕЖИЦА_70513_729566",
    },
    {
        label: "ДОЉЕВАЦ, КОЧАНЕ",
        value: "ДОЉЕВАЦ_КОЧАНЕ_70513_715263",
    },
    {
        label: "ДОЉЕВАЦ, МАЛОШИШТЕ",
        value: "ДОЉЕВАЦ_МАЛОШИШТЕ_70513_729701",
    },
    {
        label: "ДОЉЕВАЦ, МЕКИШ",
        value: "ДОЉЕВАЦ_МЕКИШ_70513_715328",
    },
    {
        label: "ДОЉЕВАЦ, ОРЉАНЕ",
        value: "ДОЉЕВАЦ_ОРЉАНЕ_70513_715433",
    },
    {
        label: "ДОЉЕВАЦ, ПЕРУТИНА",
        value: "ДОЉЕВАЦ_ПЕРУТИНА_70513_729914",
    },
    {
        label: "ДОЉЕВАЦ, ПУКОВАЦ",
        value: "ДОЉЕВАЦ_ПУКОВАЦ_70513_715492",
    },
    {
        label: "ДОЉЕВАЦ, РУСНА",
        value: "ДОЉЕВАЦ_РУСНА_70513_730009",
    },
    {
        label: "ДОЉЕВАЦ, ЋУРЛИНА",
        value: "ДОЉЕВАЦ_ЋУРЛИНА_70513_730084",
    },
    {
        label: "ДОЉЕВАЦ, ЧАПЉИНАЦ",
        value: "ДОЉЕВАЦ_ЧАПЉИНАЦ_70513_730149",
    },
    {
        label: "ДОЉЕВАЦ, ЧЕЧИНА",
        value: "ДОЉЕВАЦ_ЧЕЧИНА_70513_730157",
    },
    {
        label: "ДОЉЕВАЦ, ШАЈИНОВАЦ",
        value: "ДОЉЕВАЦ_ШАЈИНОВАЦ_70513_715646",
    },
    {
        label: "ДОЉЕВАЦ, ШАРЛИНЦЕ",
        value: "ДОЉЕВАЦ_ШАРЛИНЦЕ_70513_715654",
    },
    {
        label: "ЖАБАЉ, ГОСПОЂИНЦИ",
        value: "ЖАБАЉ_ГОСПОЂИНЦИ_80136_804614",
    },
    {
        label: "ЖАБАЉ, ЂУРЂЕВО",
        value: "ЖАБАЉ_ЂУРЂЕВО_80136_804584",
    },
    {
        label: "ЖАБАЉ, ЖАБАЉ",
        value: "ЖАБАЉ_ЖАБАЉ_80136_804746",
    },
    {
        label: "ЖАБАЉ, ЧУРУГ",
        value: "ЖАБАЉ_ЧУРУГ_80136_804568",
    },
    {
        label: "ЖАБАРИ, АЛЕКСАНДРОВАЦ",
        value: "ЖАБАРИ_АЛЕКСАНДРОВАЦ_70521_714143",
    },
    {
        label: "ЖАБАРИ, БРЗОХОДЕ",
        value: "ЖАБАРИ_БРЗОХОДЕ_70521_714160",
    },
    {
        label: "ЖАБАРИ, ВИТЕЖЕВО",
        value: "ЖАБАРИ_ВИТЕЖЕВО_70521_738522",
    },
    {
        label: "ЖАБАРИ, ВЛАШКИ ДО",
        value: "ЖАБАРИ_ВЛАШКИ ДО_70521_714178",
    },
    {
        label: "ЖАБАРИ, ЖАБАРИ",
        value: "ЖАБАРИ_ЖАБАРИ_70521_745065",
    },
    {
        label: "ЖАБАРИ, ЖАБАРСКО БЛАТО",
        value: "ЖАБАРИ_ЖАБАРСКО БЛАТО_70521_745235",
    },
    {
        label: "ЖАБАРИ, КОЧЕТИН",
        value: "ЖАБАРИ_КОЧЕТИН_70521_714224",
    },
    {
        label: "ЖАБАРИ, МИРИЈЕВО",
        value: "ЖАБАРИ_МИРИЈЕВО_70521_714232",
    },
    {
        label: "ЖАБАРИ, ОРЕОВИЦА",
        value: "ЖАБАРИ_ОРЕОВИЦА_70521_714259",
    },
    {
        label: "ЖАБАРИ, ПОЛАТНА",
        value: "ЖАБАРИ_ПОЛАТНА_70521_714275",
    },
    {
        label: "ЖАБАРИ, ПОРОДИН",
        value: "ЖАБАРИ_ПОРОДИН_70521_714283",
    },
    {
        label: "ЖАБАРИ, СВИЊАРЕВО",
        value: "ЖАБАРИ_СВИЊАРЕВО_70521_714291",
    },
    {
        label: "ЖАБАРИ, СИБНИЦА",
        value: "ЖАБАРИ_СИБНИЦА_70521_714305",
    },
    {
        label: "ЖАБАРИ, СИМИЋЕВО",
        value: "ЖАБАРИ_СИМИЋЕВО_70521_714313",
    },
    {
        label: "ЖАБАРИ, ТИЋЕВАЦ",
        value: "ЖАБАРИ_ТИЋЕВАЦ_70521_714321",
    },
    {
        label: "ЖАБАРИ, ЧЕТЕРЕЖЕ",
        value: "ЖАБАРИ_ЧЕТЕРЕЖЕ_70521_714330",
    },
    {
        label: "ЖАГУБИЦА, БЛИЗНАК",
        value: "ЖАГУБИЦА_БЛИЗНАК_70530_714348",
    },
    {
        label: "ЖАГУБИЦА, БРЕЗНИЦА",
        value: "ЖАГУБИЦА_БРЕЗНИЦА_70530_714356",
    },
    {
        label: "ЖАГУБИЦА, ВУКОВАЦ",
        value: "ЖАГУБИЦА_ВУКОВАЦ_70530_714372",
    },
    {
        label: "ЖАГУБИЦА, ГОРЊАК",
        value: "ЖАГУБИЦА_ГОРЊАК_70530_714399",
    },
    {
        label: "ЖАГУБИЦА, ЖАГУБИЦА",
        value: "ЖАГУБИЦА_ЖАГУБИЦА_70530_714402",
    },
    {
        label: "ЖАГУБИЦА, ИЗВАРИЦА",
        value: "ЖАГУБИЦА_ИЗВАРИЦА_70530_714429",
    },
    {
        label: "ЖАГУБИЦА, ЈОШАНИЦА",
        value: "ЖАГУБИЦА_ЈОШАНИЦА_70530_714445",
    },
    {
        label: "ЖАГУБИЦА, КРЕПОЉИН",
        value: "ЖАГУБИЦА_КРЕПОЉИН_70530_714453",
    },
    {
        label: "ЖАГУБИЦА, КРУПАЈА",
        value: "ЖАГУБИЦА_КРУПАЈА_70530_714461",
    },
    {
        label: "ЖАГУБИЦА, ЛАЗНИЦА-СЕЛИШТЕ",
        value: "ЖАГУБИЦА_ЛАЗНИЦА-СЕЛИШТЕ_70530_714470",
    },
    {
        label: "ЖАГУБИЦА, ЛАЗНИЦА-СЕЛО",
        value: "ЖАГУБИЦА_ЛАЗНИЦА-СЕЛО_70530_714488",
    },
    {
        label: "ЖАГУБИЦА, МАЛИ КАМЕН",
        value: "ЖАГУБИЦА_МАЛИ КАМЕН_70530_714500",
    },
    {
        label: "ЖАГУБИЦА, МЕДВЕЂИЦА",
        value: "ЖАГУБИЦА_МЕДВЕЂИЦА_70530_714518",
    },
    {
        label: "ЖАГУБИЦА, МИЛАНОВАЦ",
        value: "ЖАГУБИЦА_МИЛАНОВАЦ_70530_714526",
    },
    {
        label: "ЖАГУБИЦА, МИЛАТОВАЦ",
        value: "ЖАГУБИЦА_МИЛАТОВАЦ_70530_714534",
    },
    {
        label: "ЖАГУБИЦА, ОСАНИЦА",
        value: "ЖАГУБИЦА_ОСАНИЦА_70530_714542",
    },
    {
        label: "ЖАГУБИЦА, РИБАРЕ",
        value: "ЖАГУБИЦА_РИБАРЕ_70530_714569",
    },
    {
        label: "ЖАГУБИЦА, СИГЕ",
        value: "ЖАГУБИЦА_СИГЕ_70530_714577",
    },
    {
        label: "ЖАГУБИЦА, СУВИ ДО",
        value: "ЖАГУБИЦА_СУВИ ДО_70530_714585",
    },
    {
        label: "ЖИТИШТЕ, БАНАТСКИ ДВОР",
        value: "ЖИТИШТЕ_БАНАТСКИ ДВОР_80144_805513",
    },
    {
        label: "ЖИТИШТЕ, БАНАТСКО ВИШЊИЋЕВО",
        value: "ЖИТИШТЕ_БАНАТСКО ВИШЊИЋЕВО_80144_800953",
    },
    {
        label: "ЖИТИШТЕ, БАНАТСКО КАРАЂОРЂЕВО",
        value: "ЖИТИШТЕ_БАНАТСКО КАРАЂОРЂЕВО_80144_800821",
    },
    {
        label: "ЖИТИШТЕ, БЕГЕЈЦИ",
        value: "ЖИТИШТЕ_БЕГЕЈЦИ_80144_805521",
    },
    {
        label: "ЖИТИШТЕ, ЖИТИШТЕ",
        value: "ЖИТИШТЕ_ЖИТИШТЕ_80144_805785",
    },
    {
        label: "ЖИТИШТЕ, МЕЂА",
        value: "ЖИТИШТЕ_МЕЂА_80144_801003",
    },
    {
        label: "ЖИТИШТЕ, НОВИ ИТЕБЕЈ",
        value: "ЖИТИШТЕ_НОВИ ИТЕБЕЈ_80144_801020",
    },
    {
        label: "ЖИТИШТЕ, РАВНИ ТОПОЛОВАЦ",
        value: "ЖИТИШТЕ_РАВНИ ТОПОЛОВАЦ_80144_805688",
    },
    {
        label: "ЖИТИШТЕ, СРПСКИ ИТЕБЕЈ",
        value: "ЖИТИШТЕ_СРПСКИ ИТЕБЕЈ_80144_801046",
    },
    {
        label: "ЖИТИШТЕ, ТОРДА",
        value: "ЖИТИШТЕ_ТОРДА_80144_805734",
    },
    {
        label: "ЖИТИШТЕ, ХЕТИН",
        value: "ЖИТИШТЕ_ХЕТИН_80144_800848",
    },
    {
        label: "ЖИТИШТЕ, ЧЕСТЕРЕГ",
        value: "ЖИТИШТЕ_ЧЕСТЕРЕГ_80144_800830",
    },
    {
        label: "ЖИТОРАЂА, АСАНОВАЦ",
        value: "ЖИТОРАЂА_АСАНОВАЦ_70548_714623",
    },
    {
        label: "ЖИТОРАЂА, БАДЊЕВАЦ",
        value: "ЖИТОРАЂА_БАДЊЕВАЦ_70548_714640",
    },
    {
        label: "ЖИТОРАЂА, ВЛАХОВО",
        value: "ЖИТОРАЂА_ВЛАХОВО_70548_714828",
    },
    {
        label: "ЖИТОРАЂА, ВОЉЧИНЦЕ",
        value: "ЖИТОРАЂА_ВОЉЧИНЦЕ_70548_714836",
    },
    {
        label: "ЖИТОРАЂА, ГЛАШИНЦЕ",
        value: "ЖИТОРАЂА_ГЛАШИНЦЕ_70548_714852",
    },
    {
        label: "ЖИТОРАЂА, ГОРЊЕ ЦРНАТОВО",
        value: "ЖИТОРАЂА_ГОРЊЕ ЦРНАТОВО_70548_714909",
    },
    {
        label: "ЖИТОРАЂА, ГОРЊИ ДРЕНОВАЦ",
        value: "ЖИТОРАЂА_ГОРЊИ ДРЕНОВАЦ_70548_714917",
    },
    {
        label: "ЖИТОРАЂА, ГРУДАШ",
        value: "ЖИТОРАЂА_ГРУДАШ_70548_714941",
    },
    {
        label: "ЖИТОРАЂА, ДЕБЕЛИ ЛУГ",
        value: "ЖИТОРАЂА_ДЕБЕЛИ ЛУГ_70548_714950",
    },
    {
        label: "ЖИТОРАЂА, ДОЊЕ ЦРНАТОВО",
        value: "ЖИТОРАЂА_ДОЊЕ ЦРНАТОВО_70548_715034",
    },
    {
        label: "ЖИТОРАЂА, ДОЊИ ДРЕНОВАЦ",
        value: "ЖИТОРАЂА_ДОЊИ ДРЕНОВАЦ_70548_715042",
    },
    {
        label: "ЖИТОРАЂА, ДРЖАНОВАЦ",
        value: "ЖИТОРАЂА_ДРЖАНОВАЦ_70548_715085",
    },
    {
        label: "ЖИТОРАЂА, ДУБОВО",
        value: "ЖИТОРАЂА_ДУБОВО_70548_715093",
    },
    {
        label: "ЖИТОРАЂА, ЂАКУС",
        value: "ЖИТОРАЂА_ЂАКУС_70548_715107",
    },
    {
        label: "ЖИТОРАЂА, ЖИТОРАЂА",
        value: "ЖИТОРАЂА_ЖИТОРАЂА_70548_715131",
    },
    {
        label: "ЖИТОРАЂА, ЈАСЕНИЦА",
        value: "ЖИТОРАЂА_ЈАСЕНИЦА_70548_715166",
    },
    {
        label: "ЖИТОРАЂА, КАРЕ",
        value: "ЖИТОРАЂА_КАРЕ_70548_715204",
    },
    {
        label: "ЖИТОРАЂА, КОЊАРНИК",
        value: "ЖИТОРАЂА_КОЊАРНИК_70548_715247",
    },
    {
        label: "ЖИТОРАЂА, ЛУКОМИР",
        value: "ЖИТОРАЂА_ЛУКОМИР_70548_715301",
    },
    {
        label: "ЖИТОРАЂА, НОВО МОМЧИЛОВО",
        value: "ЖИТОРАЂА_НОВО МОМЧИЛОВО_70548_715409",
    },
    {
        label: "ЖИТОРАЂА, ПЕЈКОВАЦ",
        value: "ЖИТОРАЂА_ПЕЈКОВАЦ_70548_715450",
    },
    {
        label: "ЖИТОРАЂА, ПОДИНА",
        value: "ЖИТОРАЂА_ПОДИНА_70548_715476",
    },
    {
        label: "ЖИТОРАЂА, РЕЧИЦА",
        value: "ЖИТОРАЂА_РЕЧИЦА_70548_715522",
    },
    {
        label: "ЖИТОРАЂА, САМАРИНОВАЦ",
        value: "ЖИТОРАЂА_САМАРИНОВАЦ_70548_715557",
    },
    {
        label: "ЖИТОРАЂА, СМРДИЋ",
        value: "ЖИТОРАЂА_СМРДИЋ_70548_715565",
    },
    {
        label: "ЖИТОРАЂА, СТАРА БОЖУРНА",
        value: "ЖИТОРАЂА_СТАРА БОЖУРНА_70548_715581",
    },
    {
        label: "ЖИТОРАЂА, СТАРО МОМЧИЛОВО",
        value: "ЖИТОРАЂА_СТАРО МОМЧИЛОВО_70548_715603",
    },
    {
        label: "ЖИТОРАЂА, СТУДЕНАЦ",
        value: "ЖИТОРАЂА_СТУДЕНАЦ_70548_715611",
    },
    {
        label: "ЖИТОРАЂА, ТОПОНИЦА",
        value: "ЖИТОРАЂА_ТОПОНИЦА_70548_715620",
    },
    {
        label: "ЗАЈЕЧАР, БОРОВАЦ",
        value: "ЗАЈЕЧАР_БОРОВАЦ_70556_717908",
    },
    {
        label: "ЗАЈЕЧАР, БРУСНИК",
        value: "ЗАЈЕЧАР_БРУСНИК_70556_705152",
    },
    {
        label: "ЗАЈЕЧАР, ВЕЛИКА ЈАСИКОВА",
        value: "ЗАЈЕЧАР_ВЕЛИКА ЈАСИКОВА_70556_705179",
    },
    {
        label: "ЗАЈЕЧАР, ВЕЛИКИ ИЗВОР",
        value: "ЗАЈЕЧАР_ВЕЛИКИ ИЗВОР_70556_715727",
    },
    {
        label: "ЗАЈЕЧАР, ВЕЛИКИ ЈАСЕНОВАЦ",
        value: "ЗАЈЕЧАР_ВЕЛИКИ ЈАСЕНОВАЦ_70556_705187",
    },
    {
        label: "ЗАЈЕЧАР, ВРАЖОГРНАЦ",
        value: "ЗАЈЕЧАР_ВРАЖОГРНАЦ_70556_715735",
    },
    {
        label: "ЗАЈЕЧАР, ВРАТАРНИЦА",
        value: "ЗАЈЕЧАР_ВРАТАРНИЦА_70556_715743",
    },
    {
        label: "ЗАЈЕЧАР, ВРБИЦА",
        value: "ЗАЈЕЧАР_ВРБИЦА_70556_718009",
    },
    {
        label: "ЗАЈЕЧАР, ГАМЗИГРАД",
        value: "ЗАЈЕЧАР_ГАМЗИГРАД_70556_715751",
    },
    {
        label: "ЗАЈЕЧАР, ГЛОГОВИЦА",
        value: "ЗАЈЕЧАР_ГЛОГОВИЦА_70556_705195",
    },
    {
        label: "ЗАЈЕЧАР, ГОРЊА БЕЛА РЕКА",
        value: "ЗАЈЕЧАР_ГОРЊА БЕЛА РЕКА_70556_715760",
    },
    {
        label: "ЗАЈЕЧАР, ГРАДСКОВО",
        value: "ЗАЈЕЧАР_ГРАДСКОВО_70556_715778",
    },
    {
        label: "ЗАЈЕЧАР, ГРЛИШТЕ",
        value: "ЗАЈЕЧАР_ГРЛИШТЕ_70556_715786",
    },
    {
        label: "ЗАЈЕЧАР, ГРЉАН",
        value: "ЗАЈЕЧАР_ГРЉАН_70556_715794",
    },
    {
        label: "ЗАЈЕЧАР, ДУБОЧАНЕ",
        value: "ЗАЈЕЧАР_ДУБОЧАНЕ_70556_705209",
    },
    {
        label: "ЗАЈЕЧАР, ЗАГРАЂЕ",
        value: "ЗАЈЕЧАР_ЗАГРАЂЕ_70556_715816",
    },
    {
        label: "ЗАЈЕЧАР, ЗАЈЕЧАР",
        value: "ЗАЈЕЧАР_ЗАЈЕЧАР_70556_745073",
    },
    {
        label: "ЗАЈЕЧАР, ЗВЕЗДАН",
        value: "ЗАЈЕЧАР_ЗВЕЗДАН_70556_715859",
    },
    {
        label: "ЗАЈЕЧАР, ЈЕЛАШНИЦА",
        value: "ЗАЈЕЧАР_ЈЕЛАШНИЦА_70556_705217",
    },
    {
        label: "ЗАЈЕЧАР, КЛЕНОВАЦ",
        value: "ЗАЈЕЧАР_КЛЕНОВАЦ_70556_705225",
    },
    {
        label: "ЗАЈЕЧАР, КОПРИВНИЦА",
        value: "ЗАЈЕЧАР_КОПРИВНИЦА_70556_705233",
    },
    {
        label: "ЗАЈЕЧАР, ЛАСОВО",
        value: "ЗАЈЕЧАР_ЛАСОВО_70556_705047",
    },
    {
        label: "ЗАЈЕЧАР, ЛЕНОВАЦ",
        value: "ЗАЈЕЧАР_ЛЕНОВАЦ_70556_715875",
    },
    {
        label: "ЗАЈЕЧАР, ЛЕСКОВАЦ",
        value: "ЗАЈЕЧАР_ЛЕСКОВАЦ_70556_715883",
    },
    {
        label: "ЗАЈЕЧАР, ЛУБНИЦА",
        value: "ЗАЈЕЧАР_ЛУБНИЦА_70556_715891",
    },
    {
        label: "ЗАЈЕЧАР, МАЛА ЈАСИКОВА",
        value: "ЗАЈЕЧАР_МАЛА ЈАСИКОВА_70556_705250",
    },
    {
        label: "ЗАЈЕЧАР, МАЛИ ИЗВОР",
        value: "ЗАЈЕЧАР_МАЛИ ИЗВОР_70556_718378",
    },
    {
        label: "ЗАЈЕЧАР, МАЛИ ЈАСЕНОВАЦ",
        value: "ЗАЈЕЧАР_МАЛИ ЈАСЕНОВАЦ_70556_705268",
    },
    {
        label: "ЗАЈЕЧАР, МАРИНОВАЦ",
        value: "ЗАЈЕЧАР_МАРИНОВАЦ_70556_718394",
    },
    {
        label: "ЗАЈЕЧАР, МЕТРИШ",
        value: "ЗАЈЕЧАР_МЕТРИШ_70556_705276",
    },
    {
        label: "ЗАЈЕЧАР, НИКОЛИЧЕВО",
        value: "ЗАЈЕЧАР_НИКОЛИЧЕВО_70556_715913",
    },
    {
        label: "ЗАЈЕЧАР, ПЛАНИНИЦА",
        value: "ЗАЈЕЧАР_ПЛАНИНИЦА_70556_705098",
    },
    {
        label: "ЗАЈЕЧАР, ПРЛИТА",
        value: "ЗАЈЕЧАР_ПРЛИТА_70556_715930",
    },
    {
        label: "ЗАЈЕЧАР, РГОТИНА",
        value: "ЗАЈЕЧАР_РГОТИНА_70556_715948",
    },
    {
        label: "ЗАЈЕЧАР, САЛАШ",
        value: "ЗАЈЕЧАР_САЛАШ_70556_745022",
    },
    {
        label: "ЗАЈЕЧАР, СЕЛАЧКА",
        value: "ЗАЈЕЧАР_СЕЛАЧКА_70556_718572",
    },
    {
        label: "ЗАЈЕЧАР, ТАБАКОВАЦ",
        value: "ЗАЈЕЧАР_ТАБАКОВАЦ_70556_705322",
    },
    {
        label: "ЗАЈЕЧАР, ТРНАВАЦ",
        value: "ЗАЈЕЧАР_ТРНАВАЦ_70556_715972",
    },
    {
        label: "ЗАЈЕЧАР, ХАЛОВО",
        value: "ЗАЈЕЧАР_ХАЛОВО_70556_715999",
    },
    {
        label: "ЗАЈЕЧАР, ШИПИКОВО",
        value: "ЗАЈЕЧАР_ШИПИКОВО_70556_705365",
    },
    {
        label: "ЗАЈЕЧАР, ШЉИВАР",
        value: "ЗАЈЕЧАР_ШЉИВАР_70556_716006",
    },
    {
        label: "ЗВЕЗДАРА, ВЕЛИКИ МОКРИ ЛУГ",
        value: "ЗВЕЗДАРА_ВЕЛИКИ МОКРИ ЛУГ_70149_703583",
    },
    {
        label: "ЗВЕЗДАРА, ЗВЕЗДАРА",
        value: "ЗВЕЗДАРА_ЗВЕЗДАРА_70149_703745",
    },
    {
        label: "ЗВЕЗДАРА, МАЛИ МОКРИ ЛУГ",
        value: "ЗВЕЗДАРА_МАЛИ МОКРИ ЛУГ_70149_703842",
    },
    {
        label: "ЗВЕЗДАРА, МИРИЈЕВО",
        value: "ЗВЕЗДАРА_МИРИЈЕВО_70149_703885",
    },
    {
        label: "ЗЕМУН, БАТАЈНИЦА",
        value: "ЗЕМУН_БАТАЈНИЦА_70157_716014",
    },
    {
        label: "ЗЕМУН, ЗЕМУН",
        value: "ЗЕМУН_ЗЕМУН_70157_716065",
    },
    {
        label: "ЗЕМУН, ЗЕМУН ПОЉЕ",
        value: "ЗЕМУН_ЗЕМУН ПОЉЕ_70157_716073",
    },
    {
        label: "ЗЕМУН, УГРИНОВЦИ",
        value: "ЗЕМУН_УГРИНОВЦИ_70157_716138",
    },
    {
        label: "ЗРЕЊАНИН, БАНАТСКИ ДЕСПОТОВАЦ",
        value: "ЗРЕЊАНИН_БАНАТСКИ ДЕСПОТОВАЦ_80152_800945",
    },
    {
        label: "ЗРЕЊАНИН, БЕЛО БЛАТО",
        value: "ЗРЕЊАНИН_БЕЛО БЛАТО_80152_802581",
    },
    {
        label: "ЗРЕЊАНИН, БОТОШ",
        value: "ЗРЕЊАНИН_БОТОШ_80152_802603",
    },
    {
        label: "ЗРЕЊАНИН, ЕЛЕМИР",
        value: "ЗРЕЊАНИН_ЕЛЕМИР_80152_805548",
    },
    {
        label: "ЗРЕЊАНИН, ЕЧКА",
        value: "ЗРЕЊАНИН_ЕЧКА_80152_805530",
    },
    {
        label: "ЗРЕЊАНИН, ЗРЕЊАНИН I",
        value: "ЗРЕЊАНИН_ЗРЕЊАНИН I_80152_805742",
    },
    {
        label: "ЗРЕЊАНИН, ЗРЕЊАНИН III",
        value: "ЗРЕЊАНИН_ЗРЕЊАНИН III_80152_805777",
    },
    {
        label: "ЗРЕЊАНИН, ЈАНКОВ МОСТ",
        value: "ЗРЕЊАНИН_ЈАНКОВ МОСТ_80152_805556",
    },
    {
        label: "ЗРЕЊАНИН, КЛЕК",
        value: "ЗРЕЊАНИН_КЛЕК_80152_805564",
    },
    {
        label: "ЗРЕЊАНИН, КНИЋАНИН",
        value: "ЗРЕЊАНИН_КНИЋАНИН_80152_802727",
    },
    {
        label: "ЗРЕЊАНИН, ЛАЗАРЕВО",
        value: "ЗРЕЊАНИН_ЛАЗАРЕВО_80152_805599",
    },
    {
        label: "ЗРЕЊАНИН, ЛУКИНО СЕЛО",
        value: "ЗРЕЊАНИН_ЛУКИНО СЕЛО_80152_805629",
    },
    {
        label: "ЗРЕЊАНИН, ЛУКИЋЕВО",
        value: "ЗРЕЊАНИН_ЛУКИЋЕВО_80152_805602",
    },
    {
        label: "ЗРЕЊАНИН, МЕЛЕНЦИ",
        value: "ЗРЕЊАНИН_МЕЛЕНЦИ_80152_805637",
    },
    {
        label: "ЗРЕЊАНИН, МИХАЈЛОВО",
        value: "ЗРЕЊАНИН_МИХАЈЛОВО_80152_805645",
    },
    {
        label: "ЗРЕЊАНИН, МУЖЉА",
        value: "ЗРЕЊАНИН_МУЖЉА_80152_805653",
    },
    {
        label: "ЗРЕЊАНИН, ОРЛОВАТ",
        value: "ЗРЕЊАНИН_ОРЛОВАТ_80152_802760",
    },
    {
        label: "ЗРЕЊАНИН, ПЕРЛЕЗ",
        value: "ЗРЕЊАНИН_ПЕРЛЕЗ_80152_802794",
    },
    {
        label: "ЗРЕЊАНИН, СЛОВАЧКИ АРАДАЦ",
        value: "ЗРЕЊАНИН_СЛОВАЧКИ АРАДАЦ_80152_805696",
    },
    {
        label: "ЗРЕЊАНИН, СРПСКИ АРАДАЦ",
        value: "ЗРЕЊАНИН_СРПСКИ АРАДАЦ_80152_805491",
    },
    {
        label: "ЗРЕЊАНИН, СРПСКИ ЕЛЕМИР",
        value: "ЗРЕЊАНИН_СРПСКИ ЕЛЕМИР_80152_805700",
    },
    {
        label: "ЗРЕЊАНИН, СТАЈИЋЕВО",
        value: "ЗРЕЊАНИН_СТАЈИЋЕВО_80152_805718",
    },
    {
        label: "ЗРЕЊАНИН, ТАРАШ",
        value: "ЗРЕЊАНИН_ТАРАШ_80152_805726",
    },
    {
        label: "ЗРЕЊАНИН, ТАРАШ I",
        value: "ЗРЕЊАНИН_ТАРАШ I_80152_805793",
    },
    {
        label: "ЗРЕЊАНИН, ТОМАШЕВАЦ",
        value: "ЗРЕЊАНИН_ТОМАШЕВАЦ_80152_802824",
    },
    {
        label: "ЗРЕЊАНИН, ФАРКАЖДИН",
        value: "ЗРЕЊАНИН_ФАРКАЖДИН_80152_802654",
    },
    {
        label: "ЗРЕЊАНИН, ЧЕНТА",
        value: "ЗРЕЊАНИН_ЧЕНТА_80152_802620",
    },
    {
        label: "ИВАЊИЦА, БЕДИНА ВАРОШ",
        value: "ИВАЊИЦА_БЕДИНА ВАРОШ_70564_716154",
    },
    {
        label: "ИВАЊИЦА, БРАТЉЕВО",
        value: "ИВАЊИЦА_БРАТЉЕВО_70564_716162",
    },
    {
        label: "ИВАЊИЦА, БРЕЗОВА",
        value: "ИВАЊИЦА_БРЕЗОВА_70564_716189",
    },
    {
        label: "ИВАЊИЦА, БРУСНИК",
        value: "ИВАЊИЦА_БРУСНИК_70564_716197",
    },
    {
        label: "ИВАЊИЦА, БУДОЖЕЉА",
        value: "ИВАЊИЦА_БУДОЖЕЉА_70564_716219",
    },
    {
        label: "ИВАЊИЦА, ВАСИЉЕВИЋИ",
        value: "ИВАЊИЦА_ВАСИЉЕВИЋИ_70564_716227",
    },
    {
        label: "ИВАЊИЦА, ВИОНИЦА",
        value: "ИВАЊИЦА_ВИОНИЦА_70564_716235",
    },
    {
        label: "ИВАЊИЦА, ВРМБАЈЕ",
        value: "ИВАЊИЦА_ВРМБАЈЕ_70564_716243",
    },
    {
        label: "ИВАЊИЦА, ВУЧАК",
        value: "ИВАЊИЦА_ВУЧАК_70564_716251",
    },
    {
        label: "ИВАЊИЦА, ГЛЕЂИЦА",
        value: "ИВАЊИЦА_ГЛЕЂИЦА_70564_716260",
    },
    {
        label: "ИВАЊИЦА, ГРАДАЦ",
        value: "ИВАЊИЦА_ГРАДАЦ_70564_716278",
    },
    {
        label: "ИВАЊИЦА, ДАЈИЋИ",
        value: "ИВАЊИЦА_ДАЈИЋИ_70564_716286",
    },
    {
        label: "ИВАЊИЦА, ДЕРЕТИН",
        value: "ИВАЊИЦА_ДЕРЕТИН_70564_716294",
    },
    {
        label: "ИВАЊИЦА, ДОБРИ ДО",
        value: "ИВАЊИЦА_ДОБРИ ДО_70564_716308",
    },
    {
        label: "ИВАЊИЦА, ДУБРАВА",
        value: "ИВАЊИЦА_ДУБРАВА_70564_716316",
    },
    {
        label: "ИВАЊИЦА, ЕРЧЕГЕ",
        value: "ИВАЊИЦА_ЕРЧЕГЕ_70564_716324",
    },
    {
        label: "ИВАЊИЦА, ИВАЊИЦА",
        value: "ИВАЊИЦА_ИВАЊИЦА_70564_716332",
    },
    {
        label: "ИВАЊИЦА, КОМАДИНЕ",
        value: "ИВАЊИЦА_КОМАДИНЕ_70564_716359",
    },
    {
        label: "ИВАЊИЦА, КОРИТНИК",
        value: "ИВАЊИЦА_КОРИТНИК_70564_716367",
    },
    {
        label: "ИВАЊИЦА, КОСОВИЦА",
        value: "ИВАЊИЦА_КОСОВИЦА_70564_716375",
    },
    {
        label: "ИВАЊИЦА, КУМАНИЦА",
        value: "ИВАЊИЦА_КУМАНИЦА_70564_716383",
    },
    {
        label: "ИВАЊИЦА, КУШИЋИ",
        value: "ИВАЊИЦА_КУШИЋИ_70564_716391",
    },
    {
        label: "ИВАЊИЦА, ЛИСА",
        value: "ИВАЊИЦА_ЛИСА_70564_726729",
    },
    {
        label: "ИВАЊИЦА, ЛУКЕ",
        value: "ИВАЊИЦА_ЛУКЕ_70564_726753",
    },
    {
        label: "ИВАЊИЦА, МАНА",
        value: "ИВАЊИЦА_МАНА_70564_716405",
    },
    {
        label: "ИВАЊИЦА, МАСКОВА",
        value: "ИВАЊИЦА_МАСКОВА_70564_716413",
    },
    {
        label: "ИВАЊИЦА, МЕДОВИНЕ",
        value: "ИВАЊИЦА_МЕДОВИНЕ_70564_716421",
    },
    {
        label: "ИВАЊИЦА, МОЧИОЦИ",
        value: "ИВАЊИЦА_МОЧИОЦИ_70564_701874",
    },
    {
        label: "ИВАЊИЦА, ОПАЉЕНИК",
        value: "ИВАЊИЦА_ОПАЉЕНИК_70564_716430",
    },
    {
        label: "ИВАЊИЦА, ОСОНИЦА",
        value: "ИВАЊИЦА_ОСОНИЦА_70564_726818",
    },
    {
        label: "ИВАЊИЦА, ПРЕСЕКА",
        value: "ИВАЊИЦА_ПРЕСЕКА_70564_701882",
    },
    {
        label: "ИВАЊИЦА, ПРИЛИКЕ",
        value: "ИВАЊИЦА_ПРИЛИКЕ_70564_716448",
    },
    {
        label: "ИВАЊИЦА, РАВНА ГОРА",
        value: "ИВАЊИЦА_РАВНА ГОРА_70564_716456",
    },
    {
        label: "ИВАЊИЦА, РАДАЉЕВО",
        value: "ИВАЊИЦА_РАДАЉЕВО_70564_716464",
    },
    {
        label: "ИВАЊИЦА, РОВИНЕ",
        value: "ИВАЊИЦА_РОВИНЕ_70564_716472",
    },
    {
        label: "ИВАЊИЦА, РОКЦИ",
        value: "ИВАЊИЦА_РОКЦИ_70564_716499",
    },
    {
        label: "ИВАЊИЦА, СВЕШТИЦА",
        value: "ИВАЊИЦА_СВЕШТИЦА_70564_716502",
    },
    {
        label: "ИВАЊИЦА, СИВЧИНА",
        value: "ИВАЊИЦА_СИВЧИНА_70564_716529",
    },
    {
        label: "ИВАЊИЦА, СМИЉЕВАЦ",
        value: "ИВАЊИЦА_СМИЉЕВАЦ_70564_716537",
    },
    {
        label: "ИВАЊИЦА, ЧЕЧИНА",
        value: "ИВАЊИЦА_ЧЕЧИНА_70564_716545",
    },
    {
        label: "ИВАЊИЦА, ШАРЕНИК",
        value: "ИВАЊИЦА_ШАРЕНИК_70564_716553",
    },
    {
        label: "ИВАЊИЦА, ШУМЕ",
        value: "ИВАЊИЦА_ШУМЕ_70564_716561",
    },
    {
        label: "ИНЂИЈА, БЕШКА",
        value: "ИНЂИЈА_БЕШКА_80179_805190",
    },
    {
        label: "ИНЂИЈА, ИНЂИЈА",
        value: "ИНЂИЈА_ИНЂИЈА_80179_803049",
    },
    {
        label: "ИНЂИЈА, КРЧЕДИН",
        value: "ИНЂИЈА_КРЧЕДИН_80179_805262",
    },
    {
        label: "ИНЂИЈА, ЉУКОВО",
        value: "ИНЂИЈА_ЉУКОВО_80179_803146",
    },
    {
        label: "ИНЂИЈА, МАРАДИК",
        value: "ИНЂИЈА_МАРАДИК_80179_803197",
    },
    {
        label: "ИНЂИЈА, НОВИ КАРЛОВЦИ",
        value: "ИНЂИЈА_НОВИ КАРЛОВЦИ_80179_805335",
    },
    {
        label: "ИНЂИЈА, НОВИ СЛАНКАМЕН",
        value: "ИНЂИЈА_НОВИ СЛАНКАМЕН_80179_805343",
    },
    {
        label: "ИНЂИЈА, СТАРИ СЛАНКАМЕН",
        value: "ИНЂИЈА_СТАРИ СЛАНКАМЕН_80179_805459",
    },
    {
        label: "ИНЂИЈА, ЧОРТАНОВЦИ",
        value: "ИНЂИЈА_ЧОРТАНОВЦИ_80179_805211",
    },
    {
        label: "ИРИГ, БАНКОВЦИ",
        value: "ИРИГ_БАНКОВЦИ_80187_802913",
    },
    {
        label: "ИРИГ, ВЕЛИКА РЕМЕТА",
        value: "ИРИГ_ВЕЛИКА РЕМЕТА_80187_803383",
    },
    {
        label: "ИРИГ, ВРДНИК",
        value: "ИРИГ_ВРДНИК_80187_803421",
    },
    {
        label: "ИРИГ, ГРГЕТЕК",
        value: "ИРИГ_ГРГЕТЕК_80187_803006",
    },
    {
        label: "ИРИГ, ДОБРОДОЛ",
        value: "ИРИГ_ДОБРОДОЛ_80187_805815",
    },
    {
        label: "ИРИГ, ИРИГ",
        value: "ИРИГ_ИРИГ_80187_803057",
    },
    {
        label: "ИРИГ, ЈАЗАК ПРЊАВОР",
        value: "ИРИГ_ЈАЗАК ПРЊАВОР_80187_803073",
    },
    {
        label: "ИРИГ, ЈАЗАК СЕЛО",
        value: "ИРИГ_ЈАЗАК СЕЛО_80187_803065",
    },
    {
        label: "ИРИГ, КРУШЕДОЛ ПРЊАВОР",
        value: "ИРИГ_КРУШЕДОЛ ПРЊАВОР_80187_803103",
    },
    {
        label: "ИРИГ, КРУШЕДОЛ СЕЛО",
        value: "ИРИГ_КРУШЕДОЛ СЕЛО_80187_803090",
    },
    {
        label: "ИРИГ, МАЛА РЕМЕТА",
        value: "ИРИГ_МАЛА РЕМЕТА_80187_803154",
    },
    {
        label: "ИРИГ, НЕРАДИН",
        value: "ИРИГ_НЕРАДИН_80187_803227",
    },
    {
        label: "ИРИГ, РИВИЦА",
        value: "ИРИГ_РИВИЦА_80187_803278",
    },
    {
        label: "ИРИГ, ШАТРИНЦИ",
        value: "ИРИГ_ШАТРИНЦИ_80187_803359",
    },
    {
        label: "ЈАГОДИНА, БАГРДАН (ВАРОШИЦА)",
        value: "ЈАГОДИНА_БАГРДАН (ВАРОШИЦА)_71048_737895",
    },
    {
        label: "ЈАГОДИНА, БАГРДАН (СЕЛО)",
        value: "ЈАГОДИНА_БАГРДАН (СЕЛО)_71048_737909",
    },
    {
        label: "ЈАГОДИНА, БЕЛИЦА",
        value: "ЈАГОДИНА_БЕЛИЦА_71048_737917",
    },
    {
        label: "ЈАГОДИНА, БРЕСЈЕ",
        value: "ЈАГОДИНА_БРЕСЈЕ_71048_737933",
    },
    {
        label: "ЈАГОДИНА, БУКОВЧЕ",
        value: "ЈАГОДИНА_БУКОВЧЕ_71048_737941",
    },
    {
        label: "ЈАГОДИНА, БУНАР",
        value: "ЈАГОДИНА_БУНАР_71048_737950",
    },
    {
        label: "ЈАГОДИНА, ВИНОРАЧА",
        value: "ЈАГОДИНА_ВИНОРАЧА_71048_737968",
    },
    {
        label: "ЈАГОДИНА, ВОЉАВЧЕ",
        value: "ЈАГОДИНА_ВОЉАВЧЕ_71048_737976",
    },
    {
        label: "ЈАГОДИНА, ВРАНОВАЦ",
        value: "ЈАГОДИНА_ВРАНОВАЦ_71048_737984",
    },
    {
        label: "ЈАГОДИНА, ВРБА",
        value: "ЈАГОДИНА_ВРБА_71048_737992",
    },
    {
        label: "ЈАГОДИНА, ГЛАВИНЦИ",
        value: "ЈАГОДИНА_ГЛАВИНЦИ_71048_738000",
    },
    {
        label: "ЈАГОДИНА, ГЛОГОВАЦ",
        value: "ЈАГОДИНА_ГЛОГОВАЦ_71048_738018",
    },
    {
        label: "ЈАГОДИНА, ГОРЊЕ ШТИПЉЕ",
        value: "ЈАГОДИНА_ГОРЊЕ ШТИПЉЕ_71048_738026",
    },
    {
        label: "ЈАГОДИНА, ГОРЊИ РАЧНИК",
        value: "ЈАГОДИНА_ГОРЊИ РАЧНИК_71048_738034",
    },
    {
        label: "ЈАГОДИНА, ДЕОНИЦА",
        value: "ЈАГОДИНА_ДЕОНИЦА_71048_738069",
    },
    {
        label: "ЈАГОДИНА, ДОБРА ВОДА",
        value: "ЈАГОДИНА_ДОБРА ВОДА_71048_738581",
    },
    {
        label: "ЈАГОДИНА, ДОЊЕ ШТИПЉЕ",
        value: "ЈАГОДИНА_ДОЊЕ ШТИПЉЕ_71048_738077",
    },
    {
        label: "ЈАГОДИНА, ДОЊИ РАЧНИК",
        value: "ЈАГОДИНА_ДОЊИ РАЧНИК_71048_738085",
    },
    {
        label: "ЈАГОДИНА, ДРАГОЦВЕТ",
        value: "ЈАГОДИНА_ДРАГОЦВЕТ_71048_738093",
    },
    {
        label: "ЈАГОДИНА, ДРАГОШЕВАЦ",
        value: "ЈАГОДИНА_ДРАГОШЕВАЦ_71048_738107",
    },
    {
        label: "ЈАГОДИНА, ДРАЖМИРОВАЦ",
        value: "ЈАГОДИНА_ДРАЖМИРОВАЦ_71048_738115",
    },
    {
        label: "ЈАГОДИНА, ДУБОКА",
        value: "ЈАГОДИНА_ДУБОКА_71048_738123",
    },
    {
        label: "ЈАГОДИНА, ИВКОВАЧКИ ПРЊАВОР",
        value: "ЈАГОДИНА_ИВКОВАЧКИ ПРЊАВОР_71048_738131",
    },
    {
        label: "ЈАГОДИНА, ЈАГОДИНА",
        value: "ЈАГОДИНА_ЈАГОДИНА_71048_738409",
    },
    {
        label: "ЈАГОДИНА, ЈОШАНИЧКИ ПРЊАВОР",
        value: "ЈАГОДИНА_ЈОШАНИЧКИ ПРЊАВОР_71048_738158",
    },
    {
        label: "ЈАГОДИНА, КАЛЕНОВАЦ",
        value: "ЈАГОДИНА_КАЛЕНОВАЦ_71048_738166",
    },
    {
        label: "ЈАГОДИНА, КОВАЧЕВАЦ",
        value: "ЈАГОДИНА_КОВАЧЕВАЦ_71048_738174",
    },
    {
        label: "ЈАГОДИНА, КОЛАРЕ",
        value: "ЈАГОДИНА_КОЛАРЕ_71048_738182",
    },
    {
        label: "ЈАГОДИНА, КОНЧАРЕВО",
        value: "ЈАГОДИНА_КОНЧАРЕВО_71048_738204",
    },
    {
        label: "ЈАГОДИНА, КОЧИНО СЕЛО",
        value: "ЈАГОДИНА_КОЧИНО СЕЛО_71048_738212",
    },
    {
        label: "ЈАГОДИНА, ЛАНИШТЕ",
        value: "ЈАГОДИНА_ЛАНИШТЕ_71048_738239",
    },
    {
        label: "ЈАГОДИНА, ЛОВЦИ",
        value: "ЈАГОДИНА_ЛОВЦИ_71048_738247",
    },
    {
        label: "ЈАГОДИНА, ЛОЗОВИК",
        value: "ЈАГОДИНА_ЛОЗОВИК_71048_738255",
    },
    {
        label: "ЈАГОДИНА, ЛУКАР",
        value: "ЈАГОДИНА_ЛУКАР_71048_738271",
    },
    {
        label: "ЈАГОДИНА, МАЈУР",
        value: "ЈАГОДИНА_МАЈУР_71048_738280",
    },
    {
        label: "ЈАГОДИНА, МАЛИ ПОПОВИЋ",
        value: "ЈАГОДИНА_МАЛИ ПОПОВИЋ_71048_738298",
    },
    {
        label: "ЈАГОДИНА, МЕДОЈЕВАЦ",
        value: "ЈАГОДИНА_МЕДОЈЕВАЦ_71048_738301",
    },
    {
        label: "ЈАГОДИНА, МЕЂУРЕЧ",
        value: "ЈАГОДИНА_МЕЂУРЕЧ_71048_738310",
    },
    {
        label: "ЈАГОДИНА, МИЛОШЕВО",
        value: "ЈАГОДИНА_МИЛОШЕВО_71048_738336",
    },
    {
        label: "ЈАГОДИНА, МИШЕВИЋ",
        value: "ЈАГОДИНА_МИШЕВИЋ_71048_738344",
    },
    {
        label: "ЈАГОДИНА, РАЈКИНАЦ",
        value: "ЈАГОДИНА_РАЈКИНАЦ_71048_738719",
    },
    {
        label: "ЈАГОДИНА, РАКИТОВО",
        value: "ЈАГОДИНА_РАКИТОВО_71048_738379",
    },
    {
        label: "ЈАГОДИНА, РИБАРЕ",
        value: "ЈАГОДИНА_РИБАРЕ_71048_738387",
    },
    {
        label: "ЈАГОДИНА, РИБНИК",
        value: "ЈАГОДИНА_РИБНИК_71048_738395",
    },
    {
        label: "ЈАГОДИНА, СИОКОВАЦ",
        value: "ЈАГОДИНА_СИОКОВАЦ_71048_738425",
    },
    {
        label: "ЈАГОДИНА, СЛАТИНА",
        value: "ЈАГОДИНА_СЛАТИНА_71048_737828",
    },
    {
        label: "ЈАГОДИНА, СТАРО СЕЛО",
        value: "ЈАГОДИНА_СТАРО СЕЛО_71048_737836",
    },
    {
        label: "ЈАГОДИНА, СТРИЖИЛО",
        value: "ЈАГОДИНА_СТРИЖИЛО_71048_738433",
    },
    {
        label: "ЈАГОДИНА, ТОПОЛА",
        value: "ЈАГОДИНА_ТОПОЛА_71048_738441",
    },
    {
        label: "ЈАГОДИНА, ТРНАВА",
        value: "ЈАГОДИНА_ТРНАВА_71048_738468",
    },
    {
        label: "ЈАГОДИНА, ЦРНЧЕ",
        value: "ЈАГОДИНА_ЦРНЧЕ_71048_738476",
    },
    {
        label: "ЈАГОДИНА, ШАНТАРОВАЦ",
        value: "ЈАГОДИНА_ШАНТАРОВАЦ_71048_738484",
    },
    {
        label: "ЈАГОДИНА, ШУЉКОВАЦ",
        value: "ЈАГОДИНА_ШУЉКОВАЦ_71048_738492",
    },
    {
        label: "КАЊИЖА, АДОРЈАН",
        value: "КАЊИЖА_АДОРЈАН_80195_805831",
    },
    {
        label: "КАЊИЖА, ВЕЛЕБИТ",
        value: "КАЊИЖА_ВЕЛЕБИТ_80195_803774",
    },
    {
        label: "КАЊИЖА, КАЊИЖА",
        value: "КАЊИЖА_КАЊИЖА_80195_803537",
    },
    {
        label: "КАЊИЖА, МАЛЕ ПИЈАЦЕ",
        value: "КАЊИЖА_МАЛЕ ПИЈАЦЕ_80195_803545",
    },
    {
        label: "КАЊИЖА, МАРТОНОШ",
        value: "КАЊИЖА_МАРТОНОШ_80195_805840",
    },
    {
        label: "КАЊИЖА, ОРОМ",
        value: "КАЊИЖА_ОРОМ_80195_803669",
    },
    {
        label: "КАЊИЖА, ТРЕШЊЕВАЦ",
        value: "КАЊИЖА_ТРЕШЊЕВАЦ_80195_803723",
    },
    {
        label: "КАЊИЖА, ХОРГОШ",
        value: "КАЊИЖА_ХОРГОШ_80195_804436",
    },
    {
        label: "КИКИНДА, БАНАТСКА ТОПОЛА",
        value: "КИКИНДА_БАНАТСКА ТОПОЛА_80209_801267",
    },
    {
        label: "КИКИНДА, БАНАТСКО ВЕЛИКО СЕЛО",
        value: "КИКИНДА_БАНАТСКО ВЕЛИКО СЕЛО_80209_800759",
    },
    {
        label: "КИКИНДА, БАШАИД",
        value: "КИКИНДА_БАШАИД_80209_801275",
    },
    {
        label: "КИКИНДА, ИЂОШ",
        value: "КИКИНДА_ИЂОШ_80209_801321",
    },
    {
        label: "КИКИНДА, КИКИНДА",
        value: "КИКИНДА_КИКИНДА_80209_801356",
    },
    {
        label: "КИКИНДА, МОКРИН",
        value: "КИКИНДА_МОКРИН_80209_801364",
    },
    {
        label: "КИКИНДА, НАКОВО",
        value: "КИКИНДА_НАКОВО_80209_800783",
    },
    {
        label: "КИКИНДА, НОВИ КОЗАРЦИ",
        value: "КИКИНДА_НОВИ КОЗАРЦИ_80209_800791",
    },
    {
        label: "КИКИНДА, РУСКО СЕЛО",
        value: "КИКИНДА_РУСКО СЕЛО_80209_800813",
    },
    {
        label: "КИКИНДА, САЈАН",
        value: "КИКИНДА_САЈАН_80209_801402",
    },
    {
        label: "КЛАДОВО, БРЗА ПАЛАНКА",
        value: "КЛАДОВО_БРЗА ПАЛАНКА_70572_716782",
    },
    {
        label: "КЛАДОВО, ВАЈУГА",
        value: "КЛАДОВО_ВАЈУГА_70572_716570",
    },
    {
        label: "КЛАДОВО, ВЕЛЕСНИЦА",
        value: "КЛАДОВО_ВЕЛЕСНИЦА_70572_716588",
    },
    {
        label: "КЛАДОВО, ВЕЛИКА ВРБИЦА",
        value: "КЛАДОВО_ВЕЛИКА ВРБИЦА_70572_716596",
    },
    {
        label: "КЛАДОВО, ВЕЛИКА КАМЕНИЦА",
        value: "КЛАДОВО_ВЕЛИКА КАМЕНИЦА_70572_716600",
    },
    {
        label: "КЛАДОВО, ГРАБОВИЦА",
        value: "КЛАДОВО_ГРАБОВИЦА_70572_716618",
    },
    {
        label: "КЛАДОВО, ДАВИДОВАЦ",
        value: "КЛАДОВО_ДАВИДОВАЦ_70572_716626",
    },
    {
        label: "КЛАДОВО, КЛАДОВО",
        value: "КЛАДОВО_КЛАДОВО_70572_716634",
    },
    {
        label: "КЛАДОВО, КЛАДУШНИЦА",
        value: "КЛАДОВО_КЛАДУШНИЦА_70572_716642",
    },
    {
        label: "КЛАДОВО, КОРБОВО",
        value: "КЛАДОВО_КОРБОВО_70572_716669",
    },
    {
        label: "КЛАДОВО, КОСТОЛ",
        value: "КЛАДОВО_КОСТОЛ_70572_716677",
    },
    {
        label: "КЛАДОВО, КУПУЗИШТЕ",
        value: "КЛАДОВО_КУПУЗИШТЕ_70572_716839",
    },
    {
        label: "КЛАДОВО, ЉУБИЧЕВАЦ",
        value: "КЛАДОВО_ЉУБИЧЕВАЦ_70572_716685",
    },
    {
        label: "КЛАДОВО, МАЛА ВРБИЦА",
        value: "КЛАДОВО_МАЛА ВРБИЦА_70572_716693",
    },
    {
        label: "КЛАДОВО, МАНАСТИРИЦА",
        value: "КЛАДОВО_МАНАСТИРИЦА_70572_716707",
    },
    {
        label: "КЛАДОВО, МИЛУТИНОВАЦ",
        value: "КЛАДОВО_МИЛУТИНОВАЦ_70572_716715",
    },
    {
        label: "КЛАДОВО, ПЕТРОВО СЕЛО",
        value: "КЛАДОВО_ПЕТРОВО СЕЛО_70572_716731",
    },
    {
        label: "КЛАДОВО, ПОДВРШКА",
        value: "КЛАДОВО_ПОДВРШКА_70572_716740",
    },
    {
        label: "КЛАДОВО, РЕКА",
        value: "КЛАДОВО_РЕКА_70572_716880",
    },
    {
        label: "КЛАДОВО, РЕЧИЦА",
        value: "КЛАДОВО_РЕЧИЦА_70572_716758",
    },
    {
        label: "КЛАДОВО, РТКОВО",
        value: "КЛАДОВО_РТКОВО_70572_716766",
    },
    {
        label: "КЛАДОВО, СИП",
        value: "КЛАДОВО_СИП_70572_716723",
    },
    {
        label: "КЛАДОВО, ТЕКИЈА",
        value: "КЛАДОВО_ТЕКИЈА_70572_716774",
    },
    {
        label: "КНИЋ, БАЈЧЕТИНА",
        value: "КНИЋ_БАЈЧЕТИНА_70599_716936",
    },
    {
        label: "КНИЋ, БАЛОСАВЕ",
        value: "КНИЋ_БАЛОСАВЕ_70599_716944",
    },
    {
        label: "КНИЋ, БАРЕ",
        value: "КНИЋ_БАРЕ_70599_716952",
    },
    {
        label: "КНИЋ, БЕЧЕВИЦА",
        value: "КНИЋ_БЕЧЕВИЦА_70599_716979",
    },
    {
        label: "КНИЋ, БОРАЧ",
        value: "КНИЋ_БОРАЧ_70599_716987",
    },
    {
        label: "КНИЋ, БРЕСТОВАЦ",
        value: "КНИЋ_БРЕСТОВАЦ_70599_716995",
    },
    {
        label: "КНИЋ, БРЊИЦА",
        value: "КНИЋ_БРЊИЦА_70599_717002",
    },
    {
        label: "КНИЋ, БУМБАРЕВО БРДО",
        value: "КНИЋ_БУМБАРЕВО БРДО_70599_717029",
    },
    {
        label: "КНИЋ, ВРБЕТА",
        value: "КНИЋ_ВРБЕТА_70599_717070",
    },
    {
        label: "КНИЋ, ВУЧКОВИЦА",
        value: "КНИЋ_ВУЧКОВИЦА_70599_717088",
    },
    {
        label: "КНИЋ, ГРАБОВАЦ",
        value: "КНИЋ_ГРАБОВАЦ_70599_717142",
    },
    {
        label: "КНИЋ, ГРИВАЦ",
        value: "КНИЋ_ГРИВАЦ_70599_717169",
    },
    {
        label: "КНИЋ, ГРУЖА",
        value: "КНИЋ_ГРУЖА_70599_717193",
    },
    {
        label: "КНИЋ, ГУБЕРЕВАЦ",
        value: "КНИЋ_ГУБЕРЕВАЦ_70599_717207",
    },
    {
        label: "КНИЋ, ГУНЦАТИ",
        value: "КНИЋ_ГУНЦАТИ_70599_717215",
    },
    {
        label: "КНИЋ, ДРАГУШИЦА",
        value: "КНИЋ_ДРАГУШИЦА_70599_717266",
    },
    {
        label: "КНИЋ, ДУБРАВА",
        value: "КНИЋ_ДУБРАВА_70599_717312",
    },
    {
        label: "КНИЋ, ЖУЊЕ",
        value: "КНИЋ_ЖУЊЕ_70599_717355",
    },
    {
        label: "КНИЋ, ЗАБОЈНИЦА",
        value: "КНИЋ_ЗАБОЈНИЦА_70599_717363",
    },
    {
        label: "КНИЋ, КИКОЈЕВАЦ",
        value: "КНИЋ_КИКОЈЕВАЦ_70599_717398",
    },
    {
        label: "КНИЋ, КНЕЖЕВАЦ",
        value: "КНИЋ_КНЕЖЕВАЦ_70599_717401",
    },
    {
        label: "КНИЋ, КНИЋ",
        value: "КНИЋ_КНИЋ_70599_717410",
    },
    {
        label: "КНИЋ, КОЊУША",
        value: "КНИЋ_КОЊУША_70599_717428",
    },
    {
        label: "КНИЋ, КУСОВАЦ",
        value: "КНИЋ_КУСОВАЦ_70599_717444",
    },
    {
        label: "КНИЋ, ЛЕСКОВАЦ",
        value: "КНИЋ_ЛЕСКОВАЦ_70599_717479",
    },
    {
        label: "КНИЋ, ЛИПНИЦА",
        value: "КНИЋ_ЛИПНИЦА_70599_717495",
    },
    {
        label: "КНИЋ, ЉУБИЋ",
        value: "КНИЋ_ЉУБИЋ_70599_717509",
    },
    {
        label: "КНИЋ, ЉУЉАЦИ",
        value: "КНИЋ_ЉУЉАЦИ_70599_717517",
    },
    {
        label: "КНИЋ, ОПЛАНИЋ",
        value: "КНИЋ_ОПЛАНИЋ_70599_717568",
    },
    {
        label: "КНИЋ, ПАЈСИЈЕВИЋ",
        value: "КНИЋ_ПАЈСИЈЕВИЋ_70599_717576",
    },
    {
        label: "КНИЋ, ПРЕТОКЕ",
        value: "КНИЋ_ПРЕТОКЕ_70599_717622",
    },
    {
        label: "КНИЋ, РАДМИЛОВИЋ",
        value: "КНИЋ_РАДМИЛОВИЋ_70599_717657",
    },
    {
        label: "КНИЋ, РАШКОВИЋ",
        value: "КНИЋ_РАШКОВИЋ_70599_717673",
    },
    {
        label: "КНИЋ, СУМОРОВАЦ",
        value: "КНИЋ_СУМОРОВАЦ_70599_717720",
    },
    {
        label: "КНИЋ, ТОПОНИЦА",
        value: "КНИЋ_ТОПОНИЦА_70599_717738",
    },
    {
        label: "КНИЋ, ЧЕСТИН",
        value: "КНИЋ_ЧЕСТИН_70599_717762",
    },
    {
        label: "КЊАЖЕВАЦ, АЛДИНА РЕКА",
        value: "КЊАЖЕВАЦ_АЛДИНА РЕКА_70602_717819",
    },
    {
        label: "КЊАЖЕВАЦ, АЛДИНАЦ",
        value: "КЊАЖЕВАЦ_АЛДИНАЦ_70602_717827",
    },
    {
        label: "КЊАЖЕВАЦ, БАЛАНОВАЦ",
        value: "КЊАЖЕВАЦ_БАЛАНОВАЦ_70602_717835",
    },
    {
        label: "КЊАЖЕВАЦ, БАЛИНАЦ",
        value: "КЊАЖЕВАЦ_БАЛИНАЦ_70602_717843",
    },
    {
        label: "КЊАЖЕВАЦ, БАЛТА БЕРИЛОВАЦ",
        value: "КЊАЖЕВАЦ_БАЛТА БЕРИЛОВАЦ_70602_717851",
    },
    {
        label: "КЊАЖЕВАЦ, БАЊСКИ ОРЕШАЦ",
        value: "КЊАЖЕВАЦ_БАЊСКИ ОРЕШАЦ_70602_717860",
    },
    {
        label: "КЊАЖЕВАЦ, БЕЛИ ПОТОК",
        value: "КЊАЖЕВАЦ_БЕЛИ ПОТОК_70602_717878",
    },
    {
        label: "КЊАЖЕВАЦ, БЕРЧИНОВАЦ",
        value: "КЊАЖЕВАЦ_БЕРЧИНОВАЦ_70602_717886",
    },
    {
        label: "КЊАЖЕВАЦ, БОЖИНОВАЦ",
        value: "КЊАЖЕВАЦ_БОЖИНОВАЦ_70602_717894",
    },
    {
        label: "КЊАЖЕВАЦ, БУЛИНОВАЦ",
        value: "КЊАЖЕВАЦ_БУЛИНОВАЦ_70602_717916",
    },
    {
        label: "КЊАЖЕВАЦ, БУЧЈЕ",
        value: "КЊАЖЕВАЦ_БУЧЈЕ_70602_717924",
    },
    {
        label: "КЊАЖЕВАЦ, ВАЛЕВАЦ",
        value: "КЊАЖЕВАЦ_ВАЛЕВАЦ_70602_717932",
    },
    {
        label: "КЊАЖЕВАЦ, ВАСИЉ",
        value: "КЊАЖЕВАЦ_ВАСИЉ_70602_717959",
    },
    {
        label: "КЊАЖЕВАЦ, ВИДОВАЦ",
        value: "КЊАЖЕВАЦ_ВИДОВАЦ_70602_717967",
    },
    {
        label: "КЊАЖЕВАЦ, ВИНА",
        value: "КЊАЖЕВАЦ_ВИНА_70602_717975",
    },
    {
        label: "КЊАЖЕВАЦ, ВИТКОВАЦ",
        value: "КЊАЖЕВАЦ_ВИТКОВАЦ_70602_717983",
    },
    {
        label: "КЊАЖЕВАЦ, ВЛАШКО ПОЉЕ",
        value: "КЊАЖЕВАЦ_ВЛАШКО ПОЉЕ_70602_717991",
    },
    {
        label: "КЊАЖЕВАЦ, ВРТОВАЦ",
        value: "КЊАЖЕВАЦ_ВРТОВАЦ_70602_718017",
    },
    {
        label: "КЊАЖЕВАЦ, ГАБРОВНИЦА",
        value: "КЊАЖЕВАЦ_ГАБРОВНИЦА_70602_718025",
    },
    {
        label: "КЊАЖЕВАЦ, ГЛОГОВАЦ",
        value: "КЊАЖЕВАЦ_ГЛОГОВАЦ_70602_718033",
    },
    {
        label: "КЊАЖЕВАЦ, ГОРЊА КАМЕНИЦА",
        value: "КЊАЖЕВАЦ_ГОРЊА КАМЕНИЦА_70602_718041",
    },
    {
        label: "КЊАЖЕВАЦ, ГОРЊА СОКОЛОВИЦА",
        value: "КЊАЖЕВАЦ_ГОРЊА СОКОЛОВИЦА_70602_718050",
    },
    {
        label: "КЊАЖЕВАЦ, ГОРЊЕ ЗУНИЧЕ",
        value: "КЊАЖЕВАЦ_ГОРЊЕ ЗУНИЧЕ_70602_718068",
    },
    {
        label: "КЊАЖЕВАЦ, ГРАДИШТЕ",
        value: "КЊАЖЕВАЦ_ГРАДИШТЕ_70602_718076",
    },
    {
        label: "КЊАЖЕВАЦ, ГРЕЗНА",
        value: "КЊАЖЕВАЦ_ГРЕЗНА_70602_718084",
    },
    {
        label: "КЊАЖЕВАЦ, ДЕБЕЛИЦА",
        value: "КЊАЖЕВАЦ_ДЕБЕЛИЦА_70602_718092",
    },
    {
        label: "КЊАЖЕВАЦ, ДЕЈАНОВАЦ",
        value: "КЊАЖЕВАЦ_ДЕЈАНОВАЦ_70602_718106",
    },
    {
        label: "КЊАЖЕВАЦ, ДОЊА КАМЕНИЦА",
        value: "КЊАЖЕВАЦ_ДОЊА КАМЕНИЦА_70602_718114",
    },
    {
        label: "КЊАЖЕВАЦ, ДОЊА СОКОЛОВИЦА",
        value: "КЊАЖЕВАЦ_ДОЊА СОКОЛОВИЦА_70602_718122",
    },
    {
        label: "КЊАЖЕВАЦ, ДОЊЕ ЗУНИЧЕ",
        value: "КЊАЖЕВАЦ_ДОЊЕ ЗУНИЧЕ_70602_718149",
    },
    {
        label: "КЊАЖЕВАЦ, ДРВНИК",
        value: "КЊАЖЕВАЦ_ДРВНИК_70602_718157",
    },
    {
        label: "КЊАЖЕВАЦ, ДРЕНОВАЦ",
        value: "КЊАЖЕВАЦ_ДРЕНОВАЦ_70602_718165",
    },
    {
        label: "КЊАЖЕВАЦ, ДРЕЧИНОВАЦ",
        value: "КЊАЖЕВАЦ_ДРЕЧИНОВАЦ_70602_718173",
    },
    {
        label: "КЊАЖЕВАЦ, ЖЛНЕ",
        value: "КЊАЖЕВАЦ_ЖЛНЕ_70602_718181",
    },
    {
        label: "КЊАЖЕВАЦ, ЖУКОВАЦ",
        value: "КЊАЖЕВАЦ_ЖУКОВАЦ_70602_718190",
    },
    {
        label: "КЊАЖЕВАЦ, ЗОРУНОВАЦ",
        value: "КЊАЖЕВАЦ_ЗОРУНОВАЦ_70602_718203",
    },
    {
        label: "КЊАЖЕВАЦ, ЗУБЕТИНАЦ",
        value: "КЊАЖЕВАЦ_ЗУБЕТИНАЦ_70602_718211",
    },
    {
        label: "КЊАЖЕВАЦ, ИНОВО",
        value: "КЊАЖЕВАЦ_ИНОВО_70602_718220",
    },
    {
        label: "КЊАЖЕВАЦ, ЈАКОВАЦ",
        value: "КЊАЖЕВАЦ_ЈАКОВАЦ_70602_718238",
    },
    {
        label: "КЊАЖЕВАЦ, ЈАЛОВИК ИЗВОР",
        value: "КЊАЖЕВАЦ_ЈАЛОВИК ИЗВОР_70602_718246",
    },
    {
        label: "КЊАЖЕВАЦ, ЈАЊА",
        value: "КЊАЖЕВАЦ_ЈАЊА_70602_718254",
    },
    {
        label: "КЊАЖЕВАЦ, ЈЕЛАШНИЦА",
        value: "КЊАЖЕВАЦ_ЈЕЛАШНИЦА_70602_718262",
    },
    {
        label: "КЊАЖЕВАЦ, КАЛИЧИНА",
        value: "КЊАЖЕВАЦ_КАЛИЧИНА_70602_718289",
    },
    {
        label: "КЊАЖЕВАЦ, КАЛНА",
        value: "КЊАЖЕВАЦ_КАЛНА_70602_718297",
    },
    {
        label: "КЊАЖЕВАЦ, КАНДАЛИЦА",
        value: "КЊАЖЕВАЦ_КАНДАЛИЦА_70602_718319",
    },
    {
        label: "КЊАЖЕВАЦ, КЊАЖЕВАЦ",
        value: "КЊАЖЕВАЦ_КЊАЖЕВАЦ_70602_718327",
    },
    {
        label: "КЊАЖЕВАЦ, КОЖЕЉ",
        value: "КЊАЖЕВАЦ_КОЖЕЉ_70602_718335",
    },
    {
        label: "КЊАЖЕВАЦ, КРЕНТА",
        value: "КЊАЖЕВАЦ_КРЕНТА_70602_718343",
    },
    {
        label: "КЊАЖЕВАЦ, ЛЕПЕНА",
        value: "КЊАЖЕВАЦ_ЛЕПЕНА_70602_718351",
    },
    {
        label: "КЊАЖЕВАЦ, ЛОКВА",
        value: "КЊАЖЕВАЦ_ЛОКВА_70602_718360",
    },
    {
        label: "КЊАЖЕВАЦ, МАЊИНАЦ",
        value: "КЊАЖЕВАЦ_МАЊИНАЦ_70602_718386",
    },
    {
        label: "КЊАЖЕВАЦ, МИЉКОВАЦ",
        value: "КЊАЖЕВАЦ_МИЉКОВАЦ_70602_739057",
    },
    {
        label: "КЊАЖЕВАЦ, МИНИЋЕВО",
        value: "КЊАЖЕВАЦ_МИНИЋЕВО_70602_718408",
    },
    {
        label: "КЊАЖЕВАЦ, МУЧИБАБА",
        value: "КЊАЖЕВАЦ_МУЧИБАБА_70602_718416",
    },
    {
        label: "КЊАЖЕВАЦ, НОВО КОРИТО",
        value: "КЊАЖЕВАЦ_НОВО КОРИТО_70602_718424",
    },
    {
        label: "КЊАЖЕВАЦ, ОРЕШАЦ",
        value: "КЊАЖЕВАЦ_ОРЕШАЦ_70602_718432",
    },
    {
        label: "КЊАЖЕВАЦ, ОШЉАНЕ",
        value: "КЊАЖЕВАЦ_ОШЉАНЕ_70602_718459",
    },
    {
        label: "КЊАЖЕВАЦ, ПАПРАТНА",
        value: "КЊАЖЕВАЦ_ПАПРАТНА_70602_718467",
    },
    {
        label: "КЊАЖЕВАЦ, ПЕТРУША",
        value: "КЊАЖЕВАЦ_ПЕТРУША_70602_718475",
    },
    {
        label: "КЊАЖЕВАЦ, ПОНОР",
        value: "КЊАЖЕВАЦ_ПОНОР_70602_718483",
    },
    {
        label: "КЊАЖЕВАЦ, ПОТРКАЊЕ",
        value: "КЊАЖЕВАЦ_ПОТРКАЊЕ_70602_718491",
    },
    {
        label: "КЊАЖЕВАЦ, ПРИЧЕВАЦ",
        value: "КЊАЖЕВАЦ_ПРИЧЕВАЦ_70602_718505",
    },
    {
        label: "КЊАЖЕВАЦ, РАВНА",
        value: "КЊАЖЕВАЦ_РАВНА_70602_718513",
    },
    {
        label: "КЊАЖЕВАЦ, РАВНО БУЧЈЕ",
        value: "КЊАЖЕВАЦ_РАВНО БУЧЈЕ_70602_718521",
    },
    {
        label: "КЊАЖЕВАЦ, РАДИЧЕВАЦ",
        value: "КЊАЖЕВАЦ_РАДИЧЕВАЦ_70602_718530",
    },
    {
        label: "КЊАЖЕВАЦ, РГОШТЕ",
        value: "КЊАЖЕВАЦ_РГОШТЕ_70602_718548",
    },
    {
        label: "КЊАЖЕВАЦ, РЕПУШНИЦА",
        value: "КЊАЖЕВАЦ_РЕПУШНИЦА_70602_718556",
    },
    {
        label: "КЊАЖЕВАЦ, СВРЉИШКА ТОПЛА",
        value: "КЊАЖЕВАЦ_СВРЉИШКА ТОПЛА_70602_718564",
    },
    {
        label: "КЊАЖЕВАЦ, СКРОБНИЦА",
        value: "КЊАЖЕВАЦ_СКРОБНИЦА_70602_718599",
    },
    {
        label: "КЊАЖЕВАЦ, СЛАТИНА",
        value: "КЊАЖЕВАЦ_СЛАТИНА_70602_718602",
    },
    {
        label: "КЊАЖЕВАЦ, СТАЊИНАЦ",
        value: "КЊАЖЕВАЦ_СТАЊИНАЦ_70602_718629",
    },
    {
        label: "КЊАЖЕВАЦ, СТАРО КОРИТО",
        value: "КЊАЖЕВАЦ_СТАРО КОРИТО_70602_718637",
    },
    {
        label: "КЊАЖЕВАЦ, СТОГАЗОВАЦ",
        value: "КЊАЖЕВАЦ_СТОГАЗОВАЦ_70602_718645",
    },
    {
        label: "КЊАЖЕВАЦ, ТАТРАСНИЦА",
        value: "КЊАЖЕВАЦ_ТАТРАСНИЦА_70602_718653",
    },
    {
        label: "КЊАЖЕВАЦ, ТРГОВИШТЕ",
        value: "КЊАЖЕВАЦ_ТРГОВИШТЕ_70602_718661",
    },
    {
        label: "КЊАЖЕВАЦ, ТРНОВАЦ",
        value: "КЊАЖЕВАЦ_ТРНОВАЦ_70602_718670",
    },
    {
        label: "КЊАЖЕВАЦ, ЋУШТИЦА",
        value: "КЊАЖЕВАЦ_ЋУШТИЦА_70602_718688",
    },
    {
        label: "КЊАЖЕВАЦ, ЦРВЕЊЕ",
        value: "КЊАЖЕВАЦ_ЦРВЕЊЕ_70602_718696",
    },
    {
        label: "КЊАЖЕВАЦ, ЦРНИ ВРХ",
        value: "КЊАЖЕВАЦ_ЦРНИ ВРХ_70602_718700",
    },
    {
        label: "КЊАЖЕВАЦ, ШАРБАНОВАЦ",
        value: "КЊАЖЕВАЦ_ШАРБАНОВАЦ_70602_718718",
    },
    {
        label: "КЊАЖЕВАЦ, ШЕСТИ ГАБАР",
        value: "КЊАЖЕВАЦ_ШЕСТИ ГАБАР_70602_718726",
    },
    {
        label: "КЊАЖЕВАЦ, ШТИПИНА",
        value: "КЊАЖЕВАЦ_ШТИПИНА_70602_718734",
    },
    {
        label: "КЊАЖЕВАЦ, ШТИТАРАЦ",
        value: "КЊАЖЕВАЦ_ШТИТАРАЦ_70602_718742",
    },
    {
        label: "КЊАЖЕВАЦ, ШТРБАЦ",
        value: "КЊАЖЕВАЦ_ШТРБАЦ_70602_718769",
    },
    {
        label: "КЊАЖЕВАЦ, ШУМАН ТОПЛА",
        value: "КЊАЖЕВАЦ_ШУМАН ТОПЛА_70602_718777",
    },
    {
        label: "КОВАЧИЦА, ДЕБЕЉАЧА",
        value: "КОВАЧИЦА_ДЕБЕЉАЧА_80217_802638",
    },
    {
        label: "КОВАЧИЦА, ИДВОР",
        value: "КОВАЧИЦА_ИДВОР_80217_802689",
    },
    {
        label: "КОВАЧИЦА, КОВАЧИЦА",
        value: "КОВАЧИЦА_КОВАЧИЦА_80217_802735",
    },
    {
        label: "КОВАЧИЦА, ПАДИНА",
        value: "КОВАЧИЦА_ПАДИНА_80217_802786",
    },
    {
        label: "КОВАЧИЦА, САМОШ",
        value: "КОВАЧИЦА_САМОШ_80217_802816",
    },
    {
        label: "КОВАЧИЦА, УЗДИН",
        value: "КОВАЧИЦА_УЗДИН_80217_802832",
    },
    {
        label: "КОВАЧИЦА, ЦРЕПАЈА",
        value: "КОВАЧИЦА_ЦРЕПАЈА_80217_802352",
    },
    {
        label: "КОВИН, БАВАНИШТЕ I",
        value: "КОВИН_БАВАНИШТЕ I_80225_800279",
    },
    {
        label: "КОВИН, БАВАНИШТЕ II",
        value: "КОВИН_БАВАНИШТЕ II_80225_800287",
    },
    {
        label: "КОВИН, ГАЈ",
        value: "КОВИН_ГАЈ_80225_805858",
    },
    {
        label: "КОВИН, ДЕЛИБЛАТО",
        value: "КОВИН_ДЕЛИБЛАТО_80225_800368",
    },
    {
        label: "КОВИН, ДЕЛИБЛАТСКИ ПЕСАК",
        value: "КОВИН_ДЕЛИБЛАТСКИ ПЕСАК_80225_805807",
    },
    {
        label: "КОВИН, ДУБОВАЦ",
        value: "КОВИН_ДУБОВАЦ_80225_800384",
    },
    {
        label: "КОВИН, КОВИН",
        value: "КОВИН_КОВИН_80225_800490",
    },
    {
        label: "КОВИН, МРАМОРАК",
        value: "КОВИН_МРАМОРАК_80225_800511",
    },
    {
        label: "КОВИН, ПЛОЧИЦА",
        value: "КОВИН_ПЛОЧИЦА_80225_800589",
    },
    {
        label: "КОВИН, СКОРЕНОВАЦ",
        value: "КОВИН_СКОРЕНОВАЦ_80225_800597",
    },
    {
        label: "КОСЈЕРИЋ, БЈЕЛОПЕРИЦА",
        value: "КОСЈЕРИЋ_БЈЕЛОПЕРИЦА_70629_718785",
    },
    {
        label: "КОСЈЕРИЋ, БРАЈКОВИЋИ",
        value: "КОСЈЕРИЋ_БРАЈКОВИЋИ_70629_718793",
    },
    {
        label: "КОСЈЕРИЋ, ГАЛОВИЋИ",
        value: "КОСЈЕРИЋ_ГАЛОВИЋИ_70629_718807",
    },
    {
        label: "КОСЈЕРИЋ, ГОДЕЧЕВО I",
        value: "КОСЈЕРИЋ_ГОДЕЧЕВО I_70629_718815",
    },
    {
        label: "КОСЈЕРИЋ, ГОДЕЧЕВО II",
        value: "КОСЈЕРИЋ_ГОДЕЧЕВО II_70629_718823",
    },
    {
        label: "КОСЈЕРИЋ, ГОДЉЕВО",
        value: "КОСЈЕРИЋ_ГОДЉЕВО_70629_718831",
    },
    {
        label: "КОСЈЕРИЋ, ДРЕНОВЦИ",
        value: "КОСЈЕРИЋ_ДРЕНОВЦИ_70629_718840",
    },
    {
        label: "КОСЈЕРИЋ, ДУБНИЦА",
        value: "КОСЈЕРИЋ_ДУБНИЦА_70629_718858",
    },
    {
        label: "КОСЈЕРИЋ, КОСЈЕРИЋ (ВАРОШ)",
        value: "КОСЈЕРИЋ_КОСЈЕРИЋ (ВАРОШ)_70629_718866",
    },
    {
        label: "КОСЈЕРИЋ, КОСЈЕРИЋ (СЕЛО)",
        value: "КОСЈЕРИЋ_КОСЈЕРИЋ (СЕЛО)_70629_718874",
    },
    {
        label: "КОСЈЕРИЋ, МАКОВИШТЕ I",
        value: "КОСЈЕРИЋ_МАКОВИШТЕ I_70629_718882",
    },
    {
        label: "КОСЈЕРИЋ, МАКОВИШТЕ II",
        value: "КОСЈЕРИЋ_МАКОВИШТЕ II_70629_718904",
    },
    {
        label: "КОСЈЕРИЋ, МИОНИЦА",
        value: "КОСЈЕРИЋ_МИОНИЦА_70629_718912",
    },
    {
        label: "КОСЈЕРИЋ, МРЧИЋИ",
        value: "КОСЈЕРИЋ_МРЧИЋИ_70629_718939",
    },
    {
        label: "КОСЈЕРИЋ, МУШИЋИ",
        value: "КОСЈЕРИЋ_МУШИЋИ_70629_718947",
    },
    {
        label: "КОСЈЕРИЋ, ПАРАМУН",
        value: "КОСЈЕРИЋ_ПАРАМУН_70629_718955",
    },
    {
        label: "КОСЈЕРИЋ, ПОЛОШНИЦА I",
        value: "КОСЈЕРИЋ_ПОЛОШНИЦА I_70629_718963",
    },
    {
        label: "КОСЈЕРИЋ, ПОЛОШНИЦА II",
        value: "КОСЈЕРИЋ_ПОЛОШНИЦА II_70629_718971",
    },
    {
        label: "КОСЈЕРИЋ, РАДАНОВЦИ",
        value: "КОСЈЕРИЋ_РАДАНОВЦИ_70629_718980",
    },
    {
        label: "КОСЈЕРИЋ, РОСИЋИ",
        value: "КОСЈЕРИЋ_РОСИЋИ_70629_718998",
    },
    {
        label: "КОСЈЕРИЋ, РУДА БУКВА",
        value: "КОСЈЕРИЋ_РУДА БУКВА_70629_719005",
    },
    {
        label: "КОСЈЕРИЋ, СЕЧА РЕКА",
        value: "КОСЈЕРИЋ_СЕЧА РЕКА_70629_719013",
    },
    {
        label: "КОСЈЕРИЋ, СКАКАВЦИ",
        value: "КОСЈЕРИЋ_СКАКАВЦИ_70629_719021",
    },
    {
        label: "КОСЈЕРИЋ, СТОЈИЋИ",
        value: "КОСЈЕРИЋ_СТОЈИЋИ_70629_719030",
    },
    {
        label: "КОСЈЕРИЋ, СУБЈЕЛ",
        value: "КОСЈЕРИЋ_СУБЈЕЛ_70629_719048",
    },
    {
        label: "КОСЈЕРИЋ, ТУБИЋИ",
        value: "КОСЈЕРИЋ_ТУБИЋИ_70629_719064",
    },
    {
        label: "КОСЈЕРИЋ, ЦИКОТЕ",
        value: "КОСЈЕРИЋ_ЦИКОТЕ_70629_719072",
    },
    {
        label: "КОСЈЕРИЋ, ШЕВРЉУГЕ",
        value: "КОСЈЕРИЋ_ШЕВРЉУГЕ_70629_719099",
    },
    {
        label: "КОСТОЛАЦ, КЛЕНОВНИК",
        value: "КОСТОЛАЦ_КЛЕНОВНИК_71340_734098",
    },
    {
        label: "КОСТОЛАЦ, КОСТОЛАЦ СЕЛО",
        value: "КОСТОЛАЦ_КОСТОЛАЦ СЕЛО_71340_734314",
    },
    {
        label: "КОСТОЛАЦ, КОСТОЛАЦ-ГРАД",
        value: "КОСТОЛАЦ_КОСТОЛАЦ-ГРАД_71340_734306",
    },
    {
        label: "КОСТОЛАЦ, ОСТРОВО",
        value: "КОСТОЛАЦ_ОСТРОВО_71340_734179",
    },
    {
        label: "КОСТОЛАЦ, ПЕТКА",
        value: "КОСТОЛАЦ_ПЕТКА_71340_734187",
    },
    {
        label: "КОЦЕЉЕВА, БАТАЛАГЕ",
        value: "КОЦЕЉЕВА_БАТАЛАГЕ_70637_742767",
    },
    {
        label: "КОЦЕЉЕВА, БРДАРИЦА",
        value: "КОЦЕЉЕВА_БРДАРИЦА_70637_709115",
    },
    {
        label: "КОЦЕЉЕВА, БРЕСНИЦА",
        value: "КОЦЕЉЕВА_БРЕСНИЦА_70637_709123",
    },
    {
        label: "КОЦЕЉЕВА, ГАЛОВИЋ",
        value: "КОЦЕЉЕВА_ГАЛОВИЋ_70637_709182",
    },
    {
        label: "КОЦЕЉЕВА, ГОЛОЧЕЛО",
        value: "КОЦЕЉЕВА_ГОЛОЧЕЛО_70637_709204",
    },
    {
        label: "КОЦЕЉЕВА, ГРАДОЈЕВИЋ",
        value: "КОЦЕЉЕВА_ГРАДОЈЕВИЋ_70637_709212",
    },
    {
        label: "КОЦЕЉЕВА, ДОЊЕ ЦРНИЉЕВО",
        value: "КОЦЕЉЕВА_ДОЊЕ ЦРНИЉЕВО_70637_709247",
    },
    {
        label: "КОЦЕЉЕВА, ДРАГИЊЕ",
        value: "КОЦЕЉЕВА_ДРАГИЊЕ_70637_709255",
    },
    {
        label: "КОЦЕЉЕВА, ДРУЖЕТИЋ",
        value: "КОЦЕЉЕВА_ДРУЖЕТИЋ_70637_731986",
    },
    {
        label: "КОЦЕЉЕВА, ЗУКВЕ",
        value: "КОЦЕЉЕВА_ЗУКВЕ_70637_742864",
    },
    {
        label: "КОЦЕЉЕВА, КАМЕНИЦА",
        value: "КОЦЕЉЕВА_КАМЕНИЦА_70637_709298",
    },
    {
        label: "КОЦЕЉЕВА, КОЦЕЉЕВА",
        value: "КОЦЕЉЕВА_КОЦЕЉЕВА_70637_709328",
    },
    {
        label: "КОЦЕЉЕВА, КОЦЕЉЕВА-ВАРОШИЦА",
        value: "КОЦЕЉЕВА_КОЦЕЉЕВА-ВАРОШИЦА_70637_709336",
    },
    {
        label: "КОЦЕЉЕВА, ЉУТИЦЕ",
        value: "КОЦЕЉЕВА_ЉУТИЦЕ_70637_709395",
    },
    {
        label: "КОЦЕЉЕВА, МАЛИ БОШЊАК",
        value: "КОЦЕЉЕВА_МАЛИ БОШЊАК_70637_709409",
    },
    {
        label: "КОЦЕЉЕВА, СВИЛЕУВА",
        value: "КОЦЕЉЕВА_СВИЛЕУВА_70637_709492",
    },
    {
        label: "КОЦЕЉЕВА, СУБОТИЦА",
        value: "КОЦЕЉЕВА_СУБОТИЦА_70637_709514",
    },
    {
        label: "КОЦЕЉЕВА, ЋУКОВИНЕ",
        value: "КОЦЕЉЕВА_ЋУКОВИНЕ_70637_709549",
    },
    {
        label: "КРАГУЈЕВАЦ, АЏИНЕ ЛИВАДЕ",
        value: "КРАГУЈЕВАЦ_АЏИНЕ ЛИВАДЕ_70645_716928",
    },
    {
        label: "КРАГУЈЕВАЦ, БАЉКОВАЦ",
        value: "КРАГУЈЕВАЦ_БАЉКОВАЦ_70645_719102",
    },
    {
        label: "КРАГУЈЕВАЦ, БОТУЊЕ",
        value: "КРАГУЈЕВАЦ_БОТУЊЕ_70645_719153",
    },
    {
        label: "КРАГУЈЕВАЦ, БУКОРОВАЦ",
        value: "КРАГУЈЕВАЦ_БУКОРОВАЦ_70645_719170",
    },
    {
        label: "КРАГУЈЕВАЦ, ВЕЛИКА СУГУБИНА",
        value: "КРАГУЈЕВАЦ_ВЕЛИКА СУГУБИНА_70645_737534",
    },
    {
        label: "КРАГУЈЕВАЦ, ВЕЛИКЕ ПЧЕЛИЦЕ",
        value: "КРАГУЈЕВАЦ_ВЕЛИКЕ ПЧЕЛИЦЕ_70645_737542",
    },
    {
        label: "КРАГУЈЕВАЦ, ВЕЛИКИ ШЕЊ",
        value: "КРАГУЈЕВАЦ_ВЕЛИКИ ШЕЊ_70645_717037",
    },
    {
        label: "КРАГУЈЕВАЦ, ВИЊИШТЕ",
        value: "КРАГУЈЕВАЦ_ВИЊИШТЕ_70645_717045",
    },
    {
        label: "КРАГУЈЕВАЦ, ВЛАКЧА",
        value: "КРАГУЈЕВАЦ_ВЛАКЧА_70645_701432",
    },
    {
        label: "КРАГУЈЕВАЦ, ГОЛОЧЕЛО",
        value: "КРАГУЈЕВАЦ_ГОЛОЧЕЛО_70645_717118",
    },
    {
        label: "КРАГУЈЕВАЦ, ГОРЊА САБАНТА",
        value: "КРАГУЈЕВАЦ_ГОРЊА САБАНТА_70645_737577",
    },
    {
        label: "КРАГУЈЕВАЦ, ГОРЊЕ ГРБИЦЕ I",
        value: "КРАГУЈЕВАЦ_ГОРЊЕ ГРБИЦЕ I_70645_717126",
    },
    {
        label: "КРАГУЈЕВАЦ, ГОРЊЕ ГРБИЦЕ II",
        value: "КРАГУЈЕВАЦ_ГОРЊЕ ГРБИЦЕ II_70645_717134",
    },
    {
        label: "КРАГУЈЕВАЦ, ГОРЊЕ ЈАРУШИЦЕ",
        value: "КРАГУЈЕВАЦ_ГОРЊЕ ЈАРУШИЦЕ_70645_736481",
    },
    {
        label: "КРАГУЈЕВАЦ, ГОРЊЕ КОМАРИЦЕ",
        value: "КРАГУЈЕВАЦ_ГОРЊЕ КОМАРИЦЕ_70645_719196",
    },
    {
        label: "КРАГУЈЕВАЦ, ГРОШНИЦА I",
        value: "КРАГУЈЕВАЦ_ГРОШНИЦА I_70645_717177",
    },
    {
        label: "КРАГУЈЕВАЦ, ГРОШНИЦА II",
        value: "КРАГУЈЕВАЦ_ГРОШНИЦА II_70645_717185",
    },
    {
        label: "КРАГУЈЕВАЦ, ДЕСИМИРОВАЦ",
        value: "КРАГУЈЕВАЦ_ДЕСИМИРОВАЦ_70645_719200",
    },
    {
        label: "КРАГУЈЕВАЦ, ДИВОСТИН",
        value: "КРАГУЈЕВАЦ_ДИВОСТИН_70645_717223",
    },
    {
        label: "КРАГУЈЕВАЦ, ДОБРАЧА",
        value: "КРАГУЈЕВАЦ_ДОБРАЧА_70645_717231",
    },
    {
        label: "КРАГУЈЕВАЦ, ДОЊА САБАНТА",
        value: "КРАГУЈЕВАЦ_ДОЊА САБАНТА_70645_719226",
    },
    {
        label: "КРАГУЈЕВАЦ, ДОЊЕ ГРБИЦЕ",
        value: "КРАГУЈЕВАЦ_ДОЊЕ ГРБИЦЕ_70645_717240",
    },
    {
        label: "КРАГУЈЕВАЦ, ДОЊЕ КОМАРИЦЕ",
        value: "КРАГУЈЕВАЦ_ДОЊЕ КОМАРИЦЕ_70645_719234",
    },
    {
        label: "КРАГУЈЕВАЦ, ДРАГОБРАЋА",
        value: "КРАГУЈЕВАЦ_ДРАГОБРАЋА_70645_717258",
    },
    {
        label: "КРАГУЈЕВАЦ, ДРАЧА",
        value: "КРАГУЈЕВАЦ_ДРАЧА_70645_717274",
    },
    {
        label: "КРАГУЈЕВАЦ, ДРЕНОВАЦ",
        value: "КРАГУЈЕВАЦ_ДРЕНОВАЦ_70645_717282",
    },
    {
        label: "КРАГУЈЕВАЦ, ДУЛЕНЕ",
        value: "КРАГУЈЕВАЦ_ДУЛЕНЕ_70645_737615",
    },
    {
        label: "КРАГУЈЕВАЦ, ЂУРИСЕЛО",
        value: "КРАГУЈЕВАЦ_ЂУРИСЕЛО_70645_717339",
    },
    {
        label: "КРАГУЈЕВАЦ, ЕРДЕЧ",
        value: "КРАГУЈЕВАЦ_ЕРДЕЧ_70645_717347",
    },
    {
        label: "КРАГУЈЕВАЦ, ЈАБУЧЈЕ",
        value: "КРАГУЈЕВАЦ_ЈАБУЧЈЕ_70645_719285",
    },
    {
        label: "КРАГУЈЕВАЦ, ЈОВАНОВАЦ",
        value: "КРАГУЈЕВАЦ_ЈОВАНОВАЦ_70645_719293",
    },
    {
        label: "КРАГУЈЕВАЦ, КАМЕНИЦА",
        value: "КРАГУЈЕВАЦ_КАМЕНИЦА_70645_717380",
    },
    {
        label: "КРАГУЈЕВАЦ, КОРМАН",
        value: "КРАГУЈЕВАЦ_КОРМАН_70645_719315",
    },
    {
        label: "КРАГУЈЕВАЦ, КОТРАЖА",
        value: "КРАГУЈЕВАЦ_КОТРАЖА_70645_701564",
    },
    {
        label: "КРАГУЈЕВАЦ, КРАГУЈЕВАЦ I",
        value: "КРАГУЈЕВАЦ_КРАГУЈЕВАЦ I_70645_745081",
    },
    {
        label: "КРАГУЈЕВАЦ, КРАГУЈЕВАЦ II",
        value: "КРАГУЈЕВАЦ_КРАГУЈЕВАЦ II_70645_745090",
    },
    {
        label: "КРАГУЈЕВАЦ, КРАГУЈЕВАЦ III",
        value: "КРАГУЈЕВАЦ_КРАГУЈЕВАЦ III_70645_745103",
    },
    {
        label: "КРАГУЈЕВАЦ, КРАГУЈЕВАЦ IV",
        value: "КРАГУЈЕВАЦ_КРАГУЈЕВАЦ IV_70645_745111",
    },
    {
        label: "КРАГУЈЕВАЦ, КУТЛОВО",
        value: "КРАГУЈЕВАЦ_КУТЛОВО_70645_717452",
    },
    {
        label: "КРАГУЈЕВАЦ, ЛУЖНИЦЕ",
        value: "КРАГУЈЕВАЦ_ЛУЖНИЦЕ_70645_719331",
    },
    {
        label: "КРАГУЈЕВАЦ, ЉУБИЧЕВАЦ",
        value: "КРАГУЈЕВАЦ_ЉУБИЧЕВАЦ_70645_701599",
    },
    {
        label: "КРАГУЈЕВАЦ, МАЛА ВРБИЦА",
        value: "КРАГУЈЕВАЦ_МАЛА ВРБИЦА_70645_717525",
    },
    {
        label: "КРАГУЈЕВАЦ, МАЛИ ШЕЊ",
        value: "КРАГУЈЕВАЦ_МАЛИ ШЕЊ_70645_719340",
    },
    {
        label: "КРАГУЈЕВАЦ, МАРШИЋ",
        value: "КРАГУЈЕВАЦ_МАРШИЋ_70645_719358",
    },
    {
        label: "КРАГУЈЕВАЦ, МАСЛОШЕВО",
        value: "КРАГУЈЕВАЦ_МАСЛОШЕВО_70645_701637",
    },
    {
        label: "КРАГУЈЕВАЦ, МИРОНИЋ",
        value: "КРАГУЈЕВАЦ_МИРОНИЋ_70645_719374",
    },
    {
        label: "КРАГУЈЕВАЦ, НОВИ МИЛАНОВАЦ",
        value: "КРАГУЈЕВАЦ_НОВИ МИЛАНОВАЦ_70645_719404",
    },
    {
        label: "КРАГУЈЕВАЦ, ОПОРНИЦА",
        value: "КРАГУЈЕВАЦ_ОПОРНИЦА_70645_719412",
    },
    {
        label: "КРАГУЈЕВАЦ, ПАЈАЗИТОВО",
        value: "КРАГУЈЕВАЦ_ПАЈАЗИТОВО_70645_719439",
    },
    {
        label: "КРАГУЈЕВАЦ, ПОСКУРИЦЕ",
        value: "КРАГУЈЕВАЦ_ПОСКУРИЦЕ_70645_717606",
    },
    {
        label: "КРАГУЈЕВАЦ, ПРЕКОПЕЧА",
        value: "КРАГУЈЕВАЦ_ПРЕКОПЕЧА_70645_717614",
    },
    {
        label: "КРАГУЈЕВАЦ, РАМАЋА",
        value: "КРАГУЈЕВАЦ_РАМАЋА_70645_717665",
    },
    {
        label: "КРАГУЈЕВАЦ, РЕСНИК",
        value: "КРАГУЈЕВАЦ_РЕСНИК_70645_719463",
    },
    {
        label: "КРАГУЈЕВАЦ, РОГОЈЕВАЦ",
        value: "КРАГУЈЕВАЦ_РОГОЈЕВАЦ_70645_717681",
    },
    {
        label: "КРАГУЈЕВАЦ, СТРАГАРИ",
        value: "КРАГУЈЕВАЦ_СТРАГАРИ_70645_701700",
    },
    {
        label: "КРАГУЈЕВАЦ, ТРЕШЊЕВАК",
        value: "КРАГУЈЕВАЦ_ТРЕШЊЕВАК_70645_717746",
    },
    {
        label: "КРАГУЈЕВАЦ, ТРМБАС",
        value: "КРАГУЈЕВАЦ_ТРМБАС_70645_719498",
    },
    {
        label: "КРАГУЈЕВАЦ, УГЉАРЕВАЦ",
        value: "КРАГУЈЕВАЦ_УГЉАРЕВАЦ_70645_717754",
    },
    {
        label: "КРАГУЈЕВАЦ, ЦВЕТОЈЕВАЦ",
        value: "КРАГУЈЕВАЦ_ЦВЕТОЈЕВАЦ_70645_719501",
    },
    {
        label: "КРАГУЈЕВАЦ, ЦЕРОВАЦ",
        value: "КРАГУЈЕВАЦ_ЦЕРОВАЦ_70645_719510",
    },
    {
        label: "КРАГУЈЕВАЦ, ЧУМИЋ",
        value: "КРАГУЈЕВАЦ_ЧУМИЋ_70645_719528",
    },
    {
        label: "КРАГУЈЕВАЦ, ШЉИВОВАЦ",
        value: "КРАГУЈЕВАЦ_ШЉИВОВАЦ_70645_717797",
    },
    {
        label: "КРАЉЕВО, АДРАНИ",
        value: "КРАЉЕВО_АДРАНИ_70653_719536",
    },
    {
        label: "КРАЉЕВО, БАПСКО ПОЉЕ",
        value: "КРАЉЕВО_БАПСКО ПОЉЕ_70653_719544",
    },
    {
        label: "КРАЉЕВО, БАРЕ",
        value: "КРАЉЕВО_БАРЕ_70653_736732",
    },
    {
        label: "КРАЉЕВО, БЗОВИК",
        value: "КРАЉЕВО_БЗОВИК_70653_736775",
    },
    {
        label: "КРАЉЕВО, БОГУТОВАЦ",
        value: "КРАЉЕВО_БОГУТОВАЦ_70653_719552",
    },
    {
        label: "КРАЉЕВО, БРЕЗНА",
        value: "КРАЉЕВО_БРЕЗНА_70653_719579",
    },
    {
        label: "КРАЉЕВО, БРЕЗОВА",
        value: "КРАЉЕВО_БРЕЗОВА_70653_736830",
    },
    {
        label: "КРАЉЕВО, БРЕСНИК",
        value: "КРАЉЕВО_БРЕСНИК_70653_719587",
    },
    {
        label: "КРАЉЕВО, БУКОВИЦА",
        value: "КРАЉЕВО_БУКОВИЦА_70653_719595",
    },
    {
        label: "КРАЉЕВО, ВИТАНОВАЦ",
        value: "КРАЉЕВО_ВИТАНОВАЦ_70653_717053",
    },
    {
        label: "КРАЉЕВО, ВИТКОВАЦ",
        value: "КРАЉЕВО_ВИТКОВАЦ_70653_717061",
    },
    {
        label: "КРАЉЕВО, ВРБА",
        value: "КРАЉЕВО_ВРБА_70653_719617",
    },
    {
        label: "КРАЉЕВО, ВРДИЛА",
        value: "КРАЉЕВО_ВРДИЛА_70653_719625",
    },
    {
        label: "КРАЉЕВО, ВРХ",
        value: "КРАЉЕВО_ВРХ_70653_736864",
    },
    {
        label: "КРАЉЕВО, ГЛЕДИЋ",
        value: "КРАЉЕВО_ГЛЕДИЋ_70653_717096",
    },
    {
        label: "КРАЉЕВО, ГОДАЧИЦА",
        value: "КРАЉЕВО_ГОДАЧИЦА_70653_717100",
    },
    {
        label: "КРАЉЕВО, ГОКЧАНИЦА",
        value: "КРАЉЕВО_ГОКЧАНИЦА_70653_719641",
    },
    {
        label: "КРАЉЕВО, ГОТОВАЦ",
        value: "КРАЉЕВО_ГОТОВАЦ_70653_719650",
    },
    {
        label: "КРАЉЕВО, ГРДИЦА",
        value: "КРАЉЕВО_ГРДИЦА_70653_719676",
    },
    {
        label: "КРАЉЕВО, ДЕДЕВЦИ",
        value: "КРАЉЕВО_ДЕДЕВЦИ_70653_719684",
    },
    {
        label: "КРАЉЕВО, ДОЛАЦ",
        value: "КРАЉЕВО_ДОЛАЦ_70653_736902",
    },
    {
        label: "КРАЉЕВО, ДРАГОСИЊЦИ",
        value: "КРАЉЕВО_ДРАГОСИЊЦИ_70653_719692",
    },
    {
        label: "КРАЉЕВО, ДРАЖИНИЋЕ",
        value: "КРАЉЕВО_ДРАЖИНИЋЕ_70653_736937",
    },
    {
        label: "КРАЉЕВО, ДРАКЧИЋИ",
        value: "КРАЉЕВО_ДРАКЧИЋИ_70653_719706",
    },
    {
        label: "КРАЉЕВО, ДРЛУПА",
        value: "КРАЉЕВО_ДРЛУПА_70653_717304",
    },
    {
        label: "КРАЉЕВО, ЂАКОВО",
        value: "КРАЉЕВО_ЂАКОВО_70653_736945",
    },
    {
        label: "КРАЉЕВО, ЗАКЛОПАЧА",
        value: "КРАЉЕВО_ЗАКЛОПАЧА_70653_719714",
    },
    {
        label: "КРАЉЕВО, ЗАКУТА",
        value: "КРАЉЕВО_ЗАКУТА_70653_717371",
    },
    {
        label: "КРАЉЕВО, ЗАМЧАЊЕ",
        value: "КРАЉЕВО_ЗАМЧАЊЕ_70653_719722",
    },
    {
        label: "КРАЉЕВО, ЗАСАД",
        value: "КРАЉЕВО_ЗАСАД_70653_736970",
    },
    {
        label: "КРАЉЕВО, ЈАРЧУЈАК",
        value: "КРАЉЕВО_ЈАРЧУЈАК_70653_719749",
    },
    {
        label: "КРАЉЕВО, КАМЕНИЦА",
        value: "КРАЉЕВО_КАМЕНИЦА_70653_719757",
    },
    {
        label: "КРАЉЕВО, КОВАНЛУК",
        value: "КРАЉЕВО_КОВАНЛУК_70653_719765",
    },
    {
        label: "КРАЉЕВО, КОВАЧИ",
        value: "КРАЉЕВО_КОВАЧИ_70653_719773",
    },
    {
        label: "КРАЉЕВО, КОНАРЕВО",
        value: "КРАЉЕВО_КОНАРЕВО_70653_719781",
    },
    {
        label: "КРАЉЕВО, КРАЉЕВО",
        value: "КРАЉЕВО_КРАЉЕВО_70653_719790",
    },
    {
        label: "КРАЉЕВО, КРУШЕВИЦА",
        value: "КРАЉЕВО_КРУШЕВИЦА_70653_719803",
    },
    {
        label: "КРАЉЕВО, ЛАЂЕВЦИ",
        value: "КРАЉЕВО_ЛАЂЕВЦИ_70653_719811",
    },
    {
        label: "КРАЉЕВО, ЛАЗАЦ",
        value: "КРАЉЕВО_ЛАЗАЦ_70653_744131",
    },
    {
        label: "КРАЉЕВО, ЛЕШЕВО",
        value: "КРАЉЕВО_ЛЕШЕВО_70653_717487",
    },
    {
        label: "КРАЉЕВО, ЛОПАТНИЦА",
        value: "КРАЉЕВО_ЛОПАТНИЦА_70653_719820",
    },
    {
        label: "КРАЉЕВО, МАГЛИЧ",
        value: "КРАЉЕВО_МАГЛИЧ_70653_719838",
    },
    {
        label: "КРАЉЕВО, МАТАРУГЕ",
        value: "КРАЉЕВО_МАТАРУГЕ_70653_719846",
    },
    {
        label: "КРАЉЕВО, МЕЉАНИЦА",
        value: "КРАЉЕВО_МЕЉАНИЦА_70653_719854",
    },
    {
        label: "КРАЉЕВО, МЕТИКОШ",
        value: "КРАЉЕВО_МЕТИКОШ_70653_719862",
    },
    {
        label: "КРАЉЕВО, МИЛАВЧИЋИ",
        value: "КРАЉЕВО_МИЛАВЧИЋИ_70653_717541",
    },
    {
        label: "КРАЉЕВО, МИЛАКОВАЦ",
        value: "КРАЉЕВО_МИЛАКОВАЦ_70653_717550",
    },
    {
        label: "КРАЉЕВО, МИЛИЋЕ",
        value: "КРАЉЕВО_МИЛИЋЕ_70653_737127",
    },
    {
        label: "КРАЉЕВО, МИЛОЧАЈ",
        value: "КРАЉЕВО_МИЛОЧАЈ_70653_719889",
    },
    {
        label: "КРАЉЕВО, МЛАНЧА",
        value: "КРАЉЕВО_МЛАНЧА_70653_737135",
    },
    {
        label: "КРАЉЕВО, МРСАЋ",
        value: "КРАЉЕВО_МРСАЋ_70653_719897",
    },
    {
        label: "КРАЉЕВО, МУСИНА РЕКА",
        value: "КРАЉЕВО_МУСИНА РЕКА_70653_719919",
    },
    {
        label: "КРАЉЕВО, ОБРВА",
        value: "КРАЉЕВО_ОБРВА_70653_719927",
    },
    {
        label: "КРАЉЕВО, ОПЛАНИЋИ",
        value: "КРАЉЕВО_ОПЛАНИЋИ_70653_719935",
    },
    {
        label: "КРАЉЕВО, ОРЉА ГЛАВА",
        value: "КРАЉЕВО_ОРЉА ГЛАВА_70653_737178",
    },
    {
        label: "КРАЉЕВО, ПЕКЧАНИЦА",
        value: "КРАЉЕВО_ПЕКЧАНИЦА_70653_719951",
    },
    {
        label: "КРАЉЕВО, ПЕТРОПОЉЕ",
        value: "КРАЉЕВО_ПЕТРОПОЉЕ_70653_717584",
    },
    {
        label: "КРАЉЕВО, ПЕЧЕНОГ",
        value: "КРАЉЕВО_ПЕЧЕНОГ_70653_717592",
    },
    {
        label: "КРАЉЕВО, ПЛАНА",
        value: "КРАЉЕВО_ПЛАНА_70653_737216",
    },
    {
        label: "КРАЉЕВО, ПОЛУМИР",
        value: "КРАЉЕВО_ПОЛУМИР_70653_737259",
    },
    {
        label: "КРАЉЕВО, ПОПОВИЋИ",
        value: "КРАЉЕВО_ПОПОВИЋИ_70653_719978",
    },
    {
        label: "КРАЉЕВО, ПРЕДОЛЕ",
        value: "КРАЉЕВО_ПРЕДОЛЕ_70653_719986",
    },
    {
        label: "КРАЉЕВО, ПРОГОРЕЛИЦА",
        value: "КРАЉЕВО_ПРОГОРЕЛИЦА_70653_719994",
    },
    {
        label: "КРАЉЕВО, РАВАНИЦА",
        value: "КРАЉЕВО_РАВАНИЦА_70653_717649",
    },
    {
        label: "КРАЉЕВО, РАТИНА",
        value: "КРАЉЕВО_РАТИНА_70653_720003",
    },
    {
        label: "КРАЉЕВО, РЕКА",
        value: "КРАЉЕВО_РЕКА_70653_737313",
    },
    {
        label: "КРАЉЕВО, РИБНИЦА",
        value: "КРАЉЕВО_РИБНИЦА_70653_720011",
    },
    {
        label: "КРАЉЕВО, РОЋЕВИЋИ",
        value: "КРАЉЕВО_РОЋЕВИЋИ_70653_720020",
    },
    {
        label: "КРАЉЕВО, РУДНО",
        value: "КРАЉЕВО_РУДНО_70653_737330",
    },
    {
        label: "КРАЉЕВО, РУДЊАК",
        value: "КРАЉЕВО_РУДЊАК_70653_720038",
    },
    {
        label: "КРАЉЕВО, САВОВО",
        value: "КРАЉЕВО_САВОВО_70653_737348",
    },
    {
        label: "КРАЉЕВО, САМАИЛА",
        value: "КРАЉЕВО_САМАИЛА_70653_720046",
    },
    {
        label: "КРАЉЕВО, СИБНИЦА",
        value: "КРАЉЕВО_СИБНИЦА_70653_717690",
    },
    {
        label: "КРАЉЕВО, СИРЧА",
        value: "КРАЉЕВО_СИРЧА_70653_720054",
    },
    {
        label: "КРАЉЕВО, СТУБАЛ",
        value: "КРАЉЕВО_СТУБАЛ_70653_717711",
    },
    {
        label: "КРАЉЕВО, ТАВНИК",
        value: "КРАЉЕВО_ТАВНИК_70653_744603",
    },
    {
        label: "КРАЉЕВО, ТАДЕЊЕ",
        value: "КРАЉЕВО_ТАДЕЊЕ_70653_737399",
    },
    {
        label: "КРАЉЕВО, ТОЛИШНИЦА",
        value: "КРАЉЕВО_ТОЛИШНИЦА_70653_726915",
    },
    {
        label: "КРАЉЕВО, ТРГОВИШТЕ",
        value: "КРАЉЕВО_ТРГОВИШТЕ_70653_720062",
    },
    {
        label: "КРАЉЕВО, УШЋЕ",
        value: "КРАЉЕВО_УШЋЕ_70653_737445",
    },
    {
        label: "КРАЉЕВО, ЦВЕТКЕ",
        value: "КРАЉЕВО_ЦВЕТКЕ_70653_720089",
    },
    {
        label: "КРАЉЕВО, ЦЕРЈЕ",
        value: "КРАЉЕВО_ЦЕРЈЕ_70653_737453",
    },
    {
        label: "КРАЉЕВО, ЧИБУКОВАЦ",
        value: "КРАЉЕВО_ЧИБУКОВАЦ_70653_720097",
    },
    {
        label: "КРАЉЕВО, ЧУКОЈЕВАЦ",
        value: "КРАЉЕВО_ЧУКОЈЕВАЦ_70653_717789",
    },
    {
        label: "КРУПАЊ, БАЊЕВАЦ",
        value: "КРУПАЊ_БАЊЕВАЦ_70661_720119",
    },
    {
        label: "КРУПАЊ, БЕЛА ЦРКВА",
        value: "КРУПАЊ_БЕЛА ЦРКВА_70661_720135",
    },
    {
        label: "КРУПАЊ, БОГОШТИЦА",
        value: "КРУПАЊ_БОГОШТИЦА_70661_720151",
    },
    {
        label: "КРУПАЊ, БРЕЗОВИЦЕ",
        value: "КРУПАЊ_БРЕЗОВИЦЕ_70661_720160",
    },
    {
        label: "КРУПАЊ, БРШТИЦА",
        value: "КРУПАЊ_БРШТИЦА_70661_720178",
    },
    {
        label: "КРУПАЊ, ВРБИЋ",
        value: "КРУПАЊ_ВРБИЋ_70661_720194",
    },
    {
        label: "КРУПАЊ, ДВОРСКА",
        value: "КРУПАЊ_ДВОРСКА_70661_725994",
    },
    {
        label: "КРУПАЊ, ЗАВЛАКА",
        value: "КРУПАЊ_ЗАВЛАКА_70661_720216",
    },
    {
        label: "КРУПАЊ, КОСТАЈНИК",
        value: "КРУПАЊ_КОСТАЈНИК_70661_720259",
    },
    {
        label: "КРУПАЊ, КРАСАВА",
        value: "КРУПАЊ_КРАСАВА_70661_720267",
    },
    {
        label: "КРУПАЊ, КРЖАВА",
        value: "КРУПАЊ_КРЖАВА_70661_720275",
    },
    {
        label: "КРУПАЊ, КРУПАЊ",
        value: "КРУПАЊ_КРУПАЊ_70661_720283",
    },
    {
        label: "КРУПАЊ, ЛИКОДРА",
        value: "КРУПАЊ_ЛИКОДРА_70661_720291",
    },
    {
        label: "КРУПАЊ, ЛИПЕНОВИЋ I",
        value: "КРУПАЊ_ЛИПЕНОВИЋ I_70661_720305",
    },
    {
        label: "КРУПАЊ, ЛИПЕНОВИЋ II",
        value: "КРУПАЊ_ЛИПЕНОВИЋ II_70661_720313",
    },
    {
        label: "КРУПАЊ, МОЈКОВИЋ",
        value: "КРУПАЊ_МОЈКОВИЋ_70661_720330",
    },
    {
        label: "КРУПАЊ, ПЛАНИНА",
        value: "КРУПАЊ_ПЛАНИНА_70661_720348",
    },
    {
        label: "КРУПАЊ, РАВНАЈА",
        value: "КРУПАЊ_РАВНАЈА_70661_720356",
    },
    {
        label: "КРУПАЊ, СТАВЕ",
        value: "КРУПАЊ_СТАВЕ_70661_720399",
    },
    {
        label: "КРУПАЊ, ТОЛИСАВАЦ",
        value: "КРУПАЊ_ТОЛИСАВАЦ_70661_720402",
    },
    {
        label: "КРУПАЊ, ТОМАЊ",
        value: "КРУПАЊ_ТОМАЊ_70661_720429",
    },
    {
        label: "КРУПАЊ, ЦВЕТУЉА",
        value: "КРУПАЊ_ЦВЕТУЉА_70661_720437",
    },
    {
        label: "КРУПАЊ, ЦЕРОВА",
        value: "КРУПАЊ_ЦЕРОВА_70661_720445",
    },
    {
        label: "КРУПАЊ, ШЉИВОВА",
        value: "КРУПАЊ_ШЉИВОВА_70661_720470",
    },
    {
        label: "КРУШЕВАЦ, БЕЛА ВОДА",
        value: "КРУШЕВАЦ_БЕЛА ВОДА_70670_720488",
    },
    {
        label: "КРУШЕВАЦ, БЕЛАСИЦА",
        value: "КРУШЕВАЦ_БЕЛАСИЦА_70670_720496",
    },
    {
        label: "КРУШЕВАЦ, БИВОЉЕ",
        value: "КРУШЕВАЦ_БИВОЉЕ_70670_720500",
    },
    {
        label: "КРУШЕВАЦ, БОВАН",
        value: "КРУШЕВАЦ_БОВАН_70670_720518",
    },
    {
        label: "КРУШЕВАЦ, БОЉЕВАЦ",
        value: "КРУШЕВАЦ_БОЉЕВАЦ_70670_720526",
    },
    {
        label: "КРУШЕВАЦ, БРАЈКОВАЦ",
        value: "КРУШЕВАЦ_БРАЈКОВАЦ_70670_720534",
    },
    {
        label: "КРУШЕВАЦ, БУКОВИЦА",
        value: "КРУШЕВАЦ_БУКОВИЦА_70670_720569",
    },
    {
        label: "КРУШЕВАЦ, БУЦИ",
        value: "КРУШЕВАЦ_БУЦИ_70670_720577",
    },
    {
        label: "КРУШЕВАЦ, ВЕЛИКА КРУШЕВИЦА",
        value: "КРУШЕВАЦ_ВЕЛИКА КРУШЕВИЦА_70670_720593",
    },
    {
        label: "КРУШЕВАЦ, ВЕЛИКИ ШИЉЕГОВАЦ",
        value: "КРУШЕВАЦ_ВЕЛИКИ ШИЉЕГОВАЦ_70670_720607",
    },
    {
        label: "КРУШЕВАЦ, ВЕЛИКО ГОЛОВОДЕ",
        value: "КРУШЕВАЦ_ВЕЛИКО ГОЛОВОДЕ_70670_720615",
    },
    {
        label: "КРУШЕВАЦ, ВИТАНОВАЦ",
        value: "КРУШЕВАЦ_ВИТАНОВАЦ_70670_720623",
    },
    {
        label: "КРУШЕВАЦ, ВРАТАРЕ",
        value: "КРУШЕВАЦ_ВРАТАРЕ_70670_720631",
    },
    {
        label: "КРУШЕВАЦ, ВУЧАК",
        value: "КРУШЕВАЦ_ВУЧАК_70670_720640",
    },
    {
        label: "КРУШЕВАЦ, ГАВЕЗ",
        value: "КРУШЕВАЦ_ГАВЕЗ_70670_720658",
    },
    {
        label: "КРУШЕВАЦ, ГАГЛОВО",
        value: "КРУШЕВАЦ_ГАГЛОВО_70670_720666",
    },
    {
        label: "КРУШЕВАЦ, ГАРИ",
        value: "КРУШЕВАЦ_ГАРИ_70670_720674",
    },
    {
        label: "КРУШЕВАЦ, ГЛОБАРЕ",
        value: "КРУШЕВАЦ_ГЛОБАРЕ_70670_720682",
    },
    {
        label: "КРУШЕВАЦ, ГЛОБОДЕР",
        value: "КРУШЕВАЦ_ГЛОБОДЕР_70670_720704",
    },
    {
        label: "КРУШЕВАЦ, ГОРЊИ СТЕПОШ",
        value: "КРУШЕВАЦ_ГОРЊИ СТЕПОШ_70670_720712",
    },
    {
        label: "КРУШЕВАЦ, ГРЕВЦИ",
        value: "КРУШЕВАЦ_ГРЕВЦИ_70670_720747",
    },
    {
        label: "КРУШЕВАЦ, ГРКЉАНЕ",
        value: "КРУШЕВАЦ_ГРКЉАНЕ_70670_720755",
    },
    {
        label: "КРУШЕВАЦ, ДВОРАНЕ",
        value: "КРУШЕВАЦ_ДВОРАНЕ_70670_720763",
    },
    {
        label: "КРУШЕВАЦ, ДЕДИНА",
        value: "КРУШЕВАЦ_ДЕДИНА_70670_720771",
    },
    {
        label: "КРУШЕВАЦ, ДОЉАНЕ",
        value: "КРУШЕВАЦ_ДОЉАНЕ_70670_720780",
    },
    {
        label: "КРУШЕВАЦ, ДОЊИ СТЕПОШ",
        value: "КРУШЕВАЦ_ДОЊИ СТЕПОШ_70670_720798",
    },
    {
        label: "КРУШЕВАЦ, ЂУНИС",
        value: "КРУШЕВАЦ_ЂУНИС_70670_720810",
    },
    {
        label: "КРУШЕВАЦ, ЗДРАВИЊЕ",
        value: "КРУШЕВАЦ_ЗДРАВИЊЕ_70670_720828",
    },
    {
        label: "КРУШЕВАЦ, ЗЕБИЦА",
        value: "КРУШЕВАЦ_ЗЕБИЦА_70670_720836",
    },
    {
        label: "КРУШЕВАЦ, ЗУБОВАЦ",
        value: "КРУШЕВАЦ_ЗУБОВАЦ_70670_720844",
    },
    {
        label: "КРУШЕВАЦ, ЈАБЛАНИЦА",
        value: "КРУШЕВАЦ_ЈАБЛАНИЦА_70670_720852",
    },
    {
        label: "КРУШЕВАЦ, ЈАСИКА",
        value: "КРУШЕВАЦ_ЈАСИКА_70670_720879",
    },
    {
        label: "КРУШЕВАЦ, ЈОШЈЕ",
        value: "КРУШЕВАЦ_ЈОШЈЕ_70670_720887",
    },
    {
        label: "КРУШЕВАЦ, КАМЕНАРЕ",
        value: "КРУШЕВАЦ_КАМЕНАРЕ_70670_720895",
    },
    {
        label: "КРУШЕВАЦ, КАОНИК",
        value: "КРУШЕВАЦ_КАОНИК_70670_720909",
    },
    {
        label: "КРУШЕВАЦ, КАПИЏИЈА",
        value: "КРУШЕВАЦ_КАПИЏИЈА_70670_720917",
    },
    {
        label: "КРУШЕВАЦ, КОБИЉЕ",
        value: "КРУШЕВАЦ_КОБИЉЕ_70670_720925",
    },
    {
        label: "КРУШЕВАЦ, КОМОРАНЕ",
        value: "КРУШЕВАЦ_КОМОРАНЕ_70670_720933",
    },
    {
        label: "КРУШЕВАЦ, КОЊУХ",
        value: "КРУШЕВАЦ_КОЊУХ_70670_720941",
    },
    {
        label: "КРУШЕВАЦ, КРВАВИЦА",
        value: "КРУШЕВАЦ_КРВАВИЦА_70670_720950",
    },
    {
        label: "КРУШЕВАЦ, КРУШЕВАЦ",
        value: "КРУШЕВАЦ_КРУШЕВАЦ_70670_720968",
    },
    {
        label: "КРУШЕВАЦ, КРУШИНЦЕ",
        value: "КРУШЕВАЦ_КРУШИНЦЕ_70670_720976",
    },
    {
        label: "КРУШЕВАЦ, КУКЉИН",
        value: "КРУШЕВАЦ_КУКЉИН_70670_720984",
    },
    {
        label: "КРУШЕВАЦ, КУПЦИ",
        value: "КРУШЕВАЦ_КУПЦИ_70670_720992",
    },
    {
        label: "КРУШЕВАЦ, ЛАЗАРЕВАЦ",
        value: "КРУШЕВАЦ_ЛАЗАРЕВАЦ_70670_741698",
    },
    {
        label: "КРУШЕВАЦ, ЛАЗАРИЦА",
        value: "КРУШЕВАЦ_ЛАЗАРИЦА_70670_721000",
    },
    {
        label: "КРУШЕВАЦ, ЛИПОВАЦ",
        value: "КРУШЕВАЦ_ЛИПОВАЦ_70670_721026",
    },
    {
        label: "КРУШЕВАЦ, ЛОВЦИ",
        value: "КРУШЕВАЦ_ЛОВЦИ_70670_721034",
    },
    {
        label: "КРУШЕВАЦ, ЛОМНИЦА",
        value: "КРУШЕВАЦ_ЛОМНИЦА_70670_721042",
    },
    {
        label: "КРУШЕВАЦ, ЛУКАВАЦ",
        value: "КРУШЕВАЦ_ЛУКАВАЦ_70670_721069",
    },
    {
        label: "КРУШЕВАЦ, ЉУБАВА",
        value: "КРУШЕВАЦ_ЉУБАВА_70670_721077",
    },
    {
        label: "КРУШЕВАЦ, МАЈДЕВО",
        value: "КРУШЕВАЦ_МАЈДЕВО_70670_721085",
    },
    {
        label: "КРУШЕВАЦ, МАКРЕШАНЕ",
        value: "КРУШЕВАЦ_МАКРЕШАНЕ_70670_721093",
    },
    {
        label: "КРУШЕВАЦ, МАЛА ВРБНИЦА",
        value: "КРУШЕВАЦ_МАЛА ВРБНИЦА_70670_721107",
    },
    {
        label: "КРУШЕВАЦ, МАЛА РЕКА",
        value: "КРУШЕВАЦ_МАЛА РЕКА_70670_721115",
    },
    {
        label: "КРУШЕВАЦ, МАЛИ ШИЉЕГОВАЦ",
        value: "КРУШЕВАЦ_МАЛИ ШИЉЕГОВАЦ_70670_721123",
    },
    {
        label: "КРУШЕВАЦ, МАЛО ГОЛОВОДЕ",
        value: "КРУШЕВАЦ_МАЛО ГОЛОВОДЕ_70670_721131",
    },
    {
        label: "КРУШЕВАЦ, МАЧКОВАЦ",
        value: "КРУШЕВАЦ_МАЧКОВАЦ_70670_721140",
    },
    {
        label: "КРУШЕВАЦ, МЕШЕВО",
        value: "КРУШЕВАЦ_МЕШЕВО_70670_721158",
    },
    {
        label: "КРУШЕВАЦ, МОДРИЦА",
        value: "КРУШЕВАЦ_МОДРИЦА_70670_721166",
    },
    {
        label: "КРУШЕВАЦ, МУДРАКОВАЦ",
        value: "КРУШЕВАЦ_МУДРАКОВАЦ_70670_721212",
    },
    {
        label: "КРУШЕВАЦ, НАУПАРЕ",
        value: "КРУШЕВАЦ_НАУПАРЕ_70670_721239",
    },
    {
        label: "КРУШЕВАЦ, ПАДЕЖ",
        value: "КРУШЕВАЦ_ПАДЕЖ_70670_721247",
    },
    {
        label: "КРУШЕВАЦ, ПАКАШНИЦА",
        value: "КРУШЕВАЦ_ПАКАШНИЦА_70670_721255",
    },
    {
        label: "КРУШЕВАЦ, ПАРУНОВАЦ",
        value: "КРУШЕВАЦ_ПАРУНОВАЦ_70670_721263",
    },
    {
        label: "КРУШЕВАЦ, ПАСЈАК",
        value: "КРУШЕВАЦ_ПАСЈАК_70670_721271",
    },
    {
        label: "КРУШЕВАЦ, ПЕПЕЉЕВАЦ",
        value: "КРУШЕВАЦ_ПЕПЕЉЕВАЦ_70670_721280",
    },
    {
        label: "КРУШЕВАЦ, ПЕТИНА",
        value: "КРУШЕВАЦ_ПЕТИНА_70670_721298",
    },
    {
        label: "КРУШЕВАЦ, ПОЗЛАТА",
        value: "КРУШЕВАЦ_ПОЗЛАТА_70670_721301",
    },
    {
        label: "КРУШЕВАЦ, ПОЉАЦИ",
        value: "КРУШЕВАЦ_ПОЉАЦИ_70670_721310",
    },
    {
        label: "КРУШЕВАЦ, РИБАРЕ",
        value: "КРУШЕВАЦ_РИБАРЕ_70670_721328",
    },
    {
        label: "КРУШЕВАЦ, РЛИЦА",
        value: "КРУШЕВАЦ_РЛИЦА_70670_721336",
    },
    {
        label: "КРУШЕВАЦ, РОСИЦА",
        value: "КРУШЕВАЦ_РОСИЦА_70670_721344",
    },
    {
        label: "КРУШЕВАЦ, СЕБЕЧЕВАЦ",
        value: "КРУШЕВАЦ_СЕБЕЧЕВАЦ_70670_721352",
    },
    {
        label: "КРУШЕВАЦ, СЕЗЕМЧЕ",
        value: "КРУШЕВАЦ_СЕЗЕМЧЕ_70670_721379",
    },
    {
        label: "КРУШЕВАЦ, СЛАТИНА",
        value: "КРУШЕВАЦ_СЛАТИНА_70670_721387",
    },
    {
        label: "КРУШЕВАЦ, СРНДАЉЕ",
        value: "КРУШЕВАЦ_СРНДАЉЕ_70670_721395",
    },
    {
        label: "КРУШЕВАЦ, СРЊЕ",
        value: "КРУШЕВАЦ_СРЊЕ_70670_721409",
    },
    {
        label: "КРУШЕВАЦ, СТАНЦИ",
        value: "КРУШЕВАЦ_СТАНЦИ_70670_721417",
    },
    {
        label: "КРУШЕВАЦ, СУШИЦА",
        value: "КРУШЕВАЦ_СУШИЦА_70670_721425",
    },
    {
        label: "КРУШЕВАЦ, ТЕКИЈА",
        value: "КРУШЕВАЦ_ТЕКИЈА_70670_721433",
    },
    {
        label: "КРУШЕВАЦ, ТРЕБОТИН",
        value: "КРУШЕВАЦ_ТРЕБОТИН_70670_721441",
    },
    {
        label: "КРУШЕВАЦ, ТРМЧАРЕ",
        value: "КРУШЕВАЦ_ТРМЧАРЕ_70670_721450",
    },
    {
        label: "КРУШЕВАЦ, ЋЕЛИЈЕ",
        value: "КРУШЕВАЦ_ЋЕЛИЈЕ_70670_721476",
    },
    {
        label: "КРУШЕВАЦ, ЦЕРОВА",
        value: "КРУШЕВАЦ_ЦЕРОВА_70670_721484",
    },
    {
        label: "КРУШЕВАЦ, ЦРКВИНА",
        value: "КРУШЕВАЦ_ЦРКВИНА_70670_721492",
    },
    {
        label: "КРУШЕВАЦ, ЧИТЛУК",
        value: "КРУШЕВАЦ_ЧИТЛУК_70670_721506",
    },
    {
        label: "КРУШЕВАЦ, ШАВРАНЕ",
        value: "КРУШЕВАЦ_ШАВРАНЕ_70670_721514",
    },
    {
        label: "КРУШЕВАЦ, ШАНАЦ",
        value: "КРУШЕВАЦ_ШАНАЦ_70670_721522",
    },
    {
        label: "КРУШЕВАЦ, ШАШИЛОВАЦ",
        value: "КРУШЕВАЦ_ШАШИЛОВАЦ_70670_721549",
    },
    {
        label: "КРУШЕВАЦ, ШОГОЉ",
        value: "КРУШЕВАЦ_ШОГОЉ_70670_721557",
    },
    {
        label: "КРУШЕВАЦ, ШТИТАРЕ",
        value: "КРУШЕВАЦ_ШТИТАРЕ_70670_721565",
    },
    {
        label: "КУЛА, КРУШЧИЋ",
        value: "КУЛА_КРУШЧИЋ_80233_801623",
    },
    {
        label: "КУЛА, КУЛА",
        value: "КУЛА_КУЛА_80233_801470",
    },
    {
        label: "КУЛА, ЛИПАР",
        value: "КУЛА_ЛИПАР_80233_801488",
    },
    {
        label: "КУЛА, РУСКИ КРСТУР",
        value: "КУЛА_РУСКИ КРСТУР_80233_801674",
    },
    {
        label: "КУЛА, СИВАЦ",
        value: "КУЛА_СИВАЦ_80233_801526",
    },
    {
        label: "КУЛА, ЦРВЕНКА",
        value: "КУЛА_ЦРВЕНКА_80233_801445",
    },
    {
        label: "КУРШУМЛИЈА, БАБИЦА",
        value: "КУРШУМЛИЈА_БАБИЦА_70688_721573",
    },
    {
        label: "КУРШУМЛИЈА, БАРЛОВО",
        value: "КУРШУМЛИЈА_БАРЛОВО_70688_721581",
    },
    {
        label: "КУРШУМЛИЈА, БАЋОГЛАВА",
        value: "КУРШУМЛИЈА_БАЋОГЛАВА_70688_721590",
    },
    {
        label: "КУРШУМЛИЈА, БЕЛО ПОЉЕ",
        value: "КУРШУМЛИЈА_БЕЛО ПОЉЕ_70688_721603",
    },
    {
        label: "КУРШУМЛИЈА, БОГУЈЕВАЦ",
        value: "КУРШУМЛИЈА_БОГУЈЕВАЦ_70688_721611",
    },
    {
        label: "КУРШУМЛИЈА, ВАСИЉЕВАЦ",
        value: "КУРШУМЛИЈА_ВАСИЉЕВАЦ_70688_721620",
    },
    {
        label: "КУРШУМЛИЈА, ВЕЛИКО ПУПАВЦЕ",
        value: "КУРШУМЛИЈА_ВЕЛИКО ПУПАВЦЕ_70688_721638",
    },
    {
        label: "КУРШУМЛИЈА, ВИСОКА",
        value: "КУРШУМЛИЈА_ВИСОКА_70688_721646",
    },
    {
        label: "КУРШУМЛИЈА, ВЛАХИЊА",
        value: "КУРШУМЛИЈА_ВЛАХИЊА_70688_721654",
    },
    {
        label: "КУРШУМЛИЈА, ВРЕЛО",
        value: "КУРШУМЛИЈА_ВРЕЛО_70688_721662",
    },
    {
        label: "КУРШУМЛИЈА, ВРШЕВАЦ",
        value: "КУРШУМЛИЈА_ВРШЕВАЦ_70688_721689",
    },
    {
        label: "КУРШУМЛИЈА, ВУКОЈЕВАЦ",
        value: "КУРШУМЛИЈА_ВУКОЈЕВАЦ_70688_721697",
    },
    {
        label: "КУРШУМЛИЈА, ГОРЊА МИКУЉАНА",
        value: "КУРШУМЛИЈА_ГОРЊА МИКУЉАНА_70688_721719",
    },
    {
        label: "КУРШУМЛИЈА, ГОРЊЕ ТОЧАНЕ",
        value: "КУРШУМЛИЈА_ГОРЊЕ ТОЧАНЕ_70688_721727",
    },
    {
        label: "КУРШУМЛИЈА, ГРАБОВНИЦА",
        value: "КУРШУМЛИЈА_ГРАБОВНИЦА_70688_721735",
    },
    {
        label: "КУРШУМЛИЈА, ДАБИНОВАЦ",
        value: "КУРШУМЛИЈА_ДАБИНОВАЦ_70688_721743",
    },
    {
        label: "КУРШУМЛИЈА, ДАНКОВИЋЕ",
        value: "КУРШУМЛИЈА_ДАНКОВИЋЕ_70688_721751",
    },
    {
        label: "КУРШУМЛИЈА, ДЕГРМЕН",
        value: "КУРШУМЛИЈА_ДЕГРМЕН_70688_721760",
    },
    {
        label: "КУРШУМЛИЈА, ДЕДИНАЦ",
        value: "КУРШУМЛИЈА_ДЕДИНАЦ_70688_721778",
    },
    {
        label: "КУРШУМЛИЈА, ДЕШИШКА",
        value: "КУРШУМЛИЈА_ДЕШИШКА_70688_721786",
    },
    {
        label: "КУРШУМЛИЈА, ДОБРИ ДО",
        value: "КУРШУМЛИЈА_ДОБРИ ДО_70688_721794",
    },
    {
        label: "КУРШУМЛИЈА, ДОЊА МИКУЉАНА",
        value: "КУРШУМЛИЈА_ДОЊА МИКУЉАНА_70688_721808",
    },
    {
        label: "КУРШУМЛИЈА, ДОЊЕ ТОЧАНЕ",
        value: "КУРШУМЛИЈА_ДОЊЕ ТОЧАНЕ_70688_721816",
    },
    {
        label: "КУРШУМЛИЈА, ДУБРАВА",
        value: "КУРШУМЛИЈА_ДУБРАВА_70688_721824",
    },
    {
        label: "КУРШУМЛИЈА, ЂАКЕ",
        value: "КУРШУМЛИЈА_ЂАКЕ_70688_721832",
    },
    {
        label: "КУРШУМЛИЈА, ЖАЛИЦА",
        value: "КУРШУМЛИЈА_ЖАЛИЦА_70688_721859",
    },
    {
        label: "КУРШУМЛИЈА, ЖЕГРОВА",
        value: "КУРШУМЛИЈА_ЖЕГРОВА_70688_721867",
    },
    {
        label: "КУРШУМЛИЈА, ЖЕЉЕВА",
        value: "КУРШУМЛИЈА_ЖЕЉЕВА_70688_721875",
    },
    {
        label: "КУРШУМЛИЈА, ЖУЧ",
        value: "КУРШУМЛИЈА_ЖУЧ_70688_721883",
    },
    {
        label: "КУРШУМЛИЈА, ЗАГРАЂЕ",
        value: "КУРШУМЛИЈА_ЗАГРАЂЕ_70688_721891",
    },
    {
        label: "КУРШУМЛИЈА, ЗЕБИЦА",
        value: "КУРШУМЛИЈА_ЗЕБИЦА_70688_721905",
    },
    {
        label: "КУРШУМЛИЈА, ИВАН КУЛА",
        value: "КУРШУМЛИЈА_ИВАН КУЛА_70688_721913",
    },
    {
        label: "КУРШУМЛИЈА, ИГРИШТЕ",
        value: "КУРШУМЛИЈА_ИГРИШТЕ_70688_721921",
    },
    {
        label: "КУРШУМЛИЈА, КАЛИМАНЦЕ",
        value: "КУРШУМЛИЈА_КАЛИМАНЦЕ_70688_721930",
    },
    {
        label: "КУРШУМЛИЈА, КАСТРАТ",
        value: "КУРШУМЛИЈА_КАСТРАТ_70688_721948",
    },
    {
        label: "КУРШУМЛИЈА, КОЊУВА",
        value: "КУРШУМЛИЈА_КОЊУВА_70688_721956",
    },
    {
        label: "КУРШУМЛИЈА, КОСМАЧА",
        value: "КУРШУМЛИЈА_КОСМАЧА_70688_721964",
    },
    {
        label: "КУРШУМЛИЈА, КРТОК",
        value: "КУРШУМЛИЈА_КРТОК_70688_721972",
    },
    {
        label: "КУРШУМЛИЈА, КРЧМАРЕ",
        value: "КУРШУМЛИЈА_КРЧМАРЕ_70688_721999",
    },
    {
        label: "КУРШУМЛИЈА, КУПИНОВО",
        value: "КУРШУМЛИЈА_КУПИНОВО_70688_722006",
    },
    {
        label: "КУРШУМЛИЈА, КУРШУМЛИЈА",
        value: "КУРШУМЛИЈА_КУРШУМЛИЈА_70688_722014",
    },
    {
        label: "КУРШУМЛИЈА, КУРШУМЛИЈСКА БАЊА",
        value: "КУРШУМЛИЈА_КУРШУМЛИЈСКА БАЊА_70688_722022",
    },
    {
        label: "КУРШУМЛИЈА, КУТЛОВО",
        value: "КУРШУМЛИЈА_КУТЛОВО_70688_722049",
    },
    {
        label: "КУРШУМЛИЈА, ЛУКОВО",
        value: "КУРШУМЛИЈА_ЛУКОВО_70688_722057",
    },
    {
        label: "КУРШУМЛИЈА, ЉУТОВА",
        value: "КУРШУМЛИЈА_ЉУТОВА_70688_722065",
    },
    {
        label: "КУРШУМЛИЈА, ЉУША",
        value: "КУРШУМЛИЈА_ЉУША_70688_722073",
    },
    {
        label: "КУРШУМЛИЈА, МАГОВО",
        value: "КУРШУМЛИЈА_МАГОВО_70688_722081",
    },
    {
        label: "КУРШУМЛИЈА, МАЛА КОСАНИЦА",
        value: "КУРШУМЛИЈА_МАЛА КОСАНИЦА_70688_722090",
    },
    {
        label: "КУРШУМЛИЈА, МАРИЧИЋЕ",
        value: "КУРШУМЛИЈА_МАРИЧИЋЕ_70688_722103",
    },
    {
        label: "КУРШУМЛИЈА, МАРКОВИЋЕ",
        value: "КУРШУМЛИЈА_МАРКОВИЋЕ_70688_722111",
    },
    {
        label: "КУРШУМЛИЈА, МАТАРОВА",
        value: "КУРШУМЛИЈА_МАТАРОВА_70688_722120",
    },
    {
        label: "КУРШУМЛИЈА, МАЧЈА СТЕНА",
        value: "КУРШУМЛИЈА_МАЧЈА СТЕНА_70688_722138",
    },
    {
        label: "КУРШУМЛИЈА, МАЧКОВАЦ",
        value: "КУРШУМЛИЈА_МАЧКОВАЦ_70688_722146",
    },
    {
        label: "КУРШУМЛИЈА, МЕРДАРЕ",
        value: "КУРШУМЛИЈА_МЕРДАРЕ_70688_722154",
    },
    {
        label: "КУРШУМЛИЈА, МЕРЋЕЗ",
        value: "КУРШУМЛИЈА_МЕРЋЕЗ_70688_722162",
    },
    {
        label: "КУРШУМЛИЈА, МЕХАНЕ",
        value: "КУРШУМЛИЈА_МЕХАНЕ_70688_722189",
    },
    {
        label: "КУРШУМЛИЈА, МИЈОЧИЋИ",
        value: "КУРШУМЛИЈА_МИЈОЧИЋИ_70688_722197",
    },
    {
        label: "КУРШУМЛИЈА, МИРНИЦА",
        value: "КУРШУМЛИЈА_МИРНИЦА_70688_722219",
    },
    {
        label: "КУРШУМЛИЈА, МРЧА",
        value: "КУРШУМЛИЈА_МРЧА_70688_722227",
    },
    {
        label: "КУРШУМЛИЈА, НЕВАДА",
        value: "КУРШУМЛИЈА_НЕВАДА_70688_722235",
    },
    {
        label: "КУРШУМЛИЈА, НОВО СЕЛО",
        value: "КУРШУМЛИЈА_НОВО СЕЛО_70688_722243",
    },
    {
        label: "КУРШУМЛИЈА, ОРЛОВАЦ",
        value: "КУРШУМЛИЈА_ОРЛОВАЦ_70688_722251",
    },
    {
        label: "КУРШУМЛИЈА, ПАВАШТИЦА",
        value: "КУРШУМЛИЈА_ПАВАШТИЦА_70688_722260",
    },
    {
        label: "КУРШУМЛИЈА, ПАРАДА",
        value: "КУРШУМЛИЈА_ПАРАДА_70688_722278",
    },
    {
        label: "КУРШУМЛИЈА, ПАРДУСЕ",
        value: "КУРШУМЛИЈА_ПАРДУСЕ_70688_722286",
    },
    {
        label: "КУРШУМЛИЈА, ПАЧАРАЂА",
        value: "КУРШУМЛИЈА_ПАЧАРАЂА_70688_722294",
    },
    {
        label: "КУРШУМЛИЈА, ПЕПЕЉЕВАЦ",
        value: "КУРШУМЛИЈА_ПЕПЕЉЕВАЦ_70688_722308",
    },
    {
        label: "КУРШУМЛИЈА, ПЕРУНИКА",
        value: "КУРШУМЛИЈА_ПЕРУНИКА_70688_722316",
    },
    {
        label: "КУРШУМЛИЈА, ПЉАКОВО",
        value: "КУРШУМЛИЈА_ПЉАКОВО_70688_722324",
    },
    {
        label: "КУРШУМЛИЈА, ПРЕВЕТИЦА",
        value: "КУРШУМЛИЈА_ПРЕВЕТИЦА_70688_722332",
    },
    {
        label: "КУРШУМЛИЈА, ПРЕКОРАЂА",
        value: "КУРШУМЛИЈА_ПРЕКОРАЂА_70688_722359",
    },
    {
        label: "КУРШУМЛИЈА, ПРОЛОМ",
        value: "КУРШУМЛИЈА_ПРОЛОМ_70688_722367",
    },
    {
        label: "КУРШУМЛИЈА, РАВНИ ШОРТ",
        value: "КУРШУМЛИЈА_РАВНИ ШОРТ_70688_722375",
    },
    {
        label: "КУРШУМЛИЈА, РАСТЕЛИЦА",
        value: "КУРШУМЛИЈА_РАСТЕЛИЦА_70688_722383",
    },
    {
        label: "КУРШУМЛИЈА, РАЧА",
        value: "КУРШУМЛИЈА_РАЧА_70688_722391",
    },
    {
        label: "КУРШУМЛИЈА, РУДАРЕ",
        value: "КУРШУМЛИЈА_РУДАРЕ_70688_722405",
    },
    {
        label: "КУРШУМЛИЈА, САГОЊЕВО",
        value: "КУРШУМЛИЈА_САГОЊЕВО_70688_722413",
    },
    {
        label: "КУРШУМЛИЈА, САМОКОВО",
        value: "КУРШУМЛИЈА_САМОКОВО_70688_722421",
    },
    {
        label: "КУРШУМЛИЈА, СВИЊИШТЕ",
        value: "КУРШУМЛИЈА_СВИЊИШТЕ_70688_722430",
    },
    {
        label: "КУРШУМЛИЈА, СЕКИРАЧА",
        value: "КУРШУМЛИЈА_СЕКИРАЧА_70688_722448",
    },
    {
        label: "КУРШУМЛИЈА, СЕЛИШТЕ",
        value: "КУРШУМЛИЈА_СЕЛИШТЕ_70688_722456",
    },
    {
        label: "КУРШУМЛИЈА, СЕЛОВА",
        value: "КУРШУМЛИЈА_СЕЛОВА_70688_722464",
    },
    {
        label: "КУРШУМЛИЈА, СЕОЦЕ",
        value: "КУРШУМЛИЈА_СЕОЦЕ_70688_722472",
    },
    {
        label: "КУРШУМЛИЈА, СПАНЦЕ",
        value: "КУРШУМЛИЈА_СПАНЦЕ_70688_722499",
    },
    {
        label: "КУРШУМЛИЈА, ТАЧЕВАЦ",
        value: "КУРШУМЛИЈА_ТАЧЕВАЦ_70688_722502",
    },
    {
        label: "КУРШУМЛИЈА, ТИЈОВАЦ",
        value: "КУРШУМЛИЈА_ТИЈОВАЦ_70688_722529",
    },
    {
        label: "КУРШУМЛИЈА, ТМАВА",
        value: "КУРШУМЛИЈА_ТМАВА_70688_722537",
    },
    {
        label: "КУРШУМЛИЈА, ТРЕБИЊЕ",
        value: "КУРШУМЛИЈА_ТРЕБИЊЕ_70688_722545",
    },
    {
        label: "КУРШУМЛИЈА, ТРЕЋАК",
        value: "КУРШУМЛИЈА_ТРЕЋАК_70688_722553",
    },
    {
        label: "КУРШУМЛИЈА, ТРЕШЊИЦА",
        value: "КУРШУМЛИЈА_ТРЕШЊИЦА_70688_722561",
    },
    {
        label: "КУРШУМЛИЈА, ТРМКА",
        value: "КУРШУМЛИЈА_ТРМКА_70688_722570",
    },
    {
        label: "КУРШУМЛИЈА, ТРН",
        value: "КУРШУМЛИЈА_ТРН_70688_722588",
    },
    {
        label: "КУРШУМЛИЈА, ТРПЕЗА",
        value: "КУРШУМЛИЈА_ТРПЕЗА_70688_722596",
    },
    {
        label: "КУРШУМЛИЈА, ШАТРА",
        value: "КУРШУМЛИЈА_ШАТРА_70688_722600",
    },
    {
        label: "КУРШУМЛИЈА, ШТАВА",
        value: "КУРШУМЛИЈА_ШТАВА_70688_722618",
    },
    {
        label: "КУЧЕВО, БЛАГОЈЕВ КАМЕН",
        value: "КУЧЕВО_БЛАГОЈЕВ КАМЕН_70696_722626",
    },
    {
        label: "КУЧЕВО, БУКОВСКА",
        value: "КУЧЕВО_БУКОВСКА_70696_722634",
    },
    {
        label: "КУЧЕВО, ВЕЛИКА БРЕСНИЦА",
        value: "КУЧЕВО_ВЕЛИКА БРЕСНИЦА_70696_708755",
    },
    {
        label: "КУЧЕВО, ВОЛУЈА",
        value: "КУЧЕВО_ВОЛУЈА_70696_722642",
    },
    {
        label: "КУЧЕВО, ВУКОВИЋ",
        value: "КУЧЕВО_ВУКОВИЋ_70696_712612",
    },
    {
        label: "КУЧЕВО, ГЛОЖАНЕ",
        value: "КУЧЕВО_ГЛОЖАНЕ_70696_722669",
    },
    {
        label: "КУЧЕВО, ДУБОКА",
        value: "КУЧЕВО_ДУБОКА_70696_722677",
    },
    {
        label: "КУЧЕВО, ЗЕЛЕНИК",
        value: "КУЧЕВО_ЗЕЛЕНИК_70696_712698",
    },
    {
        label: "КУЧЕВО, КАОНА",
        value: "КУЧЕВО_КАОНА_70696_722685",
    },
    {
        label: "КУЧЕВО, КУЧАЈНА",
        value: "КУЧЕВО_КУЧАЈНА_70696_722693",
    },
    {
        label: "КУЧЕВО, КУЧЕВО I",
        value: "КУЧЕВО_КУЧЕВО I_70696_722707",
    },
    {
        label: "КУЧЕВО, КУЧЕВО II",
        value: "КУЧЕВО_КУЧЕВО II_70696_722715",
    },
    {
        label: "КУЧЕВО, КУЧЕВО III",
        value: "КУЧЕВО_КУЧЕВО III_70696_722723",
    },
    {
        label: "КУЧЕВО, ЉЕШНИЦА",
        value: "КУЧЕВО_ЉЕШНИЦА_70696_722731",
    },
    {
        label: "КУЧЕВО, МАЛА БРЕСНИЦА",
        value: "КУЧЕВО_МАЛА БРЕСНИЦА_70696_708925",
    },
    {
        label: "КУЧЕВО, МИШЉЕНОВАЦ",
        value: "КУЧЕВО_МИШЉЕНОВАЦ_70696_722740",
    },
    {
        label: "КУЧЕВО, МУСТАПИЋ",
        value: "КУЧЕВО_МУСТАПИЋ_70696_722758",
    },
    {
        label: "КУЧЕВО, НЕРЕСНИЦА",
        value: "КУЧЕВО_НЕРЕСНИЦА_70696_722766",
    },
    {
        label: "КУЧЕВО, ПОПОВАЦ",
        value: "КУЧЕВО_ПОПОВАЦ_70696_722774",
    },
    {
        label: "КУЧЕВО, РАБРОВО",
        value: "КУЧЕВО_РАБРОВО_70696_708984",
    },
    {
        label: "КУЧЕВО, РАВНИШТЕ",
        value: "КУЧЕВО_РАВНИШТЕ_70696_722782",
    },
    {
        label: "КУЧЕВО, РАДЕНКА",
        value: "КУЧЕВО_РАДЕНКА_70696_722804",
    },
    {
        label: "КУЧЕВО, РАКОВА БАРА",
        value: "КУЧЕВО_РАКОВА БАРА_70696_722812",
    },
    {
        label: "КУЧЕВО, СЕНА",
        value: "КУЧЕВО_СЕНА_70696_722839",
    },
    {
        label: "КУЧЕВО, СРПЦЕ",
        value: "КУЧЕВО_СРПЦЕ_70696_722847",
    },
    {
        label: "КУЧЕВО, ТУРИЈА",
        value: "КУЧЕВО_ТУРИЈА_70696_722855",
    },
    {
        label: "КУЧЕВО, ЦЕРЕМОШЊА",
        value: "КУЧЕВО_ЦЕРЕМОШЊА_70696_722863",
    },
    {
        label: "КУЧЕВО, ЦЕРОВИЦА",
        value: "КУЧЕВО_ЦЕРОВИЦА_70696_722871",
    },
    {
        label: "КУЧЕВО, ШЕВИЦА",
        value: "КУЧЕВО_ШЕВИЦА_70696_722880",
    },
    {
        label: "ЛАЗАРЕВАЦ, АРАПОВАЦ",
        value: "ЛАЗАРЕВАЦ_АРАПОВАЦ_70165_722898",
    },
    {
        label: "ЛАЗАРЕВАЦ, БАРЗИЛОВИЦА",
        value: "ЛАЗАРЕВАЦ_БАРЗИЛОВИЦА_70165_722901",
    },
    {
        label: "ЛАЗАРЕВАЦ, БАРОШЕВАЦ",
        value: "ЛАЗАРЕВАЦ_БАРОШЕВАЦ_70165_722910",
    },
    {
        label: "ЛАЗАРЕВАЦ, БИСТРИЦА",
        value: "ЛАЗАРЕВАЦ_БИСТРИЦА_70165_722928",
    },
    {
        label: "ЛАЗАРЕВАЦ, БРАЈКОВАЦ",
        value: "ЛАЗАРЕВАЦ_БРАЈКОВАЦ_70165_722936",
    },
    {
        label: "ЛАЗАРЕВАЦ, БУРОВО",
        value: "ЛАЗАРЕВАЦ_БУРОВО_70165_722944",
    },
    {
        label: "ЛАЗАРЕВАЦ, ВЕЛИКИ ЦРЉЕНИ",
        value: "ЛАЗАРЕВАЦ_ВЕЛИКИ ЦРЉЕНИ_70165_722952",
    },
    {
        label: "ЛАЗАРЕВАЦ, ВРБОВНО",
        value: "ЛАЗАРЕВАЦ_ВРБОВНО_70165_722987",
    },
    {
        label: "ЛАЗАРЕВАЦ, ВРЕОЦИ",
        value: "ЛАЗАРЕВАЦ_ВРЕОЦИ_70165_722995",
    },
    {
        label: "ЛАЗАРЕВАЦ, ДРЕН",
        value: "ЛАЗАРЕВАЦ_ДРЕН_70165_723002",
    },
    {
        label: "ЛАЗАРЕВАЦ, ДУДОВИЦА",
        value: "ЛАЗАРЕВАЦ_ДУДОВИЦА_70165_723029",
    },
    {
        label: "ЛАЗАРЕВАЦ, ЖУПАЊАЦ",
        value: "ЛАЗАРЕВАЦ_ЖУПАЊАЦ_70165_723037",
    },
    {
        label: "ЛАЗАРЕВАЦ, ЗЕОКЕ",
        value: "ЛАЗАРЕВАЦ_ЗЕОКЕ_70165_723045",
    },
    {
        label: "ЛАЗАРЕВАЦ, ЈУНКОВАЦ",
        value: "ЛАЗАРЕВАЦ_ЈУНКОВАЦ_70165_723053",
    },
    {
        label: "ЛАЗАРЕВАЦ, КРУШЕВИЦА",
        value: "ЛАЗАРЕВАЦ_КРУШЕВИЦА_70165_723061",
    },
    {
        label: "ЛАЗАРЕВАЦ, ЛАЗАРЕВАЦ",
        value: "ЛАЗАРЕВАЦ_ЛАЗАРЕВАЦ_70165_723070",
    },
    {
        label: "ЛАЗАРЕВАЦ, ЛЕСКОВАЦ",
        value: "ЛАЗАРЕВАЦ_ЛЕСКОВАЦ_70165_723088",
    },
    {
        label: "ЛАЗАРЕВАЦ, ЛУКАВИЦА",
        value: "ЛАЗАРЕВАЦ_ЛУКАВИЦА_70165_723096",
    },
    {
        label: "ЛАЗАРЕВАЦ, МАЛИ ЦРЉЕНИ",
        value: "ЛАЗАРЕВАЦ_МАЛИ ЦРЉЕНИ_70165_723100",
    },
    {
        label: "ЛАЗАРЕВАЦ, МЕДОШЕВАЦ",
        value: "ЛАЗАРЕВАЦ_МЕДОШЕВАЦ_70165_723118",
    },
    {
        label: "ЛАЗАРЕВАЦ, МИРОСАЉЦИ",
        value: "ЛАЗАРЕВАЦ_МИРОСАЉЦИ_70165_723126",
    },
    {
        label: "ЛАЗАРЕВАЦ, ПЕТКА",
        value: "ЛАЗАРЕВАЦ_ПЕТКА_70165_723142",
    },
    {
        label: "ЛАЗАРЕВАЦ, ПРКОСАВА",
        value: "ЛАЗАРЕВАЦ_ПРКОСАВА_70165_723169",
    },
    {
        label: "ЛАЗАРЕВАЦ, РУДОВЦИ",
        value: "ЛАЗАРЕВАЦ_РУДОВЦИ_70165_723185",
    },
    {
        label: "ЛАЗАРЕВАЦ, САКУЉА",
        value: "ЛАЗАРЕВАЦ_САКУЉА_70165_723193",
    },
    {
        label: "ЛАЗАРЕВАЦ, СОКОЛОВО",
        value: "ЛАЗАРЕВАЦ_СОКОЛОВО_70165_723207",
    },
    {
        label: "ЛАЗАРЕВАЦ, СТЕПОЈЕВАЦ",
        value: "ЛАЗАРЕВАЦ_СТЕПОЈЕВАЦ_70165_723215",
    },
    {
        label: "ЛАЗАРЕВАЦ, СТРМОВО",
        value: "ЛАЗАРЕВАЦ_СТРМОВО_70165_723223",
    },
    {
        label: "ЛАЗАРЕВАЦ, СТУБИЦА",
        value: "ЛАЗАРЕВАЦ_СТУБИЦА_70165_723231",
    },
    {
        label: "ЛАЗАРЕВАЦ, ТРБУШНИЦА",
        value: "ЛАЗАРЕВАЦ_ТРБУШНИЦА_70165_723240",
    },
    {
        label: "ЛАЗАРЕВАЦ, ЦВЕТОВАЦ",
        value: "ЛАЗАРЕВАЦ_ЦВЕТОВАЦ_70165_723274",
    },
    {
        label: "ЛАЗАРЕВАЦ, ЧИБУТКОВИЦА",
        value: "ЛАЗАРЕВАЦ_ЧИБУТКОВИЦА_70165_723282",
    },
    {
        label: "ЛАЗАРЕВАЦ, ШОПИЋ",
        value: "ЛАЗАРЕВАЦ_ШОПИЋ_70165_723304",
    },
    {
        label: "ЛАЗАРЕВАЦ, ШУШЊАР",
        value: "ЛАЗАРЕВАЦ_ШУШЊАР_70165_723312",
    },
    {
        label: "ЛАЈКОВАЦ, БАЈЕВАЦ",
        value: "ЛАЈКОВАЦ_БАЈЕВАЦ_70700_742732",
    },
    {
        label: "ЛАЈКОВАЦ, БОГОВАЂА",
        value: "ЛАЈКОВАЦ_БОГОВАЂА_70700_728233",
    },
    {
        label: "ЛАЈКОВАЦ, ВРАЧЕВИЋ",
        value: "ЛАЈКОВАЦ_ВРАЧЕВИЋ_70700_727881",
    },
    {
        label: "ЛАЈКОВАЦ, ДОЊИ ЛАЈКОВАЦ",
        value: "ЛАЈКОВАЦ_ДОЊИ ЛАЈКОВАЦ_70700_727946",
    },
    {
        label: "ЛАЈКОВАЦ, ЈАБУЧЈЕ",
        value: "ЛАЈКОВАЦ_ЈАБУЧЈЕ_70700_742872",
    },
    {
        label: "ЛАЈКОВАЦ, ЛАЈКОВАЦ",
        value: "ЛАЈКОВАЦ_ЛАЈКОВАЦ_70700_742945",
    },
    {
        label: "ЛАЈКОВАЦ, МАЛИ БОРАК",
        value: "ЛАЈКОВАЦ_МАЛИ БОРАК_70700_742961",
    },
    {
        label: "ЛАЈКОВАЦ, МАРКОВА ЦРКВА",
        value: "ЛАЈКОВАЦ_МАРКОВА ЦРКВА_70700_728080",
    },
    {
        label: "ЛАЈКОВАЦ, НЕПРИЧАВА",
        value: "ЛАЈКОВАЦ_НЕПРИЧАВА_70700_742988",
    },
    {
        label: "ЛАЈКОВАЦ, ПЕПЕЉЕВАЦ",
        value: "ЛАЈКОВАЦ_ПЕПЕЉЕВАЦ_70700_728195",
    },
    {
        label: "ЛАЈКОВАЦ, ПРИДВОРИЦА",
        value: "ЛАЈКОВАЦ_ПРИДВОРИЦА_70700_728225",
    },
    {
        label: "ЛАЈКОВАЦ, РАТКОВАЦ",
        value: "ЛАЈКОВАЦ_РАТКОВАЦ_70700_728276",
    },
    {
        label: "ЛАЈКОВАЦ, РУБРИБРЕЗА",
        value: "ЛАЈКОВАЦ_РУБРИБРЕЗА_70700_743046",
    },
    {
        label: "ЛАЈКОВАЦ, СКОБАЉ",
        value: "ЛАЈКОВАЦ_СКОБАЉ_70700_743062",
    },
    {
        label: "ЛАЈКОВАЦ, СЛОВАЦ",
        value: "ЛАЈКОВАЦ_СЛОВАЦ_70700_708259",
    },
    {
        label: "ЛАЈКОВАЦ, СТЕПАЊЕ",
        value: "ЛАЈКОВАЦ_СТЕПАЊЕ_70700_743119",
    },
    {
        label: "ЛАЈКОВАЦ, СТРМОВО",
        value: "ЛАЈКОВАЦ_СТРМОВО_70700_728314",
    },
    {
        label: "ЛАЈКОВАЦ, ЋЕЛИЈЕ",
        value: "ЛАЈКОВАЦ_ЋЕЛИЈЕ_70700_723266",
    },
    {
        label: "ЛАПОВО, ЛАПОВО",
        value: "ЛАПОВО_ЛАПОВО_71277_745146",
    },
    {
        label: "ЛЕБАНЕ, БАЧЕВИНА",
        value: "ЛЕБАНЕ_БАЧЕВИНА_70718_723339",
    },
    {
        label: "ЛЕБАНЕ, БОШЊАЦЕ",
        value: "ЛЕБАНЕ_БОШЊАЦЕ_70718_723380",
    },
    {
        label: "ЛЕБАНЕ, БУВЦЕ",
        value: "ЛЕБАНЕ_БУВЦЕ_70718_723401",
    },
    {
        label: "ЛЕБАНЕ, ВЕЛИКО ВОЈЛОВЦЕ",
        value: "ЛЕБАНЕ_ВЕЛИКО ВОЈЛОВЦЕ_70718_723436",
    },
    {
        label: "ЛЕБАНЕ, ГЕГЉА",
        value: "ЛЕБАНЕ_ГЕГЉА_70718_723509",
    },
    {
        label: "ЛЕБАНЕ, ГОЛИ РИД",
        value: "ЛЕБАНЕ_ГОЛИ РИД_70718_723517",
    },
    {
        label: "ЛЕБАНЕ, ГОРЊЕ ВРАНОВЦЕ",
        value: "ЛЕБАНЕ_ГОРЊЕ ВРАНОВЦЕ_70718_723533",
    },
    {
        label: "ЛЕБАНЕ, ГРГУРОВЦЕ",
        value: "ЛЕБАНЕ_ГРГУРОВЦЕ_70718_723576",
    },
    {
        label: "ЛЕБАНЕ, ДОЊЕ ВРАНОВЦЕ",
        value: "ЛЕБАНЕ_ДОЊЕ ВРАНОВЦЕ_70718_723614",
    },
    {
        label: "ЛЕБАНЕ, ДРВОДЕЉ",
        value: "ЛЕБАНЕ_ДРВОДЕЉ_70718_723657",
    },
    {
        label: "ЛЕБАНЕ, ЖДЕГЛОВО",
        value: "ЛЕБАНЕ_ЖДЕГЛОВО_70718_723690",
    },
    {
        label: "ЛЕБАНЕ, КЛАЈИЋ",
        value: "ЛЕБАНЕ_КЛАЈИЋ_70718_723754",
    },
    {
        label: "ЛЕБАНЕ, КОЊИНО",
        value: "ЛЕБАНЕ_КОЊИНО_70718_723762",
    },
    {
        label: "ЛЕБАНЕ, КРИВАЧА",
        value: "ЛЕБАНЕ_КРИВАЧА_70718_723789",
    },
    {
        label: "ЛЕБАНЕ, ЛАЛИНОВАЦ",
        value: "ЛЕБАНЕ_ЛАЛИНОВАЦ_70718_723797",
    },
    {
        label: "ЛЕБАНЕ, ЛЕБАНЕ",
        value: "ЛЕБАНЕ_ЛЕБАНЕ_70718_723819",
    },
    {
        label: "ЛЕБАНЕ, ЛИПОВИЦА",
        value: "ЛЕБАНЕ_ЛИПОВИЦА_70718_723835",
    },
    {
        label: "ЛЕБАНЕ, ЛУГАРЕ",
        value: "ЛЕБАНЕ_ЛУГАРЕ_70718_723851",
    },
    {
        label: "ЛЕБАНЕ, МАЛО ВОЈЛОВЦЕ",
        value: "ЛЕБАНЕ_МАЛО ВОЈЛОВЦЕ_70718_723894",
    },
    {
        label: "ЛЕБАНЕ, НОВА ТОПОЛА",
        value: "ЛЕБАНЕ_НОВА ТОПОЛА_70718_724009",
    },
    {
        label: "ЛЕБАНЕ, НОВО СЕЛО",
        value: "ЛЕБАНЕ_НОВО СЕЛО_70718_724017",
    },
    {
        label: "ЛЕБАНЕ, ПЕРТАТЕ",
        value: "ЛЕБАНЕ_ПЕРТАТЕ_70718_724050",
    },
    {
        label: "ЛЕБАНЕ, ПЕТРОВАЦ",
        value: "ЛЕБАНЕ_ПЕТРОВАЦ_70718_724076",
    },
    {
        label: "ЛЕБАНЕ, ПОПОВЦЕ",
        value: "ЛЕБАНЕ_ПОПОВЦЕ_70718_724092",
    },
    {
        label: "ЛЕБАНЕ, ПОРОШТИЦА",
        value: "ЛЕБАНЕ_ПОРОШТИЦА_70718_724114",
    },
    {
        label: "ЛЕБАНЕ, ПРЕКОПЧЕЛИЦА",
        value: "ЛЕБАНЕ_ПРЕКОПЧЕЛИЦА_70718_724122",
    },
    {
        label: "ЛЕБАНЕ, РАДЕВЦЕ",
        value: "ЛЕБАНЕ_РАДЕВЦЕ_70718_724181",
    },
    {
        label: "ЛЕБАНЕ, РАДИНОВАЦ",
        value: "ЛЕБАНЕ_РАДИНОВАЦ_70718_724190",
    },
    {
        label: "ЛЕБАНЕ, РАФУНА",
        value: "ЛЕБАНЕ_РАФУНА_70718_724203",
    },
    {
        label: "ЛЕБАНЕ, СВИЊАРИЦА",
        value: "ЛЕБАНЕ_СВИЊАРИЦА_70718_724254",
    },
    {
        label: "ЛЕБАНЕ, СЕКИЦОЛ",
        value: "ЛЕБАНЕ_СЕКИЦОЛ_70718_724289",
    },
    {
        label: "ЛЕБАНЕ, СЛИШАНЕ",
        value: "ЛЕБАНЕ_СЛИШАНЕ_70718_724335",
    },
    {
        label: "ЛЕБАНЕ, ТОГОЧЕВЦЕ",
        value: "ЛЕБАНЕ_ТОГОЧЕВЦЕ_70718_724378",
    },
    {
        label: "ЛЕБАНЕ, ЋЕНОВАЦ",
        value: "ЛЕБАНЕ_ЋЕНОВАЦ_70718_724416",
    },
    {
        label: "ЛЕБАНЕ, ЦЕКАВИЦА",
        value: "ЛЕБАНЕ_ЦЕКАВИЦА_70718_724424",
    },
    {
        label: "ЛЕБАНЕ, ШАРЦЕ",
        value: "ЛЕБАНЕ_ШАРЦЕ_70718_724475",
    },
    {
        label: "ЛЕБАНЕ, ШИЛОВО",
        value: "ЛЕБАНЕ_ШИЛОВО_70718_724483",
    },
    {
        label: "ЛЕБАНЕ, ШТУЛАЦ",
        value: "ЛЕБАНЕ_ШТУЛАЦ_70718_724491",
    },
    {
        label: "ЛЕБАНЕ, ШУМАНЕ",
        value: "ЛЕБАНЕ_ШУМАНЕ_70718_724505",
    },
    {
        label: "ЛЕСКОВАЦ, БАБИЧКО",
        value: "ЛЕСКОВАЦ_БАБИЧКО_70726_724513",
    },
    {
        label: "ЛЕСКОВАЦ, БАДИНЦЕ",
        value: "ЛЕСКОВАЦ_БАДИНЦЕ_70726_724521",
    },
    {
        label: "ЛЕСКОВАЦ, БАРЈЕ",
        value: "ЛЕСКОВАЦ_БАРЈЕ_70726_724530",
    },
    {
        label: "ЛЕСКОВАЦ, БЕЛАНОВЦЕ",
        value: "ЛЕСКОВАЦ_БЕЛАНОВЦЕ_70726_724548",
    },
    {
        label: "ЛЕСКОВАЦ, БЕЛИ ПОТОК",
        value: "ЛЕСКОВАЦ_БЕЛИ ПОТОК_70726_724556",
    },
    {
        label: "ЛЕСКОВАЦ, БИСТРИЦА",
        value: "ЛЕСКОВАЦ_БИСТРИЦА_70726_724564",
    },
    {
        label: "ЛЕСКОВАЦ, БОБИШТЕ",
        value: "ЛЕСКОВАЦ_БОБИШТЕ_70726_724572",
    },
    {
        label: "ЛЕСКОВАЦ, БОГОЈЕВЦЕ",
        value: "ЛЕСКОВАЦ_БОГОЈЕВЦЕ_70726_724599",
    },
    {
        label: "ЛЕСКОВАЦ, БОЈИШИНА",
        value: "ЛЕСКОВАЦ_БОЈИШИНА_70726_710245",
    },
    {
        label: "ЛЕСКОВАЦ, БОЋЕВИЦА",
        value: "ЛЕСКОВАЦ_БОЋЕВИЦА_70726_710270",
    },
    {
        label: "ЛЕСКОВАЦ, БРАТМИЛОВЦЕ",
        value: "ЛЕСКОВАЦ_БРАТМИЛОВЦЕ_70726_724602",
    },
    {
        label: "ЛЕСКОВАЦ, БРЕЈАНОВЦЕ",
        value: "ЛЕСКОВАЦ_БРЕЈАНОВЦЕ_70726_724629",
    },
    {
        label: "ЛЕСКОВАЦ, БРЕСТОВАЦ",
        value: "ЛЕСКОВАЦ_БРЕСТОВАЦ_70726_724637",
    },
    {
        label: "ЛЕСКОВАЦ, БРЗА",
        value: "ЛЕСКОВАЦ_БРЗА_70726_724645",
    },
    {
        label: "ЛЕСКОВАЦ, БРИЧЕВЉЕ",
        value: "ЛЕСКОВАЦ_БРИЧЕВЉЕ_70726_709620",
    },
    {
        label: "ЛЕСКОВАЦ, БУКОВА ГЛАВА",
        value: "ЛЕСКОВАЦ_БУКОВА ГЛАВА_70726_724653",
    },
    {
        label: "ЛЕСКОВАЦ, БУНУША",
        value: "ЛЕСКОВАЦ_БУНУША_70726_724661",
    },
    {
        label: "ЛЕСКОВАЦ, ВЕЛИКА БИЉАНИЦА",
        value: "ЛЕСКОВАЦ_ВЕЛИКА БИЉАНИЦА_70726_724670",
    },
    {
        label: "ЛЕСКОВАЦ, ВЕЛИКА ГРАБОВНИЦА",
        value: "ЛЕСКОВАЦ_ВЕЛИКА ГРАБОВНИЦА_70726_724688",
    },
    {
        label: "ЛЕСКОВАЦ, ВЕЛИКА КОПАШНИЦА",
        value: "ЛЕСКОВАЦ_ВЕЛИКА КОПАШНИЦА_70726_724696",
    },
    {
        label: "ЛЕСКОВАЦ, ВЕЛИКО ТРЊАНЕ",
        value: "ЛЕСКОВАЦ_ВЕЛИКО ТРЊАНЕ_70726_724700",
    },
    {
        label: "ЛЕСКОВАЦ, ВИЉЕ КОЛО",
        value: "ЛЕСКОВАЦ_ВИЉЕ КОЛО_70726_710300",
    },
    {
        label: "ЛЕСКОВАЦ, ВИНА",
        value: "ЛЕСКОВАЦ_ВИНА_70726_724718",
    },
    {
        label: "ЛЕСКОВАЦ, ВИНАРЦЕ",
        value: "ЛЕСКОВАЦ_ВИНАРЦЕ_70726_724726",
    },
    {
        label: "ЛЕСКОВАЦ, ВЛАСЕ",
        value: "ЛЕСКОВАЦ_ВЛАСЕ_70726_724734",
    },
    {
        label: "ЛЕСКОВАЦ, ВУЧЈЕ",
        value: "ЛЕСКОВАЦ_ВУЧЈЕ_70726_724742",
    },
    {
        label: "ЛЕСКОВАЦ, ГАГИНЦЕ",
        value: "ЛЕСКОВАЦ_ГАГИНЦЕ_70726_723479",
    },
    {
        label: "ЛЕСКОВАЦ, ГОРИНА",
        value: "ЛЕСКОВАЦ_ГОРИНА_70726_724769",
    },
    {
        label: "ЛЕСКОВАЦ, ГОРЊА ЈАЈИНА",
        value: "ЛЕСКОВАЦ_ГОРЊА ЈАЈИНА_70726_724777",
    },
    {
        label: "ЛЕСКОВАЦ, ГОРЊА КУПИНОВИЦА",
        value: "ЛЕСКОВАЦ_ГОРЊА КУПИНОВИЦА_70726_724785",
    },
    {
        label: "ЛЕСКОВАЦ, ГОРЊА ЛОКОШНИЦА",
        value: "ЛЕСКОВАЦ_ГОРЊА ЛОКОШНИЦА_70726_724793",
    },
    {
        label: "ЛЕСКОВАЦ, ГОРЊА СЛАТИНА",
        value: "ЛЕСКОВАЦ_ГОРЊА СЛАТИНА_70726_724807",
    },
    {
        label: "ЛЕСКОВАЦ, ГОРЊЕ КРАЈИНЦЕ",
        value: "ЛЕСКОВАЦ_ГОРЊЕ КРАЈИНЦЕ_70726_724823",
    },
    {
        label: "ЛЕСКОВАЦ, ГОРЊЕ СИНКОВЦЕ",
        value: "ЛЕСКОВАЦ_ГОРЊЕ СИНКОВЦЕ_70726_724831",
    },
    {
        label: "ЛЕСКОВАЦ, ГОРЊЕ СТОПАЊЕ",
        value: "ЛЕСКОВАЦ_ГОРЊЕ СТОПАЊЕ_70726_724840",
    },
    {
        label: "ЛЕСКОВАЦ, ГОРЊЕ ТРЊАНЕ",
        value: "ЛЕСКОВАЦ_ГОРЊЕ ТРЊАНЕ_70726_724858",
    },
    {
        label: "ЛЕСКОВАЦ, ГОРЊИ БУНИБРОД",
        value: "ЛЕСКОВАЦ_ГОРЊИ БУНИБРОД_70726_724866",
    },
    {
        label: "ЛЕСКОВАЦ, ГРАДАШНИЦА",
        value: "ЛЕСКОВАЦ_ГРАДАШНИЦА_70726_724874",
    },
    {
        label: "ЛЕСКОВАЦ, ГРАЈЕВЦЕ",
        value: "ЛЕСКОВАЦ_ГРАЈЕВЦЕ_70726_724882",
    },
    {
        label: "ЛЕСКОВАЦ, ГРАОВО",
        value: "ЛЕСКОВАЦ_ГРАОВО_70726_724904",
    },
    {
        label: "ЛЕСКОВАЦ, ГРДАНИЦА",
        value: "ЛЕСКОВАЦ_ГРДАНИЦА_70726_724912",
    },
    {
        label: "ЛЕСКОВАЦ, ГРДЕЛИЦА (ВАРОШ)",
        value: "ЛЕСКОВАЦ_ГРДЕЛИЦА (ВАРОШ)_70726_710423",
    },
    {
        label: "ЛЕСКОВАЦ, ГРДЕЛИЦА (СЕЛО)",
        value: "ЛЕСКОВАЦ_ГРДЕЛИЦА (СЕЛО)_70726_710431",
    },
    {
        label: "ЛЕСКОВАЦ, ГУБЕРЕВАЦ",
        value: "ЛЕСКОВАЦ_ГУБЕРЕВАЦ_70726_724939",
    },
    {
        label: "ЛЕСКОВАЦ, ДЕДИНА БАРА",
        value: "ЛЕСКОВАЦ_ДЕДИНА БАРА_70726_710474",
    },
    {
        label: "ЛЕСКОВАЦ, ДОБРОТИН",
        value: "ЛЕСКОВАЦ_ДОБРОТИН_70726_710539",
    },
    {
        label: "ЛЕСКОВАЦ, ДОЊА ЈАЈИНА",
        value: "ЛЕСКОВАЦ_ДОЊА ЈАЈИНА_70726_724947",
    },
    {
        label: "ЛЕСКОВАЦ, ДОЊА КУПИНОВИЦА",
        value: "ЛЕСКОВАЦ_ДОЊА КУПИНОВИЦА_70726_724955",
    },
    {
        label: "ЛЕСКОВАЦ, ДОЊА ЛОКОШНИЦА",
        value: "ЛЕСКОВАЦ_ДОЊА ЛОКОШНИЦА_70726_724963",
    },
    {
        label: "ЛЕСКОВАЦ, ДОЊА СЛАТИНА",
        value: "ЛЕСКОВАЦ_ДОЊА СЛАТИНА_70726_724971",
    },
    {
        label: "ЛЕСКОВАЦ, ДОЊЕ БРИЈАЊЕ",
        value: "ЛЕСКОВАЦ_ДОЊЕ БРИЈАЊЕ_70726_724980",
    },
    {
        label: "ЛЕСКОВАЦ, ДОЊЕ КРАЈИНЦЕ",
        value: "ЛЕСКОВАЦ_ДОЊЕ КРАЈИНЦЕ_70726_724998",
    },
    {
        label: "ЛЕСКОВАЦ, ДОЊЕ СИНКОВЦЕ",
        value: "ЛЕСКОВАЦ_ДОЊЕ СИНКОВЦЕ_70726_725005",
    },
    {
        label: "ЛЕСКОВАЦ, ДОЊЕ СТОПАЊЕ",
        value: "ЛЕСКОВАЦ_ДОЊЕ СТОПАЊЕ_70726_725013",
    },
    {
        label: "ЛЕСКОВАЦ, ДОЊЕ ТРЊАНЕ",
        value: "ЛЕСКОВАЦ_ДОЊЕ ТРЊАНЕ_70726_725021",
    },
    {
        label: "ЛЕСКОВАЦ, ДОЊИ БУНИБРОД",
        value: "ЛЕСКОВАЦ_ДОЊИ БУНИБРОД_70726_725030",
    },
    {
        label: "ЛЕСКОВАЦ, ДРАШКОВАЦ",
        value: "ЛЕСКОВАЦ_ДРАШКОВАЦ_70726_725048",
    },
    {
        label: "ЛЕСКОВАЦ, ДРВОДЕЉА",
        value: "ЛЕСКОВАЦ_ДРВОДЕЉА_70726_725056",
    },
    {
        label: "ЛЕСКОВАЦ, ДРЋЕВАЦ",
        value: "ЛЕСКОВАЦ_ДРЋЕВАЦ_70726_725064",
    },
    {
        label: "ЛЕСКОВАЦ, ДУШАНОВО",
        value: "ЛЕСКОВАЦ_ДУШАНОВО_70726_725072",
    },
    {
        label: "ЛЕСКОВАЦ, ЖАБЉАНЕ",
        value: "ЛЕСКОВАЦ_ЖАБЉАНЕ_70726_725102",
    },
    {
        label: "ЛЕСКОВАЦ, ЖИВКОВО",
        value: "ЛЕСКОВАЦ_ЖИВКОВО_70726_725129",
    },
    {
        label: "ЛЕСКОВАЦ, ЖИЖАВИЦА",
        value: "ЛЕСКОВАЦ_ЖИЖАВИЦА_70726_725137",
    },
    {
        label: "ЛЕСКОВАЦ, ЗАГУЖАНЕ",
        value: "ЛЕСКОВАЦ_ЗАГУЖАНЕ_70726_725145",
    },
    {
        label: "ЛЕСКОВАЦ, ЗАЛУЖЊЕ",
        value: "ЛЕСКОВАЦ_ЗАЛУЖЊЕ_70726_725153",
    },
    {
        label: "ЛЕСКОВАЦ, ЗЛОКУЋАНЕ",
        value: "ЛЕСКОВАЦ_ЗЛОКУЋАНЕ_70726_725161",
    },
    {
        label: "ЛЕСКОВАЦ, ЗЛОЋУДОВО",
        value: "ЛЕСКОВАЦ_ЗЛОЋУДОВО_70726_725170",
    },
    {
        label: "ЛЕСКОВАЦ, ЗОЉЕВО",
        value: "ЛЕСКОВАЦ_ЗОЉЕВО_70726_725188",
    },
    {
        label: "ЛЕСКОВАЦ, ИГРИШТЕ",
        value: "ЛЕСКОВАЦ_ИГРИШТЕ_70726_725196",
    },
    {
        label: "ЛЕСКОВАЦ, ЈАРСЕНОВО",
        value: "ЛЕСКОВАЦ_ЈАРСЕНОВО_70726_725200",
    },
    {
        label: "ЛЕСКОВАЦ, ЈАШУЊА",
        value: "ЛЕСКОВАЦ_ЈАШУЊА_70726_725218",
    },
    {
        label: "ЛЕСКОВАЦ, ЈЕЛАШНИЦА",
        value: "ЛЕСКОВАЦ_ЈЕЛАШНИЦА_70726_725226",
    },
    {
        label: "ЛЕСКОВАЦ, КАЛУЂЕРЦЕ",
        value: "ЛЕСКОВАЦ_КАЛУЂЕРЦЕ_70726_725234",
    },
    {
        label: "ЛЕСКОВАЦ, КАРАЂОРЂЕВАЦ",
        value: "ЛЕСКОВАЦ_КАРАЂОРЂЕВАЦ_70726_725242",
    },
    {
        label: "ЛЕСКОВАЦ, КАШТАВАР",
        value: "ЛЕСКОВАЦ_КАШТАВАР_70726_725285",
    },
    {
        label: "ЛЕСКОВАЦ, КОВАЧЕВА БАРА",
        value: "ЛЕСКОВАЦ_КОВАЧЕВА БАРА_70726_710636",
    },
    {
        label: "ЛЕСКОВАЦ, КОЗАРЕ",
        value: "ЛЕСКОВАЦ_КОЗАРЕ_70726_710644",
    },
    {
        label: "ЛЕСКОВАЦ, КОРАЋЕВАЦ",
        value: "ЛЕСКОВАЦ_КОРАЋЕВАЦ_70726_709824",
    },
    {
        label: "ЛЕСКОВАЦ, КРПЕЈЦЕ",
        value: "ЛЕСКОВАЦ_КРПЕЈЦЕ_70726_710709",
    },
    {
        label: "ЛЕСКОВАЦ, КУКУЛОВЦЕ",
        value: "ЛЕСКОВАЦ_КУКУЛОВЦЕ_70726_725307",
    },
    {
        label: "ЛЕСКОВАЦ, КУМАРЕВО",
        value: "ЛЕСКОВАЦ_КУМАРЕВО_70726_725315",
    },
    {
        label: "ЛЕСКОВАЦ, КУТЛЕШ",
        value: "ЛЕСКОВАЦ_КУТЛЕШ_70726_725323",
    },
    {
        label: "ЛЕСКОВАЦ, ЛЕСКОВАЦ",
        value: "ЛЕСКОВАЦ_ЛЕСКОВАЦ_70726_725340",
    },
    {
        label: "ЛЕСКОВАЦ, ЛИПОВИЦА",
        value: "ЛЕСКОВАЦ_ЛИПОВИЦА_70726_725358",
    },
    {
        label: "ЛЕСКОВАЦ, ЛИЧИН ДОЛ",
        value: "ЛЕСКОВАЦ_ЛИЧИН ДОЛ_70726_710741",
    },
    {
        label: "ЛЕСКОВАЦ, МАЛА БИЉАНИЦА",
        value: "ЛЕСКОВАЦ_МАЛА БИЉАНИЦА_70726_725366",
    },
    {
        label: "ЛЕСКОВАЦ, МАЛА ГРАБОВНИЦА",
        value: "ЛЕСКОВАЦ_МАЛА ГРАБОВНИЦА_70726_725374",
    },
    {
        label: "ЛЕСКОВАЦ, МАЛА КОПАШНИЦА",
        value: "ЛЕСКОВАЦ_МАЛА КОПАШНИЦА_70726_725382",
    },
    {
        label: "ЛЕСКОВАЦ, МАНОЈЛОВЦЕ",
        value: "ЛЕСКОВАЦ_МАНОЈЛОВЦЕ_70726_725404",
    },
    {
        label: "ЛЕСКОВАЦ, МЕЂА",
        value: "ЛЕСКОВАЦ_МЕЂА_70726_725412",
    },
    {
        label: "ЛЕСКОВАЦ, МЕЛОВО",
        value: "ЛЕСКОВАЦ_МЕЛОВО_70726_723959",
    },
    {
        label: "ЛЕСКОВАЦ, МИЛАНОВО",
        value: "ЛЕСКОВАЦ_МИЛАНОВО_70726_725439",
    },
    {
        label: "ЛЕСКОВАЦ, МИРОШЕВЦЕ",
        value: "ЛЕСКОВАЦ_МИРОШЕВЦЕ_70726_725447",
    },
    {
        label: "ЛЕСКОВАЦ, МРКОВИЦА",
        value: "ЛЕСКОВАЦ_МРКОВИЦА_70726_709948",
    },
    {
        label: "ЛЕСКОВАЦ, МРШТАНЕ",
        value: "ЛЕСКОВАЦ_МРШТАНЕ_70726_725455",
    },
    {
        label: "ЛЕСКОВАЦ, НАВАЛИН",
        value: "ЛЕСКОВАЦ_НАВАЛИН_70726_725463",
    },
    {
        label: "ЛЕСКОВАЦ, НАКРИВАЊ",
        value: "ЛЕСКОВАЦ_НАКРИВАЊ_70726_725471",
    },
    {
        label: "ЛЕСКОВАЦ, НЕСВРТА",
        value: "ЛЕСКОВАЦ_НЕСВРТА_70726_725480",
    },
    {
        label: "ЛЕСКОВАЦ, НОВО СЕЛО",
        value: "ЛЕСКОВАЦ_НОВО СЕЛО_70726_710768",
    },
    {
        label: "ЛЕСКОВАЦ, НОМАНИЦА",
        value: "ЛЕСКОВАЦ_НОМАНИЦА_70726_725498",
    },
    {
        label: "ЛЕСКОВАЦ, ОРАОВИЦА",
        value: "ЛЕСКОВАЦ_ОРАОВИЦА_70726_725510",
    },
    {
        label: "ЛЕСКОВАЦ, ОРАШАЦ",
        value: "ЛЕСКОВАЦ_ОРАШАЦ_70726_725528",
    },
    {
        label: "ЛЕСКОВАЦ, ОРУГЛИЦА",
        value: "ЛЕСКОВАЦ_ОРУГЛИЦА_70726_724041",
    },
    {
        label: "ЛЕСКОВАЦ, ПАДЕЖ",
        value: "ЛЕСКОВАЦ_ПАДЕЖ_70726_725536",
    },
    {
        label: "ЛЕСКОВАЦ, ПАЛИКУЋА",
        value: "ЛЕСКОВАЦ_ПАЛИКУЋА_70726_725544",
    },
    {
        label: "ЛЕСКОВАЦ, ПАЛОЈЦЕ",
        value: "ЛЕСКОВАЦ_ПАЛОЈЦЕ_70726_710814",
    },
    {
        label: "ЛЕСКОВАЦ, ПЕТРОВАЦ",
        value: "ЛЕСКОВАЦ_ПЕТРОВАЦ_70726_725552",
    },
    {
        label: "ЛЕСКОВАЦ, ПЕЧЕЊЕВЦЕ",
        value: "ЛЕСКОВАЦ_ПЕЧЕЊЕВЦЕ_70726_725579",
    },
    {
        label: "ЛЕСКОВАЦ, ПИСКУПОВО",
        value: "ЛЕСКОВАЦ_ПИСКУПОВО_70726_725587",
    },
    {
        label: "ЛЕСКОВАЦ, ПОДРИМЦЕ",
        value: "ЛЕСКОВАЦ_ПОДРИМЦЕ_70726_725595",
    },
    {
        label: "ЛЕСКОВАЦ, ПРЕДЕЈАНЕ (ВАРОШ)",
        value: "ЛЕСКОВАЦ_ПРЕДЕЈАНЕ (ВАРОШ)_70726_709999",
    },
    {
        label: "ЛЕСКОВАЦ, ПРЕДЕЈАНЕ (СЕЛО)",
        value: "ЛЕСКОВАЦ_ПРЕДЕЈАНЕ (СЕЛО)_70726_710008",
    },
    {
        label: "ЛЕСКОВАЦ, ПРЕСЕЧИНА",
        value: "ЛЕСКОВАЦ_ПРЕСЕЧИНА_70726_725609",
    },
    {
        label: "ЛЕСКОВАЦ, ПРИБОЈ",
        value: "ЛЕСКОВАЦ_ПРИБОЈ_70726_725617",
    },
    {
        label: "ЛЕСКОВАЦ, РАВНИ ДЕЛ",
        value: "ЛЕСКОВАЦ_РАВНИ ДЕЛ_70726_724173",
    },
    {
        label: "ЛЕСКОВАЦ, РАДОЊИЦА",
        value: "ЛЕСКОВАЦ_РАДОЊИЦА_70726_725625",
    },
    {
        label: "ЛЕСКОВАЦ, РАЗГОЈНА",
        value: "ЛЕСКОВАЦ_РАЗГОЈНА_70726_725633",
    },
    {
        label: "ЛЕСКОВАЦ, РАЈНО ПОЉЕ",
        value: "ЛЕСКОВАЦ_РАЈНО ПОЉЕ_70726_710890",
    },
    {
        label: "ЛЕСКОВАЦ, РУДАРЕ",
        value: "ЛЕСКОВАЦ_РУДАРЕ_70726_725641",
    },
    {
        label: "ЛЕСКОВАЦ, СВИРЦЕ",
        value: "ЛЕСКОВАЦ_СВИРЦЕ_70726_725650",
    },
    {
        label: "ЛЕСКОВАЦ, СЕЈАНИЦА",
        value: "ЛЕСКОВАЦ_СЕЈАНИЦА_70726_710946",
    },
    {
        label: "ЛЕСКОВАЦ, СЛАВУЈЕВЦЕ",
        value: "ЛЕСКОВАЦ_СЛАВУЈЕВЦЕ_70726_725668",
    },
    {
        label: "ЛЕСКОВАЦ, СЛАТИНА",
        value: "ЛЕСКОВАЦ_СЛАТИНА_70726_725676",
    },
    {
        label: "ЛЕСКОВАЦ, СМРДАН",
        value: "ЛЕСКОВАЦ_СМРДАН_70726_725684",
    },
    {
        label: "ЛЕСКОВАЦ, СТРОЈКОВЦЕ",
        value: "ЛЕСКОВАЦ_СТРОЈКОВЦЕ_70726_725692",
    },
    {
        label: "ЛЕСКОВАЦ, СТУПНИЦА",
        value: "ЛЕСКОВАЦ_СТУПНИЦА_70726_725714",
    },
    {
        label: "ЛЕСКОВАЦ, СУШЕВЉЕ",
        value: "ЛЕСКОВАЦ_СУШЕВЉЕ_70726_710130",
    },
    {
        label: "ЛЕСКОВАЦ, ТОДОРОВЦЕ",
        value: "ЛЕСКОВАЦ_ТОДОРОВЦЕ_70726_725722",
    },
    {
        label: "ЛЕСКОВАЦ, ТУЛОВО",
        value: "ЛЕСКОВАЦ_ТУЛОВО_70726_725749",
    },
    {
        label: "ЛЕСКОВАЦ, ТУПАЛОВЦЕ",
        value: "ЛЕСКОВАЦ_ТУПАЛОВЦЕ_70726_711012",
    },
    {
        label: "ЛЕСКОВАЦ, ТУРЕКОВАЦ",
        value: "ЛЕСКОВАЦ_ТУРЕКОВАЦ_70726_725757",
    },
    {
        label: "ЛЕСКОВАЦ, ЦРВЕНИ БРЕГ",
        value: "ЛЕСКОВАЦ_ЦРВЕНИ БРЕГ_70726_711047",
    },
    {
        label: "ЛЕСКОВАЦ, ЦРКОВНИЦА",
        value: "ЛЕСКОВАЦ_ЦРКОВНИЦА_70726_725773",
    },
    {
        label: "ЛЕСКОВАЦ, ЦРЦАВАЦ",
        value: "ЛЕСКОВАЦ_ЦРЦАВАЦ_70726_725781",
    },
    {
        label: "ЛЕСКОВАЦ, ЧЕКМИН",
        value: "ЛЕСКОВАЦ_ЧЕКМИН_70726_725790",
    },
    {
        label: "ЛЕСКОВАЦ, ЧИФЛУК РАЗГОЈНСКИ",
        value: "ЛЕСКОВАЦ_ЧИФЛУК РАЗГОЈНСКИ_70726_725803",
    },
    {
        label: "ЛЕСКОВАЦ, ЧУКЉЕНИК",
        value: "ЛЕСКОВАЦ_ЧУКЉЕНИК_70726_725811",
    },
    {
        label: "ЛЕСКОВАЦ, ШАИНОВАЦ",
        value: "ЛЕСКОВАЦ_ШАИНОВАЦ_70726_725820",
    },
    {
        label: "ЛЕСКОВАЦ, ШАРЛИНЦЕ",
        value: "ЛЕСКОВАЦ_ШАРЛИНЦЕ_70726_725838",
    },
    {
        label: "ЛЕСКОВАЦ, ШИШИНЦЕ",
        value: "ЛЕСКОВАЦ_ШИШИНЦЕ_70726_725846",
    },
    {
        label: "ЛОЗНИЦА, БАЊА КОВИЉАЧА",
        value: "ЛОЗНИЦА_БАЊА КОВИЉАЧА_70734_725854",
    },
    {
        label: "ЛОЗНИЦА, БРАДИЋ",
        value: "ЛОЗНИЦА_БРАДИЋ_70734_725862",
    },
    {
        label: "ЛОЗНИЦА, БРЊАЦ",
        value: "ЛОЗНИЦА_БРЊАЦ_70734_725897",
    },
    {
        label: "ЛОЗНИЦА, ВЕЛИКО СЕЛО",
        value: "ЛОЗНИЦА_ВЕЛИКО СЕЛО_70734_725919",
    },
    {
        label: "ЛОЗНИЦА, ВОЋЊАК",
        value: "ЛОЗНИЦА_ВОЋЊАК_70734_725927",
    },
    {
        label: "ЛОЗНИЦА, ГОРЊА БАДАЊА",
        value: "ЛОЗНИЦА_ГОРЊА БАДАЊА_70734_725935",
    },
    {
        label: "ЛОЗНИЦА, ГОРЊА БОРИНА",
        value: "ЛОЗНИЦА_ГОРЊА БОРИНА_70734_725943",
    },
    {
        label: "ЛОЗНИЦА, ГОРЊА СИПУЉА",
        value: "ЛОЗНИЦА_ГОРЊА СИПУЉА_70734_725951",
    },
    {
        label: "ЛОЗНИЦА, ГОРЊЕ НЕДЕЉИЦЕ",
        value: "ЛОЗНИЦА_ГОРЊЕ НЕДЕЉИЦЕ_70734_725960",
    },
    {
        label: "ЛОЗНИЦА, ГОРЊИ ДОБРИЋ",
        value: "ЛОЗНИЦА_ГОРЊИ ДОБРИЋ_70734_725978",
    },
    {
        label: "ЛОЗНИЦА, ГРНЧАРА",
        value: "ЛОЗНИЦА_ГРНЧАРА_70734_725986",
    },
    {
        label: "ЛОЗНИЦА, ДОЊА БАДАЊА",
        value: "ЛОЗНИЦА_ДОЊА БАДАЊА_70734_726001",
    },
    {
        label: "ЛОЗНИЦА, ДОЊА СИПУЉА",
        value: "ЛОЗНИЦА_ДОЊА СИПУЉА_70734_726028",
    },
    {
        label: "ЛОЗНИЦА, ДОЊЕ НЕДЕЉИЦЕ",
        value: "ЛОЗНИЦА_ДОЊЕ НЕДЕЉИЦЕ_70734_726036",
    },
    {
        label: "ЛОЗНИЦА, ДОЊИ ДОБРИЋ",
        value: "ЛОЗНИЦА_ДОЊИ ДОБРИЋ_70734_726044",
    },
    {
        label: "ЛОЗНИЦА, ДРАГИНАЦ",
        value: "ЛОЗНИЦА_ДРАГИНАЦ_70734_726052",
    },
    {
        label: "ЛОЗНИЦА, ЗАЈАЧА",
        value: "ЛОЗНИЦА_ЗАЈАЧА_70734_726079",
    },
    {
        label: "ЛОЗНИЦА, ЈАДРАНСКА ЛЕШНИЦА",
        value: "ЛОЗНИЦА_ЈАДРАНСКА ЛЕШНИЦА_70734_726087",
    },
    {
        label: "ЛОЗНИЦА, ЈАРЕБИЦЕ",
        value: "ЛОЗНИЦА_ЈАРЕБИЦЕ_70734_726095",
    },
    {
        label: "ЛОЗНИЦА, ЈЕЛАВ",
        value: "ЛОЗНИЦА_ЈЕЛАВ_70734_726109",
    },
    {
        label: "ЛОЗНИЦА, ЈОШЕВА",
        value: "ЛОЗНИЦА_ЈОШЕВА_70734_726117",
    },
    {
        label: "ЛОЗНИЦА, ЈУГОВИЋИ",
        value: "ЛОЗНИЦА_ЈУГОВИЋИ_70734_726125",
    },
    {
        label: "ЛОЗНИЦА, КАМЕНИЦА",
        value: "ЛОЗНИЦА_КАМЕНИЦА_70734_726133",
    },
    {
        label: "ЛОЗНИЦА, КОЗЈАК",
        value: "ЛОЗНИЦА_КОЗЈАК_70734_726150",
    },
    {
        label: "ЛОЗНИЦА, КОРЕНИТА",
        value: "ЛОЗНИЦА_КОРЕНИТА_70734_726168",
    },
    {
        label: "ЛОЗНИЦА, ЛЕШНИЦА",
        value: "ЛОЗНИЦА_ЛЕШНИЦА_70734_745197",
    },
    {
        label: "ЛОЗНИЦА, ЛИПНИЦА",
        value: "ЛОЗНИЦА_ЛИПНИЦА_70734_726192",
    },
    {
        label: "ЛОЗНИЦА, ЛОЗНИЦА",
        value: "ЛОЗНИЦА_ЛОЗНИЦА_70734_745189",
    },
    {
        label: "ЛОЗНИЦА, МИЛИНА",
        value: "ЛОЗНИЦА_МИЛИНА_70734_726222",
    },
    {
        label: "ЛОЗНИЦА, НОВО СЕЛО",
        value: "ЛОЗНИЦА_НОВО СЕЛО_70734_726249",
    },
    {
        label: "ЛОЗНИЦА, ПАСКОВАЦ",
        value: "ЛОЗНИЦА_ПАСКОВАЦ_70734_726257",
    },
    {
        label: "ЛОЗНИЦА, ПОМИЈАЧА",
        value: "ЛОЗНИЦА_ПОМИЈАЧА_70734_726265",
    },
    {
        label: "ЛОЗНИЦА, РИБАРИЦЕ",
        value: "ЛОЗНИЦА_РИБАРИЦЕ_70734_726273",
    },
    {
        label: "ЛОЗНИЦА, РУЊАНИ",
        value: "ЛОЗНИЦА_РУЊАНИ_70734_726281",
    },
    {
        label: "ЛОЗНИЦА, СИМИНО БРДО",
        value: "ЛОЗНИЦА_СИМИНО БРДО_70734_726290",
    },
    {
        label: "ЛОЗНИЦА, СЛАТИНА",
        value: "ЛОЗНИЦА_СЛАТИНА_70734_726303",
    },
    {
        label: "ЛОЗНИЦА, СТРАЖА",
        value: "ЛОЗНИЦА_СТРАЖА_70734_726311",
    },
    {
        label: "ЛОЗНИЦА, СТУПНИЦА",
        value: "ЛОЗНИЦА_СТУПНИЦА_70734_726320",
    },
    {
        label: "ЛОЗНИЦА, ТЕКЕРИШ",
        value: "ЛОЗНИЦА_ТЕКЕРИШ_70734_726338",
    },
    {
        label: "ЛОЗНИЦА, ТРБОСИЉЕ",
        value: "ЛОЗНИЦА_ТРБОСИЉЕ_70734_726346",
    },
    {
        label: "ЛОЗНИЦА, ТРБУШНИЦА",
        value: "ЛОЗНИЦА_ТРБУШНИЦА_70734_726354",
    },
    {
        label: "ЛОЗНИЦА, ТРШИЋ",
        value: "ЛОЗНИЦА_ТРШИЋ_70734_726362",
    },
    {
        label: "ЛОЗНИЦА, ФИЛИПОВИЋИ",
        value: "ЛОЗНИЦА_ФИЛИПОВИЋИ_70734_726389",
    },
    {
        label: "ЛОЗНИЦА, ЦИКОТЕ",
        value: "ЛОЗНИЦА_ЦИКОТЕ_70734_726397",
    },
    {
        label: "ЛОЗНИЦА, ЧОКЕШИНА",
        value: "ЛОЗНИЦА_ЧОКЕШИНА_70734_726419",
    },
    {
        label: "ЛОЗНИЦА, ШОР",
        value: "ЛОЗНИЦА_ШОР_70734_726427",
    },
    {
        label: "ЛОЗНИЦА, ШУРИЦЕ",
        value: "ЛОЗНИЦА_ШУРИЦЕ_70734_726435",
    },
    {
        label: "ЛУЧАНИ, БЕЛИ КАМЕН",
        value: "ЛУЧАНИ_БЕЛИ КАМЕН_70742_726443",
    },
    {
        label: "ЛУЧАНИ, ВИЧА",
        value: "ЛУЧАНИ_ВИЧА_70742_726478",
    },
    {
        label: "ЛУЧАНИ, ВЛАСТЕЉИЦЕ",
        value: "ЛУЧАНИ_ВЛАСТЕЉИЦЕ_70742_726486",
    },
    {
        label: "ЛУЧАНИ, ВУЧКОВИЦА",
        value: "ЛУЧАНИ_ВУЧКОВИЦА_70742_726494",
    },
    {
        label: "ЛУЧАНИ, ГОРАЧИЋИ",
        value: "ЛУЧАНИ_ГОРАЧИЋИ_70742_726508",
    },
    {
        label: "ЛУЧАНИ, ГОРЊА КРАВАРИЦА",
        value: "ЛУЧАНИ_ГОРЊА КРАВАРИЦА_70742_726516",
    },
    {
        label: "ЛУЧАНИ, ГОРЊИ ДУБАЦ",
        value: "ЛУЧАНИ_ГОРЊИ ДУБАЦ_70742_726524",
    },
    {
        label: "ЛУЧАНИ, ГРАБ",
        value: "ЛУЧАНИ_ГРАБ_70742_726532",
    },
    {
        label: "ЛУЧАНИ, ГУБЕРЕВЦИ",
        value: "ЛУЧАНИ_ГУБЕРЕВЦИ_70742_726559",
    },
    {
        label: "ЛУЧАНИ, ГУЧА",
        value: "ЛУЧАНИ_ГУЧА_70742_726567",
    },
    {
        label: "ЛУЧАНИ, ДЉИН",
        value: "ЛУЧАНИ_ДЉИН_70742_726575",
    },
    {
        label: "ЛУЧАНИ, ДОЊА КРАВАРИЦА",
        value: "ЛУЧАНИ_ДОЊА КРАВАРИЦА_70742_726583",
    },
    {
        label: "ЛУЧАНИ, ДОЊИ ДУБАЦ",
        value: "ЛУЧАНИ_ДОЊИ ДУБАЦ_70742_726591",
    },
    {
        label: "ЛУЧАНИ, ДУЧАЛОВИЋИ",
        value: "ЛУЧАНИ_ДУЧАЛОВИЋИ_70742_726613",
    },
    {
        label: "ЛУЧАНИ, ЂЕРАЂ",
        value: "ЛУЧАНИ_ЂЕРАЂ_70742_726621",
    },
    {
        label: "ЛУЧАНИ, ЖИВИЦА",
        value: "ЛУЧАНИ_ЖИВИЦА_70742_726630",
    },
    {
        label: "ЛУЧАНИ, ЗЕОКЕ",
        value: "ЛУЧАНИ_ЗЕОКЕ_70742_726648",
    },
    {
        label: "ЛУЧАНИ, КАОНА",
        value: "ЛУЧАНИ_КАОНА_70742_726656",
    },
    {
        label: "ЛУЧАНИ, КОТРАЖА",
        value: "ЛУЧАНИ_КОТРАЖА_70742_726664",
    },
    {
        label: "ЛУЧАНИ, КРИВАЧА",
        value: "ЛУЧАНИ_КРИВАЧА_70742_726672",
    },
    {
        label: "ЛУЧАНИ, КРСТАЦ",
        value: "ЛУЧАНИ_КРСТАЦ_70742_726699",
    },
    {
        label: "ЛУЧАНИ, ЛИС",
        value: "ЛУЧАНИ_ЛИС_70742_726702",
    },
    {
        label: "ЛУЧАНИ, ЛИСИЦЕ",
        value: "ЛУЧАНИ_ЛИСИЦЕ_70742_726737",
    },
    {
        label: "ЛУЧАНИ, ЛУЧАНИ",
        value: "ЛУЧАНИ_ЛУЧАНИ_70742_726761",
    },
    {
        label: "ЛУЧАНИ, МАРКОВИЦА",
        value: "ЛУЧАНИ_МАРКОВИЦА_70742_726770",
    },
    {
        label: "ЛУЧАНИ, МИЛАТОВИЋИ",
        value: "ЛУЧАНИ_МИЛАТОВИЋИ_70742_726788",
    },
    {
        label: "ЛУЧАНИ, НЕГРИШОРИ",
        value: "ЛУЧАНИ_НЕГРИШОРИ_70742_726800",
    },
    {
        label: "ЛУЧАНИ, ПУХОВО",
        value: "ЛУЧАНИ_ПУХОВО_70742_726842",
    },
    {
        label: "ЛУЧАНИ, ПШАНИК",
        value: "ЛУЧАНИ_ПШАНИК_70742_726869",
    },
    {
        label: "ЛУЧАНИ, РОГАЧА",
        value: "ЛУЧАНИ_РОГАЧА_70742_726877",
    },
    {
        label: "ЛУЧАНИ, РТАРИ",
        value: "ЛУЧАНИ_РТАРИ_70742_726885",
    },
    {
        label: "ЛУЧАНИ, РТИ",
        value: "ЛУЧАНИ_РТИ_70742_726893",
    },
    {
        label: "ЛУЧАНИ, ТИЈАЊЕ",
        value: "ЛУЧАНИ_ТИЈАЊЕ_70742_726907",
    },
    {
        label: "ЛУЧАНИ, ТУРИЦА",
        value: "ЛУЧАНИ_ТУРИЦА_70742_726931",
    },
    {
        label: "ЉИГ, БА",
        value: "ЉИГ_БА_70769_712833",
    },
    {
        label: "ЉИГ, БАБАЈИЋ",
        value: "ЉИГ_БАБАЈИЋ_70769_727806",
    },
    {
        label: "ЉИГ, БЕЛАНОВИЦА",
        value: "ЉИГ_БЕЛАНОВИЦА_70769_726958",
    },
    {
        label: "ЉИГ, БОШЊАНОВИЋ",
        value: "ЉИГ_БОШЊАНОВИЋ_70769_727822",
    },
    {
        label: "ЉИГ, БРАНЧИЋ",
        value: "ЉИГ_БРАНЧИЋ_70769_726982",
    },
    {
        label: "ЉИГ, ВЕЛИШЕВАЦ",
        value: "ЉИГ_ВЕЛИШЕВАЦ_70769_727865",
    },
    {
        label: "ЉИГ, ГУКОШ",
        value: "ЉИГ_ГУКОШ_70769_727024",
    },
    {
        label: "ЉИГ, ДИЋИ",
        value: "ЉИГ_ДИЋИ_70769_727067",
    },
    {
        label: "ЉИГ, ДОЊИ БАЊАНИ",
        value: "ЉИГ_ДОЊИ БАЊАНИ_70769_727075",
    },
    {
        label: "ЉИГ, ЖИВКОВЦИ",
        value: "ЉИГ_ЖИВКОВЦИ_70769_727091",
    },
    {
        label: "ЉИГ, ИВАНОВЦИ",
        value: "ЉИГ_ИВАНОВЦИ_70769_727113",
    },
    {
        label: "ЉИГ, ЈАЈЧИЋ",
        value: "ЉИГ_ЈАЈЧИЋ_70769_727997",
    },
    {
        label: "ЉИГ, КАДИНА ЛУКА",
        value: "ЉИГ_КАДИНА ЛУКА_70769_728004",
    },
    {
        label: "ЉИГ, КАЛАЊЕВЦИ",
        value: "ЉИГ_КАЛАЊЕВЦИ_70769_727130",
    },
    {
        label: "ЉИГ, КОЗЕЉ",
        value: "ЉИГ_КОЗЕЉ_70769_727148",
    },
    {
        label: "ЉИГ, ЛАЛИНЦИ",
        value: "ЉИГ_ЛАЛИНЦИ_70769_727164",
    },
    {
        label: "ЉИГ, ЛАТКОВИЋ",
        value: "ЉИГ_ЛАТКОВИЋ_70769_728063",
    },
    {
        label: "ЉИГ, ЛИПЉЕ",
        value: "ЉИГ_ЛИПЉЕ_70769_727172",
    },
    {
        label: "ЉИГ, ЉИГ",
        value: "ЉИГ_ЉИГ_70769_727199",
    },
    {
        label: "ЉИГ, МОРАВЦИ",
        value: "ЉИГ_МОРАВЦИ_70769_727229",
    },
    {
        label: "ЉИГ, ПАЛЕЖНИЦА",
        value: "ЉИГ_ПАЛЕЖНИЦА_70769_728152",
    },
    {
        label: "ЉИГ, ПОЉАНИЦЕ",
        value: "ЉИГ_ПОЉАНИЦЕ_70769_727245",
    },
    {
        label: "ЉИГ, СЛАВКОВИЦА",
        value: "ЉИГ_СЛАВКОВИЦА_70769_728306",
    },
    {
        label: "ЉИГ, ЦВЕТАНОВАЦ",
        value: "ЉИГ_ЦВЕТАНОВАЦ_70769_728373",
    },
    {
        label: "ЉИГ, ШТАВИЦА",
        value: "ЉИГ_ШТАВИЦА_70769_727326",
    },
    {
        label: "ЉИГ, ШУТЦИ",
        value: "ЉИГ_ШУТЦИ_70769_727334",
    },
    {
        label: "ЉУБОВИЈА, ГОРЊА БУКОВИЦА",
        value: "ЉУБОВИЈА_ГОРЊА БУКОВИЦА_70777_727369",
    },
    {
        label: "ЉУБОВИЈА, ГОРЊА ЉУБОВИЂА",
        value: "ЉУБОВИЈА_ГОРЊА ЉУБОВИЂА_70777_727377",
    },
    {
        label: "ЉУБОВИЈА, ГОРЊА ОРОВИЦА",
        value: "ЉУБОВИЈА_ГОРЊА ОРОВИЦА_70777_727385",
    },
    {
        label: "ЉУБОВИЈА, ГОРЊЕ КОШЉЕ",
        value: "ЉУБОВИЈА_ГОРЊЕ КОШЉЕ_70777_727393",
    },
    {
        label: "ЉУБОВИЈА, ГРАЧАНИЦА",
        value: "ЉУБОВИЈА_ГРАЧАНИЦА_70777_727407",
    },
    {
        label: "ЉУБОВИЈА, ГРЧИЋ",
        value: "ЉУБОВИЈА_ГРЧИЋ_70777_727415",
    },
    {
        label: "ЉУБОВИЈА, ДОЊА БУКОВИЦА",
        value: "ЉУБОВИЈА_ДОЊА БУКОВИЦА_70777_727431",
    },
    {
        label: "ЉУБОВИЈА, ДОЊА ЉУБОВИЂА",
        value: "ЉУБОВИЈА_ДОЊА ЉУБОВИЂА_70777_727440",
    },
    {
        label: "ЉУБОВИЈА, ДОЊА ОРОВИЦА",
        value: "ЉУБОВИЈА_ДОЊА ОРОВИЦА_70777_727458",
    },
    {
        label: "ЉУБОВИЈА, ДОЊЕ КОШЉЕ I",
        value: "ЉУБОВИЈА_ДОЊЕ КОШЉЕ I_70777_727466",
    },
    {
        label: "ЉУБОВИЈА, ДОЊЕ КОШЉЕ II",
        value: "ЉУБОВИЈА_ДОЊЕ КОШЉЕ II_70777_727474",
    },
    {
        label: "ЉУБОВИЈА, ДРЛАЧЕ",
        value: "ЉУБОВИЈА_ДРЛАЧЕ_70777_727504",
    },
    {
        label: "ЉУБОВИЈА, ЛЕОВИЋ",
        value: "ЉУБОВИЈА_ЛЕОВИЋ_70777_727512",
    },
    {
        label: "ЉУБОВИЈА, ЛОЊИН",
        value: "ЉУБОВИЈА_ЛОЊИН_70777_727539",
    },
    {
        label: "ЉУБОВИЈА, ЉУБОВИЈА",
        value: "ЉУБОВИЈА_ЉУБОВИЈА_70777_727547",
    },
    {
        label: "ЉУБОВИЈА, ПОСТЕЊЕ",
        value: "ЉУБОВИЈА_ПОСТЕЊЕ_70777_727563",
    },
    {
        label: "ЉУБОВИЈА, РУЈЕВАЦ",
        value: "ЉУБОВИЈА_РУЈЕВАЦ_70777_727571",
    },
    {
        label: "ЉУБОВИЈА, САВКОВИЋ",
        value: "ЉУБОВИЈА_САВКОВИЋ_70777_727580",
    },
    {
        label: "ЉУБОВИЈА, СЕЛАНАЦ",
        value: "ЉУБОВИЈА_СЕЛАНАЦ_70777_727598",
    },
    {
        label: "ЉУБОВИЈА, СОКОЛАЦ",
        value: "ЉУБОВИЈА_СОКОЛАЦ_70777_727601",
    },
    {
        label: "ЉУБОВИЈА, ТОРНИК",
        value: "ЉУБОВИЈА_ТОРНИК_70777_727610",
    },
    {
        label: "ЉУБОВИЈА, УЗОВНИЦА",
        value: "ЉУБОВИЈА_УЗОВНИЦА_70777_727628",
    },
    {
        label: "ЉУБОВИЈА, ЦРНЧА",
        value: "ЉУБОВИЈА_ЦРНЧА_70777_727644",
    },
    {
        label: "ЉУБОВИЈА, ЧИТЛУК",
        value: "ЉУБОВИЈА_ЧИТЛУК_70777_727652",
    },
    {
        label: "МАЈДАНПЕК, БОЉЕТИН",
        value: "МАЈДАНПЕК_БОЉЕТИН_70785_727679",
    },
    {
        label: "МАЈДАНПЕК, ВЛАОЛЕ",
        value: "МАЈДАНПЕК_ВЛАОЛЕ_70785_714364",
    },
    {
        label: "МАЈДАНПЕК, ГОЛУБИЊЕ",
        value: "МАЈДАНПЕК_ГОЛУБИЊЕ_70785_727687",
    },
    {
        label: "МАЈДАНПЕК, ДЕБЕЛИ ЛУГ",
        value: "МАЈДАНПЕК_ДЕБЕЛИ ЛУГ_70785_727709",
    },
    {
        label: "МАЈДАНПЕК, ДОЊИ МИЛАНОВАЦ",
        value: "МАЈДАНПЕК_ДОЊИ МИЛАНОВАЦ_70785_727717",
    },
    {
        label: "МАЈДАНПЕК, ЈАСИКОВО",
        value: "МАЈДАНПЕК_ЈАСИКОВО_70785_714437",
    },
    {
        label: "МАЈДАНПЕК, КЛОКОЧЕВАЦ",
        value: "МАЈДАНПЕК_КЛОКОЧЕВАЦ_70785_727725",
    },
    {
        label: "МАЈДАНПЕК, ЛЕСКОВО",
        value: "МАЈДАНПЕК_ЛЕСКОВО_70785_714496",
    },
    {
        label: "МАЈДАНПЕК, МАЈДАНПЕК",
        value: "МАЈДАНПЕК_МАЈДАНПЕК_70785_727733",
    },
    {
        label: "МАЈДАНПЕК, МИРОЧ",
        value: "МАЈДАНПЕК_МИРОЧ_70785_727741",
    },
    {
        label: "МАЈДАНПЕК, МОСНА",
        value: "МАЈДАНПЕК_МОСНА_70785_727750",
    },
    {
        label: "МАЈДАНПЕК, РУДНА ГЛАВА",
        value: "МАЈДАНПЕК_РУДНА ГЛАВА_70785_727768",
    },
    {
        label: "МАЈДАНПЕК, ТОПОЛНИЦА",
        value: "МАЈДАНПЕК_ТОПОЛНИЦА_70785_727784",
    },
    {
        label: "МАЈДАНПЕК, ЦРНАЈКА",
        value: "МАЈДАНПЕК_ЦРНАЈКА_70785_727792",
    },
    {
        label: "МАЛИ ЗВОРНИК, БРАСИНА",
        value: "МАЛИ ЗВОРНИК_БРАСИНА_70793_725889",
    },
    {
        label: "МАЛИ ЗВОРНИК, БУДИШИЋ",
        value: "МАЛИ ЗВОРНИК_БУДИШИЋ_70793_720186",
    },
    {
        label: "МАЛИ ЗВОРНИК, ВЕЛИКА РЕКА",
        value: "МАЛИ ЗВОРНИК_ВЕЛИКА РЕКА_70793_727342",
    },
    {
        label: "МАЛИ ЗВОРНИК, ДОЊА БОРИНА",
        value: "МАЛИ ЗВОРНИК_ДОЊА БОРИНА_70793_726010",
    },
    {
        label: "МАЛИ ЗВОРНИК, ДОЊА ТРЕШЊИЦА",
        value: "МАЛИ ЗВОРНИК_ДОЊА ТРЕШЊИЦА_70793_720208",
    },
    {
        label: "МАЛИ ЗВОРНИК, МАЛИ ЗВОРНИК",
        value: "МАЛИ ЗВОРНИК_МАЛИ ЗВОРНИК_70793_720321",
    },
    {
        label: "МАЛИ ЗВОРНИК, РАДАЉ",
        value: "МАЛИ ЗВОРНИК_РАДАЉ_70793_720364",
    },
    {
        label: "МАЛИ ЗВОРНИК, САКАР",
        value: "МАЛИ ЗВОРНИК_САКАР_70793_720372",
    },
    {
        label: "МАЛИ ЗВОРНИК, ЦУЛИНЕ",
        value: "МАЛИ ЗВОРНИК_ЦУЛИНЕ_70793_720453",
    },
    {
        label: "МАЛИ ЗВОРНИК, ЧИТЛУК",
        value: "МАЛИ ЗВОРНИК_ЧИТЛУК_70793_720461",
    },
    {
        label: "МАЛИ ИЂОШ, ЛОВЋЕНАЦ",
        value: "МАЛИ ИЂОШ_ЛОВЋЕНАЦ_80241_801500",
    },
    {
        label: "МАЛИ ИЂОШ, МАЛИ ИЂОШ",
        value: "МАЛИ ИЂОШ_МАЛИ ИЂОШ_80241_801518",
    },
    {
        label: "МАЛИ ИЂОШ, ФЕКЕТИЋ",
        value: "МАЛИ ИЂОШ_ФЕКЕТИЋ_80241_801461",
    },
    {
        label: "МАЛО ЦРНИЋЕ, АЉУДОВО",
        value: "МАЛО ЦРНИЋЕ_АЉУДОВО_70807_733881",
    },
    {
        label: "МАЛО ЦРНИЋЕ, БАТУША",
        value: "МАЛО ЦРНИЋЕ_БАТУША_70807_733911",
    },
    {
        label: "МАЛО ЦРНИЋЕ, БОЖЕВАЦ",
        value: "МАЛО ЦРНИЋЕ_БОЖЕВАЦ_70807_733938",
    },
    {
        label: "МАЛО ЦРНИЋЕ, ВЕЛИКО СЕЛО",
        value: "МАЛО ЦРНИЋЕ_ВЕЛИКО СЕЛО_70807_733997",
    },
    {
        label: "МАЛО ЦРНИЋЕ, ВЕЛИКО ЦРНИЋЕ",
        value: "МАЛО ЦРНИЋЕ_ВЕЛИКО ЦРНИЋЕ_70807_734004",
    },
    {
        label: "МАЛО ЦРНИЋЕ, ВРБНИЦА",
        value: "МАЛО ЦРНИЋЕ_ВРБНИЦА_70807_714186",
    },
    {
        label: "МАЛО ЦРНИЋЕ, ЗАБРЕГА",
        value: "МАЛО ЦРНИЋЕ_ЗАБРЕГА_70807_734063",
    },
    {
        label: "МАЛО ЦРНИЋЕ, КАЛИШТЕ",
        value: "МАЛО ЦРНИЋЕ_КАЛИШТЕ_70807_734071",
    },
    {
        label: "МАЛО ЦРНИЋЕ, КОБИЉЕ",
        value: "МАЛО ЦРНИЋЕ_КОБИЉЕ_70807_732893",
    },
    {
        label: "МАЛО ЦРНИЋЕ, КРАВЉИ ДО",
        value: "МАЛО ЦРНИЋЕ_КРАВЉИ ДО_70807_734101",
    },
    {
        label: "МАЛО ЦРНИЋЕ, КУЛА",
        value: "МАЛО ЦРНИЋЕ_КУЛА_70807_734110",
    },
    {
        label: "МАЛО ЦРНИЋЕ, МАЛО ГРАДИШТЕ",
        value: "МАЛО ЦРНИЋЕ_МАЛО ГРАДИШТЕ_70807_708933",
    },
    {
        label: "МАЛО ЦРНИЋЕ, МАЛО ЦРНИЋЕ",
        value: "МАЛО ЦРНИЋЕ_МАЛО ЦРНИЋЕ_70807_734144",
    },
    {
        label: "МАЛО ЦРНИЋЕ, САЛАКОВАЦ",
        value: "МАЛО ЦРНИЋЕ_САЛАКОВАЦ_70807_734233",
    },
    {
        label: "МАЛО ЦРНИЋЕ, СМОЉИНАЦ",
        value: "МАЛО ЦРНИЋЕ_СМОЉИНАЦ_70807_734241",
    },
    {
        label: "МАЛО ЦРНИЋЕ, ТОПОНИЦА",
        value: "МАЛО ЦРНИЋЕ_ТОПОНИЦА_70807_734250",
    },
    {
        label: "МАЛО ЦРНИЋЕ, ЦРЉЕНАЦ",
        value: "МАЛО ЦРНИЋЕ_ЦРЉЕНАЦ_70807_733083",
    },
    {
        label: "МАЛО ЦРНИЋЕ, ШАПИНЕ",
        value: "МАЛО ЦРНИЋЕ_ШАПИНЕ_70807_734284",
    },
    {
        label: "МАЛО ЦРНИЋЕ, ШЉИВОВАЦ",
        value: "МАЛО ЦРНИЋЕ_ШЉИВОВАЦ_70807_734292",
    },
    {
        label: "МЕДВЕЂА, БОГУНОВАЦ",
        value: "МЕДВЕЂА_БОГУНОВАЦ_70815_723347",
    },
    {
        label: "МЕДВЕЂА, БОРОВАЦ",
        value: "МЕДВЕЂА_БОРОВАЦ_70815_723371",
    },
    {
        label: "МЕДВЕЂА, БУЧУМЕТ",
        value: "МЕДВЕЂА_БУЧУМЕТ_70815_723410",
    },
    {
        label: "МЕДВЕЂА, ВЕЛИКА БРАИНА",
        value: "МЕДВЕЂА_ВЕЛИКА БРАИНА_70815_723428",
    },
    {
        label: "МЕДВЕЂА, ВРАПЦЕ",
        value: "МЕДВЕЂА_ВРАПЦЕ_70815_723444",
    },
    {
        label: "МЕДВЕЂА, ГАЗДАРЕ",
        value: "МЕДВЕЂА_ГАЗДАРЕ_70815_723487",
    },
    {
        label: "МЕДВЕЂА, ГАЈТАН",
        value: "МЕДВЕЂА_ГАЈТАН_70815_723495",
    },
    {
        label: "МЕДВЕЂА, ГОРЊА ЛАПАШТИЦА",
        value: "МЕДВЕЂА_ГОРЊА ЛАПАШТИЦА_70815_723525",
    },
    {
        label: "МЕДВЕЂА, ГРБАВЦЕ",
        value: "МЕДВЕЂА_ГРБАВЦЕ_70815_723568",
    },
    {
        label: "МЕДВЕЂА, ГУБАВЦЕ",
        value: "МЕДВЕЂА_ГУБАВЦЕ_70815_723584",
    },
    {
        label: "МЕДВЕЂА, ГУРГУТОВО",
        value: "МЕДВЕЂА_ГУРГУТОВО_70815_723592",
    },
    {
        label: "МЕДВЕЂА, ДОЊА ЛАПАШТИЦА",
        value: "МЕДВЕЂА_ДОЊА ЛАПАШТИЦА_70815_723606",
    },
    {
        label: "МЕДВЕЂА, ДРЕНЦЕ",
        value: "МЕДВЕЂА_ДРЕНЦЕ_70815_723665",
    },
    {
        label: "МЕДВЕЂА, ЂУЛЕКАРЕ",
        value: "МЕДВЕЂА_ЂУЛЕКАРЕ_70815_723681",
    },
    {
        label: "МЕДВЕЂА, КАПИТ",
        value: "МЕДВЕЂА_КАПИТ_70815_723746",
    },
    {
        label: "МЕДВЕЂА, ЛЕЦЕ",
        value: "МЕДВЕЂА_ЛЕЦЕ_70815_723827",
    },
    {
        label: "МЕДВЕЂА, МАЛА БРАИНА",
        value: "МЕДВЕЂА_МАЛА БРАИНА_70815_723886",
    },
    {
        label: "МЕДВЕЂА, МАРОВАЦ",
        value: "МЕДВЕЂА_МАРОВАЦ_70815_723908",
    },
    {
        label: "МЕДВЕЂА, МАЋЕДОНЦЕ",
        value: "МЕДВЕЂА_МАЋЕДОНЦЕ_70815_723916",
    },
    {
        label: "МЕДВЕЂА, МЕДВЕЂА",
        value: "МЕДВЕЂА_МЕДВЕЂА_70815_723924",
    },
    {
        label: "МЕДВЕЂА, МЕДЕВЦЕ",
        value: "МЕДВЕЂА_МЕДЕВЦЕ_70815_723932",
    },
    {
        label: "МЕДВЕЂА, МРКОЊЕ",
        value: "МЕДВЕЂА_МРКОЊЕ_70815_723983",
    },
    {
        label: "МЕДВЕЂА, НЕГОСАВЉЕ",
        value: "МЕДВЕЂА_НЕГОСАВЉЕ_70815_723991",
    },
    {
        label: "МЕДВЕЂА, ПЕТРИЉЕ",
        value: "МЕДВЕЂА_ПЕТРИЉЕ_70815_724068",
    },
    {
        label: "МЕДВЕЂА, ПОРОШТИЦА",
        value: "МЕДВЕЂА_ПОРОШТИЦА_70815_724106",
    },
    {
        label: "МЕДВЕЂА, ПУСТО ШИЛОВО",
        value: "МЕДВЕЂА_ПУСТО ШИЛОВО_70815_724157",
    },
    {
        label: "МЕДВЕЂА, РАВНА БАЊА",
        value: "МЕДВЕЂА_РАВНА БАЊА_70815_724165",
    },
    {
        label: "МЕДВЕЂА, РЕТКОЦЕР",
        value: "МЕДВЕЂА_РЕТКОЦЕР_70815_724211",
    },
    {
        label: "МЕДВЕЂА, РУЈКОВАЦ",
        value: "МЕДВЕЂА_РУЈКОВАЦ_70815_724238",
    },
    {
        label: "МЕДВЕЂА, СВИРЦЕ",
        value: "МЕДВЕЂА_СВИРЦЕ_70815_724262",
    },
    {
        label: "МЕДВЕЂА, СИЈАРИНА",
        value: "МЕДВЕЂА_СИЈАРИНА_70815_724297",
    },
    {
        label: "МЕДВЕЂА, СИЈАРИНСКА БАЊА",
        value: "МЕДВЕЂА_СИЈАРИНСКА БАЊА_70815_724319",
    },
    {
        label: "МЕДВЕЂА, СПОНЦЕ",
        value: "МЕДВЕЂА_СПОНЦЕ_70815_724343",
    },
    {
        label: "МЕДВЕЂА, СТАРА БАЊА",
        value: "МЕДВЕЂА_СТАРА БАЊА_70815_724351",
    },
    {
        label: "МЕДВЕЂА, СТУБЛА",
        value: "МЕДВЕЂА_СТУБЛА_70815_724360",
    },
    {
        label: "МЕДВЕЂА, ТУЛАРЕ",
        value: "МЕДВЕЂА_ТУЛАРЕ_70815_724386",
    },
    {
        label: "МЕДВЕЂА, ТУПАЛЕ",
        value: "МЕДВЕЂА_ТУПАЛЕ_70815_724394",
    },
    {
        label: "МЕДВЕЂА, ЦРНИ ВРХ",
        value: "МЕДВЕЂА_ЦРНИ ВРХ_70815_724459",
    },
    {
        label: "МЕДВЕЂА, ЧОКОТИН",
        value: "МЕДВЕЂА_ЧОКОТИН_70815_724467",
    },
    {
        label: "МЕДИЈАНА, БРЗИ БРОД",
        value: "МЕДИЈАНА_БРЗИ БРОД_71331_729094",
    },
    {
        label: 'МЕДИЈАНА, НИШ "БУБАЊ"',
        value: 'МЕДИЈАНА_НИШ "БУБАЊ"_71331_729795',
    },
    {
        label: 'МЕДИЈАНА, НИШ "ЋЕЛЕ КУЛА"',
        value: 'МЕДИЈАНА_НИШ "ЋЕЛЕ КУЛА"_71331_729809',
    },
    {
        label: "МЕРОШИНА, АЗБРЕСНИЦА",
        value: "МЕРОШИНА_АЗБРЕСНИЦА_70823_714593",
    },
    {
        label: "МЕРОШИНА, АЛЕКСАНДРОВО",
        value: "МЕРОШИНА_АЛЕКСАНДРОВО_70823_714607",
    },
    {
        label: "МЕРОШИНА, АРБАНАСЦЕ",
        value: "МЕРОШИНА_АРБАНАСЦЕ_70823_714615",
    },
    {
        label: "МЕРОШИНА, БАЛАЈНАЦ",
        value: "МЕРОШИНА_БАЛАЈНАЦ_70823_714666",
    },
    {
        label: "МЕРОШИНА, БАЛИЧЕВАЦ",
        value: "МЕРОШИНА_БАЛИЧЕВАЦ_70823_714682",
    },
    {
        label: "МЕРОШИНА, БАТУШИНАЦ",
        value: "МЕРОШИНА_БАТУШИНАЦ_70823_714712",
    },
    {
        label: "МЕРОШИНА, БИЉЕГ",
        value: "МЕРОШИНА_БИЉЕГ_70823_714755",
    },
    {
        label: "МЕРОШИНА, БРЕСТ",
        value: "МЕРОШИНА_БРЕСТ_70823_714780",
    },
    {
        label: "МЕРОШИНА, БУЧИЋ",
        value: "МЕРОШИНА_БУЧИЋ_70823_714810",
    },
    {
        label: "МЕРОШИНА, ГОРЊА РАСОВАЧА",
        value: "МЕРОШИНА_ГОРЊА РАСОВАЧА_70823_714879",
    },
    {
        label: "МЕРОШИНА, ГРАДИШТЕ",
        value: "МЕРОШИНА_ГРАДИШТЕ_70823_714933",
    },
    {
        label: "МЕРОШИНА, ДЕВЧА",
        value: "МЕРОШИНА_ДЕВЧА_70823_714968",
    },
    {
        label: "МЕРОШИНА, ДЕШИЛОВО",
        value: "МЕРОШИНА_ДЕШИЛОВО_70823_714976",
    },
    {
        label: "МЕРОШИНА, ДОЊА РАСОВАЧА",
        value: "МЕРОШИНА_ДОЊА РАСОВАЧА_70823_715000",
    },
    {
        label: "МЕРОШИНА, ДУДУЛАЈЦЕ",
        value: "МЕРОШИНА_ДУДУЛАЈЦЕ_70823_701041",
    },
    {
        label: "МЕРОШИНА, ЈУГ БОГДАНОВАЦ",
        value: "МЕРОШИНА_ЈУГ БОГДАНОВАЦ_70823_715182",
    },
    {
        label: "МЕРОШИНА, КОСТАДИНОВАЦ",
        value: "МЕРОШИНА_КОСТАДИНОВАЦ_70823_715255",
    },
    {
        label: "МЕРОШИНА, КРАЈКОВАЦ",
        value: "МЕРОШИНА_КРАЈКОВАЦ_70823_715271",
    },
    {
        label: "МЕРОШИНА, ЛЕПАЈА",
        value: "МЕРОШИНА_ЛЕПАЈА_70823_715298",
    },
    {
        label: "МЕРОШИНА, МЕРОШИНА",
        value: "МЕРОШИНА_МЕРОШИНА_70823_715336",
    },
    {
        label: "МЕРОШИНА, ОБЛАЧИНА",
        value: "МЕРОШИНА_ОБЛАЧИНА_70823_715425",
    },
    {
        label: "МЕРОШИНА, РОЖИНА",
        value: "МЕРОШИНА_РОЖИНА_70823_715549",
    },
    {
        label: "МИОНИЦА, БЕРКОВАЦ",
        value: "МИОНИЦА_БЕРКОВАЦ_70831_727814",
    },
    {
        label: "МИОНИЦА, БРЕЖЂЕ",
        value: "МИОНИЦА_БРЕЖЂЕ_70831_727849",
    },
    {
        label: "МИОНИЦА, БУКОВАЦ",
        value: "МИОНИЦА_БУКОВАЦ_70831_727857",
    },
    {
        label: "МИОНИЦА, ВИРОВАЦ",
        value: "МИОНИЦА_ВИРОВАЦ_70831_727873",
    },
    {
        label: "МИОНИЦА, ВРТИГЛАВ",
        value: "МИОНИЦА_ВРТИГЛАВ_70831_727890",
    },
    {
        label: "МИОНИЦА, ГОЛУБАЦ",
        value: "МИОНИЦА_ГОЛУБАЦ_70831_727903",
    },
    {
        label: "МИОНИЦА, ГОРЊИ ЛАЈКОВАЦ",
        value: "МИОНИЦА_ГОРЊИ ЛАЈКОВАЦ_70831_727911",
    },
    {
        label: "МИОНИЦА, ГОРЊИ МУШИЋ",
        value: "МИОНИЦА_ГОРЊИ МУШИЋ_70831_727920",
    },
    {
        label: "МИОНИЦА, ГУЊИЦА",
        value: "МИОНИЦА_ГУЊИЦА_70831_727938",
    },
    {
        label: "МИОНИЦА, ДОЊИ МУШИЋ",
        value: "МИОНИЦА_ДОЊИ МУШИЋ_70831_727954",
    },
    {
        label: "МИОНИЦА, ДУЧИЋ",
        value: "МИОНИЦА_ДУЧИЋ_70831_727962",
    },
    {
        label: "МИОНИЦА, ЂУРЂЕВАЦ",
        value: "МИОНИЦА_ЂУРЂЕВАЦ_70831_727989",
    },
    {
        label: "МИОНИЦА, КЛАШНИЋ",
        value: "МИОНИЦА_КЛАШНИЋ_70831_728012",
    },
    {
        label: "МИОНИЦА, КЉУЧ",
        value: "МИОНИЦА_КЉУЧ_70831_728039",
    },
    {
        label: "МИОНИЦА, КОМАНИЦЕ",
        value: "МИОНИЦА_КОМАНИЦЕ_70831_728047",
    },
    {
        label: "МИОНИЦА, КРЧМАР",
        value: "МИОНИЦА_КРЧМАР_70831_728055",
    },
    {
        label: "МИОНИЦА, МАЉЕВИЋ",
        value: "МИОНИЦА_МАЉЕВИЋ_70831_728071",
    },
    {
        label: "МИОНИЦА, МИОНИЦА (ВАРОШ)",
        value: "МИОНИЦА_МИОНИЦА (ВАРОШ)_70831_728098",
    },
    {
        label: "МИОНИЦА, МИОНИЦА (СЕЛО)",
        value: "МИОНИЦА_МИОНИЦА (СЕЛО)_70831_728101",
    },
    {
        label: "МИОНИЦА, МРАТИШИЋ",
        value: "МИОНИЦА_МРАТИШИЋ_70831_728110",
    },
    {
        label: "МИОНИЦА, НАНОМИР",
        value: "МИОНИЦА_НАНОМИР_70831_728136",
    },
    {
        label: "МИОНИЦА, ОСЕЧЕНИЦА",
        value: "МИОНИЦА_ОСЕЧЕНИЦА_70831_728144",
    },
    {
        label: "МИОНИЦА, ПАШТРИЋ",
        value: "МИОНИЦА_ПАШТРИЋ_70831_728187",
    },
    {
        label: "МИОНИЦА, ПЛАНИНИЦА",
        value: "МИОНИЦА_ПЛАНИНИЦА_70831_728209",
    },
    {
        label: "МИОНИЦА, ПОПАДИЋ",
        value: "МИОНИЦА_ПОПАДИЋ_70831_728217",
    },
    {
        label: "МИОНИЦА, РАДОБИЋ",
        value: "МИОНИЦА_РАДОБИЋ_70831_728241",
    },
    {
        label: "МИОНИЦА, РАЈКОВИЋ",
        value: "МИОНИЦА_РАЈКОВИЋ_70831_728250",
    },
    {
        label: "МИОНИЦА, РАКАРИ",
        value: "МИОНИЦА_РАКАРИ_70831_728268",
    },
    {
        label: "МИОНИЦА, РОБАЈЕ",
        value: "МИОНИЦА_РОБАЈЕ_70831_728284",
    },
    {
        label: "МИОНИЦА, САНКОВИЋ",
        value: "МИОНИЦА_САНКОВИЋ_70831_728292",
    },
    {
        label: "МИОНИЦА, СТРУГАНИК",
        value: "МИОНИЦА_СТРУГАНИК_70831_728322",
    },
    {
        label: "МИОНИЦА, ТАБАНОВИЋ",
        value: "МИОНИЦА_ТАБАНОВИЋ_70831_728349",
    },
    {
        label: "МИОНИЦА, ТОДОРИН ДО",
        value: "МИОНИЦА_ТОДОРИН ДО_70831_728357",
    },
    {
        label: "МИОНИЦА, ТОЛИЋ",
        value: "МИОНИЦА_ТОЛИЋ_70831_728365",
    },
    {
        label: "МИОНИЦА, ШУШЕОКА",
        value: "МИОНИЦА_ШУШЕОКА_70831_728381",
    },
    {
        label: "МЛАДЕНОВАЦ, АМЕРИЋ",
        value: "МЛАДЕНОВАЦ_АМЕРИЋ_70173_728390",
    },
    {
        label: "МЛАДЕНОВАЦ, ВЕЛИКА ИВАНЧА",
        value: "МЛАДЕНОВАЦ_ВЕЛИКА ИВАНЧА_70173_728411",
    },
    {
        label: "МЛАДЕНОВАЦ, ВЕЛИКА КРСНА",
        value: "МЛАДЕНОВАЦ_ВЕЛИКА КРСНА_70173_740241",
    },
    {
        label: "МЛАДЕНОВАЦ, ВЛАШКА",
        value: "МЛАДЕНОВАЦ_ВЛАШКА_70173_728420",
    },
    {
        label: "МЛАДЕНОВАЦ, ГРАНИЦЕ",
        value: "МЛАДЕНОВАЦ_ГРАНИЦЕ_70173_740306",
    },
    {
        label: "МЛАДЕНОВАЦ, ДУБОНА",
        value: "МЛАДЕНОВАЦ_ДУБОНА_70173_739987",
    },
    {
        label: "МЛАДЕНОВАЦ, ЈАГЊИЛО",
        value: "МЛАДЕНОВАЦ_ЈАГЊИЛО_70173_740349",
    },
    {
        label: "МЛАДЕНОВАЦ, КОВАЧЕВАЦ",
        value: "МЛАДЕНОВАЦ_КОВАЧЕВАЦ_70173_740357",
    },
    {
        label: "МЛАДЕНОВАЦ, КОРАЋИЦА",
        value: "МЛАДЕНОВАЦ_КОРАЋИЦА_70173_728489",
    },
    {
        label: "МЛАДЕНОВАЦ, МАЛА ВРБИЦА",
        value: "МЛАДЕНОВАЦ_МАЛА ВРБИЦА_70173_728497",
    },
    {
        label: "МЛАДЕНОВАЦ, МАРКОВАЦ",
        value: "МЛАДЕНОВАЦ_МАРКОВАЦ_70173_701602",
    },
    {
        label: "МЛАДЕНОВАЦ, МЕЂУЛУЖЈЕ",
        value: "МЛАДЕНОВАЦ_МЕЂУЛУЖЈЕ_70173_728535",
    },
    {
        label: "МЛАДЕНОВАЦ, МЛАДЕНОВАЦ (ВАРОШ)",
        value: "МЛАДЕНОВАЦ_МЛАДЕНОВАЦ (ВАРОШ)_70173_728543",
    },
    {
        label: "МЛАДЕНОВАЦ, МЛАДЕНОВАЦ (СЕЛО)",
        value: "МЛАДЕНОВАЦ_МЛАДЕНОВАЦ (СЕЛО)_70173_728551",
    },
    {
        label: "МЛАДЕНОВАЦ, ПРУЖАТОВАЦ",
        value: "МЛАДЕНОВАЦ_ПРУЖАТОВАЦ_70173_728594",
    },
    {
        label: "МЛАДЕНОВАЦ, РАБРОВАЦ",
        value: "МЛАДЕНОВАЦ_РАБРОВАЦ_70173_740403",
    },
    {
        label: "МЛАДЕНОВАЦ, РАЈКОВАЦ",
        value: "МЛАДЕНОВАЦ_РАЈКОВАЦ_70173_728608",
    },
    {
        label: "МЛАДЕНОВАЦ, СЕНАЈА",
        value: "МЛАДЕНОВАЦ_СЕНАЈА_70173_728659",
    },
    {
        label: "МЛАДЕНОВАЦ, ШЕПШИН",
        value: "МЛАДЕНОВАЦ_ШЕПШИН_70173_728705",
    },
    {
        label: "НЕГОТИН, АЛЕКСАНДРОВАЦ",
        value: "НЕГОТИН_АЛЕКСАНДРОВАЦ_70840_728802",
    },
    {
        label: "НЕГОТИН, БРАЋЕВАЦ",
        value: "НЕГОТИН_БРАЋЕВАЦ_70840_728713",
    },
    {
        label: "НЕГОТИН, БРЕСТОВАЦ",
        value: "НЕГОТИН_БРЕСТОВАЦ_70840_728721",
    },
    {
        label: "НЕГОТИН, БУКОВЧЕ",
        value: "НЕГОТИН_БУКОВЧЕ_70840_728730",
    },
    {
        label: "НЕГОТИН, ВЕЉКОВО",
        value: "НЕГОТИН_ВЕЉКОВО_70840_728748",
    },
    {
        label: "НЕГОТИН, ВИДРОВАЦ",
        value: "НЕГОТИН_ВИДРОВАЦ_70840_728756",
    },
    {
        label: "НЕГОТИН, ВРАТНА",
        value: "НЕГОТИН_ВРАТНА_70840_716804",
    },
    {
        label: "НЕГОТИН, ГЛОГОВИЦА II",
        value: "НЕГОТИН_ГЛОГОВИЦА II_70840_728764",
    },
    {
        label: "НЕГОТИН, ДУПЉАНЕ",
        value: "НЕГОТИН_ДУПЉАНЕ_70840_728772",
    },
    {
        label: "НЕГОТИН, ДУШАНОВАЦ",
        value: "НЕГОТИН_ДУШАНОВАЦ_70840_728799",
    },
    {
        label: "НЕГОТИН, ЈАБУКОВАЦ",
        value: "НЕГОТИН_ЈАБУКОВАЦ_70840_716812",
    },
    {
        label: "НЕГОТИН, ЈАСЕНИЦА",
        value: "НЕГОТИН_ЈАСЕНИЦА_70840_728829",
    },
    {
        label: "НЕГОТИН, КАРБУЛОВО",
        value: "НЕГОТИН_КАРБУЛОВО_70840_728837",
    },
    {
        label: "НЕГОТИН, КОБИШНИЦА",
        value: "НЕГОТИН_КОБИШНИЦА_70840_728845",
    },
    {
        label: "НЕГОТИН, КОВИЛОВО",
        value: "НЕГОТИН_КОВИЛОВО_70840_728853",
    },
    {
        label: "НЕГОТИН, МАЛА КАМЕНИЦА",
        value: "НЕГОТИН_МАЛА КАМЕНИЦА_70840_716847",
    },
    {
        label: "НЕГОТИН, МАЛАЈНИЦА",
        value: "НЕГОТИН_МАЛАЈНИЦА_70840_716855",
    },
    {
        label: "НЕГОТИН, МИЛОШЕВО",
        value: "НЕГОТИН_МИЛОШЕВО_70840_728861",
    },
    {
        label: "НЕГОТИН, МИХАЈЛОВАЦ",
        value: "НЕГОТИН_МИХАЈЛОВАЦ_70840_716863",
    },
    {
        label: "НЕГОТИН, МОКРАЊЕ",
        value: "НЕГОТИН_МОКРАЊЕ_70840_728870",
    },
    {
        label: "НЕГОТИН, НЕГОТИН",
        value: "НЕГОТИН_НЕГОТИН_70840_728888",
    },
    {
        label: "НЕГОТИН, ПЛАВНА",
        value: "НЕГОТИН_ПЛАВНА_70840_716871",
    },
    {
        label: "НЕГОТИН, ПОПОВИЦА",
        value: "НЕГОТИН_ПОПОВИЦА_70840_705284",
    },
    {
        label: "НЕГОТИН, ПРАХОВО",
        value: "НЕГОТИН_ПРАХОВО_70840_728896",
    },
    {
        label: "НЕГОТИН, РАДУЈЕВАЦ",
        value: "НЕГОТИН_РАДУЈЕВАЦ_70840_728900",
    },
    {
        label: "НЕГОТИН, РАЈАЦ",
        value: "НЕГОТИН_РАЈАЦ_70840_728918",
    },
    {
        label: "НЕГОТИН, РЕЧКА",
        value: "НЕГОТИН_РЕЧКА_70840_728926",
    },
    {
        label: "НЕГОТИН, РОГЉЕВО",
        value: "НЕГОТИН_РОГЉЕВО_70840_728934",
    },
    {
        label: "НЕГОТИН, САМАРИНОВАЦ",
        value: "НЕГОТИН_САМАРИНОВАЦ_70840_728942",
    },
    {
        label: "НЕГОТИН, СИКОЛЕ I",
        value: "НЕГОТИН_СИКОЛЕ I_70840_705314",
    },
    {
        label: "НЕГОТИН, СИКОЛЕ II",
        value: "НЕГОТИН_СИКОЛЕ II_70840_728969",
    },
    {
        label: "НЕГОТИН, СЛАТИНА",
        value: "НЕГОТИН_СЛАТИНА_70840_716898",
    },
    {
        label: "НЕГОТИН, СМЕДОВАЦ",
        value: "НЕГОТИН_СМЕДОВАЦ_70840_728977",
    },
    {
        label: "НЕГОТИН, СРБОВО",
        value: "НЕГОТИН_СРБОВО_70840_728985",
    },
    {
        label: "НЕГОТИН, ТАМНИЧ",
        value: "НЕГОТИН_ТАМНИЧ_70840_728993",
    },
    {
        label: "НЕГОТИН, ТРЊАНЕ",
        value: "НЕГОТИН_ТРЊАНЕ_70840_705349",
    },
    {
        label: "НЕГОТИН, УРОВИЦА",
        value: "НЕГОТИН_УРОВИЦА_70840_716901",
    },
    {
        label: "НЕГОТИН, ЦРНОМАСНИЦА",
        value: "НЕГОТИН_ЦРНОМАСНИЦА_70840_729019",
    },
    {
        label: "НЕГОТИН, ЧУБРА",
        value: "НЕГОТИН_ЧУБРА_70840_729027",
    },
    {
        label: "НЕГОТИН, ШАРКАМЕН",
        value: "НЕГОТИН_ШАРКАМЕН_70840_705357",
    },
    {
        label: "НЕГОТИН, ШТУБИК I",
        value: "НЕГОТИН_ШТУБИК I_70840_716910",
    },
    {
        label: "НЕГОТИН, ШТУБИК II",
        value: "НЕГОТИН_ШТУБИК II_70840_729035",
    },
    {
        label: "НИШКА БАЊА, БАНЦАРЕВО",
        value: "НИШКА БАЊА_БАНЦАРЕВО_71285_729043",
    },
    {
        label: "НИШКА БАЊА, ГОРЊА СТУДЕНА",
        value: "НИШКА БАЊА_ГОРЊА СТУДЕНА_71285_729221",
    },
    {
        label: "НИШКА БАЊА, ДОЊА СТУДЕНА",
        value: "НИШКА БАЊА_ДОЊА СТУДЕНА_71285_729361",
    },
    {
        label: "НИШКА БАЊА, ЈЕЛАШНИЦА",
        value: "НИШКА БАЊА_ЈЕЛАШНИЦА_71285_729523",
    },
    {
        label: "НИШКА БАЊА, КУНОВИЦА",
        value: "НИШКА БАЊА_КУНОВИЦА_71285_729639",
    },
    {
        label: "НИШКА БАЊА, ЛАЗАРЕВО СЕЛО",
        value: "НИШКА БАЊА_ЛАЗАРЕВО СЕЛО_71285_729647",
    },
    {
        label: "НИШКА БАЊА, НИШКА БАЊА",
        value: "НИШКА БАЊА_НИШКА БАЊА_71285_729825",
    },
    {
        label: "НИШКА БАЊА, ОСТРОВИЦА",
        value: "НИШКА БАЊА_ОСТРОВИЦА_71285_729876",
    },
    {
        label: "НИШКА БАЊА, ПРВА КУТИНА",
        value: "НИШКА БАЊА_ПРВА КУТИНА_71285_729949",
    },
    {
        label: "НИШКА БАЊА, ПРОСЕК-МАНАСТИР",
        value: "НИШКА БАЊА_ПРОСЕК-МАНАСТИР_71285_729957",
    },
    {
        label: "НИШКА БАЊА, РАВНИ ДО",
        value: "НИШКА БАЊА_РАВНИ ДО_71285_729965",
    },
    {
        label: "НИШКА БАЊА, РАДИКИНА БАРА",
        value: "НИШКА БАЊА_РАДИКИНА БАРА_71285_729973",
    },
    {
        label: "НИШКА БАЊА, РАУТОВО",
        value: "НИШКА БАЊА_РАУТОВО_71285_729981",
    },
    {
        label: "НИШКА БАЊА, СИЋЕВО",
        value: "НИШКА БАЊА_СИЋЕВО_71285_730025",
    },
    {
        label: "НИШКА БАЊА, ЧУКЉЕНИК",
        value: "НИШКА БАЊА_ЧУКЉЕНИК_71285_730173",
    },
    {
        label: "НОВА ВАРОШ, АКМАЧИЋИ",
        value: "НОВА ВАРОШ_АКМАЧИЋИ_70866_730190",
    },
    {
        label: "НОВА ВАРОШ, АМЗИЋИ",
        value: "НОВА ВАРОШ_АМЗИЋИ_70866_730203",
    },
    {
        label: "НОВА ВАРОШ, БЕЛА РЕКА",
        value: "НОВА ВАРОШ_БЕЛА РЕКА_70866_743704",
    },
    {
        label: "НОВА ВАРОШ, БИСТРИЦА",
        value: "НОВА ВАРОШ_БИСТРИЦА_70866_730211",
    },
    {
        label: "НОВА ВАРОШ, БОЖЕТИЋИ",
        value: "НОВА ВАРОШ_БОЖЕТИЋИ_70866_730220",
    },
    {
        label: "НОВА ВАРОШ, БРДО",
        value: "НОВА ВАРОШ_БРДО_70866_730238",
    },
    {
        label: "НОВА ВАРОШ, БУКОВИК",
        value: "НОВА ВАРОШ_БУКОВИК_70866_730246",
    },
    {
        label: "НОВА ВАРОШ, БУРАЂА",
        value: "НОВА ВАРОШ_БУРАЂА_70866_743739",
    },
    {
        label: "НОВА ВАРОШ, ВИЛОВИ",
        value: "НОВА ВАРОШ_ВИЛОВИ_70866_730254",
    },
    {
        label: "НОВА ВАРОШ, ВРАНЕША",
        value: "НОВА ВАРОШ_ВРАНЕША_70866_730262",
    },
    {
        label: "НОВА ВАРОШ, ГОРЊЕ ТРУДОВО",
        value: "НОВА ВАРОШ_ГОРЊЕ ТРУДОВО_70866_730289",
    },
    {
        label: "НОВА ВАРОШ, ДЕБЕЉА",
        value: "НОВА ВАРОШ_ДЕБЕЉА_70866_730297",
    },
    {
        label: "НОВА ВАРОШ, ДРАГЛИЦА",
        value: "НОВА ВАРОШ_ДРАГЛИЦА_70866_743763",
    },
    {
        label: "НОВА ВАРОШ, ДРАЖЕВИЋИ",
        value: "НОВА ВАРОШ_ДРАЖЕВИЋИ_70866_730319",
    },
    {
        label: "НОВА ВАРОШ, ДРМАНОВИЋИ",
        value: "НОВА ВАРОШ_ДРМАНОВИЋИ_70866_730327",
    },
    {
        label: "НОВА ВАРОШ, ЈАСЕНОВО",
        value: "НОВА ВАРОШ_ЈАСЕНОВО_70866_743801",
    },
    {
        label: "НОВА ВАРОШ, КОМАРАНИ",
        value: "НОВА ВАРОШ_КОМАРАНИ_70866_730335",
    },
    {
        label: "НОВА ВАРОШ, КУЋАНИ",
        value: "НОВА ВАРОШ_КУЋАНИ_70866_743836",
    },
    {
        label: "НОВА ВАРОШ, ЉЕПОЈЕВИЋИ",
        value: "НОВА ВАРОШ_ЉЕПОЈЕВИЋИ_70866_730343",
    },
    {
        label: "НОВА ВАРОШ, МИШЕВИЋИ",
        value: "НОВА ВАРОШ_МИШЕВИЋИ_70866_739642",
    },
    {
        label: "НОВА ВАРОШ, НЕГБИНА",
        value: "НОВА ВАРОШ_НЕГБИНА_70866_743887",
    },
    {
        label: "НОВА ВАРОШ, НОВА ВАРОШ",
        value: "НОВА ВАРОШ_НОВА ВАРОШ_70866_730351",
    },
    {
        label: "НОВА ВАРОШ, ОЈКОВИЦА",
        value: "НОВА ВАРОШ_ОЈКОВИЦА_70866_743895",
    },
    {
        label: "НОВА ВАРОШ, РАДИЈЕВИЋИ",
        value: "НОВА ВАРОШ_РАДИЈЕВИЋИ_70866_730360",
    },
    {
        label: "НОВА ВАРОШ, РАДОИЊА",
        value: "НОВА ВАРОШ_РАДОИЊА_70866_730378",
    },
    {
        label: "НОВА ВАРОШ, РУТОШИ",
        value: "НОВА ВАРОШ_РУТОШИ_70866_730386",
    },
    {
        label: "НОВА ВАРОШ, СЕНИШТА",
        value: "НОВА ВАРОШ_СЕНИШТА_70866_743925",
    },
    {
        label: "НОВА ВАРОШ, ТИКВА",
        value: "НОВА ВАРОШ_ТИКВА_70866_730394",
    },
    {
        label: "НОВА ВАРОШ, ТИСОВИЦА",
        value: "НОВА ВАРОШ_ТИСОВИЦА_70866_730408",
    },
    {
        label: "НОВА ВАРОШ, ТРУДОВО",
        value: "НОВА ВАРОШ_ТРУДОВО_70866_701955",
    },
    {
        label: "НОВА ВАРОШ, ЧЕЛИЦЕ",
        value: "НОВА ВАРОШ_ЧЕЛИЦЕ_70866_730416",
    },
    {
        label: "НОВА ВАРОШ, ШТИТКОВО",
        value: "НОВА ВАРОШ_ШТИТКОВО_70866_730424",
    },
    {
        label: "НОВА ЦРЊА, АЛЕКСАНДРОВО",
        value: "НОВА ЦРЊА_АЛЕКСАНДРОВО_80250_800929",
    },
    {
        label: "НОВА ЦРЊА, ВОЈВОДА СТЕПА",
        value: "НОВА ЦРЊА_ВОЈВОДА СТЕПА_80250_800937",
    },
    {
        label: "НОВА ЦРЊА, МОЛИН",
        value: "НОВА ЦРЊА_МОЛИН_80250_800856",
    },
    {
        label: "НОВА ЦРЊА, НОВА ЦРЊА",
        value: "НОВА ЦРЊА_НОВА ЦРЊА_80250_800864",
    },
    {
        label: "НОВА ЦРЊА, РАДОЈЕВО",
        value: "НОВА ЦРЊА_РАДОЈЕВО_80250_800872",
    },
    {
        label: "НОВА ЦРЊА, СРПСКА ЦРЊА",
        value: "НОВА ЦРЊА_СРПСКА ЦРЊА_80250_800899",
    },
    {
        label: "НОВА ЦРЊА, ТОБА",
        value: "НОВА ЦРЊА_ТОБА_80250_800902",
    },
    {
        label: "НОВИ БЕОГРАД, НОВИ БЕОГРАД",
        value: "НОВИ БЕОГРАД_НОВИ БЕОГРАД_70181_716090",
    },
    {
        label: "НОВИ БЕЧЕЈ, БОЧАР",
        value: "НОВИ БЕЧЕЈ_БОЧАР_80268_801305",
    },
    {
        label: "НОВИ БЕЧЕЈ, КУМАНЕ",
        value: "НОВИ БЕЧЕЈ_КУМАНЕ_80268_805572",
    },
    {
        label: "НОВИ БЕЧЕЈ, НОВИ БЕЧЕЈ",
        value: "НОВИ БЕЧЕЈ_НОВИ БЕЧЕЈ_80268_805661",
    },
    {
        label: "НОВИ БЕЧЕЈ, НОВО МИЛОШЕВО",
        value: "НОВИ БЕЧЕЈ_НОВО МИЛОШЕВО_80268_801313",
    },
    {
        label: "НОВИ КНЕЖЕВАЦ, БАНАТСКО АРАНЂЕЛОВО",
        value: "НОВИ КНЕЖЕВАЦ_БАНАТСКО АРАНЂЕЛОВО_80276_801780",
    },
    {
        label: "НОВИ КНЕЖЕВАЦ, ЂАЛА",
        value: "НОВИ КНЕЖЕВАЦ_ЂАЛА_80276_801895",
    },
    {
        label: "НОВИ КНЕЖЕВАЦ, МАЈДАН",
        value: "НОВИ КНЕЖЕВАЦ_МАЈДАН_80276_801925",
    },
    {
        label: "НОВИ КНЕЖЕВАЦ, НОВИ КНЕЖЕВАЦ",
        value: "НОВИ КНЕЖЕВАЦ_НОВИ КНЕЖЕВАЦ_80276_801941",
    },
    {
        label: "НОВИ КНЕЖЕВАЦ, СРПСКИ КРСТУР",
        value: "НОВИ КНЕЖЕВАЦ_СРПСКИ КРСТУР_80276_801909",
    },
    {
        label: "НОВИ ПАЗАР, АЛУЛОВИЋЕ",
        value: "НОВИ ПАЗАР_АЛУЛОВИЋЕ_70874_730432",
    },
    {
        label: "НОВИ ПАЗАР, БАЈЕВИЦА",
        value: "НОВИ ПАЗАР_БАЈЕВИЦА_70874_730459",
    },
    {
        label: "НОВИ ПАЗАР, БАЊА",
        value: "НОВИ ПАЗАР_БАЊА_70874_730467",
    },
    {
        label: "НОВИ ПАЗАР, БАРЕ",
        value: "НОВИ ПАЗАР_БАРЕ_70874_730475",
    },
    {
        label: "НОВИ ПАЗАР, БАТЊИК",
        value: "НОВИ ПАЗАР_БАТЊИК_70874_730483",
    },
    {
        label: "НОВИ ПАЗАР, БЕКОВА",
        value: "НОВИ ПАЗАР_БЕКОВА_70874_730491",
    },
    {
        label: "НОВИ ПАЗАР, БЕЛЕ ВОДЕ",
        value: "НОВИ ПАЗАР_БЕЛЕ ВОДЕ_70874_730505",
    },
    {
        label: "НОВИ ПАЗАР, БОТУРОВИНА",
        value: "НОВИ ПАЗАР_БОТУРОВИНА_70874_730513",
    },
    {
        label: "НОВИ ПАЗАР, БРЂАНИ",
        value: "НОВИ ПАЗАР_БРЂАНИ_70874_730521",
    },
    {
        label: "НОВИ ПАЗАР, БРЕСТОВО",
        value: "НОВИ ПАЗАР_БРЕСТОВО_70874_730530",
    },
    {
        label: "НОВИ ПАЗАР, ВАРЕВО",
        value: "НОВИ ПАЗАР_ВАРЕВО_70874_730548",
    },
    {
        label: "НОВИ ПАЗАР, ВЕВЕР",
        value: "НОВИ ПАЗАР_ВЕВЕР_70874_730556",
    },
    {
        label: "НОВИ ПАЗАР, ВИДОВО",
        value: "НОВИ ПАЗАР_ВИДОВО_70874_730564",
    },
    {
        label: "НОВИ ПАЗАР, ВИТКОВИЋЕ",
        value: "НОВИ ПАЗАР_ВИТКОВИЋЕ_70874_730572",
    },
    {
        label: "НОВИ ПАЗАР, ВОЈКОВИЋЕ",
        value: "НОВИ ПАЗАР_ВОЈКОВИЋЕ_70874_730599",
    },
    {
        label: "НОВИ ПАЗАР, ВОЈНИЋЕ",
        value: "НОВИ ПАЗАР_ВОЈНИЋЕ_70874_730602",
    },
    {
        label: "НОВИ ПАЗАР, ВРАНОВИНА",
        value: "НОВИ ПАЗАР_ВРАНОВИНА_70874_730629",
    },
    {
        label: "НОВИ ПАЗАР, ВУЧИНИЋЕ",
        value: "НОВИ ПАЗАР_ВУЧИНИЋЕ_70874_730637",
    },
    {
        label: "НОВИ ПАЗАР, ВУЧЈА ЛОКВА",
        value: "НОВИ ПАЗАР_ВУЧЈА ЛОКВА_70874_730645",
    },
    {
        label: "НОВИ ПАЗАР, ГОЛИЦЕ",
        value: "НОВИ ПАЗАР_ГОЛИЦЕ_70874_730653",
    },
    {
        label: "НОВИ ПАЗАР, ГОРЊА ТУШИМЉА",
        value: "НОВИ ПАЗАР_ГОРЊА ТУШИМЉА_70874_730661",
    },
    {
        label: "НОВИ ПАЗАР, ГОШЕВО",
        value: "НОВИ ПАЗАР_ГОШЕВО_70874_730670",
    },
    {
        label: "НОВИ ПАЗАР, ГРАЂАНОВИЋЕ",
        value: "НОВИ ПАЗАР_ГРАЂАНОВИЋЕ_70874_730688",
    },
    {
        label: "НОВИ ПАЗАР, ГРАЧАНЕ",
        value: "НОВИ ПАЗАР_ГРАЧАНЕ_70874_730696",
    },
    {
        label: "НОВИ ПАЗАР, ГРУБЕТИЋЕ",
        value: "НОВИ ПАЗАР_ГРУБЕТИЋЕ_70874_730700",
    },
    {
        label: "НОВИ ПАЗАР, ДЕЖЕВА",
        value: "НОВИ ПАЗАР_ДЕЖЕВА_70874_730718",
    },
    {
        label: "НОВИ ПАЗАР, ДОЈИНОВИЋЕ",
        value: "НОВИ ПАЗАР_ДОЈИНОВИЋЕ_70874_730726",
    },
    {
        label: "НОВИ ПАЗАР, ДОЛАЦ",
        value: "НОВИ ПАЗАР_ДОЛАЦ_70874_730734",
    },
    {
        label: "НОВИ ПАЗАР, ДОЉАНИ",
        value: "НОВИ ПАЗАР_ДОЉАНИ_70874_730742",
    },
    {
        label: "НОВИ ПАЗАР, ДРАГОЧЕВО",
        value: "НОВИ ПАЗАР_ДРАГОЧЕВО_70874_730769",
    },
    {
        label: "НОВИ ПАЗАР, ДРАМИЋЕ",
        value: "НОВИ ПАЗАР_ДРАМИЋЕ_70874_730777",
    },
    {
        label: "НОВИ ПАЗАР, ЖУЊЕВИЋЕ",
        value: "НОВИ ПАЗАР_ЖУЊЕВИЋЕ_70874_730785",
    },
    {
        label: "НОВИ ПАЗАР, ЗАБРЂЕ",
        value: "НОВИ ПАЗАР_ЗАБРЂЕ_70874_730793",
    },
    {
        label: "НОВИ ПАЗАР, ЗЛАТАРЕ",
        value: "НОВИ ПАЗАР_ЗЛАТАРЕ_70874_730807",
    },
    {
        label: "НОВИ ПАЗАР, ИВАНЧА",
        value: "НОВИ ПАЗАР_ИВАНЧА_70874_730815",
    },
    {
        label: "НОВИ ПАЗАР, ИЗБИЦЕ",
        value: "НОВИ ПАЗАР_ИЗБИЦЕ_70874_730823",
    },
    {
        label: "НОВИ ПАЗАР, ЈАБЛАНИЦА",
        value: "НОВИ ПАЗАР_ЈАБЛАНИЦА_70874_730831",
    },
    {
        label: "НОВИ ПАЗАР, ЈАВОР",
        value: "НОВИ ПАЗАР_ЈАВОР_70874_730840",
    },
    {
        label: "НОВИ ПАЗАР, ЈАНЧА",
        value: "НОВИ ПАЗАР_ЈАНЧА_70874_730858",
    },
    {
        label: "НОВИ ПАЗАР, ЈОВА",
        value: "НОВИ ПАЗАР_ЈОВА_70874_730866",
    },
    {
        label: "НОВИ ПАЗАР, КАШАЉ",
        value: "НОВИ ПАЗАР_КАШАЉ_70874_730874",
    },
    {
        label: "НОВИ ПАЗАР, КОВАЧЕВО",
        value: "НОВИ ПАЗАР_КОВАЧЕВО_70874_730882",
    },
    {
        label: "НОВИ ПАЗАР, КОЖЉЕ",
        value: "НОВИ ПАЗАР_КОЖЉЕ_70874_730904",
    },
    {
        label: "НОВИ ПАЗАР, КОПРИВНИЦА",
        value: "НОВИ ПАЗАР_КОПРИВНИЦА_70874_730912",
    },
    {
        label: "НОВИ ПАЗАР, КОСУРИЋЕ",
        value: "НОВИ ПАЗАР_КОСУРИЋЕ_70874_730939",
    },
    {
        label: "НОВИ ПАЗАР, КРУШЕВО",
        value: "НОВИ ПАЗАР_КРУШЕВО_70874_730947",
    },
    {
        label: "НОВИ ПАЗАР, КУЗМИЧЕВО",
        value: "НОВИ ПАЗАР_КУЗМИЧЕВО_70874_730955",
    },
    {
        label: "НОВИ ПАЗАР, ЛЕЧА",
        value: "НОВИ ПАЗАР_ЛЕЧА_70874_730963",
    },
    {
        label: "НОВИ ПАЗАР, ЛОПУЖЊЕ",
        value: "НОВИ ПАЗАР_ЛОПУЖЊЕ_70874_730971",
    },
    {
        label: "НОВИ ПАЗАР, ЛУКАРЕ",
        value: "НОВИ ПАЗАР_ЛУКАРЕ_70874_730980",
    },
    {
        label: "НОВИ ПАЗАР, ЛУКАРСКО ГОШЕВО",
        value: "НОВИ ПАЗАР_ЛУКАРСКО ГОШЕВО_70874_730998",
    },
    {
        label: "НОВИ ПАЗАР, ЛУКОЦРЕВО",
        value: "НОВИ ПАЗАР_ЛУКОЦРЕВО_70874_731005",
    },
    {
        label: "НОВИ ПАЗАР, МИШЧИЋЕ",
        value: "НОВИ ПАЗАР_МИШЧИЋЕ_70874_731013",
    },
    {
        label: "НОВИ ПАЗАР, МУР",
        value: "НОВИ ПАЗАР_МУР_70874_731021",
    },
    {
        label: "НОВИ ПАЗАР, МУХОВО",
        value: "НОВИ ПАЗАР_МУХОВО_70874_731030",
    },
    {
        label: "НОВИ ПАЗАР, НЕГОТИНАЦ",
        value: "НОВИ ПАЗАР_НЕГОТИНАЦ_70874_731048",
    },
    {
        label: "НОВИ ПАЗАР, НОВИ ПАЗАР",
        value: "НОВИ ПАЗАР_НОВИ ПАЗАР_70874_731056",
    },
    {
        label: "НОВИ ПАЗАР, ОДОЈЕВИЋЕ",
        value: "НОВИ ПАЗАР_ОДОЈЕВИЋЕ_70874_731064",
    },
    {
        label: "НОВИ ПАЗАР, ОКОСЕ",
        value: "НОВИ ПАЗАР_ОКОСЕ_70874_731072",
    },
    {
        label: "НОВИ ПАЗАР, ОСАОНИЦА",
        value: "НОВИ ПАЗАР_ОСАОНИЦА_70874_731099",
    },
    {
        label: "НОВИ ПАЗАР, ОСОЈЕ",
        value: "НОВИ ПАЗАР_ОСОЈЕ_70874_731102",
    },
    {
        label: "НОВИ ПАЗАР, ОХОЉЕ",
        value: "НОВИ ПАЗАР_ОХОЉЕ_70874_731129",
    },
    {
        label: "НОВИ ПАЗАР, ПАВЉЕ",
        value: "НОВИ ПАЗАР_ПАВЉЕ_70874_731137",
    },
    {
        label: "НОВИ ПАЗАР, ПАРАЛОВО",
        value: "НОВИ ПАЗАР_ПАРАЛОВО_70874_731145",
    },
    {
        label: "НОВИ ПАЗАР, ПАСЈИ ПОТОК",
        value: "НОВИ ПАЗАР_ПАСЈИ ПОТОК_70874_731153",
    },
    {
        label: "НОВИ ПАЗАР, ПИЛАРЕТА",
        value: "НОВИ ПАЗАР_ПИЛАРЕТА_70874_731161",
    },
    {
        label: "НОВИ ПАЗАР, ПОБРЂЕ",
        value: "НОВИ ПАЗАР_ПОБРЂЕ_70874_731170",
    },
    {
        label: "НОВИ ПАЗАР, ПОЖЕГА",
        value: "НОВИ ПАЗАР_ПОЖЕГА_70874_731188",
    },
    {
        label: "НОВИ ПАЗАР, ПОЖЕЖИНА",
        value: "НОВИ ПАЗАР_ПОЖЕЖИНА_70874_731196",
    },
    {
        label: "НОВИ ПАЗАР, ПОЛОКЦЕ",
        value: "НОВИ ПАЗАР_ПОЛОКЦЕ_70874_731200",
    },
    {
        label: "НОВИ ПАЗАР, ПОПЕ",
        value: "НОВИ ПАЗАР_ПОПЕ_70874_731218",
    },
    {
        label: "НОВИ ПАЗАР, ПОСТЕЊЕ",
        value: "НОВИ ПАЗАР_ПОСТЕЊЕ_70874_731226",
    },
    {
        label: "НОВИ ПАЗАР, ПРЋЕНОВА",
        value: "НОВИ ПАЗАР_ПРЋЕНОВА_70874_731234",
    },
    {
        label: "НОВИ ПАЗАР, ПУСТА ТУШИМЉА",
        value: "НОВИ ПАЗАР_ПУСТА ТУШИМЉА_70874_731242",
    },
    {
        label: "НОВИ ПАЗАР, ПУСТОВЛАХ",
        value: "НОВИ ПАЗАР_ПУСТОВЛАХ_70874_731269",
    },
    {
        label: "НОВИ ПАЗАР, РАДАЉИЦА",
        value: "НОВИ ПАЗАР_РАДАЉИЦА_70874_731277",
    },
    {
        label: "НОВИ ПАЗАР, РАЈЕТИЋЕ",
        value: "НОВИ ПАЗАР_РАЈЕТИЋЕ_70874_731285",
    },
    {
        label: "НОВИ ПАЗАР, РАЈКОВИЋЕ",
        value: "НОВИ ПАЗАР_РАЈКОВИЋЕ_70874_731293",
    },
    {
        label: "НОВИ ПАЗАР, РАЈЧИНОВИЋЕ",
        value: "НОВИ ПАЗАР_РАЈЧИНОВИЋЕ_70874_731307",
    },
    {
        label: "НОВИ ПАЗАР, РАЈЧИНОВИЋКА ТРНАВА",
        value: "НОВИ ПАЗАР_РАЈЧИНОВИЋКА ТРНАВА_70874_731315",
    },
    {
        label: "НОВИ ПАЗАР, РАКОВАЦ",
        value: "НОВИ ПАЗАР_РАКОВАЦ_70874_731323",
    },
    {
        label: "НОВИ ПАЗАР, РАСТ",
        value: "НОВИ ПАЗАР_РАСТ_70874_731331",
    },
    {
        label: "НОВИ ПАЗАР, СЕБЕЧЕВО",
        value: "НОВИ ПАЗАР_СЕБЕЧЕВО_70874_731340",
    },
    {
        label: "НОВИ ПАЗАР, СИТНИЧЕ",
        value: "НОВИ ПАЗАР_СИТНИЧЕ_70874_731358",
    },
    {
        label: "НОВИ ПАЗАР, СКУКОВО",
        value: "НОВИ ПАЗАР_СКУКОВО_70874_731366",
    },
    {
        label: "НОВИ ПАЗАР, СЛАТИНА",
        value: "НОВИ ПАЗАР_СЛАТИНА_70874_731374",
    },
    {
        label: "НОВИ ПАЗАР, СМИЛОВ ЛАЗ",
        value: "НОВИ ПАЗАР_СМИЛОВ ЛАЗ_70874_731382",
    },
    {
        label: "НОВИ ПАЗАР, СРЕДЊА ТУШИМЉА",
        value: "НОВИ ПАЗАР_СРЕДЊА ТУШИМЉА_70874_731404",
    },
    {
        label: "НОВИ ПАЗАР, СТРАДОВО",
        value: "НОВИ ПАЗАР_СТРАДОВО_70874_731412",
    },
    {
        label: "НОВИ ПАЗАР, СУДСКО СЕЛО",
        value: "НОВИ ПАЗАР_СУДСКО СЕЛО_70874_731439",
    },
    {
        label: "НОВИ ПАЗАР, ТЕНКОВО",
        value: "НОВИ ПАЗАР_ТЕНКОВО_70874_731447",
    },
    {
        label: "НОВИ ПАЗАР, ТРНАВА",
        value: "НОВИ ПАЗАР_ТРНАВА_70874_731455",
    },
    {
        label: "НОВИ ПАЗАР, ТУНОВО",
        value: "НОВИ ПАЗАР_ТУНОВО_70874_731463",
    },
    {
        label: "НОВИ ПАЗАР, ХОТКОВО",
        value: "НОВИ ПАЗАР_ХОТКОВО_70874_731471",
    },
    {
        label: "НОВИ ПАЗАР, ЦОКОВИЋЕ",
        value: "НОВИ ПАЗАР_ЦОКОВИЋЕ_70874_731480",
    },
    {
        label: "НОВИ ПАЗАР, ЧАШИЋ ДОЛАЦ",
        value: "НОВИ ПАЗАР_ЧАШИЋ ДОЛАЦ_70874_731498",
    },
    {
        label: "НОВИ ПАЗАР, ШАВЦИ",
        value: "НОВИ ПАЗАР_ШАВЦИ_70874_731501",
    },
    {
        label: "НОВИ ПАЗАР, ШАРОЊЕ",
        value: "НОВИ ПАЗАР_ШАРОЊЕ_70874_731510",
    },
    {
        label: "НОВИ ПАЗАР, ШТИТАРЕ",
        value: "НОВИ ПАЗАР_ШТИТАРЕ_70874_731528",
    },
    {
        label: "НОВИ САД, БЕГЕЧ",
        value: "НОВИ САД_БЕГЕЧ_89010_802077",
    },
    {
        label: "НОВИ САД, БУДИСАВА",
        value: "НОВИ САД_БУДИСАВА_89010_804541",
    },
    {
        label: "НОВИ САД, БУКОВАЦ",
        value: "НОВИ САД_БУКОВАЦ_89010_805203",
    },
    {
        label: "НОВИ САД, ВЕТЕРНИК",
        value: "НОВИ САД_ВЕТЕРНИК_89010_802298",
    },
    {
        label: "НОВИ САД, КАЋ",
        value: "НОВИ САД_КАЋ_89010_804649",
    },
    {
        label: "НОВИ САД, КИСАЧ",
        value: "НОВИ САД_КИСАЧ_89010_802123",
    },
    {
        label: "НОВИ САД, КОВИЉ",
        value: "НОВИ САД_КОВИЉ_89010_804576",
    },
    {
        label: "НОВИ САД, ЛЕДИНЦИ",
        value: "НОВИ САД_ЛЕДИНЦИ_89010_803120",
    },
    {
        label: "НОВИ САД, НОВИ САД I",
        value: "НОВИ САД_НОВИ САД I_89010_802158",
    },
    {
        label: "НОВИ САД, НОВИ САД II",
        value: "НОВИ САД_НОВИ САД II_89010_802166",
    },
    {
        label: "НОВИ САД, НОВИ САД III",
        value: "НОВИ САД_НОВИ САД III_89010_802174",
    },
    {
        label: "НОВИ САД, НОВИ САД IV",
        value: "НОВИ САД_НОВИ САД IV_89010_802182",
    },
    {
        label: "НОВИ САД, ПЕТРОВАРАДИН",
        value: "НОВИ САД_ПЕТРОВАРАДИН_89010_805378",
    },
    {
        label: "НОВИ САД, РУМЕНКА",
        value: "НОВИ САД_РУМЕНКА_89010_802239",
    },
    {
        label: "НОВИ САД, СРЕМСКА КАМЕНИЦА",
        value: "НОВИ САД_СРЕМСКА КАМЕНИЦА_89010_803308",
    },
    {
        label: "НОВИ САД, СТЕПАНОВИЋЕВО",
        value: "НОВИ САД_СТЕПАНОВИЋЕВО_89010_802263",
    },
    {
        label: "НОВИ САД, ФУТОГ",
        value: "НОВИ САД_ФУТОГ_89010_802255",
    },
    {
        label: "НОВИ САД, ЧЕНЕЈ",
        value: "НОВИ САД_ЧЕНЕЈ_89010_802085",
    },
    {
        label: "ОБРЕНОВАЦ, БАЉЕВАЦ",
        value: "ОБРЕНОВАЦ_БАЉЕВАЦ_70190_731536",
    },
    {
        label: "ОБРЕНОВАЦ, БАРИЧ",
        value: "ОБРЕНОВАЦ_БАРИЧ_70190_731544",
    },
    {
        label: "ОБРЕНОВАЦ, БЕЛО ПОЉЕ",
        value: "ОБРЕНОВАЦ_БЕЛО ПОЉЕ_70190_731552",
    },
    {
        label: "ОБРЕНОВАЦ, БРГУЛИЦЕ",
        value: "ОБРЕНОВАЦ_БРГУЛИЦЕ_70190_731579",
    },
    {
        label: "ОБРЕНОВАЦ, БРОВИЋ",
        value: "ОБРЕНОВАЦ_БРОВИЋ_70190_731587",
    },
    {
        label: "ОБРЕНОВАЦ, ВЕЛИКО ПОЉЕ",
        value: "ОБРЕНОВАЦ_ВЕЛИКО ПОЉЕ_70190_731595",
    },
    {
        label: "ОБРЕНОВАЦ, ВУКИЋЕВИЦА",
        value: "ОБРЕНОВАЦ_ВУКИЋЕВИЦА_70190_731609",
    },
    {
        label: "ОБРЕНОВАЦ, ГРАБОВАЦ",
        value: "ОБРЕНОВАЦ_ГРАБОВАЦ_70190_731617",
    },
    {
        label: "ОБРЕНОВАЦ, ДРАЖЕВАЦ",
        value: "ОБРЕНОВАЦ_ДРАЖЕВАЦ_70190_731625",
    },
    {
        label: "ОБРЕНОВАЦ, ДРЕН",
        value: "ОБРЕНОВАЦ_ДРЕН_70190_731633",
    },
    {
        label: "ОБРЕНОВАЦ, ЗАБРЕЖЈЕ",
        value: "ОБРЕНОВАЦ_ЗАБРЕЖЈЕ_70190_731641",
    },
    {
        label: "ОБРЕНОВАЦ, ЗВЕЧКА",
        value: "ОБРЕНОВАЦ_ЗВЕЧКА_70190_731650",
    },
    {
        label: "ОБРЕНОВАЦ, ЈАСЕНАК",
        value: "ОБРЕНОВАЦ_ЈАСЕНАК_70190_731668",
    },
    {
        label: "ОБРЕНОВАЦ, КОНАТИЦЕ",
        value: "ОБРЕНОВАЦ_КОНАТИЦЕ_70190_731684",
    },
    {
        label: "ОБРЕНОВАЦ, КРТИНСКА",
        value: "ОБРЕНОВАЦ_КРТИНСКА_70190_731692",
    },
    {
        label: "ОБРЕНОВАЦ, ЉУБИНИЋ",
        value: "ОБРЕНОВАЦ_ЉУБИНИЋ_70190_731714",
    },
    {
        label: "ОБРЕНОВАЦ, МАЛА МОШТАНИЦА",
        value: "ОБРЕНОВАЦ_МАЛА МОШТАНИЦА_70190_703834",
    },
    {
        label: "ОБРЕНОВАЦ, МИСЛОЂИН",
        value: "ОБРЕНОВАЦ_МИСЛОЂИН_70190_731749",
    },
    {
        label: "ОБРЕНОВАЦ, ОБРЕНОВАЦ",
        value: "ОБРЕНОВАЦ_ОБРЕНОВАЦ_70190_731757",
    },
    {
        label: "ОБРЕНОВАЦ, ОРАШАЦ",
        value: "ОБРЕНОВАЦ_ОРАШАЦ_70190_731765",
    },
    {
        label: "ОБРЕНОВАЦ, ПИРОМАН",
        value: "ОБРЕНОВАЦ_ПИРОМАН_70190_731773",
    },
    {
        label: "ОБРЕНОВАЦ, ПОЉАНЕ",
        value: "ОБРЕНОВАЦ_ПОЉАНЕ_70190_731781",
    },
    {
        label: "ОБРЕНОВАЦ, РАТАРИ",
        value: "ОБРЕНОВАЦ_РАТАРИ_70190_731790",
    },
    {
        label: "ОБРЕНОВАЦ, РВАТИ",
        value: "ОБРЕНОВАЦ_РВАТИ_70190_731803",
    },
    {
        label: "ОБРЕНОВАЦ, СКЕЛА",
        value: "ОБРЕНОВАЦ_СКЕЛА_70190_731811",
    },
    {
        label: "ОБРЕНОВАЦ, СТУБЛИНЕ",
        value: "ОБРЕНОВАЦ_СТУБЛИНЕ_70190_731820",
    },
    {
        label: "ОБРЕНОВАЦ, ТРСТЕНИЦА",
        value: "ОБРЕНОВАЦ_ТРСТЕНИЦА_70190_731838",
    },
    {
        label: "ОБРЕНОВАЦ, УРОВЦИ",
        value: "ОБРЕНОВАЦ_УРОВЦИ_70190_731846",
    },
    {
        label: "ОБРЕНОВАЦ, УШЋЕ",
        value: "ОБРЕНОВАЦ_УШЋЕ_70190_731854",
    },
    {
        label: "ОПОВО, БАРАНДА",
        value: "ОПОВО_БАРАНДА_80292_802573",
    },
    {
        label: "ОПОВО, ОПОВО",
        value: "ОПОВО_ОПОВО_80292_802751",
    },
    {
        label: "ОПОВО, САКУЛЕ",
        value: "ОПОВО_САКУЛЕ_80292_802808",
    },
    {
        label: "ОПОВО, СЕФКЕРИН",
        value: "ОПОВО_СЕФКЕРИН_80292_802492",
    },
    {
        label: "ОСЕЧИНА, БАСТАВ",
        value: "ОСЕЧИНА_БАСТАВ_70882_720127",
    },
    {
        label: "ОСЕЧИНА, БЕЛОТИЋ",
        value: "ОСЕЧИНА_БЕЛОТИЋ_70882_720143",
    },
    {
        label: "ОСЕЧИНА, БРАТАЧИЋ",
        value: "ОСЕЧИНА_БРАТАЧИЋ_70882_731897",
    },
    {
        label: "ОСЕЧИНА, ГОРЊЕ ЦРНИЉЕВО",
        value: "ОСЕЧИНА_ГОРЊЕ ЦРНИЉЕВО_70882_731951",
    },
    {
        label: "ОСЕЧИНА, ГУЊАЦИ",
        value: "ОСЕЧИНА_ГУЊАЦИ_70882_727423",
    },
    {
        label: "ОСЕЧИНА, ДРАГИЈЕВИЦА",
        value: "ОСЕЧИНА_ДРАГИЈЕВИЦА_70882_731978",
    },
    {
        label: "ОСЕЧИНА, ДРАГОДОЛ",
        value: "ОСЕЧИНА_ДРАГОДОЛ_70882_727482",
    },
    {
        label: "ОСЕЧИНА, КОМИРИЋ",
        value: "ОСЕЧИНА_КОМИРИЋ_70882_720224",
    },
    {
        label: "ОСЕЧИНА, КОЊУША",
        value: "ОСЕЧИНА_КОЊУША_70882_720232",
    },
    {
        label: "ОСЕЧИНА, ЛОПАТАЊ",
        value: "ОСЕЧИНА_ЛОПАТАЊ_70882_732001",
    },
    {
        label: "ОСЕЧИНА, ОСЕЧИНА",
        value: "ОСЕЧИНА_ОСЕЧИНА_70882_732044",
    },
    {
        label: "ОСЕЧИНА, ОСТРУЖАЊ",
        value: "ОСЕЧИНА_ОСТРУЖАЊ_70882_732079",
    },
    {
        label: "ОСЕЧИНА, ПЕЦКА",
        value: "ОСЕЧИНА_ПЕЦКА_70882_727555",
    },
    {
        label: "ОСЕЧИНА, ПЛУЖАЦ",
        value: "ОСЕЧИНА_ПЛУЖАЦ_70882_732087",
    },
    {
        label: "ОСЕЧИНА, СИРДИЈА",
        value: "ОСЕЧИНА_СИРДИЈА_70882_732109",
    },
    {
        label: "ОСЕЧИНА, ТУЂИН",
        value: "ОСЕЧИНА_ТУЂИН_70882_732150",
    },
    {
        label: "ОСЕЧИНА, ЦАРИНА",
        value: "ОСЕЧИНА_ЦАРИНА_70882_727636",
    },
    {
        label: "ОЏАЦИ, БАЧКИ БРЕСТОВАЦ",
        value: "ОЏАЦИ_БАЧКИ БРЕСТОВАЦ_80306_801577",
    },
    {
        label: "ОЏАЦИ, БАЧКИ ГРАЧАЦ",
        value: "ОЏАЦИ_БАЧКИ ГРАЧАЦ_80306_801585",
    },
    {
        label: "ОЏАЦИ, БОГОЈЕВО",
        value: "ОЏАЦИ_БОГОЈЕВО_80306_801593",
    },
    {
        label: "ОЏАЦИ, ДЕРОЊЕ",
        value: "ОЏАЦИ_ДЕРОЊЕ_80306_800201",
    },
    {
        label: "ОЏАЦИ, КАРАВУКОВО",
        value: "ОЏАЦИ_КАРАВУКОВО_80306_801607",
    },
    {
        label: "ОЏАЦИ, ЛАЛИЋ",
        value: "ОЏАЦИ_ЛАЛИЋ_80306_801640",
    },
    {
        label: "ОЏАЦИ, ОЏАЦИ",
        value: "ОЏАЦИ_ОЏАЦИ_80306_801666",
    },
    {
        label: "ОЏАЦИ, РАТКОВО",
        value: "ОЏАЦИ_РАТКОВО_80306_800104",
    },
    {
        label: "ОЏАЦИ, СРПСКИ МИЛЕТИЋ",
        value: "ОЏАЦИ_СРПСКИ МИЛЕТИЋ_80306_801704",
    },
    {
        label: "ПАЛИЛУЛА (БЕОГРАД), БЕСНИ ФОК",
        value: "ПАЛИЛУЛА (БЕОГРАД)_БЕСНИ ФОК_70203_732176",
    },
    {
        label: "ПАЛИЛУЛА (БЕОГРАД), БОРЧА",
        value: "ПАЛИЛУЛА (БЕОГРАД)_БОРЧА_70203_732184",
    },
    {
        label: "ПАЛИЛУЛА (БЕОГРАД), ВЕЛИКО СЕЛО",
        value: "ПАЛИЛУЛА (БЕОГРАД)_ВЕЛИКО СЕЛО_70203_703591",
    },
    {
        label: "ПАЛИЛУЛА (БЕОГРАД), ВИШЊИЦА",
        value: "ПАЛИЛУЛА (БЕОГРАД)_ВИШЊИЦА_70203_703613",
    },
    {
        label: "ПАЛИЛУЛА (БЕОГРАД), КОВИЛОВО",
        value: "ПАЛИЛУЛА (БЕОГРАД)_КОВИЛОВО_70203_732192",
    },
    {
        label: "ПАЛИЛУЛА (БЕОГРАД), КОМАРЕВА ХУМКА",
        value: "ПАЛИЛУЛА (БЕОГРАД)_КОМАРЕВА ХУМКА_70203_732206",
    },
    {
        label: "ПАЛИЛУЛА (БЕОГРАД), КРЊАЧА",
        value: "ПАЛИЛУЛА (БЕОГРАД)_КРЊАЧА_70203_732214",
    },
    {
        label: "ПАЛИЛУЛА (БЕОГРАД), ЛЕПУШНИЦА",
        value: "ПАЛИЛУЛА (БЕОГРАД)_ЛЕПУШНИЦА_70203_732222",
    },
    {
        label: "ПАЛИЛУЛА (БЕОГРАД), ОВЧА",
        value: "ПАЛИЛУЛА (БЕОГРАД)_ОВЧА_70203_732249",
    },
    {
        label: "ПАЛИЛУЛА (БЕОГРАД), ПАЛИЛУЛА",
        value: "ПАЛИЛУЛА (БЕОГРАД)_ПАЛИЛУЛА_70203_703907",
    },
    {
        label: "ПАЛИЛУЛА (БЕОГРАД), СЛАНЦИ",
        value: "ПАЛИЛУЛА (БЕОГРАД)_СЛАНЦИ_70203_704016",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), БЕРБАТОВО",
        value: "ПАЛИЛУЛА (НИШ)_БЕРБАТОВО_71323_729060",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), БУБАЊ",
        value: "ПАЛИЛУЛА (НИШ)_БУБАЊ_71323_729108",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), ВУКМАНОВО",
        value: "ПАЛИЛУЛА (НИШ)_ВУКМАНОВО_71323_729175",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), ГАБРОВАЦ",
        value: "ПАЛИЛУЛА (НИШ)_ГАБРОВАЦ_71323_729183",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), ГОРЊЕ МЕЂУРОВО",
        value: "ПАЛИЛУЛА (НИШ)_ГОРЊЕ МЕЂУРОВО_71323_729272",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), ДОЊЕ ВЛАСЕ",
        value: "ПАЛИЛУЛА (НИШ)_ДОЊЕ ВЛАСЕ_71323_729396",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), ДОЊЕ МЕЂУРОВО",
        value: "ПАЛИЛУЛА (НИШ)_ДОЊЕ МЕЂУРОВО_71323_729418",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), КРУШЦЕ",
        value: "ПАЛИЛУЛА (НИШ)_КРУШЦЕ_71323_715280",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), ЛАЛИНАЦ",
        value: "ПАЛИЛУЛА (НИШ)_ЛАЛИНАЦ_71323_729655",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), МРАМОР",
        value: "ПАЛИЛУЛА (НИШ)_МРАМОР_71323_715344",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), НОВО СЕЛО",
        value: "ПАЛИЛУЛА (НИШ)_НОВО СЕЛО_71323_729841",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), ПАСИ ПОЉАНА",
        value: "ПАЛИЛУЛА (НИШ)_ПАСИ ПОЉАНА_71323_729892",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), СУВИ ДО",
        value: "ПАЛИЛУЛА (НИШ)_СУВИ ДО_71323_745251",
    },
    {
        label: "ПАЛИЛУЛА (НИШ), ЧОКОТ",
        value: "ПАЛИЛУЛА (НИШ)_ЧОКОТ_71323_730165",
    },
    {
        label: "ПАНТЕЛЕЈ, БРЕНИЦА",
        value: "ПАНТЕЛЕЈ_БРЕНИЦА_71307_729086",
    },
    {
        label: "ПАНТЕЛЕЈ, ВРЕЛО",
        value: "ПАНТЕЛЕЈ_ВРЕЛО_71307_729159",
    },
    {
        label: "ПАНТЕЛЕЈ, ГОРЊА ВРЕЖИНА",
        value: "ПАНТЕЛЕЈ_ГОРЊА ВРЕЖИНА_71307_729213",
    },
    {
        label: "ПАНТЕЛЕЈ, ГОРЊИ МАТЕЈЕВАЦ",
        value: "ПАНТЕЛЕЈ_ГОРЊИ МАТЕЈЕВАЦ_71307_729337",
    },
    {
        label: "ПАНТЕЛЕЈ, ДОЊА ВРЕЖИНА",
        value: "ПАНТЕЛЕЈ_ДОЊА ВРЕЖИНА_71307_729353",
    },
    {
        label: "ПАНТЕЛЕЈ, ДОЊИ МАТЕЈЕВАЦ I",
        value: "ПАНТЕЛЕЈ_ДОЊИ МАТЕЈЕВАЦ I_71307_729469",
    },
    {
        label: "ПАНТЕЛЕЈ, ДОЊИ МАТЕЈЕВАЦ II",
        value: "ПАНТЕЛЕЈ_ДОЊИ МАТЕЈЕВАЦ II_71307_729477",
    },
    {
        label: "ПАНТЕЛЕЈ, ЈАСЕНОВИК",
        value: "ПАНТЕЛЕЈ_ЈАСЕНОВИК_71307_729515",
    },
    {
        label: "ПАНТЕЛЕЈ, КАМЕНИЦА",
        value: "ПАНТЕЛЕЈ_КАМЕНИЦА_71307_729540",
    },
    {
        label: "ПАНТЕЛЕЈ, КНЕЗ СЕЛО",
        value: "ПАНТЕЛЕЈ_КНЕЗ СЕЛО_71307_729574",
    },
    {
        label: "ПАНТЕЛЕЈ, МАЛЧА",
        value: "ПАНТЕЛЕЈ_МАЛЧА_71307_729710",
    },
    {
        label: 'ПАНТЕЛЕЈ, НИШ "ПАНТЕЛЕЈ"',
        value: 'ПАНТЕЛЕЈ_НИШ "ПАНТЕЛЕЈ"_71307_729787',
    },
    {
        label: "ПАНТЕЛЕЈ, ОРЕОВАЦ",
        value: "ПАНТЕЛЕЈ_ОРЕОВАЦ_71307_729868",
    },
    {
        label: "ПАНТЕЛЕЈ, ПАСЈАЧА",
        value: "ПАНТЕЛЕЈ_ПАСЈАЧА_71307_729906",
    },
    {
        label: "ПАНТЕЛЕЈ, ЦЕРЈЕ",
        value: "ПАНТЕЛЕЈ_ЦЕРЈЕ_71307_730106",
    },
    {
        label: "ПАНЧЕВО, БАНАТСКИ БРЕСТОВАЦ",
        value: "ПАНЧЕВО_БАНАТСКИ БРЕСТОВАЦ_80314_802328",
    },
    {
        label: "ПАНЧЕВО, БАНАТСКО НОВО СЕЛО",
        value: "ПАНЧЕВО_БАНАТСКО НОВО СЕЛО_80314_802336",
    },
    {
        label: "ПАНЧЕВО, ВОЈЛОВИЦА",
        value: "ПАНЧЕВО_ВОЈЛОВИЦА_80314_802549",
    },
    {
        label: "ПАНЧЕВО, ГЛОГОЊ",
        value: "ПАНЧЕВО_ГЛОГОЊ_80314_802387",
    },
    {
        label: "ПАНЧЕВО, ДОЛОВО",
        value: "ПАНЧЕВО_ДОЛОВО_80314_802379",
    },
    {
        label: "ПАНЧЕВО, ИВАНОВО",
        value: "ПАНЧЕВО_ИВАНОВО_80314_802409",
    },
    {
        label: "ПАНЧЕВО, ЈАБУКА",
        value: "ПАНЧЕВО_ЈАБУКА_80314_802417",
    },
    {
        label: "ПАНЧЕВО, КАЧАРЕВО",
        value: "ПАНЧЕВО_КАЧАРЕВО_80314_802441",
    },
    {
        label: "ПАНЧЕВО, ОМОЉИЦА",
        value: "ПАНЧЕВО_ОМОЉИЦА_80314_802468",
    },
    {
        label: "ПАНЧЕВО, ПАНЧЕВО",
        value: "ПАНЧЕВО_ПАНЧЕВО_80314_802484",
    },
    {
        label: "ПАНЧЕВО, СТАРЧЕВО",
        value: "ПАНЧЕВО_СТАРЧЕВО_80314_802514",
    },
    {
        label: "ПАРАЋИН, БОШЊАНЕ",
        value: "ПАРАЋИН_БОШЊАНЕ_70904_732273",
    },
    {
        label: "ПАРАЋИН, БУЉАНЕ",
        value: "ПАРАЋИН_БУЉАНЕ_70904_732281",
    },
    {
        label: "ПАРАЋИН, БУСИЛОВАЦ",
        value: "ПАРАЋИН_БУСИЛОВАЦ_70904_732290",
    },
    {
        label: "ПАРАЋИН, ГЛАВИЦА",
        value: "ПАРАЋИН_ГЛАВИЦА_70904_732311",
    },
    {
        label: "ПАРАЋИН, ГОЛУБОВАЦ",
        value: "ПАРАЋИН_ГОЛУБОВАЦ_70904_732320",
    },
    {
        label: "ПАРАЋИН, ГОРЊА МУТНИЦА",
        value: "ПАРАЋИН_ГОРЊА МУТНИЦА_70904_732338",
    },
    {
        label: "ПАРАЋИН, ГОРЊЕ ВИДОВО I",
        value: "ПАРАЋИН_ГОРЊЕ ВИДОВО I_70904_732346",
    },
    {
        label: "ПАРАЋИН, ГОРЊЕ ВИДОВО II",
        value: "ПАРАЋИН_ГОРЊЕ ВИДОВО II_70904_732354",
    },
    {
        label: "ПАРАЋИН, ДАВИДОВАЦ",
        value: "ПАРАЋИН_ДАВИДОВАЦ_70904_732362",
    },
    {
        label: "ПАРАЋИН, ДОЊА МУТНИЦА",
        value: "ПАРАЋИН_ДОЊА МУТНИЦА_70904_732389",
    },
    {
        label: "ПАРАЋИН, ДОЊЕ ВИДОВО",
        value: "ПАРАЋИН_ДОЊЕ ВИДОВО_70904_732397",
    },
    {
        label: "ПАРАЋИН, ДРЕНОВАЦ",
        value: "ПАРАЋИН_ДРЕНОВАЦ_70904_732419",
    },
    {
        label: "ПАРАЋИН, ЗАБРЕГА",
        value: "ПАРАЋИН_ЗАБРЕГА_70904_732427",
    },
    {
        label: "ПАРАЋИН, ИЗВОР",
        value: "ПАРАЋИН_ИЗВОР_70904_732443",
    },
    {
        label: "ПАРАЋИН, КЛАЧЕВИЦА",
        value: "ПАРАЋИН_КЛАЧЕВИЦА_70904_732451",
    },
    {
        label: "ПАРАЋИН, КРЕЖБИНАЦ",
        value: "ПАРАЋИН_КРЕЖБИНАЦ_70904_732460",
    },
    {
        label: "ПАРАЋИН, ЛЕБИНА",
        value: "ПАРАЋИН_ЛЕБИНА_70904_732486",
    },
    {
        label: "ПАРАЋИН, ЛЕШЈЕ",
        value: "ПАРАЋИН_ЛЕШЈЕ_70904_732494",
    },
    {
        label: "ПАРАЋИН, МИРИЛОВАЦ",
        value: "ПАРАЋИН_МИРИЛОВАЦ_70904_732508",
    },
    {
        label: "ПАРАЋИН, ПАРАЋИН",
        value: "ПАРАЋИН_ПАРАЋИН_70904_732524",
    },
    {
        label: "ПАРАЋИН, ПАРАЋИН-ГРАД",
        value: "ПАРАЋИН_ПАРАЋИН-ГРАД_70904_732532",
    },
    {
        label: "ПАРАЋИН, ПЛАНА",
        value: "ПАРАЋИН_ПЛАНА_70904_732559",
    },
    {
        label: "ПАРАЋИН, ПОПОВАЦ",
        value: "ПАРАЋИН_ПОПОВАЦ_70904_732567",
    },
    {
        label: "ПАРАЋИН, ПОТОЧАЦ",
        value: "ПАРАЋИН_ПОТОЧАЦ_70904_708518",
    },
    {
        label: "ПАРАЋИН, РАТАРЕ",
        value: "ПАРАЋИН_РАТАРЕ_70904_732575",
    },
    {
        label: "ПАРАЋИН, РАШЕВИЦА",
        value: "ПАРАЋИН_РАШЕВИЦА_70904_708526",
    },
    {
        label: "ПАРАЋИН, СВОЈНОВО",
        value: "ПАРАЋИН_СВОЈНОВО_70904_708534",
    },
    {
        label: "ПАРАЋИН, СИКИРИЦА",
        value: "ПАРАЋИН_СИКИРИЦА_70904_732605",
    },
    {
        label: "ПАРАЋИН, СИЊИ ВИР",
        value: "ПАРАЋИН_СИЊИ ВИР_70904_738417",
    },
    {
        label: "ПАРАЋИН, СТРИЖА",
        value: "ПАРАЋИН_СТРИЖА_70904_732613",
    },
    {
        label: "ПАРАЋИН, СТУБИЦА",
        value: "ПАРАЋИН_СТУБИЦА_70904_732621",
    },
    {
        label: "ПАРАЋИН, ТЕКИЈА",
        value: "ПАРАЋИН_ТЕКИЈА_70904_732648",
    },
    {
        label: "ПАРАЋИН, ТРЕШЊЕВИЦА",
        value: "ПАРАЋИН_ТРЕШЊЕВИЦА_70904_738450",
    },
    {
        label: "ПАРАЋИН, ЧЕПУРЕ",
        value: "ПАРАЋИН_ЧЕПУРЕ_70904_732672",
    },
    {
        label: "ПАРАЋИН, ШАВАЦ",
        value: "ПАРАЋИН_ШАВАЦ_70904_732699",
    },
    {
        label: "ПАРАЋИН, ШАЛУДОВАЦ",
        value: "ПАРАЋИН_ШАЛУДОВАЦ_70904_732702",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, БИСТРИЦА",
        value: "ПЕТРОВАЦ НА МЛАВИ_БИСТРИЦА_70912_732729",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, БОШЊАК",
        value: "ПЕТРОВАЦ НА МЛАВИ_БОШЊАК_70912_714151",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, БУРОВАЦ",
        value: "ПЕТРОВАЦ НА МЛАВИ_БУРОВАЦ_70912_732737",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, БУСУР",
        value: "ПЕТРОВАЦ НА МЛАВИ_БУСУР_70912_732745",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ВЕЗИЧЕВО",
        value: "ПЕТРОВАЦ НА МЛАВИ_ВЕЗИЧЕВО_70912_732753",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ВЕЛИКИ ПОПОВАЦ",
        value: "ПЕТРОВАЦ НА МЛАВИ_ВЕЛИКИ ПОПОВАЦ_70912_732761",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ВЕЛИКО ЛАОЛЕ",
        value: "ПЕТРОВАЦ НА МЛАВИ_ВЕЛИКО ЛАОЛЕ_70912_732770",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ВИТОВНИЦА",
        value: "ПЕТРОВАЦ НА МЛАВИ_ВИТОВНИЦА_70912_732788",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ВОШАНОВАЦ",
        value: "ПЕТРОВАЦ НА МЛАВИ_ВОШАНОВАЦ_70912_732796",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ДОБРЊЕ",
        value: "ПЕТРОВАЦ НА МЛАВИ_ДОБРЊЕ_70912_732800",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ДУБОЧКА",
        value: "ПЕТРОВАЦ НА МЛАВИ_ДУБОЧКА_70912_732818",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ЖДРЕЛО",
        value: "ПЕТРОВАЦ НА МЛАВИ_ЖДРЕЛО_70912_732826",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ЗАБРЂЕ",
        value: "ПЕТРОВАЦ НА МЛАВИ_ЗАБРЂЕ_70912_732834",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, КАМЕНОВО",
        value: "ПЕТРОВАЦ НА МЛАВИ_КАМЕНОВО_70912_732869",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, КЛАДУРОВО",
        value: "ПЕТРОВАЦ НА МЛАВИ_КЛАДУРОВО_70912_732877",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, КНЕЖИЦА",
        value: "ПЕТРОВАЦ НА МЛАВИ_КНЕЖИЦА_70912_732885",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, КРВИЈЕ",
        value: "ПЕТРОВАЦ НА МЛАВИ_КРВИЈЕ_70912_732907",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ЛЕСКОВАЦ",
        value: "ПЕТРОВАЦ НА МЛАВИ_ЛЕСКОВАЦ_70912_732915",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ЛОПУШНИК",
        value: "ПЕТРОВАЦ НА МЛАВИ_ЛОПУШНИК_70912_732923",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, МАЛО ЛАОЛЕ",
        value: "ПЕТРОВАЦ НА МЛАВИ_МАЛО ЛАОЛЕ_70912_732931",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, МАНАСТИРИЦА",
        value: "ПЕТРОВАЦ НА МЛАВИ_МАНАСТИРИЦА_70912_732940",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, МЕЛНИЦА",
        value: "ПЕТРОВАЦ НА МЛАВИ_МЕЛНИЦА_70912_732958",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ОРЕШКОВИЦА",
        value: "ПЕТРОВАЦ НА МЛАВИ_ОРЕШКОВИЦА_70912_732966",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ОРЉЕВО",
        value: "ПЕТРОВАЦ НА МЛАВИ_ОРЉЕВО_70912_714267",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ПАНКОВО",
        value: "ПЕТРОВАЦ НА МЛАВИ_ПАНКОВО_70912_732974",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ПЕТРОВАЦ НА МЛАВИ",
        value: "ПЕТРОВАЦ НА МЛАВИ_ПЕТРОВАЦ НА МЛАВИ_70912_732982",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, РАНОВАЦ",
        value: "ПЕТРОВАЦ НА МЛАВИ_РАНОВАЦ_70912_733008",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, РАШАНАЦ",
        value: "ПЕТРОВАЦ НА МЛАВИ_РАШАНАЦ_70912_733016",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, СТАМНИЦА",
        value: "ПЕТРОВАЦ НА МЛАВИ_СТАМНИЦА_70912_733024",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, СТАРЧЕВО",
        value: "ПЕТРОВАЦ НА МЛАВИ_СТАРЧЕВО_70912_733032",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ТАБАНОВАЦ",
        value: "ПЕТРОВАЦ НА МЛАВИ_ТАБАНОВАЦ_70912_733059",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ТРНОВЧЕ",
        value: "ПЕТРОВАЦ НА МЛАВИ_ТРНОВЧЕ_70912_733067",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ЋОВДИН",
        value: "ПЕТРОВАЦ НА МЛАВИ_ЋОВДИН_70912_733075",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ, ШЕТОЊЕ",
        value: "ПЕТРОВАЦ НА МЛАВИ_ШЕТОЊЕ_70912_733091",
    },
    {
        label: "ПЕЋИНЦИ, АШАЊА",
        value: "ПЕЋИНЦИ_АШАЊА_80322_805173",
    },
    {
        label: "ПЕЋИНЦИ, БРЕСТАЧ",
        value: "ПЕЋИНЦИ_БРЕСТАЧ_80322_802948",
    },
    {
        label: "ПЕЋИНЦИ, ДЕЧ",
        value: "ПЕЋИНЦИ_ДЕЧ_80322_805220",
    },
    {
        label: "ПЕЋИНЦИ, ДОЊИ ТОВАРНИК",
        value: "ПЕЋИНЦИ_ДОЊИ ТОВАРНИК_80322_804070",
    },
    {
        label: "ПЕЋИНЦИ, КАРЛОВЧИЋ",
        value: "ПЕЋИНЦИ_КАРЛОВЧИЋ_80322_805254",
    },
    {
        label: "ПЕЋИНЦИ, КУПИНОВО",
        value: "ПЕЋИНЦИ_КУПИНОВО_80322_805297",
    },
    {
        label: "ПЕЋИНЦИ, ОБРЕЖ",
        value: "ПЕЋИНЦИ_ОБРЕЖ_80322_805351",
    },
    {
        label: "ПЕЋИНЦИ, ОГАР",
        value: "ПЕЋИНЦИ_ОГАР_80322_804215",
    },
    {
        label: "ПЕЋИНЦИ, ПЕЋИНЦИ",
        value: "ПЕЋИНЦИ_ПЕЋИНЦИ_80322_805360",
    },
    {
        label: "ПЕЋИНЦИ, ПОПИНЦИ",
        value: "ПЕЋИНЦИ_ПОПИНЦИ_80322_805386",
    },
    {
        label: "ПЕЋИНЦИ, ПРХОВО",
        value: "ПЕЋИНЦИ_ПРХОВО_80322_805394",
    },
    {
        label: "ПЕЋИНЦИ, СИБАЧ",
        value: "ПЕЋИНЦИ_СИБАЧ_80322_803294",
    },
    {
        label: "ПЕЋИНЦИ, СРЕМСКИ МИХАЉЕВЦИ",
        value: "ПЕЋИНЦИ_СРЕМСКИ МИХАЉЕВЦИ_80322_805416",
    },
    {
        label: "ПЕЋИНЦИ, СУБОТИШТЕ",
        value: "ПЕЋИНЦИ_СУБОТИШТЕ_80322_803332",
    },
    {
        label: "ПЕЋИНЦИ, ШИМАНОВЦИ",
        value: "ПЕЋИНЦИ_ШИМАНОВЦИ_80322_805475",
    },
    {
        label: "ПИРОТ, БАЗОВИК",
        value: "ПИРОТ_БАЗОВИК_70939_733105",
    },
    {
        label: "ПИРОТ, БАРЈЕ ЧИФЛИК",
        value: "ПИРОТ_БАРЈЕ ЧИФЛИК_70939_733113",
    },
    {
        label: "ПИРОТ, БАСАРА",
        value: "ПИРОТ_БАСАРА_70939_733121",
    },
    {
        label: "ПИРОТ, БЕЛА",
        value: "ПИРОТ_БЕЛА_70939_733130",
    },
    {
        label: "ПИРОТ, БЕРИЛОВАЦ",
        value: "ПИРОТ_БЕРИЛОВАЦ_70939_733148",
    },
    {
        label: "ПИРОТ, БЕРОВИЦА",
        value: "ПИРОТ_БЕРОВИЦА_70939_733156",
    },
    {
        label: "ПИРОТ, БЛАТО",
        value: "ПИРОТ_БЛАТО_70939_733164",
    },
    {
        label: "ПИРОТ, БРЛОГ",
        value: "ПИРОТ_БРЛОГ_70939_733172",
    },
    {
        label: "ПИРОТ, ВЕЛИКА ЛУКАЊА",
        value: "ПИРОТ_ВЕЛИКА ЛУКАЊА_70939_733199",
    },
    {
        label: "ПИРОТ, ВЕЛИКИ ЈОВАНОВАЦ",
        value: "ПИРОТ_ВЕЛИКИ ЈОВАНОВАЦ_70939_733202",
    },
    {
        label: "ПИРОТ, ВЕЛИКИ СУВОДОЛ",
        value: "ПИРОТ_ВЕЛИКИ СУВОДОЛ_70939_733229",
    },
    {
        label: "ПИРОТ, ВЕЛИКО СЕЛО",
        value: "ПИРОТ_ВЕЛИКО СЕЛО_70939_733237",
    },
    {
        label: "ПИРОТ, ВИСОЧКА РЖАНА",
        value: "ПИРОТ_ВИСОЧКА РЖАНА_70939_733245",
    },
    {
        label: "ПИРОТ, ВЛАСИ",
        value: "ПИРОТ_ВЛАСИ_70939_713732",
    },
    {
        label: "ПИРОТ, ВОЈНЕГОВАЦ",
        value: "ПИРОТ_ВОЈНЕГОВАЦ_70939_733253",
    },
    {
        label: "ПИРОТ, ВРАНИШТЕ",
        value: "ПИРОТ_ВРАНИШТЕ_70939_733261",
    },
    {
        label: "ПИРОТ, ГЊИЛАН",
        value: "ПИРОТ_ГЊИЛАН_70939_733270",
    },
    {
        label: "ПИРОТ, ГОРЊА ДРЖИНА",
        value: "ПИРОТ_ГОРЊА ДРЖИНА_70939_713783",
    },
    {
        label: "ПИРОТ, ГОСТУША",
        value: "ПИРОТ_ГОСТУША_70939_733288",
    },
    {
        label: "ПИРОТ, ГРАДАШНИЦА",
        value: "ПИРОТ_ГРАДАШНИЦА_70939_733296",
    },
    {
        label: "ПИРОТ, ГРАДИШТЕ",
        value: "ПИРОТ_ГРАДИШТЕ_70939_733300",
    },
    {
        label: "ПИРОТ, ДОБРИ ДО",
        value: "ПИРОТ_ДОБРИ ДО_70939_733318",
    },
    {
        label: "ПИРОТ, ДОЈКИНЦИ",
        value: "ПИРОТ_ДОЈКИНЦИ_70939_733326",
    },
    {
        label: "ПИРОТ, ДРЖИНА",
        value: "ПИРОТ_ДРЖИНА_70939_733334",
    },
    {
        label: "ПИРОТ, ЗАВОЈ",
        value: "ПИРОТ_ЗАВОЈ_70939_733342",
    },
    {
        label: "ПИРОТ, ЗАСКОВЦИ",
        value: "ПИРОТ_ЗАСКОВЦИ_70939_733369",
    },
    {
        label: "ПИРОТ, ИЗВОР",
        value: "ПИРОТ_ИЗВОР_70939_733377",
    },
    {
        label: "ПИРОТ, ЈАЛБОТИНА",
        value: "ПИРОТ_ЈАЛБОТИНА_70939_733385",
    },
    {
        label: "ПИРОТ, ЈЕЛОВИЦА",
        value: "ПИРОТ_ЈЕЛОВИЦА_70939_733393",
    },
    {
        label: "ПИРОТ, КАМИК",
        value: "ПИРОТ_КАМИК_70939_733407",
    },
    {
        label: "ПИРОТ, КОПРИВШТИЦА",
        value: "ПИРОТ_КОПРИВШТИЦА_70939_733415",
    },
    {
        label: "ПИРОТ, КОСТУР",
        value: "ПИРОТ_КОСТУР_70939_733423",
    },
    {
        label: "ПИРОТ, КРУПАЦ",
        value: "ПИРОТ_КРУПАЦ_70939_733431",
    },
    {
        label: "ПИРОТ, КУМАНОВО",
        value: "ПИРОТ_КУМАНОВО_70939_733440",
    },
    {
        label: "ПИРОТ, МАЛА ЛУКАЊА",
        value: "ПИРОТ_МАЛА ЛУКАЊА_70939_733458",
    },
    {
        label: "ПИРОТ, МАЛИ ЈОВАНОВАЦ",
        value: "ПИРОТ_МАЛИ ЈОВАНОВАЦ_70939_733466",
    },
    {
        label: "ПИРОТ, МАЛИ СУВОДОЛ",
        value: "ПИРОТ_МАЛИ СУВОДОЛ_70939_733474",
    },
    {
        label: "ПИРОТ, МИЛОЈКОВАЦ",
        value: "ПИРОТ_МИЛОЈКОВАЦ_70939_733482",
    },
    {
        label: "ПИРОТ, МИРКОВЦИ",
        value: "ПИРОТ_МИРКОВЦИ_70939_733504",
    },
    {
        label: "ПИРОТ, НИШОР",
        value: "ПИРОТ_НИШОР_70939_733512",
    },
    {
        label: "ПИРОТ, ОБРЕНОВАЦ",
        value: "ПИРОТ_ОБРЕНОВАЦ_70939_733539",
    },
    {
        label: "ПИРОТ, ОРЕОВИЦА",
        value: "ПИРОТ_ОРЕОВИЦА_70939_733547",
    },
    {
        label: "ПИРОТ, ОРЉА",
        value: "ПИРОТ_ОРЉА_70939_733555",
    },
    {
        label: "ПИРОТ, ОСМАКОВО",
        value: "ПИРОТ_ОСМАКОВО_70939_733563",
    },
    {
        label: "ПИРОТ, ПАКЛЕШТИЦА",
        value: "ПИРОТ_ПАКЛЕШТИЦА_70939_733571",
    },
    {
        label: "ПИРОТ, ПАСЈАЧ",
        value: "ПИРОТ_ПАСЈАЧ_70939_733580",
    },
    {
        label: "ПИРОТ, ПЕТРОВАЦ",
        value: "ПИРОТ_ПЕТРОВАЦ_70939_733598",
    },
    {
        label: "ПИРОТ, ПИРОТ-ВАН ВАРОШ",
        value: "ПИРОТ_ПИРОТ-ВАН ВАРОШ_70939_733601",
    },
    {
        label: "ПИРОТ, ПИРОТ-ГРАД",
        value: "ПИРОТ_ПИРОТ-ГРАД_70939_733610",
    },
    {
        label: "ПИРОТ, ПЛАНИНИЦА",
        value: "ПИРОТ_ПЛАНИНИЦА_70939_733628",
    },
    {
        label: "ПИРОТ, ПОКРЕВЕНИК",
        value: "ПИРОТ_ПОКРЕВЕНИК_70939_733636",
    },
    {
        label: "ПИРОТ, ПОЉСКА РЖАНА",
        value: "ПИРОТ_ПОЉСКА РЖАНА_70939_733644",
    },
    {
        label: "ПИРОТ, ПОНОР",
        value: "ПИРОТ_ПОНОР_70939_733652",
    },
    {
        label: "ПИРОТ, ПРИСЈАН",
        value: "ПИРОТ_ПРИСЈАН_70939_733679",
    },
    {
        label: "ПИРОТ, РАГОДЕШ",
        value: "ПИРОТ_РАГОДЕШ_70939_733687",
    },
    {
        label: "ПИРОТ, РАСНИЦА",
        value: "ПИРОТ_РАСНИЦА_70939_733695",
    },
    {
        label: "ПИРОТ, РОСОМАЧ",
        value: "ПИРОТ_РОСОМАЧ_70939_733709",
    },
    {
        label: "ПИРОТ, РСОВЦИ",
        value: "ПИРОТ_РСОВЦИ_70939_733717",
    },
    {
        label: "ПИРОТ, РУДИЊЕ",
        value: "ПИРОТ_РУДИЊЕ_70939_733725",
    },
    {
        label: "ПИРОТ, СИЊА ГЛАВА",
        value: "ПИРОТ_СИЊА ГЛАВА_70939_733733",
    },
    {
        label: "ПИРОТ, СЛАВИЊА",
        value: "ПИРОТ_СЛАВИЊА_70939_714097",
    },
    {
        label: "ПИРОТ, СОПОТ",
        value: "ПИРОТ_СОПОТ_70939_733741",
    },
    {
        label: "ПИРОТ, СРЕЋКОВАЦ",
        value: "ПИРОТ_СРЕЋКОВАЦ_70939_733750",
    },
    {
        label: "ПИРОТ, СТАНИЧЕЊЕ",
        value: "ПИРОТ_СТАНИЧЕЊЕ_70939_733768",
    },
    {
        label: "ПИРОТ, СУКОВО",
        value: "ПИРОТ_СУКОВО_70939_733776",
    },
    {
        label: "ПИРОТ, ТЕМСКА",
        value: "ПИРОТ_ТЕМСКА_70939_733784",
    },
    {
        label: "ПИРОТ, ТОПЛИ ДО",
        value: "ПИРОТ_ТОПЛИ ДО_70939_733792",
    },
    {
        label: "ПИРОТ, ТРЊАНА",
        value: "ПИРОТ_ТРЊАНА_70939_733806",
    },
    {
        label: "ПИРОТ, ЦЕРЕВ ДЕЛ",
        value: "ПИРОТ_ЦЕРЕВ ДЕЛ_70939_733814",
    },
    {
        label: "ПИРОТ, ЦЕРОВА",
        value: "ПИРОТ_ЦЕРОВА_70939_733822",
    },
    {
        label: "ПИРОТ, ЦРВЕНЧЕВО",
        value: "ПИРОТ_ЦРВЕНЧЕВО_70939_733849",
    },
    {
        label: "ПИРОТ, ЦРНОКЛИШТЕ",
        value: "ПИРОТ_ЦРНОКЛИШТЕ_70939_733857",
    },
    {
        label: "ПИРОТ, ЧИНИГЛАВЦИ",
        value: "ПИРОТ_ЧИНИГЛАВЦИ_70939_733865",
    },
    {
        label: "ПИРОТ, ШУГРИН",
        value: "ПИРОТ_ШУГРИН_70939_733873",
    },
    {
        label: "ПЛАНДИШТЕ, БАНАТСКИ СОКОЛАЦ",
        value: "ПЛАНДИШТЕ_БАНАТСКИ СОКОЛАЦ_80349_801089",
    },
    {
        label: "ПЛАНДИШТЕ, БАРИЦЕ",
        value: "ПЛАНДИШТЕ_БАРИЦЕ_80349_801097",
    },
    {
        label: "ПЛАНДИШТЕ, ВЕЛИКА ГРЕДА",
        value: "ПЛАНДИШТЕ_ВЕЛИКА ГРЕДА_80349_801208",
    },
    {
        label: "ПЛАНДИШТЕ, ВЕЛИКИ ГАЈ",
        value: "ПЛАНДИШТЕ_ВЕЛИКИ ГАЈ_80349_801224",
    },
    {
        label: "ПЛАНДИШТЕ, ДУЖИНЕ",
        value: "ПЛАНДИШТЕ_ДУЖИНЕ_80349_801119",
    },
    {
        label: "ПЛАНДИШТЕ, ЈЕРМЕНОВЦИ",
        value: "ПЛАНДИШТЕ_ЈЕРМЕНОВЦИ_80349_801135",
    },
    {
        label: "ПЛАНДИШТЕ, КУПИНИК",
        value: "ПЛАНДИШТЕ_КУПИНИК_80349_801143",
    },
    {
        label: "ПЛАНДИШТЕ, МАРГИТА",
        value: "ПЛАНДИШТЕ_МАРГИТА_80349_801151",
    },
    {
        label: "ПЛАНДИШТЕ, МАРКОВИЋЕВО",
        value: "ПЛАНДИШТЕ_МАРКОВИЋЕВО_80349_801160",
    },
    {
        label: "ПЛАНДИШТЕ, МИЛЕТИЋЕВО",
        value: "ПЛАНДИШТЕ_МИЛЕТИЋЕВО_80349_801178",
    },
    {
        label: "ПЛАНДИШТЕ, ПЛАНДИШТЕ",
        value: "ПЛАНДИШТЕ_ПЛАНДИШТЕ_80349_801186",
    },
    {
        label: "ПЛАНДИШТЕ, СТАРИ ЛЕЦ",
        value: "ПЛАНДИШТЕ_СТАРИ ЛЕЦ_80349_801194",
    },
    {
        label: "ПЛАНДИШТЕ, ХАЈДУЧИЦА",
        value: "ПЛАНДИШТЕ_ХАЈДУЧИЦА_80349_801127",
    },
    {
        label: "ПОЖАРЕВАЦ, БАРЕ",
        value: "ПОЖАРЕВАЦ_БАРЕ_70947_733890",
    },
    {
        label: "ПОЖАРЕВАЦ, БАТОВАЦ",
        value: "ПОЖАРЕВАЦ_БАТОВАЦ_70947_733903",
    },
    {
        label: "ПОЖАРЕВАЦ, БЕРАЊЕ",
        value: "ПОЖАРЕВАЦ_БЕРАЊЕ_70947_733920",
    },
    {
        label: "ПОЖАРЕВАЦ, БРАДАРАЦ",
        value: "ПОЖАРЕВАЦ_БРАДАРАЦ_70947_733946",
    },
    {
        label: "ПОЖАРЕВАЦ, БРАТИНАЦ",
        value: "ПОЖАРЕВАЦ_БРАТИНАЦ_70947_733954",
    },
    {
        label: "ПОЖАРЕВАЦ, БРЕЖАНЕ",
        value: "ПОЖАРЕВАЦ_БРЕЖАНЕ_70947_733962",
    },
    {
        label: "ПОЖАРЕВАЦ, БУБУШИНАЦ",
        value: "ПОЖАРЕВАЦ_БУБУШИНАЦ_70947_733989",
    },
    {
        label: "ПОЖАРЕВАЦ, ДРАГОВАЦ",
        value: "ПОЖАРЕВАЦ_ДРАГОВАЦ_70947_734012",
    },
    {
        label: "ПОЖАРЕВАЦ, ДРМНО",
        value: "ПОЖАРЕВАЦ_ДРМНО_70947_734039",
    },
    {
        label: "ПОЖАРЕВАЦ, ДУБРАВИЦА",
        value: "ПОЖАРЕВАЦ_ДУБРАВИЦА_70947_734047",
    },
    {
        label: "ПОЖАРЕВАЦ, ЖИВИЦА",
        value: "ПОЖАРЕВАЦ_ЖИВИЦА_70947_734055",
    },
    {
        label: "ПОЖАРЕВАЦ, КАСИДОЛ",
        value: "ПОЖАРЕВАЦ_КАСИДОЛ_70947_734080",
    },
    {
        label: "ПОЖАРЕВАЦ, КЛИЧЕВАЦ",
        value: "ПОЖАРЕВАЦ_КЛИЧЕВАЦ_70947_708844",
    },
    {
        label: "ПОЖАРЕВАЦ, ЛУЧИЦА",
        value: "ПОЖАРЕВАЦ_ЛУЧИЦА_70947_734128",
    },
    {
        label: "ПОЖАРЕВАЦ, МАЉУРЕВАЦ",
        value: "ПОЖАРЕВАЦ_МАЉУРЕВАЦ_70947_734136",
    },
    {
        label: "ПОЖАРЕВАЦ, НАБРЂЕ",
        value: "ПОЖАРЕВАЦ_НАБРЂЕ_70947_734152",
    },
    {
        label: "ПОЖАРЕВАЦ, ПОЖАРЕВАЦ",
        value: "ПОЖАРЕВАЦ_ПОЖАРЕВАЦ_70947_734195",
    },
    {
        label: "ПОЖАРЕВАЦ, ПОЉАНА",
        value: "ПОЖАРЕВАЦ_ПОЉАНА_70947_734209",
    },
    {
        label: "ПОЖАРЕВАЦ, ПРУГОВО",
        value: "ПОЖАРЕВАЦ_ПРУГОВО_70947_745120",
    },
    {
        label: "ПОЖАРЕВАЦ, РЕЧИЦА",
        value: "ПОЖАРЕВАЦ_РЕЧИЦА_70947_709000",
    },
    {
        label: "ПОЖАРЕВАЦ, ТРЊАНЕ",
        value: "ПОЖАРЕВАЦ_ТРЊАНЕ_70947_734268",
    },
    {
        label: "ПОЖАРЕВАЦ, ЋИРИКОВАЦ",
        value: "ПОЖАРЕВАЦ_ЋИРИКОВАЦ_70947_734276",
    },
    {
        label: "ПОЖЕГА, БАКИОНИЦА",
        value: "ПОЖЕГА_БАКИОНИЦА_70955_743224",
    },
    {
        label: "ПОЖЕГА, ВЕЛИКА ЈЕЖЕВИЦА",
        value: "ПОЖЕГА_ВЕЛИКА ЈЕЖЕВИЦА_70955_743259",
    },
    {
        label: "ПОЖЕГА, ВИСИБАБА",
        value: "ПОЖЕГА_ВИСИБАБА_70955_743267",
    },
    {
        label: "ПОЖЕГА, ВРАЊАНИ",
        value: "ПОЖЕГА_ВРАЊАНИ_70955_743275",
    },
    {
        label: "ПОЖЕГА, ГЛУМАЧ",
        value: "ПОЖЕГА_ГЛУМАЧ_70955_743283",
    },
    {
        label: "ПОЖЕГА, ГОДОВИК",
        value: "ПОЖЕГА_ГОДОВИК_70955_743291",
    },
    {
        label: "ПОЖЕГА, ГОРЊА ДОБРИЊА",
        value: "ПОЖЕГА_ГОРЊА ДОБРИЊА_70955_743313",
    },
    {
        label: "ПОЖЕГА, ГОРОБИЉЕ",
        value: "ПОЖЕГА_ГОРОБИЉЕ_70955_743321",
    },
    {
        label: "ПОЖЕГА, ГУГАЉ",
        value: "ПОЖЕГА_ГУГАЉ_70955_743330",
    },
    {
        label: "ПОЖЕГА, ДОЊА ДОБРИЊА",
        value: "ПОЖЕГА_ДОЊА ДОБРИЊА_70955_743348",
    },
    {
        label: "ПОЖЕГА, ДРАЖИНОВИЋИ",
        value: "ПОЖЕГА_ДРАЖИНОВИЋИ_70955_743356",
    },
    {
        label: "ПОЖЕГА, ДУШКОВЦИ",
        value: "ПОЖЕГА_ДУШКОВЦИ_70955_743372",
    },
    {
        label: "ПОЖЕГА, ЗАСЕЉЕ",
        value: "ПОЖЕГА_ЗАСЕЉЕ_70955_743399",
    },
    {
        label: "ПОЖЕГА, ЗДРАВЧИЋИ",
        value: "ПОЖЕГА_ЗДРАВЧИЋИ_70955_743402",
    },
    {
        label: "ПОЖЕГА, ЈЕЛЕН ДО",
        value: "ПОЖЕГА_ЈЕЛЕН ДО_70955_743429",
    },
    {
        label: "ПОЖЕГА, КАЛЕНИЋИ",
        value: "ПОЖЕГА_КАЛЕНИЋИ_70955_743437",
    },
    {
        label: "ПОЖЕГА, ЛОПАШ",
        value: "ПОЖЕГА_ЛОПАШ_70955_726745",
    },
    {
        label: "ПОЖЕГА, ЛОРЕТ",
        value: "ПОЖЕГА_ЛОРЕТ_70955_743445",
    },
    {
        label: "ПОЖЕГА, ЉУТИЦЕ",
        value: "ПОЖЕГА_ЉУТИЦЕ_70955_743453",
    },
    {
        label: "ПОЖЕГА, МАЂЕР",
        value: "ПОЖЕГА_МАЂЕР_70955_743461",
    },
    {
        label: "ПОЖЕГА, МАЛА ЈЕЖЕВИЦА",
        value: "ПОЖЕГА_МАЛА ЈЕЖЕВИЦА_70955_743470",
    },
    {
        label: "ПОЖЕГА, МИЛИЋЕВО СЕЛО",
        value: "ПОЖЕГА_МИЛИЋЕВО СЕЛО_70955_743488",
    },
    {
        label: "ПОЖЕГА, МРШЕЉИ",
        value: "ПОЖЕГА_МРШЕЉИ_70955_743496",
    },
    {
        label: "ПОЖЕГА, ОТАЊ",
        value: "ПОЖЕГА_ОТАЊ_70955_743500",
    },
    {
        label: "ПОЖЕГА, ПАПРАТИШТЕ",
        value: "ПОЖЕГА_ПАПРАТИШТЕ_70955_743518",
    },
    {
        label: "ПОЖЕГА, ПИЛАТОВИЋИ",
        value: "ПОЖЕГА_ПИЛАТОВИЋИ_70955_726826",
    },
    {
        label: "ПОЖЕГА, ПОЖЕГА",
        value: "ПОЖЕГА_ПОЖЕГА_70955_743526",
    },
    {
        label: "ПОЖЕГА, ПРИЈАНОВИЋИ",
        value: "ПОЖЕГА_ПРИЈАНОВИЋИ_70955_743534",
    },
    {
        label: "ПОЖЕГА, ПРИЛИПАЦ",
        value: "ПОЖЕГА_ПРИЛИПАЦ_70955_726834",
    },
    {
        label: "ПОЖЕГА, РАДОВЦИ",
        value: "ПОЖЕГА_РАДОВЦИ_70955_743542",
    },
    {
        label: "ПОЖЕГА, РАСНА",
        value: "ПОЖЕГА_РАСНА_70955_743569",
    },
    {
        label: "ПОЖЕГА, РЕЧИЦЕ",
        value: "ПОЖЕГА_РЕЧИЦЕ_70955_743577",
    },
    {
        label: "ПОЖЕГА, РОГЕ",
        value: "ПОЖЕГА_РОГЕ_70955_743585",
    },
    {
        label: "ПОЖЕГА, РУПЕЉЕВО",
        value: "ПОЖЕГА_РУПЕЉЕВО_70955_743593",
    },
    {
        label: "ПОЖЕГА, СВРАЧКОВО",
        value: "ПОЖЕГА_СВРАЧКОВО_70955_743607",
    },
    {
        label: "ПОЖЕГА, СРЕДЊА ДОБРИЊА",
        value: "ПОЖЕГА_СРЕДЊА ДОБРИЊА_70955_743615",
    },
    {
        label: "ПОЖЕГА, ТАБАНОВИЋИ",
        value: "ПОЖЕГА_ТАБАНОВИЋИ_70955_743623",
    },
    {
        label: "ПОЖЕГА, ТВРДИЋИ",
        value: "ПОЖЕГА_ТВРДИЋИ_70955_743631",
    },
    {
        label: "ПОЖЕГА, ТОМЕТИНО ПОЉЕ",
        value: "ПОЖЕГА_ТОМЕТИНО ПОЉЕ_70955_743640",
    },
    {
        label: "ПОЖЕГА, ТУЧКОВО",
        value: "ПОЖЕГА_ТУЧКОВО_70955_743658",
    },
    {
        label: "ПОЖЕГА, УЗИЋИ",
        value: "ПОЖЕГА_УЗИЋИ_70955_743666",
    },
    {
        label: "ПОЖЕГА, ЧЕСТОБРОДИЦА",
        value: "ПОЖЕГА_ЧЕСТОБРОДИЦА_70955_743674",
    },
    {
        label: "ПРЕШЕВО, АЛИЂЕРЦЕ",
        value: "ПРЕШЕВО_АЛИЂЕРЦЕ_70963_706574",
    },
    {
        label: "ПРЕШЕВО, БЕРЧЕВАЦ",
        value: "ПРЕШЕВО_БЕРЧЕВАЦ_70963_706612",
    },
    {
        label: "ПРЕШЕВО, БУЈИЋ",
        value: "ПРЕШЕВО_БУЈИЋ_70963_706728",
    },
    {
        label: "ПРЕШЕВО, БУКАРЕВАЦ I",
        value: "ПРЕШЕВО_БУКАРЕВАЦ I_70963_706736",
    },
    {
        label: "ПРЕШЕВО, БУКАРЕВАЦ II",
        value: "ПРЕШЕВО_БУКАРЕВАЦ II_70963_706744",
    },
    {
        label: "ПРЕШЕВО, БУКОВАЦ",
        value: "ПРЕШЕВО_БУКОВАЦ_70963_706752",
    },
    {
        label: "ПРЕШЕВО, БУШТРАЊЕ",
        value: "ПРЕШЕВО_БУШТРАЊЕ_70963_706779",
    },
    {
        label: "ПРЕШЕВО, ГАРЕ",
        value: "ПРЕШЕВО_ГАРЕ_70963_706825",
    },
    {
        label: "ПРЕШЕВО, ГОЛЕМИ ДОЛ",
        value: "ПРЕШЕВО_ГОЛЕМИ ДОЛ_70963_706833",
    },
    {
        label: "ПРЕШЕВО, ГОРЊА ШУШАЈА",
        value: "ПРЕШЕВО_ГОРЊА ШУШАЈА_70963_706841",
    },
    {
        label: "ПРЕШЕВО, ГОСПОЂИНЦЕ",
        value: "ПРЕШЕВО_ГОСПОЂИНЦЕ_70963_706868",
    },
    {
        label: "ПРЕШЕВО, ДЕПЦЕ",
        value: "ПРЕШЕВО_ДЕПЦЕ_70963_706884",
    },
    {
        label: "ПРЕШЕВО, ДОЊА ШУШАЈА",
        value: "ПРЕШЕВО_ДОЊА ШУШАЈА_70963_706906",
    },
    {
        label: "ПРЕШЕВО, ЖУЈИНЦЕ I",
        value: "ПРЕШЕВО_ЖУЈИНЦЕ I_70963_706973",
    },
    {
        label: "ПРЕШЕВО, ЖУЈИНЦЕ II",
        value: "ПРЕШЕВО_ЖУЈИНЦЕ II_70963_706981",
    },
    {
        label: "ПРЕШЕВО, ИЛИНЦЕ",
        value: "ПРЕШЕВО_ИЛИНЦЕ_70963_707007",
    },
    {
        label: "ПРЕШЕВО, КУРБАЛИЈА",
        value: "ПРЕШЕВО_КУРБАЛИЈА_70963_707104",
    },
    {
        label: "ПРЕШЕВО, ЉАНИК",
        value: "ПРЕШЕВО_ЉАНИК_70963_707180",
    },
    {
        label: "ПРЕШЕВО, МАЂАРЕ",
        value: "ПРЕШЕВО_МАЂАРЕ_70963_707201",
    },
    {
        label: "ПРЕШЕВО, МИРАТОВАЦ",
        value: "ПРЕШЕВО_МИРАТОВАЦ_70963_707244",
    },
    {
        label: "ПРЕШЕВО, НОРЧА",
        value: "ПРЕШЕВО_НОРЧА_70963_707309",
    },
    {
        label: "ПРЕШЕВО, ОРАОВИЦА",
        value: "ПРЕШЕВО_ОРАОВИЦА_70963_707317",
    },
    {
        label: "ПРЕШЕВО, ПЕЧЕНО",
        value: "ПРЕШЕВО_ПЕЧЕНО_70963_707350",
    },
    {
        label: "ПРЕШЕВО, ПРЕШЕВО",
        value: "ПРЕШЕВО_ПРЕШЕВО_70963_707376",
    },
    {
        label: "ПРЕШЕВО, РАЈИНЦЕ",
        value: "ПРЕШЕВО_РАЈИНЦЕ_70963_707406",
    },
    {
        label: "ПРЕШЕВО, РАНАТОВЦЕ",
        value: "ПРЕШЕВО_РАНАТОВЦЕ_70963_707449",
    },
    {
        label: "ПРЕШЕВО, РЕЉАН",
        value: "ПРЕШЕВО_РЕЉАН_70963_707457",
    },
    {
        label: "ПРЕШЕВО, СВИЊИШТЕ",
        value: "ПРЕШЕВО_СВИЊИШТЕ_70963_707481",
    },
    {
        label: "ПРЕШЕВО, СЕФЕР",
        value: "ПРЕШЕВО_СЕФЕР_70963_707511",
    },
    {
        label: "ПРЕШЕВО, СЛАВУЈЕВАЦ",
        value: "ПРЕШЕВО_СЛАВУЈЕВАЦ_70963_707520",
    },
    {
        label: "ПРЕШЕВО, СТАНЕВЦЕ",
        value: "ПРЕШЕВО_СТАНЕВЦЕ_70963_707554",
    },
    {
        label: "ПРЕШЕВО, СТРЕЗОВЦЕ",
        value: "ПРЕШЕВО_СТРЕЗОВЦЕ_70963_707589",
    },
    {
        label: "ПРЕШЕВО, ТРНАВА",
        value: "ПРЕШЕВО_ТРНАВА_70963_707627",
    },
    {
        label: "ПРЕШЕВО, ЦАКАНОВАЦ",
        value: "ПРЕШЕВО_ЦАКАНОВАЦ_70963_707651",
    },
    {
        label: "ПРЕШЕВО, ЦЕРЕВАЈКА",
        value: "ПРЕШЕВО_ЦЕРЕВАЈКА_70963_707660",
    },
    {
        label: "ПРЕШЕВО, ЦРНОТИНЦЕ",
        value: "ПРЕШЕВО_ЦРНОТИНЦЕ_70963_707678",
    },
    {
        label: "ПРЕШЕВО, ЧУКАРКА",
        value: "ПРЕШЕВО_ЧУКАРКА_70963_707694",
    },
    {
        label: "ПРИБОЈ, БАЊА",
        value: "ПРИБОЈ_БАЊА_70971_734322",
    },
    {
        label: "ПРИБОЈ, БАТКОВИЋИ",
        value: "ПРИБОЈ_БАТКОВИЋИ_70971_734349",
    },
    {
        label: "ПРИБОЈ, БУЧЈЕ",
        value: "ПРИБОЈ_БУЧЈЕ_70971_734357",
    },
    {
        label: "ПРИБОЈ, ДОБРИЛОВИЋИ",
        value: "ПРИБОЈ_ДОБРИЛОВИЋИ_70971_734365",
    },
    {
        label: "ПРИБОЈ, ЗАБРЂЕ",
        value: "ПРИБОЈ_ЗАБРЂЕ_70971_734373",
    },
    {
        label: "ПРИБОЈ, ЗАБРЊИЦА",
        value: "ПРИБОЈ_ЗАБРЊИЦА_70971_734381",
    },
    {
        label: "ПРИБОЈ, КАЛАФАТИ",
        value: "ПРИБОЈ_КАЛАФАТИ_70971_734390",
    },
    {
        label: "ПРИБОЈ, КАЛУЂЕРОВИЋИ",
        value: "ПРИБОЈ_КАЛУЂЕРОВИЋИ_70971_734403",
    },
    {
        label: "ПРИБОЈ, КАСИДОЛИ",
        value: "ПРИБОЈ_КАСИДОЛИ_70971_734411",
    },
    {
        label: "ПРИБОЈ, КРАТОВО",
        value: "ПРИБОЈ_КРАТОВО_70971_734420",
    },
    {
        label: "ПРИБОЈ, КРЊАЧА",
        value: "ПРИБОЈ_КРЊАЧА_70971_734438",
    },
    {
        label: "ПРИБОЈ, МАЖИЋИ",
        value: "ПРИБОЈ_МАЖИЋИ_70971_734446",
    },
    {
        label: "ПРИБОЈ, МИЛИЈЕШ",
        value: "ПРИБОЈ_МИЛИЈЕШ_70971_734454",
    },
    {
        label: "ПРИБОЈ, ПРИБОЈ",
        value: "ПРИБОЈ_ПРИБОЈ_70971_734462",
    },
    {
        label: "ПРИБОЈ, ПРИБОЈСКА ГОЛЕША",
        value: "ПРИБОЈ_ПРИБОЈСКА ГОЛЕША_70971_734489",
    },
    {
        label: "ПРИБОЈ, ПРИБОЈСКЕ ЧЕЛИЦЕ",
        value: "ПРИБОЈ_ПРИБОЈСКЕ ЧЕЛИЦЕ_70971_734497",
    },
    {
        label: "ПРИБОЈ, РАЧА",
        value: "ПРИБОЈ_РАЧА_70971_734519",
    },
    {
        label: "ПРИБОЈ, РИТОШИЋИ",
        value: "ПРИБОЈ_РИТОШИЋИ_70971_734527",
    },
    {
        label: "ПРИБОЈ, СЈЕВЕРИН",
        value: "ПРИБОЈ_СЈЕВЕРИН_70971_734535",
    },
    {
        label: "ПРИБОЈ, СОЧИЦЕ",
        value: "ПРИБОЈ_СОЧИЦЕ_70971_734543",
    },
    {
        label: "ПРИБОЈ, СТРМАЦ",
        value: "ПРИБОЈ_СТРМАЦ_70971_734551",
    },
    {
        label: "ПРИБОЈ, ХЕРЦЕГОВАЧКА ГОЛЕША",
        value: "ПРИБОЈ_ХЕРЦЕГОВАЧКА ГОЛЕША_70971_734560",
    },
    {
        label: "ПРИБОЈ, ЦРНУЗИ",
        value: "ПРИБОЈ_ЦРНУЗИ_70971_734578",
    },
    {
        label: "ПРИБОЈ, ЧИТЛУК",
        value: "ПРИБОЈ_ЧИТЛУК_70971_734586",
    },
    {
        label: "ПРИЈЕПОЉЕ, АЉИНОВИЋИ",
        value: "ПРИЈЕПОЉЕ_АЉИНОВИЋИ_70980_739243",
    },
    {
        label: "ПРИЈЕПОЉЕ, БАЛИЋИ",
        value: "ПРИЈЕПОЉЕ_БАЛИЋИ_70980_734594",
    },
    {
        label: "ПРИЈЕПОЉЕ, БАРЕ",
        value: "ПРИЈЕПОЉЕ_БАРЕ_70980_734608",
    },
    {
        label: "ПРИЈЕПОЉЕ, БИСКУПИЋИ",
        value: "ПРИЈЕПОЉЕ_БИСКУПИЋИ_70980_734616",
    },
    {
        label: "ПРИЈЕПОЉЕ, БЈЕЛАХОВА",
        value: "ПРИЈЕПОЉЕ_БЈЕЛАХОВА_70980_734624",
    },
    {
        label: "ПРИЈЕПОЉЕ, БРАЈКОВАЦ",
        value: "ПРИЈЕПОЉЕ_БРАЈКОВАЦ_70980_734632",
    },
    {
        label: "ПРИЈЕПОЉЕ, БРВИНЕ",
        value: "ПРИЈЕПОЉЕ_БРВИНЕ_70980_734659",
    },
    {
        label: "ПРИЈЕПОЉЕ, БРОДАРЕВО",
        value: "ПРИЈЕПОЉЕ_БРОДАРЕВО_70980_734667",
    },
    {
        label: "ПРИЈЕПОЉЕ, БУКОВИК",
        value: "ПРИЈЕПОЉЕ_БУКОВИК_70980_734675",
    },
    {
        label: "ПРИЈЕПОЉЕ, ВИНИЦКА",
        value: "ПРИЈЕПОЉЕ_ВИНИЦКА_70980_734683",
    },
    {
        label: "ПРИЈЕПОЉЕ, ВРБОВО",
        value: "ПРИЈЕПОЉЕ_ВРБОВО_70980_734691",
    },
    {
        label: "ПРИЈЕПОЉЕ, ГОЈАКОВИЋИ",
        value: "ПРИЈЕПОЉЕ_ГОЈАКОВИЋИ_70980_734705",
    },
    {
        label: "ПРИЈЕПОЉЕ, ГОРЊЕ БАБИНЕ",
        value: "ПРИЈЕПОЉЕ_ГОРЊЕ БАБИНЕ_70980_734713",
    },
    {
        label: "ПРИЈЕПОЉЕ, ГОРЊЕ ГОРАЧИЋЕ",
        value: "ПРИЈЕПОЉЕ_ГОРЊЕ ГОРАЧИЋЕ_70980_739367",
    },
    {
        label: "ПРИЈЕПОЉЕ, ГОРЊИ СТРАЊАНИ",
        value: "ПРИЈЕПОЉЕ_ГОРЊИ СТРАЊАНИ_70980_734721",
    },
    {
        label: "ПРИЈЕПОЉЕ, ГОСТУН",
        value: "ПРИЈЕПОЉЕ_ГОСТУН_70980_734730",
    },
    {
        label: "ПРИЈЕПОЉЕ, ГРАЧАНИЦА",
        value: "ПРИЈЕПОЉЕ_ГРАЧАНИЦА_70980_734748",
    },
    {
        label: "ПРИЈЕПОЉЕ, ГРОБНИЦЕ",
        value: "ПРИЈЕПОЉЕ_ГРОБНИЦЕ_70980_734756",
    },
    {
        label: "ПРИЈЕПОЉЕ, ДИВЦИ",
        value: "ПРИЈЕПОЉЕ_ДИВЦИ_70980_734764",
    },
    {
        label: "ПРИЈЕПОЉЕ, ДОЊЕ БАБИНЕ",
        value: "ПРИЈЕПОЉЕ_ДОЊЕ БАБИНЕ_70980_734772",
    },
    {
        label: "ПРИЈЕПОЉЕ, ДОЊИ СТРАЊАНИ",
        value: "ПРИЈЕПОЉЕ_ДОЊИ СТРАЊАНИ_70980_734799",
    },
    {
        label: "ПРИЈЕПОЉЕ, ДРЕНОВА",
        value: "ПРИЈЕПОЉЕ_ДРЕНОВА_70980_734802",
    },
    {
        label: "ПРИЈЕПОЉЕ, ДУШМАНИЋИ",
        value: "ПРИЈЕПОЉЕ_ДУШМАНИЋИ_70980_734829",
    },
    {
        label: "ПРИЈЕПОЉЕ, ЂУРАШИЋИ",
        value: "ПРИЈЕПОЉЕ_ЂУРАШИЋИ_70980_734837",
    },
    {
        label: "ПРИЈЕПОЉЕ, ЗАБРДЊИ ТОЦИ",
        value: "ПРИЈЕПОЉЕ_ЗАБРДЊИ ТОЦИ_70980_734845",
    },
    {
        label: "ПРИЈЕПОЉЕ, ЗАВИНОГРАЂЕ",
        value: "ПРИЈЕПОЉЕ_ЗАВИНОГРАЂЕ_70980_734853",
    },
    {
        label: "ПРИЈЕПОЉЕ, ЗАЛУГ",
        value: "ПРИЈЕПОЉЕ_ЗАЛУГ_70980_734861",
    },
    {
        label: "ПРИЈЕПОЉЕ, ЗАСТУП",
        value: "ПРИЈЕПОЉЕ_ЗАСТУП_70980_734870",
    },
    {
        label: "ПРИЈЕПОЉЕ, ЗВИЈЕЗД",
        value: "ПРИЈЕПОЉЕ_ЗВИЈЕЗД_70980_734888",
    },
    {
        label: "ПРИЈЕПОЉЕ, ИВАЊЕ",
        value: "ПРИЈЕПОЉЕ_ИВАЊЕ_70980_734896",
    },
    {
        label: "ПРИЈЕПОЉЕ, ИВЕЗИЋИ",
        value: "ПРИЈЕПОЉЕ_ИВЕЗИЋИ_70980_734900",
    },
    {
        label: "ПРИЈЕПОЉЕ, ИЗБИЧАЊ",
        value: "ПРИЈЕПОЉЕ_ИЗБИЧАЊ_70980_734918",
    },
    {
        label: "ПРИЈЕПОЉЕ, ЈАБУКА",
        value: "ПРИЈЕПОЉЕ_ЈАБУКА_70980_734926",
    },
    {
        label: "ПРИЈЕПОЉЕ, ЈУНЧЕВИЋИ",
        value: "ПРИЈЕПОЉЕ_ЈУНЧЕВИЋИ_70980_734934",
    },
    {
        label: "ПРИЈЕПОЉЕ, КАМЕНА ГОРА",
        value: "ПРИЈЕПОЉЕ_КАМЕНА ГОРА_70980_734942",
    },
    {
        label: "ПРИЈЕПОЉЕ, КАРАУЛА",
        value: "ПРИЈЕПОЉЕ_КАРАУЛА_70980_739561",
    },
    {
        label: "ПРИЈЕПОЉЕ, КАРОШЕВИНА",
        value: "ПРИЈЕПОЉЕ_КАРОШЕВИНА_70980_734969",
    },
    {
        label: "ПРИЈЕПОЉЕ, КАЋЕВО",
        value: "ПРИЈЕПОЉЕ_КАЋЕВО_70980_734977",
    },
    {
        label: "ПРИЈЕПОЉЕ, КОВАЧЕВАЦ",
        value: "ПРИЈЕПОЉЕ_КОВАЧЕВАЦ_70980_734985",
    },
    {
        label: "ПРИЈЕПОЉЕ, КОЛОВРАТ",
        value: "ПРИЈЕПОЉЕ_КОЛОВРАТ_70980_734993",
    },
    {
        label: "ПРИЈЕПОЉЕ, КОПРИВНА",
        value: "ПРИЈЕПОЉЕ_КОПРИВНА_70980_735019",
    },
    {
        label: "ПРИЈЕПОЉЕ, КОСАТИЦА",
        value: "ПРИЈЕПОЉЕ_КОСАТИЦА_70980_735027",
    },
    {
        label: "ПРИЈЕПОЉЕ, КОШЕВИНЕ",
        value: "ПРИЈЕПОЉЕ_КОШЕВИНЕ_70980_735035",
    },
    {
        label: "ПРИЈЕПОЉЕ, КРУШЕВО",
        value: "ПРИЈЕПОЉЕ_КРУШЕВО_70980_735043",
    },
    {
        label: "ПРИЈЕПОЉЕ, КУЧИН",
        value: "ПРИЈЕПОЉЕ_КУЧИН_70980_735051",
    },
    {
        label: "ПРИЈЕПОЉЕ, МАТАРУГЕ",
        value: "ПРИЈЕПОЉЕ_МАТАРУГЕ_70980_735060",
    },
    {
        label: "ПРИЈЕПОЉЕ, МЕЂАНИ",
        value: "ПРИЈЕПОЉЕ_МЕЂАНИ_70980_735078",
    },
    {
        label: "ПРИЈЕПОЉЕ, МИЛАКОВИЋИ",
        value: "ПРИЈЕПОЉЕ_МИЛАКОВИЋИ_70980_735086",
    },
    {
        label: "ПРИЈЕПОЉЕ, МИЛЕШЕВО",
        value: "ПРИЈЕПОЉЕ_МИЛЕШЕВО_70980_735094",
    },
    {
        label: "ПРИЈЕПОЉЕ, МИЛОШЕВ ДО",
        value: "ПРИЈЕПОЉЕ_МИЛОШЕВ ДО_70980_739634",
    },
    {
        label: "ПРИЈЕПОЉЕ, МИЉЕВИЋИ",
        value: "ПРИЈЕПОЉЕ_МИЉЕВИЋИ_70980_735108",
    },
    {
        label: "ПРИЈЕПОЉЕ, МРЧКОВИНА",
        value: "ПРИЈЕПОЉЕ_МРЧКОВИНА_70980_735116",
    },
    {
        label: "ПРИЈЕПОЉЕ, МУШКОВИНА",
        value: "ПРИЈЕПОЉЕ_МУШКОВИНА_70980_735124",
    },
    {
        label: "ПРИЈЕПОЉЕ, ОРАОВАЦ",
        value: "ПРИЈЕПОЉЕ_ОРАОВАЦ_70980_735132",
    },
    {
        label: "ПРИЈЕПОЉЕ, ОРАШАЦ",
        value: "ПРИЈЕПОЉЕ_ОРАШАЦ_70980_735159",
    },
    {
        label: "ПРИЈЕПОЉЕ, ОСОЈЕ",
        value: "ПРИЈЕПОЉЕ_ОСОЈЕ_70980_735167",
    },
    {
        label: "ПРИЈЕПОЉЕ, ОШТРА СТИЈЕНА",
        value: "ПРИЈЕПОЉЕ_ОШТРА СТИЈЕНА_70980_735175",
    },
    {
        label: "ПРИЈЕПОЉЕ, ПОТКРШ",
        value: "ПРИЈЕПОЉЕ_ПОТКРШ_70980_735183",
    },
    {
        label: "ПРИЈЕПОЉЕ, ПОТОК",
        value: "ПРИЈЕПОЉЕ_ПОТОК_70980_735191",
    },
    {
        label: "ПРИЈЕПОЉЕ, ПРАВОШЕВО",
        value: "ПРИЈЕПОЉЕ_ПРАВОШЕВО_70980_735205",
    },
    {
        label: "ПРИЈЕПОЉЕ, ПРИЈЕПОЉЕ",
        value: "ПРИЈЕПОЉЕ_ПРИЈЕПОЉЕ_70980_735213",
    },
    {
        label: "ПРИЈЕПОЉЕ, РАСНО",
        value: "ПРИЈЕПОЉЕ_РАСНО_70980_735221",
    },
    {
        label: "ПРИЈЕПОЉЕ, РАТАЈСКА",
        value: "ПРИЈЕПОЉЕ_РАТАЈСКА_70980_735230",
    },
    {
        label: "ПРИЈЕПОЉЕ, СЕДОБРО",
        value: "ПРИЈЕПОЉЕ_СЕДОБРО_70980_735248",
    },
    {
        label: "ПРИЈЕПОЉЕ, СЕЉАНЕ",
        value: "ПРИЈЕПОЉЕ_СЕЉАНЕ_70980_735256",
    },
    {
        label: "ПРИЈЕПОЉЕ, СЕЉАШНИЦА",
        value: "ПРИЈЕПОЉЕ_СЕЉАШНИЦА_70980_735264",
    },
    {
        label: "ПРИЈЕПОЉЕ, СКОКУЋЕ",
        value: "ПРИЈЕПОЉЕ_СКОКУЋЕ_70980_735272",
    },
    {
        label: "ПРИЈЕПОЉЕ, СЛАТИНА",
        value: "ПРИЈЕПОЉЕ_СЛАТИНА_70980_735299",
    },
    {
        label: "ПРИЈЕПОЉЕ, СОПОТНИЦА",
        value: "ПРИЈЕПОЉЕ_СОПОТНИЦА_70980_735302",
    },
    {
        label: "ПРИЈЕПОЉЕ, ТАШЕВО",
        value: "ПРИЈЕПОЉЕ_ТАШЕВО_70980_735329",
    },
    {
        label: "ПРИЈЕПОЉЕ, ХИСАРЏИК",
        value: "ПРИЈЕПОЉЕ_ХИСАРЏИК_70980_735337",
    },
    {
        label: "ПРИЈЕПОЉЕ, ХРТА",
        value: "ПРИЈЕПОЉЕ_ХРТА_70980_735345",
    },
    {
        label: "ПРИЈЕПОЉЕ, ЦРКВЕНИ ТОЦИ",
        value: "ПРИЈЕПОЉЕ_ЦРКВЕНИ ТОЦИ_70980_735353",
    },
    {
        label: "ПРИЈЕПОЉЕ, ЧАУШЕВИЋИ",
        value: "ПРИЈЕПОЉЕ_ЧАУШЕВИЋИ_70980_735361",
    },
    {
        label: "ПРИЈЕПОЉЕ, ЏУРОВО",
        value: "ПРИЈЕПОЉЕ_ЏУРОВО_70980_735370",
    },
    {
        label: "ПРОКУПЉЕ, АРБАНАШКА",
        value: "ПРОКУПЉЕ_АРБАНАШКА_70998_735388",
    },
    {
        label: "ПРОКУПЉЕ, БАБИН ПОТОК",
        value: "ПРОКУПЉЕ_БАБИН ПОТОК_70998_714631",
    },
    {
        label: "ПРОКУПЉЕ, БАБОТИНАЦ",
        value: "ПРОКУПЉЕ_БАБОТИНАЦ_70998_735396",
    },
    {
        label: "ПРОКУПЉЕ, БАЈЧИНЦЕ",
        value: "ПРОКУПЉЕ_БАЈЧИНЦЕ_70998_714658",
    },
    {
        label: "ПРОКУПЉЕ, БАЛИНОВАЦ",
        value: "ПРОКУПЉЕ_БАЛИНОВАЦ_70998_714674",
    },
    {
        label: "ПРОКУПЉЕ, БАЛЧАК",
        value: "ПРОКУПЉЕ_БАЛЧАК_70998_714704",
    },
    {
        label: "ПРОКУПЉЕ, БАЦЕ",
        value: "ПРОКУПЉЕ_БАЦЕ_70998_735400",
    },
    {
        label: "ПРОКУПЉЕ, БЕЛА ВОДА",
        value: "ПРОКУПЉЕ_БЕЛА ВОДА_70998_735418",
    },
    {
        label: "ПРОКУПЉЕ, БЕЛИ КАМЕН",
        value: "ПРОКУПЉЕ_БЕЛИ КАМЕН_70998_714739",
    },
    {
        label: "ПРОКУПЉЕ, БЕЛОГОШ",
        value: "ПРОКУПЉЕ_БЕЛОГОШ_70998_735426",
    },
    {
        label: "ПРОКУПЉЕ, БЕЛОЉИН",
        value: "ПРОКУПЉЕ_БЕЛОЉИН_70998_735434",
    },
    {
        label: "ПРОКУПЉЕ, БЕРИЉЕ",
        value: "ПРОКУПЉЕ_БЕРИЉЕ_70998_714747",
    },
    {
        label: "ПРОКУПЉЕ, БОГУЈЕВАЦ",
        value: "ПРОКУПЉЕ_БОГУЈЕВАЦ_70998_714763",
    },
    {
        label: "ПРОКУПЉЕ, БРЕГОВИНА",
        value: "ПРОКУПЉЕ_БРЕГОВИНА_70998_714771",
    },
    {
        label: "ПРОКУПЉЕ, БРЕСНИК",
        value: "ПРОКУПЉЕ_БРЕСНИК_70998_735442",
    },
    {
        label: "ПРОКУПЉЕ, БРЕСНИЧИЋ",
        value: "ПРОКУПЉЕ_БРЕСНИЧИЋ_70998_735469",
    },
    {
        label: "ПРОКУПЉЕ, БУБЛИЦА",
        value: "ПРОКУПЉЕ_БУБЛИЦА_70998_714798",
    },
    {
        label: "ПРОКУПЉЕ, БУКОЛОРАМ",
        value: "ПРОКУПЉЕ_БУКОЛОРАМ_70998_735477",
    },
    {
        label: "ПРОКУПЉЕ, БУЛАТОВАЦ",
        value: "ПРОКУПЉЕ_БУЛАТОВАЦ_70998_735485",
    },
    {
        label: "ПРОКУПЉЕ, БУЧИНЦЕ",
        value: "ПРОКУПЉЕ_БУЧИНЦЕ_70998_714801",
    },
    {
        label: "ПРОКУПЉЕ, ВЕЛИКА ПЛАНА",
        value: "ПРОКУПЉЕ_ВЕЛИКА ПЛАНА_70998_735493",
    },
    {
        label: "ПРОКУПЉЕ, ВИЧА",
        value: "ПРОКУПЉЕ_ВИЧА_70998_735507",
    },
    {
        label: "ПРОКУПЉЕ, ВЛАСА",
        value: "ПРОКУПЉЕ_ВЛАСА_70998_735515",
    },
    {
        label: "ПРОКУПЉЕ, ВЛАСОВО",
        value: "ПРОКУПЉЕ_ВЛАСОВО_70998_735523",
    },
    {
        label: "ПРОКУПЉЕ, ВОДИЦЕ",
        value: "ПРОКУПЉЕ_ВОДИЦЕ_70998_735531",
    },
    {
        label: "ПРОКУПЉЕ, ГЛАСОВИК",
        value: "ПРОКУПЉЕ_ГЛАСОВИК_70998_714844",
    },
    {
        label: "ПРОКУПЉЕ, ГОЈИНОВАЦ",
        value: "ПРОКУПЉЕ_ГОЈИНОВАЦ_70998_735540",
    },
    {
        label: "ПРОКУПЉЕ, ГОРЊА БЕЈАШНИЦА",
        value: "ПРОКУПЉЕ_ГОРЊА БЕЈАШНИЦА_70998_735558",
    },
    {
        label: "ПРОКУПЉЕ, ГОРЊА БРЕСНИЦА",
        value: "ПРОКУПЉЕ_ГОРЊА БРЕСНИЦА_70998_735566",
    },
    {
        label: "ПРОКУПЉЕ, ГОРЊА КОЊУША",
        value: "ПРОКУПЉЕ_ГОРЊА КОЊУША_70998_735574",
    },
    {
        label: "ПРОКУПЉЕ, ГОРЊА РЕЧИЦА",
        value: "ПРОКУПЉЕ_ГОРЊА РЕЧИЦА_70998_735582",
    },
    {
        label: "ПРОКУПЉЕ, ГОРЊА СТРАЖАВА",
        value: "ПРОКУПЉЕ_ГОРЊА СТРАЖАВА_70998_714887",
    },
    {
        label: "ПРОКУПЉЕ, ГОРЊА ТОПОНИЦА",
        value: "ПРОКУПЉЕ_ГОРЊА ТОПОНИЦА_70998_735604",
    },
    {
        label: "ПРОКУПЉЕ, ГОРЊА ТРНАВА",
        value: "ПРОКУПЉЕ_ГОРЊА ТРНАВА_70998_735612",
    },
    {
        label: "ПРОКУПЉЕ, ГОРЊЕ КОРДИНЦЕ",
        value: "ПРОКУПЉЕ_ГОРЊЕ КОРДИНЦЕ_70998_714895",
    },
    {
        label: "ПРОКУПЉЕ, ГОРЊИ СТАТОВАЦ",
        value: "ПРОКУПЉЕ_ГОРЊИ СТАТОВАЦ_70998_714925",
    },
    {
        label: "ПРОКУПЉЕ, ГРАБОВАЦ",
        value: "ПРОКУПЉЕ_ГРАБОВАЦ_70998_735639",
    },
    {
        label: "ПРОКУПЉЕ, ГУБЕТИН",
        value: "ПРОКУПЉЕ_ГУБЕТИН_70998_735647",
    },
    {
        label: "ПРОКУПЉЕ, ДОБРОТИЋ",
        value: "ПРОКУПЉЕ_ДОБРОТИЋ_70998_714984",
    },
    {
        label: "ПРОКУПЉЕ, ДОЊА БЕЈАШНИЦА",
        value: "ПРОКУПЉЕ_ДОЊА БЕЈАШНИЦА_70998_735655",
    },
    {
        label: "ПРОКУПЉЕ, ДОЊА БРЕСНИЦА",
        value: "ПРОКУПЉЕ_ДОЊА БРЕСНИЦА_70998_735663",
    },
    {
        label: "ПРОКУПЉЕ, ДОЊА КОЊУША",
        value: "ПРОКУПЉЕ_ДОЊА КОЊУША_70998_735671",
    },
    {
        label: "ПРОКУПЉЕ, ДОЊА РЕЧИЦА",
        value: "ПРОКУПЉЕ_ДОЊА РЕЧИЦА_70998_735680",
    },
    {
        label: "ПРОКУПЉЕ, ДОЊА СТРАЖАВА",
        value: "ПРОКУПЉЕ_ДОЊА СТРАЖАВА_70998_715018",
    },
    {
        label: "ПРОКУПЉЕ, ДОЊА ТОПОНИЦА",
        value: "ПРОКУПЉЕ_ДОЊА ТОПОНИЦА_70998_735698",
    },
    {
        label: "ПРОКУПЉЕ, ДОЊА ТРНАВА",
        value: "ПРОКУПЉЕ_ДОЊА ТРНАВА_70998_735701",
    },
    {
        label: "ПРОКУПЉЕ, ДОЊЕ КОРДИНЦЕ",
        value: "ПРОКУПЉЕ_ДОЊЕ КОРДИНЦЕ_70998_715026",
    },
    {
        label: "ПРОКУПЉЕ, ДОЊИ СТАТОВАЦ",
        value: "ПРОКУПЉЕ_ДОЊИ СТАТОВАЦ_70998_715069",
    },
    {
        label: "ПРОКУПЉЕ, ДРАГИ ДЕО",
        value: "ПРОКУПЉЕ_ДРАГИ ДЕО_70998_715077",
    },
    {
        label: "ПРОКУПЉЕ, ДРЕНОВАЦ",
        value: "ПРОКУПЉЕ_ДРЕНОВАЦ_70998_735710",
    },
    {
        label: "ПРОКУПЉЕ, ЂУРОВАЦ",
        value: "ПРОКУПЉЕ_ЂУРОВАЦ_70998_715115",
    },
    {
        label: "ПРОКУПЉЕ, ЂУШНИЦА",
        value: "ПРОКУПЉЕ_ЂУШНИЦА_70998_735728",
    },
    {
        label: "ПРОКУПЉЕ, ЖИТНИ ПОТОК",
        value: "ПРОКУПЉЕ_ЖИТНИ ПОТОК_70998_715123",
    },
    {
        label: "ПРОКУПЉЕ, ЗДРАВИЊЕ",
        value: "ПРОКУПЉЕ_ЗДРАВИЊЕ_70998_735736",
    },
    {
        label: "ПРОКУПЉЕ, ЗЛАТА",
        value: "ПРОКУПЉЕ_ЗЛАТА_70998_715140",
    },
    {
        label: "ПРОКУПЉЕ, ЈАБУЧЕВО",
        value: "ПРОКУПЉЕ_ЈАБУЧЕВО_70998_715158",
    },
    {
        label: "ПРОКУПЉЕ, ЈОВИНЕ ЛИВАДЕ",
        value: "ПРОКУПЉЕ_ЈОВИНЕ ЛИВАДЕ_70998_715174",
    },
    {
        label: "ПРОКУПЉЕ, ЈУГОВАЦ",
        value: "ПРОКУПЉЕ_ЈУГОВАЦ_70998_735744",
    },
    {
        label: "ПРОКУПЉЕ, КАЛУДРА",
        value: "ПРОКУПЉЕ_КАЛУДРА_70998_735752",
    },
    {
        label: "ПРОКУПЉЕ, КЛИСУРИЦА",
        value: "ПРОКУПЉЕ_КЛИСУРИЦА_70998_715212",
    },
    {
        label: "ПРОКУПЉЕ, КОЖИНЦЕ",
        value: "ПРОКУПЉЕ_КОЖИНЦЕ_70998_715239",
    },
    {
        label: "ПРОКУПЉЕ, КОНЧИЋ",
        value: "ПРОКУПЉЕ_КОНЧИЋ_70998_735787",
    },
    {
        label: "ПРОКУПЉЕ, КОНЏЕЉ",
        value: "ПРОКУПЉЕ_КОНЏЕЉ_70998_735779",
    },
    {
        label: "ПРОКУПЉЕ, КРУШЕВИЦА",
        value: "ПРОКУПЉЕ_КРУШЕВИЦА_70998_735795",
    },
    {
        label: "ПРОКУПЉЕ, МАЂЕРЕ",
        value: "ПРОКУПЉЕ_МАЂЕРЕ_70998_735809",
    },
    {
        label: "ПРОКУПЉЕ, МАЛА ПЛАНА",
        value: "ПРОКУПЉЕ_МАЛА ПЛАНА_70998_735817",
    },
    {
        label: "ПРОКУПЉЕ, МАЧИНА",
        value: "ПРОКУПЉЕ_МАЧИНА_70998_715310",
    },
    {
        label: "ПРОКУПЉЕ, МЕРОВАЦ",
        value: "ПРОКУПЉЕ_МЕРОВАЦ_70998_735825",
    },
    {
        label: "ПРОКУПЉЕ, МИКУЛОВАЦ",
        value: "ПРОКУПЉЕ_МИКУЛОВАЦ_70998_715379",
    },
    {
        label: "ПРОКУПЉЕ, МИЉКОВИЦА",
        value: "ПРОКУПЉЕ_МИЉКОВИЦА_70998_735833",
    },
    {
        label: "ПРОКУПЉЕ, МРЉАК",
        value: "ПРОКУПЉЕ_МРЉАК_70998_715352",
    },
    {
        label: "ПРОКУПЉЕ, МРШЕЉ",
        value: "ПРОКУПЉЕ_МРШЕЉ_70998_735841",
    },
    {
        label: "ПРОКУПЉЕ, НОВА БОЖУРНА",
        value: "ПРОКУПЉЕ_НОВА БОЖУРНА_70998_715387",
    },
    {
        label: "ПРОКУПЉЕ, НОВИ ЂУРОВАЦ",
        value: "ПРОКУПЉЕ_НОВИ ЂУРОВАЦ_70998_715395",
    },
    {
        label: "ПРОКУПЉЕ, НОВО СЕЛО",
        value: "ПРОКУПЉЕ_НОВО СЕЛО_70998_715417",
    },
    {
        label: "ПРОКУПЉЕ, ОБРТИНЦЕ",
        value: "ПРОКУПЉЕ_ОБРТИНЦЕ_70998_735850",
    },
    {
        label: "ПРОКУПЉЕ, ПАСЈАЧА",
        value: "ПРОКУПЉЕ_ПАСЈАЧА_70998_715441",
    },
    {
        label: "ПРОКУПЉЕ, ПАШИНАЦ",
        value: "ПРОКУПЉЕ_ПАШИНАЦ_70998_735868",
    },
    {
        label: "ПРОКУПЉЕ, ПЕСТИШ",
        value: "ПРОКУПЉЕ_ПЕСТИШ_70998_735876",
    },
    {
        label: "ПРОКУПЉЕ, ПЕТРОВАЦ",
        value: "ПРОКУПЉЕ_ПЕТРОВАЦ_70998_715468",
    },
    {
        label: "ПРОКУПЉЕ, ПИСКАЉЕ",
        value: "ПРОКУПЉЕ_ПИСКАЉЕ_70998_735884",
    },
    {
        label: "ПРОКУПЉЕ, ПЛОЧНИК",
        value: "ПРОКУПЉЕ_ПЛОЧНИК_70998_735892",
    },
    {
        label: "ПРОКУПЉЕ, ПОЈАТЕ",
        value: "ПРОКУПЉЕ_ПОЈАТЕ_70998_715484",
    },
    {
        label: "ПРОКУПЉЕ, ПОТОЧИЋ",
        value: "ПРОКУПЉЕ_ПОТОЧИЋ_70998_735906",
    },
    {
        label: "ПРОКУПЉЕ, ПРЕКАДИН",
        value: "ПРОКУПЉЕ_ПРЕКАДИН_70998_735914",
    },
    {
        label: "ПРОКУПЉЕ, ПРЕКАШНИЦА",
        value: "ПРОКУПЉЕ_ПРЕКАШНИЦА_70998_735922",
    },
    {
        label: "ПРОКУПЉЕ, ПРЕКОПУЦЕ",
        value: "ПРОКУПЉЕ_ПРЕКОПУЦЕ_70998_735949",
    },
    {
        label: "ПРОКУПЉЕ, ПРОКУПЉЕ",
        value: "ПРОКУПЉЕ_ПРОКУПЉЕ_70998_735957",
    },
    {
        label: "ПРОКУПЉЕ, ПРОКУПЉЕ ГРАД",
        value: "ПРОКУПЉЕ_ПРОКУПЉЕ ГРАД_70998_735965",
    },
    {
        label: "ПРОКУПЉЕ, РАНКОВА РЕКА",
        value: "ПРОКУПЉЕ_РАНКОВА РЕКА_70998_715506",
    },
    {
        label: "ПРОКУПЉЕ, РАСТОВНИЦА",
        value: "ПРОКУПЉЕ_РАСТОВНИЦА_70998_715514",
    },
    {
        label: "ПРОКУПЉЕ, РГАЈЕ",
        value: "ПРОКУПЉЕ_РГАЈЕ_70998_735973",
    },
    {
        label: "ПРОКУПЉЕ, РЕЉИНАЦ",
        value: "ПРОКУПЉЕ_РЕЉИНАЦ_70998_735981",
    },
    {
        label: "ПРОКУПЉЕ, РЕСИНАЦ",
        value: "ПРОКУПЉЕ_РЕСИНАЦ_70998_735990",
    },
    {
        label: "ПРОКУПЉЕ, СМРДАН",
        value: "ПРОКУПЉЕ_СМРДАН_70998_736007",
    },
    {
        label: "ПРОКУПЉЕ, СРЕДЊИ СТАТОВАЦ",
        value: "ПРОКУПЉЕ_СРЕДЊИ СТАТОВАЦ_70998_715573",
    },
    {
        label: "ПРОКУПЉЕ, СТАРИ ЂУРОВАЦ",
        value: "ПРОКУПЉЕ_СТАРИ ЂУРОВАЦ_70998_715590",
    },
    {
        label: "ПРОКУПЉЕ, ТОВРЉАНЕ",
        value: "ПРОКУПЉЕ_ТОВРЉАНЕ_70998_736015",
    },
    {
        label: "ПРОКУПЉЕ, ТРНОВИ ЛАЗ",
        value: "ПРОКУПЉЕ_ТРНОВИ ЛАЗ_70998_736023",
    },
    {
        label: "ПРОКУПЉЕ, ТУЛАРЕ",
        value: "ПРОКУПЉЕ_ТУЛАРЕ_70998_736031",
    },
    {
        label: "ПРОКУПЉЕ, ЋУКОВАЦ",
        value: "ПРОКУПЉЕ_ЋУКОВАЦ_70998_736040",
    },
    {
        label: "ПРОКУПЉЕ, ЏИГОЉ",
        value: "ПРОКУПЉЕ_ЏИГОЉ_70998_715638",
    },
    {
        label: "ПРОКУПЉЕ, ШИРОКЕ ЊИВЕ",
        value: "ПРОКУПЉЕ_ШИРОКЕ ЊИВЕ_70998_736058",
    },
    {
        label: "ПРОКУПЉЕ, ШИШМАНОВАЦ",
        value: "ПРОКУПЉЕ_ШИШМАНОВАЦ_70998_736066",
    },
    {
        label: "РАЖАЊ, БРАЉИНА",
        value: "РАЖАЊ_БРАЉИНА_71005_736074",
    },
    {
        label: "РАЖАЊ, БРАЧИН",
        value: "РАЖАЊ_БРАЧИН_71005_736082",
    },
    {
        label: "РАЖАЊ, ВАРОШ",
        value: "РАЖАЊ_ВАРОШ_71005_736104",
    },
    {
        label: "РАЖАЊ, ВИТОШЕВАЦ",
        value: "РАЖАЊ_ВИТОШЕВАЦ_71005_736112",
    },
    {
        label: "РАЖАЊ, ГРАБОВО",
        value: "РАЖАЊ_ГРАБОВО_71005_736139",
    },
    {
        label: "РАЖАЊ, ЛИПОВАЦ",
        value: "РАЖАЊ_ЛИПОВАЦ_71005_736147",
    },
    {
        label: "РАЖАЊ, МАЂЕРЕ",
        value: "РАЖАЊ_МАЂЕРЕ_71005_736163",
    },
    {
        label: "РАЖАЊ, МАЛЕТИНА",
        value: "РАЖАЊ_МАЛЕТИНА_71005_736171",
    },
    {
        label: "РАЖАЊ, МАНАСТИРСКО",
        value: "РАЖАЊ_МАНАСТИРСКО_71005_736180",
    },
    {
        label: "РАЖАЊ, МАЋИЈА",
        value: "РАЖАЊ_МАЋИЈА_71005_736198",
    },
    {
        label: "РАЖАЊ, ПАРДИК",
        value: "РАЖАЊ_ПАРДИК_71005_736201",
    },
    {
        label: "РАЖАЊ, ПОДГОРАЦ",
        value: "РАЖАЊ_ПОДГОРАЦ_71005_736228",
    },
    {
        label: "РАЖАЊ, ПОСЛОН",
        value: "РАЖАЊ_ПОСЛОН_71005_736244",
    },
    {
        label: "РАЖАЊ, ПРАСКОВЧЕ",
        value: "РАЖАЊ_ПРАСКОВЧЕ_71005_736252",
    },
    {
        label: "РАЖАЊ, ПРЕТРКОВАЦ",
        value: "РАЖАЊ_ПРЕТРКОВАЦ_71005_736279",
    },
    {
        label: "РАЖАЊ, РАЖАЊ",
        value: "РАЖАЊ_РАЖАЊ_71005_736295",
    },
    {
        label: "РАЖАЊ, РУЈИШТЕ",
        value: "РАЖАЊ_РУЈИШТЕ_71005_736309",
    },
    {
        label: "РАЖАЊ, СКОРИЦА",
        value: "РАЖАЊ_СКОРИЦА_71005_736317",
    },
    {
        label: "РАЖАЊ, СМИЛОВАЦ",
        value: "РАЖАЊ_СМИЛОВАЦ_71005_736325",
    },
    {
        label: "РАЖАЊ, ЦЕРОВО",
        value: "РАЖАЊ_ЦЕРОВО_71005_736368",
    },
    {
        label: "РАЖАЊ, ЦРНИ КАО",
        value: "РАЖАЊ_ЦРНИ КАО_71005_736376",
    },
    {
        label: "РАЖАЊ, ЧУБУРА",
        value: "РАЖАЊ_ЧУБУРА_71005_736384",
    },
    {
        label: "РАЖАЊ, ШЕТКА",
        value: "РАЖАЊ_ШЕТКА_71005_736392",
    },
    {
        label: "РАКОВИЦА, КНЕЖЕВАЦ",
        value: "РАКОВИЦА_КНЕЖЕВАЦ_70211_703796",
    },
    {
        label: "РАКОВИЦА, РЕСНИК",
        value: "РАКОВИЦА_РЕСНИК_70211_703940",
    },
    {
        label: "РАКОВИЦА, СТАРА РАКОВИЦА",
        value: "РАКОВИЦА_СТАРА РАКОВИЦА_70211_704032",
    },
    {
        label: "РАЧА, АДРОВАЦ",
        value: "РАЧА_АДРОВАЦ_71013_736406",
    },
    {
        label: "РАЧА, БОРЦИ",
        value: "РАЧА_БОРЦИ_71013_736422",
    },
    {
        label: "РАЧА, БОШЊАНЕ",
        value: "РАЧА_БОШЊАНЕ_71013_736449",
    },
    {
        label: "РАЧА, ВЕЛИКО КРЧМАРЕ",
        value: "РАЧА_ВЕЛИКО КРЧМАРЕ_71013_736457",
    },
    {
        label: "РАЧА, ВИШЕВАЦ",
        value: "РАЧА_ВИШЕВАЦ_71013_736465",
    },
    {
        label: "РАЧА, ВОЈИНОВАЦ",
        value: "РАЧА_ВОЈИНОВАЦ_71013_719188",
    },
    {
        label: "РАЧА, ВУЧИЋ",
        value: "РАЧА_ВУЧИЋ_71013_736473",
    },
    {
        label: "РАЧА, ДОЊА РАЧА",
        value: "РАЧА_ДОЊА РАЧА_71013_736511",
    },
    {
        label: "РАЧА, ДОЊЕ ЈАРУШИЦЕ",
        value: "РАЧА_ДОЊЕ ЈАРУШИЦЕ_71013_736520",
    },
    {
        label: "РАЧА, ЂУРЂЕВО",
        value: "РАЧА_ЂУРЂЕВО_71013_736538",
    },
    {
        label: "РАЧА, МАЛО КРЧМАРЕ",
        value: "РАЧА_МАЛО КРЧМАРЕ_71013_736589",
    },
    {
        label: "РАЧА, МИРАШЕВАЦ",
        value: "РАЧА_МИРАШЕВАЦ_71013_736597",
    },
    {
        label: "РАЧА, ПОПОВИЋ",
        value: "РАЧА_ПОПОВИЋ_71013_736635",
    },
    {
        label: "РАЧА, РАЧА",
        value: "РАЧА_РАЧА_71013_736643",
    },
    {
        label: "РАЧА, САРАНОВО",
        value: "РАЧА_САРАНОВО_71013_736651",
    },
    {
        label: "РАЧА, СЕПЦИ",
        value: "РАЧА_СЕПЦИ_71013_736660",
    },
    {
        label: "РАЧА, СИПИЋ",
        value: "РАЧА_СИПИЋ_71013_736678",
    },
    {
        label: "РАШКА, БАДАЊ",
        value: "РАШКА_БАДАЊ_71021_736716",
    },
    {
        label: "РАШКА, БАЉЕВАЦ",
        value: "РАШКА_БАЉЕВАЦ_71021_736724",
    },
    {
        label: "РАШКА, БЕЛА СТЕНА",
        value: "РАШКА_БЕЛА СТЕНА_71021_736759",
    },
    {
        label: "РАШКА, БЕОЦИ",
        value: "РАШКА_БЕОЦИ_71021_736767",
    },
    {
        label: "РАШКА, БИЉАНОВАЦ",
        value: "РАШКА_БИЉАНОВАЦ_71021_736783",
    },
    {
        label: "РАШКА, БИНИЋЕ",
        value: "РАШКА_БИНИЋЕ_71021_736791",
    },
    {
        label: "РАШКА, БОРОВИЋЕ",
        value: "РАШКА_БОРОВИЋЕ_71021_736805",
    },
    {
        label: "РАШКА, БОЋЕ",
        value: "РАШКА_БОЋЕ_71021_736813",
    },
    {
        label: "РАШКА, БРВЕНИЦА",
        value: "РАШКА_БРВЕНИЦА_71021_736821",
    },
    {
        label: "РАШКА, ВАРЕВО",
        value: "РАШКА_ВАРЕВО_71021_736848",
    },
    {
        label: "РАШКА, ВОЈМИЛОВИЋИ",
        value: "РАШКА_ВОЈМИЛОВИЋИ_71021_736856",
    },
    {
        label: "РАШКА, ГЊИЛИЦА",
        value: "РАШКА_ГЊИЛИЦА_71021_736872",
    },
    {
        label: "РАШКА, ГРАДАЦ",
        value: "РАШКА_ГРАДАЦ_71021_736899",
    },
    {
        label: "РАШКА, ДРАГАНИЋИ",
        value: "РАШКА_ДРАГАНИЋИ_71021_736929",
    },
    {
        label: "РАШКА, ЖУТИЦЕ",
        value: "РАШКА_ЖУТИЦЕ_71021_736953",
    },
    {
        label: "РАШКА, ЗАРЕВО",
        value: "РАШКА_ЗАРЕВО_71021_736961",
    },
    {
        label: "РАШКА, ЈОШАНИЧКА БАЊА",
        value: "РАШКА_ЈОШАНИЧКА БАЊА_71021_736988",
    },
    {
        label: "РАШКА, КАЗНОВИЋЕ",
        value: "РАШКА_КАЗНОВИЋЕ_71021_736996",
    },
    {
        label: "РАШКА, КАРАДАК",
        value: "РАШКА_КАРАДАК_71021_737003",
    },
    {
        label: "РАШКА, КОВАЧИ",
        value: "РАШКА_КОВАЧИ_71021_737011",
    },
    {
        label: "РАШКА, КОПАОНИК",
        value: "РАШКА_КОПАОНИК_71021_745154",
    },
    {
        label: "РАШКА, КОРЛАЋЕ",
        value: "РАШКА_КОРЛАЋЕ_71021_737020",
    },
    {
        label: "РАШКА, КРАВИЋЕ",
        value: "РАШКА_КРАВИЋЕ_71021_737038",
    },
    {
        label: "РАШКА, КРЕМИЋЕ",
        value: "РАШКА_КРЕМИЋЕ_71021_737046",
    },
    {
        label: "РАШКА, КРУШЕВИЦА",
        value: "РАШКА_КРУШЕВИЦА_71021_737054",
    },
    {
        label: "РАШКА, КУЋАНЕ",
        value: "РАШКА_КУЋАНЕ_71021_737062",
    },
    {
        label: "РАШКА, ЛИСИНА",
        value: "РАШКА_ЛИСИНА_71021_737089",
    },
    {
        label: "РАШКА, ЛУКОВО",
        value: "РАШКА_ЛУКОВО_71021_737097",
    },
    {
        label: "РАШКА, МИЛАТКОВИЋЕ",
        value: "РАШКА_МИЛАТКОВИЋЕ_71021_737119",
    },
    {
        label: "РАШКА, МУРЕ",
        value: "РАШКА_МУРЕ_71021_737143",
    },
    {
        label: "РАШКА, НОВО СЕЛО",
        value: "РАШКА_НОВО СЕЛО_71021_737151",
    },
    {
        label: "РАШКА, НОСОЉИН",
        value: "РАШКА_НОСОЉИН_71021_737160",
    },
    {
        label: "РАШКА, ПАВЛИЦА",
        value: "РАШКА_ПАВЛИЦА_71021_737186",
    },
    {
        label: "РАШКА, ПАНОЈЕВИЋЕ",
        value: "РАШКА_ПАНОЈЕВИЋЕ_71021_737194",
    },
    {
        label: "РАШКА, ПИСКАЊА",
        value: "РАШКА_ПИСКАЊА_71021_737208",
    },
    {
        label: "РАШКА, ПЛЕШИН",
        value: "РАШКА_ПЛЕШИН_71021_737224",
    },
    {
        label: "РАШКА, ПОБРЂЕ",
        value: "РАШКА_ПОБРЂЕ_71021_737232",
    },
    {
        label: "РАШКА, ПОЦЕСЈЕ",
        value: "РАШКА_ПОЦЕСЈЕ_71021_737267",
    },
    {
        label: "РАШКА, РАДОШИЋЕ",
        value: "РАШКА_РАДОШИЋЕ_71021_737275",
    },
    {
        label: "РАШКА, РАКОВАЦ",
        value: "РАШКА_РАКОВАЦ_71021_737283",
    },
    {
        label: "РАШКА, РАШКА",
        value: "РАШКА_РАШКА_71021_737291",
    },
    {
        label: "РАШКА, РВАТИ",
        value: "РАШКА_РВАТИ_71021_737305",
    },
    {
        label: "РАШКА, РУДНИЦА",
        value: "РАШКА_РУДНИЦА_71021_737321",
    },
    {
        label: "РАШКА, СЕБИМИЉЕ",
        value: "РАШКА_СЕБИМИЉЕ_71021_737356",
    },
    {
        label: "РАШКА, СЕМЕТЕШ",
        value: "РАШКА_СЕМЕТЕШ_71021_737364",
    },
    {
        label: "РАШКА, СУПЊЕ",
        value: "РАШКА_СУПЊЕ_71021_737372",
    },
    {
        label: "РАШКА, ТИОЏЕ",
        value: "РАШКА_ТИОЏЕ_71021_737402",
    },
    {
        label: "РАШКА, ТРНАВА",
        value: "РАШКА_ТРНАВА_71021_737429",
    },
    {
        label: "РАШКА, ТУСНИЋ-ПЛАВКОВО",
        value: "РАШКА_ТУСНИЋ-ПЛАВКОВО_71021_737437",
    },
    {
        label: "РАШКА, ЦРНА ГЛАВА",
        value: "РАШКА_ЦРНА ГЛАВА_71021_737461",
    },
    {
        label: "РАШКА, ШИПАЧИНА",
        value: "РАШКА_ШИПАЧИНА_71021_737470",
    },
    {
        label: "РЕКОВАЦ, БАРЕ",
        value: "РЕКОВАЦ_БАРЕ_71030_737488",
    },
    {
        label: "РЕКОВАЦ, БЕЛУШИЋ",
        value: "РЕКОВАЦ_БЕЛУШИЋ_71030_737496",
    },
    {
        label: "РЕКОВАЦ, БЕОЧИЋ",
        value: "РЕКОВАЦ_БЕОЧИЋ_71030_737925",
    },
    {
        label: "РЕКОВАЦ, БОГАЛИНАЦ",
        value: "РЕКОВАЦ_БОГАЛИНАЦ_71030_737500",
    },
    {
        label: "РЕКОВАЦ, БРАЈИНОВАЦ",
        value: "РЕКОВАЦ_БРАЈИНОВАЦ_71030_737518",
    },
    {
        label: "РЕКОВАЦ, ВЕЛИКА КРУШЕВИЦА",
        value: "РЕКОВАЦ_ВЕЛИКА КРУШЕВИЦА_71030_737526",
    },
    {
        label: "РЕКОВАЦ, ВУКМАНОВАЦ",
        value: "РЕКОВАЦ_ВУКМАНОВАЦ_71030_737569",
    },
    {
        label: "РЕКОВАЦ, ДОБРОСЕЛИЦА",
        value: "РЕКОВАЦ_ДОБРОСЕЛИЦА_71030_737593",
    },
    {
        label: "РЕКОВАЦ, ДРАГОВО",
        value: "РЕКОВАЦ_ДРАГОВО_71030_737607",
    },
    {
        label: "РЕКОВАЦ, ЖУПАЊЕВАЦ",
        value: "РЕКОВАЦ_ЖУПАЊЕВАЦ_71030_737623",
    },
    {
        label: "РЕКОВАЦ, КАВАДАР",
        value: "РЕКОВАЦ_КАВАДАР_71030_737631",
    },
    {
        label: "РЕКОВАЦ, КАЛЕНИЋКИ ПРЊАВОР",
        value: "РЕКОВАЦ_КАЛЕНИЋКИ ПРЊАВОР_71030_737640",
    },
    {
        label: "РЕКОВАЦ, КАЛУДРА",
        value: "РЕКОВАЦ_КАЛУДРА_71030_737658",
    },
    {
        label: "РЕКОВАЦ, КОМАРАНЕ",
        value: "РЕКОВАЦ_КОМАРАНЕ_71030_737666",
    },
    {
        label: "РЕКОВАЦ, ЛЕПОЈЕВИЋ",
        value: "РЕКОВАЦ_ЛЕПОЈЕВИЋ_71030_737674",
    },
    {
        label: "РЕКОВАЦ, ЛОМНИЦА",
        value: "РЕКОВАЦ_ЛОМНИЦА_71030_737682",
    },
    {
        label: "РЕКОВАЦ, ЛОЋИКА",
        value: "РЕКОВАЦ_ЛОЋИКА_71030_738263",
    },
    {
        label: "РЕКОВАЦ, МАЛЕШЕВО",
        value: "РЕКОВАЦ_МАЛЕШЕВО_71030_737704",
    },
    {
        label: "РЕКОВАЦ, МОТРИЋ",
        value: "РЕКОВАЦ_МОТРИЋ_71030_737712",
    },
    {
        label: "РЕКОВАЦ, НАДРЉЕ",
        value: "РЕКОВАЦ_НАДРЉЕ_71030_737739",
    },
    {
        label: "РЕКОВАЦ, ОПАРИЋ",
        value: "РЕКОВАЦ_ОПАРИЋ_71030_737747",
    },
    {
        label: "РЕКОВАЦ, ПРЕВЕШТ",
        value: "РЕКОВАЦ_ПРЕВЕШТ_71030_737755",
    },
    {
        label: "РЕКОВАЦ, РАБЕНОВАЦ",
        value: "РЕКОВАЦ_РАБЕНОВАЦ_71030_737763",
    },
    {
        label: "РЕКОВАЦ, РАТКОВИЋ",
        value: "РЕКОВАЦ_РАТКОВИЋ_71030_737771",
    },
    {
        label: "РЕКОВАЦ, РЕКОВАЦ",
        value: "РЕКОВАЦ_РЕКОВАЦ_71030_737780",
    },
    {
        label: "РЕКОВАЦ, СЕКУРИЧ",
        value: "РЕКОВАЦ_СЕКУРИЧ_71030_737798",
    },
    {
        label: "РЕКОВАЦ, СИБНИЦА",
        value: "РЕКОВАЦ_СИБНИЦА_71030_737801",
    },
    {
        label: "РЕКОВАЦ, СИЉЕВИЦА",
        value: "РЕКОВАЦ_СИЉЕВИЦА_71030_737810",
    },
    {
        label: "РЕКОВАЦ, ТЕЧИЋ",
        value: "РЕКОВАЦ_ТЕЧИЋ_71030_737844",
    },
    {
        label: "РЕКОВАЦ, УРСУЛЕ",
        value: "РЕКОВАЦ_УРСУЛЕ_71030_737852",
    },
    {
        label: "РЕКОВАЦ, ЦИКОТ",
        value: "РЕКОВАЦ_ЦИКОТ_71030_737879",
    },
    {
        label: "РЕКОВАЦ, ШЉИВИЦА",
        value: "РЕКОВАЦ_ШЉИВИЦА_71030_737887",
    },
    {
        label: "РУМА, БУЂАНОВЦИ",
        value: "РУМА_БУЂАНОВЦИ_80357_802956",
    },
    {
        label: "РУМА, ВИТОЈЕВЦИ",
        value: "РУМА_ВИТОЈЕВЦИ_80357_804207",
    },
    {
        label: "РУМА, ВОГАЊ",
        value: "РУМА_ВОГАЊ_80357_803413",
    },
    {
        label: "РУМА, ГРАБОВЦИ",
        value: "РУМА_ГРАБОВЦИ_80357_804088",
    },
    {
        label: "РУМА, ДОБРИНЦИ",
        value: "РУМА_ДОБРИНЦИ_80357_802972",
    },
    {
        label: "РУМА, ДОЊИ ПЕТРОВЦИ",
        value: "РУМА_ДОЊИ ПЕТРОВЦИ_80357_802999",
    },
    {
        label: "РУМА, ЖАРКОВАЦ",
        value: "РУМА_ЖАРКОВАЦ_80357_803430",
    },
    {
        label: "РУМА, КЛЕНАК",
        value: "РУМА_КЛЕНАК_80357_804134",
    },
    {
        label: "РУМА, КРАЉЕВЦИ",
        value: "РУМА_КРАЉЕВЦИ_80357_803081",
    },
    {
        label: "РУМА, МАЛИ РАДИНЦИ",
        value: "РУМА_МАЛИ РАДИНЦИ_80357_803162",
    },
    {
        label: "РУМА, МАРЂЕЛОС",
        value: "РУМА_МАРЂЕЛОС_80357_803219",
    },
    {
        label: "РУМА, НИКИНЦИ",
        value: "РУМА_НИКИНЦИ_80357_804193",
    },
    {
        label: "РУМА, ПАВЛОВЦИ",
        value: "РУМА_ПАВЛОВЦИ_80357_803243",
    },
    {
        label: "РУМА, ПЛАТИЧЕВО",
        value: "РУМА_ПЛАТИЧЕВО_80357_804223",
    },
    {
        label: "РУМА, ПУТИНЦИ",
        value: "РУМА_ПУТИНЦИ_80357_803251",
    },
    {
        label: "РУМА, РУМА",
        value: "РУМА_РУМА_80357_803286",
    },
    {
        label: "РУМА, СТЕЈАНОВЦИ",
        value: "РУМА_СТЕЈАНОВЦИ_80357_803324",
    },
    {
        label: "РУМА, ХРТКОВЦИ",
        value: "РУМА_ХРТКОВЦИ_80357_804096",
    },
    {
        label: "САВСКИ ВЕНАЦ, САВСКИ ВЕНАЦ",
        value: "САВСКИ ВЕНАЦ_САВСКИ ВЕНАЦ_70220_704008",
    },
    {
        label: "СВИЛАЈНАЦ, БОБОВО",
        value: "СВИЛАЈНАЦ_БОБОВО_71056_738506",
    },
    {
        label: "СВИЛАЈНАЦ, БРЕСЈЕ",
        value: "СВИЛАЈНАЦ_БРЕСЈЕ_71056_738514",
    },
    {
        label: "СВИЛАЈНАЦ, ВОЈСКА",
        value: "СВИЛАЈНАЦ_ВОЈСКА_71056_738549",
    },
    {
        label: "СВИЛАЈНАЦ, ВРЛАНЕ",
        value: "СВИЛАЈНАЦ_ВРЛАНЕ_71056_738557",
    },
    {
        label: "СВИЛАЈНАЦ, ГЛОЖАНЕ",
        value: "СВИЛАЈНАЦ_ГЛОЖАНЕ_71056_738565",
    },
    {
        label: "СВИЛАЈНАЦ, ГРАБОВАЦ",
        value: "СВИЛАЈНАЦ_ГРАБОВАЦ_71056_738573",
    },
    {
        label: "СВИЛАЈНАЦ, ДУБЉЕ",
        value: "СВИЛАЈНАЦ_ДУБЉЕ_71056_738590",
    },
    {
        label: "СВИЛАЈНАЦ, ДУБНИЦА",
        value: "СВИЛАЈНАЦ_ДУБНИЦА_71056_738603",
    },
    {
        label: "СВИЛАЈНАЦ, ЂУРИНАЦ",
        value: "СВИЛАЈНАЦ_ЂУРИНАЦ_71056_738611",
    },
    {
        label: "СВИЛАЈНАЦ, КУПИНОВАЦ",
        value: "СВИЛАЈНАЦ_КУПИНОВАЦ_71056_738638",
    },
    {
        label: "СВИЛАЈНАЦ, КУШИЉЕВО",
        value: "СВИЛАЈНАЦ_КУШИЉЕВО_71056_738646",
    },
    {
        label: "СВИЛАЈНАЦ, ЛУКОВИЦА",
        value: "СВИЛАЈНАЦ_ЛУКОВИЦА_71056_738654",
    },
    {
        label: "СВИЛАЈНАЦ, МАЧЕВАЦ",
        value: "СВИЛАЈНАЦ_МАЧЕВАЦ_71056_738662",
    },
    {
        label: "СВИЛАЈНАЦ, ПРОШТИНАЦ",
        value: "СВИЛАЈНАЦ_ПРОШТИНАЦ_71056_738689",
    },
    {
        label: "СВИЛАЈНАЦ, РАДОШИН",
        value: "СВИЛАЈНАЦ_РАДОШИН_71056_738697",
    },
    {
        label: "СВИЛАЈНАЦ, РОАНДА",
        value: "СВИЛАЈНАЦ_РОАНДА_71056_738727",
    },
    {
        label: "СВИЛАЈНАЦ, РОЋЕВАЦ",
        value: "СВИЛАЈНАЦ_РОЋЕВАЦ_71056_738735",
    },
    {
        label: "СВИЛАЈНАЦ, СВИЛАЈНАЦ",
        value: "СВИЛАЈНАЦ_СВИЛАЈНАЦ_71056_738743",
    },
    {
        label: "СВИЛАЈНАЦ, СЕДЛАРЕ",
        value: "СВИЛАЈНАЦ_СЕДЛАРЕ_71056_738751",
    },
    {
        label: "СВИЛАЈНАЦ, СУБОТИЦА",
        value: "СВИЛАЈНАЦ_СУБОТИЦА_71056_738760",
    },
    {
        label: "СВИЛАЈНАЦ, ТРОПОЊЕ",
        value: "СВИЛАЈНАЦ_ТРОПОЊЕ_71056_738778",
    },
    {
        label: "СВИЛАЈНАЦ, ЦРКВЕНАЦ",
        value: "СВИЛАЈНАЦ_ЦРКВЕНАЦ_71056_738786",
    },
    {
        label: "СВРЉИГ, БЕЛОИЊЕ",
        value: "СВРЉИГ_БЕЛОИЊЕ_71064_738794",
    },
    {
        label: "СВРЉИГ, БУРДИМО",
        value: "СВРЉИГ_БУРДИМО_71064_738808",
    },
    {
        label: "СВРЉИГ, БУЧУМ",
        value: "СВРЉИГ_БУЧУМ_71064_738816",
    },
    {
        label: "СВРЉИГ, ВАРОШ",
        value: "СВРЉИГ_ВАРОШ_71064_738824",
    },
    {
        label: "СВРЉИГ, ВЛАХОВО",
        value: "СВРЉИГ_ВЛАХОВО_71064_738832",
    },
    {
        label: "СВРЉИГ, ГАЛИБАБИНАЦ",
        value: "СВРЉИГ_ГАЛИБАБИНАЦ_71064_738859",
    },
    {
        label: "СВРЉИГ, ГОЈМАНОВАЦ",
        value: "СВРЉИГ_ГОЈМАНОВАЦ_71064_738867",
    },
    {
        label: "СВРЉИГ, ГРБАВЧЕ",
        value: "СВРЉИГ_ГРБАВЧЕ_71064_738875",
    },
    {
        label: "СВРЉИГ, ГУЛИЈАН",
        value: "СВРЉИГ_ГУЛИЈАН_71064_738883",
    },
    {
        label: "СВРЉИГ, ГУШЕВАЦ",
        value: "СВРЉИГ_ГУШЕВАЦ_71064_738891",
    },
    {
        label: "СВРЉИГ, ДАВИДОВАЦ I",
        value: "СВРЉИГ_ДАВИДОВАЦ I_71064_738905",
    },
    {
        label: "СВРЉИГ, ДАВИДОВАЦ II",
        value: "СВРЉИГ_ДАВИДОВАЦ II_71064_738913",
    },
    {
        label: "СВРЉИГ, ДРАЈИНАЦ",
        value: "СВРЉИГ_ДРАЈИНАЦ_71064_738921",
    },
    {
        label: "СВРЉИГ, ЂУРИНАЦ",
        value: "СВРЉИГ_ЂУРИНАЦ_71064_738930",
    },
    {
        label: "СВРЉИГ, ЖЕЉЕВО",
        value: "СВРЉИГ_ЖЕЉЕВО_71064_738948",
    },
    {
        label: "СВРЉИГ, ИЗВОР",
        value: "СВРЉИГ_ИЗВОР_71064_738956",
    },
    {
        label: "СВРЉИГ, КОПАЈКОШАРА",
        value: "СВРЉИГ_КОПАЈКОШАРА_71064_738964",
    },
    {
        label: "СВРЉИГ, ЛАБУКОВО",
        value: "СВРЉИГ_ЛАБУКОВО_71064_740586",
    },
    {
        label: "СВРЉИГ, ЛАЛИНАЦ",
        value: "СВРЉИГ_ЛАЛИНАЦ_71064_738972",
    },
    {
        label: "СВРЉИГ, ЛОЗАН",
        value: "СВРЉИГ_ЛОЗАН_71064_738999",
    },
    {
        label: "СВРЉИГ, ЛУКОВО",
        value: "СВРЉИГ_ЛУКОВО_71064_739006",
    },
    {
        label: "СВРЉИГ, МАНОЈЛИЦА",
        value: "СВРЉИГ_МАНОЈЛИЦА_71064_739014",
    },
    {
        label: "СВРЉИГ, МЕРЏЕЛАТ",
        value: "СВРЉИГ_МЕРЏЕЛАТ_71064_739022",
    },
    {
        label: "СВРЉИГ, МЕЧЈИ ДО",
        value: "СВРЉИГ_МЕЧЈИ ДО_71064_739049",
    },
    {
        label: "СВРЉИГ, НИШЕВАЦ",
        value: "СВРЉИГ_НИШЕВАЦ_71064_739065",
    },
    {
        label: "СВРЉИГ, ОКОЛИШТЕ",
        value: "СВРЉИГ_ОКОЛИШТЕ_71064_739073",
    },
    {
        label: "СВРЉИГ, ОКРУГЛИЦА",
        value: "СВРЉИГ_ОКРУГЛИЦА_71064_739081",
    },
    {
        label: "СВРЉИГ, ПАЛИЛУЛА",
        value: "СВРЉИГ_ПАЛИЛУЛА_71064_739090",
    },
    {
        label: "СВРЉИГ, ПЕРИШ",
        value: "СВРЉИГ_ПЕРИШ_71064_739103",
    },
    {
        label: "СВРЉИГ, ПИРКОВАЦ",
        value: "СВРЉИГ_ПИРКОВАЦ_71064_739111",
    },
    {
        label: "СВРЉИГ, ПЛУЖИНА",
        value: "СВРЉИГ_ПЛУЖИНА_71064_739120",
    },
    {
        label: "СВРЉИГ, ПОПШИЦА",
        value: "СВРЉИГ_ПОПШИЦА_71064_739138",
    },
    {
        label: "СВРЉИГ, ПРЕКОНОГА",
        value: "СВРЉИГ_ПРЕКОНОГА_71064_739146",
    },
    {
        label: "СВРЉИГ, РАДМИРОВАЦ",
        value: "СВРЉИГ_РАДМИРОВАЦ_71064_739154",
    },
    {
        label: "СВРЉИГ, РИБАРЕ",
        value: "СВРЉИГ_РИБАРЕ_71064_739162",
    },
    {
        label: "СВРЉИГ, СВРЉИГ",
        value: "СВРЉИГ_СВРЉИГ_71064_739189",
    },
    {
        label: "СВРЉИГ, СЛИВЈЕ",
        value: "СВРЉИГ_СЛИВЈЕ_71064_739197",
    },
    {
        label: "СВРЉИГ, ТИЈОВАЦ",
        value: "СВРЉИГ_ТИЈОВАЦ_71064_739219",
    },
    {
        label: "СВРЉИГ, ЦРНОЉЕВИЦА",
        value: "СВРЉИГ_ЦРНОЉЕВИЦА_71064_739227",
    },
    {
        label: "СВРЉИГ, ШЉИВОВИК",
        value: "СВРЉИГ_ШЉИВОВИК_71064_739235",
    },
    {
        label: "СЕВОЈНО, СЕВОЈНО",
        value: "СЕВОЈНО_СЕВОЈНО_71366_741418",
    },
    {
        label: "СЕНТА, БАТКА",
        value: "СЕНТА_БАТКА_80365_802891",
    },
    {
        label: "СЕНТА, СЕНТА",
        value: "СЕНТА_СЕНТА_80365_803731",
    },
    {
        label: "СЕНТА, ТОРЊОШ",
        value: "СЕНТА_ТОРЊОШ_80365_803758",
    },
    {
        label: "СЕЧАЊ, БАНАТСКА ДУБИЦА",
        value: "СЕЧАЊ_БАНАТСКА ДУБИЦА_80373_802565",
    },
    {
        label: "СЕЧАЊ, БОКА",
        value: "СЕЧАЊ_БОКА_80373_800961",
    },
    {
        label: "СЕЧАЊ, ЈАРКОВАЦ",
        value: "СЕЧАЊ_ЈАРКОВАЦ_80373_802719",
    },
    {
        label: "СЕЧАЊ, ЈАША ТОМИЋ",
        value: "СЕЧАЊ_ЈАША ТОМИЋ_80373_800970",
    },
    {
        label: "СЕЧАЊ, КОНАК",
        value: "СЕЧАЊ_КОНАК_80373_800988",
    },
    {
        label: "СЕЧАЊ, КРАЈИШНИК",
        value: "СЕЧАЊ_КРАЈИШНИК_80373_800996",
    },
    {
        label: "СЕЧАЊ, НЕУЗИНА",
        value: "СЕЧАЊ_НЕУЗИНА_80373_801011",
    },
    {
        label: "СЕЧАЊ, СЕЧАЊ",
        value: "СЕЧАЊ_СЕЧАЊ_80373_801038",
    },
    {
        label: "СЕЧАЊ, СУТЈЕСКА",
        value: "СЕЧАЊ_СУТЈЕСКА_80373_801054",
    },
    {
        label: "СЕЧАЊ, ШУРЈАН",
        value: "СЕЧАЊ_ШУРЈАН_80373_801062",
    },
    {
        label: "СЈЕНИЦА, БАГАЧИЋЕ",
        value: "СЈЕНИЦА_БАГАЧИЋЕ_71072_739251",
    },
    {
        label: "СЈЕНИЦА, БАРЕ",
        value: "СЈЕНИЦА_БАРЕ_71072_739260",
    },
    {
        label: "СЈЕНИЦА, БАЧИЈА",
        value: "СЈЕНИЦА_БАЧИЈА_71072_739278",
    },
    {
        label: "СЈЕНИЦА, БОЖОВ ПОТОК",
        value: "СЈЕНИЦА_БОЖОВ ПОТОК_71072_739286",
    },
    {
        label: "СЈЕНИЦА, БОЉАРЕ",
        value: "СЈЕНИЦА_БОЉАРЕ_71072_739294",
    },
    {
        label: "СЈЕНИЦА, БРЊИЦА",
        value: "СЈЕНИЦА_БРЊИЦА_71072_739308",
    },
    {
        label: "СЈЕНИЦА, БУЂЕВО",
        value: "СЈЕНИЦА_БУЂЕВО_71072_739316",
    },
    {
        label: "СЈЕНИЦА, ВАПА",
        value: "СЈЕНИЦА_ВАПА_71072_739324",
    },
    {
        label: "СЈЕНИЦА, ВИШЊЕВА",
        value: "СЈЕНИЦА_ВИШЊЕВА_71072_739332",
    },
    {
        label: "СЈЕНИЦА, ВРСЈЕНИЦЕ",
        value: "СЈЕНИЦА_ВРСЈЕНИЦЕ_71072_739359",
    },
    {
        label: "СЈЕНИЦА, ГОРЊЕ ЛОПИЖЕ",
        value: "СЈЕНИЦА_ГОРЊЕ ЛОПИЖЕ_71072_739375",
    },
    {
        label: "СЈЕНИЦА, ГОШЕВО",
        value: "СЈЕНИЦА_ГОШЕВО_71072_739383",
    },
    {
        label: "СЈЕНИЦА, ДОЛИЋЕ",
        value: "СЈЕНИЦА_ДОЛИЋЕ_71072_739391",
    },
    {
        label: "СЈЕНИЦА, ДОЊЕ ГОРАЧИЋЕ",
        value: "СЈЕНИЦА_ДОЊЕ ГОРАЧИЋЕ_71072_739405",
    },
    {
        label: "СЈЕНИЦА, ДОЊЕ ЛОПИЖЕ",
        value: "СЈЕНИЦА_ДОЊЕ ЛОПИЖЕ_71072_739413",
    },
    {
        label: "СЈЕНИЦА, ДРАГОЈЛОВИЋЕ",
        value: "СЈЕНИЦА_ДРАГОЈЛОВИЋЕ_71072_739421",
    },
    {
        label: "СЈЕНИЦА, ДРАЖЕВИЋЕ",
        value: "СЈЕНИЦА_ДРАЖЕВИЋЕ_71072_739430",
    },
    {
        label: "СЈЕНИЦА, ДРУЖИНИЋЕ",
        value: "СЈЕНИЦА_ДРУЖИНИЋЕ_71072_739448",
    },
    {
        label: "СЈЕНИЦА, ДУБНИЦА",
        value: "СЈЕНИЦА_ДУБНИЦА_71072_739456",
    },
    {
        label: "СЈЕНИЦА, ДУГА ПОЉАНА",
        value: "СЈЕНИЦА_ДУГА ПОЉАНА_71072_739464",
    },
    {
        label: "СЈЕНИЦА, ДУЈКЕ",
        value: "СЈЕНИЦА_ДУЈКЕ_71072_739472",
    },
    {
        label: "СЈЕНИЦА, ДУНИШИЋЕ",
        value: "СЈЕНИЦА_ДУНИШИЋЕ_71072_739499",
    },
    {
        label: "СЈЕНИЦА, ЖАБРЕН",
        value: "СЈЕНИЦА_ЖАБРЕН_71072_739502",
    },
    {
        label: "СЈЕНИЦА, ЖИТНИЋЕ",
        value: "СЈЕНИЦА_ЖИТНИЋЕ_71072_739529",
    },
    {
        label: "СЈЕНИЦА, ЗАЈЕЧИЋЕ",
        value: "СЈЕНИЦА_ЗАЈЕЧИЋЕ_71072_739537",
    },
    {
        label: "СЈЕНИЦА, ЗАХУМСКО",
        value: "СЈЕНИЦА_ЗАХУМСКО_71072_739545",
    },
    {
        label: "СЈЕНИЦА, КАМЕШНИЦА",
        value: "СЈЕНИЦА_КАМЕШНИЦА_71072_739553",
    },
    {
        label: "СЈЕНИЦА, КИЈЕВЦИ",
        value: "СЈЕНИЦА_КИЈЕВЦИ_71072_739570",
    },
    {
        label: "СЈЕНИЦА, КЛАДНИЦА",
        value: "СЈЕНИЦА_КЛАДНИЦА_71072_739588",
    },
    {
        label: "СЈЕНИЦА, КРАЈИНОВИЋЕ",
        value: "СЈЕНИЦА_КРАЈИНОВИЋЕ_71072_739596",
    },
    {
        label: "СЈЕНИЦА, КРСТАЦ",
        value: "СЈЕНИЦА_КРСТАЦ_71072_739600",
    },
    {
        label: "СЈЕНИЦА, КРЋЕ",
        value: "СЈЕНИЦА_КРЋЕ_71072_739618",
    },
    {
        label: "СЈЕНИЦА, МАШОВИЋЕ",
        value: "СЈЕНИЦА_МАШОВИЋЕ_71072_739626",
    },
    {
        label: "СЈЕНИЦА, ПОНОРАЦ",
        value: "СЈЕНИЦА_ПОНОРАЦ_71072_739669",
    },
    {
        label: "СЈЕНИЦА, ПРАЉА",
        value: "СЈЕНИЦА_ПРАЉА_71072_739677",
    },
    {
        label: "СЈЕНИЦА, РАЖДАГИЊА",
        value: "СЈЕНИЦА_РАЖДАГИЊА_71072_739685",
    },
    {
        label: "СЈЕНИЦА, РАСНО",
        value: "СЈЕНИЦА_РАСНО_71072_739693",
    },
    {
        label: "СЈЕНИЦА, РАШКОВИЋЕ",
        value: "СЈЕНИЦА_РАШКОВИЋЕ_71072_739707",
    },
    {
        label: "СЈЕНИЦА, СЈЕНИЦА",
        value: "СЈЕНИЦА_СЈЕНИЦА_71072_739715",
    },
    {
        label: "СЈЕНИЦА, СТУП",
        value: "СЈЕНИЦА_СТУП_71072_739723",
    },
    {
        label: "СЈЕНИЦА, СУГУБИНЕ",
        value: "СЈЕНИЦА_СУГУБИНЕ_71072_739731",
    },
    {
        label: "СЈЕНИЦА, ТРИЈЕБИНЕ",
        value: "СЈЕНИЦА_ТРИЈЕБИНЕ_71072_739740",
    },
    {
        label: "СЈЕНИЦА, ТУЗИЊЕ",
        value: "СЈЕНИЦА_ТУЗИЊЕ_71072_739758",
    },
    {
        label: "СЈЕНИЦА, УВАЦ",
        value: "СЈЕНИЦА_УВАЦ_71072_739766",
    },
    {
        label: "СЈЕНИЦА, УГАО",
        value: "СЈЕНИЦА_УГАО_71072_739774",
    },
    {
        label: "СЈЕНИЦА, УРСУЛЕ",
        value: "СЈЕНИЦА_УРСУЛЕ_71072_739782",
    },
    {
        label: "СЈЕНИЦА, ФИЈУЉ",
        value: "СЈЕНИЦА_ФИЈУЉ_71072_739804",
    },
    {
        label: "СЈЕНИЦА, ЦАРИЧИНА",
        value: "СЈЕНИЦА_ЦАРИЧИНА_71072_739812",
    },
    {
        label: "СЈЕНИЦА, ЦЕТАНОВИЋЕ",
        value: "СЈЕНИЦА_ЦЕТАНОВИЋЕ_71072_739839",
    },
    {
        label: "СЈЕНИЦА, ЧЕДОВО",
        value: "СЈЕНИЦА_ЧЕДОВО_71072_739847",
    },
    {
        label: "СЈЕНИЦА, ШАРЕ",
        value: "СЈЕНИЦА_ШАРЕ_71072_739855",
    },
    {
        label: "СЈЕНИЦА, ШТАВАЉ",
        value: "СЈЕНИЦА_ШТАВАЉ_71072_739863",
    },
    {
        label: "СЈЕНИЦА, ШУШУРЕ",
        value: "СЈЕНИЦА_ШУШУРЕ_71072_739871",
    },
    {
        label: "СМЕДЕРЕВО, БАДЉЕВИЦА",
        value: "СМЕДЕРЕВО_БАДЉЕВИЦА_71099_739880",
    },
    {
        label: "СМЕДЕРЕВО, БИНОВАЦ",
        value: "СМЕДЕРЕВО_БИНОВАЦ_71099_739898",
    },
    {
        label: "СМЕДЕРЕВО, ВОДАЊ",
        value: "СМЕДЕРЕВО_ВОДАЊ_71099_739901",
    },
    {
        label: "СМЕДЕРЕВО, ВРАНОВО",
        value: "СМЕДЕРЕВО_ВРАНОВО_71099_739910",
    },
    {
        label: "СМЕДЕРЕВО, ВРБОВАЦ",
        value: "СМЕДЕРЕВО_ВРБОВАЦ_71099_739928",
    },
    {
        label: "СМЕДЕРЕВО, ВУЧАК",
        value: "СМЕДЕРЕВО_ВУЧАК_71099_739936",
    },
    {
        label: "СМЕДЕРЕВО, ДОБРИ ДО",
        value: "СМЕДЕРЕВО_ДОБРИ ДО_71099_739944",
    },
    {
        label: "СМЕДЕРЕВО, ДРУГОВАЦ I",
        value: "СМЕДЕРЕВО_ДРУГОВАЦ I_71099_739952",
    },
    {
        label: "СМЕДЕРЕВО, ДРУГОВАЦ II",
        value: "СМЕДЕРЕВО_ДРУГОВАЦ II_71099_739979",
    },
    {
        label: "СМЕДЕРЕВО, КОЛАРИ",
        value: "СМЕДЕРЕВО_КОЛАРИ_71099_739995",
    },
    {
        label: "СМЕДЕРЕВО, КУЛИЧ",
        value: "СМЕДЕРЕВО_КУЛИЧ_71099_740004",
    },
    {
        label: "СМЕДЕРЕВО, ЛАНДОЛ",
        value: "СМЕДЕРЕВО_ЛАНДОЛ_71099_740012",
    },
    {
        label: "СМЕДЕРЕВО, ЛИПЕ I",
        value: "СМЕДЕРЕВО_ЛИПЕ I_71099_740039",
    },
    {
        label: "СМЕДЕРЕВО, ЛИПЕ II",
        value: "СМЕДЕРЕВО_ЛИПЕ II_71099_740047",
    },
    {
        label: "СМЕДЕРЕВО, ЛУГАВЧИНА",
        value: "СМЕДЕРЕВО_ЛУГАВЧИНА_71099_740055",
    },
    {
        label: "СМЕДЕРЕВО, ЛУЊЕВАЦ",
        value: "СМЕДЕРЕВО_ЛУЊЕВАЦ_71099_740373",
    },
    {
        label: "СМЕДЕРЕВО, МАЛА КРСНА",
        value: "СМЕДЕРЕВО_МАЛА КРСНА_71099_740063",
    },
    {
        label: "СМЕДЕРЕВО, МАЛО ОРАШЈЕ",
        value: "СМЕДЕРЕВО_МАЛО ОРАШЈЕ_71099_740071",
    },
    {
        label: "СМЕДЕРЕВО, МИХАЈЛОВАЦ",
        value: "СМЕДЕРЕВО_МИХАЈЛОВАЦ_71099_740080",
    },
    {
        label: "СМЕДЕРЕВО, ОСИПАОНИЦА",
        value: "СМЕДЕРЕВО_ОСИПАОНИЦА_71099_740098",
    },
    {
        label: "СМЕДЕРЕВО, ПЕТРИЈЕВО",
        value: "СМЕДЕРЕВО_ПЕТРИЈЕВО_71099_740101",
    },
    {
        label: "СМЕДЕРЕВО, РАДИНАЦ",
        value: "СМЕДЕРЕВО_РАДИНАЦ_71099_740110",
    },
    {
        label: "СМЕДЕРЕВО, РАЉА",
        value: "СМЕДЕРЕВО_РАЉА_71099_740128",
    },
    {
        label: "СМЕДЕРЕВО, САРАОРЦИ",
        value: "СМЕДЕРЕВО_САРАОРЦИ_71099_708704",
    },
    {
        label: "СМЕДЕРЕВО, СЕОНЕ",
        value: "СМЕДЕРЕВО_СЕОНЕ_71099_740136",
    },
    {
        label: "СМЕДЕРЕВО, СКОБАЉ",
        value: "СМЕДЕРЕВО_СКОБАЉ_71099_740144",
    },
    {
        label: "СМЕДЕРЕВО, СМЕДЕРЕВО",
        value: "СМЕДЕРЕВО_СМЕДЕРЕВО_71099_740152",
    },
    {
        label: "СМЕДЕРЕВО, СУВОДОЛ",
        value: "СМЕДЕРЕВО_СУВОДОЛ_71099_740179",
    },
    {
        label: "СМЕДЕРЕВО, УДОВИЦЕ",
        value: "СМЕДЕРЕВО_УДОВИЦЕ_71099_740187",
    },
    {
        label: "СМЕДЕРЕВО, ШАЛИНАЦ",
        value: "СМЕДЕРЕВО_ШАЛИНАЦ_71099_740195",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, АЗАЊА",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_АЗАЊА_71102_740209",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, БАНИЧИНА",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_БАНИЧИНА_71102_740217",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, БАЧИНАЦ",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_БАЧИНАЦ_71102_740225",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, БАШИН",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_БАШИН_71102_740233",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, ВЛАШКИ ДО",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_ВЛАШКИ ДО_71102_740250",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, ВОДИЦЕ",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_ВОДИЦЕ_71102_740268",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, ГЛИБОВАЦ I",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_ГЛИБОВАЦ I_71102_740276",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, ГЛИБОВАЦ II",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_ГЛИБОВАЦ II_71102_740284",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, ГОЛОБОК",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_ГОЛОБОК_71102_740292",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, ГРЧАЦ",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_ГРЧАЦ_71102_740314",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, ГРЧАЦ ПАЛАНКА",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_ГРЧАЦ ПАЛАНКА_71102_745219",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, КУСАДАК",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_КУСАДАК_71102_740365",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, МАЛА ПЛАНА",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_МАЛА ПЛАНА_71102_708631",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, МРАМОРАЦ",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_МРАМОРАЦ_71102_740381",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, ПРИДВОРИЦЕ",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_ПРИДВОРИЦЕ_71102_740390",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, РАТАРИ",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_РАТАРИ_71102_740411",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, СЕЛЕВАЦ",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_СЕЛЕВАЦ_71102_740420",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, СМЕДЕРЕВСКА ПАЛАНКА I",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_СМЕДЕРЕВСКА ПАЛАНКА I_71102_740438",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, СМЕДЕРЕВСКА ПАЛАНКА II",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_СМЕДЕРЕВСКА ПАЛАНКА II_71102_740446",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, СМЕДЕРЕВСКА ПАЛАНКА III",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_СМЕДЕРЕВСКА ПАЛАНКА III_71102_745260",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, СТОЈАЧАК",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_СТОЈАЧАК_71102_740454",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА, ЦЕРОВАЦ",
        value: "СМЕДЕРЕВСКА ПАЛАНКА_ЦЕРОВАЦ_71102_740462",
    },
    {
        label: "СОКОБАЊА, БЕЛИ ПОТОК",
        value: "СОКОБАЊА_БЕЛИ ПОТОК_71129_740489",
    },
    {
        label: "СОКОБАЊА, БЛЕНДИЈА",
        value: "СОКОБАЊА_БЛЕНДИЈА_71129_740497",
    },
    {
        label: "СОКОБАЊА, БОГДИНАЦ",
        value: "СОКОБАЊА_БОГДИНАЦ_71129_740519",
    },
    {
        label: "СОКОБАЊА, ВРБОВАЦ",
        value: "СОКОБАЊА_ВРБОВАЦ_71129_740527",
    },
    {
        label: "СОКОБАЊА, ВРМЏА",
        value: "СОКОБАЊА_ВРМЏА_71129_740535",
    },
    {
        label: "СОКОБАЊА, ДУГО ПОЉЕ",
        value: "СОКОБАЊА_ДУГО ПОЉЕ_71129_740543",
    },
    {
        label: "СОКОБАЊА, ЖУЧКОВАЦ",
        value: "СОКОБАЊА_ЖУЧКОВАЦ_71129_740551",
    },
    {
        label: "СОКОБАЊА, ЈЕЗЕРО",
        value: "СОКОБАЊА_ЈЕЗЕРО_71129_740560",
    },
    {
        label: "СОКОБАЊА, ЈОШАНИЦА",
        value: "СОКОБАЊА_ЈОШАНИЦА_71129_740578",
    },
    {
        label: "СОКОБАЊА, МИЛУШИНАЦ",
        value: "СОКОБАЊА_МИЛУШИНАЦ_71129_740594",
    },
    {
        label: "СОКОБАЊА, МУЖИНАЦ",
        value: "СОКОБАЊА_МУЖИНАЦ_71129_740608",
    },
    {
        label: "СОКОБАЊА, НИКОЛИНАЦ",
        value: "СОКОБАЊА_НИКОЛИНАЦ_71129_740616",
    },
    {
        label: "СОКОБАЊА, НОВО СЕЛО",
        value: "СОКОБАЊА_НОВО СЕЛО_71129_740624",
    },
    {
        label: "СОКОБАЊА, ПОРУЖНИЦА",
        value: "СОКОБАЊА_ПОРУЖНИЦА_71129_740632",
    },
    {
        label: "СОКОБАЊА, РАДЕНКОВАЦ",
        value: "СОКОБАЊА_РАДЕНКОВАЦ_71129_740667",
    },
    {
        label: "СОКОБАЊА, РЕСНИК",
        value: "СОКОБАЊА_РЕСНИК_71129_740675",
    },
    {
        label: "СОКОБАЊА, РУЈЕВИЦА",
        value: "СОКОБАЊА_РУЈЕВИЦА_71129_740691",
    },
    {
        label: "СОКОБАЊА, СЕСАЛАЦ",
        value: "СОКОБАЊА_СЕСАЛАЦ_71129_740713",
    },
    {
        label: "СОКОБАЊА, СОКОБАЊА",
        value: "СОКОБАЊА_СОКОБАЊА_71129_740721",
    },
    {
        label: "СОКОБАЊА, ТРГОВИШТЕ",
        value: "СОКОБАЊА_ТРГОВИШТЕ_71129_740730",
    },
    {
        label: "СОКОБАЊА, ТРУБАРЕВАЦ",
        value: "СОКОБАЊА_ТРУБАРЕВАЦ_71129_740748",
    },
    {
        label: "СОКОБАЊА, ЦЕРОВИЦА",
        value: "СОКОБАЊА_ЦЕРОВИЦА_71129_740756",
    },
    {
        label: "СОКОБАЊА, ЧИТЛУК",
        value: "СОКОБАЊА_ЧИТЛУК_71129_740764",
    },
    {
        label: "СОКОБАЊА, ШАРБАНОВАЦ",
        value: "СОКОБАЊА_ШАРБАНОВАЦ_71129_740772",
    },
    {
        label: "СОМБОР, АЛЕКСА ШАНТИЋ",
        value: "СОМБОР_АЛЕКСА ШАНТИЋ_80381_803782",
    },
    {
        label: "СОМБОР, БАЧКИ БРЕГ",
        value: "СОМБОР_БАЧКИ БРЕГ_80381_802867",
    },
    {
        label: "СОМБОР, БАЧКИ МОНОШТОР",
        value: "СОМБОР_БАЧКИ МОНОШТОР_80381_803839",
    },
    {
        label: "СОМБОР, БЕЗДАН",
        value: "СОМБОР_БЕЗДАН_80381_803847",
    },
    {
        label: "СОМБОР, ГАКОВО",
        value: "СОМБОР_ГАКОВО_80381_803871",
    },
    {
        label: "СОМБОР, ДОРОСЛОВО",
        value: "СОМБОР_ДОРОСЛОВО_80381_803863",
    },
    {
        label: "СОМБОР, КЉАЈИЋЕВО",
        value: "СОМБОР_КЉАЈИЋЕВО_80381_803880",
    },
    {
        label: "СОМБОР, КОЛУТ",
        value: "СОМБОР_КОЛУТ_80381_803898",
    },
    {
        label: "СОМБОР, РАСТИНА",
        value: "СОМБОР_РАСТИНА_80381_803944",
    },
    {
        label: "СОМБОР, РИЂИЦА",
        value: "СОМБОР_РИЂИЦА_80381_802883",
    },
    {
        label: "СОМБОР, СВЕТОЗАР МИЛЕТИЋ",
        value: "СОМБОР_СВЕТОЗАР МИЛЕТИЋ_80381_804002",
    },
    {
        label: "СОМБОР, СОМБОР I",
        value: "СОМБОР_СОМБОР I_80381_803952",
    },
    {
        label: "СОМБОР, СОМБОР II",
        value: "СОМБОР_СОМБОР II_80381_805866",
    },
    {
        label: "СОМБОР, СТАНИШИЋ",
        value: "СОМБОР_СТАНИШИЋ_80381_803987",
    },
    {
        label: "СОМБОР, СТАПАР",
        value: "СОМБОР_СТАПАР_80381_803995",
    },
    {
        label: "СОМБОР, ТЕЛЕЧКА",
        value: "СОМБОР_ТЕЛЕЧКА_80381_804037",
    },
    {
        label: "СОМБОР, ЧОНОПЉА",
        value: "СОМБОР_ЧОНОПЉА_80381_803855",
    },
    {
        label: "СОПОТ, БАБЕ",
        value: "СОПОТ_БАБЕ_70238_728403",
    },
    {
        label: "СОПОТ, ГУБЕРЕВАЦ",
        value: "СОПОТ_ГУБЕРЕВАЦ_70238_728438",
    },
    {
        label: "СОПОТ, ДРЛУПА",
        value: "СОПОТ_ДРЛУПА_70238_728446",
    },
    {
        label: "СОПОТ, ДУЧИНА",
        value: "СОПОТ_ДУЧИНА_70238_728454",
    },
    {
        label: "СОПОТ, ЂУРИНЦИ",
        value: "СОПОТ_ЂУРИНЦИ_70238_728462",
    },
    {
        label: "СОПОТ, МАЛА ИВАНЧА",
        value: "СОПОТ_МАЛА ИВАНЧА_70238_728519",
    },
    {
        label: "СОПОТ, МАЛИ ПОЖАРЕВАЦ",
        value: "СОПОТ_МАЛИ ПОЖАРЕВАЦ_70238_728527",
    },
    {
        label: "СОПОТ, НЕМЕНИКУЋЕ",
        value: "СОПОТ_НЕМЕНИКУЋЕ_70238_728560",
    },
    {
        label: "СОПОТ, ПАРЦАНИ",
        value: "СОПОТ_ПАРЦАНИ_70238_728578",
    },
    {
        label: "СОПОТ, ПОПОВИЋ",
        value: "СОПОТ_ПОПОВИЋ_70238_728586",
    },
    {
        label: "СОПОТ, РАЉА",
        value: "СОПОТ_РАЉА_70238_728616",
    },
    {
        label: "СОПОТ, РОГАЧА",
        value: "СОПОТ_РОГАЧА_70238_728632",
    },
    {
        label: "СОПОТ, СИБНИЦА",
        value: "СОПОТ_СИБНИЦА_70238_728667",
    },
    {
        label: "СОПОТ, СЛАТИНА",
        value: "СОПОТ_СЛАТИНА_70238_728675",
    },
    {
        label: "СОПОТ, СОПОТ",
        value: "СОПОТ_СОПОТ_70238_728683",
    },
    {
        label: "СОПОТ, СТОЈНИК",
        value: "СОПОТ_СТОЈНИК_70238_728691",
    },
    {
        label: "СРБОБРАН, НАДАЉ I",
        value: "СРБОБРАН_НАДАЉ I_80390_804681",
    },
    {
        label: "СРБОБРАН, НАДАЉ II",
        value: "СРБОБРАН_НАДАЉ II_80390_804690",
    },
    {
        label: "СРБОБРАН, СРБОБРАН",
        value: "СРБОБРАН_СРБОБРАН_80390_803740",
    },
    {
        label: "СРБОБРАН, ТУРИЈА",
        value: "СРБОБРАН_ТУРИЈА_80390_803766",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, БЕШЕНОВО ПРЊАВОР",
        value: "СРЕМСКА МИТРОВИЦА_БЕШЕНОВО ПРЊАВОР_80403_802930",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, БЕШЕНОВО СЕЛО",
        value: "СРЕМСКА МИТРОВИЦА_БЕШЕНОВО СЕЛО_80403_802921",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, БОСУТ",
        value: "СРЕМСКА МИТРОВИЦА_БОСУТ_80403_804061",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, ВЕЛИКИ РАДИНЦИ",
        value: "СРЕМСКА МИТРОВИЦА_ВЕЛИКИ РАДИНЦИ_80403_803391",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, ГРГУРЕВЦИ",
        value: "СРЕМСКА МИТРОВИЦА_ГРГУРЕВЦИ_80403_803014",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, ДИВОШ",
        value: "СРЕМСКА МИТРОВИЦА_ДИВОШ_80403_803235",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, ЗАСАВИЦА",
        value: "СРЕМСКА МИТРОВИЦА_ЗАСАВИЦА_80403_801771",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, ЈАРАК",
        value: "СРЕМСКА МИТРОВИЦА_ЈАРАК_80403_804126",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, КУЗМИН",
        value: "СРЕМСКА МИТРОВИЦА_КУЗМИН_80403_804142",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, ЛАЋАРАК",
        value: "СРЕМСКА МИТРОВИЦА_ЛАЋАРАК_80403_804169",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, ЛЕЖИМИР",
        value: "СРЕМСКА МИТРОВИЦА_ЛЕЖИМИР_80403_803138",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, МАНЂЕЛОС",
        value: "СРЕМСКА МИТРОВИЦА_МАНЂЕЛОС_80403_803189",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, МАРТИНЦИ",
        value: "СРЕМСКА МИТРОВИЦА_МАРТИНЦИ_80403_804177",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, МАЧВАНСКА МИТРОВИЦА",
        value: "СРЕМСКА МИТРОВИЦА_МАЧВАНСКА МИТРОВИЦА_80403_801712",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, НОЋАЈ",
        value: "СРЕМСКА МИТРОВИЦА_НОЋАЈ_80403_801739",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, РАВЊЕ",
        value: "СРЕМСКА МИТРОВИЦА_РАВЊЕ_80403_801755",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, РАДЕНКОВИЋ",
        value: "СРЕМСКА МИТРОВИЦА_РАДЕНКОВИЋ_80403_801747",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, САЛАШ НОЋАЈСКИ",
        value: "СРЕМСКА МИТРОВИЦА_САЛАШ НОЋАЈСКИ_80403_801763",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, СРЕМСКА МИТРОВИЦА",
        value: "СРЕМСКА МИТРОВИЦА_СРЕМСКА МИТРОВИЦА_80403_804231",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, СРЕМСКА РАЧА",
        value: "СРЕМСКА МИТРОВИЦА_СРЕМСКА РАЧА_80403_804240",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, ЧАЛМА",
        value: "СРЕМСКА МИТРОВИЦА_ЧАЛМА_80403_802964",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, ШАШИНЦИ",
        value: "СРЕМСКА МИТРОВИЦА_ШАШИНЦИ_80403_804266",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА, ШУЉАМ",
        value: "СРЕМСКА МИТРОВИЦА_ШУЉАМ_80403_803375",
    },
    {
        label: "СРЕМСКИ КАРЛОВЦИ, СРЕМСКИ КАРЛОВЦИ",
        value: "СРЕМСКИ КАРЛОВЦИ_СРЕМСКИ КАРЛОВЦИ_80411_805408",
    },
    {
        label: "СТАРА ПАЗОВА, БЕЛЕГИШ",
        value: "СТАРА ПАЗОВА_БЕЛЕГИШ_80420_805181",
    },
    {
        label: "СТАРА ПАЗОВА, ВОЈКА",
        value: "СТАРА ПАЗОВА_ВОЈКА_80420_805483",
    },
    {
        label: "СТАРА ПАЗОВА, ГОЛУБИНЦИ",
        value: "СТАРА ПАЗОВА_ГОЛУБИНЦИ_80420_805238",
    },
    {
        label: "СТАРА ПАЗОВА, КРЊЕШЕВЦИ",
        value: "СТАРА ПАЗОВА_КРЊЕШЕВЦИ_80420_805289",
    },
    {
        label: "СТАРА ПАЗОВА, НОВА ПАЗОВА",
        value: "СТАРА ПАЗОВА_НОВА ПАЗОВА_80420_805319",
    },
    {
        label: "СТАРА ПАЗОВА, НОВИ БАНОВЦИ",
        value: "СТАРА ПАЗОВА_НОВИ БАНОВЦИ_80420_805327",
    },
    {
        label: "СТАРА ПАЗОВА, СТАРА ПАЗОВА",
        value: "СТАРА ПАЗОВА_СТАРА ПАЗОВА_80420_805424",
    },
    {
        label: "СТАРА ПАЗОВА, СТАРИ БАНОВЦИ",
        value: "СТАРА ПАЗОВА_СТАРИ БАНОВЦИ_80420_805432",
    },
    {
        label: "СТАРА ПАЗОВА, СУРДУК",
        value: "СТАРА ПАЗОВА_СУРДУК_80420_805467",
    },
    {
        label: "СТАРИ ГРАД, СТАРИ ГРАД",
        value: "СТАРИ ГРАД_СТАРИ ГРАД_70246_704059",
    },
    {
        label: "СУБОТИЦА, БАЈМОК",
        value: "СУБОТИЦА_БАЈМОК_80438_804347",
    },
    {
        label: "СУБОТИЦА, БАЧКИ ВИНОГРАДИ",
        value: "СУБОТИЦА_БАЧКИ ВИНОГРАДИ_80438_804339",
    },
    {
        label: "СУБОТИЦА, БИКОВО",
        value: "СУБОТИЦА_БИКОВО_80438_804363",
    },
    {
        label: "СУБОТИЦА, ДОЊИ ГРАД",
        value: "СУБОТИЦА_ДОЊИ ГРАД_80438_804398",
    },
    {
        label: "СУБОТИЦА, ЂУРЂИН",
        value: "СУБОТИЦА_ЂУРЂИН_80438_804410",
    },
    {
        label: "СУБОТИЦА, ЖЕДНИК",
        value: "СУБОТИЦА_ЖЕДНИК_80438_804533",
    },
    {
        label: "СУБОТИЦА, НОВИ ГРАД",
        value: "СУБОТИЦА_НОВИ ГРАД_80438_804479",
    },
    {
        label: "СУБОТИЦА, ПАЛИЋ",
        value: "СУБОТИЦА_ПАЛИЋ_80438_804495",
    },
    {
        label: "СУБОТИЦА, СТАРИ ГРАД",
        value: "СУБОТИЦА_СТАРИ ГРАД_80438_804517",
    },
    {
        label: "СУБОТИЦА, ТАВАНКУТ",
        value: "СУБОТИЦА_ТАВАНКУТ_80438_804525",
    },
    {
        label: "СУБОТИЦА, ЧАНТАВИР",
        value: "СУБОТИЦА_ЧАНТАВИР_80438_804371",
    },
    {
        label: "СУРДУЛИЦА, АЛАКИНЦЕ",
        value: "СУРДУЛИЦА_АЛАКИНЦЕ_71137_740799",
    },
    {
        label: "СУРДУЛИЦА, БАЦИЈЕВЦЕ",
        value: "СУРДУЛИЦА_БАЦИЈЕВЦЕ_71137_740802",
    },
    {
        label: "СУРДУЛИЦА, БИНОВЦЕ",
        value: "СУРДУЛИЦА_БИНОВЦЕ_71137_740829",
    },
    {
        label: "СУРДУЛИЦА, БИТВРЂА",
        value: "СУРДУЛИЦА_БИТВРЂА_71137_740837",
    },
    {
        label: "СУРДУЛИЦА, БОЖИЦА",
        value: "СУРДУЛИЦА_БОЖИЦА_71137_705403",
    },
    {
        label: "СУРДУЛИЦА, ВЛАСИНА ОКРУГЛИЦА",
        value: "СУРДУЛИЦА_ВЛАСИНА ОКРУГЛИЦА_71137_740845",
    },
    {
        label: "СУРДУЛИЦА, ВЛАСИНА РИД",
        value: "СУРДУЛИЦА_ВЛАСИНА РИД_71137_740853",
    },
    {
        label: "СУРДУЛИЦА, ВЛАСИНА СТОЈКОВИЋЕВА",
        value: "СУРДУЛИЦА_ВЛАСИНА СТОЈКОВИЋЕВА_71137_740861",
    },
    {
        label: "СУРДУЛИЦА, ВУЧАДЕЛЦЕ",
        value: "СУРДУЛИЦА_ВУЧАДЕЛЦЕ_71137_740870",
    },
    {
        label: "СУРДУЛИЦА, ГОРЊА КОЗНИЦА",
        value: "СУРДУЛИЦА_ГОРЊА КОЗНИЦА_71137_740888",
    },
    {
        label: "СУРДУЛИЦА, ГОРЊЕ РОМАНОВЦЕ",
        value: "СУРДУЛИЦА_ГОРЊЕ РОМАНОВЦЕ_71137_740896",
    },
    {
        label: "СУРДУЛИЦА, ГРОЗНАТОВЦИ",
        value: "СУРДУЛИЦА_ГРОЗНАТОВЦИ_71137_705543",
    },
    {
        label: "СУРДУЛИЦА, ДАЊИНО СЕЛО",
        value: "СУРДУЛИЦА_ДАЊИНО СЕЛО_71137_740900",
    },
    {
        label: "СУРДУЛИЦА, ДИКАВА",
        value: "СУРДУЛИЦА_ДИКАВА_71137_740918",
    },
    {
        label: "СУРДУЛИЦА, ДОЊЕ РОМАНОВЦЕ",
        value: "СУРДУЛИЦА_ДОЊЕ РОМАНОВЦЕ_71137_740926",
    },
    {
        label: "СУРДУЛИЦА, ДРАЈИНЦИ",
        value: "СУРДУЛИЦА_ДРАЈИНЦИ_71137_705616",
    },
    {
        label: "СУРДУЛИЦА, ДУГИ ДЕЛ",
        value: "СУРДУЛИЦА_ДУГИ ДЕЛ_71137_740934",
    },
    {
        label: "СУРДУЛИЦА, ДУГОЈНИЦА",
        value: "СУРДУЛИЦА_ДУГОЈНИЦА_71137_740942",
    },
    {
        label: "СУРДУЛИЦА, ЗАГУЖАЊЕ",
        value: "СУРДУЛИЦА_ЗАГУЖАЊЕ_71137_740969",
    },
    {
        label: "СУРДУЛИЦА, ЈЕЛАШНИЦА",
        value: "СУРДУЛИЦА_ЈЕЛАШНИЦА_71137_740977",
    },
    {
        label: "СУРДУЛИЦА, КАЛАБОВЦЕ",
        value: "СУРДУЛИЦА_КАЛАБОВЦЕ_71137_740985",
    },
    {
        label: "СУРДУЛИЦА, КИЈЕВАЦ",
        value: "СУРДУЛИЦА_КИЈЕВАЦ_71137_740993",
    },
    {
        label: "СУРДУЛИЦА, КЛИСУРА",
        value: "СУРДУЛИЦА_КЛИСУРА_71137_705691",
    },
    {
        label: "СУРДУЛИЦА, КОЛУНИЦА",
        value: "СУРДУЛИЦА_КОЛУНИЦА_71137_705705",
    },
    {
        label: "СУРДУЛИЦА, КОСТРОШЕВЦИ",
        value: "СУРДУЛИЦА_КОСТРОШЕВЦИ_71137_705713",
    },
    {
        label: "СУРДУЛИЦА, ЛЕСКОВА БАРА",
        value: "СУРДУЛИЦА_ЛЕСКОВА БАРА_71137_741027",
    },
    {
        label: "СУРДУЛИЦА, МАСУРИЦА",
        value: "СУРДУЛИЦА_МАСУРИЦА_71137_741035",
    },
    {
        label: "СУРДУЛИЦА, МАЧКАТИЦА",
        value: "СУРДУЛИЦА_МАЧКАТИЦА_71137_741043",
    },
    {
        label: "СУРДУЛИЦА, НОВО СЕЛО",
        value: "СУРДУЛИЦА_НОВО СЕЛО_71137_741051",
    },
    {
        label: "СУРДУЛИЦА, ПАЉА",
        value: "СУРДУЛИЦА_ПАЉА_71137_705764",
    },
    {
        label: "СУРДУЛИЦА, РЂАВИЦА",
        value: "СУРДУЛИЦА_РЂАВИЦА_71137_741060",
    },
    {
        label: "СУРДУЛИЦА, СТАЈКОВЦЕ",
        value: "СУРДУЛИЦА_СТАЈКОВЦЕ_71137_741078",
    },
    {
        label: "СУРДУЛИЦА, СТРЕЗИМИРОВЦИ",
        value: "СУРДУЛИЦА_СТРЕЗИМИРОВЦИ_71137_705861",
    },
    {
        label: "СУРДУЛИЦА, СУВОЈНИЦА",
        value: "СУРДУЛИЦА_СУВОЈНИЦА_71137_741086",
    },
    {
        label: "СУРДУЛИЦА, СУРДУЛИЦА",
        value: "СУРДУЛИЦА_СУРДУЛИЦА_71137_741094",
    },
    {
        label: "СУРДУЛИЦА, СУХИ ДОЛ",
        value: "СУРДУЛИЦА_СУХИ ДОЛ_71137_705870",
    },
    {
        label: "СУРДУЛИЦА, ТОПЛИ ДО",
        value: "СУРДУЛИЦА_ТОПЛИ ДО_71137_741108",
    },
    {
        label: "СУРДУЛИЦА, ТОПЛИ ДОЛ",
        value: "СУРДУЛИЦА_ТОПЛИ ДОЛ_71137_705888",
    },
    {
        label: "СУРДУЛИЦА, ЋУРКОВИЦА",
        value: "СУРДУЛИЦА_ЋУРКОВИЦА_71137_741116",
    },
    {
        label: "СУРЧИН, БЕЧМЕН",
        value: "СУРЧИН_БЕЧМЕН_71293_716022",
    },
    {
        label: "СУРЧИН, БОЉЕВЦИ",
        value: "СУРЧИН_БОЉЕВЦИ_71293_716049",
    },
    {
        label: "СУРЧИН, ДОБАНОВЦИ",
        value: "СУРЧИН_ДОБАНОВЦИ_71293_716057",
    },
    {
        label: "СУРЧИН, ЈАКОВО",
        value: "СУРЧИН_ЈАКОВО_71293_716081",
    },
    {
        label: "СУРЧИН, ПЕТРОВЧИЋ",
        value: "СУРЧИН_ПЕТРОВЧИЋ_71293_716103",
    },
    {
        label: "СУРЧИН, ПРОГАР",
        value: "СУРЧИН_ПРОГАР_71293_716111",
    },
    {
        label: "СУРЧИН, СУРЧИН",
        value: "СУРЧИН_СУРЧИН_71293_716120",
    },
    {
        label: "ТЕМЕРИН, БАЧКИ ЈАРАК",
        value: "ТЕМЕРИН_БАЧКИ ЈАРАК_80446_802034",
    },
    {
        label: "ТЕМЕРИН, СИРИГ",
        value: "ТЕМЕРИН_СИРИГ_80446_802247",
    },
    {
        label: "ТЕМЕРИН, ТЕМЕРИН",
        value: "ТЕМЕРИН_ТЕМЕРИН_80446_802280",
    },
    {
        label: "ТИТЕЛ, ВИЛОВО",
        value: "ТИТЕЛ_ВИЛОВО_80454_804738",
    },
    {
        label: "ТИТЕЛ, ГАРДИНОВЦИ",
        value: "ТИТЕЛ_ГАРДИНОВЦИ_80454_804592",
    },
    {
        label: "ТИТЕЛ, ЛОК",
        value: "ТИТЕЛ_ЛОК_80454_804665",
    },
    {
        label: "ТИТЕЛ, МОШОРИН",
        value: "ТИТЕЛ_МОШОРИН_80454_804673",
    },
    {
        label: "ТИТЕЛ, ТИТЕЛ",
        value: "ТИТЕЛ_ТИТЕЛ_80454_804720",
    },
    {
        label: "ТИТЕЛ, ШАЈКАШ",
        value: "ТИТЕЛ_ШАЈКАШ_80454_804711",
    },
    {
        label: "ТОПОЛА, БЕЛОСАВЦИ",
        value: "ТОПОЛА_БЕЛОСАВЦИ_71153_701378",
    },
    {
        label: "ТОПОЛА, БЛАЗНАВА",
        value: "ТОПОЛА_БЛАЗНАВА_71153_701386",
    },
    {
        label: "ТОПОЛА, БОЖУРЊА",
        value: "ТОПОЛА_БОЖУРЊА_71153_701394",
    },
    {
        label: "ТОПОЛА, ВИНЧА",
        value: "ТОПОЛА_ВИНЧА_71153_701424",
    },
    {
        label: "ТОПОЛА, ВОЈКОВЦИ",
        value: "ТОПОЛА_ВОЈКОВЦИ_71153_727016",
    },
    {
        label: "ТОПОЛА, ГОРЊА ШАТОРЊА",
        value: "ТОПОЛА_ГОРЊА ШАТОРЊА_71153_701491",
    },
    {
        label: "ТОПОЛА, ГОРОВИЧ",
        value: "ТОПОЛА_ГОРОВИЧ_71153_736490",
    },
    {
        label: "ТОПОЛА, ГУРИШЕВЦИ",
        value: "ТОПОЛА_ГУРИШЕВЦИ_71153_727032",
    },
    {
        label: "ТОПОЛА, ДОЊА ТРЕШЊЕВИЦА",
        value: "ТОПОЛА_ДОЊА ТРЕШЊЕВИЦА_71153_701505",
    },
    {
        label: "ТОПОЛА, ДОЊА ШАТОРЊА",
        value: "ТОПОЛА_ДОЊА ШАТОРЊА_71153_701513",
    },
    {
        label: "ТОПОЛА, ЖАБАРЕ",
        value: "ТОПОЛА_ЖАБАРЕ_71153_736546",
    },
    {
        label: "ТОПОЛА, ЗАГОРИЦА",
        value: "ТОПОЛА_ЗАГОРИЦА_71153_701521",
    },
    {
        label: "ТОПОЛА, ЈАРМЕНОВЦИ",
        value: "ТОПОЛА_ЈАРМЕНОВЦИ_71153_727121",
    },
    {
        label: "ТОПОЛА, ЈЕЛЕНАЦ",
        value: "ТОПОЛА_ЈЕЛЕНАЦ_71153_701530",
    },
    {
        label: "ТОПОЛА, ЈУНКОВАЦ",
        value: "ТОПОЛА_ЈУНКОВАЦ_71153_736554",
    },
    {
        label: "ТОПОЛА, КЛОКА",
        value: "ТОПОЛА_КЛОКА_71153_736562",
    },
    {
        label: "ТОПОЛА, ЛИПОВАЦ",
        value: "ТОПОЛА_ЛИПОВАЦ_71153_701572",
    },
    {
        label: "ТОПОЛА, МАНОЈЛОВЦИ",
        value: "ТОПОЛА_МАНОЈЛОВЦИ_71153_727202",
    },
    {
        label: "ТОПОЛА, МАСКАР",
        value: "ТОПОЛА_МАСКАР_71153_701629",
    },
    {
        label: "ТОПОЛА, НАТАЛИНЦИ",
        value: "ТОПОЛА_НАТАЛИНЦИ_71153_736619",
    },
    {
        label: "ТОПОЛА, ОВСИШТЕ",
        value: "ТОПОЛА_ОВСИШТЕ_71153_701653",
    },
    {
        label: "ТОПОЛА, ПАВЛОВАЦ",
        value: "ТОПОЛА_ПАВЛОВАЦ_71153_736627",
    },
    {
        label: "ТОПОЛА, ПЛАСКОВАЦ",
        value: "ТОПОЛА_ПЛАСКОВАЦ_71153_701670",
    },
    {
        label: "ТОПОЛА, РАЈКОВАЦ",
        value: "ТОПОЛА_РАЈКОВАЦ_71153_701688",
    },
    {
        label: "ТОПОЛА, СВЕТЛИЋ",
        value: "ТОПОЛА_СВЕТЛИЋ_71153_719471",
    },
    {
        label: "ТОПОЛА, ТОПОЛА (ВАРОШИЦА)",
        value: "ТОПОЛА_ТОПОЛА (ВАРОШИЦА)_71153_701718",
    },
    {
        label: "ТОПОЛА, ТОПОЛА (СЕЛО)",
        value: "ТОПОЛА_ТОПОЛА (СЕЛО)_71153_701726",
    },
    {
        label: "ТОПОЛА, ТРНАВА",
        value: "ТОПОЛА_ТРНАВА_71153_736686",
    },
    {
        label: "ТОПОЛА, ШУМЕ",
        value: "ТОПОЛА_ШУМЕ_71153_736708",
    },
    {
        label: "ТРГОВИШТЕ, БАБИНА ПОЉАНА",
        value: "ТРГОВИШТЕ_БАБИНА ПОЉАНА_71161_711110",
    },
    {
        label: "ТРГОВИШТЕ, БАРБАЦЕ",
        value: "ТРГОВИШТЕ_БАРБАЦЕ_71161_706604",
    },
    {
        label: "ТРГОВИШТЕ, ВЛАДОВЦЕ",
        value: "ТРГОВИШТЕ_ВЛАДОВЦЕ_71161_706795",
    },
    {
        label: "ТРГОВИШТЕ, ГОЛОЧЕВАЦ",
        value: "ТРГОВИШТЕ_ГОЛОЧЕВАЦ_71161_711284",
    },
    {
        label: "ТРГОВИШТЕ, ГОРНОВАЦ",
        value: "ТРГОВИШТЕ_ГОРНОВАЦ_71161_711292",
    },
    {
        label: "ТРГОВИШТЕ, ГОРЊА ТРНИЦА",
        value: "ТРГОВИШТЕ_ГОРЊА ТРНИЦА_71161_711314",
    },
    {
        label: "ТРГОВИШТЕ, ГОРЊИ СТАЈЕВАЦ",
        value: "ТРГОВИШТЕ_ГОРЊИ СТАЈЕВАЦ_71161_711381",
    },
    {
        label: "ТРГОВИШТЕ, ДЕЈАНЦЕ",
        value: "ТРГОВИШТЕ_ДЕЈАНЦЕ_71161_711420",
    },
    {
        label: "ТРГОВИШТЕ, ДОЊА ТРНИЦА",
        value: "ТРГОВИШТЕ_ДОЊА ТРНИЦА_71161_711454",
    },
    {
        label: "ТРГОВИШТЕ, ДОЊИ СТАЈЕВАЦ",
        value: "ТРГОВИШТЕ_ДОЊИ СТАЈЕВАЦ_71161_711535",
    },
    {
        label: "ТРГОВИШТЕ, ДУМБИЈА",
        value: "ТРГОВИШТЕ_ДУМБИЈА_71161_711586",
    },
    {
        label: "ТРГОВИШТЕ, ЂЕРЕКАРЦЕ",
        value: "ТРГОВИШТЕ_ЂЕРЕКАРЦЕ_71161_711608",
    },
    {
        label: "ТРГОВИШТЕ, ЗЛАДОВЦЕ",
        value: "ТРГОВИШТЕ_ЗЛАДОВЦЕ_71161_711616",
    },
    {
        label: "ТРГОВИШТЕ, КАЛОВО",
        value: "ТРГОВИШТЕ_КАЛОВО_71161_711659",
    },
    {
        label: "ТРГОВИШТЕ, КОЗЈИ ДОЛ",
        value: "ТРГОВИШТЕ_КОЗЈИ ДОЛ_71161_711691",
    },
    {
        label: "ТРГОВИШТЕ, ЛЕСНИЦА",
        value: "ТРГОВИШТЕ_ЛЕСНИЦА_71161_711837",
    },
    {
        label: "ТРГОВИШТЕ, МАЛА РЕКА",
        value: "ТРГОВИШТЕ_МАЛА РЕКА_71161_707210",
    },
    {
        label: "ТРГОВИШТЕ, МАРГАНЦЕ",
        value: "ТРГОВИШТЕ_МАРГАНЦЕ_71161_711861",
    },
    {
        label: "ТРГОВИШТЕ, МЕЗДРАЈА",
        value: "ТРГОВИШТЕ_МЕЗДРАЈА_71161_707236",
    },
    {
        label: "ТРГОВИШТЕ, НОВИ ГЛОГ",
        value: "ТРГОВИШТЕ_НОВИ ГЛОГ_71161_711977",
    },
    {
        label: "ТРГОВИШТЕ, НОВО СЕЛО",
        value: "ТРГОВИШТЕ_НОВО СЕЛО_71161_707295",
    },
    {
        label: "ТРГОВИШТЕ, ПЕТРОВАЦ",
        value: "ТРГОВИШТЕ_ПЕТРОВАЦ_71161_707341",
    },
    {
        label: "ТРГОВИШТЕ, ПРОЛЕСЈЕ",
        value: "ТРГОВИШТЕ_ПРОЛЕСЈЕ_71161_712060",
    },
    {
        label: "ТРГОВИШТЕ, РАДОВНИЦА",
        value: "ТРГОВИШТЕ_РАДОВНИЦА_71161_712078",
    },
    {
        label: "ТРГОВИШТЕ, РАЈЧЕВЦЕ",
        value: "ТРГОВИШТЕ_РАЈЧЕВЦЕ_71161_707414",
    },
    {
        label: "ТРГОВИШТЕ, СУРЛИЦА",
        value: "ТРГОВИШТЕ_СУРЛИЦА_71161_712345",
    },
    {
        label: "ТРГОВИШТЕ, ТРГОВИШТЕ",
        value: "ТРГОВИШТЕ_ТРГОВИШТЕ_71161_712388",
    },
    {
        label: "ТРГОВИШТЕ, ЦРВЕНИ ГРАД",
        value: "ТРГОВИШТЕ_ЦРВЕНИ ГРАД_71161_712442",
    },
    {
        label: "ТРГОВИШТЕ, ЦРНА РЕКА",
        value: "ТРГОВИШТЕ_ЦРНА РЕКА_71161_712469",
    },
    {
        label: "ТРГОВИШТЕ, ЦРНОВЦЕ",
        value: "ТРГОВИШТЕ_ЦРНОВЦЕ_71161_712493",
    },
    {
        label: "ТРГОВИШТЕ, ШАЈИНЦЕ",
        value: "ТРГОВИШТЕ_ШАЈИНЦЕ_71161_712515",
    },
    {
        label: "ТРГОВИШТЕ, ШАПРАНЦЕ",
        value: "ТРГОВИШТЕ_ШАПРАНЦЕ_71161_712523",
    },
    {
        label: "ТРГОВИШТЕ, ШИРОКА ПЛАНИНА",
        value: "ТРГОВИШТЕ_ШИРОКА ПЛАНИНА_71161_712531",
    },
    {
        label: "ТРГОВИШТЕ, ШУМАТА ТРНИЦА",
        value: "ТРГОВИШТЕ_ШУМАТА ТРНИЦА_71161_707708",
    },
    {
        label: "ТРСТЕНИК, БОГДАЊЕ",
        value: "ТРСТЕНИК_БОГДАЊЕ_71170_741485",
    },
    {
        label: "ТРСТЕНИК, БОЖУРЕВАЦ",
        value: "ТРСТЕНИК_БОЖУРЕВАЦ_71170_741493",
    },
    {
        label: "ТРСТЕНИК, БРЕЗОВИЦА",
        value: "ТРСТЕНИК_БРЕЗОВИЦА_71170_741507",
    },
    {
        label: "ТРСТЕНИК, БРЕСНО ПОЉЕ",
        value: "ТРСТЕНИК_БРЕСНО ПОЉЕ_71170_741515",
    },
    {
        label: "ТРСТЕНИК, БУЧЈЕ",
        value: "ТРСТЕНИК_БУЧЈЕ_71170_741523",
    },
    {
        label: "ТРСТЕНИК, ВЕЛИКА ДРЕНОВА",
        value: "ТРСТЕНИК_ВЕЛИКА ДРЕНОВА_71170_741531",
    },
    {
        label: "ТРСТЕНИК, ВЕЛУЋЕ",
        value: "ТРСТЕНИК_ВЕЛУЋЕ_71170_741540",
    },
    {
        label: "ТРСТЕНИК, ГОЛУБОВАЦ",
        value: "ТРСТЕНИК_ГОЛУБОВАЦ_71170_741566",
    },
    {
        label: "ТРСТЕНИК, ГОРЊА ЦРНИШАВА",
        value: "ТРСТЕНИК_ГОРЊА ЦРНИШАВА_71170_741574",
    },
    {
        label: "ТРСТЕНИК, ГОРЊИ ДУБИЧ",
        value: "ТРСТЕНИК_ГОРЊИ ДУБИЧ_71170_737585",
    },
    {
        label: "ТРСТЕНИК, ГОРЊИ РИБНИК",
        value: "ТРСТЕНИК_ГОРЊИ РИБНИК_71170_741582",
    },
    {
        label: "ТРСТЕНИК, ГРАБОВАЦ",
        value: "ТРСТЕНИК_ГРАБОВАЦ_71170_741612",
    },
    {
        label: "ТРСТЕНИК, ДОЊА ЦРНИШАВА",
        value: "ТРСТЕНИК_ДОЊА ЦРНИШАВА_71170_741639",
    },
    {
        label: "ТРСТЕНИК, ДОЊИ ДУБИЧ",
        value: "ТРСТЕНИК_ДОЊИ ДУБИЧ_71170_741647",
    },
    {
        label: "ТРСТЕНИК, ДОЊИ РИБНИК",
        value: "ТРСТЕНИК_ДОЊИ РИБНИК_71170_741655",
    },
    {
        label: "ТРСТЕНИК, ДУБЉЕ",
        value: "ТРСТЕНИК_ДУБЉЕ_71170_741663",
    },
    {
        label: "ТРСТЕНИК, ЈАСИКОВИЦА",
        value: "ТРСТЕНИК_ЈАСИКОВИЦА_71170_741671",
    },
    {
        label: "ТРСТЕНИК, КАМЕЊАЧА",
        value: "ТРСТЕНИК_КАМЕЊАЧА_71170_741680",
    },
    {
        label: "ТРСТЕНИК, ЛОБОДЕР",
        value: "ТРСТЕНИК_ЛОБОДЕР_71170_741710",
    },
    {
        label: "ТРСТЕНИК, ЛОЗНА",
        value: "ТРСТЕНИК_ЛОЗНА_71170_741728",
    },
    {
        label: "ТРСТЕНИК, ЛОПАШ",
        value: "ТРСТЕНИК_ЛОПАШ_71170_741736",
    },
    {
        label: "ТРСТЕНИК, МАЛА ДРЕНОВА",
        value: "ТРСТЕНИК_МАЛА ДРЕНОВА_71170_741744",
    },
    {
        label: "ТРСТЕНИК, МАЛА СУГУБИНА",
        value: "ТРСТЕНИК_МАЛА СУГУБИНА_71170_741752",
    },
    {
        label: "ТРСТЕНИК, МЕДВЕЂА",
        value: "ТРСТЕНИК_МЕДВЕЂА_71170_741779",
    },
    {
        label: "ТРСТЕНИК, МИЈАЈЛОВАЦ",
        value: "ТРСТЕНИК_МИЈАЈЛОВАЦ_71170_741787",
    },
    {
        label: "ТРСТЕНИК, МИЛУТОВАЦ",
        value: "ТРСТЕНИК_МИЛУТОВАЦ_71170_741795",
    },
    {
        label: "ТРСТЕНИК, ОКРУГЛИЦА",
        value: "ТРСТЕНИК_ОКРУГЛИЦА_71170_741817",
    },
    {
        label: "ТРСТЕНИК, ОМАШНИЦА",
        value: "ТРСТЕНИК_ОМАШНИЦА_71170_741825",
    },
    {
        label: "ТРСТЕНИК, ОСАОНИЦА",
        value: "ТРСТЕНИК_ОСАОНИЦА_71170_741833",
    },
    {
        label: "ТРСТЕНИК, ОЏАЦИ",
        value: "ТРСТЕНИК_ОЏАЦИ_71170_741841",
    },
    {
        label: "ТРСТЕНИК, ПАЈСАК",
        value: "ТРСТЕНИК_ПАЈСАК_71170_741850",
    },
    {
        label: "ТРСТЕНИК, ПЛАНИНИЦА",
        value: "ТРСТЕНИК_ПЛАНИНИЦА_71170_741868",
    },
    {
        label: "ТРСТЕНИК, ПОЉНА",
        value: "ТРСТЕНИК_ПОЉНА_71170_741876",
    },
    {
        label: "ТРСТЕНИК, ПОПИНА",
        value: "ТРСТЕНИК_ПОПИНА_71170_741884",
    },
    {
        label: "ТРСТЕНИК, ПОЧЕКОВИНА",
        value: "ТРСТЕНИК_ПОЧЕКОВИНА_71170_741892",
    },
    {
        label: "ТРСТЕНИК, ПРЊАВОР",
        value: "ТРСТЕНИК_ПРЊАВОР_71170_741906",
    },
    {
        label: "ТРСТЕНИК, РАЈИНАЦ",
        value: "ТРСТЕНИК_РАЈИНАЦ_71170_741914",
    },
    {
        label: "ТРСТЕНИК, РИЂЕВШТИЦА",
        value: "ТРСТЕНИК_РИЂЕВШТИЦА_71170_741922",
    },
    {
        label: "ТРСТЕНИК, РИЉАЦ",
        value: "ТРСТЕНИК_РИЉАЦ_71170_741949",
    },
    {
        label: "ТРСТЕНИК, РУЈИШНИК",
        value: "ТРСТЕНИК_РУЈИШНИК_71170_741973",
    },
    {
        label: "ТРСТЕНИК, СЕЛИШТЕ",
        value: "ТРСТЕНИК_СЕЛИШТЕ_71170_741981",
    },
    {
        label: "ТРСТЕНИК, СТАРИ ТРСТЕНИК",
        value: "ТРСТЕНИК_СТАРИ ТРСТЕНИК_71170_742007",
    },
    {
        label: "ТРСТЕНИК, СТОПАЊА",
        value: "ТРСТЕНИК_СТОПАЊА_71170_742015",
    },
    {
        label: "ТРСТЕНИК, СТРАГАРИ",
        value: "ТРСТЕНИК_СТРАГАРИ_71170_742023",
    },
    {
        label: "ТРСТЕНИК, СТУБЛИЦА",
        value: "ТРСТЕНИК_СТУБЛИЦА_71170_742031",
    },
    {
        label: "ТРСТЕНИК, ТОБОЛАЦ",
        value: "ТРСТЕНИК_ТОБОЛАЦ_71170_742040",
    },
    {
        label: "ТРСТЕНИК, ТРСТЕНИК",
        value: "ТРСТЕНИК_ТРСТЕНИК_71170_742058",
    },
    {
        label: "ТРСТЕНИК, УГЉАРЕВО",
        value: "ТРСТЕНИК_УГЉАРЕВО_71170_742066",
    },
    {
        label: "ТРСТЕНИК, ЧАИРИ",
        value: "ТРСТЕНИК_ЧАИРИ_71170_742074",
    },
    {
        label: "ТУТИН, АРАПОВИЋЕ",
        value: "ТУТИН_АРАПОВИЋЕ_71188_742104",
    },
    {
        label: "ТУТИН, БАЋИЦА",
        value: "ТУТИН_БАЋИЦА_71188_742112",
    },
    {
        label: "ТУТИН, БОРОШТИЦА",
        value: "ТУТИН_БОРОШТИЦА_71188_742139",
    },
    {
        label: "ТУТИН, БРАЋАК",
        value: "ТУТИН_БРАЋАК_71188_742147",
    },
    {
        label: "ТУТИН, БУЈКОВИЋЕ",
        value: "ТУТИН_БУЈКОВИЋЕ_71188_742155",
    },
    {
        label: "ТУТИН, ВЕЉЕ ПОЉЕ",
        value: "ТУТИН_ВЕЉЕ ПОЉЕ_71188_742163",
    },
    {
        label: "ТУТИН, ВЕСЕНИЋЕ",
        value: "ТУТИН_ВЕСЕНИЋЕ_71188_742171",
    },
    {
        label: "ТУТИН, ГЛОГОВИК",
        value: "ТУТИН_ГЛОГОВИК_71188_742180",
    },
    {
        label: "ТУТИН, ГЛУХАВИЦА",
        value: "ТУТИН_ГЛУХАВИЦА_71188_742198",
    },
    {
        label: "ТУТИН, ГНИЛА",
        value: "ТУТИН_ГНИЛА_71188_742201",
    },
    {
        label: "ТУТИН, ГОДОВО",
        value: "ТУТИН_ГОДОВО_71188_742210",
    },
    {
        label: "ТУТИН, ДЕВРЕЧ",
        value: "ТУТИН_ДЕВРЕЧ_71188_742228",
    },
    {
        label: "ТУТИН, ДЕЛИМЕЂЕ",
        value: "ТУТИН_ДЕЛИМЕЂЕ_71188_742236",
    },
    {
        label: "ТУТИН, ДЕТАНЕ",
        value: "ТУТИН_ДЕТАНЕ_71188_742244",
    },
    {
        label: "ТУТИН, ДОБРИ ДУБ",
        value: "ТУТИН_ДОБРИ ДУБ_71188_742252",
    },
    {
        label: "ТУТИН, ДОБРИЊЕ",
        value: "ТУТИН_ДОБРИЊЕ_71188_742279",
    },
    {
        label: "ТУТИН, ДОЛОВО",
        value: "ТУТИН_ДОЛОВО_71188_742287",
    },
    {
        label: "ТУТИН, ДРАГА",
        value: "ТУТИН_ДРАГА_71188_742295",
    },
    {
        label: "ТУТИН, ДУБОВО",
        value: "ТУТИН_ДУБОВО_71188_742309",
    },
    {
        label: "ТУТИН, ДУЛЕБЕ",
        value: "ТУТИН_ДУЛЕБЕ_71188_742317",
    },
    {
        label: "ТУТИН, ЂЕРЕКАРЕ",
        value: "ТУТИН_ЂЕРЕКАРЕ_71188_742325",
    },
    {
        label: "ТУТИН, ЖИРЧЕ",
        value: "ТУТИН_ЖИРЧЕ_71188_742333",
    },
    {
        label: "ТУТИН, ЖУПА",
        value: "ТУТИН_ЖУПА_71188_742341",
    },
    {
        label: "ТУТИН, ЗАПАДНИ МОЈСТИР",
        value: "ТУТИН_ЗАПАДНИ МОЈСТИР_71188_742350",
    },
    {
        label: "ТУТИН, ИСТОЧНИ МОЈСТИР",
        value: "ТУТИН_ИСТОЧНИ МОЈСТИР_71188_742368",
    },
    {
        label: "ТУТИН, ЈЕЗГРОВИЋЕ",
        value: "ТУТИН_ЈЕЗГРОВИЋЕ_71188_742376",
    },
    {
        label: "ТУТИН, ЈЕЛИЋЕ",
        value: "ТУТИН_ЈЕЛИЋЕ_71188_742384",
    },
    {
        label: "ТУТИН, ЈУЖНИ КОЧАРНИК",
        value: "ТУТИН_ЈУЖНИ КОЧАРНИК_71188_742392",
    },
    {
        label: "ТУТИН, КОВАЧИ",
        value: "ТУТИН_КОВАЧИ_71188_742406",
    },
    {
        label: "ТУТИН, КОНИЧЕ",
        value: "ТУТИН_КОНИЧЕ_71188_742414",
    },
    {
        label: "ТУТИН, ЛЕСКОВА",
        value: "ТУТИН_ЛЕСКОВА_71188_742422",
    },
    {
        label: "ТУТИН, ЛИПИЦА",
        value: "ТУТИН_ЛИПИЦА_71188_742449",
    },
    {
        label: "ТУТИН, ЛУКАВИЦА",
        value: "ТУТИН_ЛУКАВИЦА_71188_742457",
    },
    {
        label: "ТУТИН, МЕЛАЈЕ",
        value: "ТУТИН_МЕЛАЈЕ_71188_742465",
    },
    {
        label: "ТУТИН, МИТРОВА",
        value: "ТУТИН_МИТРОВА_71188_742473",
    },
    {
        label: "ТУТИН, МОРАНИ",
        value: "ТУТИН_МОРАНИ_71188_742481",
    },
    {
        label: "ТУТИН, НАБОЈЕ",
        value: "ТУТИН_НАБОЈЕ_71188_742490",
    },
    {
        label: "ТУТИН, НАМГА",
        value: "ТУТИН_НАМГА_71188_742503",
    },
    {
        label: "ТУТИН, ОРЉЕ",
        value: "ТУТИН_ОРЉЕ_71188_742511",
    },
    {
        label: "ТУТИН, ОСТРОВИЦА",
        value: "ТУТИН_ОСТРОВИЦА_71188_742520",
    },
    {
        label: "ТУТИН, ПАЉЕВО",
        value: "ТУТИН_ПАЉЕВО_71188_742538",
    },
    {
        label: "ТУТИН, ПОКРВЕНИК",
        value: "ТУТИН_ПОКРВЕНИК_71188_742546",
    },
    {
        label: "ТУТИН, ПОПЕ",
        value: "ТУТИН_ПОПЕ_71188_742554",
    },
    {
        label: "ТУТИН, ПРУЖАЊ",
        value: "ТУТИН_ПРУЖАЊ_71188_742562",
    },
    {
        label: "ТУТИН, РАДУХОВЦЕ",
        value: "ТУТИН_РАДУХОВЦЕ_71188_742589",
    },
    {
        label: "ТУТИН, РАДУША",
        value: "ТУТИН_РАДУША_71188_742597",
    },
    {
        label: "ТУТИН, РЕЖЕВИЋЕ",
        value: "ТУТИН_РЕЖЕВИЋЕ_71188_742619",
    },
    {
        label: "ТУТИН, РИБАРИЋЕ",
        value: "ТУТИН_РИБАРИЋЕ_71188_742627",
    },
    {
        label: "ТУТИН, РУДНИЦА",
        value: "ТУТИН_РУДНИЦА_71188_742635",
    },
    {
        label: "ТУТИН, САШ",
        value: "ТУТИН_САШ_71188_742643",
    },
    {
        label: "ТУТИН, СТАРЧЕВИЋЕ",
        value: "ТУТИН_СТАРЧЕВИЋЕ_71188_742651",
    },
    {
        label: "ТУТИН, СУВИ ДО",
        value: "ТУТИН_СУВИ ДО_71188_742660",
    },
    {
        label: "ТУТИН, ТУТИН",
        value: "ТУТИН_ТУТИН_71188_742678",
    },
    {
        label: "ТУТИН, ЦРНИШ",
        value: "ТУТИН_ЦРНИШ_71188_742686",
    },
    {
        label: "ТУТИН, ЧАРОВИНА",
        value: "ТУТИН_ЧАРОВИНА_71188_742694",
    },
    {
        label: "ТУТИН, ЧУКОТЕ",
        value: "ТУТИН_ЧУКОТЕ_71188_742708",
    },
    {
        label: "ТУТИН, ШАРОЊЕ",
        value: "ТУТИН_ШАРОЊЕ_71188_742716",
    },
    {
        label: "ТУТИН, ШПИЉАНИ",
        value: "ТУТИН_ШПИЉАНИ_71188_742724",
    },
    {
        label: "ЋИЋЕВАЦ, БРАЉИНА",
        value: "ЋИЋЕВАЦ_БРАЉИНА_71196_720542",
    },
    {
        label: "ЋИЋЕВАЦ, ГРАД СТАЛАЋ",
        value: "ЋИЋЕВАЦ_ГРАД СТАЛАЋ_71196_720739",
    },
    {
        label: "ЋИЋЕВАЦ, ЛУЧИНА",
        value: "ЋИЋЕВАЦ_ЛУЧИНА_71196_736155",
    },
    {
        label: "ЋИЋЕВАЦ, МОЈСИЊЕ",
        value: "ЋИЋЕВАЦ_МОЈСИЊЕ_71196_721174",
    },
    {
        label: "ЋИЋЕВАЦ, МРЗЕНИЦА",
        value: "ЋИЋЕВАЦ_МРЗЕНИЦА_71196_721182",
    },
    {
        label: "ЋИЋЕВАЦ, ПЛОЧНИК",
        value: "ЋИЋЕВАЦ_ПЛОЧНИК_71196_736210",
    },
    {
        label: "ЋИЋЕВАЦ, ПОЈАТЕ",
        value: "ЋИЋЕВАЦ_ПОЈАТЕ_71196_736236",
    },
    {
        label: "ЋИЋЕВАЦ, РАДОШЕВАЦ",
        value: "ЋИЋЕВАЦ_РАДОШЕВАЦ_71196_736287",
    },
    {
        label: "ЋИЋЕВАЦ, СТАЛАЋ",
        value: "ЋИЋЕВАЦ_СТАЛАЋ_71196_736333",
    },
    {
        label: "ЋИЋЕВАЦ, ТРУБАРЕВО",
        value: "ЋИЋЕВАЦ_ТРУБАРЕВО_71196_721468",
    },
    {
        label: "ЋИЋЕВАЦ, ЋИЋЕВАЦ",
        value: "ЋИЋЕВАЦ_ЋИЋЕВАЦ_71196_736341",
    },
    {
        label: "ЋИЋЕВАЦ, ЋИЋЕВАЦ -ГРАД",
        value: "ЋИЋЕВАЦ_ЋИЋЕВАЦ -ГРАД_71196_736350",
    },
    {
        label: "ЋУПРИЈА, БАТИНАЦ",
        value: "ЋУПРИЈА_БАТИНАЦ_71200_732257",
    },
    {
        label: "ЋУПРИЈА, БИГРЕНИЦА",
        value: "ЋУПРИЈА_БИГРЕНИЦА_71200_732265",
    },
    {
        label: "ЋУПРИЈА, ВИРИНЕ",
        value: "ЋУПРИЈА_ВИРИНЕ_71200_713376",
    },
    {
        label: "ЋУПРИЈА, ВЛАШКА",
        value: "ЋУПРИЈА_ВЛАШКА_71200_732303",
    },
    {
        label: "ЋУПРИЈА, ДВОРИЦА",
        value: "ЋУПРИЈА_ДВОРИЦА_71200_738042",
    },
    {
        label: "ЋУПРИЈА, ИВАНКОВАЦ",
        value: "ЋУПРИЈА_ИВАНКОВАЦ_71200_732435",
    },
    {
        label: "ЋУПРИЈА, ИСАКОВО",
        value: "ЋУПРИЈА_ИСАКОВО_71200_713457",
    },
    {
        label: "ЋУПРИЈА, ЈОВАЦ",
        value: "ЋУПРИЈА_ЈОВАЦ_71200_738140",
    },
    {
        label: "ЋУПРИЈА, КОВАНИЦА",
        value: "ЋУПРИЈА_КОВАНИЦА_71200_713481",
    },
    {
        label: "ЋУПРИЈА, КРУШАР",
        value: "ЋУПРИЈА_КРУШАР_71200_732478",
    },
    {
        label: "ЋУПРИЈА, МИЈАТОВАЦ",
        value: "ЋУПРИЈА_МИЈАТОВАЦ_71200_738328",
    },
    {
        label: "ЋУПРИЈА, ОСТРИКОВАЦ",
        value: "ЋУПРИЈА_ОСТРИКОВАЦ_71200_738352",
    },
    {
        label: "ЋУПРИЈА, ПАЉАНЕ",
        value: "ЋУПРИЈА_ПАЉАНЕ_71200_732516",
    },
    {
        label: "ЋУПРИЈА, СЕЊЕ",
        value: "ЋУПРИЈА_СЕЊЕ_71200_732583",
    },
    {
        label: "ЋУПРИЈА, СУПСКА",
        value: "ЋУПРИЈА_СУПСКА_71200_732630",
    },
    {
        label: "ЋУПРИЈА, ЋУПРИЈА (ВАН ГРАД)",
        value: "ЋУПРИЈА_ЋУПРИЈА (ВАН ГРАД)_71200_732656",
    },
    {
        label: "ЋУПРИЈА, ЋУПРИЈА (ГРАД)",
        value: "ЋУПРИЈА_ЋУПРИЈА (ГРАД)_71200_732664",
    },
    {
        label: "УБ, БАЊАНИ",
        value: "УБ_БАЊАНИ_71218_742759",
    },
    {
        label: "УБ, БРГУЛЕ",
        value: "УБ_БРГУЛЕ_71218_742775",
    },
    {
        label: "УБ, БРЕЗОВИЦА",
        value: "УБ_БРЕЗОВИЦА_71218_742783",
    },
    {
        label: "УБ, ВРЕЛО",
        value: "УБ_ВРЕЛО_71218_742791",
    },
    {
        label: "УБ, ВРХОВИНЕ",
        value: "УБ_ВРХОВИНЕ_71218_742805",
    },
    {
        label: "УБ, ВУКОНА",
        value: "УБ_ВУКОНА_71218_742813",
    },
    {
        label: "УБ, ГВОЗДЕНОВИЋ",
        value: "УБ_ГВОЗДЕНОВИЋ_71218_742821",
    },
    {
        label: "УБ, ГУЊЕВАЦ",
        value: "УБ_ГУЊЕВАЦ_71218_742830",
    },
    {
        label: "УБ, ДОКМИР",
        value: "УБ_ДОКМИР_71218_742848",
    },
    {
        label: "УБ, ЗВИЗДАР",
        value: "УБ_ЗВИЗДАР_71218_742856",
    },
    {
        label: "УБ, ЈОШЕВА",
        value: "УБ_ЈОШЕВА_71218_731676",
    },
    {
        label: "УБ, КАЛЕНИЋ",
        value: "УБ_КАЛЕНИЋ_71218_742899",
    },
    {
        label: "УБ, КАЛИНОВАЦ",
        value: "УБ_КАЛИНОВАЦ_71218_742902",
    },
    {
        label: "УБ, КОЖУАР",
        value: "УБ_КОЖУАР_71218_742929",
    },
    {
        label: "УБ, КРШНА ГЛАВА",
        value: "УБ_КРШНА ГЛАВА_71218_742937",
    },
    {
        label: "УБ, ЛИСО ПОЉЕ",
        value: "УБ_ЛИСО ПОЉЕ_71218_742953",
    },
    {
        label: "УБ, ЛОНЧАНИК",
        value: "УБ_ЛОНЧАНИК_71218_731706",
    },
    {
        label: "УБ, МИЛОРЦИ",
        value: "УБ_МИЛОРЦИ_71218_731722",
    },
    {
        label: "УБ, МУРГАШ",
        value: "УБ_МУРГАШ_71218_742970",
    },
    {
        label: "УБ, НОВАЦИ",
        value: "УБ_НОВАЦИ_71218_742996",
    },
    {
        label: "УБ, ПАЉУВИ",
        value: "УБ_ПАЉУВИ_71218_743003",
    },
    {
        label: "УБ, ПАМБУКОВИЦА",
        value: "УБ_ПАМБУКОВИЦА_71218_743011",
    },
    {
        label: "УБ, РАДЉЕВО",
        value: "УБ_РАДЉЕВО_71218_743020",
    },
    {
        label: "УБ, РАДУША",
        value: "УБ_РАДУША_71218_743038",
    },
    {
        label: "УБ, РУКЛАДА",
        value: "УБ_РУКЛАДА_71218_743054",
    },
    {
        label: "УБ, СЛАТИНА",
        value: "УБ_СЛАТИНА_71218_743089",
    },
    {
        label: "УБ, СОВЉАК",
        value: "УБ_СОВЉАК_71218_743097",
    },
    {
        label: "УБ, СТУБЛЕНИЦА",
        value: "УБ_СТУБЛЕНИЦА_71218_743127",
    },
    {
        label: "УБ, ТАКОВО",
        value: "УБ_ТАКОВО_71218_743135",
    },
    {
        label: "УБ, ТВРДОЈЕВАЦ",
        value: "УБ_ТВРДОЈЕВАЦ_71218_743143",
    },
    {
        label: "УБ, ТРЛИЋ",
        value: "УБ_ТРЛИЋ_71218_743151",
    },
    {
        label: "УБ, ТРЊАЦИ",
        value: "УБ_ТРЊАЦИ_71218_743160",
    },
    {
        label: "УБ, ТУЛАРИ",
        value: "УБ_ТУЛАРИ_71218_743178",
    },
    {
        label: "УБ, УБ",
        value: "УБ_УБ_71218_743186",
    },
    {
        label: "УБ, ЦРВЕНА ЈАБУКА",
        value: "УБ_ЦРВЕНА ЈАБУКА_71218_743194",
    },
    {
        label: "УБ, ЧУЧУГЕ",
        value: "УБ_ЧУЧУГЕ_71218_743208",
    },
    {
        label: "УБ, ШАРБАНЕ",
        value: "УБ_ШАРБАНЕ_71218_743216",
    },
    {
        label: "УЖИЦЕ, БИОСКА",
        value: "УЖИЦЕ_БИОСКА_71145_741124",
    },
    {
        label: "УЖИЦЕ, БУАР",
        value: "УЖИЦЕ_БУАР_71145_741132",
    },
    {
        label: "УЖИЦЕ, ВРУТЦИ",
        value: "УЖИЦЕ_ВРУТЦИ_71145_741159",
    },
    {
        label: "УЖИЦЕ, ГОРЈАНИ",
        value: "УЖИЦЕ_ГОРЈАНИ_71145_741167",
    },
    {
        label: "УЖИЦЕ, ГОСТИНИЦА",
        value: "УЖИЦЕ_ГОСТИНИЦА_71145_741175",
    },
    {
        label: "УЖИЦЕ, ГУБИН ДО",
        value: "УЖИЦЕ_ГУБИН ДО_71145_741183",
    },
    {
        label: "УЖИЦЕ, ДОБРОДО",
        value: "УЖИЦЕ_ДОБРОДО_71145_741191",
    },
    {
        label: "УЖИЦЕ, ДРЕЖНИК",
        value: "УЖИЦЕ_ДРЕЖНИК_71145_741205",
    },
    {
        label: "УЖИЦЕ, ДРИЈЕТАЊ",
        value: "УЖИЦЕ_ДРИЈЕТАЊ_71145_741213",
    },
    {
        label: "УЖИЦЕ, ДУБОКО",
        value: "УЖИЦЕ_ДУБОКО_71145_741221",
    },
    {
        label: "УЖИЦЕ, ЗБОЈШТИЦА",
        value: "УЖИЦЕ_ЗБОЈШТИЦА_71145_741230",
    },
    {
        label: "УЖИЦЕ, ЗЛАКУСА",
        value: "УЖИЦЕ_ЗЛАКУСА_71145_741248",
    },
    {
        label: "УЖИЦЕ, КАМЕНИЦА",
        value: "УЖИЦЕ_КАМЕНИЦА_71145_741256",
    },
    {
        label: "УЖИЦЕ, КАРАН",
        value: "УЖИЦЕ_КАРАН_71145_741264",
    },
    {
        label: "УЖИЦЕ, КАЧЕР",
        value: "УЖИЦЕ_КАЧЕР_71145_741272",
    },
    {
        label: "УЖИЦЕ, КРВАВЦИ",
        value: "УЖИЦЕ_КРВАВЦИ_71145_741299",
    },
    {
        label: "УЖИЦЕ, КРЕМНА",
        value: "УЖИЦЕ_КРЕМНА_71145_743810",
    },
    {
        label: "УЖИЦЕ, ЛЕЛИЋИ",
        value: "УЖИЦЕ_ЛЕЛИЋИ_71145_741329",
    },
    {
        label: "УЖИЦЕ, ЉУБАЊЕ",
        value: "УЖИЦЕ_ЉУБАЊЕ_71145_741337",
    },
    {
        label: "УЖИЦЕ, МОКРА ГОРА",
        value: "УЖИЦЕ_МОКРА ГОРА_71145_743879",
    },
    {
        label: "УЖИЦЕ, НИКОЈЕВИЋИ",
        value: "УЖИЦЕ_НИКОЈЕВИЋИ_71145_741353",
    },
    {
        label: "УЖИЦЕ, ПОНИКОВИЦА",
        value: "УЖИЦЕ_ПОНИКОВИЦА_71145_741361",
    },
    {
        label: "УЖИЦЕ, ПОТОЧАЊЕ",
        value: "УЖИЦЕ_ПОТОЧАЊЕ_71145_741370",
    },
    {
        label: "УЖИЦЕ, ПОТПЕЋЕ",
        value: "УЖИЦЕ_ПОТПЕЋЕ_71145_741388",
    },
    {
        label: "УЖИЦЕ, РАВНИ",
        value: "УЖИЦЕ_РАВНИ_71145_741396",
    },
    {
        label: "УЖИЦЕ, РИБАШЕВИНА",
        value: "УЖИЦЕ_РИБАШЕВИНА_71145_741400",
    },
    {
        label: "УЖИЦЕ, СКРЖУТИ",
        value: "УЖИЦЕ_СКРЖУТИ_71145_741426",
    },
    {
        label: "УЖИЦЕ, СТАПАРИ",
        value: "УЖИЦЕ_СТАПАРИ_71145_741434",
    },
    {
        label: "УЖИЦЕ, ТРНАВА",
        value: "УЖИЦЕ_ТРНАВА_71145_741477",
    },
    {
        label: "УЖИЦЕ, УЖИЦЕ",
        value: "УЖИЦЕ_УЖИЦЕ_71145_741442",
    },
    {
        label: "ЦРВЕНИ КРСТ, БЕРЧИНАЦ",
        value: "ЦРВЕНИ КРСТ_БЕРЧИНАЦ_71315_729078",
    },
    {
        label: "ЦРВЕНИ КРСТ, ВЕЛЕ ПОЉЕ",
        value: "ЦРВЕНИ КРСТ_ВЕЛЕ ПОЉЕ_71315_700614",
    },
    {
        label: "ЦРВЕНИ КРСТ, ВРТИШТЕ",
        value: "ЦРВЕНИ КРСТ_ВРТИШТЕ_71315_729167",
    },
    {
        label: "ЦРВЕНИ КРСТ, ГОРЊА ТОПОНИЦА",
        value: "ЦРВЕНИ КРСТ_ГОРЊА ТОПОНИЦА_71315_729230",
    },
    {
        label: "ЦРВЕНИ КРСТ, ГОРЊА ТРНАВА",
        value: "ЦРВЕНИ КРСТ_ГОРЊА ТРНАВА_71315_729248",
    },
    {
        label: "ЦРВЕНИ КРСТ, ГОРЊИ КОМРЕН",
        value: "ЦРВЕНИ КРСТ_ГОРЊИ КОМРЕН_71315_729329",
    },
    {
        label: "ЦРВЕНИ КРСТ, ДОЊА ТОПОНИЦА",
        value: "ЦРВЕНИ КРСТ_ДОЊА ТОПОНИЦА_71315_729370",
    },
    {
        label: "ЦРВЕНИ КРСТ, ДОЊА ТРНАВА",
        value: "ЦРВЕНИ КРСТ_ДОЊА ТРНАВА_71315_729388",
    },
    {
        label: "ЦРВЕНИ КРСТ, ДОЊИ КОМРЕН",
        value: "ЦРВЕНИ КРСТ_ДОЊИ КОМРЕН_71315_729442",
    },
    {
        label: "ЦРВЕНИ КРСТ, КРАВЉЕ",
        value: "ЦРВЕНИ КРСТ_КРАВЉЕ_71315_729604",
    },
    {
        label: "ЦРВЕНИ КРСТ, ЛЕСКОВИК",
        value: "ЦРВЕНИ КРСТ_ЛЕСКОВИК_71315_729663",
    },
    {
        label: "ЦРВЕНИ КРСТ, МЕДОШЕВАЦ",
        value: "ЦРВЕНИ КРСТ_МЕДОШЕВАЦ_71315_729736",
    },
    {
        label: "ЦРВЕНИ КРСТ, МЕЗГРАЈА",
        value: "ЦРВЕНИ КРСТ_МЕЗГРАЈА_71315_729744",
    },
    {
        label: "ЦРВЕНИ КРСТ, МИЉКОВАЦ",
        value: "ЦРВЕНИ КРСТ_МИЉКОВАЦ_71315_729779",
    },
    {
        label: 'ЦРВЕНИ КРСТ, НИШ "ЦРВЕНИ КРСТ"',
        value: 'ЦРВЕНИ КРСТ_НИШ "ЦРВЕНИ КРСТ"_71315_729817',
    },
    {
        label: "ЦРВЕНИ КРСТ, ПАЛИГРАЦЕ",
        value: "ЦРВЕНИ КРСТ_ПАЛИГРАЦЕ_71315_700762",
    },
    {
        label: "ЦРВЕНИ КРСТ, ПАЉИНА",
        value: "ЦРВЕНИ КРСТ_ПАЉИНА_71315_729884",
    },
    {
        label: "ЦРВЕНИ КРСТ, ПОПОВАЦ",
        value: "ЦРВЕНИ КРСТ_ПОПОВАЦ_71315_729922",
    },
    {
        label: "ЦРВЕНИ КРСТ, РУЈНИК",
        value: "ЦРВЕНИ КРСТ_РУЈНИК_71315_729990",
    },
    {
        label: "ЦРВЕНИ КРСТ, СЕЧАНИЦА",
        value: "ЦРВЕНИ КРСТ_СЕЧАНИЦА_71315_701246",
    },
    {
        label: "ЦРВЕНИ КРСТ, СУПОВАЦ",
        value: "ЦРВЕНИ КРСТ_СУПОВАЦ_71315_701289",
    },
    {
        label: "ЦРВЕНИ КРСТ, ТРУПАЛЕ",
        value: "ЦРВЕНИ КРСТ_ТРУПАЛЕ_71315_730068",
    },
    {
        label: "ЦРВЕНИ КРСТ, ХУМ",
        value: "ЦРВЕНИ КРСТ_ХУМ_71315_730092",
    },
    {
        label: "ЦРВЕНИ КРСТ, ЧАМУРЛИЈА",
        value: "ЦРВЕНИ КРСТ_ЧАМУРЛИЈА_71315_730122",
    },
    {
        label: "ЦРНА ТРАВА, БАЈИНЦИ",
        value: "ЦРНА ТРАВА_БАЈИНЦИ_71226_710202",
    },
    {
        label: "ЦРНА ТРАВА, БАНКОВЦИ",
        value: "ЦРНА ТРАВА_БАНКОВЦИ_71226_710229",
    },
    {
        label: "ЦРНА ТРАВА, БРОД",
        value: "ЦРНА ТРАВА_БРОД_71226_710296",
    },
    {
        label: "ЦРНА ТРАВА, ГОРЊЕ ГАРЕ",
        value: "ЦРНА ТРАВА_ГОРЊЕ ГАРЕ_71226_710377",
    },
    {
        label: "ЦРНА ТРАВА, ГРАДСКА",
        value: "ЦРНА ТРАВА_ГРАДСКА_71226_710415",
    },
    {
        label: "ЦРНА ТРАВА, ДАРКОВЦЕ",
        value: "ЦРНА ТРАВА_ДАРКОВЦЕ_71226_710466",
    },
    {
        label: "ЦРНА ТРАВА, ДОБРО ПОЉЕ",
        value: "ЦРНА ТРАВА_ДОБРО ПОЉЕ_71226_710512",
    },
    {
        label: "ЦРНА ТРАВА, ЈАБУКОВИК",
        value: "ЦРНА ТРАВА_ЈАБУКОВИК_71226_710580",
    },
    {
        label: "ЦРНА ТРАВА, КАЛНА",
        value: "ЦРНА ТРАВА_КАЛНА_71226_710628",
    },
    {
        label: "ЦРНА ТРАВА, КРИВИ ДЕЛ",
        value: "ЦРНА ТРАВА_КРИВИ ДЕЛ_71226_710695",
    },
    {
        label: "ЦРНА ТРАВА, МЛАЧИШТЕ",
        value: "ЦРНА ТРАВА_МЛАЧИШТЕ_71226_710750",
    },
    {
        label: "ЦРНА ТРАВА, ОСТРОЗУБ",
        value: "ЦРНА ТРАВА_ОСТРОЗУБ_71226_710784",
    },
    {
        label: "ЦРНА ТРАВА, ПАВЛИЧИНА",
        value: "ЦРНА ТРАВА_ПАВЛИЧИНА_71226_710806",
    },
    {
        label: "ЦРНА ТРАВА, ПРЕСЛАП",
        value: "ЦРНА ТРАВА_ПРЕСЛАП_71226_710822",
    },
    {
        label: "ЦРНА ТРАВА, РАЈЧЕТИНЕ",
        value: "ЦРНА ТРАВА_РАЈЧЕТИНЕ_71226_710903",
    },
    {
        label: "ЦРНА ТРАВА, РУПЉЕ",
        value: "ЦРНА ТРАВА_РУПЉЕ_71226_710911",
    },
    {
        label: "ЦРНА ТРАВА, ЦРНА ТРАВА",
        value: "ЦРНА ТРАВА_ЦРНА ТРАВА_71226_711063",
    },
    {
        label: "ЧАЈЕТИНА, АЛИН ПОТОК",
        value: "ЧАЈЕТИНА_АЛИН ПОТОК_71234_743682",
    },
    {
        label: "ЧАЈЕТИНА, БРАНЕШЦИ",
        value: "ЧАЈЕТИНА_БРАНЕШЦИ_71234_743712",
    },
    {
        label: "ЧАЈЕТИНА, ГОСТИЉЕ",
        value: "ЧАЈЕТИНА_ГОСТИЉЕ_71234_743747",
    },
    {
        label: "ЧАЈЕТИНА, ДОБРОСЕЛИЦА",
        value: "ЧАЈЕТИНА_ДОБРОСЕЛИЦА_71234_743755",
    },
    {
        label: "ЧАЈЕТИНА, ДРЕНОВА",
        value: "ЧАЈЕТИНА_ДРЕНОВА_71234_743771",
    },
    {
        label: "ЧАЈЕТИНА, ЖЕЉИНЕ",
        value: "ЧАЈЕТИНА_ЖЕЉИНЕ_71234_743780",
    },
    {
        label: "ЧАЈЕТИНА, ЈАБЛАНИЦА",
        value: "ЧАЈЕТИНА_ЈАБЛАНИЦА_71234_743798",
    },
    {
        label: "ЧАЈЕТИНА, КРИВА РЕКА",
        value: "ЧАЈЕТИНА_КРИВА РЕКА_71234_743828",
    },
    {
        label: "ЧАЈЕТИНА, ЉУБИШ",
        value: "ЧАЈЕТИНА_ЉУБИШ_71234_743844",
    },
    {
        label: "ЧАЈЕТИНА, МАЧКАТ",
        value: "ЧАЈЕТИНА_МАЧКАТ_71234_741345",
    },
    {
        label: "ЧАЈЕТИНА, МЕШНИК",
        value: "ЧАЈЕТИНА_МЕШНИК_71234_743852",
    },
    {
        label: "ЧАЈЕТИНА, РОЖАНСТВО",
        value: "ЧАЈЕТИНА_РОЖАНСТВО_71234_743909",
    },
    {
        label: "ЧАЈЕТИНА, СЕМЕГЊЕВО",
        value: "ЧАЈЕТИНА_СЕМЕГЊЕВО_71234_743917",
    },
    {
        label: "ЧАЈЕТИНА, СИРОГОЈНО",
        value: "ЧАЈЕТИНА_СИРОГОЈНО_71234_743933",
    },
    {
        label: "ЧАЈЕТИНА, СТУБЛО",
        value: "ЧАЈЕТИНА_СТУБЛО_71234_743941",
    },
    {
        label: "ЧАЈЕТИНА, ТРИПКОВА",
        value: "ЧАЈЕТИНА_ТРИПКОВА_71234_741469",
    },
    {
        label: "ЧАЈЕТИНА, ТРНАВА",
        value: "ЧАЈЕТИНА_ТРНАВА_71234_743950",
    },
    {
        label: "ЧАЈЕТИНА, ЧАЈЕТИНА",
        value: "ЧАЈЕТИНА_ЧАЈЕТИНА_71234_743968",
    },
    {
        label: "ЧАЈЕТИНА, ЧИЧКОВА",
        value: "ЧАЈЕТИНА_ЧИЧКОВА_71234_701963",
    },
    {
        label: "ЧАЈЕТИНА, ШЉИВОВИЦА",
        value: "ЧАЈЕТИНА_ШЉИВОВИЦА_71234_743976",
    },
    {
        label: "ЧАЧАК, АТЕНИЦА",
        value: "ЧАЧАК_АТЕНИЦА_71242_743984",
    },
    {
        label: "ЧАЧАК, БАЛУГА (ЉУБИЋСКА)",
        value: "ЧАЧАК_БАЛУГА (ЉУБИЋСКА)_71242_744301",
    },
    {
        label: "ЧАЧАК, БАЛУГА (ТРНАВСКА)",
        value: "ЧАЧАК_БАЛУГА (ТРНАВСКА)_71242_743992",
    },
    {
        label: "ЧАЧАК, БАЊИЦА",
        value: "ЧАЧАК_БАЊИЦА_71242_744000",
    },
    {
        label: "ЧАЧАК, БЕЉИНА",
        value: "ЧАЧАК_БЕЉИНА_71242_745162",
    },
    {
        label: "ЧАЧАК, БЕЧАЊ",
        value: "ЧАЧАК_БЕЧАЊ_71242_744310",
    },
    {
        label: "ЧАЧАК, БРЕЗОВИЦА",
        value: "ЧАЧАК_БРЕЗОВИЦА_71242_726451",
    },
    {
        label: "ЧАЧАК, БРЕСНИЦА",
        value: "ЧАЧАК_БРЕСНИЦА_71242_744336",
    },
    {
        label: "ЧАЧАК, ВАПА",
        value: "ЧАЧАК_ВАПА_71242_744026",
    },
    {
        label: "ЧАЧАК, ВИДОВА",
        value: "ЧАЧАК_ВИДОВА_71242_744344",
    },
    {
        label: "ЧАЧАК, ВИЉУША",
        value: "ЧАЧАК_ВИЉУША_71242_744034",
    },
    {
        label: "ЧАЧАК, ВРАНИЋИ",
        value: "ЧАЧАК_ВРАНИЋИ_71242_744352",
    },
    {
        label: "ЧАЧАК, ВРНЧАНИ",
        value: "ЧАЧАК_ВРНЧАНИ_71242_744379",
    },
    {
        label: "ЧАЧАК, ВУЈЕТИНЦИ",
        value: "ЧАЧАК_ВУЈЕТИНЦИ_71242_744387",
    },
    {
        label: "ЧАЧАК, ГОРИЧАНИ",
        value: "ЧАЧАК_ГОРИЧАНИ_71242_744042",
    },
    {
        label: "ЧАЧАК, ГОРЊА ГОРЕВНИЦА",
        value: "ЧАЧАК_ГОРЊА ГОРЕВНИЦА_71242_744395",
    },
    {
        label: "ЧАЧАК, ГОРЊА ТРЕПЧА",
        value: "ЧАЧАК_ГОРЊА ТРЕПЧА_71242_744409",
    },
    {
        label: "ЧАЧАК, ДОЊА ГОРЕВНИЦА",
        value: "ЧАЧАК_ДОЊА ГОРЕВНИЦА_71242_744417",
    },
    {
        label: "ЧАЧАК, ДОЊА ТРЕПЧА",
        value: "ЧАЧАК_ДОЊА ТРЕПЧА_71242_744425",
    },
    {
        label: "ЧАЧАК, ЖАОЧАНИ",
        value: "ЧАЧАК_ЖАОЧАНИ_71242_744069",
    },
    {
        label: "ЧАЧАК, ЗАБЛАЋЕ",
        value: "ЧАЧАК_ЗАБЛАЋЕ_71242_744077",
    },
    {
        label: "ЧАЧАК, ЈАНЧИЋИ",
        value: "ЧАЧАК_ЈАНЧИЋИ_71242_744433",
    },
    {
        label: "ЧАЧАК, ЈЕЖЕВИЦА",
        value: "ЧАЧАК_ЈЕЖЕВИЦА_71242_744085",
    },
    {
        label: "ЧАЧАК, ЈЕЗДИНА",
        value: "ЧАЧАК_ЈЕЗДИНА_71242_744093",
    },
    {
        label: "ЧАЧАК, КАТРГА",
        value: "ЧАЧАК_КАТРГА_71242_744441",
    },
    {
        label: "ЧАЧАК, КАЧУЛИЦЕ",
        value: "ЧАЧАК_КАЧУЛИЦЕ_71242_744107",
    },
    {
        label: "ЧАЧАК, КОЊЕВИЋИ",
        value: "ЧАЧАК_КОЊЕВИЋИ_71242_744450",
    },
    {
        label: "ЧАЧАК, КУКИЋИ",
        value: "ЧАЧАК_КУКИЋИ_71242_744115",
    },
    {
        label: "ЧАЧАК, КУЛИНОВЦИ",
        value: "ЧАЧАК_КУЛИНОВЦИ_71242_744123",
    },
    {
        label: "ЧАЧАК, ЛИПНИЦА",
        value: "ЧАЧАК_ЛИПНИЦА_71242_744140",
    },
    {
        label: "ЧАЧАК, ЛОЗНИЦА",
        value: "ЧАЧАК_ЛОЗНИЦА_71242_744158",
    },
    {
        label: "ЧАЧАК, ЉУБИЋ",
        value: "ЧАЧАК_ЉУБИЋ_71242_744468",
    },
    {
        label: "ЧАЧАК, МЕЂУВРШЈЕ",
        value: "ЧАЧАК_МЕЂУВРШЈЕ_71242_744166",
    },
    {
        label: "ЧАЧАК, МИЛИЋЕВЦИ",
        value: "ЧАЧАК_МИЛИЋЕВЦИ_71242_744476",
    },
    {
        label: "ЧАЧАК, МИОКОВЦИ",
        value: "ЧАЧАК_МИОКОВЦИ_71242_744484",
    },
    {
        label: "ЧАЧАК, МОЈСИЊЕ",
        value: "ЧАЧАК_МОЈСИЊЕ_71242_744492",
    },
    {
        label: "ЧАЧАК, МРЧАЈЕВЦИ",
        value: "ЧАЧАК_МРЧАЈЕВЦИ_71242_744506",
    },
    {
        label: "ЧАЧАК, МРШИНЦИ",
        value: "ЧАЧАК_МРШИНЦИ_71242_744174",
    },
    {
        label: "ЧАЧАК, ОСТРА",
        value: "ЧАЧАК_ОСТРА_71242_744514",
    },
    {
        label: "ЧАЧАК, ПАКОВРАЋЕ",
        value: "ЧАЧАК_ПАКОВРАЋЕ_71242_744182",
    },
    {
        label: "ЧАЧАК, ПАРМЕНАЦ",
        value: "ЧАЧАК_ПАРМЕНАЦ_71242_744204",
    },
    {
        label: "ЧАЧАК, ПЕТНИЦА",
        value: "ЧАЧАК_ПЕТНИЦА_71242_744212",
    },
    {
        label: "ЧАЧАК, ПРЕЉИНА",
        value: "ЧАЧАК_ПРЕЉИНА_71242_744522",
    },
    {
        label: "ЧАЧАК, ПРЕМЕЋА",
        value: "ЧАЧАК_ПРЕМЕЋА_71242_744239",
    },
    {
        label: "ЧАЧАК, ПРИДВОРИЦА",
        value: "ЧАЧАК_ПРИДВОРИЦА_71242_744247",
    },
    {
        label: "ЧАЧАК, ПРИЈЕВОР",
        value: "ЧАЧАК_ПРИЈЕВОР_71242_744549",
    },
    {
        label: "ЧАЧАК, ПРИСЛОНИЦА",
        value: "ЧАЧАК_ПРИСЛОНИЦА_71242_744557",
    },
    {
        label: "ЧАЧАК, РАЈАЦ",
        value: "ЧАЧАК_РАЈАЦ_71242_744255",
    },
    {
        label: "ЧАЧАК, РАКОВА",
        value: "ЧАЧАК_РАКОВА_71242_744565",
    },
    {
        label: "ЧАЧАК, РИЂАГЕ",
        value: "ЧАЧАК_РИЂАГЕ_71242_744263",
    },
    {
        label: "ЧАЧАК, РОШЦИ",
        value: "ЧАЧАК_РОШЦИ_71242_744573",
    },
    {
        label: "ЧАЧАК, СЛАТИНА",
        value: "ЧАЧАК_СЛАТИНА_71242_744271",
    },
    {
        label: "ЧАЧАК, СОКОЛИЋИ",
        value: "ЧАЧАК_СОКОЛИЋИ_71242_744581",
    },
    {
        label: "ЧАЧАК, СТАНЧИЋИ",
        value: "ЧАЧАК_СТАНЧИЋИ_71242_744590",
    },
    {
        label: "ЧАЧАК, ТРБУШАНИ",
        value: "ЧАЧАК_ТРБУШАНИ_71242_744611",
    },
    {
        label: "ЧАЧАК, ТРНАВА",
        value: "ЧАЧАК_ТРНАВА_71242_744280",
    },
    {
        label: "ЧАЧАК, ЧАЧАК",
        value: "ЧАЧАК_ЧАЧАК_71242_744298",
    },
    {
        label: "ЧОКА, ВРБИЦА",
        value: "ЧОКА_ВРБИЦА_80489_802018",
    },
    {
        label: "ЧОКА, ЈАЗОВО",
        value: "ЧОКА_ЈАЗОВО_80489_801330",
    },
    {
        label: "ЧОКА, ОСТОЈИЋЕВО",
        value: "ЧОКА_ОСТОЈИЋЕВО_80489_801372",
    },
    {
        label: "ЧОКА, ПАДЕЈ",
        value: "ЧОКА_ПАДЕЈ_80489_801399",
    },
    {
        label: "ЧОКА, САНАД",
        value: "ЧОКА_САНАД_80489_801984",
    },
    {
        label: "ЧОКА, ЦРНА БАРА",
        value: "ЧОКА_ЦРНА БАРА_80489_801836",
    },
    {
        label: "ЧОКА, ЧОКА",
        value: "ЧОКА_ЧОКА_80489_801844",
    },
    {
        label: "ЧУКАРИЦА, ВЕЛИКА МОШТАНИЦА",
        value: "ЧУКАРИЦА_ВЕЛИКА МОШТАНИЦА_70254_703559",
    },
    {
        label: "ЧУКАРИЦА, ЖЕЛЕЗНИК",
        value: "ЧУКАРИЦА_ЖЕЛЕЗНИК_70254_703702",
    },
    {
        label: "ЧУКАРИЦА, ОСТРУЖНИЦА",
        value: "ЧУКАРИЦА_ОСТРУЖНИЦА_70254_703893",
    },
    {
        label: "ЧУКАРИЦА, РУШАЊ",
        value: "ЧУКАРИЦА_РУШАЊ_70254_703982",
    },
    {
        label: "ЧУКАРИЦА, СРЕМЧИЦА",
        value: "ЧУКАРИЦА_СРЕМЧИЦА_70254_704024",
    },
    {
        label: "ЧУКАРИЦА, УМКА",
        value: "ЧУКАРИЦА_УМКА_70254_704067",
    },
    {
        label: "ЧУКАРИЦА, ЧУКАРИЦА",
        value: "ЧУКАРИЦА_ЧУКАРИЦА_70254_704083",
    },
    {
        label: "ШАБАЦ, БЕЛА РЕКА",
        value: "ШАБАЦ_БЕЛА РЕКА_71269_704598",
    },
    {
        label: "ШАБАЦ, БОГОСАВАЦ",
        value: "ШАБАЦ_БОГОСАВАЦ_71269_744620",
    },
    {
        label: "ШАБАЦ, БОЈИЋ",
        value: "ШАБАЦ_БОЈИЋ_71269_744638",
    },
    {
        label: "ШАБАЦ, БУКОР",
        value: "ШАБАЦ_БУКОР_71269_744646",
    },
    {
        label: "ШАБАЦ, ВАРНА",
        value: "ШАБАЦ_ВАРНА_71269_744654",
    },
    {
        label: "ШАБАЦ, ВОЛУЈАЦ",
        value: "ШАБАЦ_ВОЛУЈАЦ_71269_744662",
    },
    {
        label: "ШАБАЦ, ГОРЊА ВРАЊСКА",
        value: "ШАБАЦ_ГОРЊА ВРАЊСКА_71269_744689",
    },
    {
        label: "ШАБАЦ, ГРУШИЋ",
        value: "ШАБАЦ_ГРУШИЋ_71269_744697",
    },
    {
        label: "ШАБАЦ, ДВОРИШТЕ",
        value: "ШАБАЦ_ДВОРИШТЕ_71269_744719",
    },
    {
        label: "ШАБАЦ, ДЕСИЋ",
        value: "ШАБАЦ_ДЕСИЋ_71269_744727",
    },
    {
        label: "ШАБАЦ, ДОБРИЋ",
        value: "ШАБАЦ_ДОБРИЋ_71269_744735",
    },
    {
        label: "ШАБАЦ, ДРЕНОВАЦ",
        value: "ШАБАЦ_ДРЕНОВАЦ_71269_704644",
    },
    {
        label: "ШАБАЦ, ДУВАНИШТЕ",
        value: "ШАБАЦ_ДУВАНИШТЕ_71269_704679",
    },
    {
        label: "ШАБАЦ, ЖАБАР",
        value: "ШАБАЦ_ЖАБАР_71269_744751",
    },
    {
        label: "ШАБАЦ, ЗАБЛАЋЕ",
        value: "ШАБАЦ_ЗАБЛАЋЕ_71269_744760",
    },
    {
        label: "ШАБАЦ, ЗМИЊАК",
        value: "ШАБАЦ_ЗМИЊАК_71269_704687",
    },
    {
        label: "ШАБАЦ, ЈЕВРЕМОВАЦ",
        value: "ШАБАЦ_ЈЕВРЕМОВАЦ_71269_744778",
    },
    {
        label: "ШАБАЦ, ЈЕЛЕНЧА",
        value: "ШАБАЦ_ЈЕЛЕНЧА_71269_744786",
    },
    {
        label: "ШАБАЦ, КОРМАН",
        value: "ШАБАЦ_КОРМАН_71269_744794",
    },
    {
        label: "ШАБАЦ, КРИВАЈА",
        value: "ШАБАЦ_КРИВАЈА_71269_744808",
    },
    {
        label: "ШАБАЦ, ЛИПОЛИСТ",
        value: "ШАБАЦ_ЛИПОЛИСТ_71269_704709",
    },
    {
        label: "ШАБАЦ, МАЈУР",
        value: "ШАБАЦ_МАЈУР_71269_744816",
    },
    {
        label: "ШАБАЦ, МАЛА ВРАЊСКА",
        value: "ШАБАЦ_МАЛА ВРАЊСКА_71269_744824",
    },
    {
        label: "ШАБАЦ, МАОВИ",
        value: "ШАБАЦ_МАОВИ_71269_744832",
    },
    {
        label: "ШАБАЦ, МАЧВАНСКИ ПРИЧИНОВИЋ",
        value: "ШАБАЦ_МАЧВАНСКИ ПРИЧИНОВИЋ_71269_704717",
    },
    {
        label: "ШАБАЦ, МЕТЛИЋ",
        value: "ШАБАЦ_МЕТЛИЋ_71269_744867",
    },
    {
        label: "ШАБАЦ, МИОКУС",
        value: "ШАБАЦ_МИОКУС_71269_744875",
    },
    {
        label: "ШАБАЦ, МИШАР",
        value: "ШАБАЦ_МИШАР_71269_744883",
    },
    {
        label: "ШАБАЦ, МРЂЕНОВАЦ",
        value: "ШАБАЦ_МРЂЕНОВАЦ_71269_744891",
    },
    {
        label: "ШАБАЦ, НАКУЧАНИ",
        value: "ШАБАЦ_НАКУЧАНИ_71269_744905",
    },
    {
        label: "ШАБАЦ, ОРАШАЦ",
        value: "ШАБАЦ_ОРАШАЦ_71269_744913",
    },
    {
        label: "ШАБАЦ, ОРИД",
        value: "ШАБАЦ_ОРИД_71269_744921",
    },
    {
        label: "ШАБАЦ, ПЕТКОВИЦА",
        value: "ШАБАЦ_ПЕТКОВИЦА_71269_704741",
    },
    {
        label: "ШАБАЦ, ПЕТЛОВАЧА",
        value: "ШАБАЦ_ПЕТЛОВАЧА_71269_704750",
    },
    {
        label: "ШАБАЦ, ПОЦЕРСКИ МЕТКОВИЋ",
        value: "ШАБАЦ_ПОЦЕРСКИ МЕТКОВИЋ_71269_744859",
    },
    {
        label: "ШАБАЦ, ПОЦЕРСКИ ПРИЧИНОВИЋ",
        value: "ШАБАЦ_ПОЦЕРСКИ ПРИЧИНОВИЋ_71269_744930",
    },
    {
        label: "ШАБАЦ, ПРЕДВОРИЦА",
        value: "ШАБАЦ_ПРЕДВОРИЦА_71269_744948",
    },
    {
        label: "ШАБАЦ, ПРЊАВОР",
        value: "ШАБАЦ_ПРЊАВОР_71269_704768",
    },
    {
        label: "ШАБАЦ, РАДОВАШНИЦА",
        value: "ШАБАЦ_РАДОВАШНИЦА_71269_744956",
    },
    {
        label: "ШАБАЦ, РИБАРИ",
        value: "ШАБАЦ_РИБАРИ_71269_704776",
    },
    {
        label: "ШАБАЦ, РУМСКА",
        value: "ШАБАЦ_РУМСКА_71269_744964",
    },
    {
        label: "ШАБАЦ, СИНОШЕВИЋ",
        value: "ШАБАЦ_СИНОШЕВИЋ_71269_744972",
    },
    {
        label: "ШАБАЦ, СЛЕПЧЕВИЋ",
        value: "ШАБАЦ_СЛЕПЧЕВИЋ_71269_704792",
    },
    {
        label: "ШАБАЦ, ТАБАНОВИЋ",
        value: "ШАБАЦ_ТАБАНОВИЋ_71269_704814",
    },
    {
        label: "ШАБАЦ, ЦЕРОВАЦ",
        value: "ШАБАЦ_ЦЕРОВАЦ_71269_709557",
    },
    {
        label: "ШАБАЦ, ЦУЉКОВИЋ",
        value: "ШАБАЦ_ЦУЉКОВИЋ_71269_745006",
    },
    {
        label: "ШАБАЦ, ШАБАЦ",
        value: "ШАБАЦ_ШАБАЦ_71269_745014",
    },
    {
        label: "ШАБАЦ, ШЕВАРИЦЕ",
        value: "ШАБАЦ_ШЕВАРИЦЕ_71269_704857",
    },
    {
        label: "ШАБАЦ, ШТИТАР",
        value: "ШАБАЦ_ШТИТАР_71269_704865",
    },
    {
        label: "ШИД, АДАШЕВЦИ",
        value: "ШИД_АДАШЕВЦИ_80497_804045",
    },
    {
        label: "ШИД, БАТРОВЦИ",
        value: "ШИД_БАТРОВЦИ_80497_804053",
    },
    {
        label: "ШИД, БАЧИНЦИ",
        value: "ШИД_БАЧИНЦИ_80497_804959",
    },
    {
        label: "ШИД, БЕРКАСОВО",
        value: "ШИД_БЕРКАСОВО_80497_804983",
    },
    {
        label: "ШИД, БИНГУЛА",
        value: "ШИД_БИНГУЛА_80497_804991",
    },
    {
        label: "ШИД, ВАШИЦА",
        value: "ШИД_ВАШИЦА_80497_804274",
    },
    {
        label: "ШИД, ВИШЊИЋЕВО",
        value: "ШИД_ВИШЊИЋЕВО_80497_804282",
    },
    {
        label: "ШИД, ГИБАРАЦ",
        value: "ШИД_ГИБАРАЦ_80497_805033",
    },
    {
        label: "ШИД, ЂИПША",
        value: "ШИД_ЂИПША_80497_805017",
    },
    {
        label: "ШИД, ЕРДЕВИК",
        value: "ШИД_ЕРДЕВИК_80497_805025",
    },
    {
        label: "ШИД, ИЛИНЦИ",
        value: "ШИД_ИЛИНЦИ_80497_804100",
    },
    {
        label: "ШИД, ЈАМЕНА",
        value: "ШИД_ЈАМЕНА_80497_804118",
    },
    {
        label: "ШИД, КУКУЈЕВЦИ",
        value: "ШИД_КУКУЈЕВЦИ_80497_805050",
    },
    {
        label: "ШИД, ЉУБА",
        value: "ШИД_ЉУБА_80497_805076",
    },
    {
        label: "ШИД, МОЛОВИН",
        value: "ШИД_МОЛОВИН_80497_805084",
    },
    {
        label: "ШИД, МОРОВИЋ",
        value: "ШИД_МОРОВИЋ_80497_804185",
    },
    {
        label: "ШИД, ПРИВИНА ГЛАВА",
        value: "ШИД_ПРИВИНА ГЛАВА_80497_805106",
    },
    {
        label: "ШИД, СОТ",
        value: "ШИД_СОТ_80497_805114",
    },
    {
        label: "ШИД, ШИД",
        value: "ШИД_ШИД_80497_805157",
    },
];

export const mestaOptions = [
    {
        label: "БЕОГРАД",
        value: "БЕОГРАД",
    },
    {
        label: "АДА",
        value: "АДА",
    },
    {
        label: "МОЛ",
        value: "МОЛ",
    },
    {
        label: "ОБОРЊАЧА",
        value: "ОБОРЊАЧА",
    },
    {
        label: "УТРИНЕ",
        value: "УТРИНЕ",
    },
    {
        label: "АЛЕКСАНДРОВАЦ",
        value: "АЛЕКСАНДРОВАЦ",
    },
    {
        label: "БЗЕНИЦЕ",
        value: "БЗЕНИЦЕ",
    },
    {
        label: "БОБОТЕ",
        value: "БОБОТЕ",
    },
    {
        label: "БОТУРИЋИ",
        value: "БОТУРИЋИ",
    },
    {
        label: "БРАТИЋИ",
        value: "БРАТИЋИ",
    },
    {
        label: "ВЕЛИКА ВРБНИЦА ГОРЊА",
        value: "ВЕЛИКА ВРБНИЦА ГОРЊА",
    },
    {
        label: "ВЕЛИКА ВРБНИЦА ДОЊА",
        value: "ВЕЛИКА ВРБНИЦА ДОЊА",
    },
    {
        label: "ВЕЉА ГЛАВА",
        value: "ВЕЉА ГЛАВА",
    },
    {
        label: "ВИТКОВО",
        value: "ВИТКОВО",
    },
    {
        label: "ВРАЖОГРНЦИ",
        value: "ВРАЖОГРНЦИ",
    },
    {
        label: "ВРАНШТИЦА",
        value: "ВРАНШТИЦА",
    },
    {
        label: "ГАРЕВИНА",
        value: "ГАРЕВИНА",
    },
    {
        label: "ГОРЊА ЗЛЕГИЊА",
        value: "ГОРЊА ЗЛЕГИЊА",
    },
    {
        label: "ГОРЊИ ВРАТАРИ",
        value: "ГОРЊИ ВРАТАРИ",
    },
    {
        label: "ГОРЊИ СТУПАЊ",
        value: "ГОРЊИ СТУПАЊ",
    },
    {
        label: "ГРЧАК",
        value: "ГРЧАК",
    },
    {
        label: "ДАШНИЦА",
        value: "ДАШНИЦА",
    },
    {
        label: "ДОБРОЉУПЦИ",
        value: "ДОБРОЉУПЦИ",
    },
    {
        label: "ДОЊА ЗЛЕГИЊА",
        value: "ДОЊА ЗЛЕГИЊА",
    },
    {
        label: "ДОЊИ ВРАТАРИ",
        value: "ДОЊИ ВРАТАРИ",
    },
    {
        label: "ДОЊИ СТУПАЊ",
        value: "ДОЊИ СТУПАЊ",
    },
    {
        label: "ДРЕНЧА",
        value: "ДРЕНЧА",
    },
    {
        label: "ЈЕЛАКЦИ",
        value: "ЈЕЛАКЦИ",
    },
    {
        label: "КОЖЕТИН",
        value: "КОЖЕТИН",
    },
    {
        label: "КОЗНИЦА",
        value: "КОЗНИЦА",
    },
    {
        label: "ЛАТКОВАЦ",
        value: "ЛАТКОВАЦ",
    },
    {
        label: "ЛАЋИСЛЕД",
        value: "ЛАЋИСЛЕД",
    },
    {
        label: "ЛЕСЕНОВЦИ",
        value: "ЛЕСЕНОВЦИ",
    },
    {
        label: "ЛЕСКОВИЦА",
        value: "ЛЕСКОВИЦА",
    },
    {
        label: "ЉУБИНЦИ",
        value: "ЉУБИНЦИ",
    },
    {
        label: "МРМОШ",
        value: "МРМОШ",
    },
    {
        label: "НОВАЦИ",
        value: "НОВАЦИ",
    },
    {
        label: "ПАРЧИН",
        value: "ПАРЧИН",
    },
    {
        label: "ПЛЕШ",
        value: "ПЛЕШ",
    },
    {
        label: "ПЛОЧА",
        value: "ПЛОЧА",
    },
    {
        label: "ПУХОВАЦ",
        value: "ПУХОВАЦ",
    },
    {
        label: "РАКЉА",
        value: "РАКЉА",
    },
    {
        label: "РАТАЈЕ",
        value: "РАТАЈЕ",
    },
    {
        label: "РЖАНИЦА",
        value: "РЖАНИЦА",
    },
    {
        label: "РОГАВЧИНА",
        value: "РОГАВЧИНА",
    },
    {
        label: "РОКЦИ",
        value: "РОКЦИ",
    },
    {
        label: "РУДЕНИЦЕ",
        value: "РУДЕНИЦЕ",
    },
    {
        label: "СТАЊЕВО",
        value: "СТАЊЕВО",
    },
    {
        label: "СТАРЦИ-ПОПОВЦИ",
        value: "СТАРЦИ-ПОПОВЦИ",
    },
    {
        label: "СТРМЕНИЦА",
        value: "СТРМЕНИЦА",
    },
    {
        label: "СТУБАЛ",
        value: "СТУБАЛ",
    },
    {
        label: "СУБОТИЦА",
        value: "СУБОТИЦА",
    },
    {
        label: "ТРЖАЦ",
        value: "ТРЖАЦ",
    },
    {
        label: "ТРНАВЦИ",
        value: "ТРНАВЦИ",
    },
    {
        label: "ТУЛЕШ",
        value: "ТУЛЕШ",
    },
    {
        label: "ШЉИВОВО",
        value: "ШЉИВОВО",
    },
    {
        label: "АЛЕКСИНАЦ",
        value: "АЛЕКСИНАЦ",
    },
    {
        label: "АЛЕКСИНАЦ ВАН ВАРОШ",
        value: "АЛЕКСИНАЦ ВАН ВАРОШ",
    },
    {
        label: "АЛЕКСИНАЦ ВАРОШ",
        value: "АЛЕКСИНАЦ ВАРОШ",
    },
    {
        label: "АЛЕКСИНАЧКИ БУЈМИР",
        value: "АЛЕКСИНАЧКИ БУЈМИР",
    },
    {
        label: "БАНКОВАЦ",
        value: "БАНКОВАЦ",
    },
    {
        label: "БЕЛИ БРЕГ",
        value: "БЕЛИ БРЕГ",
    },
    {
        label: "БЕЉА",
        value: "БЕЉА",
    },
    {
        label: "БОБОВИШТЕ",
        value: "БОБОВИШТЕ",
    },
    {
        label: "БОВАН",
        value: "БОВАН",
    },
    {
        label: "БРАДАРАЦ",
        value: "БРАДАРАЦ",
    },
    {
        label: "ВАКУП",
        value: "ВАКУП",
    },
    {
        label: "ВЕЛИКИ ДРЕНОВАЦ",
        value: "ВЕЛИКИ ДРЕНОВАЦ",
    },
    {
        label: "ВИТКОВАЦ",
        value: "ВИТКОВАЦ",
    },
    {
        label: "ВРЕЛО",
        value: "ВРЕЛО",
    },
    {
        label: "ВРЋЕНОВИЦА",
        value: "ВРЋЕНОВИЦА",
    },
    {
        label: "ВУКАЊА",
        value: "ВУКАЊА",
    },
    {
        label: "ВУКАШИНОВАЦ",
        value: "ВУКАШИНОВАЦ",
    },
    {
        label: "ГЛОГОВИЦА",
        value: "ГЛОГОВИЦА",
    },
    {
        label: "ГОЛЕШНИЦА",
        value: "ГОЛЕШНИЦА",
    },
    {
        label: "ГОРЊА ПЕШЧАНИЦА",
        value: "ГОРЊА ПЕШЧАНИЦА",
    },
    {
        label: "ГОРЊЕ СУХОТНО",
        value: "ГОРЊЕ СУХОТНО",
    },
    {
        label: "ГОРЊИ АДРОВАЦ",
        value: "ГОРЊИ АДРОВАЦ",
    },
    {
        label: "ГОРЊИ КРУПАЦ",
        value: "ГОРЊИ КРУПАЦ",
    },
    {
        label: "ГОРЊИ ЉУБЕШ",
        value: "ГОРЊИ ЉУБЕШ",
    },
    {
        label: "ГРЕДЕТИН",
        value: "ГРЕДЕТИН",
    },
    {
        label: "ГРЕЈАЧ",
        value: "ГРЕЈАЧ",
    },
    {
        label: "ДЕЛИГРАД",
        value: "ДЕЛИГРАД",
    },
    {
        label: "ДОБРУЈЕВАЦ",
        value: "ДОБРУЈЕВАЦ",
    },
    {
        label: "ДОЊА ПЕШЧАНИЦА",
        value: "ДОЊА ПЕШЧАНИЦА",
    },
    {
        label: "ДОЊЕ СУХОТНО",
        value: "ДОЊЕ СУХОТНО",
    },
    {
        label: "ДОЊИ АДРОВАЦ",
        value: "ДОЊИ АДРОВАЦ",
    },
    {
        label: "ДОЊИ КРУПАЦ",
        value: "ДОЊИ КРУПАЦ",
    },
    {
        label: "ДОЊИ ЉУБЕШ",
        value: "ДОЊИ ЉУБЕШ",
    },
    {
        label: "ДРАЖЕВАЦ",
        value: "ДРАЖЕВАЦ",
    },
    {
        label: "ЖИТКОВАЦ",
        value: "ЖИТКОВАЦ",
    },
    {
        label: "ЈАКОВЉЕ",
        value: "ЈАКОВЉЕ",
    },
    {
        label: "ЈАСЕЊЕ",
        value: "ЈАСЕЊЕ",
    },
    {
        label: "КАМЕНИЦА",
        value: "КАМЕНИЦА",
    },
    {
        label: "КАТУН",
        value: "КАТУН",
    },
    {
        label: "КОПРИВНИЦА",
        value: "КОПРИВНИЦА",
    },
    {
        label: "КОРМАН",
        value: "КОРМАН",
    },
    {
        label: "КРАЉЕВО",
        value: "КРАЉЕВО",
    },
    {
        label: "КРУШЈЕ",
        value: "КРУШЈЕ",
    },
    {
        label: "КУЛИНА",
        value: "КУЛИНА",
    },
    {
        label: "ЛИПОВАЦ",
        value: "ЛИПОВАЦ",
    },
    {
        label: "ЛОЗНАЦ",
        value: "ЛОЗНАЦ",
    },
    {
        label: "ЛОЋИКА",
        value: "ЛОЋИКА",
    },
    {
        label: "ЛУЖАНЕ",
        value: "ЛУЖАНЕ",
    },
    {
        label: "ЉУПТЕН",
        value: "ЉУПТЕН",
    },
    {
        label: "МАЛИ ДРЕНОВАЦ",
        value: "МАЛИ ДРЕНОВАЦ",
    },
    {
        label: "МОЗГОВО",
        value: "МОЗГОВО",
    },
    {
        label: "МОРАВАЦ",
        value: "МОРАВАЦ",
    },
    {
        label: "МОРАВСКИ БУЈМИР",
        value: "МОРАВСКИ БУЈМИР",
    },
    {
        label: "НОЗРИНА",
        value: "НОЗРИНА",
    },
    {
        label: "ПОРОДИН",
        value: "ПОРОДИН",
    },
    {
        label: "ПРЕКОНОЗИ",
        value: "ПРЕКОНОЗИ",
    },
    {
        label: "ПРЋИЛОВИЦА",
        value: "ПРЋИЛОВИЦА",
    },
    {
        label: "ПРУГОВАЦ",
        value: "ПРУГОВАЦ",
    },
    {
        label: "РАДЕВЦЕ",
        value: "РАДЕВЦЕ",
    },
    {
        label: "РСОВАЦ",
        value: "РСОВАЦ",
    },
    {
        label: "РУТЕВАЦ",
        value: "РУТЕВАЦ",
    },
    {
        label: "СРЕЗОВАЦ",
        value: "СРЕЗОВАЦ",
    },
    {
        label: "СТАНЦИ",
        value: "СТАНЦИ",
    },
    {
        label: "СТУБЛИНА",
        value: "СТУБЛИНА",
    },
    {
        label: "СУБОТИНАЦ",
        value: "СУБОТИНАЦ",
    },
    {
        label: "ТЕШИЦА",
        value: "ТЕШИЦА",
    },
    {
        label: "ТРЊАНЕ",
        value: "ТРЊАНЕ",
    },
    {
        label: "ЋИЋИНА",
        value: "ЋИЋИНА",
    },
    {
        label: "ЦРНА БАРА",
        value: "ЦРНА БАРА",
    },
    {
        label: "ЧЕСТА",
        value: "ЧЕСТА",
    },
    {
        label: "ЧУКУРОВАЦ",
        value: "ЧУКУРОВАЦ",
    },
    {
        label: "ШУРИЋ",
        value: "ШУРИЋ",
    },
    {
        label: "АЛИБУНАР",
        value: "АЛИБУНАР",
    },
    {
        label: "БАНАТСКИ КАРЛОВАЦ",
        value: "БАНАТСКИ КАРЛОВАЦ",
    },
    {
        label: "ВЛАДИМИРОВАЦ",
        value: "ВЛАДИМИРОВАЦ",
    },
    {
        label: "ДОБРИЦА",
        value: "ДОБРИЦА",
    },
    {
        label: "ИЛАНЏА",
        value: "ИЛАНЏА",
    },
    {
        label: "ЈАНОШИК",
        value: "ЈАНОШИК",
    },
    {
        label: "ЛОКВЕ",
        value: "ЛОКВЕ",
    },
    {
        label: "НИКОЛИНЦИ",
        value: "НИКОЛИНЦИ",
    },
    {
        label: "НОВИ КОЗЈАК",
        value: "НОВИ КОЗЈАК",
    },
    {
        label: "СЕЛЕУШ",
        value: "СЕЛЕУШ",
    },
    {
        label: "АПАТИН",
        value: "АПАТИН",
    },
    {
        label: "КУПУСИНА",
        value: "КУПУСИНА",
    },
    {
        label: "ПРИГРЕВИЦА",
        value: "ПРИГРЕВИЦА",
    },
    {
        label: "СВИЛОЈЕВО",
        value: "СВИЛОЈЕВО",
    },
    {
        label: "СОНТА",
        value: "СОНТА",
    },
    {
        label: "АРАНЂЕЛОВАЦ",
        value: "АРАНЂЕЛОВАЦ",
    },
    {
        label: "БАЊА",
        value: "БАЊА",
    },
    {
        label: "БОСУТА",
        value: "БОСУТА",
    },
    {
        label: "БРЕЗОВАЦ",
        value: "БРЕЗОВАЦ",
    },
    {
        label: "БУКОВИК",
        value: "БУКОВИК",
    },
    {
        label: "ВЕНЧАНЕ",
        value: "ВЕНЧАНЕ",
    },
    {
        label: "ВРБИЦА",
        value: "ВРБИЦА",
    },
    {
        label: "ВУКОСАВЦИ",
        value: "ВУКОСАВЦИ",
    },
    {
        label: "ГАРАШИ",
        value: "ГАРАШИ",
    },
    {
        label: "ГОРЊА ТРЕШЊЕВИЦА",
        value: "ГОРЊА ТРЕШЊЕВИЦА",
    },
    {
        label: "ЈЕЛОВИК",
        value: "ЈЕЛОВИК",
    },
    {
        label: "КОПЉАРЕ",
        value: "КОПЉАРЕ",
    },
    {
        label: "МИСАЧА",
        value: "МИСАЧА",
    },
    {
        label: "ОРАШАЦ",
        value: "ОРАШАЦ",
    },
    {
        label: "ПАРТИЗАНИ",
        value: "ПАРТИЗАНИ",
    },
    {
        label: "ПРОГОРЕОЦИ",
        value: "ПРОГОРЕОЦИ",
    },
    {
        label: "РАНИЛОВИЋ",
        value: "РАНИЛОВИЋ",
    },
    {
        label: "СТОЈНИК",
        value: "СТОЈНИК",
    },
    {
        label: "ТУЛЕЖ",
        value: "ТУЛЕЖ",
    },
    {
        label: "АРИЉЕ",
        value: "АРИЉЕ",
    },
    {
        label: "БЈЕЛУША",
        value: "БЈЕЛУША",
    },
    {
        label: "БОГОЈЕВИЋИ",
        value: "БОГОЈЕВИЋИ",
    },
    {
        label: "БРЕКОВО",
        value: "БРЕКОВО",
    },
    {
        label: "ВИГОШТЕ-ПОГЛЕД",
        value: "ВИГОШТЕ-ПОГЛЕД",
    },
    {
        label: "ВИРОВО",
        value: "ВИРОВО",
    },
    {
        label: "ВИСОКА",
        value: "ВИСОКА",
    },
    {
        label: "ВРАНЕ",
        value: "ВРАНЕ",
    },
    {
        label: "ГРДОВИЋИ",
        value: "ГРДОВИЋИ",
    },
    {
        label: "ГРИВСКА",
        value: "ГРИВСКА",
    },
    {
        label: "ДОБРАЧЕ",
        value: "ДОБРАЧЕ",
    },
    {
        label: "ДРАГОЈЕВАЦ",
        value: "ДРАГОЈЕВАЦ",
    },
    {
        label: "ЂЕДОВАЦ",
        value: "ЂЕДОВАЦ",
    },
    {
        label: "КРУШЧИЦА",
        value: "КРУШЧИЦА",
    },
    {
        label: "ЛАТВИЦА",
        value: "ЛАТВИЦА",
    },
    {
        label: "МИРОСАЉЦИ",
        value: "МИРОСАЉЦИ",
    },
    {
        label: "РАДОБУЂА",
        value: "РАДОБУЂА",
    },
    {
        label: "РАДОШЕВО",
        value: "РАДОШЕВО",
    },
    {
        label: "СЕВЕРОВО",
        value: "СЕВЕРОВО",
    },
    {
        label: "СТУПЧЕВИЋИ",
        value: "СТУПЧЕВИЋИ",
    },
    {
        label: "ТРЕШЊЕВИЦА",
        value: "ТРЕШЊЕВИЦА",
    },
    {
        label: "ЦЕРОВА",
        value: "ЦЕРОВА",
    },
    {
        label: "БАБУШНИЦА",
        value: "БАБУШНИЦА",
    },
    {
        label: "БЕРДУЈ",
        value: "БЕРДУЈ",
    },
    {
        label: "БЕРИН ИЗВОР",
        value: "БЕРИН ИЗВОР",
    },
    {
        label: "БОГДАНОВАЦ",
        value: "БОГДАНОВАЦ",
    },
    {
        label: "БРАТИШЕВАЦ",
        value: "БРАТИШЕВАЦ",
    },
    {
        label: "БРЕСТОВ ДОЛ",
        value: "БРЕСТОВ ДОЛ",
    },
    {
        label: "ВАВА",
        value: "ВАВА",
    },
    {
        label: "ВАЛНИШ",
        value: "ВАЛНИШ",
    },
    {
        label: "ВЕЛИКО БОЊИНЦЕ",
        value: "ВЕЛИКО БОЊИНЦЕ",
    },
    {
        label: "ВУЧИ ДЕЛ",
        value: "ВУЧИ ДЕЛ",
    },
    {
        label: "ГОРЊЕ КРЊИНО",
        value: "ГОРЊЕ КРЊИНО",
    },
    {
        label: "ГОРЧИНЦЕ",
        value: "ГОРЧИНЦЕ",
    },
    {
        label: "ГРНЧАР",
        value: "ГРНЧАР",
    },
    {
        label: "ДОЛ",
        value: "ДОЛ",
    },
    {
        label: "ДОЊЕ КРЊИНО",
        value: "ДОЊЕ КРЊИНО",
    },
    {
        label: "ДРАГИНАЦ",
        value: "ДРАГИНАЦ",
    },
    {
        label: "ДУЧЕВАЦ",
        value: "ДУЧЕВАЦ",
    },
    {
        label: "ЗАВИДИНЦЕ",
        value: "ЗАВИДИНЦЕ",
    },
    {
        label: "ЗВОНЦЕ",
        value: "ЗВОНЦЕ",
    },
    {
        label: "ИЗВОР",
        value: "ИЗВОР",
    },
    {
        label: "ЈАСЕНОВ ДЕЛ",
        value: "ЈАСЕНОВ ДЕЛ",
    },
    {
        label: "КАЛУЂЕРОВО",
        value: "КАЛУЂЕРОВО",
    },
    {
        label: "КАМБЕЛЕВАЦ",
        value: "КАМБЕЛЕВАЦ",
    },
    {
        label: "КИЈЕВАЦ",
        value: "КИЈЕВАЦ",
    },
    {
        label: "ЛИНОВО",
        value: "ЛИНОВО",
    },
    {
        label: "ЉУБЕРАЂА",
        value: "ЉУБЕРАЂА",
    },
    {
        label: "МАЛО БОЊИНЦЕ",
        value: "МАЛО БОЊИНЦЕ",
    },
    {
        label: "МАСУРОВАЦ",
        value: "МАСУРОВАЦ",
    },
    {
        label: "МЕЗГРАЈА",
        value: "МЕЗГРАЈА",
    },
    {
        label: "МОДРА СТЕНА",
        value: "МОДРА СТЕНА",
    },
    {
        label: "НАШУШКОВИЦА",
        value: "НАШУШКОВИЦА",
    },
    {
        label: "ОСТАТОВИЦА",
        value: "ОСТАТОВИЦА",
    },
    {
        label: "ПРЕСЕКА",
        value: "ПРЕСЕКА",
    },
    {
        label: "ПРОВАЉЕНИК",
        value: "ПРОВАЉЕНИК",
    },
    {
        label: "РАДИЊИНЦЕ",
        value: "РАДИЊИНЦЕ",
    },
    {
        label: "РАДОСИН",
        value: "РАДОСИН",
    },
    {
        label: "РАДОШЕВАЦ",
        value: "РАДОШЕВАЦ",
    },
    {
        label: "РАКИТА",
        value: "РАКИТА",
    },
    {
        label: "РАКОВ ДОЛ",
        value: "РАКОВ ДОЛ",
    },
    {
        label: "РАЉИН",
        value: "РАЉИН",
    },
    {
        label: "РЕСНИК",
        value: "РЕСНИК",
    },
    {
        label: "СТОЛ",
        value: "СТОЛ",
    },
    {
        label: "СТРЕЛАЦ",
        value: "СТРЕЛАЦ",
    },
    {
        label: "СТРИЖЕВАЦ",
        value: "СТРИЖЕВАЦ",
    },
    {
        label: "СТУДЕНА",
        value: "СТУДЕНА",
    },
    {
        label: "СУРАЧЕВО",
        value: "СУРАЧЕВО",
    },
    {
        label: "ЦРВЕНА ЈАБУКА",
        value: "ЦРВЕНА ЈАБУКА",
    },
    {
        label: "ШТРБОВАЦ",
        value: "ШТРБОВАЦ",
    },
    {
        label: "БАЈИНА БАШТА",
        value: "БАЈИНА БАШТА",
    },
    {
        label: "БЕСЕРОВИНА",
        value: "БЕСЕРОВИНА",
    },
    {
        label: "ВИШЕСАВА",
        value: "ВИШЕСАВА",
    },
    {
        label: "ГВОЗДАЦ",
        value: "ГВОЗДАЦ",
    },
    {
        label: "ГОРЊЕ ЗАРОЖЈЕ",
        value: "ГОРЊЕ ЗАРОЖЈЕ",
    },
    {
        label: "ДОБРОТИН",
        value: "ДОБРОТИН",
    },
    {
        label: "ДОЊЕ ЗАРОЖЈЕ",
        value: "ДОЊЕ ЗАРОЖЈЕ",
    },
    {
        label: "ДРАКСИН",
        value: "ДРАКСИН",
    },
    {
        label: "ДУБ",
        value: "ДУБ",
    },
    {
        label: "ЗАГЛАВАК",
        value: "ЗАГЛАВАК",
    },
    {
        label: "ЗАОВИНЕ",
        value: "ЗАОВИНЕ",
    },
    {
        label: "ЗАУГЛИНЕ",
        value: "ЗАУГЛИНЕ",
    },
    {
        label: "ЗЛОДОЛ",
        value: "ЗЛОДОЛ",
    },
    {
        label: "ЈАГОШТИЦА",
        value: "ЈАГОШТИЦА",
    },
    {
        label: "ЈАКАЉ",
        value: "ЈАКАЉ",
    },
    {
        label: "КОЊСКА РЕКА",
        value: "КОЊСКА РЕКА",
    },
    {
        label: "КОСТОЈЕВИЋИ",
        value: "КОСТОЈЕВИЋИ",
    },
    {
        label: "ЛУГ",
        value: "ЛУГ",
    },
    {
        label: "ЉЕШТАНСКО",
        value: "ЉЕШТАНСКО",
    },
    {
        label: "МАЛА РЕКА",
        value: "МАЛА РЕКА",
    },
    {
        label: "ОБАЈГОРА",
        value: "ОБАЈГОРА",
    },
    {
        label: "ОВЧИЊА",
        value: "ОВЧИЊА",
    },
    {
        label: "ОКЛЕТАЦ",
        value: "ОКЛЕТАЦ",
    },
    {
        label: "ПЕРУЋАЦ",
        value: "ПЕРУЋАЦ",
    },
    {
        label: "ПИЛИЦА",
        value: "ПИЛИЦА",
    },
    {
        label: "ПРИДОЛИ",
        value: "ПРИДОЛИ",
    },
    {
        label: "РАСТИШТЕ",
        value: "РАСТИШТЕ",
    },
    {
        label: "РАЧА",
        value: "РАЧА",
    },
    {
        label: "РОГАЧИЦА",
        value: "РОГАЧИЦА",
    },
    {
        label: "СВОЈДРУГ",
        value: "СВОЈДРУГ",
    },
    {
        label: "СИЈЕРАЧ",
        value: "СИЈЕРАЧ",
    },
    {
        label: "СОЛОТУША",
        value: "СОЛОТУША",
    },
    {
        label: "СТРМОВО",
        value: "СТРМОВО",
    },
    {
        label: "ЦРВИЦА",
        value: "ЦРВИЦА",
    },
    {
        label: "БАРАЈЕВО",
        value: "БАРАЈЕВО",
    },
    {
        label: "АРНАЈЕВО",
        value: "АРНАЈЕВО",
    },
    {
        label: "БАЋЕВАЦ",
        value: "БАЋЕВАЦ",
    },
    {
        label: "БЕЉИНА",
        value: "БЕЉИНА",
    },
    {
        label: "БОЖДАРЕВАЦ",
        value: "БОЖДАРЕВАЦ",
    },
    {
        label: "ВЕЛИКИ БОРАК",
        value: "ВЕЛИКИ БОРАК",
    },
    {
        label: "ВРАНИЋ",
        value: "ВРАНИЋ",
    },
    {
        label: "ГУНЦАТИ",
        value: "ГУНЦАТИ",
    },
    {
        label: "ЛИСОВИЋ",
        value: "ЛИСОВИЋ",
    },
    {
        label: "МАНИЋ",
        value: "МАНИЋ",
    },
    {
        label: "МЕЉАК",
        value: "МЕЉАК",
    },
    {
        label: "РОЖАНЦИ",
        value: "РОЖАНЦИ",
    },
    {
        label: "ШИЉАКОВАЦ",
        value: "ШИЉАКОВАЦ",
    },
    {
        label: "БАТОЧИНА",
        value: "БАТОЧИНА",
    },
    {
        label: "БАДЊЕВАЦ",
        value: "БАДЊЕВАЦ",
    },
    {
        label: "БАТОЧИНА (ВАРОШИЦА)",
        value: "БАТОЧИНА (ВАРОШИЦА)",
    },
    {
        label: "БАТОЧИНА (СЕЛО)",
        value: "БАТОЧИНА (СЕЛО)",
    },
    {
        label: "БРЗАН",
        value: "БРЗАН",
    },
    {
        label: "ГРАДАЦ",
        value: "ГРАДАЦ",
    },
    {
        label: "ДОБРОВОДИЦА",
        value: "ДОБРОВОДИЦА",
    },
    {
        label: "ЖИРОВНИЦА",
        value: "ЖИРОВНИЦА",
    },
    {
        label: "КИЈЕВО",
        value: "КИЈЕВО",
    },
    {
        label: "МИЛАТОВАЦ",
        value: "МИЛАТОВАЦ",
    },
    {
        label: "НИКШИЋ",
        value: "НИКШИЋ",
    },
    {
        label: "ПРЊАВОР",
        value: "ПРЊАВОР",
    },
    {
        label: "ЦРНИ КАО",
        value: "ЦРНИ КАО",
    },
    {
        label: "БАЧ",
        value: "БАЧ",
    },
    {
        label: "БАЧКО НОВО СЕЛО",
        value: "БАЧКО НОВО СЕЛО",
    },
    {
        label: "БОЂАНИ",
        value: "БОЂАНИ",
    },
    {
        label: "ВАЈСКА",
        value: "ВАЈСКА",
    },
    {
        label: "ПЛАВНА",
        value: "ПЛАВНА",
    },
    {
        label: "СЕЛЕНЧА",
        value: "СЕЛЕНЧА",
    },
    {
        label: "БАЧКА ПАЛАНКА",
        value: "БАЧКА ПАЛАНКА",
    },
    {
        label: "БАЧКА ПАЛАНКА-ГРАД",
        value: "БАЧКА ПАЛАНКА-ГРАД",
    },
    {
        label: "ВИЗИЋ",
        value: "ВИЗИЋ",
    },
    {
        label: "ГАЈДОБРА",
        value: "ГАЈДОБРА",
    },
    {
        label: "ДЕСПОТОВО",
        value: "ДЕСПОТОВО",
    },
    {
        label: "МЛАДЕНОВО",
        value: "МЛАДЕНОВО",
    },
    {
        label: "НЕШТИН",
        value: "НЕШТИН",
    },
    {
        label: "НОВА ГАЈДОБРА",
        value: "НОВА ГАЈДОБРА",
    },
    {
        label: "НОВА ПАЛАНКА",
        value: "НОВА ПАЛАНКА",
    },
    {
        label: "ОБРОВАЦ",
        value: "ОБРОВАЦ",
    },
    {
        label: "ПАРАГЕ",
        value: "ПАРАГЕ",
    },
    {
        label: "ПИВНИЦЕ",
        value: "ПИВНИЦЕ",
    },
    {
        label: "СИЛБАШ",
        value: "СИЛБАШ",
    },
    {
        label: "ТОВАРИШЕВО",
        value: "ТОВАРИШЕВО",
    },
    {
        label: "ЧЕЛАРЕВО",
        value: "ЧЕЛАРЕВО",
    },
    {
        label: "БАЧКА ТОПОЛА",
        value: "БАЧКА ТОПОЛА",
    },
    {
        label: "БАЈША",
        value: "БАЈША",
    },
    {
        label: "БАЧКА ТОПОЛА-ГРАД",
        value: "БАЧКА ТОПОЛА-ГРАД",
    },
    {
        label: "ГОРЊА РОГАТИЦА",
        value: "ГОРЊА РОГАТИЦА",
    },
    {
        label: "ГУНАРОШ",
        value: "ГУНАРОШ",
    },
    {
        label: "МАЛИ БЕОГРАД",
        value: "МАЛИ БЕОГРАД",
    },
    {
        label: "НОВО ОРАХОВО",
        value: "НОВО ОРАХОВО",
    },
    {
        label: "ЊЕГОШЕВО",
        value: "ЊЕГОШЕВО",
    },
    {
        label: "ПАЧИР",
        value: "ПАЧИР",
    },
    {
        label: "СТАРА МОРАВИЦА",
        value: "СТАРА МОРАВИЦА",
    },
    {
        label: "БАЧКИ ПЕТРОВАЦ",
        value: "БАЧКИ ПЕТРОВАЦ",
    },
    {
        label: "ГЛОЖАН",
        value: "ГЛОЖАН",
    },
    {
        label: "КУЛПИН",
        value: "КУЛПИН",
    },
    {
        label: "МАГЛИЋ",
        value: "МАГЛИЋ",
    },
    {
        label: "БЕЛА ПАЛАНКА",
        value: "БЕЛА ПАЛАНКА",
    },
    {
        label: "БАБИН КАЛ I",
        value: "БАБИН КАЛ I",
    },
    {
        label: "БАБИН КАЛ II",
        value: "БАБИН КАЛ II",
    },
    {
        label: "БЕЖИШТЕ",
        value: "БЕЖИШТЕ",
    },
    {
        label: "БЕЛА ПАЛАНКА-ВАН ВАРОШ",
        value: "БЕЛА ПАЛАНКА-ВАН ВАРОШ",
    },
    {
        label: "БЕЛА ПАЛАНКА-ВАРОШ",
        value: "БЕЛА ПАЛАНКА-ВАРОШ",
    },
    {
        label: "БУКУРОВАЦ",
        value: "БУКУРОВАЦ",
    },
    {
        label: "ВЕТА",
        value: "ВЕТА",
    },
    {
        label: "ВИТАНОВАЦ",
        value: "ВИТАНОВАЦ",
    },
    {
        label: "ВРАНДОЛ",
        value: "ВРАНДОЛ",
    },
    {
        label: "ВРГУДИНАЦ",
        value: "ВРГУДИНАЦ",
    },
    {
        label: "ГЛОГОВАЦ",
        value: "ГЛОГОВАЦ",
    },
    {
        label: "ГОРЊА ГЛАМА",
        value: "ГОРЊА ГЛАМА",
    },
    {
        label: "ГОРЊА КОРИТНИЦА",
        value: "ГОРЊА КОРИТНИЦА",
    },
    {
        label: "ГОРЊИ РИЊ",
        value: "ГОРЊИ РИЊ",
    },
    {
        label: "ГРАДИШТЕ I",
        value: "ГРАДИШТЕ I",
    },
    {
        label: "ГРАДИШТЕ II",
        value: "ГРАДИШТЕ II",
    },
    {
        label: "ДИВЉАНА",
        value: "ДИВЉАНА",
    },
    {
        label: "ДОЛАЦ",
        value: "ДОЛАЦ",
    },
    {
        label: "ДОЊА ГЛАМА",
        value: "ДОЊА ГЛАМА",
    },
    {
        label: "ДОЊА КОРИТНИЦА",
        value: "ДОЊА КОРИТНИЦА",
    },
    {
        label: "ДОЊИ РИЊ",
        value: "ДОЊИ РИЊ",
    },
    {
        label: "ДРАЖЕВО",
        value: "ДРАЖЕВО",
    },
    {
        label: "КЛЕЊЕ",
        value: "КЛЕЊЕ",
    },
    {
        label: "КЛИСУРА",
        value: "КЛИСУРА",
    },
    {
        label: "КОЗЈА",
        value: "КОЗЈА",
    },
    {
        label: "КОСМОВАЦ",
        value: "КОСМОВАЦ",
    },
    {
        label: "КРЕМЕНИЦА",
        value: "КРЕМЕНИЦА",
    },
    {
        label: "КРУПАЦ",
        value: "КРУПАЦ",
    },
    {
        label: "ЛАНИШТЕ",
        value: "ЛАНИШТЕ",
    },
    {
        label: "ЛЕСКОВИК",
        value: "ЛЕСКОВИК",
    },
    {
        label: "ЉУБАТОВИЦА",
        value: "ЉУБАТОВИЦА",
    },
    {
        label: "МИРАНОВАЦ",
        value: "МИРАНОВАЦ",
    },
    {
        label: "МОКЛИШТЕ",
        value: "МОКЛИШТЕ",
    },
    {
        label: "МОКРА",
        value: "МОКРА",
    },
    {
        label: "НОВО СЕЛО",
        value: "НОВО СЕЛО",
    },
    {
        label: "ОРЕОВАЦ",
        value: "ОРЕОВАЦ",
    },
    {
        label: "ПАЈЕЖ",
        value: "ПАЈЕЖ",
    },
    {
        label: "СИЊАЦ",
        value: "СИЊАЦ",
    },
    {
        label: "ТАМЊАНИЦА",
        value: "ТАМЊАНИЦА",
    },
    {
        label: "ТЕЛОВАЦ",
        value: "ТЕЛОВАЦ",
    },
    {
        label: "ТОПОНИЦА",
        value: "ТОПОНИЦА",
    },
    {
        label: "ТРЕШЊАНЦИ",
        value: "ТРЕШЊАНЦИ",
    },
    {
        label: "ЦРВЕНА РЕКА",
        value: "ЦРВЕНА РЕКА",
    },
    {
        label: "ЦРВЕНИ БРЕГ",
        value: "ЦРВЕНИ БРЕГ",
    },
    {
        label: "ЦРНЧЕ",
        value: "ЦРНЧЕ",
    },
    {
        label: "ЧИФЛИК",
        value: "ЧИФЛИК",
    },
    {
        label: "ШЉИВОВИК",
        value: "ШЉИВОВИК",
    },
    {
        label: "ШПАЈ",
        value: "ШПАЈ",
    },
    {
        label: "БЕЛА ЦРКВА",
        value: "БЕЛА ЦРКВА",
    },
    {
        label: "БАНАТСКА ПАЛАНКА I",
        value: "БАНАТСКА ПАЛАНКА I",
    },
    {
        label: "БАНАТСКА ПАЛАНКА II",
        value: "БАНАТСКА ПАЛАНКА II",
    },
    {
        label: "БАНАТСКА СУБОТИЦА",
        value: "БАНАТСКА СУБОТИЦА",
    },
    {
        label: "ВРАЧЕВ ГАЈ I",
        value: "ВРАЧЕВ ГАЈ I",
    },
    {
        label: "ВРАЧЕВ ГАЈ II",
        value: "ВРАЧЕВ ГАЈ II",
    },
    {
        label: "ГРЕБЕНАЦ",
        value: "ГРЕБЕНАЦ",
    },
    {
        label: "ДОБРИЧЕВО",
        value: "ДОБРИЧЕВО",
    },
    {
        label: "ДУПЉАЈА",
        value: "ДУПЉАЈА",
    },
    {
        label: "ЈАСЕНОВО",
        value: "ЈАСЕНОВО",
    },
    {
        label: "КАЈТАСОВО",
        value: "КАЈТАСОВО",
    },
    {
        label: "КАЛУЂЕРОВО I",
        value: "КАЛУЂЕРОВО I",
    },
    {
        label: "КАЛУЂЕРОВО II",
        value: "КАЛУЂЕРОВО II",
    },
    {
        label: "КАЛУЂЕРОВО III",
        value: "КАЛУЂЕРОВО III",
    },
    {
        label: "КУСИЋ I",
        value: "КУСИЋ I",
    },
    {
        label: "КУСИЋ II",
        value: "КУСИЋ II",
    },
    {
        label: "КУСИЋ III",
        value: "КУСИЋ III",
    },
    {
        label: "ЦРВЕНА ЦРКВА",
        value: "ЦРВЕНА ЦРКВА",
    },
    {
        label: "ЧЕШКО СЕЛО",
        value: "ЧЕШКО СЕЛО",
    },
    {
        label: "БЕОЧИН",
        value: "БЕОЧИН",
    },
    {
        label: "БАНОШТОР",
        value: "БАНОШТОР",
    },
    {
        label: "ГРАБОВО",
        value: "ГРАБОВО",
    },
    {
        label: "РАКОВАЦ",
        value: "РАКОВАЦ",
    },
    {
        label: "СВИЛОШ",
        value: "СВИЛОШ",
    },
    {
        label: "СУСЕК",
        value: "СУСЕК",
    },
    {
        label: "ЧЕРЕВИЋ",
        value: "ЧЕРЕВИЋ",
    },
    {
        label: "БЕЧЕЈ",
        value: "БЕЧЕЈ",
    },
    {
        label: "БАЧКО ГРАДИШТЕ",
        value: "БАЧКО ГРАДИШТЕ",
    },
    {
        label: "БАЧКО ПЕТРОВО СЕЛО",
        value: "БАЧКО ПЕТРОВО СЕЛО",
    },
    {
        label: "МИЛЕШЕВО",
        value: "МИЛЕШЕВО",
    },
    {
        label: "РАДИЧЕВИЋ",
        value: "РАДИЧЕВИЋ",
    },
    {
        label: "БЛАЦЕ",
        value: "БЛАЦЕ",
    },
    {
        label: "АЛАБАНА",
        value: "АЛАБАНА",
    },
    {
        label: "БАРБАТОВАЦ",
        value: "БАРБАТОВАЦ",
    },
    {
        label: "ВИШЕ СЕЛО",
        value: "ВИШЕ СЕЛО",
    },
    {
        label: "ВРБОВАЦ",
        value: "ВРБОВАЦ",
    },
    {
        label: "ГОРЊА ДРАГУША",
        value: "ГОРЊА ДРАГУША",
    },
    {
        label: "ГОРЊА ЈОШАНИЦА",
        value: "ГОРЊА ЈОШАНИЦА",
    },
    {
        label: "ГОРЊИ КАШЕВАР",
        value: "ГОРЊИ КАШЕВАР",
    },
    {
        label: "ГРГУРЕ",
        value: "ГРГУРЕ",
    },
    {
        label: "ДОЊА ДРАГУША",
        value: "ДОЊА ДРАГУША",
    },
    {
        label: "ДОЊА ЈОШАНИЦА",
        value: "ДОЊА ЈОШАНИЦА",
    },
    {
        label: "ДОЊА РАШИЦА",
        value: "ДОЊА РАШИЦА",
    },
    {
        label: "ДОЊИ КАШЕВАР",
        value: "ДОЊИ КАШЕВАР",
    },
    {
        label: "ЂУРЕВАЦ",
        value: "ЂУРЕВАЦ",
    },
    {
        label: "КАЧАПОР",
        value: "КАЧАПОР",
    },
    {
        label: "КРИВАЈА",
        value: "КРИВАЈА",
    },
    {
        label: "КУТЛОВАЦ",
        value: "КУТЛОВАЦ",
    },
    {
        label: "ЛАЗАРЕВАЦ",
        value: "ЛАЗАРЕВАЦ",
    },
    {
        label: "МЕЂУХАНА",
        value: "МЕЂУХАНА",
    },
    {
        label: "МУЗАЋЕ",
        value: "МУЗАЋЕ",
    },
    {
        label: "ПОПОВА",
        value: "ПОПОВА",
    },
    {
        label: "ПРЕБРЕЗА",
        value: "ПРЕБРЕЗА",
    },
    {
        label: "ПРЕТРЕШЊА",
        value: "ПРЕТРЕШЊА",
    },
    {
        label: "ПРИДВОРИЦА",
        value: "ПРИДВОРИЦА",
    },
    {
        label: "РАШИЦА",
        value: "РАШИЦА",
    },
    {
        label: "СВАРЧЕ",
        value: "СВАРЧЕ",
    },
    {
        label: "СИБНИЦА",
        value: "СИБНИЦА",
    },
    {
        label: "СУВАЈА",
        value: "СУВАЈА",
    },
    {
        label: "СУВИ ДО",
        value: "СУВИ ДО",
    },
    {
        label: "ТРБУЊЕ",
        value: "ТРБУЊЕ",
    },
    {
        label: "ЧУНГУЛА",
        value: "ЧУНГУЛА",
    },
    {
        label: "ЧУЧАЛЕ",
        value: "ЧУЧАЛЕ",
    },
    {
        label: "ЏЕПНИЦА",
        value: "ЏЕПНИЦА",
    },
    {
        label: "ШИЉОМАНА",
        value: "ШИЉОМАНА",
    },
    {
        label: "БОГАТИЋ",
        value: "БОГАТИЋ",
    },
    {
        label: "БАДОВИНЦИ",
        value: "БАДОВИНЦИ",
    },
    {
        label: "БАНОВО ПОЉЕ",
        value: "БАНОВО ПОЉЕ",
    },
    {
        label: "БЕЛОТИЋ",
        value: "БЕЛОТИЋ",
    },
    {
        label: "ГЛУШЦИ",
        value: "ГЛУШЦИ",
    },
    {
        label: "ДУБЉЕ",
        value: "ДУБЉЕ",
    },
    {
        label: "МЕТКОВИЋ",
        value: "МЕТКОВИЋ",
    },
    {
        label: "ОЧАГЕ",
        value: "ОЧАГЕ",
    },
    {
        label: "САЛАШ ЦРНОБАРСКИ",
        value: "САЛАШ ЦРНОБАРСКИ",
    },
    {
        label: "СОВЉАК",
        value: "СОВЉАК",
    },
    {
        label: "УЗВЕЋЕ",
        value: "УЗВЕЋЕ",
    },
    {
        label: "БОЈНИК",
        value: "БОЈНИК",
    },
    {
        label: "БОРИНЦЕ",
        value: "БОРИНЦЕ",
    },
    {
        label: "БРЕСТОВАЦ",
        value: "БРЕСТОВАЦ",
    },
    {
        label: "ВУЈАНОВО",
        value: "ВУЈАНОВО",
    },
    {
        label: "ГОРЊЕ БРИЈАЊЕ",
        value: "ГОРЊЕ БРИЈАЊЕ",
    },
    {
        label: "ГОРЊЕ КОЊУВЦЕ",
        value: "ГОРЊЕ КОЊУВЦЕ",
    },
    {
        label: "ГРАНИЦА",
        value: "ГРАНИЦА",
    },
    {
        label: "ДОЊЕ КОЊУВЦЕ",
        value: "ДОЊЕ КОЊУВЦЕ",
    },
    {
        label: "ДРАГОВАЦ",
        value: "ДРАГОВАЦ",
    },
    {
        label: "ДУБРАВА",
        value: "ДУБРАВА",
    },
    {
        label: "ЂИНЂУША",
        value: "ЂИНЂУША",
    },
    {
        label: "ЗЕЛЕТОВО",
        value: "ЗЕЛЕТОВО",
    },
    {
        label: "ЗОРОВАЦ",
        value: "ЗОРОВАЦ",
    },
    {
        label: "ИВАЊЕ",
        value: "ИВАЊЕ",
    },
    {
        label: "КАЦАБАЋ I",
        value: "КАЦАБАЋ I",
    },
    {
        label: "КАЦАБАЋ II",
        value: "КАЦАБАЋ II",
    },
    {
        label: "КОСАНЧИЋ",
        value: "КОСАНЧИЋ",
    },
    {
        label: "ЛАПОТИНЦЕ",
        value: "ЛАПОТИНЦЕ",
    },
    {
        label: "ЛОЗАНЕ",
        value: "ЛОЗАНЕ",
    },
    {
        label: "МАГАШ-ДОБРА ВОДА",
        value: "МАГАШ-ДОБРА ВОДА",
    },
    {
        label: "МАЈКОВАЦ",
        value: "МАЈКОВАЦ",
    },
    {
        label: "МИЈАЈЛИЦА",
        value: "МИЈАЈЛИЦА",
    },
    {
        label: "МРВЕШ",
        value: "МРВЕШ",
    },
    {
        label: "ОБИЛИЋ",
        value: "ОБИЛИЋ",
    },
    {
        label: "ОБРАЖДА",
        value: "ОБРАЖДА",
    },
    {
        label: "ОРАНЕ",
        value: "ОРАНЕ",
    },
    {
        label: "ПЛАВЦЕ",
        value: "ПЛАВЦЕ",
    },
    {
        label: "РЕЧИЦА",
        value: "РЕЧИЦА",
    },
    {
        label: "САВИНАЦ",
        value: "САВИНАЦ",
    },
    {
        label: "СЛАВНИК",
        value: "СЛАВНИК",
    },
    {
        label: "СТУБЛА",
        value: "СТУБЛА",
    },
    {
        label: "ТУРЈАНЕ",
        value: "ТУРЈАНЕ",
    },
    {
        label: "ЋУКОВАЦ",
        value: "ЋУКОВАЦ",
    },
    {
        label: "ЦРКВИЦЕ",
        value: "ЦРКВИЦЕ",
    },
    {
        label: "БОЉЕВАЦ",
        value: "БОЉЕВАЦ",
    },
    {
        label: "БАЧЕВИЦА",
        value: "БАЧЕВИЦА",
    },
    {
        label: "БОГОВИНА",
        value: "БОГОВИНА",
    },
    {
        label: "ВАЛАКОЊЕ",
        value: "ВАЛАКОЊЕ",
    },
    {
        label: "ДОБРО ПОЉЕ",
        value: "ДОБРО ПОЉЕ",
    },
    {
        label: "ИЛИНО",
        value: "ИЛИНО",
    },
    {
        label: "ЈАБЛАНИЦА",
        value: "ЈАБЛАНИЦА",
    },
    {
        label: "КРИВИ ВИР",
        value: "КРИВИ ВИР",
    },
    {
        label: "ЛУКОВО",
        value: "ЛУКОВО",
    },
    {
        label: "МАЛИ ИЗВОР",
        value: "МАЛИ ИЗВОР",
    },
    {
        label: "МИРОВО",
        value: "МИРОВО",
    },
    {
        label: "ОСНИЋ",
        value: "ОСНИЋ",
    },
    {
        label: "ПОДГОРАЦ I",
        value: "ПОДГОРАЦ I",
    },
    {
        label: "ПОДГОРАЦ II",
        value: "ПОДГОРАЦ II",
    },
    {
        label: "РУЈИШТЕ",
        value: "РУЈИШТЕ",
    },
    {
        label: "СУМРАКОВАЦ",
        value: "СУМРАКОВАЦ",
    },
    {
        label: "БОР",
        value: "БОР",
    },
    {
        label: "БОР I",
        value: "БОР I",
    },
    {
        label: "БОР II",
        value: "БОР II",
    },
    {
        label: "БУЧЈЕ",
        value: "БУЧЈЕ",
    },
    {
        label: "ГОРЊАНЕ",
        value: "ГОРЊАНЕ",
    },
    {
        label: "ДОЊА БЕЛА РЕКА",
        value: "ДОЊА БЕЛА РЕКА",
    },
    {
        label: "ЗЛОТ I",
        value: "ЗЛОТ I",
    },
    {
        label: "ЗЛОТ II",
        value: "ЗЛОТ II",
    },
    {
        label: "ЗЛОТ III",
        value: "ЗЛОТ III",
    },
    {
        label: "ЗЛОТ IV",
        value: "ЗЛОТ IV",
    },
    {
        label: "ЗЛОТ V",
        value: "ЗЛОТ V",
    },
    {
        label: "КРИВЕЉ",
        value: "КРИВЕЉ",
    },
    {
        label: "ЛУКА",
        value: "ЛУКА",
    },
    {
        label: "МЕТОВНИЦА",
        value: "МЕТОВНИЦА",
    },
    {
        label: "ОШТРЕЉ",
        value: "ОШТРЕЉ",
    },
    {
        label: "СЛАТИНА",
        value: "СЛАТИНА",
    },
    {
        label: "ТАНДА",
        value: "ТАНДА",
    },
    {
        label: "ТОПЛА",
        value: "ТОПЛА",
    },
    {
        label: "ШАРБАНОВАЦ",
        value: "ШАРБАНОВАЦ",
    },
    {
        label: "БОСИЛЕГРАД",
        value: "БОСИЛЕГРАД",
    },
    {
        label: "БАРЈЕ",
        value: "БАРЈЕ",
    },
    {
        label: "БЕЛУТ",
        value: "БЕЛУТ",
    },
    {
        label: "БИСТАР",
        value: "БИСТАР",
    },
    {
        label: "БОСИЛЕГРАД I",
        value: "БОСИЛЕГРАД I",
    },
    {
        label: "БОСИЛЕГРАД II",
        value: "БОСИЛЕГРАД II",
    },
    {
        label: "БРАНКОВЦИ",
        value: "БРАНКОВЦИ",
    },
    {
        label: "БРЕСНИЦА",
        value: "БРЕСНИЦА",
    },
    {
        label: "БУЦЕЉЕВО",
        value: "БУЦЕЉЕВО",
    },
    {
        label: "ГЛОЖЈЕ",
        value: "ГЛОЖЈЕ",
    },
    {
        label: "ГОЛЕШ",
        value: "ГОЛЕШ",
    },
    {
        label: "ГОРЊА ЛИСИНА",
        value: "ГОРЊА ЛИСИНА",
    },
    {
        label: "ГОРЊА ЉУБАТА",
        value: "ГОРЊА ЉУБАТА",
    },
    {
        label: "ГОРЊА РЖАНА",
        value: "ГОРЊА РЖАНА",
    },
    {
        label: "ГОРЊЕ ТЛАМИНО",
        value: "ГОРЊЕ ТЛАМИНО",
    },
    {
        label: "ГРУЈИНЦИ",
        value: "ГРУЈИНЦИ",
    },
    {
        label: "ДОГАНИЦА",
        value: "ДОГАНИЦА",
    },
    {
        label: "ДОЊА ЛИСИНА",
        value: "ДОЊА ЛИСИНА",
    },
    {
        label: "ДОЊА ЉУБАТА",
        value: "ДОЊА ЉУБАТА",
    },
    {
        label: "ДОЊА РЖАНА",
        value: "ДОЊА РЖАНА",
    },
    {
        label: "ДОЊЕ ТЛАМИНО",
        value: "ДОЊЕ ТЛАМИНО",
    },
    {
        label: "ДУКАТ",
        value: "ДУКАТ",
    },
    {
        label: "ЖЕРАВИНО",
        value: "ЖЕРАВИНО",
    },
    {
        label: "ЗЛИ ДОЛ",
        value: "ЗЛИ ДОЛ",
    },
    {
        label: "ЈАРЕШНИК",
        value: "ЈАРЕШНИК",
    },
    {
        label: "КАРАМАНИЦА",
        value: "КАРАМАНИЦА",
    },
    {
        label: "МИЛЕВЦИ",
        value: "МИЛЕВЦИ",
    },
    {
        label: "МЛЕКОМИНЦИ",
        value: "МЛЕКОМИНЦИ",
    },
    {
        label: "МУСУЉ",
        value: "МУСУЉ",
    },
    {
        label: "НАЗАРИЦА",
        value: "НАЗАРИЦА",
    },
    {
        label: "ПАРАЛОВО",
        value: "ПАРАЛОВО",
    },
    {
        label: "РАДИЧЕВЦИ",
        value: "РАДИЧЕВЦИ",
    },
    {
        label: "РАЈЧИЛОВЦИ",
        value: "РАЈЧИЛОВЦИ",
    },
    {
        label: "РЕСЕН",
        value: "РЕСЕН",
    },
    {
        label: "РИБАРЦИ",
        value: "РИБАРЦИ",
    },
    {
        label: "РИКАЧЕВО",
        value: "РИКАЧЕВО",
    },
    {
        label: "ЦРНОШТИЦА",
        value: "ЦРНОШТИЦА",
    },
    {
        label: "БРУС",
        value: "БРУС",
    },
    {
        label: "БАТОТЕ",
        value: "БАТОТЕ",
    },
    {
        label: "БЕЛО ПОЉЕ",
        value: "БЕЛО ПОЉЕ",
    },
    {
        label: "БЛАЖЕВО",
        value: "БЛАЖЕВО",
    },
    {
        label: "БОГИШЕ",
        value: "БОГИШЕ",
    },
    {
        label: "БОЗОЉИН",
        value: "БОЗОЉИН",
    },
    {
        label: "БОРАНЦИ",
        value: "БОРАНЦИ",
    },
    {
        label: "БОТУЊА",
        value: "БОТУЊА",
    },
    {
        label: "БРЂАНИ",
        value: "БРЂАНИ",
    },
    {
        label: "БРЗЕЋЕ",
        value: "БРЗЕЋЕ",
    },
    {
        label: "БУДИЛОВИНА",
        value: "БУДИЛОВИНА",
    },
    {
        label: "ВЕЛИКА ГРАБОВНИЦА",
        value: "ВЕЛИКА ГРАБОВНИЦА",
    },
    {
        label: "ВИТОШЕ",
        value: "ВИТОШЕ",
    },
    {
        label: "ВЛАЈКОВЦИ",
        value: "ВЛАЈКОВЦИ",
    },
    {
        label: "ГОРЊЕ ЛЕВИЋЕ",
        value: "ГОРЊЕ ЛЕВИЋЕ",
    },
    {
        label: "ГОРЊИ ЛИПОВАЦ",
        value: "ГОРЊИ ЛИПОВАЦ",
    },
    {
        label: "ГОЧМАНЦИ",
        value: "ГОЧМАНЦИ",
    },
    {
        label: "ГРАД",
        value: "ГРАД",
    },
    {
        label: "ГРАШЕВЦИ",
        value: "ГРАШЕВЦИ",
    },
    {
        label: "ДОМИШЕВИНА",
        value: "ДОМИШЕВИНА",
    },
    {
        label: "ДОЊЕ ЛЕВИЋЕ",
        value: "ДОЊЕ ЛЕВИЋЕ",
    },
    {
        label: "ДОЊИ ЛИПОВАЦ",
        value: "ДОЊИ ЛИПОВАЦ",
    },
    {
        label: "ДРЕНОВА",
        value: "ДРЕНОВА",
    },
    {
        label: "ДРТЕВЦИ",
        value: "ДРТЕВЦИ",
    },
    {
        label: "ДУПЦИ",
        value: "ДУПЦИ",
    },
    {
        label: "ЂЕРЕКАРЕ",
        value: "ЂЕРЕКАРЕ",
    },
    {
        label: "ЖАРЕВО",
        value: "ЖАРЕВО",
    },
    {
        label: "ЖИЛИНЦИ",
        value: "ЖИЛИНЦИ",
    },
    {
        label: "ЖИЉЦИ",
        value: "ЖИЉЦИ",
    },
    {
        label: "ЖУЊЕ",
        value: "ЖУЊЕ",
    },
    {
        label: "ЗЛАТАРИ",
        value: "ЗЛАТАРИ",
    },
    {
        label: "ИГРОШ",
        value: "ИГРОШ",
    },
    {
        label: "ИРИЧИЋИ",
        value: "ИРИЧИЋИ",
    },
    {
        label: "КНЕЖЕВО",
        value: "КНЕЖЕВО",
    },
    {
        label: "КОБИЉЕ",
        value: "КОБИЉЕ",
    },
    {
        label: "КОВИЗЛЕ",
        value: "КОВИЗЛЕ",
    },
    {
        label: "КОЧИНЕ",
        value: "КОЧИНЕ",
    },
    {
        label: "КРИВА РЕКА",
        value: "КРИВА РЕКА",
    },
    {
        label: "ЛЕПЕНАЦ",
        value: "ЛЕПЕНАЦ",
    },
    {
        label: "ЛИВАЂЕ",
        value: "ЛИВАЂЕ",
    },
    {
        label: "МАЛА ВРБНИЦА",
        value: "МАЛА ВРБНИЦА",
    },
    {
        label: "МАЛА ГРАБОВНИЦА",
        value: "МАЛА ГРАБОВНИЦА",
    },
    {
        label: "МАЧКОВАЦ",
        value: "МАЧКОВАЦ",
    },
    {
        label: "МИЛЕНТИЈА",
        value: "МИЛЕНТИЈА",
    },
    {
        label: "ОСРЕДЦИ",
        value: "ОСРЕДЦИ",
    },
    {
        label: "ПАЉЕВШТИЦА",
        value: "ПАЉЕВШТИЦА",
    },
    {
        label: "ПОКРП",
        value: "ПОКРП",
    },
    {
        label: "РАВНИ",
        value: "РАВНИ",
    },
    {
        label: "РАВНИШТЕ",
        value: "РАВНИШТЕ",
    },
    {
        label: "РАДМАНОВО",
        value: "РАДМАНОВО",
    },
    {
        label: "РАДУЊЕ",
        value: "РАДУЊЕ",
    },
    {
        label: "РАЗБОЈНА",
        value: "РАЗБОЈНА",
    },
    {
        label: "РИБАРИ",
        value: "РИБАРИ",
    },
    {
        label: "СТАНУЛОВИЋИ",
        value: "СТАНУЛОВИЋИ",
    },
    {
        label: "СТРОЈИНЦИ",
        value: "СТРОЈИНЦИ",
    },
    {
        label: "СУДИМЉА",
        value: "СУДИМЉА",
    },
    {
        label: "ТРШАНОВЦИ",
        value: "ТРШАНОВЦИ",
    },
    {
        label: "ЧОКОТАР",
        value: "ЧОКОТАР",
    },
    {
        label: "ШОШИЋЕ",
        value: "ШОШИЋЕ",
    },
    {
        label: "БУЈАНОВАЦ",
        value: "БУЈАНОВАЦ",
    },
    {
        label: "БАРАЉЕВАЦ",
        value: "БАРАЉЕВАЦ",
    },
    {
        label: "БИЉАЧА",
        value: "БИЉАЧА",
    },
    {
        label: "БОЖИЊЕВАЦ I",
        value: "БОЖИЊЕВАЦ I",
    },
    {
        label: "БОЖИЊЕВАЦ II",
        value: "БОЖИЊЕВАЦ II",
    },
    {
        label: "БОРОВАЦ",
        value: "БОРОВАЦ",
    },
    {
        label: "БРАТОСЕЛЦЕ",
        value: "БРАТОСЕЛЦЕ",
    },
    {
        label: "БРЕЗНИЦА",
        value: "БРЕЗНИЦА",
    },
    {
        label: "БРЊАРЕ",
        value: "БРЊАРЕ",
    },
    {
        label: "ВЕЛИКИ ТРНОВАЦ",
        value: "ВЕЛИКИ ТРНОВАЦ",
    },
    {
        label: "ВОГАНЦЕ",
        value: "ВОГАНЦЕ",
    },
    {
        label: "ВРБАН",
        value: "ВРБАН",
    },
    {
        label: "ГОРЊЕ НОВО СЕЛО",
        value: "ГОРЊЕ НОВО СЕЛО",
    },
    {
        label: "ГРАМАДА",
        value: "ГРАМАДА",
    },
    {
        label: "ДОБРОСИН",
        value: "ДОБРОСИН",
    },
    {
        label: "ДОЊЕ НОВО СЕЛО",
        value: "ДОЊЕ НОВО СЕЛО",
    },
    {
        label: "ДРЕЖНИЦА",
        value: "ДРЕЖНИЦА",
    },
    {
        label: "ЂОРЂЕВАЦ",
        value: "ЂОРЂЕВАЦ",
    },
    {
        label: "ЖБЕВАЦ",
        value: "ЖБЕВАЦ",
    },
    {
        label: "ЖУЖЕЉИЦА",
        value: "ЖУЖЕЉИЦА",
    },
    {
        label: "ЗАРБИНЦЕ",
        value: "ЗАРБИНЦЕ",
    },
    {
        label: "ЈАСТРЕБАЦ",
        value: "ЈАСТРЕБАЦ",
    },
    {
        label: "КАРАДНИК",
        value: "КАРАДНИК",
    },
    {
        label: "КЛЕНИКЕ",
        value: "КЛЕНИКЕ",
    },
    {
        label: "КЛИНОВАЦ",
        value: "КЛИНОВАЦ",
    },
    {
        label: "КОНЧУЉ",
        value: "КОНЧУЉ",
    },
    {
        label: "КОШАРНО",
        value: "КОШАРНО",
    },
    {
        label: "КРШЕВИЦА",
        value: "КРШЕВИЦА",
    },
    {
        label: "КУШТИЦА",
        value: "КУШТИЦА",
    },
    {
        label: "ЛЕВОСОЈЕ",
        value: "ЛЕВОСОЈЕ",
    },
    {
        label: "ЛЕТОВИЦА",
        value: "ЛЕТОВИЦА",
    },
    {
        label: "ЛОПАРДИНЦЕ",
        value: "ЛОПАРДИНЦЕ",
    },
    {
        label: "ЛУКАРЦЕ",
        value: "ЛУКАРЦЕ",
    },
    {
        label: "ЛУЧАНЕ",
        value: "ЛУЧАНЕ",
    },
    {
        label: "ЉИЉАНЦЕ",
        value: "ЉИЉАНЦЕ",
    },
    {
        label: "МАЛО БУШТРАЊЕ",
        value: "МАЛО БУШТРАЊЕ",
    },
    {
        label: "МУХОВАЦ",
        value: "МУХОВАЦ",
    },
    {
        label: "НЕГОВАЦ",
        value: "НЕГОВАЦ",
    },
    {
        label: "НЕСАЛЦЕ",
        value: "НЕСАЛЦЕ",
    },
    {
        label: "ОСЛАРЕ",
        value: "ОСЛАРЕ",
    },
    {
        label: "ПЕТКА",
        value: "ПЕТКА",
    },
    {
        label: "ПРЕТИНА",
        value: "ПРЕТИНА",
    },
    {
        label: "ПРИБОВЦЕ",
        value: "ПРИБОВЦЕ",
    },
    {
        label: "РАВНО БУЧЈЕ",
        value: "РАВНО БУЧЈЕ",
    },
    {
        label: "РУСЦЕ",
        value: "РУСЦЕ",
    },
    {
        label: "САМОЉИЦА",
        value: "САМОЉИЦА",
    },
    {
        label: "СЕБРАТ",
        value: "СЕБРАТ",
    },
    {
        label: "СЕЈАЦЕ",
        value: "СЕЈАЦЕ",
    },
    {
        label: "СПАНЧЕВАЦ",
        value: "СПАНЧЕВАЦ",
    },
    {
        label: "СРПСКА КУЋА",
        value: "СРПСКА КУЋА",
    },
    {
        label: "СТАРАЦ",
        value: "СТАРАЦ",
    },
    {
        label: "СУХАРНО",
        value: "СУХАРНО",
    },
    {
        label: "ТРЕЈАК",
        value: "ТРЕЈАК",
    },
    {
        label: "ТУРИЈА",
        value: "ТУРИЈА",
    },
    {
        label: "УЗОВО",
        value: "УЗОВО",
    },
    {
        label: "ЧАР",
        value: "ЧАР",
    },
    {
        label: "ВАЉЕВО",
        value: "ВАЉЕВО",
    },
    {
        label: "БАБИНА ЛУКА",
        value: "БАБИНА ЛУКА",
    },
    {
        label: "БАЛИНОВИЋ",
        value: "БАЛИНОВИЋ",
    },
    {
        label: "БАЧЕВЦИ",
        value: "БАЧЕВЦИ",
    },
    {
        label: "БЕЛИЋ",
        value: "БЕЛИЋ",
    },
    {
        label: "БЕЛОШЕВАЦ",
        value: "БЕЛОШЕВАЦ",
    },
    {
        label: "БЕОМУЖЕВИЋ",
        value: "БЕОМУЖЕВИЋ",
    },
    {
        label: "БЛИЗОЊЕ",
        value: "БЛИЗОЊЕ",
    },
    {
        label: "БОБОВА",
        value: "БОБОВА",
    },
    {
        label: "БРАНГОВИЋ",
        value: "БРАНГОВИЋ",
    },
    {
        label: "БРАНКОВИНА",
        value: "БРАНКОВИНА",
    },
    {
        label: "БРЕЗОВИЦЕ",
        value: "БРЕЗОВИЦЕ",
    },
    {
        label: "БУЈАЧИЋ",
        value: "БУЈАЧИЋ",
    },
    {
        label: "ВЕСЕЛИНОВАЦ",
        value: "ВЕСЕЛИНОВАЦ",
    },
    {
        label: "ВЛАШЧИЋ",
        value: "ВЛАШЧИЋ",
    },
    {
        label: "ВРАГОЧАНИЦА",
        value: "ВРАГОЧАНИЦА",
    },
    {
        label: "ВУЈИНОВАЧА",
        value: "ВУЈИНОВАЧА",
    },
    {
        label: "ГОЛА ГЛАВА",
        value: "ГОЛА ГЛАВА",
    },
    {
        label: "ГОРЊА БУКОВИЦА",
        value: "ГОРЊА БУКОВИЦА",
    },
    {
        label: "ГОРЊЕ ЛЕСКОВИЦЕ",
        value: "ГОРЊЕ ЛЕСКОВИЦЕ",
    },
    {
        label: "ГРАБОВИЦА",
        value: "ГРАБОВИЦА",
    },
    {
        label: "ДЕГУРИЋ",
        value: "ДЕГУРИЋ",
    },
    {
        label: "ДИВЦИ",
        value: "ДИВЦИ",
    },
    {
        label: "ДИВЧИБАРЕ",
        value: "ДИВЧИБАРЕ",
    },
    {
        label: "ДОЊА БУКОВИЦА",
        value: "ДОЊА БУКОВИЦА",
    },
    {
        label: "ДОЊЕ ЛЕСКОВИЦЕ",
        value: "ДОЊЕ ЛЕСКОВИЦЕ",
    },
    {
        label: "ДРАЧИЋ",
        value: "ДРАЧИЋ",
    },
    {
        label: "ДУПЉАЈ",
        value: "ДУПЉАЈ",
    },
    {
        label: "ЖАБАРИ",
        value: "ЖАБАРИ",
    },
    {
        label: "ЗАБРДИЦА",
        value: "ЗАБРДИЦА",
    },
    {
        label: "ЗАРУБЕ",
        value: "ЗАРУБЕ",
    },
    {
        label: "ЗЛАТАРИЋ",
        value: "ЗЛАТАРИЋ",
    },
    {
        label: "ЈАСЕНИЦА",
        value: "ЈАСЕНИЦА",
    },
    {
        label: "ЈОШЕВА",
        value: "ЈОШЕВА",
    },
    {
        label: "КЛАНИЦА",
        value: "КЛАНИЦА",
    },
    {
        label: "КЛИНЦИ",
        value: "КЛИНЦИ",
    },
    {
        label: "КОВАЧИЦЕ",
        value: "КОВАЧИЦЕ",
    },
    {
        label: "КОЗЛИЧИЋ",
        value: "КОЗЛИЧИЋ",
    },
    {
        label: "КОТЕШИЦА",
        value: "КОТЕШИЦА",
    },
    {
        label: "КУНИЦЕ",
        value: "КУНИЦЕ",
    },
    {
        label: "ЛЕЛИЋ",
        value: "ЛЕЛИЋ",
    },
    {
        label: "ЛОЗНИЦА",
        value: "ЛОЗНИЦА",
    },
    {
        label: "ЛУКАВАЦ",
        value: "ЛУКАВАЦ",
    },
    {
        label: "МАЈИНОВИЋ",
        value: "МАЈИНОВИЋ",
    },
    {
        label: "МИЛИЧИНИЦА",
        value: "МИЛИЧИНИЦА",
    },
    {
        label: "МРЧИЋ",
        value: "МРЧИЋ",
    },
    {
        label: "ОГЛАЂЕНОВАЦ",
        value: "ОГЛАЂЕНОВАЦ",
    },
    {
        label: "ОСЛАДИЋ",
        value: "ОСЛАДИЋ",
    },
    {
        label: "ПАКЉЕ",
        value: "ПАКЉЕ",
    },
    {
        label: "ПАУНЕ",
        value: "ПАУНЕ",
    },
    {
        label: "ПЕТНИЦА",
        value: "ПЕТНИЦА",
    },
    {
        label: "ПОПУЧКЕ",
        value: "ПОПУЧКЕ",
    },
    {
        label: "ПРИЈЕЗДИЋ",
        value: "ПРИЈЕЗДИЋ",
    },
    {
        label: "ПРИЧЕВИЋ",
        value: "ПРИЧЕВИЋ",
    },
    {
        label: "РАБАС",
        value: "РАБАС",
    },
    {
        label: "РАВЊЕ",
        value: "РАВЊЕ",
    },
    {
        label: "РАЂЕВО СЕЛО",
        value: "РАЂЕВО СЕЛО",
    },
    {
        label: "РЕБЕЉ",
        value: "РЕБЕЉ",
    },
    {
        label: "РОВНИ",
        value: "РОВНИ",
    },
    {
        label: "САНДАЉ",
        value: "САНДАЉ",
    },
    {
        label: "СЕДЛАРИ",
        value: "СЕДЛАРИ",
    },
    {
        label: "СИТАРИЦЕ",
        value: "СИТАРИЦЕ",
    },
    {
        label: "СОВАЧ",
        value: "СОВАЧ",
    },
    {
        label: "СТАНИНА РЕКА",
        value: "СТАНИНА РЕКА",
    },
    {
        label: "СТАПАР",
        value: "СТАПАР",
    },
    {
        label: "СТРМНА ГОРА",
        value: "СТРМНА ГОРА",
    },
    {
        label: "СТУБО",
        value: "СТУБО",
    },
    {
        label: "СУВОДАЊЕ",
        value: "СУВОДАЊЕ",
    },
    {
        label: "СУШИЦА",
        value: "СУШИЦА",
    },
    {
        label: "ТАОР",
        value: "ТАОР",
    },
    {
        label: "ТУБРАВИЋ",
        value: "ТУБРАВИЋ",
    },
    {
        label: "ТУПАНЦИ",
        value: "ТУПАНЦИ",
    },
    {
        label: "ВАРВАРИН",
        value: "ВАРВАРИН",
    },
    {
        label: "БАЧИНА",
        value: "БАЧИНА",
    },
    {
        label: "БОШЊАНЕ",
        value: "БОШЊАНЕ",
    },
    {
        label: "ВАРВАРИН (ВАРОШ)",
        value: "ВАРВАРИН (ВАРОШ)",
    },
    {
        label: "ВАРВАРИН (СЕЛО)",
        value: "ВАРВАРИН (СЕЛО)",
    },
    {
        label: "ГОРЊИ КАТУН",
        value: "ГОРЊИ КАТУН",
    },
    {
        label: "ГОРЊИ КРЧИН",
        value: "ГОРЊИ КРЧИН",
    },
    {
        label: "ДОЊИ КАТУН",
        value: "ДОЊИ КАТУН",
    },
    {
        label: "ДОЊИ КРЧИН",
        value: "ДОЊИ КРЧИН",
    },
    {
        label: "ЗАЛОГОВАЦ",
        value: "ЗАЛОГОВАЦ",
    },
    {
        label: "ИЗБЕНИЦА",
        value: "ИЗБЕНИЦА",
    },
    {
        label: "КАРАНОВАЦ",
        value: "КАРАНОВАЦ",
    },
    {
        label: "МАЛА КРУШЕВИЦА",
        value: "МАЛА КРУШЕВИЦА",
    },
    {
        label: "МАРЕНОВО",
        value: "МАРЕНОВО",
    },
    {
        label: "МАСКАРЕ",
        value: "МАСКАРЕ",
    },
    {
        label: "ОБРЕЖ",
        value: "ОБРЕЖ",
    },
    {
        label: "ОРАШЈЕ",
        value: "ОРАШЈЕ",
    },
    {
        label: "ПАЈКОВАЦ",
        value: "ПАЈКОВАЦ",
    },
    {
        label: "ПАРЦАНЕ",
        value: "ПАРЦАНЕ",
    },
    {
        label: "ТОЉЕВАЦ",
        value: "ТОЉЕВАЦ",
    },
    {
        label: "ЦЕРНИЦА",
        value: "ЦЕРНИЦА",
    },
    {
        label: "ВЕЛИКА ПЛАНА",
        value: "ВЕЛИКА ПЛАНА",
    },
    {
        label: "ВЕЛИКА ПЛАНА I",
        value: "ВЕЛИКА ПЛАНА I",
    },
    {
        label: "ВЕЛИКА ПЛАНА II",
        value: "ВЕЛИКА ПЛАНА II",
    },
    {
        label: "ВЕЛИКО ОРАШЈЕ",
        value: "ВЕЛИКО ОРАШЈЕ",
    },
    {
        label: "ДОЊА ЛИВАДИЦА",
        value: "ДОЊА ЛИВАДИЦА",
    },
    {
        label: "КРЊЕВО",
        value: "КРЊЕВО",
    },
    {
        label: "ЛОЗОВИК",
        value: "ЛОЗОВИК",
    },
    {
        label: "МАРКОВАЦ",
        value: "МАРКОВАЦ",
    },
    {
        label: "МИЛОШЕВАЦ",
        value: "МИЛОШЕВАЦ",
    },
    {
        label: "НОВО СЕЛО I",
        value: "НОВО СЕЛО I",
    },
    {
        label: "НОВО СЕЛО II",
        value: "НОВО СЕЛО II",
    },
    {
        label: "РАДОВАЊЕ",
        value: "РАДОВАЊЕ",
    },
    {
        label: "РАКИНАЦ",
        value: "РАКИНАЦ",
    },
    {
        label: "СТАРО СЕЛО",
        value: "СТАРО СЕЛО",
    },
    {
        label: "ТРНОВЧЕ",
        value: "ТРНОВЧЕ",
    },
    {
        label: "ВЕЛИКО ГРАДИШТЕ",
        value: "ВЕЛИКО ГРАДИШТЕ",
    },
    {
        label: "БИСКУПЉЕ",
        value: "БИСКУПЉЕ",
    },
    {
        label: "ГАРЕВО",
        value: "ГАРЕВО",
    },
    {
        label: "ДЕСИНА",
        value: "ДЕСИНА",
    },
    {
        label: "ДОЉАШНИЦА",
        value: "ДОЉАШНИЦА",
    },
    {
        label: "ЂУРАКОВО",
        value: "ЂУРАКОВО",
    },
    {
        label: "ЗАТОЊЕ",
        value: "ЗАТОЊЕ",
    },
    {
        label: "КАМИЈЕВО",
        value: "КАМИЈЕВО",
    },
    {
        label: "КИСИЉЕВО",
        value: "КИСИЉЕВО",
    },
    {
        label: "КУМАНЕ",
        value: "КУМАНЕ",
    },
    {
        label: "КУРЈАЧЕ",
        value: "КУРЈАЧЕ",
    },
    {
        label: "КУСИЋЕ",
        value: "КУСИЋЕ",
    },
    {
        label: "ЉУБИЊЕ",
        value: "ЉУБИЊЕ",
    },
    {
        label: "МАЈИЛОВАЦ",
        value: "МАЈИЛОВАЦ",
    },
    {
        label: "МАКЦЕ",
        value: "МАКЦЕ",
    },
    {
        label: "ОСТРОВО",
        value: "ОСТРОВО",
    },
    {
        label: "ПЕЧАНИЦА",
        value: "ПЕЧАНИЦА",
    },
    {
        label: "ПОЖЕЖЕНО",
        value: "ПОЖЕЖЕНО",
    },
    {
        label: "ПОПОВАЦ",
        value: "ПОПОВАЦ",
    },
    {
        label: "РАМ",
        value: "РАМ",
    },
    {
        label: "СИРАКОВО",
        value: "СИРАКОВО",
    },
    {
        label: "СРЕДЊЕВО",
        value: "СРЕДЊЕВО",
    },
    {
        label: "ТОПОЛОВНИК",
        value: "ТОПОЛОВНИК",
    },
    {
        label: "ТРИБРОДЕ",
        value: "ТРИБРОДЕ",
    },
    {
        label: "ЦАРЕВАЦ",
        value: "ЦАРЕВАЦ",
    },
    {
        label: "ЧЕШЉЕВА БАРА",
        value: "ЧЕШЉЕВА БАРА",
    },
    {
        label: "ВЛАДИМИРЦИ",
        value: "ВЛАДИМИРЦИ",
    },
    {
        label: "БЕЉИН",
        value: "БЕЉИН",
    },
    {
        label: "БОБОВИК",
        value: "БОБОВИК",
    },
    {
        label: "ВЛАДИМИРЦИ СЕЛО",
        value: "ВЛАДИМИРЦИ СЕЛО",
    },
    {
        label: "ВЛАСАНИЦА",
        value: "ВЛАСАНИЦА",
    },
    {
        label: "ВУКОШИЋ",
        value: "ВУКОШИЋ",
    },
    {
        label: "ВУЧЕВИЦА",
        value: "ВУЧЕВИЦА",
    },
    {
        label: "ДЕБРЦ",
        value: "ДЕБРЦ",
    },
    {
        label: "ЗВЕЗД",
        value: "ЗВЕЗД",
    },
    {
        label: "ЈАЗОВНИК",
        value: "ЈАЗОВНИК",
    },
    {
        label: "ЈАЛОВИК",
        value: "ЈАЛОВИК",
    },
    {
        label: "КАОНА",
        value: "КАОНА",
    },
    {
        label: "КОЗАРИЦА",
        value: "КОЗАРИЦА",
    },
    {
        label: "КРНИЋ",
        value: "КРНИЋ",
    },
    {
        label: "КРНУЛЕ",
        value: "КРНУЛЕ",
    },
    {
        label: "КУЈАВИЦА",
        value: "КУЈАВИЦА",
    },
    {
        label: "ЛОЈАНИЦЕ",
        value: "ЛОЈАНИЦЕ",
    },
    {
        label: "МАТИЈЕВАЦ",
        value: "МАТИЈЕВАЦ",
    },
    {
        label: "МЕСАРЦИ",
        value: "МЕСАРЦИ",
    },
    {
        label: "МЕХОВИНЕ",
        value: "МЕХОВИНЕ",
    },
    {
        label: "МРОВСКА",
        value: "МРОВСКА",
    },
    {
        label: "ПЕЈИНОВИЋ",
        value: "ПЕЈИНОВИЋ",
    },
    {
        label: "ПРОВО",
        value: "ПРОВО",
    },
    {
        label: "РИЂАКЕ",
        value: "РИЂАКЕ",
    },
    {
        label: "СКУПЉЕН",
        value: "СКУПЉЕН",
    },
    {
        label: "СУВО СЕЛО",
        value: "СУВО СЕЛО",
    },
    {
        label: "ТРБУШАЦ",
        value: "ТРБУШАЦ",
    },
    {
        label: "ВЛАДИЧИН ХАН",
        value: "ВЛАДИЧИН ХАН",
    },
    {
        label: "БАЛИНОВЦЕ",
        value: "БАЛИНОВЦЕ",
    },
    {
        label: "БАЧВИШТЕ",
        value: "БАЧВИШТЕ",
    },
    {
        label: "БЕЛАНОВЦЕ",
        value: "БЕЛАНОВЦЕ",
    },
    {
        label: "БЕЛИШЕВО",
        value: "БЕЛИШЕВО",
    },
    {
        label: "БОГОШЕВО",
        value: "БОГОШЕВО",
    },
    {
        label: "БРЕСТОВО",
        value: "БРЕСТОВО",
    },
    {
        label: "ВРБОВО",
        value: "ВРБОВО",
    },
    {
        label: "ГАРИЊЕ",
        value: "ГАРИЊЕ",
    },
    {
        label: "ГОРЊЕ ЈАБУКОВО",
        value: "ГОРЊЕ ЈАБУКОВО",
    },
    {
        label: "ГРАМАЂЕ",
        value: "ГРАМАЂЕ",
    },
    {
        label: "ДЕКУТИНЦЕ",
        value: "ДЕКУТИНЦЕ",
    },
    {
        label: "ДОЊА КОЗНИЦА",
        value: "ДОЊА КОЗНИЦА",
    },
    {
        label: "ДОЊЕ ЈАБУКОВО",
        value: "ДОЊЕ ЈАБУКОВО",
    },
    {
        label: "ДУПЉАНЕ",
        value: "ДУПЉАНЕ",
    },
    {
        label: "ЖИТОРАЂЕ",
        value: "ЖИТОРАЂЕ",
    },
    {
        label: "ЗЕБИНЦЕ",
        value: "ЗЕБИНЦЕ",
    },
    {
        label: "ЈАГЊИЛО",
        value: "ЈАГЊИЛО",
    },
    {
        label: "ЈОВАЦ",
        value: "ЈОВАЦ",
    },
    {
        label: "КАЛИМАНЦЕ",
        value: "КАЛИМАНЦЕ",
    },
    {
        label: "КАЦАПУН",
        value: "КАЦАПУН",
    },
    {
        label: "КОПИТАРЦЕ",
        value: "КОПИТАРЦЕ",
    },
    {
        label: "КОСТОМЛАТИЦА",
        value: "КОСТОМЛАТИЦА",
    },
    {
        label: "КРЖИНЦЕ",
        value: "КРЖИНЦЕ",
    },
    {
        label: "КУКАВИЦА",
        value: "КУКАВИЦА",
    },
    {
        label: "КУНОВО",
        value: "КУНОВО",
    },
    {
        label: "ЛЕБЕТ",
        value: "ЛЕБЕТ",
    },
    {
        label: "ЛЕПЕНИЦА",
        value: "ЛЕПЕНИЦА",
    },
    {
        label: "ЛЕТОВИШТЕ",
        value: "ЛЕТОВИШТЕ",
    },
    {
        label: "ЉУТЕЖ",
        value: "ЉУТЕЖ",
    },
    {
        label: "МАЗАРАЋ",
        value: "МАЗАРАЋ",
    },
    {
        label: "МАНАЈЛЕ",
        value: "МАНАЈЛЕ",
    },
    {
        label: "МАЊАК",
        value: "МАЊАК",
    },
    {
        label: "МРТВИЦА",
        value: "МРТВИЦА",
    },
    {
        label: "ОСТРОВИЦА",
        value: "ОСТРОВИЦА",
    },
    {
        label: "ПОЛОМ",
        value: "ПОЛОМ",
    },
    {
        label: "ПРЕКОДОЛЦЕ",
        value: "ПРЕКОДОЛЦЕ",
    },
    {
        label: "ПРИБОЈ",
        value: "ПРИБОЈ",
    },
    {
        label: "РАВНА РЕКА",
        value: "РАВНА РЕКА",
    },
    {
        label: "РДОВО",
        value: "РДОВО",
    },
    {
        label: "РЕПИНЦЕ",
        value: "РЕПИНЦЕ",
    },
    {
        label: "РЕПИШТЕ",
        value: "РЕПИШТЕ",
    },
    {
        label: "РУЖИЋ",
        value: "РУЖИЋ",
    },
    {
        label: "СОЛАЧКА СЕНА",
        value: "СОЛАЧКА СЕНА",
    },
    {
        label: "СРНЕЋИ ДОЛ",
        value: "СРНЕЋИ ДОЛ",
    },
    {
        label: "СУВА МОРАВА",
        value: "СУВА МОРАВА",
    },
    {
        label: "ТЕГОВИШТЕ",
        value: "ТЕГОВИШТЕ",
    },
    {
        label: "УРВИЧ",
        value: "УРВИЧ",
    },
    {
        label: "ЏЕП",
        value: "ЏЕП",
    },
    {
        label: "ВЛАСОТИНЦЕ",
        value: "ВЛАСОТИНЦЕ",
    },
    {
        label: "АЛЕКСИНЕ",
        value: "АЛЕКСИНЕ",
    },
    {
        label: "БАТУЛОВЦЕ",
        value: "БАТУЛОВЦЕ",
    },
    {
        label: "БОЉАРЕ",
        value: "БОЉАРЕ",
    },
    {
        label: "БОРИН ДО",
        value: "БОРИН ДО",
    },
    {
        label: "БРЕЗОВИЦА",
        value: "БРЕЗОВИЦА",
    },
    {
        label: "ВЛАСОТИНЦЕ-ВАН ВАРОШ",
        value: "ВЛАСОТИНЦЕ-ВАН ВАРОШ",
    },
    {
        label: "ВЛАСОТИНЦЕ-ВАРОШ",
        value: "ВЛАСОТИНЦЕ-ВАРОШ",
    },
    {
        label: "ГЛОЖАНЕ",
        value: "ГЛОЖАНЕ",
    },
    {
        label: "ГОРЊА ЛОМНИЦА",
        value: "ГОРЊА ЛОМНИЦА",
    },
    {
        label: "ГОРЊА ЛОПУШЊА",
        value: "ГОРЊА ЛОПУШЊА",
    },
    {
        label: "ГОРЊИ ОРАХ",
        value: "ГОРЊИ ОРАХ",
    },
    {
        label: "ГОРЊИ ПРИСЈАН",
        value: "ГОРЊИ ПРИСЈАН",
    },
    {
        label: "ГРАДИШТЕ",
        value: "ГРАДИШТЕ",
    },
    {
        label: "ГУЊЕТИНА",
        value: "ГУЊЕТИНА",
    },
    {
        label: "ДАДИНЦЕ",
        value: "ДАДИНЦЕ",
    },
    {
        label: "ДЕЈАН",
        value: "ДЕЈАН",
    },
    {
        label: "ДОБРОВИШ",
        value: "ДОБРОВИШ",
    },
    {
        label: "ДОЊА ЛОМНИЦА",
        value: "ДОЊА ЛОМНИЦА",
    },
    {
        label: "ДОЊА ЛОПУШЊА",
        value: "ДОЊА ЛОПУШЊА",
    },
    {
        label: "ДОЊЕ ГАРЕ",
        value: "ДОЊЕ ГАРЕ",
    },
    {
        label: "ДОЊИ ПРИСЈАН",
        value: "ДОЊИ ПРИСЈАН",
    },
    {
        label: "ЈАВОРЈЕ",
        value: "ЈАВОРЈЕ",
    },
    {
        label: "ЈАКОВЉЕВО",
        value: "ЈАКОВЉЕВО",
    },
    {
        label: "КОЗИЛО",
        value: "КОЗИЛО",
    },
    {
        label: "КОМАРИЦА",
        value: "КОМАРИЦА",
    },
    {
        label: "КОНОПНИЦА",
        value: "КОНОПНИЦА",
    },
    {
        label: "КРУШЕВИЦА",
        value: "КРУШЕВИЦА",
    },
    {
        label: "ЛАДОВИЦА",
        value: "ЛАДОВИЦА",
    },
    {
        label: "ЛИПОВИЦА",
        value: "ЛИПОВИЦА",
    },
    {
        label: "ОСТРЦ",
        value: "ОСТРЦ",
    },
    {
        label: "ПРЖОЈНЕ",
        value: "ПРЖОЈНЕ",
    },
    {
        label: "ПРИЛЕПАЦ",
        value: "ПРИЛЕПАЦ",
    },
    {
        label: "РАВНА ГОРА",
        value: "РАВНА ГОРА",
    },
    {
        label: "РАВНИ ДЕЛ",
        value: "РАВНИ ДЕЛ",
    },
    {
        label: "САМАРНИЦА",
        value: "САМАРНИЦА",
    },
    {
        label: "СВОЂЕ",
        value: "СВОЂЕ",
    },
    {
        label: "СКРАПЕЖ",
        value: "СКРАПЕЖ",
    },
    {
        label: "СРЕДОР",
        value: "СРЕДОР",
    },
    {
        label: "СТАЈКОВЦЕ",
        value: "СТАЈКОВЦЕ",
    },
    {
        label: "СТРАЊЕВО",
        value: "СТРАЊЕВО",
    },
    {
        label: "СТРЕШКОВАЦ",
        value: "СТРЕШКОВАЦ",
    },
    {
        label: "ЋЕЛИШТЕ",
        value: "ЋЕЛИШТЕ",
    },
    {
        label: "ЦРНАТОВО",
        value: "ЦРНАТОВО",
    },
    {
        label: "ШИШАВЕ",
        value: "ШИШАВЕ",
    },
    {
        label: "ВОЖДОВАЦ",
        value: "ВОЖДОВАЦ",
    },
    {
        label: "БЕЛИ ПОТОК",
        value: "БЕЛИ ПОТОК",
    },
    {
        label: "ЗУЦЕ",
        value: "ЗУЦЕ",
    },
    {
        label: "ЈАЈИНЦИ",
        value: "ЈАЈИНЦИ",
    },
    {
        label: "КУМОДРАЖ",
        value: "КУМОДРАЖ",
    },
    {
        label: "ПИНОСАВА",
        value: "ПИНОСАВА",
    },
    {
        label: "РАКОВИЦА СЕЛО",
        value: "РАКОВИЦА СЕЛО",
    },
    {
        label: "РИПАЊ",
        value: "РИПАЊ",
    },
    {
        label: "ВРАЊЕ",
        value: "ВРАЊЕ",
    },
    {
        label: "БАРБАРУШИНЦЕ",
        value: "БАРБАРУШИНЦЕ",
    },
    {
        label: "БАРЕЛИЋ",
        value: "БАРЕЛИЋ",
    },
    {
        label: "БОЈИН ДЕЛ",
        value: "БОЈИН ДЕЛ",
    },
    {
        label: "БУЉЕСОВЦЕ",
        value: "БУЉЕСОВЦЕ",
    },
    {
        label: "БУНУШЕВЦЕ",
        value: "БУНУШЕВЦЕ",
    },
    {
        label: "БУШТРАЊЕ",
        value: "БУШТРАЊЕ",
    },
    {
        label: "ВИШЕВЦЕ",
        value: "ВИШЕВЦЕ",
    },
    {
        label: "ВЛАСЕ",
        value: "ВЛАСЕ",
    },
    {
        label: "ВРАЊЕ I",
        value: "ВРАЊЕ I",
    },
    {
        label: "ВРАЊЕ II",
        value: "ВРАЊЕ II",
    },
    {
        label: "ГОЛЕМО СЕЛО",
        value: "ГОЛЕМО СЕЛО",
    },
    {
        label: "ГОРЊА ОТУЉА",
        value: "ГОРЊА ОТУЉА",
    },
    {
        label: "ГОРЊЕ ЖАПСКО",
        value: "ГОРЊЕ ЖАПСКО",
    },
    {
        label: "ГОРЊЕ ПУНУШЕВЦЕ",
        value: "ГОРЊЕ ПУНУШЕВЦЕ",
    },
    {
        label: "ГОРЊЕ ТРЕБЕШИЊЕ",
        value: "ГОРЊЕ ТРЕБЕШИЊЕ",
    },
    {
        label: "ГОРЊИ ВРТОГОШ",
        value: "ГОРЊИ ВРТОГОШ",
    },
    {
        label: "ГОРЊИ НЕРАДОВАЦ",
        value: "ГОРЊИ НЕРАДОВАЦ",
    },
    {
        label: "ГРАДЊА",
        value: "ГРАДЊА",
    },
    {
        label: "ГУМЕРИШТЕ",
        value: "ГУМЕРИШТЕ",
    },
    {
        label: "ДАВИДОВАЦ",
        value: "ДАВИДОВАЦ",
    },
    {
        label: "ДОБРЕЈАНЦЕ",
        value: "ДОБРЕЈАНЦЕ",
    },
    {
        label: "ДОЊА ОТУЉА",
        value: "ДОЊА ОТУЉА",
    },
    {
        label: "ДОЊЕ ЖАПСКО",
        value: "ДОЊЕ ЖАПСКО",
    },
    {
        label: "ДОЊЕ ПУНУШЕВЦЕ",
        value: "ДОЊЕ ПУНУШЕВЦЕ",
    },
    {
        label: "ДОЊЕ ТРЕБЕШИЊЕ",
        value: "ДОЊЕ ТРЕБЕШИЊЕ",
    },
    {
        label: "ДОЊИ ВРТОГОШ",
        value: "ДОЊИ ВРТОГОШ",
    },
    {
        label: "ДОЊИ НЕРАДОВАЦ",
        value: "ДОЊИ НЕРАДОВАЦ",
    },
    {
        label: "ДРЕНОВАЦ",
        value: "ДРЕНОВАЦ",
    },
    {
        label: "ДУБНИЦА",
        value: "ДУБНИЦА",
    },
    {
        label: "ДУЛАН",
        value: "ДУЛАН",
    },
    {
        label: "ДУПЕЉЕВО",
        value: "ДУПЕЉЕВО",
    },
    {
        label: "ЗЛАТОКОП",
        value: "ЗЛАТОКОП",
    },
    {
        label: "КЛАШЊИЦЕ",
        value: "КЛАШЊИЦЕ",
    },
    {
        label: "КОПАЊАНЕ",
        value: "КОПАЊАНЕ",
    },
    {
        label: "КОЋУРА",
        value: "КОЋУРА",
    },
    {
        label: "КРУШЕВА ГЛАВА",
        value: "КРУШЕВА ГЛАВА",
    },
    {
        label: "КУПИНИНЦЕ",
        value: "КУПИНИНЦЕ",
    },
    {
        label: "ЛАЛИНЦЕ",
        value: "ЛАЛИНЦЕ",
    },
    {
        label: "ЛЕПЧИНЦЕ",
        value: "ЛЕПЧИНЦЕ",
    },
    {
        label: "МЕЧКОВАЦ",
        value: "МЕЧКОВАЦ",
    },
    {
        label: "МИЈАКОВЦЕ",
        value: "МИЈАКОВЦЕ",
    },
    {
        label: "МИЈОВЦЕ",
        value: "МИЈОВЦЕ",
    },
    {
        label: "МИЛАНОВО",
        value: "МИЛАНОВО",
    },
    {
        label: "МИЛИВОЈЦЕ",
        value: "МИЛИВОЈЦЕ",
    },
    {
        label: "МОШТАНИЦА",
        value: "МОШТАНИЦА",
    },
    {
        label: "НАСТАВЦЕ",
        value: "НАСТАВЦЕ",
    },
    {
        label: "НОВА БРЕЗОВИЦА",
        value: "НОВА БРЕЗОВИЦА",
    },
    {
        label: "ОБЛИЧКА СЕНА",
        value: "ОБЛИЧКА СЕНА",
    },
    {
        label: "ОСТРА ГЛАВА",
        value: "ОСТРА ГЛАВА",
    },
    {
        label: "ПАВЛОВАЦ",
        value: "ПАВЛОВАЦ",
    },
    {
        label: "ПРЕОБРАЖЕЊЕ",
        value: "ПРЕОБРАЖЕЊЕ",
    },
    {
        label: "РАНУТОВАЦ",
        value: "РАНУТОВАЦ",
    },
    {
        label: "РАТАЈЕ I",
        value: "РАТАЈЕ I",
    },
    {
        label: "РАТАЈЕ II",
        value: "РАТАЈЕ II",
    },
    {
        label: "РИБИНЦЕ",
        value: "РИБИНЦЕ",
    },
    {
        label: "РИСТОВАЦ",
        value: "РИСТОВАЦ",
    },
    {
        label: "РОЖДАЦЕ",
        value: "РОЖДАЦЕ",
    },
    {
        label: "СИКИРЈЕ",
        value: "СИКИРЈЕ",
    },
    {
        label: "СМИЉЕВИЋ",
        value: "СМИЉЕВИЋ",
    },
    {
        label: "СОДЕРЦЕ",
        value: "СОДЕРЦЕ",
    },
    {
        label: "СРЕДЊИ ДЕЛ",
        value: "СРЕДЊИ ДЕЛ",
    },
    {
        label: "СРПСКО МАРГАНЦЕ",
        value: "СРПСКО МАРГАНЦЕ",
    },
    {
        label: "СТАНЦЕ",
        value: "СТАНЦЕ",
    },
    {
        label: "СТАРА БРЕЗОВИЦА",
        value: "СТАРА БРЕЗОВИЦА",
    },
    {
        label: "СТРЕШАК",
        value: "СТРЕШАК",
    },
    {
        label: "СТРОПСКО",
        value: "СТРОПСКО",
    },
    {
        label: "СТРУГАНИЦА",
        value: "СТРУГАНИЦА",
    },
    {
        label: "СУВИ ДОЛ",
        value: "СУВИ ДОЛ",
    },
    {
        label: "СУРДУЛ",
        value: "СУРДУЛ",
    },
    {
        label: "ТЕСОВИШТЕ",
        value: "ТЕСОВИШТЕ",
    },
    {
        label: "ТИБУЖДЕ",
        value: "ТИБУЖДЕ",
    },
    {
        label: "ТРСТЕНА",
        value: "ТРСТЕНА",
    },
    {
        label: "ТУМБА",
        value: "ТУМБА",
    },
    {
        label: "ЋУРКОВИЦА",
        value: "ЋУРКОВИЦА",
    },
    {
        label: "УРМАНИЦА",
        value: "УРМАНИЦА",
    },
    {
        label: "УШЕВЦЕ",
        value: "УШЕВЦЕ",
    },
    {
        label: "ЦРНИ ЛУГ",
        value: "ЦРНИ ЛУГ",
    },
    {
        label: "ЧЕСТЕЛИН",
        value: "ЧЕСТЕЛИН",
    },
    {
        label: "ВРАЊСКА БАЊА",
        value: "ВРАЊСКА БАЊА",
    },
    {
        label: "БАБИНА ПОЉАНА",
        value: "БАБИНА ПОЉАНА",
    },
    {
        label: "БУЈКОВАЦ",
        value: "БУЈКОВАЦ",
    },
    {
        label: "ДУГА ЛУКА",
        value: "ДУГА ЛУКА",
    },
    {
        label: "ИЗУМНО",
        value: "ИЗУМНО",
    },
    {
        label: "КЛИСУРИЦА",
        value: "КЛИСУРИЦА",
    },
    {
        label: "КОРБЕВАЦ",
        value: "КОРБЕВАЦ",
    },
    {
        label: "КОРБУЛ",
        value: "КОРБУЛ",
    },
    {
        label: "КРИВА ФЕЈА",
        value: "КРИВА ФЕЈА",
    },
    {
        label: "КУМАРЕВО",
        value: "КУМАРЕВО",
    },
    {
        label: "ЛЕВА РЕКА",
        value: "ЛЕВА РЕКА",
    },
    {
        label: "НЕСВРТА",
        value: "НЕСВРТА",
    },
    {
        label: "ПАНЕВЉЕ",
        value: "ПАНЕВЉЕ",
    },
    {
        label: "ПРВОНЕК",
        value: "ПРВОНЕК",
    },
    {
        label: "ПРЕВАЛАЦ",
        value: "ПРЕВАЛАЦ",
    },
    {
        label: "СЕБЕВРАЊЕ",
        value: "СЕБЕВРАЊЕ",
    },
    {
        label: "СЛИВНИЦА",
        value: "СЛИВНИЦА",
    },
    {
        label: "СТАРИ ГЛОГ",
        value: "СТАРИ ГЛОГ",
    },
    {
        label: "ТОПЛАЦ",
        value: "ТОПЛАЦ",
    },
    {
        label: "ЦРНИ ВРХ",
        value: "ЦРНИ ВРХ",
    },
    {
        label: "ВРАЧАР",
        value: "ВРАЧАР",
    },
    {
        label: "ВРБАС",
        value: "ВРБАС",
    },
    {
        label: "БАЧКО ДОБРО ПОЉЕ",
        value: "БАЧКО ДОБРО ПОЉЕ",
    },
    {
        label: "ВРБАС-ГРАД",
        value: "ВРБАС-ГРАД",
    },
    {
        label: "ЗМАЈЕВО",
        value: "ЗМАЈЕВО",
    },
    {
        label: "КУЦУРА",
        value: "КУЦУРА",
    },
    {
        label: "РАВНО СЕЛО",
        value: "РАВНО СЕЛО",
    },
    {
        label: "САВИНО СЕЛО",
        value: "САВИНО СЕЛО",
    },
    {
        label: "ВРЊАЧКА БАЊА",
        value: "ВРЊАЧКА БАЊА",
    },
    {
        label: "ВРАНЕШИ",
        value: "ВРАНЕШИ",
    },
    {
        label: "ВУКУШИЦА",
        value: "ВУКУШИЦА",
    },
    {
        label: "ГОЧ",
        value: "ГОЧ",
    },
    {
        label: "ГРАЧАЦ",
        value: "ГРАЧАЦ",
    },
    {
        label: "ЛИПОВА",
        value: "ЛИПОВА",
    },
    {
        label: "ОТРОЦИ",
        value: "ОТРОЦИ",
    },
    {
        label: "ПОДУНАВЦИ",
        value: "ПОДУНАВЦИ",
    },
    {
        label: "РСАВЦИ",
        value: "РСАВЦИ",
    },
    {
        label: "РУЂИНЦИ",
        value: "РУЂИНЦИ",
    },
    {
        label: "СТАНИШИНЦИ",
        value: "СТАНИШИНЦИ",
    },
    {
        label: "ШТУЛАЦ",
        value: "ШТУЛАЦ",
    },
    {
        label: "ВРШАЦ",
        value: "ВРШАЦ",
    },
    {
        label: "ВАТИН",
        value: "ВАТИН",
    },
    {
        label: "ВЕЛИКО СРЕДИШТЕ",
        value: "ВЕЛИКО СРЕДИШТЕ",
    },
    {
        label: "ВЛАЈКОВАЦ",
        value: "ВЛАЈКОВАЦ",
    },
    {
        label: "ВОЈВОДИНЦИ",
        value: "ВОЈВОДИНЦИ",
    },
    {
        label: "ГУДУРИЦА",
        value: "ГУДУРИЦА",
    },
    {
        label: "ЗАГАЈИЦА",
        value: "ЗАГАЈИЦА",
    },
    {
        label: "ИЗБИШТЕ",
        value: "ИЗБИШТЕ",
    },
    {
        label: "ЈАБЛАНКА",
        value: "ЈАБЛАНКА",
    },
    {
        label: "КУШТИЉ",
        value: "КУШТИЉ",
    },
    {
        label: "МАЛИ ЖАМ",
        value: "МАЛИ ЖАМ",
    },
    {
        label: "МАЛО СРЕДИШТЕ",
        value: "МАЛО СРЕДИШТЕ",
    },
    {
        label: "МЕСИЋ",
        value: "МЕСИЋ",
    },
    {
        label: "ОРЕШАЦ",
        value: "ОРЕШАЦ",
    },
    {
        label: "ПАВЛИШ",
        value: "ПАВЛИШ",
    },
    {
        label: "ПАРТА",
        value: "ПАРТА",
    },
    {
        label: "ПОТПОРАЊ",
        value: "ПОТПОРАЊ",
    },
    {
        label: "РИТИШЕВО",
        value: "РИТИШЕВО",
    },
    {
        label: "СОЧИЦА I",
        value: "СОЧИЦА I",
    },
    {
        label: "СОЧИЦА II",
        value: "СОЧИЦА II",
    },
    {
        label: "СТРАЖА",
        value: "СТРАЖА",
    },
    {
        label: "УЉМА",
        value: "УЉМА",
    },
    {
        label: "ШУШАРА",
        value: "ШУШАРА",
    },
    {
        label: "ГАЏИН ХАН",
        value: "ГАЏИН ХАН",
    },
    {
        label: "ВЕЛИКИ ВРТОП",
        value: "ВЕЛИКИ ВРТОП",
    },
    {
        label: "ВЕЛИКИ КРЧИМИР",
        value: "ВЕЛИКИ КРЧИМИР",
    },
    {
        label: "ВИЛАНДРИЦА",
        value: "ВИЛАНДРИЦА",
    },
    {
        label: "ГАРЕ",
        value: "ГАРЕ",
    },
    {
        label: "ГОРЊЕ ВЛАСЕ",
        value: "ГОРЊЕ ВЛАСЕ",
    },
    {
        label: "ГОРЊЕ ДРАГОВЉЕ",
        value: "ГОРЊЕ ДРАГОВЉЕ",
    },
    {
        label: "ГОРЊИ БАРБЕШ",
        value: "ГОРЊИ БАРБЕШ",
    },
    {
        label: "ГОРЊИ ДУШНИК",
        value: "ГОРЊИ ДУШНИК",
    },
    {
        label: "ГРКИЊА",
        value: "ГРКИЊА",
    },
    {
        label: "ДОЊЕ ДРАГОВЉЕ",
        value: "ДОЊЕ ДРАГОВЉЕ",
    },
    {
        label: "ДОЊИ БАРБЕШ",
        value: "ДОЊИ БАРБЕШ",
    },
    {
        label: "ДОЊИ ДУШНИК",
        value: "ДОЊИ ДУШНИК",
    },
    {
        label: "ДУГА ПОЉАНА",
        value: "ДУГА ПОЉАНА",
    },
    {
        label: "ЈАГЛИЧЈЕ",
        value: "ЈАГЛИЧЈЕ",
    },
    {
        label: "КАЛЕТИНАЦ",
        value: "КАЛЕТИНАЦ",
    },
    {
        label: "КРАСТАВЧЕ",
        value: "КРАСТАВЧЕ",
    },
    {
        label: "ЛИЧЈЕ",
        value: "ЛИЧЈЕ",
    },
    {
        label: "МАЛИ ВРТОП",
        value: "МАЛИ ВРТОП",
    },
    {
        label: "МАЛИ КРЧИМИР",
        value: "МАЛИ КРЧИМИР",
    },
    {
        label: "МАРИНА КУТИНА",
        value: "МАРИНА КУТИНА",
    },
    {
        label: "МИЉКОВАЦ",
        value: "МИЉКОВАЦ",
    },
    {
        label: "ОВСИЊИНАЦ",
        value: "ОВСИЊИНАЦ",
    },
    {
        label: "РАВНА ДУБРАВА",
        value: "РАВНА ДУБРАВА",
    },
    {
        label: "СЕМЧЕ",
        value: "СЕМЧЕ",
    },
    {
        label: "СОПОТНИЦА",
        value: "СОПОТНИЦА",
    },
    {
        label: "ТАСКОВИЋИ",
        value: "ТАСКОВИЋИ",
    },
    {
        label: "ЋЕЛИЈЕ",
        value: "ЋЕЛИЈЕ",
    },
    {
        label: "ЧАГРОВАЦ",
        value: "ЧАГРОВАЦ",
    },
    {
        label: "ШЕБЕТ",
        value: "ШЕБЕТ",
    },
    {
        label: "ГОЛУБАЦ",
        value: "ГОЛУБАЦ",
    },
    {
        label: "БАРИЧ",
        value: "БАРИЧ",
    },
    {
        label: "БИКИЊЕ",
        value: "БИКИЊЕ",
    },
    {
        label: "БРАНИЧЕВО",
        value: "БРАНИЧЕВО",
    },
    {
        label: "БРЊИЦА",
        value: "БРЊИЦА",
    },
    {
        label: "ВИНЦИ",
        value: "ВИНЦИ",
    },
    {
        label: "ВОЈИЛОВО",
        value: "ВОЈИЛОВО",
    },
    {
        label: "ДВОРИШТЕ",
        value: "ДВОРИШТЕ",
    },
    {
        label: "ДОБРА",
        value: "ДОБРА",
    },
    {
        label: "ДОЊА КРУШЕВИЦА",
        value: "ДОЊА КРУШЕВИЦА",
    },
    {
        label: "ДУШМАНИЋ",
        value: "ДУШМАНИЋ",
    },
    {
        label: "ЖИТКОВИЦА",
        value: "ЖИТКОВИЦА",
    },
    {
        label: "КРИВАЧА",
        value: "КРИВАЧА",
    },
    {
        label: "КУДРЕШ",
        value: "КУДРЕШ",
    },
    {
        label: "МАЛЕШЕВО",
        value: "МАЛЕШЕВО",
    },
    {
        label: "МИЉЕВИЋ",
        value: "МИЉЕВИЋ",
    },
    {
        label: "МРЧКОВАЦ",
        value: "МРЧКОВАЦ",
    },
    {
        label: "ПОНИКВЕ",
        value: "ПОНИКВЕ",
    },
    {
        label: "СЛАДИНАЦ",
        value: "СЛАДИНАЦ",
    },
    {
        label: "СНЕГОТИН",
        value: "СНЕГОТИН",
    },
    {
        label: "УСИЈЕ",
        value: "УСИЈЕ",
    },
    {
        label: "ШУВАЈИЋ",
        value: "ШУВАЈИЋ",
    },
    {
        label: "ГОРЊИ МИЛАНОВАЦ",
        value: "ГОРЊИ МИЛАНОВАЦ",
    },
    {
        label: "БЕРШИЋИ",
        value: "БЕРШИЋИ",
    },
    {
        label: "БОГДАНИЦА",
        value: "БОГДАНИЦА",
    },
    {
        label: "БОЉКОВЦИ",
        value: "БОЉКОВЦИ",
    },
    {
        label: "БРАЈИЋИ",
        value: "БРАЈИЋИ",
    },
    {
        label: "БРЕЗНА",
        value: "БРЕЗНА",
    },
    {
        label: "БРУСНИЦА",
        value: "БРУСНИЦА",
    },
    {
        label: "ВЕЛЕРЕЧ",
        value: "ВЕЛЕРЕЧ",
    },
    {
        label: "ВРАЋЕВШНИЦА",
        value: "ВРАЋЕВШНИЦА",
    },
    {
        label: "ВРНЧАНИ",
        value: "ВРНЧАНИ",
    },
    {
        label: "ГОЈНА ГОРА",
        value: "ГОЈНА ГОРА",
    },
    {
        label: "ГОРЊА ВРБАВА",
        value: "ГОРЊА ВРБАВА",
    },
    {
        label: "ГОРЊА ЦРНУЋА",
        value: "ГОРЊА ЦРНУЋА",
    },
    {
        label: "ГОРЊИ БАЊАНИ",
        value: "ГОРЊИ БАЊАНИ",
    },
    {
        label: "ГОРЊИ БРАНЕТИЋИ",
        value: "ГОРЊИ БРАНЕТИЋИ",
    },
    {
        label: "ДАВИДОВИЦА",
        value: "ДАВИДОВИЦА",
    },
    {
        label: "ДОЊА ВРБАВА",
        value: "ДОЊА ВРБАВА",
    },
    {
        label: "ДОЊА ЦРНУЋА",
        value: "ДОЊА ЦРНУЋА",
    },
    {
        label: "ДОЊИ БРАНЕТИЋИ",
        value: "ДОЊИ БРАНЕТИЋИ",
    },
    {
        label: "ДРАГОЉ",
        value: "ДРАГОЉ",
    },
    {
        label: "ДРУЖЕТИЋИ",
        value: "ДРУЖЕТИЋИ",
    },
    {
        label: "ЗАГРАЂЕ",
        value: "ЗАГРАЂЕ",
    },
    {
        label: "КАЛИМАНИЋИ",
        value: "КАЛИМАНИЋИ",
    },
    {
        label: "КЛАТИЧЕВО",
        value: "КЛАТИЧЕВО",
    },
    {
        label: "КОШТУНИЋИ",
        value: "КОШТУНИЋИ",
    },
    {
        label: "ЛЕУШИЋИ",
        value: "ЛЕУШИЋИ",
    },
    {
        label: "ЛОЗАЊ",
        value: "ЛОЗАЊ",
    },
    {
        label: "ЛОЧЕВЦИ",
        value: "ЛОЧЕВЦИ",
    },
    {
        label: "ЛУЊЕВИЦА",
        value: "ЛУЊЕВИЦА",
    },
    {
        label: "ЉЕВАЈА",
        value: "ЉЕВАЈА",
    },
    {
        label: "ЉУТОВНИЦА",
        value: "ЉУТОВНИЦА",
    },
    {
        label: "МАЈДАН",
        value: "МАЈДАН",
    },
    {
        label: "МУТАЊ",
        value: "МУТАЊ",
    },
    {
        label: "НАКУЧАНИ",
        value: "НАКУЧАНИ",
    },
    {
        label: "НЕВАДЕ",
        value: "НЕВАДЕ",
    },
    {
        label: "ОЗРЕМ",
        value: "ОЗРЕМ",
    },
    {
        label: "ПРАЊАНИ",
        value: "ПРАЊАНИ",
    },
    {
        label: "РЕЉИНЦИ",
        value: "РЕЉИНЦИ",
    },
    {
        label: "РУДНИК",
        value: "РУДНИК",
    },
    {
        label: "РУЧИЋИ",
        value: "РУЧИЋИ",
    },
    {
        label: "СВРАЧКОВЦИ",
        value: "СВРАЧКОВЦИ",
    },
    {
        label: "СЕМЕДРАЖ",
        value: "СЕМЕДРАЖ",
    },
    {
        label: "СИНОШЕВИЋИ",
        value: "СИНОШЕВИЋИ",
    },
    {
        label: "СРЕЗОЈЕВЦИ",
        value: "СРЕЗОЈЕВЦИ",
    },
    {
        label: "ТАКОВО",
        value: "ТАКОВО",
    },
    {
        label: "ТЕОЧИН",
        value: "ТЕОЧИН",
    },
    {
        label: "ТРУДЕЉ",
        value: "ТРУДЕЉ",
    },
    {
        label: "УГРИНОВЦИ",
        value: "УГРИНОВЦИ",
    },
    {
        label: "ШАРАНИ",
        value: "ШАРАНИ",
    },
    {
        label: "ШИЛОПАЈ",
        value: "ШИЛОПАЈ",
    },
    {
        label: "ГРОЦКА",
        value: "ГРОЦКА",
    },
    {
        label: "БЕГАЉИЦА",
        value: "БЕГАЉИЦА",
    },
    {
        label: "БОЛЕЧ",
        value: "БОЛЕЧ",
    },
    {
        label: "БРЕСТОВИК",
        value: "БРЕСТОВИК",
    },
    {
        label: "ВИНЧА",
        value: "ВИНЧА",
    },
    {
        label: "ВРЧИН",
        value: "ВРЧИН",
    },
    {
        label: "ДРАЖАЊ",
        value: "ДРАЖАЊ",
    },
    {
        label: "ЗАКЛОПАЧА",
        value: "ЗАКЛОПАЧА",
    },
    {
        label: "КАЛУЂЕРИЦА",
        value: "КАЛУЂЕРИЦА",
    },
    {
        label: "КАМЕНДОЛ",
        value: "КАМЕНДОЛ",
    },
    {
        label: "ЛЕШТАНЕ",
        value: "ЛЕШТАНЕ",
    },
    {
        label: "ПУДАРЦИ",
        value: "ПУДАРЦИ",
    },
    {
        label: "РИТОПЕК",
        value: "РИТОПЕК",
    },
    {
        label: "УМЧАРИ",
        value: "УМЧАРИ",
    },
    {
        label: "ДЕСПОТОВАЦ",
        value: "ДЕСПОТОВАЦ",
    },
    {
        label: "БАЛАЈНАЦ",
        value: "БАЛАЈНАЦ",
    },
    {
        label: "БЕЉАЈКА",
        value: "БЕЉАЈКА",
    },
    {
        label: "БОГАВА",
        value: "БОГАВА",
    },
    {
        label: "БУКОВАЦ",
        value: "БУКОВАЦ",
    },
    {
        label: "ВЕЛИКИ ПОПОВИЋ",
        value: "ВЕЛИКИ ПОПОВИЋ",
    },
    {
        label: "ВИТАНЦЕ",
        value: "ВИТАНЦЕ",
    },
    {
        label: "ВОЈНИК",
        value: "ВОЈНИК",
    },
    {
        label: "ЖИДИЉЕ",
        value: "ЖИДИЉЕ",
    },
    {
        label: "ЗЛАТОВО",
        value: "ЗЛАТОВО",
    },
    {
        label: "ЈЕЗЕРО",
        value: "ЈЕЗЕРО",
    },
    {
        label: "ЈЕЛОВАЦ",
        value: "ЈЕЛОВАЦ",
    },
    {
        label: "ЛОМНИЦА",
        value: "ЛОМНИЦА",
    },
    {
        label: "МЕДВЕЂА",
        value: "МЕДВЕЂА",
    },
    {
        label: "МИЛИВА",
        value: "МИЛИВА",
    },
    {
        label: "ПАЊЕВАЦ",
        value: "ПАЊЕВАЦ",
    },
    {
        label: "ПЛАЖАНЕ",
        value: "ПЛАЖАНЕ",
    },
    {
        label: "ПОПОВЊАК",
        value: "ПОПОВЊАК",
    },
    {
        label: "РЕСАВИЦА",
        value: "РЕСАВИЦА",
    },
    {
        label: "СЕЊСКИ РУДНИК",
        value: "СЕЊСКИ РУДНИК",
    },
    {
        label: "СЛАДАЈА",
        value: "СЛАДАЈА",
    },
    {
        label: "СТЕЊЕВАЦ",
        value: "СТЕЊЕВАЦ",
    },
    {
        label: "СТРМОСТЕН",
        value: "СТРМОСТЕН",
    },
    {
        label: "ТРУЋЕВАЦ",
        value: "ТРУЋЕВАЦ",
    },
    {
        label: "ДИМИТРОВГРАД",
        value: "ДИМИТРОВГРАД",
    },
    {
        label: "БАЉЕВ ДОЛ",
        value: "БАЉЕВ ДОЛ",
    },
    {
        label: "БАЊСКИ ДОЛ",
        value: "БАЊСКИ ДОЛ",
    },
    {
        label: "БАЧЕВО",
        value: "БАЧЕВО",
    },
    {
        label: "БИЛО",
        value: "БИЛО",
    },
    {
        label: "БРАЋЕВЦИ",
        value: "БРАЋЕВЦИ",
    },
    {
        label: "БРЕБЕВНИЦА",
        value: "БРЕБЕВНИЦА",
    },
    {
        label: "ВЕРЗАР",
        value: "ВЕРЗАР",
    },
    {
        label: "ВИСОЧКИ ОДОРОВЦИ",
        value: "ВИСОЧКИ ОДОРОВЦИ",
    },
    {
        label: "ВЛКОВИЈА",
        value: "ВЛКОВИЈА",
    },
    {
        label: "ВРАПЧА",
        value: "ВРАПЧА",
    },
    {
        label: "ГОЈИН ДОЛ",
        value: "ГОЈИН ДОЛ",
    },
    {
        label: "ГОРЊА НЕВЉА",
        value: "ГОРЊА НЕВЉА",
    },
    {
        label: "ГОРЊА ПЛАНИНИЦА",
        value: "ГОРЊА ПЛАНИНИЦА",
    },
    {
        label: "ГОРЊИ КРИВОДОЛ",
        value: "ГОРЊИ КРИВОДОЛ",
    },
    {
        label: "ГРАДИЊЕ",
        value: "ГРАДИЊЕ",
    },
    {
        label: "ГРАПА",
        value: "ГРАПА",
    },
    {
        label: "ГУЛЕНОВЦИ",
        value: "ГУЛЕНОВЦИ",
    },
    {
        label: "ДОЊА НЕВЉА",
        value: "ДОЊА НЕВЉА",
    },
    {
        label: "ДОЊИ КРИВОДОЛ",
        value: "ДОЊИ КРИВОДОЛ",
    },
    {
        label: "ДРАГОВИТА",
        value: "ДРАГОВИТА",
    },
    {
        label: "ЖЕЉУША",
        value: "ЖЕЉУША",
    },
    {
        label: "ИЗАТОВЦИ",
        value: "ИЗАТОВЦИ",
    },
    {
        label: "ИСКРОВЦИ",
        value: "ИСКРОВЦИ",
    },
    {
        label: "КУСА ВРАНА",
        value: "КУСА ВРАНА",
    },
    {
        label: "ЛУКАВИЦА",
        value: "ЛУКАВИЦА",
    },
    {
        label: "МАЗГОШ",
        value: "МАЗГОШ",
    },
    {
        label: "МОЈИНЦИ",
        value: "МОЈИНЦИ",
    },
    {
        label: "ПАСКАШИЈА",
        value: "ПАСКАШИЈА",
    },
    {
        label: "ПЕТАЧИНЦИ",
        value: "ПЕТАЧИНЦИ",
    },
    {
        label: "ПЕТРЛАШ",
        value: "ПЕТРЛАШ",
    },
    {
        label: "ПОГАНОВО",
        value: "ПОГАНОВО",
    },
    {
        label: "ПРАЧА",
        value: "ПРАЧА",
    },
    {
        label: "ПРОТОПОПИНЦИ",
        value: "ПРОТОПОПИНЦИ",
    },
    {
        label: "РАДЕЈНА",
        value: "РАДЕЈНА",
    },
    {
        label: "СЕНОКОС",
        value: "СЕНОКОС",
    },
    {
        label: "СКРВЕНИЦА",
        value: "СКРВЕНИЦА",
    },
    {
        label: "СМИЛОВЦИ",
        value: "СМИЛОВЦИ",
    },
    {
        label: "ТРНСКИ ОДОРОВЦИ",
        value: "ТРНСКИ ОДОРОВЦИ",
    },
    {
        label: "ДОЉЕВАЦ",
        value: "ДОЉЕВАЦ",
    },
    {
        label: "БЕЛОТИНАЦ",
        value: "БЕЛОТИНАЦ",
    },
    {
        label: "КНЕЖИЦА",
        value: "КНЕЖИЦА",
    },
    {
        label: "КОЧАНЕ",
        value: "КОЧАНЕ",
    },
    {
        label: "МАЛОШИШТЕ",
        value: "МАЛОШИШТЕ",
    },
    {
        label: "МЕКИШ",
        value: "МЕКИШ",
    },
    {
        label: "ОРЉАНЕ",
        value: "ОРЉАНЕ",
    },
    {
        label: "ПЕРУТИНА",
        value: "ПЕРУТИНА",
    },
    {
        label: "ПУКОВАЦ",
        value: "ПУКОВАЦ",
    },
    {
        label: "РУСНА",
        value: "РУСНА",
    },
    {
        label: "ЋУРЛИНА",
        value: "ЋУРЛИНА",
    },
    {
        label: "ЧАПЉИНАЦ",
        value: "ЧАПЉИНАЦ",
    },
    {
        label: "ЧЕЧИНА",
        value: "ЧЕЧИНА",
    },
    {
        label: "ШАЈИНОВАЦ",
        value: "ШАЈИНОВАЦ",
    },
    {
        label: "ШАРЛИНЦЕ",
        value: "ШАРЛИНЦЕ",
    },
    {
        label: "ЖАБАЉ",
        value: "ЖАБАЉ",
    },
    {
        label: "ГОСПОЂИНЦИ",
        value: "ГОСПОЂИНЦИ",
    },
    {
        label: "ЂУРЂЕВО",
        value: "ЂУРЂЕВО",
    },
    {
        label: "ЧУРУГ",
        value: "ЧУРУГ",
    },
    {
        label: "БРЗОХОДЕ",
        value: "БРЗОХОДЕ",
    },
    {
        label: "ВИТЕЖЕВО",
        value: "ВИТЕЖЕВО",
    },
    {
        label: "ВЛАШКИ ДО",
        value: "ВЛАШКИ ДО",
    },
    {
        label: "ЖАБАРСКО БЛАТО",
        value: "ЖАБАРСКО БЛАТО",
    },
    {
        label: "КОЧЕТИН",
        value: "КОЧЕТИН",
    },
    {
        label: "МИРИЈЕВО",
        value: "МИРИЈЕВО",
    },
    {
        label: "ОРЕОВИЦА",
        value: "ОРЕОВИЦА",
    },
    {
        label: "ПОЛАТНА",
        value: "ПОЛАТНА",
    },
    {
        label: "СВИЊАРЕВО",
        value: "СВИЊАРЕВО",
    },
    {
        label: "СИМИЋЕВО",
        value: "СИМИЋЕВО",
    },
    {
        label: "ТИЋЕВАЦ",
        value: "ТИЋЕВАЦ",
    },
    {
        label: "ЧЕТЕРЕЖЕ",
        value: "ЧЕТЕРЕЖЕ",
    },
    {
        label: "ЖАГУБИЦА",
        value: "ЖАГУБИЦА",
    },
    {
        label: "БЛИЗНАК",
        value: "БЛИЗНАК",
    },
    {
        label: "ВУКОВАЦ",
        value: "ВУКОВАЦ",
    },
    {
        label: "ГОРЊАК",
        value: "ГОРЊАК",
    },
    {
        label: "ИЗВАРИЦА",
        value: "ИЗВАРИЦА",
    },
    {
        label: "ЈОШАНИЦА",
        value: "ЈОШАНИЦА",
    },
    {
        label: "КРЕПОЉИН",
        value: "КРЕПОЉИН",
    },
    {
        label: "КРУПАЈА",
        value: "КРУПАЈА",
    },
    {
        label: "ЛАЗНИЦА-СЕЛИШТЕ",
        value: "ЛАЗНИЦА-СЕЛИШТЕ",
    },
    {
        label: "ЛАЗНИЦА-СЕЛО",
        value: "ЛАЗНИЦА-СЕЛО",
    },
    {
        label: "МАЛИ КАМЕН",
        value: "МАЛИ КАМЕН",
    },
    {
        label: "МЕДВЕЂИЦА",
        value: "МЕДВЕЂИЦА",
    },
    {
        label: "МИЛАНОВАЦ",
        value: "МИЛАНОВАЦ",
    },
    {
        label: "ОСАНИЦА",
        value: "ОСАНИЦА",
    },
    {
        label: "РИБАРЕ",
        value: "РИБАРЕ",
    },
    {
        label: "СИГЕ",
        value: "СИГЕ",
    },
    {
        label: "ЖИТИШТЕ",
        value: "ЖИТИШТЕ",
    },
    {
        label: "БАНАТСКИ ДВОР",
        value: "БАНАТСКИ ДВОР",
    },
    {
        label: "БАНАТСКО ВИШЊИЋЕВО",
        value: "БАНАТСКО ВИШЊИЋЕВО",
    },
    {
        label: "БАНАТСКО КАРАЂОРЂЕВО",
        value: "БАНАТСКО КАРАЂОРЂЕВО",
    },
    {
        label: "БЕГЕЈЦИ",
        value: "БЕГЕЈЦИ",
    },
    {
        label: "МЕЂА",
        value: "МЕЂА",
    },
    {
        label: "НОВИ ИТЕБЕЈ",
        value: "НОВИ ИТЕБЕЈ",
    },
    {
        label: "РАВНИ ТОПОЛОВАЦ",
        value: "РАВНИ ТОПОЛОВАЦ",
    },
    {
        label: "СРПСКИ ИТЕБЕЈ",
        value: "СРПСКИ ИТЕБЕЈ",
    },
    {
        label: "ТОРДА",
        value: "ТОРДА",
    },
    {
        label: "ХЕТИН",
        value: "ХЕТИН",
    },
    {
        label: "ЧЕСТЕРЕГ",
        value: "ЧЕСТЕРЕГ",
    },
    {
        label: "ЖИТОРАЂА",
        value: "ЖИТОРАЂА",
    },
    {
        label: "АСАНОВАЦ",
        value: "АСАНОВАЦ",
    },
    {
        label: "ВЛАХОВО",
        value: "ВЛАХОВО",
    },
    {
        label: "ВОЉЧИНЦЕ",
        value: "ВОЉЧИНЦЕ",
    },
    {
        label: "ГЛАШИНЦЕ",
        value: "ГЛАШИНЦЕ",
    },
    {
        label: "ГОРЊЕ ЦРНАТОВО",
        value: "ГОРЊЕ ЦРНАТОВО",
    },
    {
        label: "ГОРЊИ ДРЕНОВАЦ",
        value: "ГОРЊИ ДРЕНОВАЦ",
    },
    {
        label: "ГРУДАШ",
        value: "ГРУДАШ",
    },
    {
        label: "ДЕБЕЛИ ЛУГ",
        value: "ДЕБЕЛИ ЛУГ",
    },
    {
        label: "ДОЊЕ ЦРНАТОВО",
        value: "ДОЊЕ ЦРНАТОВО",
    },
    {
        label: "ДОЊИ ДРЕНОВАЦ",
        value: "ДОЊИ ДРЕНОВАЦ",
    },
    {
        label: "ДРЖАНОВАЦ",
        value: "ДРЖАНОВАЦ",
    },
    {
        label: "ДУБОВО",
        value: "ДУБОВО",
    },
    {
        label: "ЂАКУС",
        value: "ЂАКУС",
    },
    {
        label: "КАРЕ",
        value: "КАРЕ",
    },
    {
        label: "КОЊАРНИК",
        value: "КОЊАРНИК",
    },
    {
        label: "ЛУКОМИР",
        value: "ЛУКОМИР",
    },
    {
        label: "НОВО МОМЧИЛОВО",
        value: "НОВО МОМЧИЛОВО",
    },
    {
        label: "ПЕЈКОВАЦ",
        value: "ПЕЈКОВАЦ",
    },
    {
        label: "ПОДИНА",
        value: "ПОДИНА",
    },
    {
        label: "САМАРИНОВАЦ",
        value: "САМАРИНОВАЦ",
    },
    {
        label: "СМРДИЋ",
        value: "СМРДИЋ",
    },
    {
        label: "СТАРА БОЖУРНА",
        value: "СТАРА БОЖУРНА",
    },
    {
        label: "СТАРО МОМЧИЛОВО",
        value: "СТАРО МОМЧИЛОВО",
    },
    {
        label: "СТУДЕНАЦ",
        value: "СТУДЕНАЦ",
    },
    {
        label: "ЗАЈЕЧАР",
        value: "ЗАЈЕЧАР",
    },
    {
        label: "БРУСНИК",
        value: "БРУСНИК",
    },
    {
        label: "ВЕЛИКА ЈАСИКОВА",
        value: "ВЕЛИКА ЈАСИКОВА",
    },
    {
        label: "ВЕЛИКИ ИЗВОР",
        value: "ВЕЛИКИ ИЗВОР",
    },
    {
        label: "ВЕЛИКИ ЈАСЕНОВАЦ",
        value: "ВЕЛИКИ ЈАСЕНОВАЦ",
    },
    {
        label: "ВРАЖОГРНАЦ",
        value: "ВРАЖОГРНАЦ",
    },
    {
        label: "ВРАТАРНИЦА",
        value: "ВРАТАРНИЦА",
    },
    {
        label: "ГАМЗИГРАД",
        value: "ГАМЗИГРАД",
    },
    {
        label: "ГОРЊА БЕЛА РЕКА",
        value: "ГОРЊА БЕЛА РЕКА",
    },
    {
        label: "ГРАДСКОВО",
        value: "ГРАДСКОВО",
    },
    {
        label: "ГРЛИШТЕ",
        value: "ГРЛИШТЕ",
    },
    {
        label: "ГРЉАН",
        value: "ГРЉАН",
    },
    {
        label: "ДУБОЧАНЕ",
        value: "ДУБОЧАНЕ",
    },
    {
        label: "ЗВЕЗДАН",
        value: "ЗВЕЗДАН",
    },
    {
        label: "ЈЕЛАШНИЦА",
        value: "ЈЕЛАШНИЦА",
    },
    {
        label: "КЛЕНОВАЦ",
        value: "КЛЕНОВАЦ",
    },
    {
        label: "ЛАСОВО",
        value: "ЛАСОВО",
    },
    {
        label: "ЛЕНОВАЦ",
        value: "ЛЕНОВАЦ",
    },
    {
        label: "ЛЕСКОВАЦ",
        value: "ЛЕСКОВАЦ",
    },
    {
        label: "ЛУБНИЦА",
        value: "ЛУБНИЦА",
    },
    {
        label: "МАЛА ЈАСИКОВА",
        value: "МАЛА ЈАСИКОВА",
    },
    {
        label: "МАЛИ ЈАСЕНОВАЦ",
        value: "МАЛИ ЈАСЕНОВАЦ",
    },
    {
        label: "МАРИНОВАЦ",
        value: "МАРИНОВАЦ",
    },
    {
        label: "МЕТРИШ",
        value: "МЕТРИШ",
    },
    {
        label: "НИКОЛИЧЕВО",
        value: "НИКОЛИЧЕВО",
    },
    {
        label: "ПЛАНИНИЦА",
        value: "ПЛАНИНИЦА",
    },
    {
        label: "ПРЛИТА",
        value: "ПРЛИТА",
    },
    {
        label: "РГОТИНА",
        value: "РГОТИНА",
    },
    {
        label: "САЛАШ",
        value: "САЛАШ",
    },
    {
        label: "СЕЛАЧКА",
        value: "СЕЛАЧКА",
    },
    {
        label: "ТАБАКОВАЦ",
        value: "ТАБАКОВАЦ",
    },
    {
        label: "ТРНАВАЦ",
        value: "ТРНАВАЦ",
    },
    {
        label: "ХАЛОВО",
        value: "ХАЛОВО",
    },
    {
        label: "ШИПИКОВО",
        value: "ШИПИКОВО",
    },
    {
        label: "ШЉИВАР",
        value: "ШЉИВАР",
    },
    {
        label: "ЗВЕЗДАРА",
        value: "ЗВЕЗДАРА",
    },
    {
        label: "ВЕЛИКИ МОКРИ ЛУГ",
        value: "ВЕЛИКИ МОКРИ ЛУГ",
    },
    {
        label: "МАЛИ МОКРИ ЛУГ",
        value: "МАЛИ МОКРИ ЛУГ",
    },
    {
        label: "ЗЕМУН",
        value: "ЗЕМУН",
    },
    {
        label: "БАТАЈНИЦА",
        value: "БАТАЈНИЦА",
    },
    {
        label: "ЗЕМУН ПОЉЕ",
        value: "ЗЕМУН ПОЉЕ",
    },
    {
        label: "ЗРЕЊАНИН",
        value: "ЗРЕЊАНИН",
    },
    {
        label: "БАНАТСКИ ДЕСПОТОВАЦ",
        value: "БАНАТСКИ ДЕСПОТОВАЦ",
    },
    {
        label: "БЕЛО БЛАТО",
        value: "БЕЛО БЛАТО",
    },
    {
        label: "БОТОШ",
        value: "БОТОШ",
    },
    {
        label: "ЕЛЕМИР",
        value: "ЕЛЕМИР",
    },
    {
        label: "ЕЧКА",
        value: "ЕЧКА",
    },
    {
        label: "ЗРЕЊАНИН I",
        value: "ЗРЕЊАНИН I",
    },
    {
        label: "ЗРЕЊАНИН III",
        value: "ЗРЕЊАНИН III",
    },
    {
        label: "ЈАНКОВ МОСТ",
        value: "ЈАНКОВ МОСТ",
    },
    {
        label: "КЛЕК",
        value: "КЛЕК",
    },
    {
        label: "КНИЋАНИН",
        value: "КНИЋАНИН",
    },
    {
        label: "ЛАЗАРЕВО",
        value: "ЛАЗАРЕВО",
    },
    {
        label: "ЛУКИНО СЕЛО",
        value: "ЛУКИНО СЕЛО",
    },
    {
        label: "ЛУКИЋЕВО",
        value: "ЛУКИЋЕВО",
    },
    {
        label: "МЕЛЕНЦИ",
        value: "МЕЛЕНЦИ",
    },
    {
        label: "МИХАЈЛОВО",
        value: "МИХАЈЛОВО",
    },
    {
        label: "МУЖЉА",
        value: "МУЖЉА",
    },
    {
        label: "ОРЛОВАТ",
        value: "ОРЛОВАТ",
    },
    {
        label: "ПЕРЛЕЗ",
        value: "ПЕРЛЕЗ",
    },
    {
        label: "СЛОВАЧКИ АРАДАЦ",
        value: "СЛОВАЧКИ АРАДАЦ",
    },
    {
        label: "СРПСКИ АРАДАЦ",
        value: "СРПСКИ АРАДАЦ",
    },
    {
        label: "СРПСКИ ЕЛЕМИР",
        value: "СРПСКИ ЕЛЕМИР",
    },
    {
        label: "СТАЈИЋЕВО",
        value: "СТАЈИЋЕВО",
    },
    {
        label: "ТАРАШ",
        value: "ТАРАШ",
    },
    {
        label: "ТАРАШ I",
        value: "ТАРАШ I",
    },
    {
        label: "ТОМАШЕВАЦ",
        value: "ТОМАШЕВАЦ",
    },
    {
        label: "ФАРКАЖДИН",
        value: "ФАРКАЖДИН",
    },
    {
        label: "ЧЕНТА",
        value: "ЧЕНТА",
    },
    {
        label: "ИВАЊИЦА",
        value: "ИВАЊИЦА",
    },
    {
        label: "БЕДИНА ВАРОШ",
        value: "БЕДИНА ВАРОШ",
    },
    {
        label: "БРАТЉЕВО",
        value: "БРАТЉЕВО",
    },
    {
        label: "БРЕЗОВА",
        value: "БРЕЗОВА",
    },
    {
        label: "БУДОЖЕЉА",
        value: "БУДОЖЕЉА",
    },
    {
        label: "ВАСИЉЕВИЋИ",
        value: "ВАСИЉЕВИЋИ",
    },
    {
        label: "ВИОНИЦА",
        value: "ВИОНИЦА",
    },
    {
        label: "ВРМБАЈЕ",
        value: "ВРМБАЈЕ",
    },
    {
        label: "ВУЧАК",
        value: "ВУЧАК",
    },
    {
        label: "ГЛЕЂИЦА",
        value: "ГЛЕЂИЦА",
    },
    {
        label: "ДАЈИЋИ",
        value: "ДАЈИЋИ",
    },
    {
        label: "ДЕРЕТИН",
        value: "ДЕРЕТИН",
    },
    {
        label: "ДОБРИ ДО",
        value: "ДОБРИ ДО",
    },
    {
        label: "ЕРЧЕГЕ",
        value: "ЕРЧЕГЕ",
    },
    {
        label: "КОМАДИНЕ",
        value: "КОМАДИНЕ",
    },
    {
        label: "КОРИТНИК",
        value: "КОРИТНИК",
    },
    {
        label: "КОСОВИЦА",
        value: "КОСОВИЦА",
    },
    {
        label: "КУМАНИЦА",
        value: "КУМАНИЦА",
    },
    {
        label: "КУШИЋИ",
        value: "КУШИЋИ",
    },
    {
        label: "ЛИСА",
        value: "ЛИСА",
    },
    {
        label: "ЛУКЕ",
        value: "ЛУКЕ",
    },
    {
        label: "МАНА",
        value: "МАНА",
    },
    {
        label: "МАСКОВА",
        value: "МАСКОВА",
    },
    {
        label: "МЕДОВИНЕ",
        value: "МЕДОВИНЕ",
    },
    {
        label: "МОЧИОЦИ",
        value: "МОЧИОЦИ",
    },
    {
        label: "ОПАЉЕНИК",
        value: "ОПАЉЕНИК",
    },
    {
        label: "ОСОНИЦА",
        value: "ОСОНИЦА",
    },
    {
        label: "ПРИЛИКЕ",
        value: "ПРИЛИКЕ",
    },
    {
        label: "РАДАЉЕВО",
        value: "РАДАЉЕВО",
    },
    {
        label: "РОВИНЕ",
        value: "РОВИНЕ",
    },
    {
        label: "СВЕШТИЦА",
        value: "СВЕШТИЦА",
    },
    {
        label: "СИВЧИНА",
        value: "СИВЧИНА",
    },
    {
        label: "СМИЉЕВАЦ",
        value: "СМИЉЕВАЦ",
    },
    {
        label: "ШАРЕНИК",
        value: "ШАРЕНИК",
    },
    {
        label: "ШУМЕ",
        value: "ШУМЕ",
    },
    {
        label: "ИНЂИЈА",
        value: "ИНЂИЈА",
    },
    {
        label: "БЕШКА",
        value: "БЕШКА",
    },
    {
        label: "КРЧЕДИН",
        value: "КРЧЕДИН",
    },
    {
        label: "ЉУКОВО",
        value: "ЉУКОВО",
    },
    {
        label: "МАРАДИК",
        value: "МАРАДИК",
    },
    {
        label: "НОВИ КАРЛОВЦИ",
        value: "НОВИ КАРЛОВЦИ",
    },
    {
        label: "НОВИ СЛАНКАМЕН",
        value: "НОВИ СЛАНКАМЕН",
    },
    {
        label: "СТАРИ СЛАНКАМЕН",
        value: "СТАРИ СЛАНКАМЕН",
    },
    {
        label: "ЧОРТАНОВЦИ",
        value: "ЧОРТАНОВЦИ",
    },
    {
        label: "ИРИГ",
        value: "ИРИГ",
    },
    {
        label: "БАНКОВЦИ",
        value: "БАНКОВЦИ",
    },
    {
        label: "ВЕЛИКА РЕМЕТА",
        value: "ВЕЛИКА РЕМЕТА",
    },
    {
        label: "ВРДНИК",
        value: "ВРДНИК",
    },
    {
        label: "ГРГЕТЕК",
        value: "ГРГЕТЕК",
    },
    {
        label: "ДОБРОДОЛ",
        value: "ДОБРОДОЛ",
    },
    {
        label: "ЈАЗАК ПРЊАВОР",
        value: "ЈАЗАК ПРЊАВОР",
    },
    {
        label: "ЈАЗАК СЕЛО",
        value: "ЈАЗАК СЕЛО",
    },
    {
        label: "КРУШЕДОЛ ПРЊАВОР",
        value: "КРУШЕДОЛ ПРЊАВОР",
    },
    {
        label: "КРУШЕДОЛ СЕЛО",
        value: "КРУШЕДОЛ СЕЛО",
    },
    {
        label: "МАЛА РЕМЕТА",
        value: "МАЛА РЕМЕТА",
    },
    {
        label: "НЕРАДИН",
        value: "НЕРАДИН",
    },
    {
        label: "РИВИЦА",
        value: "РИВИЦА",
    },
    {
        label: "ШАТРИНЦИ",
        value: "ШАТРИНЦИ",
    },
    {
        label: "ЈАГОДИНА",
        value: "ЈАГОДИНА",
    },
    {
        label: "БАГРДАН (ВАРОШИЦА)",
        value: "БАГРДАН (ВАРОШИЦА)",
    },
    {
        label: "БАГРДАН (СЕЛО)",
        value: "БАГРДАН (СЕЛО)",
    },
    {
        label: "БЕЛИЦА",
        value: "БЕЛИЦА",
    },
    {
        label: "БРЕСЈЕ",
        value: "БРЕСЈЕ",
    },
    {
        label: "БУКОВЧЕ",
        value: "БУКОВЧЕ",
    },
    {
        label: "БУНАР",
        value: "БУНАР",
    },
    {
        label: "ВИНОРАЧА",
        value: "ВИНОРАЧА",
    },
    {
        label: "ВОЉАВЧЕ",
        value: "ВОЉАВЧЕ",
    },
    {
        label: "ВРАНОВАЦ",
        value: "ВРАНОВАЦ",
    },
    {
        label: "ВРБА",
        value: "ВРБА",
    },
    {
        label: "ГЛАВИНЦИ",
        value: "ГЛАВИНЦИ",
    },
    {
        label: "ГОРЊЕ ШТИПЉЕ",
        value: "ГОРЊЕ ШТИПЉЕ",
    },
    {
        label: "ГОРЊИ РАЧНИК",
        value: "ГОРЊИ РАЧНИК",
    },
    {
        label: "ДЕОНИЦА",
        value: "ДЕОНИЦА",
    },
    {
        label: "ДОБРА ВОДА",
        value: "ДОБРА ВОДА",
    },
    {
        label: "ДОЊЕ ШТИПЉЕ",
        value: "ДОЊЕ ШТИПЉЕ",
    },
    {
        label: "ДОЊИ РАЧНИК",
        value: "ДОЊИ РАЧНИК",
    },
    {
        label: "ДРАГОЦВЕТ",
        value: "ДРАГОЦВЕТ",
    },
    {
        label: "ДРАГОШЕВАЦ",
        value: "ДРАГОШЕВАЦ",
    },
    {
        label: "ДРАЖМИРОВАЦ",
        value: "ДРАЖМИРОВАЦ",
    },
    {
        label: "ДУБОКА",
        value: "ДУБОКА",
    },
    {
        label: "ИВКОВАЧКИ ПРЊАВОР",
        value: "ИВКОВАЧКИ ПРЊАВОР",
    },
    {
        label: "ЈОШАНИЧКИ ПРЊАВОР",
        value: "ЈОШАНИЧКИ ПРЊАВОР",
    },
    {
        label: "КАЛЕНОВАЦ",
        value: "КАЛЕНОВАЦ",
    },
    {
        label: "КОВАЧЕВАЦ",
        value: "КОВАЧЕВАЦ",
    },
    {
        label: "КОЛАРЕ",
        value: "КОЛАРЕ",
    },
    {
        label: "КОНЧАРЕВО",
        value: "КОНЧАРЕВО",
    },
    {
        label: "КОЧИНО СЕЛО",
        value: "КОЧИНО СЕЛО",
    },
    {
        label: "ЛОВЦИ",
        value: "ЛОВЦИ",
    },
    {
        label: "ЛУКАР",
        value: "ЛУКАР",
    },
    {
        label: "МАЈУР",
        value: "МАЈУР",
    },
    {
        label: "МАЛИ ПОПОВИЋ",
        value: "МАЛИ ПОПОВИЋ",
    },
    {
        label: "МЕДОЈЕВАЦ",
        value: "МЕДОЈЕВАЦ",
    },
    {
        label: "МЕЂУРЕЧ",
        value: "МЕЂУРЕЧ",
    },
    {
        label: "МИЛОШЕВО",
        value: "МИЛОШЕВО",
    },
    {
        label: "МИШЕВИЋ",
        value: "МИШЕВИЋ",
    },
    {
        label: "РАЈКИНАЦ",
        value: "РАЈКИНАЦ",
    },
    {
        label: "РАКИТОВО",
        value: "РАКИТОВО",
    },
    {
        label: "РИБНИК",
        value: "РИБНИК",
    },
    {
        label: "СИОКОВАЦ",
        value: "СИОКОВАЦ",
    },
    {
        label: "СТРИЖИЛО",
        value: "СТРИЖИЛО",
    },
    {
        label: "ТОПОЛА",
        value: "ТОПОЛА",
    },
    {
        label: "ТРНАВА",
        value: "ТРНАВА",
    },
    {
        label: "ШАНТАРОВАЦ",
        value: "ШАНТАРОВАЦ",
    },
    {
        label: "ШУЉКОВАЦ",
        value: "ШУЉКОВАЦ",
    },
    {
        label: "КАЊИЖА",
        value: "КАЊИЖА",
    },
    {
        label: "АДОРЈАН",
        value: "АДОРЈАН",
    },
    {
        label: "ВЕЛЕБИТ",
        value: "ВЕЛЕБИТ",
    },
    {
        label: "МАЛЕ ПИЈАЦЕ",
        value: "МАЛЕ ПИЈАЦЕ",
    },
    {
        label: "МАРТОНОШ",
        value: "МАРТОНОШ",
    },
    {
        label: "ОРОМ",
        value: "ОРОМ",
    },
    {
        label: "ТРЕШЊЕВАЦ",
        value: "ТРЕШЊЕВАЦ",
    },
    {
        label: "ХОРГОШ",
        value: "ХОРГОШ",
    },
    {
        label: "КИКИНДА",
        value: "КИКИНДА",
    },
    {
        label: "БАНАТСКА ТОПОЛА",
        value: "БАНАТСКА ТОПОЛА",
    },
    {
        label: "БАНАТСКО ВЕЛИКО СЕЛО",
        value: "БАНАТСКО ВЕЛИКО СЕЛО",
    },
    {
        label: "БАШАИД",
        value: "БАШАИД",
    },
    {
        label: "ИЂОШ",
        value: "ИЂОШ",
    },
    {
        label: "МОКРИН",
        value: "МОКРИН",
    },
    {
        label: "НАКОВО",
        value: "НАКОВО",
    },
    {
        label: "НОВИ КОЗАРЦИ",
        value: "НОВИ КОЗАРЦИ",
    },
    {
        label: "РУСКО СЕЛО",
        value: "РУСКО СЕЛО",
    },
    {
        label: "САЈАН",
        value: "САЈАН",
    },
    {
        label: "КЛАДОВО",
        value: "КЛАДОВО",
    },
    {
        label: "БРЗА ПАЛАНКА",
        value: "БРЗА ПАЛАНКА",
    },
    {
        label: "ВАЈУГА",
        value: "ВАЈУГА",
    },
    {
        label: "ВЕЛЕСНИЦА",
        value: "ВЕЛЕСНИЦА",
    },
    {
        label: "ВЕЛИКА ВРБИЦА",
        value: "ВЕЛИКА ВРБИЦА",
    },
    {
        label: "ВЕЛИКА КАМЕНИЦА",
        value: "ВЕЛИКА КАМЕНИЦА",
    },
    {
        label: "КЛАДУШНИЦА",
        value: "КЛАДУШНИЦА",
    },
    {
        label: "КОРБОВО",
        value: "КОРБОВО",
    },
    {
        label: "КОСТОЛ",
        value: "КОСТОЛ",
    },
    {
        label: "КУПУЗИШТЕ",
        value: "КУПУЗИШТЕ",
    },
    {
        label: "ЉУБИЧЕВАЦ",
        value: "ЉУБИЧЕВАЦ",
    },
    {
        label: "МАЛА ВРБИЦА",
        value: "МАЛА ВРБИЦА",
    },
    {
        label: "МАНАСТИРИЦА",
        value: "МАНАСТИРИЦА",
    },
    {
        label: "МИЛУТИНОВАЦ",
        value: "МИЛУТИНОВАЦ",
    },
    {
        label: "ПЕТРОВО СЕЛО",
        value: "ПЕТРОВО СЕЛО",
    },
    {
        label: "ПОДВРШКА",
        value: "ПОДВРШКА",
    },
    {
        label: "РЕКА",
        value: "РЕКА",
    },
    {
        label: "РТКОВО",
        value: "РТКОВО",
    },
    {
        label: "СИП",
        value: "СИП",
    },
    {
        label: "ТЕКИЈА",
        value: "ТЕКИЈА",
    },
    {
        label: "КНИЋ",
        value: "КНИЋ",
    },
    {
        label: "БАЈЧЕТИНА",
        value: "БАЈЧЕТИНА",
    },
    {
        label: "БАЛОСАВЕ",
        value: "БАЛОСАВЕ",
    },
    {
        label: "БАРЕ",
        value: "БАРЕ",
    },
    {
        label: "БЕЧЕВИЦА",
        value: "БЕЧЕВИЦА",
    },
    {
        label: "БОРАЧ",
        value: "БОРАЧ",
    },
    {
        label: "БУМБАРЕВО БРДО",
        value: "БУМБАРЕВО БРДО",
    },
    {
        label: "ВРБЕТА",
        value: "ВРБЕТА",
    },
    {
        label: "ВУЧКОВИЦА",
        value: "ВУЧКОВИЦА",
    },
    {
        label: "ГРАБОВАЦ",
        value: "ГРАБОВАЦ",
    },
    {
        label: "ГРИВАЦ",
        value: "ГРИВАЦ",
    },
    {
        label: "ГРУЖА",
        value: "ГРУЖА",
    },
    {
        label: "ГУБЕРЕВАЦ",
        value: "ГУБЕРЕВАЦ",
    },
    {
        label: "ДРАГУШИЦА",
        value: "ДРАГУШИЦА",
    },
    {
        label: "ЗАБОЈНИЦА",
        value: "ЗАБОЈНИЦА",
    },
    {
        label: "КИКОЈЕВАЦ",
        value: "КИКОЈЕВАЦ",
    },
    {
        label: "КНЕЖЕВАЦ",
        value: "КНЕЖЕВАЦ",
    },
    {
        label: "КОЊУША",
        value: "КОЊУША",
    },
    {
        label: "КУСОВАЦ",
        value: "КУСОВАЦ",
    },
    {
        label: "ЛИПНИЦА",
        value: "ЛИПНИЦА",
    },
    {
        label: "ЉУБИЋ",
        value: "ЉУБИЋ",
    },
    {
        label: "ЉУЉАЦИ",
        value: "ЉУЉАЦИ",
    },
    {
        label: "ОПЛАНИЋ",
        value: "ОПЛАНИЋ",
    },
    {
        label: "ПАЈСИЈЕВИЋ",
        value: "ПАЈСИЈЕВИЋ",
    },
    {
        label: "ПРЕТОКЕ",
        value: "ПРЕТОКЕ",
    },
    {
        label: "РАДМИЛОВИЋ",
        value: "РАДМИЛОВИЋ",
    },
    {
        label: "РАШКОВИЋ",
        value: "РАШКОВИЋ",
    },
    {
        label: "СУМОРОВАЦ",
        value: "СУМОРОВАЦ",
    },
    {
        label: "ЧЕСТИН",
        value: "ЧЕСТИН",
    },
    {
        label: "КЊАЖЕВАЦ",
        value: "КЊАЖЕВАЦ",
    },
    {
        label: "АЛДИНА РЕКА",
        value: "АЛДИНА РЕКА",
    },
    {
        label: "АЛДИНАЦ",
        value: "АЛДИНАЦ",
    },
    {
        label: "БАЛАНОВАЦ",
        value: "БАЛАНОВАЦ",
    },
    {
        label: "БАЛИНАЦ",
        value: "БАЛИНАЦ",
    },
    {
        label: "БАЛТА БЕРИЛОВАЦ",
        value: "БАЛТА БЕРИЛОВАЦ",
    },
    {
        label: "БАЊСКИ ОРЕШАЦ",
        value: "БАЊСКИ ОРЕШАЦ",
    },
    {
        label: "БЕРЧИНОВАЦ",
        value: "БЕРЧИНОВАЦ",
    },
    {
        label: "БОЖИНОВАЦ",
        value: "БОЖИНОВАЦ",
    },
    {
        label: "БУЛИНОВАЦ",
        value: "БУЛИНОВАЦ",
    },
    {
        label: "ВАЛЕВАЦ",
        value: "ВАЛЕВАЦ",
    },
    {
        label: "ВАСИЉ",
        value: "ВАСИЉ",
    },
    {
        label: "ВИДОВАЦ",
        value: "ВИДОВАЦ",
    },
    {
        label: "ВИНА",
        value: "ВИНА",
    },
    {
        label: "ВЛАШКО ПОЉЕ",
        value: "ВЛАШКО ПОЉЕ",
    },
    {
        label: "ВРТОВАЦ",
        value: "ВРТОВАЦ",
    },
    {
        label: "ГАБРОВНИЦА",
        value: "ГАБРОВНИЦА",
    },
    {
        label: "ГОРЊА КАМЕНИЦА",
        value: "ГОРЊА КАМЕНИЦА",
    },
    {
        label: "ГОРЊА СОКОЛОВИЦА",
        value: "ГОРЊА СОКОЛОВИЦА",
    },
    {
        label: "ГОРЊЕ ЗУНИЧЕ",
        value: "ГОРЊЕ ЗУНИЧЕ",
    },
    {
        label: "ГРЕЗНА",
        value: "ГРЕЗНА",
    },
    {
        label: "ДЕБЕЛИЦА",
        value: "ДЕБЕЛИЦА",
    },
    {
        label: "ДЕЈАНОВАЦ",
        value: "ДЕЈАНОВАЦ",
    },
    {
        label: "ДОЊА КАМЕНИЦА",
        value: "ДОЊА КАМЕНИЦА",
    },
    {
        label: "ДОЊА СОКОЛОВИЦА",
        value: "ДОЊА СОКОЛОВИЦА",
    },
    {
        label: "ДОЊЕ ЗУНИЧЕ",
        value: "ДОЊЕ ЗУНИЧЕ",
    },
    {
        label: "ДРВНИК",
        value: "ДРВНИК",
    },
    {
        label: "ДРЕЧИНОВАЦ",
        value: "ДРЕЧИНОВАЦ",
    },
    {
        label: "ЖЛНЕ",
        value: "ЖЛНЕ",
    },
    {
        label: "ЖУКОВАЦ",
        value: "ЖУКОВАЦ",
    },
    {
        label: "ЗОРУНОВАЦ",
        value: "ЗОРУНОВАЦ",
    },
    {
        label: "ЗУБЕТИНАЦ",
        value: "ЗУБЕТИНАЦ",
    },
    {
        label: "ИНОВО",
        value: "ИНОВО",
    },
    {
        label: "ЈАКОВАЦ",
        value: "ЈАКОВАЦ",
    },
    {
        label: "ЈАЛОВИК ИЗВОР",
        value: "ЈАЛОВИК ИЗВОР",
    },
    {
        label: "ЈАЊА",
        value: "ЈАЊА",
    },
    {
        label: "КАЛИЧИНА",
        value: "КАЛИЧИНА",
    },
    {
        label: "КАЛНА",
        value: "КАЛНА",
    },
    {
        label: "КАНДАЛИЦА",
        value: "КАНДАЛИЦА",
    },
    {
        label: "КОЖЕЉ",
        value: "КОЖЕЉ",
    },
    {
        label: "КРЕНТА",
        value: "КРЕНТА",
    },
    {
        label: "ЛЕПЕНА",
        value: "ЛЕПЕНА",
    },
    {
        label: "ЛОКВА",
        value: "ЛОКВА",
    },
    {
        label: "МАЊИНАЦ",
        value: "МАЊИНАЦ",
    },
    {
        label: "МИНИЋЕВО",
        value: "МИНИЋЕВО",
    },
    {
        label: "МУЧИБАБА",
        value: "МУЧИБАБА",
    },
    {
        label: "НОВО КОРИТО",
        value: "НОВО КОРИТО",
    },
    {
        label: "ОШЉАНЕ",
        value: "ОШЉАНЕ",
    },
    {
        label: "ПАПРАТНА",
        value: "ПАПРАТНА",
    },
    {
        label: "ПЕТРУША",
        value: "ПЕТРУША",
    },
    {
        label: "ПОНОР",
        value: "ПОНОР",
    },
    {
        label: "ПОТРКАЊЕ",
        value: "ПОТРКАЊЕ",
    },
    {
        label: "ПРИЧЕВАЦ",
        value: "ПРИЧЕВАЦ",
    },
    {
        label: "РАВНА",
        value: "РАВНА",
    },
    {
        label: "РАДИЧЕВАЦ",
        value: "РАДИЧЕВАЦ",
    },
    {
        label: "РГОШТЕ",
        value: "РГОШТЕ",
    },
    {
        label: "РЕПУШНИЦА",
        value: "РЕПУШНИЦА",
    },
    {
        label: "СВРЉИШКА ТОПЛА",
        value: "СВРЉИШКА ТОПЛА",
    },
    {
        label: "СКРОБНИЦА",
        value: "СКРОБНИЦА",
    },
    {
        label: "СТАЊИНАЦ",
        value: "СТАЊИНАЦ",
    },
    {
        label: "СТАРО КОРИТО",
        value: "СТАРО КОРИТО",
    },
    {
        label: "СТОГАЗОВАЦ",
        value: "СТОГАЗОВАЦ",
    },
    {
        label: "ТАТРАСНИЦА",
        value: "ТАТРАСНИЦА",
    },
    {
        label: "ТРГОВИШТЕ",
        value: "ТРГОВИШТЕ",
    },
    {
        label: "ТРНОВАЦ",
        value: "ТРНОВАЦ",
    },
    {
        label: "ЋУШТИЦА",
        value: "ЋУШТИЦА",
    },
    {
        label: "ЦРВЕЊЕ",
        value: "ЦРВЕЊЕ",
    },
    {
        label: "ШЕСТИ ГАБАР",
        value: "ШЕСТИ ГАБАР",
    },
    {
        label: "ШТИПИНА",
        value: "ШТИПИНА",
    },
    {
        label: "ШТИТАРАЦ",
        value: "ШТИТАРАЦ",
    },
    {
        label: "ШТРБАЦ",
        value: "ШТРБАЦ",
    },
    {
        label: "ШУМАН ТОПЛА",
        value: "ШУМАН ТОПЛА",
    },
    {
        label: "КОВАЧИЦА",
        value: "КОВАЧИЦА",
    },
    {
        label: "ДЕБЕЉАЧА",
        value: "ДЕБЕЉАЧА",
    },
    {
        label: "ИДВОР",
        value: "ИДВОР",
    },
    {
        label: "ПАДИНА",
        value: "ПАДИНА",
    },
    {
        label: "САМОШ",
        value: "САМОШ",
    },
    {
        label: "УЗДИН",
        value: "УЗДИН",
    },
    {
        label: "ЦРЕПАЈА",
        value: "ЦРЕПАЈА",
    },
    {
        label: "КОВИН",
        value: "КОВИН",
    },
    {
        label: "БАВАНИШТЕ I",
        value: "БАВАНИШТЕ I",
    },
    {
        label: "БАВАНИШТЕ II",
        value: "БАВАНИШТЕ II",
    },
    {
        label: "ГАЈ",
        value: "ГАЈ",
    },
    {
        label: "ДЕЛИБЛАТО",
        value: "ДЕЛИБЛАТО",
    },
    {
        label: "ДЕЛИБЛАТСКИ ПЕСАК",
        value: "ДЕЛИБЛАТСКИ ПЕСАК",
    },
    {
        label: "ДУБОВАЦ",
        value: "ДУБОВАЦ",
    },
    {
        label: "МРАМОРАК",
        value: "МРАМОРАК",
    },
    {
        label: "ПЛОЧИЦА",
        value: "ПЛОЧИЦА",
    },
    {
        label: "СКОРЕНОВАЦ",
        value: "СКОРЕНОВАЦ",
    },
    {
        label: "КОСЈЕРИЋ",
        value: "КОСЈЕРИЋ",
    },
    {
        label: "БЈЕЛОПЕРИЦА",
        value: "БЈЕЛОПЕРИЦА",
    },
    {
        label: "БРАЈКОВИЋИ",
        value: "БРАЈКОВИЋИ",
    },
    {
        label: "ГАЛОВИЋИ",
        value: "ГАЛОВИЋИ",
    },
    {
        label: "ГОДЕЧЕВО I",
        value: "ГОДЕЧЕВО I",
    },
    {
        label: "ГОДЕЧЕВО II",
        value: "ГОДЕЧЕВО II",
    },
    {
        label: "ГОДЉЕВО",
        value: "ГОДЉЕВО",
    },
    {
        label: "ДРЕНОВЦИ",
        value: "ДРЕНОВЦИ",
    },
    {
        label: "КОСЈЕРИЋ (ВАРОШ)",
        value: "КОСЈЕРИЋ (ВАРОШ)",
    },
    {
        label: "КОСЈЕРИЋ (СЕЛО)",
        value: "КОСЈЕРИЋ (СЕЛО)",
    },
    {
        label: "МАКОВИШТЕ I",
        value: "МАКОВИШТЕ I",
    },
    {
        label: "МАКОВИШТЕ II",
        value: "МАКОВИШТЕ II",
    },
    {
        label: "МИОНИЦА",
        value: "МИОНИЦА",
    },
    {
        label: "МРЧИЋИ",
        value: "МРЧИЋИ",
    },
    {
        label: "МУШИЋИ",
        value: "МУШИЋИ",
    },
    {
        label: "ПАРАМУН",
        value: "ПАРАМУН",
    },
    {
        label: "ПОЛОШНИЦА I",
        value: "ПОЛОШНИЦА I",
    },
    {
        label: "ПОЛОШНИЦА II",
        value: "ПОЛОШНИЦА II",
    },
    {
        label: "РАДАНОВЦИ",
        value: "РАДАНОВЦИ",
    },
    {
        label: "РОСИЋИ",
        value: "РОСИЋИ",
    },
    {
        label: "РУДА БУКВА",
        value: "РУДА БУКВА",
    },
    {
        label: "СЕЧА РЕКА",
        value: "СЕЧА РЕКА",
    },
    {
        label: "СКАКАВЦИ",
        value: "СКАКАВЦИ",
    },
    {
        label: "СТОЈИЋИ",
        value: "СТОЈИЋИ",
    },
    {
        label: "СУБЈЕЛ",
        value: "СУБЈЕЛ",
    },
    {
        label: "ТУБИЋИ",
        value: "ТУБИЋИ",
    },
    {
        label: "ЦИКОТЕ",
        value: "ЦИКОТЕ",
    },
    {
        label: "ШЕВРЉУГЕ",
        value: "ШЕВРЉУГЕ",
    },
    {
        label: "КОСТОЛАЦ",
        value: "КОСТОЛАЦ",
    },
    {
        label: "КЛЕНОВНИК",
        value: "КЛЕНОВНИК",
    },
    {
        label: "КОСТОЛАЦ СЕЛО",
        value: "КОСТОЛАЦ СЕЛО",
    },
    {
        label: "КОСТОЛАЦ-ГРАД",
        value: "КОСТОЛАЦ-ГРАД",
    },
    {
        label: "КОЦЕЉЕВА",
        value: "КОЦЕЉЕВА",
    },
    {
        label: "БАТАЛАГЕ",
        value: "БАТАЛАГЕ",
    },
    {
        label: "БРДАРИЦА",
        value: "БРДАРИЦА",
    },
    {
        label: "ГАЛОВИЋ",
        value: "ГАЛОВИЋ",
    },
    {
        label: "ГОЛОЧЕЛО",
        value: "ГОЛОЧЕЛО",
    },
    {
        label: "ГРАДОЈЕВИЋ",
        value: "ГРАДОЈЕВИЋ",
    },
    {
        label: "ДОЊЕ ЦРНИЉЕВО",
        value: "ДОЊЕ ЦРНИЉЕВО",
    },
    {
        label: "ДРАГИЊЕ",
        value: "ДРАГИЊЕ",
    },
    {
        label: "ДРУЖЕТИЋ",
        value: "ДРУЖЕТИЋ",
    },
    {
        label: "ЗУКВЕ",
        value: "ЗУКВЕ",
    },
    {
        label: "КОЦЕЉЕВА-ВАРОШИЦА",
        value: "КОЦЕЉЕВА-ВАРОШИЦА",
    },
    {
        label: "ЉУТИЦЕ",
        value: "ЉУТИЦЕ",
    },
    {
        label: "МАЛИ БОШЊАК",
        value: "МАЛИ БОШЊАК",
    },
    {
        label: "СВИЛЕУВА",
        value: "СВИЛЕУВА",
    },
    {
        label: "ЋУКОВИНЕ",
        value: "ЋУКОВИНЕ",
    },
    {
        label: "КРАГУЈЕВАЦ",
        value: "КРАГУЈЕВАЦ",
    },
    {
        label: "АЏИНЕ ЛИВАДЕ",
        value: "АЏИНЕ ЛИВАДЕ",
    },
    {
        label: "БАЉКОВАЦ",
        value: "БАЉКОВАЦ",
    },
    {
        label: "БОТУЊЕ",
        value: "БОТУЊЕ",
    },
    {
        label: "БУКОРОВАЦ",
        value: "БУКОРОВАЦ",
    },
    {
        label: "ВЕЛИКА СУГУБИНА",
        value: "ВЕЛИКА СУГУБИНА",
    },
    {
        label: "ВЕЛИКЕ ПЧЕЛИЦЕ",
        value: "ВЕЛИКЕ ПЧЕЛИЦЕ",
    },
    {
        label: "ВЕЛИКИ ШЕЊ",
        value: "ВЕЛИКИ ШЕЊ",
    },
    {
        label: "ВИЊИШТЕ",
        value: "ВИЊИШТЕ",
    },
    {
        label: "ВЛАКЧА",
        value: "ВЛАКЧА",
    },
    {
        label: "ГОРЊА САБАНТА",
        value: "ГОРЊА САБАНТА",
    },
    {
        label: "ГОРЊЕ ГРБИЦЕ I",
        value: "ГОРЊЕ ГРБИЦЕ I",
    },
    {
        label: "ГОРЊЕ ГРБИЦЕ II",
        value: "ГОРЊЕ ГРБИЦЕ II",
    },
    {
        label: "ГОРЊЕ ЈАРУШИЦЕ",
        value: "ГОРЊЕ ЈАРУШИЦЕ",
    },
    {
        label: "ГОРЊЕ КОМАРИЦЕ",
        value: "ГОРЊЕ КОМАРИЦЕ",
    },
    {
        label: "ГРОШНИЦА I",
        value: "ГРОШНИЦА I",
    },
    {
        label: "ГРОШНИЦА II",
        value: "ГРОШНИЦА II",
    },
    {
        label: "ДЕСИМИРОВАЦ",
        value: "ДЕСИМИРОВАЦ",
    },
    {
        label: "ДИВОСТИН",
        value: "ДИВОСТИН",
    },
    {
        label: "ДОБРАЧА",
        value: "ДОБРАЧА",
    },
    {
        label: "ДОЊА САБАНТА",
        value: "ДОЊА САБАНТА",
    },
    {
        label: "ДОЊЕ ГРБИЦЕ",
        value: "ДОЊЕ ГРБИЦЕ",
    },
    {
        label: "ДОЊЕ КОМАРИЦЕ",
        value: "ДОЊЕ КОМАРИЦЕ",
    },
    {
        label: "ДРАГОБРАЋА",
        value: "ДРАГОБРАЋА",
    },
    {
        label: "ДРАЧА",
        value: "ДРАЧА",
    },
    {
        label: "ДУЛЕНЕ",
        value: "ДУЛЕНЕ",
    },
    {
        label: "ЂУРИСЕЛО",
        value: "ЂУРИСЕЛО",
    },
    {
        label: "ЕРДЕЧ",
        value: "ЕРДЕЧ",
    },
    {
        label: "ЈАБУЧЈЕ",
        value: "ЈАБУЧЈЕ",
    },
    {
        label: "ЈОВАНОВАЦ",
        value: "ЈОВАНОВАЦ",
    },
    {
        label: "КОТРАЖА",
        value: "КОТРАЖА",
    },
    {
        label: "КРАГУЈЕВАЦ I",
        value: "КРАГУЈЕВАЦ I",
    },
    {
        label: "КРАГУЈЕВАЦ II",
        value: "КРАГУЈЕВАЦ II",
    },
    {
        label: "КРАГУЈЕВАЦ III",
        value: "КРАГУЈЕВАЦ III",
    },
    {
        label: "КРАГУЈЕВАЦ IV",
        value: "КРАГУЈЕВАЦ IV",
    },
    {
        label: "КУТЛОВО",
        value: "КУТЛОВО",
    },
    {
        label: "ЛУЖНИЦЕ",
        value: "ЛУЖНИЦЕ",
    },
    {
        label: "МАЛИ ШЕЊ",
        value: "МАЛИ ШЕЊ",
    },
    {
        label: "МАРШИЋ",
        value: "МАРШИЋ",
    },
    {
        label: "МАСЛОШЕВО",
        value: "МАСЛОШЕВО",
    },
    {
        label: "МИРОНИЋ",
        value: "МИРОНИЋ",
    },
    {
        label: "НОВИ МИЛАНОВАЦ",
        value: "НОВИ МИЛАНОВАЦ",
    },
    {
        label: "ОПОРНИЦА",
        value: "ОПОРНИЦА",
    },
    {
        label: "ПАЈАЗИТОВО",
        value: "ПАЈАЗИТОВО",
    },
    {
        label: "ПОСКУРИЦЕ",
        value: "ПОСКУРИЦЕ",
    },
    {
        label: "ПРЕКОПЕЧА",
        value: "ПРЕКОПЕЧА",
    },
    {
        label: "РАМАЋА",
        value: "РАМАЋА",
    },
    {
        label: "РОГОЈЕВАЦ",
        value: "РОГОЈЕВАЦ",
    },
    {
        label: "СТРАГАРИ",
        value: "СТРАГАРИ",
    },
    {
        label: "ТРЕШЊЕВАК",
        value: "ТРЕШЊЕВАК",
    },
    {
        label: "ТРМБАС",
        value: "ТРМБАС",
    },
    {
        label: "УГЉАРЕВАЦ",
        value: "УГЉАРЕВАЦ",
    },
    {
        label: "ЦВЕТОЈЕВАЦ",
        value: "ЦВЕТОЈЕВАЦ",
    },
    {
        label: "ЦЕРОВАЦ",
        value: "ЦЕРОВАЦ",
    },
    {
        label: "ЧУМИЋ",
        value: "ЧУМИЋ",
    },
    {
        label: "ШЉИВОВАЦ",
        value: "ШЉИВОВАЦ",
    },
    {
        label: "АДРАНИ",
        value: "АДРАНИ",
    },
    {
        label: "БАПСКО ПОЉЕ",
        value: "БАПСКО ПОЉЕ",
    },
    {
        label: "БЗОВИК",
        value: "БЗОВИК",
    },
    {
        label: "БОГУТОВАЦ",
        value: "БОГУТОВАЦ",
    },
    {
        label: "БРЕСНИК",
        value: "БРЕСНИК",
    },
    {
        label: "БУКОВИЦА",
        value: "БУКОВИЦА",
    },
    {
        label: "ВРДИЛА",
        value: "ВРДИЛА",
    },
    {
        label: "ВРХ",
        value: "ВРХ",
    },
    {
        label: "ГЛЕДИЋ",
        value: "ГЛЕДИЋ",
    },
    {
        label: "ГОДАЧИЦА",
        value: "ГОДАЧИЦА",
    },
    {
        label: "ГОКЧАНИЦА",
        value: "ГОКЧАНИЦА",
    },
    {
        label: "ГОТОВАЦ",
        value: "ГОТОВАЦ",
    },
    {
        label: "ГРДИЦА",
        value: "ГРДИЦА",
    },
    {
        label: "ДЕДЕВЦИ",
        value: "ДЕДЕВЦИ",
    },
    {
        label: "ДРАГОСИЊЦИ",
        value: "ДРАГОСИЊЦИ",
    },
    {
        label: "ДРАЖИНИЋЕ",
        value: "ДРАЖИНИЋЕ",
    },
    {
        label: "ДРАКЧИЋИ",
        value: "ДРАКЧИЋИ",
    },
    {
        label: "ДРЛУПА",
        value: "ДРЛУПА",
    },
    {
        label: "ЂАКОВО",
        value: "ЂАКОВО",
    },
    {
        label: "ЗАКУТА",
        value: "ЗАКУТА",
    },
    {
        label: "ЗАМЧАЊЕ",
        value: "ЗАМЧАЊЕ",
    },
    {
        label: "ЗАСАД",
        value: "ЗАСАД",
    },
    {
        label: "ЈАРЧУЈАК",
        value: "ЈАРЧУЈАК",
    },
    {
        label: "КОВАНЛУК",
        value: "КОВАНЛУК",
    },
    {
        label: "КОВАЧИ",
        value: "КОВАЧИ",
    },
    {
        label: "КОНАРЕВО",
        value: "КОНАРЕВО",
    },
    {
        label: "ЛАЂЕВЦИ",
        value: "ЛАЂЕВЦИ",
    },
    {
        label: "ЛАЗАЦ",
        value: "ЛАЗАЦ",
    },
    {
        label: "ЛЕШЕВО",
        value: "ЛЕШЕВО",
    },
    {
        label: "ЛОПАТНИЦА",
        value: "ЛОПАТНИЦА",
    },
    {
        label: "МАГЛИЧ",
        value: "МАГЛИЧ",
    },
    {
        label: "МАТАРУГЕ",
        value: "МАТАРУГЕ",
    },
    {
        label: "МЕЉАНИЦА",
        value: "МЕЉАНИЦА",
    },
    {
        label: "МЕТИКОШ",
        value: "МЕТИКОШ",
    },
    {
        label: "МИЛАВЧИЋИ",
        value: "МИЛАВЧИЋИ",
    },
    {
        label: "МИЛАКОВАЦ",
        value: "МИЛАКОВАЦ",
    },
    {
        label: "МИЛИЋЕ",
        value: "МИЛИЋЕ",
    },
    {
        label: "МИЛОЧАЈ",
        value: "МИЛОЧАЈ",
    },
    {
        label: "МЛАНЧА",
        value: "МЛАНЧА",
    },
    {
        label: "МРСАЋ",
        value: "МРСАЋ",
    },
    {
        label: "МУСИНА РЕКА",
        value: "МУСИНА РЕКА",
    },
    {
        label: "ОБРВА",
        value: "ОБРВА",
    },
    {
        label: "ОПЛАНИЋИ",
        value: "ОПЛАНИЋИ",
    },
    {
        label: "ОРЉА ГЛАВА",
        value: "ОРЉА ГЛАВА",
    },
    {
        label: "ПЕКЧАНИЦА",
        value: "ПЕКЧАНИЦА",
    },
    {
        label: "ПЕТРОПОЉЕ",
        value: "ПЕТРОПОЉЕ",
    },
    {
        label: "ПЕЧЕНОГ",
        value: "ПЕЧЕНОГ",
    },
    {
        label: "ПЛАНА",
        value: "ПЛАНА",
    },
    {
        label: "ПОЛУМИР",
        value: "ПОЛУМИР",
    },
    {
        label: "ПОПОВИЋИ",
        value: "ПОПОВИЋИ",
    },
    {
        label: "ПРЕДОЛЕ",
        value: "ПРЕДОЛЕ",
    },
    {
        label: "ПРОГОРЕЛИЦА",
        value: "ПРОГОРЕЛИЦА",
    },
    {
        label: "РАВАНИЦА",
        value: "РАВАНИЦА",
    },
    {
        label: "РАТИНА",
        value: "РАТИНА",
    },
    {
        label: "РИБНИЦА",
        value: "РИБНИЦА",
    },
    {
        label: "РОЋЕВИЋИ",
        value: "РОЋЕВИЋИ",
    },
    {
        label: "РУДНО",
        value: "РУДНО",
    },
    {
        label: "РУДЊАК",
        value: "РУДЊАК",
    },
    {
        label: "САВОВО",
        value: "САВОВО",
    },
    {
        label: "САМАИЛА",
        value: "САМАИЛА",
    },
    {
        label: "СИРЧА",
        value: "СИРЧА",
    },
    {
        label: "ТАВНИК",
        value: "ТАВНИК",
    },
    {
        label: "ТАДЕЊЕ",
        value: "ТАДЕЊЕ",
    },
    {
        label: "ТОЛИШНИЦА",
        value: "ТОЛИШНИЦА",
    },
    {
        label: "УШЋЕ",
        value: "УШЋЕ",
    },
    {
        label: "ЦВЕТКЕ",
        value: "ЦВЕТКЕ",
    },
    {
        label: "ЦЕРЈЕ",
        value: "ЦЕРЈЕ",
    },
    {
        label: "ЧИБУКОВАЦ",
        value: "ЧИБУКОВАЦ",
    },
    {
        label: "ЧУКОЈЕВАЦ",
        value: "ЧУКОЈЕВАЦ",
    },
    {
        label: "КРУПАЊ",
        value: "КРУПАЊ",
    },
    {
        label: "БАЊЕВАЦ",
        value: "БАЊЕВАЦ",
    },
    {
        label: "БОГОШТИЦА",
        value: "БОГОШТИЦА",
    },
    {
        label: "БРШТИЦА",
        value: "БРШТИЦА",
    },
    {
        label: "ВРБИЋ",
        value: "ВРБИЋ",
    },
    {
        label: "ДВОРСКА",
        value: "ДВОРСКА",
    },
    {
        label: "ЗАВЛАКА",
        value: "ЗАВЛАКА",
    },
    {
        label: "КОСТАЈНИК",
        value: "КОСТАЈНИК",
    },
    {
        label: "КРАСАВА",
        value: "КРАСАВА",
    },
    {
        label: "КРЖАВА",
        value: "КРЖАВА",
    },
    {
        label: "ЛИКОДРА",
        value: "ЛИКОДРА",
    },
    {
        label: "ЛИПЕНОВИЋ I",
        value: "ЛИПЕНОВИЋ I",
    },
    {
        label: "ЛИПЕНОВИЋ II",
        value: "ЛИПЕНОВИЋ II",
    },
    {
        label: "МОЈКОВИЋ",
        value: "МОЈКОВИЋ",
    },
    {
        label: "ПЛАНИНА",
        value: "ПЛАНИНА",
    },
    {
        label: "РАВНАЈА",
        value: "РАВНАЈА",
    },
    {
        label: "СТАВЕ",
        value: "СТАВЕ",
    },
    {
        label: "ТОЛИСАВАЦ",
        value: "ТОЛИСАВАЦ",
    },
    {
        label: "ТОМАЊ",
        value: "ТОМАЊ",
    },
    {
        label: "ЦВЕТУЉА",
        value: "ЦВЕТУЉА",
    },
    {
        label: "ШЉИВОВА",
        value: "ШЉИВОВА",
    },
    {
        label: "КРУШЕВАЦ",
        value: "КРУШЕВАЦ",
    },
    {
        label: "БЕЛА ВОДА",
        value: "БЕЛА ВОДА",
    },
    {
        label: "БЕЛАСИЦА",
        value: "БЕЛАСИЦА",
    },
    {
        label: "БИВОЉЕ",
        value: "БИВОЉЕ",
    },
    {
        label: "БРАЈКОВАЦ",
        value: "БРАЈКОВАЦ",
    },
    {
        label: "БУЦИ",
        value: "БУЦИ",
    },
    {
        label: "ВЕЛИКА КРУШЕВИЦА",
        value: "ВЕЛИКА КРУШЕВИЦА",
    },
    {
        label: "ВЕЛИКИ ШИЉЕГОВАЦ",
        value: "ВЕЛИКИ ШИЉЕГОВАЦ",
    },
    {
        label: "ВЕЛИКО ГОЛОВОДЕ",
        value: "ВЕЛИКО ГОЛОВОДЕ",
    },
    {
        label: "ВРАТАРЕ",
        value: "ВРАТАРЕ",
    },
    {
        label: "ГАВЕЗ",
        value: "ГАВЕЗ",
    },
    {
        label: "ГАГЛОВО",
        value: "ГАГЛОВО",
    },
    {
        label: "ГАРИ",
        value: "ГАРИ",
    },
    {
        label: "ГЛОБАРЕ",
        value: "ГЛОБАРЕ",
    },
    {
        label: "ГЛОБОДЕР",
        value: "ГЛОБОДЕР",
    },
    {
        label: "ГОРЊИ СТЕПОШ",
        value: "ГОРЊИ СТЕПОШ",
    },
    {
        label: "ГРЕВЦИ",
        value: "ГРЕВЦИ",
    },
    {
        label: "ГРКЉАНЕ",
        value: "ГРКЉАНЕ",
    },
    {
        label: "ДВОРАНЕ",
        value: "ДВОРАНЕ",
    },
    {
        label: "ДЕДИНА",
        value: "ДЕДИНА",
    },
    {
        label: "ДОЉАНЕ",
        value: "ДОЉАНЕ",
    },
    {
        label: "ДОЊИ СТЕПОШ",
        value: "ДОЊИ СТЕПОШ",
    },
    {
        label: "ЂУНИС",
        value: "ЂУНИС",
    },
    {
        label: "ЗДРАВИЊЕ",
        value: "ЗДРАВИЊЕ",
    },
    {
        label: "ЗЕБИЦА",
        value: "ЗЕБИЦА",
    },
    {
        label: "ЗУБОВАЦ",
        value: "ЗУБОВАЦ",
    },
    {
        label: "ЈАСИКА",
        value: "ЈАСИКА",
    },
    {
        label: "ЈОШЈЕ",
        value: "ЈОШЈЕ",
    },
    {
        label: "КАМЕНАРЕ",
        value: "КАМЕНАРЕ",
    },
    {
        label: "КАОНИК",
        value: "КАОНИК",
    },
    {
        label: "КАПИЏИЈА",
        value: "КАПИЏИЈА",
    },
    {
        label: "КОМОРАНЕ",
        value: "КОМОРАНЕ",
    },
    {
        label: "КОЊУХ",
        value: "КОЊУХ",
    },
    {
        label: "КРВАВИЦА",
        value: "КРВАВИЦА",
    },
    {
        label: "КРУШИНЦЕ",
        value: "КРУШИНЦЕ",
    },
    {
        label: "КУКЉИН",
        value: "КУКЉИН",
    },
    {
        label: "КУПЦИ",
        value: "КУПЦИ",
    },
    {
        label: "ЛАЗАРИЦА",
        value: "ЛАЗАРИЦА",
    },
    {
        label: "ЉУБАВА",
        value: "ЉУБАВА",
    },
    {
        label: "МАЈДЕВО",
        value: "МАЈДЕВО",
    },
    {
        label: "МАКРЕШАНЕ",
        value: "МАКРЕШАНЕ",
    },
    {
        label: "МАЛИ ШИЉЕГОВАЦ",
        value: "МАЛИ ШИЉЕГОВАЦ",
    },
    {
        label: "МАЛО ГОЛОВОДЕ",
        value: "МАЛО ГОЛОВОДЕ",
    },
    {
        label: "МЕШЕВО",
        value: "МЕШЕВО",
    },
    {
        label: "МОДРИЦА",
        value: "МОДРИЦА",
    },
    {
        label: "МУДРАКОВАЦ",
        value: "МУДРАКОВАЦ",
    },
    {
        label: "НАУПАРЕ",
        value: "НАУПАРЕ",
    },
    {
        label: "ПАДЕЖ",
        value: "ПАДЕЖ",
    },
    {
        label: "ПАКАШНИЦА",
        value: "ПАКАШНИЦА",
    },
    {
        label: "ПАРУНОВАЦ",
        value: "ПАРУНОВАЦ",
    },
    {
        label: "ПАСЈАК",
        value: "ПАСЈАК",
    },
    {
        label: "ПЕПЕЉЕВАЦ",
        value: "ПЕПЕЉЕВАЦ",
    },
    {
        label: "ПЕТИНА",
        value: "ПЕТИНА",
    },
    {
        label: "ПОЗЛАТА",
        value: "ПОЗЛАТА",
    },
    {
        label: "ПОЉАЦИ",
        value: "ПОЉАЦИ",
    },
    {
        label: "РЛИЦА",
        value: "РЛИЦА",
    },
    {
        label: "РОСИЦА",
        value: "РОСИЦА",
    },
    {
        label: "СЕБЕЧЕВАЦ",
        value: "СЕБЕЧЕВАЦ",
    },
    {
        label: "СЕЗЕМЧЕ",
        value: "СЕЗЕМЧЕ",
    },
    {
        label: "СРНДАЉЕ",
        value: "СРНДАЉЕ",
    },
    {
        label: "СРЊЕ",
        value: "СРЊЕ",
    },
    {
        label: "ТРЕБОТИН",
        value: "ТРЕБОТИН",
    },
    {
        label: "ТРМЧАРЕ",
        value: "ТРМЧАРЕ",
    },
    {
        label: "ЦРКВИНА",
        value: "ЦРКВИНА",
    },
    {
        label: "ЧИТЛУК",
        value: "ЧИТЛУК",
    },
    {
        label: "ШАВРАНЕ",
        value: "ШАВРАНЕ",
    },
    {
        label: "ШАНАЦ",
        value: "ШАНАЦ",
    },
    {
        label: "ШАШИЛОВАЦ",
        value: "ШАШИЛОВАЦ",
    },
    {
        label: "ШОГОЉ",
        value: "ШОГОЉ",
    },
    {
        label: "ШТИТАРЕ",
        value: "ШТИТАРЕ",
    },
    {
        label: "КУЛА",
        value: "КУЛА",
    },
    {
        label: "КРУШЧИЋ",
        value: "КРУШЧИЋ",
    },
    {
        label: "ЛИПАР",
        value: "ЛИПАР",
    },
    {
        label: "РУСКИ КРСТУР",
        value: "РУСКИ КРСТУР",
    },
    {
        label: "СИВАЦ",
        value: "СИВАЦ",
    },
    {
        label: "ЦРВЕНКА",
        value: "ЦРВЕНКА",
    },
    {
        label: "КУРШУМЛИЈА",
        value: "КУРШУМЛИЈА",
    },
    {
        label: "БАБИЦА",
        value: "БАБИЦА",
    },
    {
        label: "БАРЛОВО",
        value: "БАРЛОВО",
    },
    {
        label: "БАЋОГЛАВА",
        value: "БАЋОГЛАВА",
    },
    {
        label: "БОГУЈЕВАЦ",
        value: "БОГУЈЕВАЦ",
    },
    {
        label: "ВАСИЉЕВАЦ",
        value: "ВАСИЉЕВАЦ",
    },
    {
        label: "ВЕЛИКО ПУПАВЦЕ",
        value: "ВЕЛИКО ПУПАВЦЕ",
    },
    {
        label: "ВЛАХИЊА",
        value: "ВЛАХИЊА",
    },
    {
        label: "ВРШЕВАЦ",
        value: "ВРШЕВАЦ",
    },
    {
        label: "ВУКОЈЕВАЦ",
        value: "ВУКОЈЕВАЦ",
    },
    {
        label: "ГОРЊА МИКУЉАНА",
        value: "ГОРЊА МИКУЉАНА",
    },
    {
        label: "ГОРЊЕ ТОЧАНЕ",
        value: "ГОРЊЕ ТОЧАНЕ",
    },
    {
        label: "ГРАБОВНИЦА",
        value: "ГРАБОВНИЦА",
    },
    {
        label: "ДАБИНОВАЦ",
        value: "ДАБИНОВАЦ",
    },
    {
        label: "ДАНКОВИЋЕ",
        value: "ДАНКОВИЋЕ",
    },
    {
        label: "ДЕГРМЕН",
        value: "ДЕГРМЕН",
    },
    {
        label: "ДЕДИНАЦ",
        value: "ДЕДИНАЦ",
    },
    {
        label: "ДЕШИШКА",
        value: "ДЕШИШКА",
    },
    {
        label: "ДОЊА МИКУЉАНА",
        value: "ДОЊА МИКУЉАНА",
    },
    {
        label: "ДОЊЕ ТОЧАНЕ",
        value: "ДОЊЕ ТОЧАНЕ",
    },
    {
        label: "ЂАКЕ",
        value: "ЂАКЕ",
    },
    {
        label: "ЖАЛИЦА",
        value: "ЖАЛИЦА",
    },
    {
        label: "ЖЕГРОВА",
        value: "ЖЕГРОВА",
    },
    {
        label: "ЖЕЉЕВА",
        value: "ЖЕЉЕВА",
    },
    {
        label: "ЖУЧ",
        value: "ЖУЧ",
    },
    {
        label: "ИВАН КУЛА",
        value: "ИВАН КУЛА",
    },
    {
        label: "ИГРИШТЕ",
        value: "ИГРИШТЕ",
    },
    {
        label: "КАСТРАТ",
        value: "КАСТРАТ",
    },
    {
        label: "КОЊУВА",
        value: "КОЊУВА",
    },
    {
        label: "КОСМАЧА",
        value: "КОСМАЧА",
    },
    {
        label: "КРТОК",
        value: "КРТОК",
    },
    {
        label: "КРЧМАРЕ",
        value: "КРЧМАРЕ",
    },
    {
        label: "КУПИНОВО",
        value: "КУПИНОВО",
    },
    {
        label: "КУРШУМЛИЈСКА БАЊА",
        value: "КУРШУМЛИЈСКА БАЊА",
    },
    {
        label: "ЉУТОВА",
        value: "ЉУТОВА",
    },
    {
        label: "ЉУША",
        value: "ЉУША",
    },
    {
        label: "МАГОВО",
        value: "МАГОВО",
    },
    {
        label: "МАЛА КОСАНИЦА",
        value: "МАЛА КОСАНИЦА",
    },
    {
        label: "МАРИЧИЋЕ",
        value: "МАРИЧИЋЕ",
    },
    {
        label: "МАРКОВИЋЕ",
        value: "МАРКОВИЋЕ",
    },
    {
        label: "МАТАРОВА",
        value: "МАТАРОВА",
    },
    {
        label: "МАЧЈА СТЕНА",
        value: "МАЧЈА СТЕНА",
    },
    {
        label: "МЕРДАРЕ",
        value: "МЕРДАРЕ",
    },
    {
        label: "МЕРЋЕЗ",
        value: "МЕРЋЕЗ",
    },
    {
        label: "МЕХАНЕ",
        value: "МЕХАНЕ",
    },
    {
        label: "МИЈОЧИЋИ",
        value: "МИЈОЧИЋИ",
    },
    {
        label: "МИРНИЦА",
        value: "МИРНИЦА",
    },
    {
        label: "МРЧА",
        value: "МРЧА",
    },
    {
        label: "НЕВАДА",
        value: "НЕВАДА",
    },
    {
        label: "ОРЛОВАЦ",
        value: "ОРЛОВАЦ",
    },
    {
        label: "ПАВАШТИЦА",
        value: "ПАВАШТИЦА",
    },
    {
        label: "ПАРАДА",
        value: "ПАРАДА",
    },
    {
        label: "ПАРДУСЕ",
        value: "ПАРДУСЕ",
    },
    {
        label: "ПАЧАРАЂА",
        value: "ПАЧАРАЂА",
    },
    {
        label: "ПЕРУНИКА",
        value: "ПЕРУНИКА",
    },
    {
        label: "ПЉАКОВО",
        value: "ПЉАКОВО",
    },
    {
        label: "ПРЕВЕТИЦА",
        value: "ПРЕВЕТИЦА",
    },
    {
        label: "ПРЕКОРАЂА",
        value: "ПРЕКОРАЂА",
    },
    {
        label: "ПРОЛОМ",
        value: "ПРОЛОМ",
    },
    {
        label: "РАВНИ ШОРТ",
        value: "РАВНИ ШОРТ",
    },
    {
        label: "РАСТЕЛИЦА",
        value: "РАСТЕЛИЦА",
    },
    {
        label: "РУДАРЕ",
        value: "РУДАРЕ",
    },
    {
        label: "САГОЊЕВО",
        value: "САГОЊЕВО",
    },
    {
        label: "САМОКОВО",
        value: "САМОКОВО",
    },
    {
        label: "СВИЊИШТЕ",
        value: "СВИЊИШТЕ",
    },
    {
        label: "СЕКИРАЧА",
        value: "СЕКИРАЧА",
    },
    {
        label: "СЕЛИШТЕ",
        value: "СЕЛИШТЕ",
    },
    {
        label: "СЕЛОВА",
        value: "СЕЛОВА",
    },
    {
        label: "СЕОЦЕ",
        value: "СЕОЦЕ",
    },
    {
        label: "СПАНЦЕ",
        value: "СПАНЦЕ",
    },
    {
        label: "ТАЧЕВАЦ",
        value: "ТАЧЕВАЦ",
    },
    {
        label: "ТИЈОВАЦ",
        value: "ТИЈОВАЦ",
    },
    {
        label: "ТМАВА",
        value: "ТМАВА",
    },
    {
        label: "ТРЕБИЊЕ",
        value: "ТРЕБИЊЕ",
    },
    {
        label: "ТРЕЋАК",
        value: "ТРЕЋАК",
    },
    {
        label: "ТРЕШЊИЦА",
        value: "ТРЕШЊИЦА",
    },
    {
        label: "ТРМКА",
        value: "ТРМКА",
    },
    {
        label: "ТРН",
        value: "ТРН",
    },
    {
        label: "ТРПЕЗА",
        value: "ТРПЕЗА",
    },
    {
        label: "ШАТРА",
        value: "ШАТРА",
    },
    {
        label: "ШТАВА",
        value: "ШТАВА",
    },
    {
        label: "КУЧЕВО",
        value: "КУЧЕВО",
    },
    {
        label: "БЛАГОЈЕВ КАМЕН",
        value: "БЛАГОЈЕВ КАМЕН",
    },
    {
        label: "БУКОВСКА",
        value: "БУКОВСКА",
    },
    {
        label: "ВЕЛИКА БРЕСНИЦА",
        value: "ВЕЛИКА БРЕСНИЦА",
    },
    {
        label: "ВОЛУЈА",
        value: "ВОЛУЈА",
    },
    {
        label: "ВУКОВИЋ",
        value: "ВУКОВИЋ",
    },
    {
        label: "ЗЕЛЕНИК",
        value: "ЗЕЛЕНИК",
    },
    {
        label: "КУЧАЈНА",
        value: "КУЧАЈНА",
    },
    {
        label: "КУЧЕВО I",
        value: "КУЧЕВО I",
    },
    {
        label: "КУЧЕВО II",
        value: "КУЧЕВО II",
    },
    {
        label: "КУЧЕВО III",
        value: "КУЧЕВО III",
    },
    {
        label: "ЉЕШНИЦА",
        value: "ЉЕШНИЦА",
    },
    {
        label: "МАЛА БРЕСНИЦА",
        value: "МАЛА БРЕСНИЦА",
    },
    {
        label: "МИШЉЕНОВАЦ",
        value: "МИШЉЕНОВАЦ",
    },
    {
        label: "МУСТАПИЋ",
        value: "МУСТАПИЋ",
    },
    {
        label: "НЕРЕСНИЦА",
        value: "НЕРЕСНИЦА",
    },
    {
        label: "РАБРОВО",
        value: "РАБРОВО",
    },
    {
        label: "РАДЕНКА",
        value: "РАДЕНКА",
    },
    {
        label: "РАКОВА БАРА",
        value: "РАКОВА БАРА",
    },
    {
        label: "СЕНА",
        value: "СЕНА",
    },
    {
        label: "СРПЦЕ",
        value: "СРПЦЕ",
    },
    {
        label: "ЦЕРЕМОШЊА",
        value: "ЦЕРЕМОШЊА",
    },
    {
        label: "ЦЕРОВИЦА",
        value: "ЦЕРОВИЦА",
    },
    {
        label: "ШЕВИЦА",
        value: "ШЕВИЦА",
    },
    {
        label: "АРАПОВАЦ",
        value: "АРАПОВАЦ",
    },
    {
        label: "БАРЗИЛОВИЦА",
        value: "БАРЗИЛОВИЦА",
    },
    {
        label: "БАРОШЕВАЦ",
        value: "БАРОШЕВАЦ",
    },
    {
        label: "БИСТРИЦА",
        value: "БИСТРИЦА",
    },
    {
        label: "БУРОВО",
        value: "БУРОВО",
    },
    {
        label: "ВЕЛИКИ ЦРЉЕНИ",
        value: "ВЕЛИКИ ЦРЉЕНИ",
    },
    {
        label: "ВРБОВНО",
        value: "ВРБОВНО",
    },
    {
        label: "ВРЕОЦИ",
        value: "ВРЕОЦИ",
    },
    {
        label: "ДРЕН",
        value: "ДРЕН",
    },
    {
        label: "ДУДОВИЦА",
        value: "ДУДОВИЦА",
    },
    {
        label: "ЖУПАЊАЦ",
        value: "ЖУПАЊАЦ",
    },
    {
        label: "ЗЕОКЕ",
        value: "ЗЕОКЕ",
    },
    {
        label: "ЈУНКОВАЦ",
        value: "ЈУНКОВАЦ",
    },
    {
        label: "МАЛИ ЦРЉЕНИ",
        value: "МАЛИ ЦРЉЕНИ",
    },
    {
        label: "МЕДОШЕВАЦ",
        value: "МЕДОШЕВАЦ",
    },
    {
        label: "ПРКОСАВА",
        value: "ПРКОСАВА",
    },
    {
        label: "РУДОВЦИ",
        value: "РУДОВЦИ",
    },
    {
        label: "САКУЉА",
        value: "САКУЉА",
    },
    {
        label: "СОКОЛОВО",
        value: "СОКОЛОВО",
    },
    {
        label: "СТЕПОЈЕВАЦ",
        value: "СТЕПОЈЕВАЦ",
    },
    {
        label: "СТУБИЦА",
        value: "СТУБИЦА",
    },
    {
        label: "ТРБУШНИЦА",
        value: "ТРБУШНИЦА",
    },
    {
        label: "ЦВЕТОВАЦ",
        value: "ЦВЕТОВАЦ",
    },
    {
        label: "ЧИБУТКОВИЦА",
        value: "ЧИБУТКОВИЦА",
    },
    {
        label: "ШОПИЋ",
        value: "ШОПИЋ",
    },
    {
        label: "ШУШЊАР",
        value: "ШУШЊАР",
    },
    {
        label: "ЛАЈКОВАЦ",
        value: "ЛАЈКОВАЦ",
    },
    {
        label: "БАЈЕВАЦ",
        value: "БАЈЕВАЦ",
    },
    {
        label: "БОГОВАЂА",
        value: "БОГОВАЂА",
    },
    {
        label: "ВРАЧЕВИЋ",
        value: "ВРАЧЕВИЋ",
    },
    {
        label: "ДОЊИ ЛАЈКОВАЦ",
        value: "ДОЊИ ЛАЈКОВАЦ",
    },
    {
        label: "МАЛИ БОРАК",
        value: "МАЛИ БОРАК",
    },
    {
        label: "МАРКОВА ЦРКВА",
        value: "МАРКОВА ЦРКВА",
    },
    {
        label: "НЕПРИЧАВА",
        value: "НЕПРИЧАВА",
    },
    {
        label: "РАТКОВАЦ",
        value: "РАТКОВАЦ",
    },
    {
        label: "РУБРИБРЕЗА",
        value: "РУБРИБРЕЗА",
    },
    {
        label: "СКОБАЉ",
        value: "СКОБАЉ",
    },
    {
        label: "СЛОВАЦ",
        value: "СЛОВАЦ",
    },
    {
        label: "СТЕПАЊЕ",
        value: "СТЕПАЊЕ",
    },
    {
        label: "ЛАПОВО",
        value: "ЛАПОВО",
    },
    {
        label: "ЛЕБАНЕ",
        value: "ЛЕБАНЕ",
    },
    {
        label: "БАЧЕВИНА",
        value: "БАЧЕВИНА",
    },
    {
        label: "БОШЊАЦЕ",
        value: "БОШЊАЦЕ",
    },
    {
        label: "БУВЦЕ",
        value: "БУВЦЕ",
    },
    {
        label: "ВЕЛИКО ВОЈЛОВЦЕ",
        value: "ВЕЛИКО ВОЈЛОВЦЕ",
    },
    {
        label: "ГЕГЉА",
        value: "ГЕГЉА",
    },
    {
        label: "ГОЛИ РИД",
        value: "ГОЛИ РИД",
    },
    {
        label: "ГОРЊЕ ВРАНОВЦЕ",
        value: "ГОРЊЕ ВРАНОВЦЕ",
    },
    {
        label: "ГРГУРОВЦЕ",
        value: "ГРГУРОВЦЕ",
    },
    {
        label: "ДОЊЕ ВРАНОВЦЕ",
        value: "ДОЊЕ ВРАНОВЦЕ",
    },
    {
        label: "ДРВОДЕЉ",
        value: "ДРВОДЕЉ",
    },
    {
        label: "ЖДЕГЛОВО",
        value: "ЖДЕГЛОВО",
    },
    {
        label: "КЛАЈИЋ",
        value: "КЛАЈИЋ",
    },
    {
        label: "КОЊИНО",
        value: "КОЊИНО",
    },
    {
        label: "ЛАЛИНОВАЦ",
        value: "ЛАЛИНОВАЦ",
    },
    {
        label: "ЛУГАРЕ",
        value: "ЛУГАРЕ",
    },
    {
        label: "МАЛО ВОЈЛОВЦЕ",
        value: "МАЛО ВОЈЛОВЦЕ",
    },
    {
        label: "НОВА ТОПОЛА",
        value: "НОВА ТОПОЛА",
    },
    {
        label: "ПЕРТАТЕ",
        value: "ПЕРТАТЕ",
    },
    {
        label: "ПЕТРОВАЦ",
        value: "ПЕТРОВАЦ",
    },
    {
        label: "ПОПОВЦЕ",
        value: "ПОПОВЦЕ",
    },
    {
        label: "ПОРОШТИЦА",
        value: "ПОРОШТИЦА",
    },
    {
        label: "ПРЕКОПЧЕЛИЦА",
        value: "ПРЕКОПЧЕЛИЦА",
    },
    {
        label: "РАДИНОВАЦ",
        value: "РАДИНОВАЦ",
    },
    {
        label: "РАФУНА",
        value: "РАФУНА",
    },
    {
        label: "СВИЊАРИЦА",
        value: "СВИЊАРИЦА",
    },
    {
        label: "СЕКИЦОЛ",
        value: "СЕКИЦОЛ",
    },
    {
        label: "СЛИШАНЕ",
        value: "СЛИШАНЕ",
    },
    {
        label: "ТОГОЧЕВЦЕ",
        value: "ТОГОЧЕВЦЕ",
    },
    {
        label: "ЋЕНОВАЦ",
        value: "ЋЕНОВАЦ",
    },
    {
        label: "ЦЕКАВИЦА",
        value: "ЦЕКАВИЦА",
    },
    {
        label: "ШАРЦЕ",
        value: "ШАРЦЕ",
    },
    {
        label: "ШИЛОВО",
        value: "ШИЛОВО",
    },
    {
        label: "ШУМАНЕ",
        value: "ШУМАНЕ",
    },
    {
        label: "БАБИЧКО",
        value: "БАБИЧКО",
    },
    {
        label: "БАДИНЦЕ",
        value: "БАДИНЦЕ",
    },
    {
        label: "БОБИШТЕ",
        value: "БОБИШТЕ",
    },
    {
        label: "БОГОЈЕВЦЕ",
        value: "БОГОЈЕВЦЕ",
    },
    {
        label: "БОЈИШИНА",
        value: "БОЈИШИНА",
    },
    {
        label: "БОЋЕВИЦА",
        value: "БОЋЕВИЦА",
    },
    {
        label: "БРАТМИЛОВЦЕ",
        value: "БРАТМИЛОВЦЕ",
    },
    {
        label: "БРЕЈАНОВЦЕ",
        value: "БРЕЈАНОВЦЕ",
    },
    {
        label: "БРЗА",
        value: "БРЗА",
    },
    {
        label: "БРИЧЕВЉЕ",
        value: "БРИЧЕВЉЕ",
    },
    {
        label: "БУКОВА ГЛАВА",
        value: "БУКОВА ГЛАВА",
    },
    {
        label: "БУНУША",
        value: "БУНУША",
    },
    {
        label: "ВЕЛИКА БИЉАНИЦА",
        value: "ВЕЛИКА БИЉАНИЦА",
    },
    {
        label: "ВЕЛИКА КОПАШНИЦА",
        value: "ВЕЛИКА КОПАШНИЦА",
    },
    {
        label: "ВЕЛИКО ТРЊАНЕ",
        value: "ВЕЛИКО ТРЊАНЕ",
    },
    {
        label: "ВИЉЕ КОЛО",
        value: "ВИЉЕ КОЛО",
    },
    {
        label: "ВИНАРЦЕ",
        value: "ВИНАРЦЕ",
    },
    {
        label: "ВУЧЈЕ",
        value: "ВУЧЈЕ",
    },
    {
        label: "ГАГИНЦЕ",
        value: "ГАГИНЦЕ",
    },
    {
        label: "ГОРИНА",
        value: "ГОРИНА",
    },
    {
        label: "ГОРЊА ЈАЈИНА",
        value: "ГОРЊА ЈАЈИНА",
    },
    {
        label: "ГОРЊА КУПИНОВИЦА",
        value: "ГОРЊА КУПИНОВИЦА",
    },
    {
        label: "ГОРЊА ЛОКОШНИЦА",
        value: "ГОРЊА ЛОКОШНИЦА",
    },
    {
        label: "ГОРЊА СЛАТИНА",
        value: "ГОРЊА СЛАТИНА",
    },
    {
        label: "ГОРЊЕ КРАЈИНЦЕ",
        value: "ГОРЊЕ КРАЈИНЦЕ",
    },
    {
        label: "ГОРЊЕ СИНКОВЦЕ",
        value: "ГОРЊЕ СИНКОВЦЕ",
    },
    {
        label: "ГОРЊЕ СТОПАЊЕ",
        value: "ГОРЊЕ СТОПАЊЕ",
    },
    {
        label: "ГОРЊЕ ТРЊАНЕ",
        value: "ГОРЊЕ ТРЊАНЕ",
    },
    {
        label: "ГОРЊИ БУНИБРОД",
        value: "ГОРЊИ БУНИБРОД",
    },
    {
        label: "ГРАДАШНИЦА",
        value: "ГРАДАШНИЦА",
    },
    {
        label: "ГРАЈЕВЦЕ",
        value: "ГРАЈЕВЦЕ",
    },
    {
        label: "ГРАОВО",
        value: "ГРАОВО",
    },
    {
        label: "ГРДАНИЦА",
        value: "ГРДАНИЦА",
    },
    {
        label: "ГРДЕЛИЦА (ВАРОШ)",
        value: "ГРДЕЛИЦА (ВАРОШ)",
    },
    {
        label: "ГРДЕЛИЦА (СЕЛО)",
        value: "ГРДЕЛИЦА (СЕЛО)",
    },
    {
        label: "ДЕДИНА БАРА",
        value: "ДЕДИНА БАРА",
    },
    {
        label: "ДОЊА ЈАЈИНА",
        value: "ДОЊА ЈАЈИНА",
    },
    {
        label: "ДОЊА КУПИНОВИЦА",
        value: "ДОЊА КУПИНОВИЦА",
    },
    {
        label: "ДОЊА ЛОКОШНИЦА",
        value: "ДОЊА ЛОКОШНИЦА",
    },
    {
        label: "ДОЊА СЛАТИНА",
        value: "ДОЊА СЛАТИНА",
    },
    {
        label: "ДОЊЕ БРИЈАЊЕ",
        value: "ДОЊЕ БРИЈАЊЕ",
    },
    {
        label: "ДОЊЕ КРАЈИНЦЕ",
        value: "ДОЊЕ КРАЈИНЦЕ",
    },
    {
        label: "ДОЊЕ СИНКОВЦЕ",
        value: "ДОЊЕ СИНКОВЦЕ",
    },
    {
        label: "ДОЊЕ СТОПАЊЕ",
        value: "ДОЊЕ СТОПАЊЕ",
    },
    {
        label: "ДОЊЕ ТРЊАНЕ",
        value: "ДОЊЕ ТРЊАНЕ",
    },
    {
        label: "ДОЊИ БУНИБРОД",
        value: "ДОЊИ БУНИБРОД",
    },
    {
        label: "ДРАШКОВАЦ",
        value: "ДРАШКОВАЦ",
    },
    {
        label: "ДРВОДЕЉА",
        value: "ДРВОДЕЉА",
    },
    {
        label: "ДРЋЕВАЦ",
        value: "ДРЋЕВАЦ",
    },
    {
        label: "ДУШАНОВО",
        value: "ДУШАНОВО",
    },
    {
        label: "ЖАБЉАНЕ",
        value: "ЖАБЉАНЕ",
    },
    {
        label: "ЖИВКОВО",
        value: "ЖИВКОВО",
    },
    {
        label: "ЖИЖАВИЦА",
        value: "ЖИЖАВИЦА",
    },
    {
        label: "ЗАГУЖАНЕ",
        value: "ЗАГУЖАНЕ",
    },
    {
        label: "ЗАЛУЖЊЕ",
        value: "ЗАЛУЖЊЕ",
    },
    {
        label: "ЗЛОКУЋАНЕ",
        value: "ЗЛОКУЋАНЕ",
    },
    {
        label: "ЗЛОЋУДОВО",
        value: "ЗЛОЋУДОВО",
    },
    {
        label: "ЗОЉЕВО",
        value: "ЗОЉЕВО",
    },
    {
        label: "ЈАРСЕНОВО",
        value: "ЈАРСЕНОВО",
    },
    {
        label: "ЈАШУЊА",
        value: "ЈАШУЊА",
    },
    {
        label: "КАЛУЂЕРЦЕ",
        value: "КАЛУЂЕРЦЕ",
    },
    {
        label: "КАРАЂОРЂЕВАЦ",
        value: "КАРАЂОРЂЕВАЦ",
    },
    {
        label: "КАШТАВАР",
        value: "КАШТАВАР",
    },
    {
        label: "КОВАЧЕВА БАРА",
        value: "КОВАЧЕВА БАРА",
    },
    {
        label: "КОЗАРЕ",
        value: "КОЗАРЕ",
    },
    {
        label: "КОРАЋЕВАЦ",
        value: "КОРАЋЕВАЦ",
    },
    {
        label: "КРПЕЈЦЕ",
        value: "КРПЕЈЦЕ",
    },
    {
        label: "КУКУЛОВЦЕ",
        value: "КУКУЛОВЦЕ",
    },
    {
        label: "КУТЛЕШ",
        value: "КУТЛЕШ",
    },
    {
        label: "ЛИЧИН ДОЛ",
        value: "ЛИЧИН ДОЛ",
    },
    {
        label: "МАЛА БИЉАНИЦА",
        value: "МАЛА БИЉАНИЦА",
    },
    {
        label: "МАЛА КОПАШНИЦА",
        value: "МАЛА КОПАШНИЦА",
    },
    {
        label: "МАНОЈЛОВЦЕ",
        value: "МАНОЈЛОВЦЕ",
    },
    {
        label: "МЕЛОВО",
        value: "МЕЛОВО",
    },
    {
        label: "МИРОШЕВЦЕ",
        value: "МИРОШЕВЦЕ",
    },
    {
        label: "МРКОВИЦА",
        value: "МРКОВИЦА",
    },
    {
        label: "МРШТАНЕ",
        value: "МРШТАНЕ",
    },
    {
        label: "НАВАЛИН",
        value: "НАВАЛИН",
    },
    {
        label: "НАКРИВАЊ",
        value: "НАКРИВАЊ",
    },
    {
        label: "НОМАНИЦА",
        value: "НОМАНИЦА",
    },
    {
        label: "ОРАОВИЦА",
        value: "ОРАОВИЦА",
    },
    {
        label: "ОРУГЛИЦА",
        value: "ОРУГЛИЦА",
    },
    {
        label: "ПАЛИКУЋА",
        value: "ПАЛИКУЋА",
    },
    {
        label: "ПАЛОЈЦЕ",
        value: "ПАЛОЈЦЕ",
    },
    {
        label: "ПЕЧЕЊЕВЦЕ",
        value: "ПЕЧЕЊЕВЦЕ",
    },
    {
        label: "ПИСКУПОВО",
        value: "ПИСКУПОВО",
    },
    {
        label: "ПОДРИМЦЕ",
        value: "ПОДРИМЦЕ",
    },
    {
        label: "ПРЕДЕЈАНЕ (ВАРОШ)",
        value: "ПРЕДЕЈАНЕ (ВАРОШ)",
    },
    {
        label: "ПРЕДЕЈАНЕ (СЕЛО)",
        value: "ПРЕДЕЈАНЕ (СЕЛО)",
    },
    {
        label: "ПРЕСЕЧИНА",
        value: "ПРЕСЕЧИНА",
    },
    {
        label: "РАДОЊИЦА",
        value: "РАДОЊИЦА",
    },
    {
        label: "РАЗГОЈНА",
        value: "РАЗГОЈНА",
    },
    {
        label: "РАЈНО ПОЉЕ",
        value: "РАЈНО ПОЉЕ",
    },
    {
        label: "СВИРЦЕ",
        value: "СВИРЦЕ",
    },
    {
        label: "СЕЈАНИЦА",
        value: "СЕЈАНИЦА",
    },
    {
        label: "СЛАВУЈЕВЦЕ",
        value: "СЛАВУЈЕВЦЕ",
    },
    {
        label: "СМРДАН",
        value: "СМРДАН",
    },
    {
        label: "СТРОЈКОВЦЕ",
        value: "СТРОЈКОВЦЕ",
    },
    {
        label: "СТУПНИЦА",
        value: "СТУПНИЦА",
    },
    {
        label: "СУШЕВЉЕ",
        value: "СУШЕВЉЕ",
    },
    {
        label: "ТОДОРОВЦЕ",
        value: "ТОДОРОВЦЕ",
    },
    {
        label: "ТУЛОВО",
        value: "ТУЛОВО",
    },
    {
        label: "ТУПАЛОВЦЕ",
        value: "ТУПАЛОВЦЕ",
    },
    {
        label: "ТУРЕКОВАЦ",
        value: "ТУРЕКОВАЦ",
    },
    {
        label: "ЦРКОВНИЦА",
        value: "ЦРКОВНИЦА",
    },
    {
        label: "ЦРЦАВАЦ",
        value: "ЦРЦАВАЦ",
    },
    {
        label: "ЧЕКМИН",
        value: "ЧЕКМИН",
    },
    {
        label: "ЧИФЛУК РАЗГОЈНСКИ",
        value: "ЧИФЛУК РАЗГОЈНСКИ",
    },
    {
        label: "ЧУКЉЕНИК",
        value: "ЧУКЉЕНИК",
    },
    {
        label: "ШАИНОВАЦ",
        value: "ШАИНОВАЦ",
    },
    {
        label: "ШИШИНЦЕ",
        value: "ШИШИНЦЕ",
    },
    {
        label: "БАЊА КОВИЉАЧА",
        value: "БАЊА КОВИЉАЧА",
    },
    {
        label: "БРАДИЋ",
        value: "БРАДИЋ",
    },
    {
        label: "БРЊАЦ",
        value: "БРЊАЦ",
    },
    {
        label: "ВЕЛИКО СЕЛО",
        value: "ВЕЛИКО СЕЛО",
    },
    {
        label: "ВОЋЊАК",
        value: "ВОЋЊАК",
    },
    {
        label: "ГОРЊА БАДАЊА",
        value: "ГОРЊА БАДАЊА",
    },
    {
        label: "ГОРЊА БОРИНА",
        value: "ГОРЊА БОРИНА",
    },
    {
        label: "ГОРЊА СИПУЉА",
        value: "ГОРЊА СИПУЉА",
    },
    {
        label: "ГОРЊЕ НЕДЕЉИЦЕ",
        value: "ГОРЊЕ НЕДЕЉИЦЕ",
    },
    {
        label: "ГОРЊИ ДОБРИЋ",
        value: "ГОРЊИ ДОБРИЋ",
    },
    {
        label: "ГРНЧАРА",
        value: "ГРНЧАРА",
    },
    {
        label: "ДОЊА БАДАЊА",
        value: "ДОЊА БАДАЊА",
    },
    {
        label: "ДОЊА СИПУЉА",
        value: "ДОЊА СИПУЉА",
    },
    {
        label: "ДОЊЕ НЕДЕЉИЦЕ",
        value: "ДОЊЕ НЕДЕЉИЦЕ",
    },
    {
        label: "ДОЊИ ДОБРИЋ",
        value: "ДОЊИ ДОБРИЋ",
    },
    {
        label: "ЗАЈАЧА",
        value: "ЗАЈАЧА",
    },
    {
        label: "ЈАДРАНСКА ЛЕШНИЦА",
        value: "ЈАДРАНСКА ЛЕШНИЦА",
    },
    {
        label: "ЈАРЕБИЦЕ",
        value: "ЈАРЕБИЦЕ",
    },
    {
        label: "ЈЕЛАВ",
        value: "ЈЕЛАВ",
    },
    {
        label: "ЈУГОВИЋИ",
        value: "ЈУГОВИЋИ",
    },
    {
        label: "КОЗЈАК",
        value: "КОЗЈАК",
    },
    {
        label: "КОРЕНИТА",
        value: "КОРЕНИТА",
    },
    {
        label: "ЛЕШНИЦА",
        value: "ЛЕШНИЦА",
    },
    {
        label: "МИЛИНА",
        value: "МИЛИНА",
    },
    {
        label: "ПАСКОВАЦ",
        value: "ПАСКОВАЦ",
    },
    {
        label: "ПОМИЈАЧА",
        value: "ПОМИЈАЧА",
    },
    {
        label: "РИБАРИЦЕ",
        value: "РИБАРИЦЕ",
    },
    {
        label: "РУЊАНИ",
        value: "РУЊАНИ",
    },
    {
        label: "СИМИНО БРДО",
        value: "СИМИНО БРДО",
    },
    {
        label: "ТЕКЕРИШ",
        value: "ТЕКЕРИШ",
    },
    {
        label: "ТРБОСИЉЕ",
        value: "ТРБОСИЉЕ",
    },
    {
        label: "ТРШИЋ",
        value: "ТРШИЋ",
    },
    {
        label: "ФИЛИПОВИЋИ",
        value: "ФИЛИПОВИЋИ",
    },
    {
        label: "ЧОКЕШИНА",
        value: "ЧОКЕШИНА",
    },
    {
        label: "ШОР",
        value: "ШОР",
    },
    {
        label: "ШУРИЦЕ",
        value: "ШУРИЦЕ",
    },
    {
        label: "ЛУЧАНИ",
        value: "ЛУЧАНИ",
    },
    {
        label: "БЕЛИ КАМЕН",
        value: "БЕЛИ КАМЕН",
    },
    {
        label: "ВИЧА",
        value: "ВИЧА",
    },
    {
        label: "ВЛАСТЕЉИЦЕ",
        value: "ВЛАСТЕЉИЦЕ",
    },
    {
        label: "ГОРАЧИЋИ",
        value: "ГОРАЧИЋИ",
    },
    {
        label: "ГОРЊА КРАВАРИЦА",
        value: "ГОРЊА КРАВАРИЦА",
    },
    {
        label: "ГОРЊИ ДУБАЦ",
        value: "ГОРЊИ ДУБАЦ",
    },
    {
        label: "ГРАБ",
        value: "ГРАБ",
    },
    {
        label: "ГУБЕРЕВЦИ",
        value: "ГУБЕРЕВЦИ",
    },
    {
        label: "ГУЧА",
        value: "ГУЧА",
    },
    {
        label: "ДЉИН",
        value: "ДЉИН",
    },
    {
        label: "ДОЊА КРАВАРИЦА",
        value: "ДОЊА КРАВАРИЦА",
    },
    {
        label: "ДОЊИ ДУБАЦ",
        value: "ДОЊИ ДУБАЦ",
    },
    {
        label: "ДУЧАЛОВИЋИ",
        value: "ДУЧАЛОВИЋИ",
    },
    {
        label: "ЂЕРАЂ",
        value: "ЂЕРАЂ",
    },
    {
        label: "ЖИВИЦА",
        value: "ЖИВИЦА",
    },
    {
        label: "КРСТАЦ",
        value: "КРСТАЦ",
    },
    {
        label: "ЛИС",
        value: "ЛИС",
    },
    {
        label: "ЛИСИЦЕ",
        value: "ЛИСИЦЕ",
    },
    {
        label: "МАРКОВИЦА",
        value: "МАРКОВИЦА",
    },
    {
        label: "МИЛАТОВИЋИ",
        value: "МИЛАТОВИЋИ",
    },
    {
        label: "НЕГРИШОРИ",
        value: "НЕГРИШОРИ",
    },
    {
        label: "ПУХОВО",
        value: "ПУХОВО",
    },
    {
        label: "ПШАНИК",
        value: "ПШАНИК",
    },
    {
        label: "РОГАЧА",
        value: "РОГАЧА",
    },
    {
        label: "РТАРИ",
        value: "РТАРИ",
    },
    {
        label: "РТИ",
        value: "РТИ",
    },
    {
        label: "ТИЈАЊЕ",
        value: "ТИЈАЊЕ",
    },
    {
        label: "ТУРИЦА",
        value: "ТУРИЦА",
    },
    {
        label: "ЉИГ",
        value: "ЉИГ",
    },
    {
        label: "БА",
        value: "БА",
    },
    {
        label: "БАБАЈИЋ",
        value: "БАБАЈИЋ",
    },
    {
        label: "БЕЛАНОВИЦА",
        value: "БЕЛАНОВИЦА",
    },
    {
        label: "БОШЊАНОВИЋ",
        value: "БОШЊАНОВИЋ",
    },
    {
        label: "БРАНЧИЋ",
        value: "БРАНЧИЋ",
    },
    {
        label: "ВЕЛИШЕВАЦ",
        value: "ВЕЛИШЕВАЦ",
    },
    {
        label: "ГУКОШ",
        value: "ГУКОШ",
    },
    {
        label: "ДИЋИ",
        value: "ДИЋИ",
    },
    {
        label: "ДОЊИ БАЊАНИ",
        value: "ДОЊИ БАЊАНИ",
    },
    {
        label: "ЖИВКОВЦИ",
        value: "ЖИВКОВЦИ",
    },
    {
        label: "ИВАНОВЦИ",
        value: "ИВАНОВЦИ",
    },
    {
        label: "ЈАЈЧИЋ",
        value: "ЈАЈЧИЋ",
    },
    {
        label: "КАДИНА ЛУКА",
        value: "КАДИНА ЛУКА",
    },
    {
        label: "КАЛАЊЕВЦИ",
        value: "КАЛАЊЕВЦИ",
    },
    {
        label: "КОЗЕЉ",
        value: "КОЗЕЉ",
    },
    {
        label: "ЛАЛИНЦИ",
        value: "ЛАЛИНЦИ",
    },
    {
        label: "ЛАТКОВИЋ",
        value: "ЛАТКОВИЋ",
    },
    {
        label: "ЛИПЉЕ",
        value: "ЛИПЉЕ",
    },
    {
        label: "МОРАВЦИ",
        value: "МОРАВЦИ",
    },
    {
        label: "ПАЛЕЖНИЦА",
        value: "ПАЛЕЖНИЦА",
    },
    {
        label: "ПОЉАНИЦЕ",
        value: "ПОЉАНИЦЕ",
    },
    {
        label: "СЛАВКОВИЦА",
        value: "СЛАВКОВИЦА",
    },
    {
        label: "ЦВЕТАНОВАЦ",
        value: "ЦВЕТАНОВАЦ",
    },
    {
        label: "ШТАВИЦА",
        value: "ШТАВИЦА",
    },
    {
        label: "ШУТЦИ",
        value: "ШУТЦИ",
    },
    {
        label: "ЉУБОВИЈА",
        value: "ЉУБОВИЈА",
    },
    {
        label: "ГОРЊА ЉУБОВИЂА",
        value: "ГОРЊА ЉУБОВИЂА",
    },
    {
        label: "ГОРЊА ОРОВИЦА",
        value: "ГОРЊА ОРОВИЦА",
    },
    {
        label: "ГОРЊЕ КОШЉЕ",
        value: "ГОРЊЕ КОШЉЕ",
    },
    {
        label: "ГРАЧАНИЦА",
        value: "ГРАЧАНИЦА",
    },
    {
        label: "ГРЧИЋ",
        value: "ГРЧИЋ",
    },
    {
        label: "ДОЊА ЉУБОВИЂА",
        value: "ДОЊА ЉУБОВИЂА",
    },
    {
        label: "ДОЊА ОРОВИЦА",
        value: "ДОЊА ОРОВИЦА",
    },
    {
        label: "ДОЊЕ КОШЉЕ I",
        value: "ДОЊЕ КОШЉЕ I",
    },
    {
        label: "ДОЊЕ КОШЉЕ II",
        value: "ДОЊЕ КОШЉЕ II",
    },
    {
        label: "ДРЛАЧЕ",
        value: "ДРЛАЧЕ",
    },
    {
        label: "ЛЕОВИЋ",
        value: "ЛЕОВИЋ",
    },
    {
        label: "ЛОЊИН",
        value: "ЛОЊИН",
    },
    {
        label: "ПОСТЕЊЕ",
        value: "ПОСТЕЊЕ",
    },
    {
        label: "РУЈЕВАЦ",
        value: "РУЈЕВАЦ",
    },
    {
        label: "САВКОВИЋ",
        value: "САВКОВИЋ",
    },
    {
        label: "СЕЛАНАЦ",
        value: "СЕЛАНАЦ",
    },
    {
        label: "СОКОЛАЦ",
        value: "СОКОЛАЦ",
    },
    {
        label: "ТОРНИК",
        value: "ТОРНИК",
    },
    {
        label: "УЗОВНИЦА",
        value: "УЗОВНИЦА",
    },
    {
        label: "ЦРНЧА",
        value: "ЦРНЧА",
    },
    {
        label: "МАЈДАНПЕК",
        value: "МАЈДАНПЕК",
    },
    {
        label: "БОЉЕТИН",
        value: "БОЉЕТИН",
    },
    {
        label: "ВЛАОЛЕ",
        value: "ВЛАОЛЕ",
    },
    {
        label: "ГОЛУБИЊЕ",
        value: "ГОЛУБИЊЕ",
    },
    {
        label: "ДОЊИ МИЛАНОВАЦ",
        value: "ДОЊИ МИЛАНОВАЦ",
    },
    {
        label: "ЈАСИКОВО",
        value: "ЈАСИКОВО",
    },
    {
        label: "КЛОКОЧЕВАЦ",
        value: "КЛОКОЧЕВАЦ",
    },
    {
        label: "ЛЕСКОВО",
        value: "ЛЕСКОВО",
    },
    {
        label: "МИРОЧ",
        value: "МИРОЧ",
    },
    {
        label: "МОСНА",
        value: "МОСНА",
    },
    {
        label: "РУДНА ГЛАВА",
        value: "РУДНА ГЛАВА",
    },
    {
        label: "ТОПОЛНИЦА",
        value: "ТОПОЛНИЦА",
    },
    {
        label: "ЦРНАЈКА",
        value: "ЦРНАЈКА",
    },
    {
        label: "МАЛИ ЗВОРНИК",
        value: "МАЛИ ЗВОРНИК",
    },
    {
        label: "БРАСИНА",
        value: "БРАСИНА",
    },
    {
        label: "БУДИШИЋ",
        value: "БУДИШИЋ",
    },
    {
        label: "ВЕЛИКА РЕКА",
        value: "ВЕЛИКА РЕКА",
    },
    {
        label: "ДОЊА БОРИНА",
        value: "ДОЊА БОРИНА",
    },
    {
        label: "ДОЊА ТРЕШЊИЦА",
        value: "ДОЊА ТРЕШЊИЦА",
    },
    {
        label: "РАДАЉ",
        value: "РАДАЉ",
    },
    {
        label: "САКАР",
        value: "САКАР",
    },
    {
        label: "ЦУЛИНЕ",
        value: "ЦУЛИНЕ",
    },
    {
        label: "МАЛИ ИЂОШ",
        value: "МАЛИ ИЂОШ",
    },
    {
        label: "ЛОВЋЕНАЦ",
        value: "ЛОВЋЕНАЦ",
    },
    {
        label: "ФЕКЕТИЋ",
        value: "ФЕКЕТИЋ",
    },
    {
        label: "МАЛО ЦРНИЋЕ",
        value: "МАЛО ЦРНИЋЕ",
    },
    {
        label: "АЉУДОВО",
        value: "АЉУДОВО",
    },
    {
        label: "БАТУША",
        value: "БАТУША",
    },
    {
        label: "БОЖЕВАЦ",
        value: "БОЖЕВАЦ",
    },
    {
        label: "ВЕЛИКО ЦРНИЋЕ",
        value: "ВЕЛИКО ЦРНИЋЕ",
    },
    {
        label: "ВРБНИЦА",
        value: "ВРБНИЦА",
    },
    {
        label: "ЗАБРЕГА",
        value: "ЗАБРЕГА",
    },
    {
        label: "КАЛИШТЕ",
        value: "КАЛИШТЕ",
    },
    {
        label: "КРАВЉИ ДО",
        value: "КРАВЉИ ДО",
    },
    {
        label: "МАЛО ГРАДИШТЕ",
        value: "МАЛО ГРАДИШТЕ",
    },
    {
        label: "САЛАКОВАЦ",
        value: "САЛАКОВАЦ",
    },
    {
        label: "СМОЉИНАЦ",
        value: "СМОЉИНАЦ",
    },
    {
        label: "ЦРЉЕНАЦ",
        value: "ЦРЉЕНАЦ",
    },
    {
        label: "ШАПИНЕ",
        value: "ШАПИНЕ",
    },
    {
        label: "БОГУНОВАЦ",
        value: "БОГУНОВАЦ",
    },
    {
        label: "БУЧУМЕТ",
        value: "БУЧУМЕТ",
    },
    {
        label: "ВЕЛИКА БРАИНА",
        value: "ВЕЛИКА БРАИНА",
    },
    {
        label: "ВРАПЦЕ",
        value: "ВРАПЦЕ",
    },
    {
        label: "ГАЗДАРЕ",
        value: "ГАЗДАРЕ",
    },
    {
        label: "ГАЈТАН",
        value: "ГАЈТАН",
    },
    {
        label: "ГОРЊА ЛАПАШТИЦА",
        value: "ГОРЊА ЛАПАШТИЦА",
    },
    {
        label: "ГРБАВЦЕ",
        value: "ГРБАВЦЕ",
    },
    {
        label: "ГУБАВЦЕ",
        value: "ГУБАВЦЕ",
    },
    {
        label: "ГУРГУТОВО",
        value: "ГУРГУТОВО",
    },
    {
        label: "ДОЊА ЛАПАШТИЦА",
        value: "ДОЊА ЛАПАШТИЦА",
    },
    {
        label: "ДРЕНЦЕ",
        value: "ДРЕНЦЕ",
    },
    {
        label: "ЂУЛЕКАРЕ",
        value: "ЂУЛЕКАРЕ",
    },
    {
        label: "КАПИТ",
        value: "КАПИТ",
    },
    {
        label: "ЛЕЦЕ",
        value: "ЛЕЦЕ",
    },
    {
        label: "МАЛА БРАИНА",
        value: "МАЛА БРАИНА",
    },
    {
        label: "МАРОВАЦ",
        value: "МАРОВАЦ",
    },
    {
        label: "МАЋЕДОНЦЕ",
        value: "МАЋЕДОНЦЕ",
    },
    {
        label: "МЕДЕВЦЕ",
        value: "МЕДЕВЦЕ",
    },
    {
        label: "МРКОЊЕ",
        value: "МРКОЊЕ",
    },
    {
        label: "НЕГОСАВЉЕ",
        value: "НЕГОСАВЉЕ",
    },
    {
        label: "ПЕТРИЉЕ",
        value: "ПЕТРИЉЕ",
    },
    {
        label: "ПУСТО ШИЛОВО",
        value: "ПУСТО ШИЛОВО",
    },
    {
        label: "РАВНА БАЊА",
        value: "РАВНА БАЊА",
    },
    {
        label: "РЕТКОЦЕР",
        value: "РЕТКОЦЕР",
    },
    {
        label: "РУЈКОВАЦ",
        value: "РУЈКОВАЦ",
    },
    {
        label: "СИЈАРИНА",
        value: "СИЈАРИНА",
    },
    {
        label: "СИЈАРИНСКА БАЊА",
        value: "СИЈАРИНСКА БАЊА",
    },
    {
        label: "СПОНЦЕ",
        value: "СПОНЦЕ",
    },
    {
        label: "СТАРА БАЊА",
        value: "СТАРА БАЊА",
    },
    {
        label: "ТУЛАРЕ",
        value: "ТУЛАРЕ",
    },
    {
        label: "ТУПАЛЕ",
        value: "ТУПАЛЕ",
    },
    {
        label: "ЧОКОТИН",
        value: "ЧОКОТИН",
    },
    {
        label: "МЕДИЈАНА",
        value: "МЕДИЈАНА",
    },
    {
        label: "БРЗИ БРОД",
        value: "БРЗИ БРОД",
    },
    {
        label: 'НИШ "БУБАЊ"',
        value: 'НИШ "БУБАЊ"',
    },
    {
        label: 'НИШ "ЋЕЛЕ КУЛА"',
        value: 'НИШ "ЋЕЛЕ КУЛА"',
    },
    {
        label: "МЕРОШИНА",
        value: "МЕРОШИНА",
    },
    {
        label: "АЗБРЕСНИЦА",
        value: "АЗБРЕСНИЦА",
    },
    {
        label: "АЛЕКСАНДРОВО",
        value: "АЛЕКСАНДРОВО",
    },
    {
        label: "АРБАНАСЦЕ",
        value: "АРБАНАСЦЕ",
    },
    {
        label: "БАЛИЧЕВАЦ",
        value: "БАЛИЧЕВАЦ",
    },
    {
        label: "БАТУШИНАЦ",
        value: "БАТУШИНАЦ",
    },
    {
        label: "БИЉЕГ",
        value: "БИЉЕГ",
    },
    {
        label: "БРЕСТ",
        value: "БРЕСТ",
    },
    {
        label: "БУЧИЋ",
        value: "БУЧИЋ",
    },
    {
        label: "ГОРЊА РАСОВАЧА",
        value: "ГОРЊА РАСОВАЧА",
    },
    {
        label: "ДЕВЧА",
        value: "ДЕВЧА",
    },
    {
        label: "ДЕШИЛОВО",
        value: "ДЕШИЛОВО",
    },
    {
        label: "ДОЊА РАСОВАЧА",
        value: "ДОЊА РАСОВАЧА",
    },
    {
        label: "ДУДУЛАЈЦЕ",
        value: "ДУДУЛАЈЦЕ",
    },
    {
        label: "ЈУГ БОГДАНОВАЦ",
        value: "ЈУГ БОГДАНОВАЦ",
    },
    {
        label: "КОСТАДИНОВАЦ",
        value: "КОСТАДИНОВАЦ",
    },
    {
        label: "КРАЈКОВАЦ",
        value: "КРАЈКОВАЦ",
    },
    {
        label: "ЛЕПАЈА",
        value: "ЛЕПАЈА",
    },
    {
        label: "ОБЛАЧИНА",
        value: "ОБЛАЧИНА",
    },
    {
        label: "РОЖИНА",
        value: "РОЖИНА",
    },
    {
        label: "БЕРКОВАЦ",
        value: "БЕРКОВАЦ",
    },
    {
        label: "БРЕЖЂЕ",
        value: "БРЕЖЂЕ",
    },
    {
        label: "ВИРОВАЦ",
        value: "ВИРОВАЦ",
    },
    {
        label: "ВРТИГЛАВ",
        value: "ВРТИГЛАВ",
    },
    {
        label: "ГОРЊИ ЛАЈКОВАЦ",
        value: "ГОРЊИ ЛАЈКОВАЦ",
    },
    {
        label: "ГОРЊИ МУШИЋ",
        value: "ГОРЊИ МУШИЋ",
    },
    {
        label: "ГУЊИЦА",
        value: "ГУЊИЦА",
    },
    {
        label: "ДОЊИ МУШИЋ",
        value: "ДОЊИ МУШИЋ",
    },
    {
        label: "ДУЧИЋ",
        value: "ДУЧИЋ",
    },
    {
        label: "ЂУРЂЕВАЦ",
        value: "ЂУРЂЕВАЦ",
    },
    {
        label: "КЛАШНИЋ",
        value: "КЛАШНИЋ",
    },
    {
        label: "КЉУЧ",
        value: "КЉУЧ",
    },
    {
        label: "КОМАНИЦЕ",
        value: "КОМАНИЦЕ",
    },
    {
        label: "КРЧМАР",
        value: "КРЧМАР",
    },
    {
        label: "МАЉЕВИЋ",
        value: "МАЉЕВИЋ",
    },
    {
        label: "МИОНИЦА (ВАРОШ)",
        value: "МИОНИЦА (ВАРОШ)",
    },
    {
        label: "МИОНИЦА (СЕЛО)",
        value: "МИОНИЦА (СЕЛО)",
    },
    {
        label: "МРАТИШИЋ",
        value: "МРАТИШИЋ",
    },
    {
        label: "НАНОМИР",
        value: "НАНОМИР",
    },
    {
        label: "ОСЕЧЕНИЦА",
        value: "ОСЕЧЕНИЦА",
    },
    {
        label: "ПАШТРИЋ",
        value: "ПАШТРИЋ",
    },
    {
        label: "ПОПАДИЋ",
        value: "ПОПАДИЋ",
    },
    {
        label: "РАДОБИЋ",
        value: "РАДОБИЋ",
    },
    {
        label: "РАЈКОВИЋ",
        value: "РАЈКОВИЋ",
    },
    {
        label: "РАКАРИ",
        value: "РАКАРИ",
    },
    {
        label: "РОБАЈЕ",
        value: "РОБАЈЕ",
    },
    {
        label: "САНКОВИЋ",
        value: "САНКОВИЋ",
    },
    {
        label: "СТРУГАНИК",
        value: "СТРУГАНИК",
    },
    {
        label: "ТАБАНОВИЋ",
        value: "ТАБАНОВИЋ",
    },
    {
        label: "ТОДОРИН ДО",
        value: "ТОДОРИН ДО",
    },
    {
        label: "ТОЛИЋ",
        value: "ТОЛИЋ",
    },
    {
        label: "ШУШЕОКА",
        value: "ШУШЕОКА",
    },
    {
        label: "МЛАДЕНОВАЦ",
        value: "МЛАДЕНОВАЦ",
    },
    {
        label: "АМЕРИЋ",
        value: "АМЕРИЋ",
    },
    {
        label: "ВЕЛИКА ИВАНЧА",
        value: "ВЕЛИКА ИВАНЧА",
    },
    {
        label: "ВЕЛИКА КРСНА",
        value: "ВЕЛИКА КРСНА",
    },
    {
        label: "ВЛАШКА",
        value: "ВЛАШКА",
    },
    {
        label: "ГРАНИЦЕ",
        value: "ГРАНИЦЕ",
    },
    {
        label: "ДУБОНА",
        value: "ДУБОНА",
    },
    {
        label: "КОРАЋИЦА",
        value: "КОРАЋИЦА",
    },
    {
        label: "МЕЂУЛУЖЈЕ",
        value: "МЕЂУЛУЖЈЕ",
    },
    {
        label: "МЛАДЕНОВАЦ (ВАРОШ)",
        value: "МЛАДЕНОВАЦ (ВАРОШ)",
    },
    {
        label: "МЛАДЕНОВАЦ (СЕЛО)",
        value: "МЛАДЕНОВАЦ (СЕЛО)",
    },
    {
        label: "ПРУЖАТОВАЦ",
        value: "ПРУЖАТОВАЦ",
    },
    {
        label: "РАБРОВАЦ",
        value: "РАБРОВАЦ",
    },
    {
        label: "РАЈКОВАЦ",
        value: "РАЈКОВАЦ",
    },
    {
        label: "СЕНАЈА",
        value: "СЕНАЈА",
    },
    {
        label: "ШЕПШИН",
        value: "ШЕПШИН",
    },
    {
        label: "НЕГОТИН",
        value: "НЕГОТИН",
    },
    {
        label: "БРАЋЕВАЦ",
        value: "БРАЋЕВАЦ",
    },
    {
        label: "ВЕЉКОВО",
        value: "ВЕЉКОВО",
    },
    {
        label: "ВИДРОВАЦ",
        value: "ВИДРОВАЦ",
    },
    {
        label: "ВРАТНА",
        value: "ВРАТНА",
    },
    {
        label: "ГЛОГОВИЦА II",
        value: "ГЛОГОВИЦА II",
    },
    {
        label: "ДУШАНОВАЦ",
        value: "ДУШАНОВАЦ",
    },
    {
        label: "ЈАБУКОВАЦ",
        value: "ЈАБУКОВАЦ",
    },
    {
        label: "КАРБУЛОВО",
        value: "КАРБУЛОВО",
    },
    {
        label: "КОБИШНИЦА",
        value: "КОБИШНИЦА",
    },
    {
        label: "КОВИЛОВО",
        value: "КОВИЛОВО",
    },
    {
        label: "МАЛА КАМЕНИЦА",
        value: "МАЛА КАМЕНИЦА",
    },
    {
        label: "МАЛАЈНИЦА",
        value: "МАЛАЈНИЦА",
    },
    {
        label: "МИХАЈЛОВАЦ",
        value: "МИХАЈЛОВАЦ",
    },
    {
        label: "МОКРАЊЕ",
        value: "МОКРАЊЕ",
    },
    {
        label: "ПОПОВИЦА",
        value: "ПОПОВИЦА",
    },
    {
        label: "ПРАХОВО",
        value: "ПРАХОВО",
    },
    {
        label: "РАДУЈЕВАЦ",
        value: "РАДУЈЕВАЦ",
    },
    {
        label: "РАЈАЦ",
        value: "РАЈАЦ",
    },
    {
        label: "РЕЧКА",
        value: "РЕЧКА",
    },
    {
        label: "РОГЉЕВО",
        value: "РОГЉЕВО",
    },
    {
        label: "СИКОЛЕ I",
        value: "СИКОЛЕ I",
    },
    {
        label: "СИКОЛЕ II",
        value: "СИКОЛЕ II",
    },
    {
        label: "СМЕДОВАЦ",
        value: "СМЕДОВАЦ",
    },
    {
        label: "СРБОВО",
        value: "СРБОВО",
    },
    {
        label: "ТАМНИЧ",
        value: "ТАМНИЧ",
    },
    {
        label: "УРОВИЦА",
        value: "УРОВИЦА",
    },
    {
        label: "ЦРНОМАСНИЦА",
        value: "ЦРНОМАСНИЦА",
    },
    {
        label: "ЧУБРА",
        value: "ЧУБРА",
    },
    {
        label: "ШАРКАМЕН",
        value: "ШАРКАМЕН",
    },
    {
        label: "ШТУБИК I",
        value: "ШТУБИК I",
    },
    {
        label: "ШТУБИК II",
        value: "ШТУБИК II",
    },
    {
        label: "НИШКА БАЊА",
        value: "НИШКА БАЊА",
    },
    {
        label: "БАНЦАРЕВО",
        value: "БАНЦАРЕВО",
    },
    {
        label: "ГОРЊА СТУДЕНА",
        value: "ГОРЊА СТУДЕНА",
    },
    {
        label: "ДОЊА СТУДЕНА",
        value: "ДОЊА СТУДЕНА",
    },
    {
        label: "КУНОВИЦА",
        value: "КУНОВИЦА",
    },
    {
        label: "ЛАЗАРЕВО СЕЛО",
        value: "ЛАЗАРЕВО СЕЛО",
    },
    {
        label: "ПРВА КУТИНА",
        value: "ПРВА КУТИНА",
    },
    {
        label: "ПРОСЕК-МАНАСТИР",
        value: "ПРОСЕК-МАНАСТИР",
    },
    {
        label: "РАВНИ ДО",
        value: "РАВНИ ДО",
    },
    {
        label: "РАДИКИНА БАРА",
        value: "РАДИКИНА БАРА",
    },
    {
        label: "РАУТОВО",
        value: "РАУТОВО",
    },
    {
        label: "СИЋЕВО",
        value: "СИЋЕВО",
    },
    {
        label: "НОВА ВАРОШ",
        value: "НОВА ВАРОШ",
    },
    {
        label: "АКМАЧИЋИ",
        value: "АКМАЧИЋИ",
    },
    {
        label: "АМЗИЋИ",
        value: "АМЗИЋИ",
    },
    {
        label: "БЕЛА РЕКА",
        value: "БЕЛА РЕКА",
    },
    {
        label: "БОЖЕТИЋИ",
        value: "БОЖЕТИЋИ",
    },
    {
        label: "БРДО",
        value: "БРДО",
    },
    {
        label: "БУРАЂА",
        value: "БУРАЂА",
    },
    {
        label: "ВИЛОВИ",
        value: "ВИЛОВИ",
    },
    {
        label: "ВРАНЕША",
        value: "ВРАНЕША",
    },
    {
        label: "ГОРЊЕ ТРУДОВО",
        value: "ГОРЊЕ ТРУДОВО",
    },
    {
        label: "ДЕБЕЉА",
        value: "ДЕБЕЉА",
    },
    {
        label: "ДРАГЛИЦА",
        value: "ДРАГЛИЦА",
    },
    {
        label: "ДРАЖЕВИЋИ",
        value: "ДРАЖЕВИЋИ",
    },
    {
        label: "ДРМАНОВИЋИ",
        value: "ДРМАНОВИЋИ",
    },
    {
        label: "КОМАРАНИ",
        value: "КОМАРАНИ",
    },
    {
        label: "КУЋАНИ",
        value: "КУЋАНИ",
    },
    {
        label: "ЉЕПОЈЕВИЋИ",
        value: "ЉЕПОЈЕВИЋИ",
    },
    {
        label: "МИШЕВИЋИ",
        value: "МИШЕВИЋИ",
    },
    {
        label: "НЕГБИНА",
        value: "НЕГБИНА",
    },
    {
        label: "ОЈКОВИЦА",
        value: "ОЈКОВИЦА",
    },
    {
        label: "РАДИЈЕВИЋИ",
        value: "РАДИЈЕВИЋИ",
    },
    {
        label: "РАДОИЊА",
        value: "РАДОИЊА",
    },
    {
        label: "РУТОШИ",
        value: "РУТОШИ",
    },
    {
        label: "СЕНИШТА",
        value: "СЕНИШТА",
    },
    {
        label: "ТИКВА",
        value: "ТИКВА",
    },
    {
        label: "ТИСОВИЦА",
        value: "ТИСОВИЦА",
    },
    {
        label: "ТРУДОВО",
        value: "ТРУДОВО",
    },
    {
        label: "ЧЕЛИЦЕ",
        value: "ЧЕЛИЦЕ",
    },
    {
        label: "ШТИТКОВО",
        value: "ШТИТКОВО",
    },
    {
        label: "НОВА ЦРЊА",
        value: "НОВА ЦРЊА",
    },
    {
        label: "ВОЈВОДА СТЕПА",
        value: "ВОЈВОДА СТЕПА",
    },
    {
        label: "МОЛИН",
        value: "МОЛИН",
    },
    {
        label: "РАДОЈЕВО",
        value: "РАДОЈЕВО",
    },
    {
        label: "СРПСКА ЦРЊА",
        value: "СРПСКА ЦРЊА",
    },
    {
        label: "ТОБА",
        value: "ТОБА",
    },
    {
        label: "НОВИ БЕОГРАД",
        value: "НОВИ БЕОГРАД",
    },
    {
        label: "НОВИ БЕЧЕЈ",
        value: "НОВИ БЕЧЕЈ",
    },
    {
        label: "БОЧАР",
        value: "БОЧАР",
    },
    {
        label: "НОВО МИЛОШЕВО",
        value: "НОВО МИЛОШЕВО",
    },
    {
        label: "НОВИ КНЕЖЕВАЦ",
        value: "НОВИ КНЕЖЕВАЦ",
    },
    {
        label: "БАНАТСКО АРАНЂЕЛОВО",
        value: "БАНАТСКО АРАНЂЕЛОВО",
    },
    {
        label: "ЂАЛА",
        value: "ЂАЛА",
    },
    {
        label: "СРПСКИ КРСТУР",
        value: "СРПСКИ КРСТУР",
    },
    {
        label: "НОВИ ПАЗАР",
        value: "НОВИ ПАЗАР",
    },
    {
        label: "АЛУЛОВИЋЕ",
        value: "АЛУЛОВИЋЕ",
    },
    {
        label: "БАЈЕВИЦА",
        value: "БАЈЕВИЦА",
    },
    {
        label: "БАТЊИК",
        value: "БАТЊИК",
    },
    {
        label: "БЕКОВА",
        value: "БЕКОВА",
    },
    {
        label: "БЕЛЕ ВОДЕ",
        value: "БЕЛЕ ВОДЕ",
    },
    {
        label: "БОТУРОВИНА",
        value: "БОТУРОВИНА",
    },
    {
        label: "ВАРЕВО",
        value: "ВАРЕВО",
    },
    {
        label: "ВЕВЕР",
        value: "ВЕВЕР",
    },
    {
        label: "ВИДОВО",
        value: "ВИДОВО",
    },
    {
        label: "ВИТКОВИЋЕ",
        value: "ВИТКОВИЋЕ",
    },
    {
        label: "ВОЈКОВИЋЕ",
        value: "ВОЈКОВИЋЕ",
    },
    {
        label: "ВОЈНИЋЕ",
        value: "ВОЈНИЋЕ",
    },
    {
        label: "ВРАНОВИНА",
        value: "ВРАНОВИНА",
    },
    {
        label: "ВУЧИНИЋЕ",
        value: "ВУЧИНИЋЕ",
    },
    {
        label: "ВУЧЈА ЛОКВА",
        value: "ВУЧЈА ЛОКВА",
    },
    {
        label: "ГОЛИЦЕ",
        value: "ГОЛИЦЕ",
    },
    {
        label: "ГОРЊА ТУШИМЉА",
        value: "ГОРЊА ТУШИМЉА",
    },
    {
        label: "ГОШЕВО",
        value: "ГОШЕВО",
    },
    {
        label: "ГРАЂАНОВИЋЕ",
        value: "ГРАЂАНОВИЋЕ",
    },
    {
        label: "ГРАЧАНЕ",
        value: "ГРАЧАНЕ",
    },
    {
        label: "ГРУБЕТИЋЕ",
        value: "ГРУБЕТИЋЕ",
    },
    {
        label: "ДЕЖЕВА",
        value: "ДЕЖЕВА",
    },
    {
        label: "ДОЈИНОВИЋЕ",
        value: "ДОЈИНОВИЋЕ",
    },
    {
        label: "ДОЉАНИ",
        value: "ДОЉАНИ",
    },
    {
        label: "ДРАГОЧЕВО",
        value: "ДРАГОЧЕВО",
    },
    {
        label: "ДРАМИЋЕ",
        value: "ДРАМИЋЕ",
    },
    {
        label: "ЖУЊЕВИЋЕ",
        value: "ЖУЊЕВИЋЕ",
    },
    {
        label: "ЗАБРЂЕ",
        value: "ЗАБРЂЕ",
    },
    {
        label: "ЗЛАТАРЕ",
        value: "ЗЛАТАРЕ",
    },
    {
        label: "ИВАНЧА",
        value: "ИВАНЧА",
    },
    {
        label: "ИЗБИЦЕ",
        value: "ИЗБИЦЕ",
    },
    {
        label: "ЈАВОР",
        value: "ЈАВОР",
    },
    {
        label: "ЈАНЧА",
        value: "ЈАНЧА",
    },
    {
        label: "ЈОВА",
        value: "ЈОВА",
    },
    {
        label: "КАШАЉ",
        value: "КАШАЉ",
    },
    {
        label: "КОВАЧЕВО",
        value: "КОВАЧЕВО",
    },
    {
        label: "КОЖЉЕ",
        value: "КОЖЉЕ",
    },
    {
        label: "КОСУРИЋЕ",
        value: "КОСУРИЋЕ",
    },
    {
        label: "КРУШЕВО",
        value: "КРУШЕВО",
    },
    {
        label: "КУЗМИЧЕВО",
        value: "КУЗМИЧЕВО",
    },
    {
        label: "ЛЕЧА",
        value: "ЛЕЧА",
    },
    {
        label: "ЛОПУЖЊЕ",
        value: "ЛОПУЖЊЕ",
    },
    {
        label: "ЛУКАРЕ",
        value: "ЛУКАРЕ",
    },
    {
        label: "ЛУКАРСКО ГОШЕВО",
        value: "ЛУКАРСКО ГОШЕВО",
    },
    {
        label: "ЛУКОЦРЕВО",
        value: "ЛУКОЦРЕВО",
    },
    {
        label: "МИШЧИЋЕ",
        value: "МИШЧИЋЕ",
    },
    {
        label: "МУР",
        value: "МУР",
    },
    {
        label: "МУХОВО",
        value: "МУХОВО",
    },
    {
        label: "НЕГОТИНАЦ",
        value: "НЕГОТИНАЦ",
    },
    {
        label: "ОДОЈЕВИЋЕ",
        value: "ОДОЈЕВИЋЕ",
    },
    {
        label: "ОКОСЕ",
        value: "ОКОСЕ",
    },
    {
        label: "ОСАОНИЦА",
        value: "ОСАОНИЦА",
    },
    {
        label: "ОСОЈЕ",
        value: "ОСОЈЕ",
    },
    {
        label: "ОХОЉЕ",
        value: "ОХОЉЕ",
    },
    {
        label: "ПАВЉЕ",
        value: "ПАВЉЕ",
    },
    {
        label: "ПАСЈИ ПОТОК",
        value: "ПАСЈИ ПОТОК",
    },
    {
        label: "ПИЛАРЕТА",
        value: "ПИЛАРЕТА",
    },
    {
        label: "ПОБРЂЕ",
        value: "ПОБРЂЕ",
    },
    {
        label: "ПОЖЕГА",
        value: "ПОЖЕГА",
    },
    {
        label: "ПОЖЕЖИНА",
        value: "ПОЖЕЖИНА",
    },
    {
        label: "ПОЛОКЦЕ",
        value: "ПОЛОКЦЕ",
    },
    {
        label: "ПОПЕ",
        value: "ПОПЕ",
    },
    {
        label: "ПРЋЕНОВА",
        value: "ПРЋЕНОВА",
    },
    {
        label: "ПУСТА ТУШИМЉА",
        value: "ПУСТА ТУШИМЉА",
    },
    {
        label: "ПУСТОВЛАХ",
        value: "ПУСТОВЛАХ",
    },
    {
        label: "РАДАЉИЦА",
        value: "РАДАЉИЦА",
    },
    {
        label: "РАЈЕТИЋЕ",
        value: "РАЈЕТИЋЕ",
    },
    {
        label: "РАЈКОВИЋЕ",
        value: "РАЈКОВИЋЕ",
    },
    {
        label: "РАЈЧИНОВИЋЕ",
        value: "РАЈЧИНОВИЋЕ",
    },
    {
        label: "РАЈЧИНОВИЋКА ТРНАВА",
        value: "РАЈЧИНОВИЋКА ТРНАВА",
    },
    {
        label: "РАСТ",
        value: "РАСТ",
    },
    {
        label: "СЕБЕЧЕВО",
        value: "СЕБЕЧЕВО",
    },
    {
        label: "СИТНИЧЕ",
        value: "СИТНИЧЕ",
    },
    {
        label: "СКУКОВО",
        value: "СКУКОВО",
    },
    {
        label: "СМИЛОВ ЛАЗ",
        value: "СМИЛОВ ЛАЗ",
    },
    {
        label: "СРЕДЊА ТУШИМЉА",
        value: "СРЕДЊА ТУШИМЉА",
    },
    {
        label: "СТРАДОВО",
        value: "СТРАДОВО",
    },
    {
        label: "СУДСКО СЕЛО",
        value: "СУДСКО СЕЛО",
    },
    {
        label: "ТЕНКОВО",
        value: "ТЕНКОВО",
    },
    {
        label: "ТУНОВО",
        value: "ТУНОВО",
    },
    {
        label: "ХОТКОВО",
        value: "ХОТКОВО",
    },
    {
        label: "ЦОКОВИЋЕ",
        value: "ЦОКОВИЋЕ",
    },
    {
        label: "ЧАШИЋ ДОЛАЦ",
        value: "ЧАШИЋ ДОЛАЦ",
    },
    {
        label: "ШАВЦИ",
        value: "ШАВЦИ",
    },
    {
        label: "ШАРОЊЕ",
        value: "ШАРОЊЕ",
    },
    {
        label: "НОВИ САД",
        value: "НОВИ САД",
    },
    {
        label: "БЕГЕЧ",
        value: "БЕГЕЧ",
    },
    {
        label: "БУДИСАВА",
        value: "БУДИСАВА",
    },
    {
        label: "ВЕТЕРНИК",
        value: "ВЕТЕРНИК",
    },
    {
        label: "КАЋ",
        value: "КАЋ",
    },
    {
        label: "КИСАЧ",
        value: "КИСАЧ",
    },
    {
        label: "КОВИЉ",
        value: "КОВИЉ",
    },
    {
        label: "ЛЕДИНЦИ",
        value: "ЛЕДИНЦИ",
    },
    {
        label: "НОВИ САД I",
        value: "НОВИ САД I",
    },
    {
        label: "НОВИ САД II",
        value: "НОВИ САД II",
    },
    {
        label: "НОВИ САД III",
        value: "НОВИ САД III",
    },
    {
        label: "НОВИ САД IV",
        value: "НОВИ САД IV",
    },
    {
        label: "ПЕТРОВАРАДИН",
        value: "ПЕТРОВАРАДИН",
    },
    {
        label: "РУМЕНКА",
        value: "РУМЕНКА",
    },
    {
        label: "СРЕМСКА КАМЕНИЦА",
        value: "СРЕМСКА КАМЕНИЦА",
    },
    {
        label: "СТЕПАНОВИЋЕВО",
        value: "СТЕПАНОВИЋЕВО",
    },
    {
        label: "ФУТОГ",
        value: "ФУТОГ",
    },
    {
        label: "ЧЕНЕЈ",
        value: "ЧЕНЕЈ",
    },
    {
        label: "ОБРЕНОВАЦ",
        value: "ОБРЕНОВАЦ",
    },
    {
        label: "БАЉЕВАЦ",
        value: "БАЉЕВАЦ",
    },
    {
        label: "БРГУЛИЦЕ",
        value: "БРГУЛИЦЕ",
    },
    {
        label: "БРОВИЋ",
        value: "БРОВИЋ",
    },
    {
        label: "ВЕЛИКО ПОЉЕ",
        value: "ВЕЛИКО ПОЉЕ",
    },
    {
        label: "ВУКИЋЕВИЦА",
        value: "ВУКИЋЕВИЦА",
    },
    {
        label: "ЗАБРЕЖЈЕ",
        value: "ЗАБРЕЖЈЕ",
    },
    {
        label: "ЗВЕЧКА",
        value: "ЗВЕЧКА",
    },
    {
        label: "ЈАСЕНАК",
        value: "ЈАСЕНАК",
    },
    {
        label: "КОНАТИЦЕ",
        value: "КОНАТИЦЕ",
    },
    {
        label: "КРТИНСКА",
        value: "КРТИНСКА",
    },
    {
        label: "ЉУБИНИЋ",
        value: "ЉУБИНИЋ",
    },
    {
        label: "МАЛА МОШТАНИЦА",
        value: "МАЛА МОШТАНИЦА",
    },
    {
        label: "МИСЛОЂИН",
        value: "МИСЛОЂИН",
    },
    {
        label: "ПИРОМАН",
        value: "ПИРОМАН",
    },
    {
        label: "ПОЉАНЕ",
        value: "ПОЉАНЕ",
    },
    {
        label: "РАТАРИ",
        value: "РАТАРИ",
    },
    {
        label: "РВАТИ",
        value: "РВАТИ",
    },
    {
        label: "СКЕЛА",
        value: "СКЕЛА",
    },
    {
        label: "СТУБЛИНЕ",
        value: "СТУБЛИНЕ",
    },
    {
        label: "ТРСТЕНИЦА",
        value: "ТРСТЕНИЦА",
    },
    {
        label: "УРОВЦИ",
        value: "УРОВЦИ",
    },
    {
        label: "ОПОВО",
        value: "ОПОВО",
    },
    {
        label: "БАРАНДА",
        value: "БАРАНДА",
    },
    {
        label: "САКУЛЕ",
        value: "САКУЛЕ",
    },
    {
        label: "СЕФКЕРИН",
        value: "СЕФКЕРИН",
    },
    {
        label: "ОСЕЧИНА",
        value: "ОСЕЧИНА",
    },
    {
        label: "БАСТАВ",
        value: "БАСТАВ",
    },
    {
        label: "БРАТАЧИЋ",
        value: "БРАТАЧИЋ",
    },
    {
        label: "ГОРЊЕ ЦРНИЉЕВО",
        value: "ГОРЊЕ ЦРНИЉЕВО",
    },
    {
        label: "ГУЊАЦИ",
        value: "ГУЊАЦИ",
    },
    {
        label: "ДРАГИЈЕВИЦА",
        value: "ДРАГИЈЕВИЦА",
    },
    {
        label: "ДРАГОДОЛ",
        value: "ДРАГОДОЛ",
    },
    {
        label: "КОМИРИЋ",
        value: "КОМИРИЋ",
    },
    {
        label: "ЛОПАТАЊ",
        value: "ЛОПАТАЊ",
    },
    {
        label: "ОСТРУЖАЊ",
        value: "ОСТРУЖАЊ",
    },
    {
        label: "ПЕЦКА",
        value: "ПЕЦКА",
    },
    {
        label: "ПЛУЖАЦ",
        value: "ПЛУЖАЦ",
    },
    {
        label: "СИРДИЈА",
        value: "СИРДИЈА",
    },
    {
        label: "ТУЂИН",
        value: "ТУЂИН",
    },
    {
        label: "ЦАРИНА",
        value: "ЦАРИНА",
    },
    {
        label: "ОЏАЦИ",
        value: "ОЏАЦИ",
    },
    {
        label: "БАЧКИ БРЕСТОВАЦ",
        value: "БАЧКИ БРЕСТОВАЦ",
    },
    {
        label: "БАЧКИ ГРАЧАЦ",
        value: "БАЧКИ ГРАЧАЦ",
    },
    {
        label: "БОГОЈЕВО",
        value: "БОГОЈЕВО",
    },
    {
        label: "ДЕРОЊЕ",
        value: "ДЕРОЊЕ",
    },
    {
        label: "КАРАВУКОВО",
        value: "КАРАВУКОВО",
    },
    {
        label: "ЛАЛИЋ",
        value: "ЛАЛИЋ",
    },
    {
        label: "РАТКОВО",
        value: "РАТКОВО",
    },
    {
        label: "СРПСКИ МИЛЕТИЋ",
        value: "СРПСКИ МИЛЕТИЋ",
    },
    {
        label: "ПАЛИЛУЛА (БЕОГРАД)",
        value: "ПАЛИЛУЛА (БЕОГРАД)",
    },
    {
        label: "БЕСНИ ФОК",
        value: "БЕСНИ ФОК",
    },
    {
        label: "БОРЧА",
        value: "БОРЧА",
    },
    {
        label: "ВИШЊИЦА",
        value: "ВИШЊИЦА",
    },
    {
        label: "КОМАРЕВА ХУМКА",
        value: "КОМАРЕВА ХУМКА",
    },
    {
        label: "КРЊАЧА",
        value: "КРЊАЧА",
    },
    {
        label: "ЛЕПУШНИЦА",
        value: "ЛЕПУШНИЦА",
    },
    {
        label: "ОВЧА",
        value: "ОВЧА",
    },
    {
        label: "ПАЛИЛУЛА",
        value: "ПАЛИЛУЛА",
    },
    {
        label: "СЛАНЦИ",
        value: "СЛАНЦИ",
    },
    {
        label: "ПАЛИЛУЛА (НИШ)",
        value: "ПАЛИЛУЛА (НИШ)",
    },
    {
        label: "БЕРБАТОВО",
        value: "БЕРБАТОВО",
    },
    {
        label: "БУБАЊ",
        value: "БУБАЊ",
    },
    {
        label: "ВУКМАНОВО",
        value: "ВУКМАНОВО",
    },
    {
        label: "ГАБРОВАЦ",
        value: "ГАБРОВАЦ",
    },
    {
        label: "ГОРЊЕ МЕЂУРОВО",
        value: "ГОРЊЕ МЕЂУРОВО",
    },
    {
        label: "ДОЊЕ ВЛАСЕ",
        value: "ДОЊЕ ВЛАСЕ",
    },
    {
        label: "ДОЊЕ МЕЂУРОВО",
        value: "ДОЊЕ МЕЂУРОВО",
    },
    {
        label: "КРУШЦЕ",
        value: "КРУШЦЕ",
    },
    {
        label: "ЛАЛИНАЦ",
        value: "ЛАЛИНАЦ",
    },
    {
        label: "МРАМОР",
        value: "МРАМОР",
    },
    {
        label: "ПАСИ ПОЉАНА",
        value: "ПАСИ ПОЉАНА",
    },
    {
        label: "ЧОКОТ",
        value: "ЧОКОТ",
    },
    {
        label: "ПАНТЕЛЕЈ",
        value: "ПАНТЕЛЕЈ",
    },
    {
        label: "БРЕНИЦА",
        value: "БРЕНИЦА",
    },
    {
        label: "ГОРЊА ВРЕЖИНА",
        value: "ГОРЊА ВРЕЖИНА",
    },
    {
        label: "ГОРЊИ МАТЕЈЕВАЦ",
        value: "ГОРЊИ МАТЕЈЕВАЦ",
    },
    {
        label: "ДОЊА ВРЕЖИНА",
        value: "ДОЊА ВРЕЖИНА",
    },
    {
        label: "ДОЊИ МАТЕЈЕВАЦ I",
        value: "ДОЊИ МАТЕЈЕВАЦ I",
    },
    {
        label: "ДОЊИ МАТЕЈЕВАЦ II",
        value: "ДОЊИ МАТЕЈЕВАЦ II",
    },
    {
        label: "ЈАСЕНОВИК",
        value: "ЈАСЕНОВИК",
    },
    {
        label: "КНЕЗ СЕЛО",
        value: "КНЕЗ СЕЛО",
    },
    {
        label: "МАЛЧА",
        value: "МАЛЧА",
    },
    {
        label: 'НИШ "ПАНТЕЛЕЈ"',
        value: 'НИШ "ПАНТЕЛЕЈ"',
    },
    {
        label: "ПАСЈАЧА",
        value: "ПАСЈАЧА",
    },
    {
        label: "ПАНЧЕВО",
        value: "ПАНЧЕВО",
    },
    {
        label: "БАНАТСКИ БРЕСТОВАЦ",
        value: "БАНАТСКИ БРЕСТОВАЦ",
    },
    {
        label: "БАНАТСКО НОВО СЕЛО",
        value: "БАНАТСКО НОВО СЕЛО",
    },
    {
        label: "ВОЈЛОВИЦА",
        value: "ВОЈЛОВИЦА",
    },
    {
        label: "ГЛОГОЊ",
        value: "ГЛОГОЊ",
    },
    {
        label: "ДОЛОВО",
        value: "ДОЛОВО",
    },
    {
        label: "ИВАНОВО",
        value: "ИВАНОВО",
    },
    {
        label: "ЈАБУКА",
        value: "ЈАБУКА",
    },
    {
        label: "КАЧАРЕВО",
        value: "КАЧАРЕВО",
    },
    {
        label: "ОМОЉИЦА",
        value: "ОМОЉИЦА",
    },
    {
        label: "СТАРЧЕВО",
        value: "СТАРЧЕВО",
    },
    {
        label: "ПАРАЋИН",
        value: "ПАРАЋИН",
    },
    {
        label: "БУЉАНЕ",
        value: "БУЉАНЕ",
    },
    {
        label: "БУСИЛОВАЦ",
        value: "БУСИЛОВАЦ",
    },
    {
        label: "ГЛАВИЦА",
        value: "ГЛАВИЦА",
    },
    {
        label: "ГОЛУБОВАЦ",
        value: "ГОЛУБОВАЦ",
    },
    {
        label: "ГОРЊА МУТНИЦА",
        value: "ГОРЊА МУТНИЦА",
    },
    {
        label: "ГОРЊЕ ВИДОВО I",
        value: "ГОРЊЕ ВИДОВО I",
    },
    {
        label: "ГОРЊЕ ВИДОВО II",
        value: "ГОРЊЕ ВИДОВО II",
    },
    {
        label: "ДОЊА МУТНИЦА",
        value: "ДОЊА МУТНИЦА",
    },
    {
        label: "ДОЊЕ ВИДОВО",
        value: "ДОЊЕ ВИДОВО",
    },
    {
        label: "КЛАЧЕВИЦА",
        value: "КЛАЧЕВИЦА",
    },
    {
        label: "КРЕЖБИНАЦ",
        value: "КРЕЖБИНАЦ",
    },
    {
        label: "ЛЕБИНА",
        value: "ЛЕБИНА",
    },
    {
        label: "ЛЕШЈЕ",
        value: "ЛЕШЈЕ",
    },
    {
        label: "МИРИЛОВАЦ",
        value: "МИРИЛОВАЦ",
    },
    {
        label: "ПАРАЋИН-ГРАД",
        value: "ПАРАЋИН-ГРАД",
    },
    {
        label: "ПОТОЧАЦ",
        value: "ПОТОЧАЦ",
    },
    {
        label: "РАТАРЕ",
        value: "РАТАРЕ",
    },
    {
        label: "РАШЕВИЦА",
        value: "РАШЕВИЦА",
    },
    {
        label: "СВОЈНОВО",
        value: "СВОЈНОВО",
    },
    {
        label: "СИКИРИЦА",
        value: "СИКИРИЦА",
    },
    {
        label: "СИЊИ ВИР",
        value: "СИЊИ ВИР",
    },
    {
        label: "СТРИЖА",
        value: "СТРИЖА",
    },
    {
        label: "ЧЕПУРЕ",
        value: "ЧЕПУРЕ",
    },
    {
        label: "ШАВАЦ",
        value: "ШАВАЦ",
    },
    {
        label: "ШАЛУДОВАЦ",
        value: "ШАЛУДОВАЦ",
    },
    {
        label: "ПЕТРОВАЦ НА МЛАВИ",
        value: "ПЕТРОВАЦ НА МЛАВИ",
    },
    {
        label: "БОШЊАК",
        value: "БОШЊАК",
    },
    {
        label: "БУРОВАЦ",
        value: "БУРОВАЦ",
    },
    {
        label: "БУСУР",
        value: "БУСУР",
    },
    {
        label: "ВЕЗИЧЕВО",
        value: "ВЕЗИЧЕВО",
    },
    {
        label: "ВЕЛИКИ ПОПОВАЦ",
        value: "ВЕЛИКИ ПОПОВАЦ",
    },
    {
        label: "ВЕЛИКО ЛАОЛЕ",
        value: "ВЕЛИКО ЛАОЛЕ",
    },
    {
        label: "ВИТОВНИЦА",
        value: "ВИТОВНИЦА",
    },
    {
        label: "ВОШАНОВАЦ",
        value: "ВОШАНОВАЦ",
    },
    {
        label: "ДОБРЊЕ",
        value: "ДОБРЊЕ",
    },
    {
        label: "ДУБОЧКА",
        value: "ДУБОЧКА",
    },
    {
        label: "ЖДРЕЛО",
        value: "ЖДРЕЛО",
    },
    {
        label: "КАМЕНОВО",
        value: "КАМЕНОВО",
    },
    {
        label: "КЛАДУРОВО",
        value: "КЛАДУРОВО",
    },
    {
        label: "КРВИЈЕ",
        value: "КРВИЈЕ",
    },
    {
        label: "ЛОПУШНИК",
        value: "ЛОПУШНИК",
    },
    {
        label: "МАЛО ЛАОЛЕ",
        value: "МАЛО ЛАОЛЕ",
    },
    {
        label: "МЕЛНИЦА",
        value: "МЕЛНИЦА",
    },
    {
        label: "ОРЕШКОВИЦА",
        value: "ОРЕШКОВИЦА",
    },
    {
        label: "ОРЉЕВО",
        value: "ОРЉЕВО",
    },
    {
        label: "ПАНКОВО",
        value: "ПАНКОВО",
    },
    {
        label: "РАНОВАЦ",
        value: "РАНОВАЦ",
    },
    {
        label: "РАШАНАЦ",
        value: "РАШАНАЦ",
    },
    {
        label: "СТАМНИЦА",
        value: "СТАМНИЦА",
    },
    {
        label: "ТАБАНОВАЦ",
        value: "ТАБАНОВАЦ",
    },
    {
        label: "ЋОВДИН",
        value: "ЋОВДИН",
    },
    {
        label: "ШЕТОЊЕ",
        value: "ШЕТОЊЕ",
    },
    {
        label: "ПЕЋИНЦИ",
        value: "ПЕЋИНЦИ",
    },
    {
        label: "АШАЊА",
        value: "АШАЊА",
    },
    {
        label: "БРЕСТАЧ",
        value: "БРЕСТАЧ",
    },
    {
        label: "ДЕЧ",
        value: "ДЕЧ",
    },
    {
        label: "ДОЊИ ТОВАРНИК",
        value: "ДОЊИ ТОВАРНИК",
    },
    {
        label: "КАРЛОВЧИЋ",
        value: "КАРЛОВЧИЋ",
    },
    {
        label: "ОГАР",
        value: "ОГАР",
    },
    {
        label: "ПОПИНЦИ",
        value: "ПОПИНЦИ",
    },
    {
        label: "ПРХОВО",
        value: "ПРХОВО",
    },
    {
        label: "СИБАЧ",
        value: "СИБАЧ",
    },
    {
        label: "СРЕМСКИ МИХАЉЕВЦИ",
        value: "СРЕМСКИ МИХАЉЕВЦИ",
    },
    {
        label: "СУБОТИШТЕ",
        value: "СУБОТИШТЕ",
    },
    {
        label: "ШИМАНОВЦИ",
        value: "ШИМАНОВЦИ",
    },
    {
        label: "ПИРОТ",
        value: "ПИРОТ",
    },
    {
        label: "БАЗОВИК",
        value: "БАЗОВИК",
    },
    {
        label: "БАРЈЕ ЧИФЛИК",
        value: "БАРЈЕ ЧИФЛИК",
    },
    {
        label: "БАСАРА",
        value: "БАСАРА",
    },
    {
        label: "БЕЛА",
        value: "БЕЛА",
    },
    {
        label: "БЕРИЛОВАЦ",
        value: "БЕРИЛОВАЦ",
    },
    {
        label: "БЕРОВИЦА",
        value: "БЕРОВИЦА",
    },
    {
        label: "БЛАТО",
        value: "БЛАТО",
    },
    {
        label: "БРЛОГ",
        value: "БРЛОГ",
    },
    {
        label: "ВЕЛИКА ЛУКАЊА",
        value: "ВЕЛИКА ЛУКАЊА",
    },
    {
        label: "ВЕЛИКИ ЈОВАНОВАЦ",
        value: "ВЕЛИКИ ЈОВАНОВАЦ",
    },
    {
        label: "ВЕЛИКИ СУВОДОЛ",
        value: "ВЕЛИКИ СУВОДОЛ",
    },
    {
        label: "ВИСОЧКА РЖАНА",
        value: "ВИСОЧКА РЖАНА",
    },
    {
        label: "ВЛАСИ",
        value: "ВЛАСИ",
    },
    {
        label: "ВОЈНЕГОВАЦ",
        value: "ВОЈНЕГОВАЦ",
    },
    {
        label: "ВРАНИШТЕ",
        value: "ВРАНИШТЕ",
    },
    {
        label: "ГЊИЛАН",
        value: "ГЊИЛАН",
    },
    {
        label: "ГОРЊА ДРЖИНА",
        value: "ГОРЊА ДРЖИНА",
    },
    {
        label: "ГОСТУША",
        value: "ГОСТУША",
    },
    {
        label: "ДОЈКИНЦИ",
        value: "ДОЈКИНЦИ",
    },
    {
        label: "ДРЖИНА",
        value: "ДРЖИНА",
    },
    {
        label: "ЗАВОЈ",
        value: "ЗАВОЈ",
    },
    {
        label: "ЗАСКОВЦИ",
        value: "ЗАСКОВЦИ",
    },
    {
        label: "ЈАЛБОТИНА",
        value: "ЈАЛБОТИНА",
    },
    {
        label: "ЈЕЛОВИЦА",
        value: "ЈЕЛОВИЦА",
    },
    {
        label: "КАМИК",
        value: "КАМИК",
    },
    {
        label: "КОПРИВШТИЦА",
        value: "КОПРИВШТИЦА",
    },
    {
        label: "КОСТУР",
        value: "КОСТУР",
    },
    {
        label: "КУМАНОВО",
        value: "КУМАНОВО",
    },
    {
        label: "МАЛА ЛУКАЊА",
        value: "МАЛА ЛУКАЊА",
    },
    {
        label: "МАЛИ ЈОВАНОВАЦ",
        value: "МАЛИ ЈОВАНОВАЦ",
    },
    {
        label: "МАЛИ СУВОДОЛ",
        value: "МАЛИ СУВОДОЛ",
    },
    {
        label: "МИЛОЈКОВАЦ",
        value: "МИЛОЈКОВАЦ",
    },
    {
        label: "МИРКОВЦИ",
        value: "МИРКОВЦИ",
    },
    {
        label: "НИШОР",
        value: "НИШОР",
    },
    {
        label: "ОРЉА",
        value: "ОРЉА",
    },
    {
        label: "ОСМАКОВО",
        value: "ОСМАКОВО",
    },
    {
        label: "ПАКЛЕШТИЦА",
        value: "ПАКЛЕШТИЦА",
    },
    {
        label: "ПАСЈАЧ",
        value: "ПАСЈАЧ",
    },
    {
        label: "ПИРОТ-ВАН ВАРОШ",
        value: "ПИРОТ-ВАН ВАРОШ",
    },
    {
        label: "ПИРОТ-ГРАД",
        value: "ПИРОТ-ГРАД",
    },
    {
        label: "ПОКРЕВЕНИК",
        value: "ПОКРЕВЕНИК",
    },
    {
        label: "ПОЉСКА РЖАНА",
        value: "ПОЉСКА РЖАНА",
    },
    {
        label: "ПРИСЈАН",
        value: "ПРИСЈАН",
    },
    {
        label: "РАГОДЕШ",
        value: "РАГОДЕШ",
    },
    {
        label: "РАСНИЦА",
        value: "РАСНИЦА",
    },
    {
        label: "РОСОМАЧ",
        value: "РОСОМАЧ",
    },
    {
        label: "РСОВЦИ",
        value: "РСОВЦИ",
    },
    {
        label: "РУДИЊЕ",
        value: "РУДИЊЕ",
    },
    {
        label: "СИЊА ГЛАВА",
        value: "СИЊА ГЛАВА",
    },
    {
        label: "СЛАВИЊА",
        value: "СЛАВИЊА",
    },
    {
        label: "СОПОТ",
        value: "СОПОТ",
    },
    {
        label: "СРЕЋКОВАЦ",
        value: "СРЕЋКОВАЦ",
    },
    {
        label: "СТАНИЧЕЊЕ",
        value: "СТАНИЧЕЊЕ",
    },
    {
        label: "СУКОВО",
        value: "СУКОВО",
    },
    {
        label: "ТЕМСКА",
        value: "ТЕМСКА",
    },
    {
        label: "ТОПЛИ ДО",
        value: "ТОПЛИ ДО",
    },
    {
        label: "ТРЊАНА",
        value: "ТРЊАНА",
    },
    {
        label: "ЦЕРЕВ ДЕЛ",
        value: "ЦЕРЕВ ДЕЛ",
    },
    {
        label: "ЦРВЕНЧЕВО",
        value: "ЦРВЕНЧЕВО",
    },
    {
        label: "ЦРНОКЛИШТЕ",
        value: "ЦРНОКЛИШТЕ",
    },
    {
        label: "ЧИНИГЛАВЦИ",
        value: "ЧИНИГЛАВЦИ",
    },
    {
        label: "ШУГРИН",
        value: "ШУГРИН",
    },
    {
        label: "ПЛАНДИШТЕ",
        value: "ПЛАНДИШТЕ",
    },
    {
        label: "БАНАТСКИ СОКОЛАЦ",
        value: "БАНАТСКИ СОКОЛАЦ",
    },
    {
        label: "БАРИЦЕ",
        value: "БАРИЦЕ",
    },
    {
        label: "ВЕЛИКА ГРЕДА",
        value: "ВЕЛИКА ГРЕДА",
    },
    {
        label: "ВЕЛИКИ ГАЈ",
        value: "ВЕЛИКИ ГАЈ",
    },
    {
        label: "ДУЖИНЕ",
        value: "ДУЖИНЕ",
    },
    {
        label: "ЈЕРМЕНОВЦИ",
        value: "ЈЕРМЕНОВЦИ",
    },
    {
        label: "КУПИНИК",
        value: "КУПИНИК",
    },
    {
        label: "МАРГИТА",
        value: "МАРГИТА",
    },
    {
        label: "МАРКОВИЋЕВО",
        value: "МАРКОВИЋЕВО",
    },
    {
        label: "МИЛЕТИЋЕВО",
        value: "МИЛЕТИЋЕВО",
    },
    {
        label: "СТАРИ ЛЕЦ",
        value: "СТАРИ ЛЕЦ",
    },
    {
        label: "ХАЈДУЧИЦА",
        value: "ХАЈДУЧИЦА",
    },
    {
        label: "ПОЖАРЕВАЦ",
        value: "ПОЖАРЕВАЦ",
    },
    {
        label: "БАТОВАЦ",
        value: "БАТОВАЦ",
    },
    {
        label: "БЕРАЊЕ",
        value: "БЕРАЊЕ",
    },
    {
        label: "БРАТИНАЦ",
        value: "БРАТИНАЦ",
    },
    {
        label: "БРЕЖАНЕ",
        value: "БРЕЖАНЕ",
    },
    {
        label: "БУБУШИНАЦ",
        value: "БУБУШИНАЦ",
    },
    {
        label: "ДРМНО",
        value: "ДРМНО",
    },
    {
        label: "ДУБРАВИЦА",
        value: "ДУБРАВИЦА",
    },
    {
        label: "КАСИДОЛ",
        value: "КАСИДОЛ",
    },
    {
        label: "КЛИЧЕВАЦ",
        value: "КЛИЧЕВАЦ",
    },
    {
        label: "ЛУЧИЦА",
        value: "ЛУЧИЦА",
    },
    {
        label: "МАЉУРЕВАЦ",
        value: "МАЉУРЕВАЦ",
    },
    {
        label: "НАБРЂЕ",
        value: "НАБРЂЕ",
    },
    {
        label: "ПОЉАНА",
        value: "ПОЉАНА",
    },
    {
        label: "ПРУГОВО",
        value: "ПРУГОВО",
    },
    {
        label: "ЋИРИКОВАЦ",
        value: "ЋИРИКОВАЦ",
    },
    {
        label: "БАКИОНИЦА",
        value: "БАКИОНИЦА",
    },
    {
        label: "ВЕЛИКА ЈЕЖЕВИЦА",
        value: "ВЕЛИКА ЈЕЖЕВИЦА",
    },
    {
        label: "ВИСИБАБА",
        value: "ВИСИБАБА",
    },
    {
        label: "ВРАЊАНИ",
        value: "ВРАЊАНИ",
    },
    {
        label: "ГЛУМАЧ",
        value: "ГЛУМАЧ",
    },
    {
        label: "ГОДОВИК",
        value: "ГОДОВИК",
    },
    {
        label: "ГОРЊА ДОБРИЊА",
        value: "ГОРЊА ДОБРИЊА",
    },
    {
        label: "ГОРОБИЉЕ",
        value: "ГОРОБИЉЕ",
    },
    {
        label: "ГУГАЉ",
        value: "ГУГАЉ",
    },
    {
        label: "ДОЊА ДОБРИЊА",
        value: "ДОЊА ДОБРИЊА",
    },
    {
        label: "ДРАЖИНОВИЋИ",
        value: "ДРАЖИНОВИЋИ",
    },
    {
        label: "ДУШКОВЦИ",
        value: "ДУШКОВЦИ",
    },
    {
        label: "ЗАСЕЉЕ",
        value: "ЗАСЕЉЕ",
    },
    {
        label: "ЗДРАВЧИЋИ",
        value: "ЗДРАВЧИЋИ",
    },
    {
        label: "ЈЕЛЕН ДО",
        value: "ЈЕЛЕН ДО",
    },
    {
        label: "КАЛЕНИЋИ",
        value: "КАЛЕНИЋИ",
    },
    {
        label: "ЛОПАШ",
        value: "ЛОПАШ",
    },
    {
        label: "ЛОРЕТ",
        value: "ЛОРЕТ",
    },
    {
        label: "МАЂЕР",
        value: "МАЂЕР",
    },
    {
        label: "МАЛА ЈЕЖЕВИЦА",
        value: "МАЛА ЈЕЖЕВИЦА",
    },
    {
        label: "МИЛИЋЕВО СЕЛО",
        value: "МИЛИЋЕВО СЕЛО",
    },
    {
        label: "МРШЕЉИ",
        value: "МРШЕЉИ",
    },
    {
        label: "ОТАЊ",
        value: "ОТАЊ",
    },
    {
        label: "ПАПРАТИШТЕ",
        value: "ПАПРАТИШТЕ",
    },
    {
        label: "ПИЛАТОВИЋИ",
        value: "ПИЛАТОВИЋИ",
    },
    {
        label: "ПРИЈАНОВИЋИ",
        value: "ПРИЈАНОВИЋИ",
    },
    {
        label: "ПРИЛИПАЦ",
        value: "ПРИЛИПАЦ",
    },
    {
        label: "РАДОВЦИ",
        value: "РАДОВЦИ",
    },
    {
        label: "РАСНА",
        value: "РАСНА",
    },
    {
        label: "РЕЧИЦЕ",
        value: "РЕЧИЦЕ",
    },
    {
        label: "РОГЕ",
        value: "РОГЕ",
    },
    {
        label: "РУПЕЉЕВО",
        value: "РУПЕЉЕВО",
    },
    {
        label: "СВРАЧКОВО",
        value: "СВРАЧКОВО",
    },
    {
        label: "СРЕДЊА ДОБРИЊА",
        value: "СРЕДЊА ДОБРИЊА",
    },
    {
        label: "ТАБАНОВИЋИ",
        value: "ТАБАНОВИЋИ",
    },
    {
        label: "ТВРДИЋИ",
        value: "ТВРДИЋИ",
    },
    {
        label: "ТОМЕТИНО ПОЉЕ",
        value: "ТОМЕТИНО ПОЉЕ",
    },
    {
        label: "ТУЧКОВО",
        value: "ТУЧКОВО",
    },
    {
        label: "УЗИЋИ",
        value: "УЗИЋИ",
    },
    {
        label: "ЧЕСТОБРОДИЦА",
        value: "ЧЕСТОБРОДИЦА",
    },
    {
        label: "ПРЕШЕВО",
        value: "ПРЕШЕВО",
    },
    {
        label: "АЛИЂЕРЦЕ",
        value: "АЛИЂЕРЦЕ",
    },
    {
        label: "БЕРЧЕВАЦ",
        value: "БЕРЧЕВАЦ",
    },
    {
        label: "БУЈИЋ",
        value: "БУЈИЋ",
    },
    {
        label: "БУКАРЕВАЦ I",
        value: "БУКАРЕВАЦ I",
    },
    {
        label: "БУКАРЕВАЦ II",
        value: "БУКАРЕВАЦ II",
    },
    {
        label: "ГОЛЕМИ ДОЛ",
        value: "ГОЛЕМИ ДОЛ",
    },
    {
        label: "ГОРЊА ШУШАЈА",
        value: "ГОРЊА ШУШАЈА",
    },
    {
        label: "ГОСПОЂИНЦЕ",
        value: "ГОСПОЂИНЦЕ",
    },
    {
        label: "ДЕПЦЕ",
        value: "ДЕПЦЕ",
    },
    {
        label: "ДОЊА ШУШАЈА",
        value: "ДОЊА ШУШАЈА",
    },
    {
        label: "ЖУЈИНЦЕ I",
        value: "ЖУЈИНЦЕ I",
    },
    {
        label: "ЖУЈИНЦЕ II",
        value: "ЖУЈИНЦЕ II",
    },
    {
        label: "ИЛИНЦЕ",
        value: "ИЛИНЦЕ",
    },
    {
        label: "КУРБАЛИЈА",
        value: "КУРБАЛИЈА",
    },
    {
        label: "ЉАНИК",
        value: "ЉАНИК",
    },
    {
        label: "МАЂАРЕ",
        value: "МАЂАРЕ",
    },
    {
        label: "МИРАТОВАЦ",
        value: "МИРАТОВАЦ",
    },
    {
        label: "НОРЧА",
        value: "НОРЧА",
    },
    {
        label: "ПЕЧЕНО",
        value: "ПЕЧЕНО",
    },
    {
        label: "РАЈИНЦЕ",
        value: "РАЈИНЦЕ",
    },
    {
        label: "РАНАТОВЦЕ",
        value: "РАНАТОВЦЕ",
    },
    {
        label: "РЕЉАН",
        value: "РЕЉАН",
    },
    {
        label: "СЕФЕР",
        value: "СЕФЕР",
    },
    {
        label: "СЛАВУЈЕВАЦ",
        value: "СЛАВУЈЕВАЦ",
    },
    {
        label: "СТАНЕВЦЕ",
        value: "СТАНЕВЦЕ",
    },
    {
        label: "СТРЕЗОВЦЕ",
        value: "СТРЕЗОВЦЕ",
    },
    {
        label: "ЦАКАНОВАЦ",
        value: "ЦАКАНОВАЦ",
    },
    {
        label: "ЦЕРЕВАЈКА",
        value: "ЦЕРЕВАЈКА",
    },
    {
        label: "ЦРНОТИНЦЕ",
        value: "ЦРНОТИНЦЕ",
    },
    {
        label: "ЧУКАРКА",
        value: "ЧУКАРКА",
    },
    {
        label: "БАТКОВИЋИ",
        value: "БАТКОВИЋИ",
    },
    {
        label: "ДОБРИЛОВИЋИ",
        value: "ДОБРИЛОВИЋИ",
    },
    {
        label: "ЗАБРЊИЦА",
        value: "ЗАБРЊИЦА",
    },
    {
        label: "КАЛАФАТИ",
        value: "КАЛАФАТИ",
    },
    {
        label: "КАЛУЂЕРОВИЋИ",
        value: "КАЛУЂЕРОВИЋИ",
    },
    {
        label: "КАСИДОЛИ",
        value: "КАСИДОЛИ",
    },
    {
        label: "КРАТОВО",
        value: "КРАТОВО",
    },
    {
        label: "МАЖИЋИ",
        value: "МАЖИЋИ",
    },
    {
        label: "МИЛИЈЕШ",
        value: "МИЛИЈЕШ",
    },
    {
        label: "ПРИБОЈСКА ГОЛЕША",
        value: "ПРИБОЈСКА ГОЛЕША",
    },
    {
        label: "ПРИБОЈСКЕ ЧЕЛИЦЕ",
        value: "ПРИБОЈСКЕ ЧЕЛИЦЕ",
    },
    {
        label: "РИТОШИЋИ",
        value: "РИТОШИЋИ",
    },
    {
        label: "СЈЕВЕРИН",
        value: "СЈЕВЕРИН",
    },
    {
        label: "СОЧИЦЕ",
        value: "СОЧИЦЕ",
    },
    {
        label: "СТРМАЦ",
        value: "СТРМАЦ",
    },
    {
        label: "ХЕРЦЕГОВАЧКА ГОЛЕША",
        value: "ХЕРЦЕГОВАЧКА ГОЛЕША",
    },
    {
        label: "ЦРНУЗИ",
        value: "ЦРНУЗИ",
    },
    {
        label: "ПРИЈЕПОЉЕ",
        value: "ПРИЈЕПОЉЕ",
    },
    {
        label: "АЉИНОВИЋИ",
        value: "АЉИНОВИЋИ",
    },
    {
        label: "БАЛИЋИ",
        value: "БАЛИЋИ",
    },
    {
        label: "БИСКУПИЋИ",
        value: "БИСКУПИЋИ",
    },
    {
        label: "БЈЕЛАХОВА",
        value: "БЈЕЛАХОВА",
    },
    {
        label: "БРВИНЕ",
        value: "БРВИНЕ",
    },
    {
        label: "БРОДАРЕВО",
        value: "БРОДАРЕВО",
    },
    {
        label: "ВИНИЦКА",
        value: "ВИНИЦКА",
    },
    {
        label: "ГОЈАКОВИЋИ",
        value: "ГОЈАКОВИЋИ",
    },
    {
        label: "ГОРЊЕ БАБИНЕ",
        value: "ГОРЊЕ БАБИНЕ",
    },
    {
        label: "ГОРЊЕ ГОРАЧИЋЕ",
        value: "ГОРЊЕ ГОРАЧИЋЕ",
    },
    {
        label: "ГОРЊИ СТРАЊАНИ",
        value: "ГОРЊИ СТРАЊАНИ",
    },
    {
        label: "ГОСТУН",
        value: "ГОСТУН",
    },
    {
        label: "ГРОБНИЦЕ",
        value: "ГРОБНИЦЕ",
    },
    {
        label: "ДОЊЕ БАБИНЕ",
        value: "ДОЊЕ БАБИНЕ",
    },
    {
        label: "ДОЊИ СТРАЊАНИ",
        value: "ДОЊИ СТРАЊАНИ",
    },
    {
        label: "ДУШМАНИЋИ",
        value: "ДУШМАНИЋИ",
    },
    {
        label: "ЂУРАШИЋИ",
        value: "ЂУРАШИЋИ",
    },
    {
        label: "ЗАБРДЊИ ТОЦИ",
        value: "ЗАБРДЊИ ТОЦИ",
    },
    {
        label: "ЗАВИНОГРАЂЕ",
        value: "ЗАВИНОГРАЂЕ",
    },
    {
        label: "ЗАЛУГ",
        value: "ЗАЛУГ",
    },
    {
        label: "ЗАСТУП",
        value: "ЗАСТУП",
    },
    {
        label: "ЗВИЈЕЗД",
        value: "ЗВИЈЕЗД",
    },
    {
        label: "ИВЕЗИЋИ",
        value: "ИВЕЗИЋИ",
    },
    {
        label: "ИЗБИЧАЊ",
        value: "ИЗБИЧАЊ",
    },
    {
        label: "ЈУНЧЕВИЋИ",
        value: "ЈУНЧЕВИЋИ",
    },
    {
        label: "КАМЕНА ГОРА",
        value: "КАМЕНА ГОРА",
    },
    {
        label: "КАРАУЛА",
        value: "КАРАУЛА",
    },
    {
        label: "КАРОШЕВИНА",
        value: "КАРОШЕВИНА",
    },
    {
        label: "КАЋЕВО",
        value: "КАЋЕВО",
    },
    {
        label: "КОЛОВРАТ",
        value: "КОЛОВРАТ",
    },
    {
        label: "КОПРИВНА",
        value: "КОПРИВНА",
    },
    {
        label: "КОСАТИЦА",
        value: "КОСАТИЦА",
    },
    {
        label: "КОШЕВИНЕ",
        value: "КОШЕВИНЕ",
    },
    {
        label: "КУЧИН",
        value: "КУЧИН",
    },
    {
        label: "МЕЂАНИ",
        value: "МЕЂАНИ",
    },
    {
        label: "МИЛАКОВИЋИ",
        value: "МИЛАКОВИЋИ",
    },
    {
        label: "МИЛОШЕВ ДО",
        value: "МИЛОШЕВ ДО",
    },
    {
        label: "МИЉЕВИЋИ",
        value: "МИЉЕВИЋИ",
    },
    {
        label: "МРЧКОВИНА",
        value: "МРЧКОВИНА",
    },
    {
        label: "МУШКОВИНА",
        value: "МУШКОВИНА",
    },
    {
        label: "ОРАОВАЦ",
        value: "ОРАОВАЦ",
    },
    {
        label: "ОШТРА СТИЈЕНА",
        value: "ОШТРА СТИЈЕНА",
    },
    {
        label: "ПОТКРШ",
        value: "ПОТКРШ",
    },
    {
        label: "ПОТОК",
        value: "ПОТОК",
    },
    {
        label: "ПРАВОШЕВО",
        value: "ПРАВОШЕВО",
    },
    {
        label: "РАСНО",
        value: "РАСНО",
    },
    {
        label: "РАТАЈСКА",
        value: "РАТАЈСКА",
    },
    {
        label: "СЕДОБРО",
        value: "СЕДОБРО",
    },
    {
        label: "СЕЉАНЕ",
        value: "СЕЉАНЕ",
    },
    {
        label: "СЕЉАШНИЦА",
        value: "СЕЉАШНИЦА",
    },
    {
        label: "СКОКУЋЕ",
        value: "СКОКУЋЕ",
    },
    {
        label: "ТАШЕВО",
        value: "ТАШЕВО",
    },
    {
        label: "ХИСАРЏИК",
        value: "ХИСАРЏИК",
    },
    {
        label: "ХРТА",
        value: "ХРТА",
    },
    {
        label: "ЦРКВЕНИ ТОЦИ",
        value: "ЦРКВЕНИ ТОЦИ",
    },
    {
        label: "ЧАУШЕВИЋИ",
        value: "ЧАУШЕВИЋИ",
    },
    {
        label: "ЏУРОВО",
        value: "ЏУРОВО",
    },
    {
        label: "ПРОКУПЉЕ",
        value: "ПРОКУПЉЕ",
    },
    {
        label: "АРБАНАШКА",
        value: "АРБАНАШКА",
    },
    {
        label: "БАБИН ПОТОК",
        value: "БАБИН ПОТОК",
    },
    {
        label: "БАБОТИНАЦ",
        value: "БАБОТИНАЦ",
    },
    {
        label: "БАЈЧИНЦЕ",
        value: "БАЈЧИНЦЕ",
    },
    {
        label: "БАЛИНОВАЦ",
        value: "БАЛИНОВАЦ",
    },
    {
        label: "БАЛЧАК",
        value: "БАЛЧАК",
    },
    {
        label: "БАЦЕ",
        value: "БАЦЕ",
    },
    {
        label: "БЕЛОГОШ",
        value: "БЕЛОГОШ",
    },
    {
        label: "БЕЛОЉИН",
        value: "БЕЛОЉИН",
    },
    {
        label: "БЕРИЉЕ",
        value: "БЕРИЉЕ",
    },
    {
        label: "БРЕГОВИНА",
        value: "БРЕГОВИНА",
    },
    {
        label: "БРЕСНИЧИЋ",
        value: "БРЕСНИЧИЋ",
    },
    {
        label: "БУБЛИЦА",
        value: "БУБЛИЦА",
    },
    {
        label: "БУКОЛОРАМ",
        value: "БУКОЛОРАМ",
    },
    {
        label: "БУЛАТОВАЦ",
        value: "БУЛАТОВАЦ",
    },
    {
        label: "БУЧИНЦЕ",
        value: "БУЧИНЦЕ",
    },
    {
        label: "ВЛАСА",
        value: "ВЛАСА",
    },
    {
        label: "ВЛАСОВО",
        value: "ВЛАСОВО",
    },
    {
        label: "ВОДИЦЕ",
        value: "ВОДИЦЕ",
    },
    {
        label: "ГЛАСОВИК",
        value: "ГЛАСОВИК",
    },
    {
        label: "ГОЈИНОВАЦ",
        value: "ГОЈИНОВАЦ",
    },
    {
        label: "ГОРЊА БЕЈАШНИЦА",
        value: "ГОРЊА БЕЈАШНИЦА",
    },
    {
        label: "ГОРЊА БРЕСНИЦА",
        value: "ГОРЊА БРЕСНИЦА",
    },
    {
        label: "ГОРЊА КОЊУША",
        value: "ГОРЊА КОЊУША",
    },
    {
        label: "ГОРЊА РЕЧИЦА",
        value: "ГОРЊА РЕЧИЦА",
    },
    {
        label: "ГОРЊА СТРАЖАВА",
        value: "ГОРЊА СТРАЖАВА",
    },
    {
        label: "ГОРЊА ТОПОНИЦА",
        value: "ГОРЊА ТОПОНИЦА",
    },
    {
        label: "ГОРЊА ТРНАВА",
        value: "ГОРЊА ТРНАВА",
    },
    {
        label: "ГОРЊЕ КОРДИНЦЕ",
        value: "ГОРЊЕ КОРДИНЦЕ",
    },
    {
        label: "ГОРЊИ СТАТОВАЦ",
        value: "ГОРЊИ СТАТОВАЦ",
    },
    {
        label: "ГУБЕТИН",
        value: "ГУБЕТИН",
    },
    {
        label: "ДОБРОТИЋ",
        value: "ДОБРОТИЋ",
    },
    {
        label: "ДОЊА БЕЈАШНИЦА",
        value: "ДОЊА БЕЈАШНИЦА",
    },
    {
        label: "ДОЊА БРЕСНИЦА",
        value: "ДОЊА БРЕСНИЦА",
    },
    {
        label: "ДОЊА КОЊУША",
        value: "ДОЊА КОЊУША",
    },
    {
        label: "ДОЊА РЕЧИЦА",
        value: "ДОЊА РЕЧИЦА",
    },
    {
        label: "ДОЊА СТРАЖАВА",
        value: "ДОЊА СТРАЖАВА",
    },
    {
        label: "ДОЊА ТОПОНИЦА",
        value: "ДОЊА ТОПОНИЦА",
    },
    {
        label: "ДОЊА ТРНАВА",
        value: "ДОЊА ТРНАВА",
    },
    {
        label: "ДОЊЕ КОРДИНЦЕ",
        value: "ДОЊЕ КОРДИНЦЕ",
    },
    {
        label: "ДОЊИ СТАТОВАЦ",
        value: "ДОЊИ СТАТОВАЦ",
    },
    {
        label: "ДРАГИ ДЕО",
        value: "ДРАГИ ДЕО",
    },
    {
        label: "ЂУРОВАЦ",
        value: "ЂУРОВАЦ",
    },
    {
        label: "ЂУШНИЦА",
        value: "ЂУШНИЦА",
    },
    {
        label: "ЖИТНИ ПОТОК",
        value: "ЖИТНИ ПОТОК",
    },
    {
        label: "ЗЛАТА",
        value: "ЗЛАТА",
    },
    {
        label: "ЈАБУЧЕВО",
        value: "ЈАБУЧЕВО",
    },
    {
        label: "ЈОВИНЕ ЛИВАДЕ",
        value: "ЈОВИНЕ ЛИВАДЕ",
    },
    {
        label: "ЈУГОВАЦ",
        value: "ЈУГОВАЦ",
    },
    {
        label: "КАЛУДРА",
        value: "КАЛУДРА",
    },
    {
        label: "КОЖИНЦЕ",
        value: "КОЖИНЦЕ",
    },
    {
        label: "КОНЧИЋ",
        value: "КОНЧИЋ",
    },
    {
        label: "КОНЏЕЉ",
        value: "КОНЏЕЉ",
    },
    {
        label: "МАЂЕРЕ",
        value: "МАЂЕРЕ",
    },
    {
        label: "МАЛА ПЛАНА",
        value: "МАЛА ПЛАНА",
    },
    {
        label: "МАЧИНА",
        value: "МАЧИНА",
    },
    {
        label: "МЕРОВАЦ",
        value: "МЕРОВАЦ",
    },
    {
        label: "МИКУЛОВАЦ",
        value: "МИКУЛОВАЦ",
    },
    {
        label: "МИЉКОВИЦА",
        value: "МИЉКОВИЦА",
    },
    {
        label: "МРЉАК",
        value: "МРЉАК",
    },
    {
        label: "МРШЕЉ",
        value: "МРШЕЉ",
    },
    {
        label: "НОВА БОЖУРНА",
        value: "НОВА БОЖУРНА",
    },
    {
        label: "НОВИ ЂУРОВАЦ",
        value: "НОВИ ЂУРОВАЦ",
    },
    {
        label: "ОБРТИНЦЕ",
        value: "ОБРТИНЦЕ",
    },
    {
        label: "ПАШИНАЦ",
        value: "ПАШИНАЦ",
    },
    {
        label: "ПЕСТИШ",
        value: "ПЕСТИШ",
    },
    {
        label: "ПИСКАЉЕ",
        value: "ПИСКАЉЕ",
    },
    {
        label: "ПЛОЧНИК",
        value: "ПЛОЧНИК",
    },
    {
        label: "ПОЈАТЕ",
        value: "ПОЈАТЕ",
    },
    {
        label: "ПОТОЧИЋ",
        value: "ПОТОЧИЋ",
    },
    {
        label: "ПРЕКАДИН",
        value: "ПРЕКАДИН",
    },
    {
        label: "ПРЕКАШНИЦА",
        value: "ПРЕКАШНИЦА",
    },
    {
        label: "ПРЕКОПУЦЕ",
        value: "ПРЕКОПУЦЕ",
    },
    {
        label: "ПРОКУПЉЕ ГРАД",
        value: "ПРОКУПЉЕ ГРАД",
    },
    {
        label: "РАНКОВА РЕКА",
        value: "РАНКОВА РЕКА",
    },
    {
        label: "РАСТОВНИЦА",
        value: "РАСТОВНИЦА",
    },
    {
        label: "РГАЈЕ",
        value: "РГАЈЕ",
    },
    {
        label: "РЕЉИНАЦ",
        value: "РЕЉИНАЦ",
    },
    {
        label: "РЕСИНАЦ",
        value: "РЕСИНАЦ",
    },
    {
        label: "СРЕДЊИ СТАТОВАЦ",
        value: "СРЕДЊИ СТАТОВАЦ",
    },
    {
        label: "СТАРИ ЂУРОВАЦ",
        value: "СТАРИ ЂУРОВАЦ",
    },
    {
        label: "ТОВРЉАНЕ",
        value: "ТОВРЉАНЕ",
    },
    {
        label: "ТРНОВИ ЛАЗ",
        value: "ТРНОВИ ЛАЗ",
    },
    {
        label: "ЏИГОЉ",
        value: "ЏИГОЉ",
    },
    {
        label: "ШИРОКЕ ЊИВЕ",
        value: "ШИРОКЕ ЊИВЕ",
    },
    {
        label: "ШИШМАНОВАЦ",
        value: "ШИШМАНОВАЦ",
    },
    {
        label: "РАЖАЊ",
        value: "РАЖАЊ",
    },
    {
        label: "БРАЉИНА",
        value: "БРАЉИНА",
    },
    {
        label: "БРАЧИН",
        value: "БРАЧИН",
    },
    {
        label: "ВАРОШ",
        value: "ВАРОШ",
    },
    {
        label: "ВИТОШЕВАЦ",
        value: "ВИТОШЕВАЦ",
    },
    {
        label: "МАЛЕТИНА",
        value: "МАЛЕТИНА",
    },
    {
        label: "МАНАСТИРСКО",
        value: "МАНАСТИРСКО",
    },
    {
        label: "МАЋИЈА",
        value: "МАЋИЈА",
    },
    {
        label: "ПАРДИК",
        value: "ПАРДИК",
    },
    {
        label: "ПОДГОРАЦ",
        value: "ПОДГОРАЦ",
    },
    {
        label: "ПОСЛОН",
        value: "ПОСЛОН",
    },
    {
        label: "ПРАСКОВЧЕ",
        value: "ПРАСКОВЧЕ",
    },
    {
        label: "ПРЕТРКОВАЦ",
        value: "ПРЕТРКОВАЦ",
    },
    {
        label: "СКОРИЦА",
        value: "СКОРИЦА",
    },
    {
        label: "СМИЛОВАЦ",
        value: "СМИЛОВАЦ",
    },
    {
        label: "ЦЕРОВО",
        value: "ЦЕРОВО",
    },
    {
        label: "ЧУБУРА",
        value: "ЧУБУРА",
    },
    {
        label: "ШЕТКА",
        value: "ШЕТКА",
    },
    {
        label: "РАКОВИЦА",
        value: "РАКОВИЦА",
    },
    {
        label: "СТАРА РАКОВИЦА",
        value: "СТАРА РАКОВИЦА",
    },
    {
        label: "АДРОВАЦ",
        value: "АДРОВАЦ",
    },
    {
        label: "БОРЦИ",
        value: "БОРЦИ",
    },
    {
        label: "ВЕЛИКО КРЧМАРЕ",
        value: "ВЕЛИКО КРЧМАРЕ",
    },
    {
        label: "ВИШЕВАЦ",
        value: "ВИШЕВАЦ",
    },
    {
        label: "ВОЈИНОВАЦ",
        value: "ВОЈИНОВАЦ",
    },
    {
        label: "ВУЧИЋ",
        value: "ВУЧИЋ",
    },
    {
        label: "ДОЊА РАЧА",
        value: "ДОЊА РАЧА",
    },
    {
        label: "ДОЊЕ ЈАРУШИЦЕ",
        value: "ДОЊЕ ЈАРУШИЦЕ",
    },
    {
        label: "МАЛО КРЧМАРЕ",
        value: "МАЛО КРЧМАРЕ",
    },
    {
        label: "МИРАШЕВАЦ",
        value: "МИРАШЕВАЦ",
    },
    {
        label: "ПОПОВИЋ",
        value: "ПОПОВИЋ",
    },
    {
        label: "САРАНОВО",
        value: "САРАНОВО",
    },
    {
        label: "СЕПЦИ",
        value: "СЕПЦИ",
    },
    {
        label: "СИПИЋ",
        value: "СИПИЋ",
    },
    {
        label: "РАШКА",
        value: "РАШКА",
    },
    {
        label: "БАДАЊ",
        value: "БАДАЊ",
    },
    {
        label: "БЕЛА СТЕНА",
        value: "БЕЛА СТЕНА",
    },
    {
        label: "БЕОЦИ",
        value: "БЕОЦИ",
    },
    {
        label: "БИЉАНОВАЦ",
        value: "БИЉАНОВАЦ",
    },
    {
        label: "БИНИЋЕ",
        value: "БИНИЋЕ",
    },
    {
        label: "БОРОВИЋЕ",
        value: "БОРОВИЋЕ",
    },
    {
        label: "БОЋЕ",
        value: "БОЋЕ",
    },
    {
        label: "БРВЕНИЦА",
        value: "БРВЕНИЦА",
    },
    {
        label: "ВОЈМИЛОВИЋИ",
        value: "ВОЈМИЛОВИЋИ",
    },
    {
        label: "ГЊИЛИЦА",
        value: "ГЊИЛИЦА",
    },
    {
        label: "ДРАГАНИЋИ",
        value: "ДРАГАНИЋИ",
    },
    {
        label: "ЖУТИЦЕ",
        value: "ЖУТИЦЕ",
    },
    {
        label: "ЗАРЕВО",
        value: "ЗАРЕВО",
    },
    {
        label: "ЈОШАНИЧКА БАЊА",
        value: "ЈОШАНИЧКА БАЊА",
    },
    {
        label: "КАЗНОВИЋЕ",
        value: "КАЗНОВИЋЕ",
    },
    {
        label: "КАРАДАК",
        value: "КАРАДАК",
    },
    {
        label: "КОПАОНИК",
        value: "КОПАОНИК",
    },
    {
        label: "КОРЛАЋЕ",
        value: "КОРЛАЋЕ",
    },
    {
        label: "КРАВИЋЕ",
        value: "КРАВИЋЕ",
    },
    {
        label: "КРЕМИЋЕ",
        value: "КРЕМИЋЕ",
    },
    {
        label: "КУЋАНЕ",
        value: "КУЋАНЕ",
    },
    {
        label: "ЛИСИНА",
        value: "ЛИСИНА",
    },
    {
        label: "МИЛАТКОВИЋЕ",
        value: "МИЛАТКОВИЋЕ",
    },
    {
        label: "МУРЕ",
        value: "МУРЕ",
    },
    {
        label: "НОСОЉИН",
        value: "НОСОЉИН",
    },
    {
        label: "ПАВЛИЦА",
        value: "ПАВЛИЦА",
    },
    {
        label: "ПАНОЈЕВИЋЕ",
        value: "ПАНОЈЕВИЋЕ",
    },
    {
        label: "ПИСКАЊА",
        value: "ПИСКАЊА",
    },
    {
        label: "ПЛЕШИН",
        value: "ПЛЕШИН",
    },
    {
        label: "ПОЦЕСЈЕ",
        value: "ПОЦЕСЈЕ",
    },
    {
        label: "РАДОШИЋЕ",
        value: "РАДОШИЋЕ",
    },
    {
        label: "РУДНИЦА",
        value: "РУДНИЦА",
    },
    {
        label: "СЕБИМИЉЕ",
        value: "СЕБИМИЉЕ",
    },
    {
        label: "СЕМЕТЕШ",
        value: "СЕМЕТЕШ",
    },
    {
        label: "СУПЊЕ",
        value: "СУПЊЕ",
    },
    {
        label: "ТИОЏЕ",
        value: "ТИОЏЕ",
    },
    {
        label: "ТУСНИЋ-ПЛАВКОВО",
        value: "ТУСНИЋ-ПЛАВКОВО",
    },
    {
        label: "ЦРНА ГЛАВА",
        value: "ЦРНА ГЛАВА",
    },
    {
        label: "ШИПАЧИНА",
        value: "ШИПАЧИНА",
    },
    {
        label: "РЕКОВАЦ",
        value: "РЕКОВАЦ",
    },
    {
        label: "БЕЛУШИЋ",
        value: "БЕЛУШИЋ",
    },
    {
        label: "БЕОЧИЋ",
        value: "БЕОЧИЋ",
    },
    {
        label: "БОГАЛИНАЦ",
        value: "БОГАЛИНАЦ",
    },
    {
        label: "БРАЈИНОВАЦ",
        value: "БРАЈИНОВАЦ",
    },
    {
        label: "ВУКМАНОВАЦ",
        value: "ВУКМАНОВАЦ",
    },
    {
        label: "ДОБРОСЕЛИЦА",
        value: "ДОБРОСЕЛИЦА",
    },
    {
        label: "ДРАГОВО",
        value: "ДРАГОВО",
    },
    {
        label: "ЖУПАЊЕВАЦ",
        value: "ЖУПАЊЕВАЦ",
    },
    {
        label: "КАВАДАР",
        value: "КАВАДАР",
    },
    {
        label: "КАЛЕНИЋКИ ПРЊАВОР",
        value: "КАЛЕНИЋКИ ПРЊАВОР",
    },
    {
        label: "КОМАРАНЕ",
        value: "КОМАРАНЕ",
    },
    {
        label: "ЛЕПОЈЕВИЋ",
        value: "ЛЕПОЈЕВИЋ",
    },
    {
        label: "МОТРИЋ",
        value: "МОТРИЋ",
    },
    {
        label: "НАДРЉЕ",
        value: "НАДРЉЕ",
    },
    {
        label: "ОПАРИЋ",
        value: "ОПАРИЋ",
    },
    {
        label: "ПРЕВЕШТ",
        value: "ПРЕВЕШТ",
    },
    {
        label: "РАБЕНОВАЦ",
        value: "РАБЕНОВАЦ",
    },
    {
        label: "РАТКОВИЋ",
        value: "РАТКОВИЋ",
    },
    {
        label: "СЕКУРИЧ",
        value: "СЕКУРИЧ",
    },
    {
        label: "СИЉЕВИЦА",
        value: "СИЉЕВИЦА",
    },
    {
        label: "ТЕЧИЋ",
        value: "ТЕЧИЋ",
    },
    {
        label: "УРСУЛЕ",
        value: "УРСУЛЕ",
    },
    {
        label: "ЦИКОТ",
        value: "ЦИКОТ",
    },
    {
        label: "ШЉИВИЦА",
        value: "ШЉИВИЦА",
    },
    {
        label: "РУМА",
        value: "РУМА",
    },
    {
        label: "БУЂАНОВЦИ",
        value: "БУЂАНОВЦИ",
    },
    {
        label: "ВИТОЈЕВЦИ",
        value: "ВИТОЈЕВЦИ",
    },
    {
        label: "ВОГАЊ",
        value: "ВОГАЊ",
    },
    {
        label: "ГРАБОВЦИ",
        value: "ГРАБОВЦИ",
    },
    {
        label: "ДОБРИНЦИ",
        value: "ДОБРИНЦИ",
    },
    {
        label: "ДОЊИ ПЕТРОВЦИ",
        value: "ДОЊИ ПЕТРОВЦИ",
    },
    {
        label: "ЖАРКОВАЦ",
        value: "ЖАРКОВАЦ",
    },
    {
        label: "КЛЕНАК",
        value: "КЛЕНАК",
    },
    {
        label: "КРАЉЕВЦИ",
        value: "КРАЉЕВЦИ",
    },
    {
        label: "МАЛИ РАДИНЦИ",
        value: "МАЛИ РАДИНЦИ",
    },
    {
        label: "МАРЂЕЛОС",
        value: "МАРЂЕЛОС",
    },
    {
        label: "НИКИНЦИ",
        value: "НИКИНЦИ",
    },
    {
        label: "ПАВЛОВЦИ",
        value: "ПАВЛОВЦИ",
    },
    {
        label: "ПЛАТИЧЕВО",
        value: "ПЛАТИЧЕВО",
    },
    {
        label: "ПУТИНЦИ",
        value: "ПУТИНЦИ",
    },
    {
        label: "СТЕЈАНОВЦИ",
        value: "СТЕЈАНОВЦИ",
    },
    {
        label: "ХРТКОВЦИ",
        value: "ХРТКОВЦИ",
    },
    {
        label: "САВСКИ ВЕНАЦ",
        value: "САВСКИ ВЕНАЦ",
    },
    {
        label: "СВИЛАЈНАЦ",
        value: "СВИЛАЈНАЦ",
    },
    {
        label: "БОБОВО",
        value: "БОБОВО",
    },
    {
        label: "ВОЈСКА",
        value: "ВОЈСКА",
    },
    {
        label: "ВРЛАНЕ",
        value: "ВРЛАНЕ",
    },
    {
        label: "ЂУРИНАЦ",
        value: "ЂУРИНАЦ",
    },
    {
        label: "КУПИНОВАЦ",
        value: "КУПИНОВАЦ",
    },
    {
        label: "КУШИЉЕВО",
        value: "КУШИЉЕВО",
    },
    {
        label: "ЛУКОВИЦА",
        value: "ЛУКОВИЦА",
    },
    {
        label: "МАЧЕВАЦ",
        value: "МАЧЕВАЦ",
    },
    {
        label: "ПРОШТИНАЦ",
        value: "ПРОШТИНАЦ",
    },
    {
        label: "РАДОШИН",
        value: "РАДОШИН",
    },
    {
        label: "РОАНДА",
        value: "РОАНДА",
    },
    {
        label: "РОЋЕВАЦ",
        value: "РОЋЕВАЦ",
    },
    {
        label: "СЕДЛАРЕ",
        value: "СЕДЛАРЕ",
    },
    {
        label: "ТРОПОЊЕ",
        value: "ТРОПОЊЕ",
    },
    {
        label: "ЦРКВЕНАЦ",
        value: "ЦРКВЕНАЦ",
    },
    {
        label: "СВРЉИГ",
        value: "СВРЉИГ",
    },
    {
        label: "БЕЛОИЊЕ",
        value: "БЕЛОИЊЕ",
    },
    {
        label: "БУРДИМО",
        value: "БУРДИМО",
    },
    {
        label: "БУЧУМ",
        value: "БУЧУМ",
    },
    {
        label: "ГАЛИБАБИНАЦ",
        value: "ГАЛИБАБИНАЦ",
    },
    {
        label: "ГОЈМАНОВАЦ",
        value: "ГОЈМАНОВАЦ",
    },
    {
        label: "ГРБАВЧЕ",
        value: "ГРБАВЧЕ",
    },
    {
        label: "ГУЛИЈАН",
        value: "ГУЛИЈАН",
    },
    {
        label: "ГУШЕВАЦ",
        value: "ГУШЕВАЦ",
    },
    {
        label: "ДАВИДОВАЦ I",
        value: "ДАВИДОВАЦ I",
    },
    {
        label: "ДАВИДОВАЦ II",
        value: "ДАВИДОВАЦ II",
    },
    {
        label: "ДРАЈИНАЦ",
        value: "ДРАЈИНАЦ",
    },
    {
        label: "ЖЕЉЕВО",
        value: "ЖЕЉЕВО",
    },
    {
        label: "КОПАЈКОШАРА",
        value: "КОПАЈКОШАРА",
    },
    {
        label: "ЛАБУКОВО",
        value: "ЛАБУКОВО",
    },
    {
        label: "ЛОЗАН",
        value: "ЛОЗАН",
    },
    {
        label: "МАНОЈЛИЦА",
        value: "МАНОЈЛИЦА",
    },
    {
        label: "МЕРЏЕЛАТ",
        value: "МЕРЏЕЛАТ",
    },
    {
        label: "МЕЧЈИ ДО",
        value: "МЕЧЈИ ДО",
    },
    {
        label: "НИШЕВАЦ",
        value: "НИШЕВАЦ",
    },
    {
        label: "ОКОЛИШТЕ",
        value: "ОКОЛИШТЕ",
    },
    {
        label: "ОКРУГЛИЦА",
        value: "ОКРУГЛИЦА",
    },
    {
        label: "ПЕРИШ",
        value: "ПЕРИШ",
    },
    {
        label: "ПИРКОВАЦ",
        value: "ПИРКОВАЦ",
    },
    {
        label: "ПЛУЖИНА",
        value: "ПЛУЖИНА",
    },
    {
        label: "ПОПШИЦА",
        value: "ПОПШИЦА",
    },
    {
        label: "ПРЕКОНОГА",
        value: "ПРЕКОНОГА",
    },
    {
        label: "РАДМИРОВАЦ",
        value: "РАДМИРОВАЦ",
    },
    {
        label: "СЛИВЈЕ",
        value: "СЛИВЈЕ",
    },
    {
        label: "ЦРНОЉЕВИЦА",
        value: "ЦРНОЉЕВИЦА",
    },
    {
        label: "СЕВОЈНО",
        value: "СЕВОЈНО",
    },
    {
        label: "СЕНТА",
        value: "СЕНТА",
    },
    {
        label: "БАТКА",
        value: "БАТКА",
    },
    {
        label: "ТОРЊОШ",
        value: "ТОРЊОШ",
    },
    {
        label: "СЕЧАЊ",
        value: "СЕЧАЊ",
    },
    {
        label: "БАНАТСКА ДУБИЦА",
        value: "БАНАТСКА ДУБИЦА",
    },
    {
        label: "БОКА",
        value: "БОКА",
    },
    {
        label: "ЈАРКОВАЦ",
        value: "ЈАРКОВАЦ",
    },
    {
        label: "ЈАША ТОМИЋ",
        value: "ЈАША ТОМИЋ",
    },
    {
        label: "КОНАК",
        value: "КОНАК",
    },
    {
        label: "КРАЈИШНИК",
        value: "КРАЈИШНИК",
    },
    {
        label: "НЕУЗИНА",
        value: "НЕУЗИНА",
    },
    {
        label: "СУТЈЕСКА",
        value: "СУТЈЕСКА",
    },
    {
        label: "ШУРЈАН",
        value: "ШУРЈАН",
    },
    {
        label: "СЈЕНИЦА",
        value: "СЈЕНИЦА",
    },
    {
        label: "БАГАЧИЋЕ",
        value: "БАГАЧИЋЕ",
    },
    {
        label: "БАЧИЈА",
        value: "БАЧИЈА",
    },
    {
        label: "БОЖОВ ПОТОК",
        value: "БОЖОВ ПОТОК",
    },
    {
        label: "БУЂЕВО",
        value: "БУЂЕВО",
    },
    {
        label: "ВАПА",
        value: "ВАПА",
    },
    {
        label: "ВИШЊЕВА",
        value: "ВИШЊЕВА",
    },
    {
        label: "ВРСЈЕНИЦЕ",
        value: "ВРСЈЕНИЦЕ",
    },
    {
        label: "ГОРЊЕ ЛОПИЖЕ",
        value: "ГОРЊЕ ЛОПИЖЕ",
    },
    {
        label: "ДОЛИЋЕ",
        value: "ДОЛИЋЕ",
    },
    {
        label: "ДОЊЕ ГОРАЧИЋЕ",
        value: "ДОЊЕ ГОРАЧИЋЕ",
    },
    {
        label: "ДОЊЕ ЛОПИЖЕ",
        value: "ДОЊЕ ЛОПИЖЕ",
    },
    {
        label: "ДРАГОЈЛОВИЋЕ",
        value: "ДРАГОЈЛОВИЋЕ",
    },
    {
        label: "ДРАЖЕВИЋЕ",
        value: "ДРАЖЕВИЋЕ",
    },
    {
        label: "ДРУЖИНИЋЕ",
        value: "ДРУЖИНИЋЕ",
    },
    {
        label: "ДУЈКЕ",
        value: "ДУЈКЕ",
    },
    {
        label: "ДУНИШИЋЕ",
        value: "ДУНИШИЋЕ",
    },
    {
        label: "ЖАБРЕН",
        value: "ЖАБРЕН",
    },
    {
        label: "ЖИТНИЋЕ",
        value: "ЖИТНИЋЕ",
    },
    {
        label: "ЗАЈЕЧИЋЕ",
        value: "ЗАЈЕЧИЋЕ",
    },
    {
        label: "ЗАХУМСКО",
        value: "ЗАХУМСКО",
    },
    {
        label: "КАМЕШНИЦА",
        value: "КАМЕШНИЦА",
    },
    {
        label: "КИЈЕВЦИ",
        value: "КИЈЕВЦИ",
    },
    {
        label: "КЛАДНИЦА",
        value: "КЛАДНИЦА",
    },
    {
        label: "КРАЈИНОВИЋЕ",
        value: "КРАЈИНОВИЋЕ",
    },
    {
        label: "КРЋЕ",
        value: "КРЋЕ",
    },
    {
        label: "МАШОВИЋЕ",
        value: "МАШОВИЋЕ",
    },
    {
        label: "ПОНОРАЦ",
        value: "ПОНОРАЦ",
    },
    {
        label: "ПРАЉА",
        value: "ПРАЉА",
    },
    {
        label: "РАЖДАГИЊА",
        value: "РАЖДАГИЊА",
    },
    {
        label: "РАШКОВИЋЕ",
        value: "РАШКОВИЋЕ",
    },
    {
        label: "СТУП",
        value: "СТУП",
    },
    {
        label: "СУГУБИНЕ",
        value: "СУГУБИНЕ",
    },
    {
        label: "ТРИЈЕБИНЕ",
        value: "ТРИЈЕБИНЕ",
    },
    {
        label: "ТУЗИЊЕ",
        value: "ТУЗИЊЕ",
    },
    {
        label: "УВАЦ",
        value: "УВАЦ",
    },
    {
        label: "УГАО",
        value: "УГАО",
    },
    {
        label: "ФИЈУЉ",
        value: "ФИЈУЉ",
    },
    {
        label: "ЦАРИЧИНА",
        value: "ЦАРИЧИНА",
    },
    {
        label: "ЦЕТАНОВИЋЕ",
        value: "ЦЕТАНОВИЋЕ",
    },
    {
        label: "ЧЕДОВО",
        value: "ЧЕДОВО",
    },
    {
        label: "ШАРЕ",
        value: "ШАРЕ",
    },
    {
        label: "ШТАВАЉ",
        value: "ШТАВАЉ",
    },
    {
        label: "ШУШУРЕ",
        value: "ШУШУРЕ",
    },
    {
        label: "СМЕДЕРЕВО",
        value: "СМЕДЕРЕВО",
    },
    {
        label: "БАДЉЕВИЦА",
        value: "БАДЉЕВИЦА",
    },
    {
        label: "БИНОВАЦ",
        value: "БИНОВАЦ",
    },
    {
        label: "ВОДАЊ",
        value: "ВОДАЊ",
    },
    {
        label: "ВРАНОВО",
        value: "ВРАНОВО",
    },
    {
        label: "ДРУГОВАЦ I",
        value: "ДРУГОВАЦ I",
    },
    {
        label: "ДРУГОВАЦ II",
        value: "ДРУГОВАЦ II",
    },
    {
        label: "КОЛАРИ",
        value: "КОЛАРИ",
    },
    {
        label: "КУЛИЧ",
        value: "КУЛИЧ",
    },
    {
        label: "ЛАНДОЛ",
        value: "ЛАНДОЛ",
    },
    {
        label: "ЛИПЕ I",
        value: "ЛИПЕ I",
    },
    {
        label: "ЛИПЕ II",
        value: "ЛИПЕ II",
    },
    {
        label: "ЛУГАВЧИНА",
        value: "ЛУГАВЧИНА",
    },
    {
        label: "ЛУЊЕВАЦ",
        value: "ЛУЊЕВАЦ",
    },
    {
        label: "МАЛА КРСНА",
        value: "МАЛА КРСНА",
    },
    {
        label: "МАЛО ОРАШЈЕ",
        value: "МАЛО ОРАШЈЕ",
    },
    {
        label: "ОСИПАОНИЦА",
        value: "ОСИПАОНИЦА",
    },
    {
        label: "ПЕТРИЈЕВО",
        value: "ПЕТРИЈЕВО",
    },
    {
        label: "РАДИНАЦ",
        value: "РАДИНАЦ",
    },
    {
        label: "РАЉА",
        value: "РАЉА",
    },
    {
        label: "САРАОРЦИ",
        value: "САРАОРЦИ",
    },
    {
        label: "СЕОНЕ",
        value: "СЕОНЕ",
    },
    {
        label: "СУВОДОЛ",
        value: "СУВОДОЛ",
    },
    {
        label: "УДОВИЦЕ",
        value: "УДОВИЦЕ",
    },
    {
        label: "ШАЛИНАЦ",
        value: "ШАЛИНАЦ",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА",
        value: "СМЕДЕРЕВСКА ПАЛАНКА",
    },
    {
        label: "АЗАЊА",
        value: "АЗАЊА",
    },
    {
        label: "БАНИЧИНА",
        value: "БАНИЧИНА",
    },
    {
        label: "БАЧИНАЦ",
        value: "БАЧИНАЦ",
    },
    {
        label: "БАШИН",
        value: "БАШИН",
    },
    {
        label: "ГЛИБОВАЦ I",
        value: "ГЛИБОВАЦ I",
    },
    {
        label: "ГЛИБОВАЦ II",
        value: "ГЛИБОВАЦ II",
    },
    {
        label: "ГОЛОБОК",
        value: "ГОЛОБОК",
    },
    {
        label: "ГРЧАЦ",
        value: "ГРЧАЦ",
    },
    {
        label: "ГРЧАЦ ПАЛАНКА",
        value: "ГРЧАЦ ПАЛАНКА",
    },
    {
        label: "КУСАДАК",
        value: "КУСАДАК",
    },
    {
        label: "МРАМОРАЦ",
        value: "МРАМОРАЦ",
    },
    {
        label: "ПРИДВОРИЦЕ",
        value: "ПРИДВОРИЦЕ",
    },
    {
        label: "СЕЛЕВАЦ",
        value: "СЕЛЕВАЦ",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА I",
        value: "СМЕДЕРЕВСКА ПАЛАНКА I",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА II",
        value: "СМЕДЕРЕВСКА ПАЛАНКА II",
    },
    {
        label: "СМЕДЕРЕВСКА ПАЛАНКА III",
        value: "СМЕДЕРЕВСКА ПАЛАНКА III",
    },
    {
        label: "СТОЈАЧАК",
        value: "СТОЈАЧАК",
    },
    {
        label: "СОКОБАЊА",
        value: "СОКОБАЊА",
    },
    {
        label: "БЛЕНДИЈА",
        value: "БЛЕНДИЈА",
    },
    {
        label: "БОГДИНАЦ",
        value: "БОГДИНАЦ",
    },
    {
        label: "ВРМЏА",
        value: "ВРМЏА",
    },
    {
        label: "ДУГО ПОЉЕ",
        value: "ДУГО ПОЉЕ",
    },
    {
        label: "ЖУЧКОВАЦ",
        value: "ЖУЧКОВАЦ",
    },
    {
        label: "МИЛУШИНАЦ",
        value: "МИЛУШИНАЦ",
    },
    {
        label: "МУЖИНАЦ",
        value: "МУЖИНАЦ",
    },
    {
        label: "НИКОЛИНАЦ",
        value: "НИКОЛИНАЦ",
    },
    {
        label: "ПОРУЖНИЦА",
        value: "ПОРУЖНИЦА",
    },
    {
        label: "РАДЕНКОВАЦ",
        value: "РАДЕНКОВАЦ",
    },
    {
        label: "РУЈЕВИЦА",
        value: "РУЈЕВИЦА",
    },
    {
        label: "СЕСАЛАЦ",
        value: "СЕСАЛАЦ",
    },
    {
        label: "ТРУБАРЕВАЦ",
        value: "ТРУБАРЕВАЦ",
    },
    {
        label: "СОМБОР",
        value: "СОМБОР",
    },
    {
        label: "АЛЕКСА ШАНТИЋ",
        value: "АЛЕКСА ШАНТИЋ",
    },
    {
        label: "БАЧКИ БРЕГ",
        value: "БАЧКИ БРЕГ",
    },
    {
        label: "БАЧКИ МОНОШТОР",
        value: "БАЧКИ МОНОШТОР",
    },
    {
        label: "БЕЗДАН",
        value: "БЕЗДАН",
    },
    {
        label: "ГАКОВО",
        value: "ГАКОВО",
    },
    {
        label: "ДОРОСЛОВО",
        value: "ДОРОСЛОВО",
    },
    {
        label: "КЉАЈИЋЕВО",
        value: "КЉАЈИЋЕВО",
    },
    {
        label: "КОЛУТ",
        value: "КОЛУТ",
    },
    {
        label: "РАСТИНА",
        value: "РАСТИНА",
    },
    {
        label: "РИЂИЦА",
        value: "РИЂИЦА",
    },
    {
        label: "СВЕТОЗАР МИЛЕТИЋ",
        value: "СВЕТОЗАР МИЛЕТИЋ",
    },
    {
        label: "СОМБОР I",
        value: "СОМБОР I",
    },
    {
        label: "СОМБОР II",
        value: "СОМБОР II",
    },
    {
        label: "СТАНИШИЋ",
        value: "СТАНИШИЋ",
    },
    {
        label: "ТЕЛЕЧКА",
        value: "ТЕЛЕЧКА",
    },
    {
        label: "ЧОНОПЉА",
        value: "ЧОНОПЉА",
    },
    {
        label: "БАБЕ",
        value: "БАБЕ",
    },
    {
        label: "ДУЧИНА",
        value: "ДУЧИНА",
    },
    {
        label: "ЂУРИНЦИ",
        value: "ЂУРИНЦИ",
    },
    {
        label: "МАЛА ИВАНЧА",
        value: "МАЛА ИВАНЧА",
    },
    {
        label: "МАЛИ ПОЖАРЕВАЦ",
        value: "МАЛИ ПОЖАРЕВАЦ",
    },
    {
        label: "НЕМЕНИКУЋЕ",
        value: "НЕМЕНИКУЋЕ",
    },
    {
        label: "ПАРЦАНИ",
        value: "ПАРЦАНИ",
    },
    {
        label: "СРБОБРАН",
        value: "СРБОБРАН",
    },
    {
        label: "НАДАЉ I",
        value: "НАДАЉ I",
    },
    {
        label: "НАДАЉ II",
        value: "НАДАЉ II",
    },
    {
        label: "СРЕМСКА МИТРОВИЦА",
        value: "СРЕМСКА МИТРОВИЦА",
    },
    {
        label: "БЕШЕНОВО ПРЊАВОР",
        value: "БЕШЕНОВО ПРЊАВОР",
    },
    {
        label: "БЕШЕНОВО СЕЛО",
        value: "БЕШЕНОВО СЕЛО",
    },
    {
        label: "БОСУТ",
        value: "БОСУТ",
    },
    {
        label: "ВЕЛИКИ РАДИНЦИ",
        value: "ВЕЛИКИ РАДИНЦИ",
    },
    {
        label: "ГРГУРЕВЦИ",
        value: "ГРГУРЕВЦИ",
    },
    {
        label: "ДИВОШ",
        value: "ДИВОШ",
    },
    {
        label: "ЗАСАВИЦА",
        value: "ЗАСАВИЦА",
    },
    {
        label: "ЈАРАК",
        value: "ЈАРАК",
    },
    {
        label: "КУЗМИН",
        value: "КУЗМИН",
    },
    {
        label: "ЛАЋАРАК",
        value: "ЛАЋАРАК",
    },
    {
        label: "ЛЕЖИМИР",
        value: "ЛЕЖИМИР",
    },
    {
        label: "МАНЂЕЛОС",
        value: "МАНЂЕЛОС",
    },
    {
        label: "МАРТИНЦИ",
        value: "МАРТИНЦИ",
    },
    {
        label: "МАЧВАНСКА МИТРОВИЦА",
        value: "МАЧВАНСКА МИТРОВИЦА",
    },
    {
        label: "НОЋАЈ",
        value: "НОЋАЈ",
    },
    {
        label: "РАДЕНКОВИЋ",
        value: "РАДЕНКОВИЋ",
    },
    {
        label: "САЛАШ НОЋАЈСКИ",
        value: "САЛАШ НОЋАЈСКИ",
    },
    {
        label: "СРЕМСКА РАЧА",
        value: "СРЕМСКА РАЧА",
    },
    {
        label: "ЧАЛМА",
        value: "ЧАЛМА",
    },
    {
        label: "ШАШИНЦИ",
        value: "ШАШИНЦИ",
    },
    {
        label: "ШУЉАМ",
        value: "ШУЉАМ",
    },
    {
        label: "СРЕМСКИ КАРЛОВЦИ",
        value: "СРЕМСКИ КАРЛОВЦИ",
    },
    {
        label: "СТАРА ПАЗОВА",
        value: "СТАРА ПАЗОВА",
    },
    {
        label: "БЕЛЕГИШ",
        value: "БЕЛЕГИШ",
    },
    {
        label: "ВОЈКА",
        value: "ВОЈКА",
    },
    {
        label: "ГОЛУБИНЦИ",
        value: "ГОЛУБИНЦИ",
    },
    {
        label: "КРЊЕШЕВЦИ",
        value: "КРЊЕШЕВЦИ",
    },
    {
        label: "НОВА ПАЗОВА",
        value: "НОВА ПАЗОВА",
    },
    {
        label: "НОВИ БАНОВЦИ",
        value: "НОВИ БАНОВЦИ",
    },
    {
        label: "СТАРИ БАНОВЦИ",
        value: "СТАРИ БАНОВЦИ",
    },
    {
        label: "СУРДУК",
        value: "СУРДУК",
    },
    {
        label: "СТАРИ ГРАД",
        value: "СТАРИ ГРАД",
    },
    {
        label: "БАЈМОК",
        value: "БАЈМОК",
    },
    {
        label: "БАЧКИ ВИНОГРАДИ",
        value: "БАЧКИ ВИНОГРАДИ",
    },
    {
        label: "БИКОВО",
        value: "БИКОВО",
    },
    {
        label: "ДОЊИ ГРАД",
        value: "ДОЊИ ГРАД",
    },
    {
        label: "ЂУРЂИН",
        value: "ЂУРЂИН",
    },
    {
        label: "ЖЕДНИК",
        value: "ЖЕДНИК",
    },
    {
        label: "НОВИ ГРАД",
        value: "НОВИ ГРАД",
    },
    {
        label: "ПАЛИЋ",
        value: "ПАЛИЋ",
    },
    {
        label: "ТАВАНКУТ",
        value: "ТАВАНКУТ",
    },
    {
        label: "ЧАНТАВИР",
        value: "ЧАНТАВИР",
    },
    {
        label: "СУРДУЛИЦА",
        value: "СУРДУЛИЦА",
    },
    {
        label: "АЛАКИНЦЕ",
        value: "АЛАКИНЦЕ",
    },
    {
        label: "БАЦИЈЕВЦЕ",
        value: "БАЦИЈЕВЦЕ",
    },
    {
        label: "БИНОВЦЕ",
        value: "БИНОВЦЕ",
    },
    {
        label: "БИТВРЂА",
        value: "БИТВРЂА",
    },
    {
        label: "БОЖИЦА",
        value: "БОЖИЦА",
    },
    {
        label: "ВЛАСИНА ОКРУГЛИЦА",
        value: "ВЛАСИНА ОКРУГЛИЦА",
    },
    {
        label: "ВЛАСИНА РИД",
        value: "ВЛАСИНА РИД",
    },
    {
        label: "ВЛАСИНА СТОЈКОВИЋЕВА",
        value: "ВЛАСИНА СТОЈКОВИЋЕВА",
    },
    {
        label: "ВУЧАДЕЛЦЕ",
        value: "ВУЧАДЕЛЦЕ",
    },
    {
        label: "ГОРЊА КОЗНИЦА",
        value: "ГОРЊА КОЗНИЦА",
    },
    {
        label: "ГОРЊЕ РОМАНОВЦЕ",
        value: "ГОРЊЕ РОМАНОВЦЕ",
    },
    {
        label: "ГРОЗНАТОВЦИ",
        value: "ГРОЗНАТОВЦИ",
    },
    {
        label: "ДАЊИНО СЕЛО",
        value: "ДАЊИНО СЕЛО",
    },
    {
        label: "ДИКАВА",
        value: "ДИКАВА",
    },
    {
        label: "ДОЊЕ РОМАНОВЦЕ",
        value: "ДОЊЕ РОМАНОВЦЕ",
    },
    {
        label: "ДРАЈИНЦИ",
        value: "ДРАЈИНЦИ",
    },
    {
        label: "ДУГИ ДЕЛ",
        value: "ДУГИ ДЕЛ",
    },
    {
        label: "ДУГОЈНИЦА",
        value: "ДУГОЈНИЦА",
    },
    {
        label: "ЗАГУЖАЊЕ",
        value: "ЗАГУЖАЊЕ",
    },
    {
        label: "КАЛАБОВЦЕ",
        value: "КАЛАБОВЦЕ",
    },
    {
        label: "КОЛУНИЦА",
        value: "КОЛУНИЦА",
    },
    {
        label: "КОСТРОШЕВЦИ",
        value: "КОСТРОШЕВЦИ",
    },
    {
        label: "ЛЕСКОВА БАРА",
        value: "ЛЕСКОВА БАРА",
    },
    {
        label: "МАСУРИЦА",
        value: "МАСУРИЦА",
    },
    {
        label: "МАЧКАТИЦА",
        value: "МАЧКАТИЦА",
    },
    {
        label: "ПАЉА",
        value: "ПАЉА",
    },
    {
        label: "РЂАВИЦА",
        value: "РЂАВИЦА",
    },
    {
        label: "СТРЕЗИМИРОВЦИ",
        value: "СТРЕЗИМИРОВЦИ",
    },
    {
        label: "СУВОЈНИЦА",
        value: "СУВОЈНИЦА",
    },
    {
        label: "СУХИ ДОЛ",
        value: "СУХИ ДОЛ",
    },
    {
        label: "ТОПЛИ ДОЛ",
        value: "ТОПЛИ ДОЛ",
    },
    {
        label: "СУРЧИН",
        value: "СУРЧИН",
    },
    {
        label: "БЕЧМЕН",
        value: "БЕЧМЕН",
    },
    {
        label: "БОЉЕВЦИ",
        value: "БОЉЕВЦИ",
    },
    {
        label: "ДОБАНОВЦИ",
        value: "ДОБАНОВЦИ",
    },
    {
        label: "ЈАКОВО",
        value: "ЈАКОВО",
    },
    {
        label: "ПЕТРОВЧИЋ",
        value: "ПЕТРОВЧИЋ",
    },
    {
        label: "ПРОГАР",
        value: "ПРОГАР",
    },
    {
        label: "ТЕМЕРИН",
        value: "ТЕМЕРИН",
    },
    {
        label: "БАЧКИ ЈАРАК",
        value: "БАЧКИ ЈАРАК",
    },
    {
        label: "СИРИГ",
        value: "СИРИГ",
    },
    {
        label: "ТИТЕЛ",
        value: "ТИТЕЛ",
    },
    {
        label: "ВИЛОВО",
        value: "ВИЛОВО",
    },
    {
        label: "ГАРДИНОВЦИ",
        value: "ГАРДИНОВЦИ",
    },
    {
        label: "ЛОК",
        value: "ЛОК",
    },
    {
        label: "МОШОРИН",
        value: "МОШОРИН",
    },
    {
        label: "ШАЈКАШ",
        value: "ШАЈКАШ",
    },
    {
        label: "БЕЛОСАВЦИ",
        value: "БЕЛОСАВЦИ",
    },
    {
        label: "БЛАЗНАВА",
        value: "БЛАЗНАВА",
    },
    {
        label: "БОЖУРЊА",
        value: "БОЖУРЊА",
    },
    {
        label: "ВОЈКОВЦИ",
        value: "ВОЈКОВЦИ",
    },
    {
        label: "ГОРЊА ШАТОРЊА",
        value: "ГОРЊА ШАТОРЊА",
    },
    {
        label: "ГОРОВИЧ",
        value: "ГОРОВИЧ",
    },
    {
        label: "ГУРИШЕВЦИ",
        value: "ГУРИШЕВЦИ",
    },
    {
        label: "ДОЊА ТРЕШЊЕВИЦА",
        value: "ДОЊА ТРЕШЊЕВИЦА",
    },
    {
        label: "ДОЊА ШАТОРЊА",
        value: "ДОЊА ШАТОРЊА",
    },
    {
        label: "ЖАБАРЕ",
        value: "ЖАБАРЕ",
    },
    {
        label: "ЗАГОРИЦА",
        value: "ЗАГОРИЦА",
    },
    {
        label: "ЈАРМЕНОВЦИ",
        value: "ЈАРМЕНОВЦИ",
    },
    {
        label: "ЈЕЛЕНАЦ",
        value: "ЈЕЛЕНАЦ",
    },
    {
        label: "КЛОКА",
        value: "КЛОКА",
    },
    {
        label: "МАНОЈЛОВЦИ",
        value: "МАНОЈЛОВЦИ",
    },
    {
        label: "МАСКАР",
        value: "МАСКАР",
    },
    {
        label: "НАТАЛИНЦИ",
        value: "НАТАЛИНЦИ",
    },
    {
        label: "ОВСИШТЕ",
        value: "ОВСИШТЕ",
    },
    {
        label: "ПЛАСКОВАЦ",
        value: "ПЛАСКОВАЦ",
    },
    {
        label: "СВЕТЛИЋ",
        value: "СВЕТЛИЋ",
    },
    {
        label: "ТОПОЛА (ВАРОШИЦА)",
        value: "ТОПОЛА (ВАРОШИЦА)",
    },
    {
        label: "ТОПОЛА (СЕЛО)",
        value: "ТОПОЛА (СЕЛО)",
    },
    {
        label: "БАРБАЦЕ",
        value: "БАРБАЦЕ",
    },
    {
        label: "ВЛАДОВЦЕ",
        value: "ВЛАДОВЦЕ",
    },
    {
        label: "ГОЛОЧЕВАЦ",
        value: "ГОЛОЧЕВАЦ",
    },
    {
        label: "ГОРНОВАЦ",
        value: "ГОРНОВАЦ",
    },
    {
        label: "ГОРЊА ТРНИЦА",
        value: "ГОРЊА ТРНИЦА",
    },
    {
        label: "ГОРЊИ СТАЈЕВАЦ",
        value: "ГОРЊИ СТАЈЕВАЦ",
    },
    {
        label: "ДЕЈАНЦЕ",
        value: "ДЕЈАНЦЕ",
    },
    {
        label: "ДОЊА ТРНИЦА",
        value: "ДОЊА ТРНИЦА",
    },
    {
        label: "ДОЊИ СТАЈЕВАЦ",
        value: "ДОЊИ СТАЈЕВАЦ",
    },
    {
        label: "ДУМБИЈА",
        value: "ДУМБИЈА",
    },
    {
        label: "ЂЕРЕКАРЦЕ",
        value: "ЂЕРЕКАРЦЕ",
    },
    {
        label: "ЗЛАДОВЦЕ",
        value: "ЗЛАДОВЦЕ",
    },
    {
        label: "КАЛОВО",
        value: "КАЛОВО",
    },
    {
        label: "КОЗЈИ ДОЛ",
        value: "КОЗЈИ ДОЛ",
    },
    {
        label: "ЛЕСНИЦА",
        value: "ЛЕСНИЦА",
    },
    {
        label: "МАРГАНЦЕ",
        value: "МАРГАНЦЕ",
    },
    {
        label: "МЕЗДРАЈА",
        value: "МЕЗДРАЈА",
    },
    {
        label: "НОВИ ГЛОГ",
        value: "НОВИ ГЛОГ",
    },
    {
        label: "ПРОЛЕСЈЕ",
        value: "ПРОЛЕСЈЕ",
    },
    {
        label: "РАДОВНИЦА",
        value: "РАДОВНИЦА",
    },
    {
        label: "РАЈЧЕВЦЕ",
        value: "РАЈЧЕВЦЕ",
    },
    {
        label: "СУРЛИЦА",
        value: "СУРЛИЦА",
    },
    {
        label: "ЦРВЕНИ ГРАД",
        value: "ЦРВЕНИ ГРАД",
    },
    {
        label: "ЦРНА РЕКА",
        value: "ЦРНА РЕКА",
    },
    {
        label: "ЦРНОВЦЕ",
        value: "ЦРНОВЦЕ",
    },
    {
        label: "ШАЈИНЦЕ",
        value: "ШАЈИНЦЕ",
    },
    {
        label: "ШАПРАНЦЕ",
        value: "ШАПРАНЦЕ",
    },
    {
        label: "ШИРОКА ПЛАНИНА",
        value: "ШИРОКА ПЛАНИНА",
    },
    {
        label: "ШУМАТА ТРНИЦА",
        value: "ШУМАТА ТРНИЦА",
    },
    {
        label: "ТРСТЕНИК",
        value: "ТРСТЕНИК",
    },
    {
        label: "БОГДАЊЕ",
        value: "БОГДАЊЕ",
    },
    {
        label: "БОЖУРЕВАЦ",
        value: "БОЖУРЕВАЦ",
    },
    {
        label: "БРЕСНО ПОЉЕ",
        value: "БРЕСНО ПОЉЕ",
    },
    {
        label: "ВЕЛИКА ДРЕНОВА",
        value: "ВЕЛИКА ДРЕНОВА",
    },
    {
        label: "ВЕЛУЋЕ",
        value: "ВЕЛУЋЕ",
    },
    {
        label: "ГОРЊА ЦРНИШАВА",
        value: "ГОРЊА ЦРНИШАВА",
    },
    {
        label: "ГОРЊИ ДУБИЧ",
        value: "ГОРЊИ ДУБИЧ",
    },
    {
        label: "ГОРЊИ РИБНИК",
        value: "ГОРЊИ РИБНИК",
    },
    {
        label: "ДОЊА ЦРНИШАВА",
        value: "ДОЊА ЦРНИШАВА",
    },
    {
        label: "ДОЊИ ДУБИЧ",
        value: "ДОЊИ ДУБИЧ",
    },
    {
        label: "ДОЊИ РИБНИК",
        value: "ДОЊИ РИБНИК",
    },
    {
        label: "ЈАСИКОВИЦА",
        value: "ЈАСИКОВИЦА",
    },
    {
        label: "КАМЕЊАЧА",
        value: "КАМЕЊАЧА",
    },
    {
        label: "ЛОБОДЕР",
        value: "ЛОБОДЕР",
    },
    {
        label: "ЛОЗНА",
        value: "ЛОЗНА",
    },
    {
        label: "МАЛА ДРЕНОВА",
        value: "МАЛА ДРЕНОВА",
    },
    {
        label: "МАЛА СУГУБИНА",
        value: "МАЛА СУГУБИНА",
    },
    {
        label: "МИЈАЈЛОВАЦ",
        value: "МИЈАЈЛОВАЦ",
    },
    {
        label: "МИЛУТОВАЦ",
        value: "МИЛУТОВАЦ",
    },
    {
        label: "ОМАШНИЦА",
        value: "ОМАШНИЦА",
    },
    {
        label: "ПАЈСАК",
        value: "ПАЈСАК",
    },
    {
        label: "ПОЉНА",
        value: "ПОЉНА",
    },
    {
        label: "ПОПИНА",
        value: "ПОПИНА",
    },
    {
        label: "ПОЧЕКОВИНА",
        value: "ПОЧЕКОВИНА",
    },
    {
        label: "РАЈИНАЦ",
        value: "РАЈИНАЦ",
    },
    {
        label: "РИЂЕВШТИЦА",
        value: "РИЂЕВШТИЦА",
    },
    {
        label: "РИЉАЦ",
        value: "РИЉАЦ",
    },
    {
        label: "РУЈИШНИК",
        value: "РУЈИШНИК",
    },
    {
        label: "СТАРИ ТРСТЕНИК",
        value: "СТАРИ ТРСТЕНИК",
    },
    {
        label: "СТОПАЊА",
        value: "СТОПАЊА",
    },
    {
        label: "СТУБЛИЦА",
        value: "СТУБЛИЦА",
    },
    {
        label: "ТОБОЛАЦ",
        value: "ТОБОЛАЦ",
    },
    {
        label: "УГЉАРЕВО",
        value: "УГЉАРЕВО",
    },
    {
        label: "ЧАИРИ",
        value: "ЧАИРИ",
    },
    {
        label: "ТУТИН",
        value: "ТУТИН",
    },
    {
        label: "АРАПОВИЋЕ",
        value: "АРАПОВИЋЕ",
    },
    {
        label: "БАЋИЦА",
        value: "БАЋИЦА",
    },
    {
        label: "БОРОШТИЦА",
        value: "БОРОШТИЦА",
    },
    {
        label: "БРАЋАК",
        value: "БРАЋАК",
    },
    {
        label: "БУЈКОВИЋЕ",
        value: "БУЈКОВИЋЕ",
    },
    {
        label: "ВЕЉЕ ПОЉЕ",
        value: "ВЕЉЕ ПОЉЕ",
    },
    {
        label: "ВЕСЕНИЋЕ",
        value: "ВЕСЕНИЋЕ",
    },
    {
        label: "ГЛОГОВИК",
        value: "ГЛОГОВИК",
    },
    {
        label: "ГЛУХАВИЦА",
        value: "ГЛУХАВИЦА",
    },
    {
        label: "ГНИЛА",
        value: "ГНИЛА",
    },
    {
        label: "ГОДОВО",
        value: "ГОДОВО",
    },
    {
        label: "ДЕВРЕЧ",
        value: "ДЕВРЕЧ",
    },
    {
        label: "ДЕЛИМЕЂЕ",
        value: "ДЕЛИМЕЂЕ",
    },
    {
        label: "ДЕТАНЕ",
        value: "ДЕТАНЕ",
    },
    {
        label: "ДОБРИ ДУБ",
        value: "ДОБРИ ДУБ",
    },
    {
        label: "ДОБРИЊЕ",
        value: "ДОБРИЊЕ",
    },
    {
        label: "ДРАГА",
        value: "ДРАГА",
    },
    {
        label: "ДУЛЕБЕ",
        value: "ДУЛЕБЕ",
    },
    {
        label: "ЖИРЧЕ",
        value: "ЖИРЧЕ",
    },
    {
        label: "ЖУПА",
        value: "ЖУПА",
    },
    {
        label: "ЗАПАДНИ МОЈСТИР",
        value: "ЗАПАДНИ МОЈСТИР",
    },
    {
        label: "ИСТОЧНИ МОЈСТИР",
        value: "ИСТОЧНИ МОЈСТИР",
    },
    {
        label: "ЈЕЗГРОВИЋЕ",
        value: "ЈЕЗГРОВИЋЕ",
    },
    {
        label: "ЈЕЛИЋЕ",
        value: "ЈЕЛИЋЕ",
    },
    {
        label: "ЈУЖНИ КОЧАРНИК",
        value: "ЈУЖНИ КОЧАРНИК",
    },
    {
        label: "КОНИЧЕ",
        value: "КОНИЧЕ",
    },
    {
        label: "ЛЕСКОВА",
        value: "ЛЕСКОВА",
    },
    {
        label: "ЛИПИЦА",
        value: "ЛИПИЦА",
    },
    {
        label: "МЕЛАЈЕ",
        value: "МЕЛАЈЕ",
    },
    {
        label: "МИТРОВА",
        value: "МИТРОВА",
    },
    {
        label: "МОРАНИ",
        value: "МОРАНИ",
    },
    {
        label: "НАБОЈЕ",
        value: "НАБОЈЕ",
    },
    {
        label: "НАМГА",
        value: "НАМГА",
    },
    {
        label: "ОРЉЕ",
        value: "ОРЉЕ",
    },
    {
        label: "ПАЉЕВО",
        value: "ПАЉЕВО",
    },
    {
        label: "ПОКРВЕНИК",
        value: "ПОКРВЕНИК",
    },
    {
        label: "ПРУЖАЊ",
        value: "ПРУЖАЊ",
    },
    {
        label: "РАДУХОВЦЕ",
        value: "РАДУХОВЦЕ",
    },
    {
        label: "РАДУША",
        value: "РАДУША",
    },
    {
        label: "РЕЖЕВИЋЕ",
        value: "РЕЖЕВИЋЕ",
    },
    {
        label: "РИБАРИЋЕ",
        value: "РИБАРИЋЕ",
    },
    {
        label: "САШ",
        value: "САШ",
    },
    {
        label: "СТАРЧЕВИЋЕ",
        value: "СТАРЧЕВИЋЕ",
    },
    {
        label: "ЦРНИШ",
        value: "ЦРНИШ",
    },
    {
        label: "ЧАРОВИНА",
        value: "ЧАРОВИНА",
    },
    {
        label: "ЧУКОТЕ",
        value: "ЧУКОТЕ",
    },
    {
        label: "ШПИЉАНИ",
        value: "ШПИЉАНИ",
    },
    {
        label: "ЋИЋЕВАЦ",
        value: "ЋИЋЕВАЦ",
    },
    {
        label: "ГРАД СТАЛАЋ",
        value: "ГРАД СТАЛАЋ",
    },
    {
        label: "ЛУЧИНА",
        value: "ЛУЧИНА",
    },
    {
        label: "МОЈСИЊЕ",
        value: "МОЈСИЊЕ",
    },
    {
        label: "МРЗЕНИЦА",
        value: "МРЗЕНИЦА",
    },
    {
        label: "СТАЛАЋ",
        value: "СТАЛАЋ",
    },
    {
        label: "ТРУБАРЕВО",
        value: "ТРУБАРЕВО",
    },
    {
        label: "ЋИЋЕВАЦ -ГРАД",
        value: "ЋИЋЕВАЦ -ГРАД",
    },
    {
        label: "ЋУПРИЈА",
        value: "ЋУПРИЈА",
    },
    {
        label: "БАТИНАЦ",
        value: "БАТИНАЦ",
    },
    {
        label: "БИГРЕНИЦА",
        value: "БИГРЕНИЦА",
    },
    {
        label: "ВИРИНЕ",
        value: "ВИРИНЕ",
    },
    {
        label: "ДВОРИЦА",
        value: "ДВОРИЦА",
    },
    {
        label: "ИВАНКОВАЦ",
        value: "ИВАНКОВАЦ",
    },
    {
        label: "ИСАКОВО",
        value: "ИСАКОВО",
    },
    {
        label: "КОВАНИЦА",
        value: "КОВАНИЦА",
    },
    {
        label: "КРУШАР",
        value: "КРУШАР",
    },
    {
        label: "МИЈАТОВАЦ",
        value: "МИЈАТОВАЦ",
    },
    {
        label: "ОСТРИКОВАЦ",
        value: "ОСТРИКОВАЦ",
    },
    {
        label: "ПАЉАНЕ",
        value: "ПАЉАНЕ",
    },
    {
        label: "СЕЊЕ",
        value: "СЕЊЕ",
    },
    {
        label: "СУПСКА",
        value: "СУПСКА",
    },
    {
        label: "ЋУПРИЈА (ВАН ГРАД)",
        value: "ЋУПРИЈА (ВАН ГРАД)",
    },
    {
        label: "ЋУПРИЈА (ГРАД)",
        value: "ЋУПРИЈА (ГРАД)",
    },
    {
        label: "УБ",
        value: "УБ",
    },
    {
        label: "БАЊАНИ",
        value: "БАЊАНИ",
    },
    {
        label: "БРГУЛЕ",
        value: "БРГУЛЕ",
    },
    {
        label: "ВРХОВИНЕ",
        value: "ВРХОВИНЕ",
    },
    {
        label: "ВУКОНА",
        value: "ВУКОНА",
    },
    {
        label: "ГВОЗДЕНОВИЋ",
        value: "ГВОЗДЕНОВИЋ",
    },
    {
        label: "ГУЊЕВАЦ",
        value: "ГУЊЕВАЦ",
    },
    {
        label: "ДОКМИР",
        value: "ДОКМИР",
    },
    {
        label: "ЗВИЗДАР",
        value: "ЗВИЗДАР",
    },
    {
        label: "КАЛЕНИЋ",
        value: "КАЛЕНИЋ",
    },
    {
        label: "КАЛИНОВАЦ",
        value: "КАЛИНОВАЦ",
    },
    {
        label: "КОЖУАР",
        value: "КОЖУАР",
    },
    {
        label: "КРШНА ГЛАВА",
        value: "КРШНА ГЛАВА",
    },
    {
        label: "ЛИСО ПОЉЕ",
        value: "ЛИСО ПОЉЕ",
    },
    {
        label: "ЛОНЧАНИК",
        value: "ЛОНЧАНИК",
    },
    {
        label: "МИЛОРЦИ",
        value: "МИЛОРЦИ",
    },
    {
        label: "МУРГАШ",
        value: "МУРГАШ",
    },
    {
        label: "ПАЉУВИ",
        value: "ПАЉУВИ",
    },
    {
        label: "ПАМБУКОВИЦА",
        value: "ПАМБУКОВИЦА",
    },
    {
        label: "РАДЉЕВО",
        value: "РАДЉЕВО",
    },
    {
        label: "РУКЛАДА",
        value: "РУКЛАДА",
    },
    {
        label: "СТУБЛЕНИЦА",
        value: "СТУБЛЕНИЦА",
    },
    {
        label: "ТВРДОЈЕВАЦ",
        value: "ТВРДОЈЕВАЦ",
    },
    {
        label: "ТРЛИЋ",
        value: "ТРЛИЋ",
    },
    {
        label: "ТРЊАЦИ",
        value: "ТРЊАЦИ",
    },
    {
        label: "ТУЛАРИ",
        value: "ТУЛАРИ",
    },
    {
        label: "ЧУЧУГЕ",
        value: "ЧУЧУГЕ",
    },
    {
        label: "ШАРБАНЕ",
        value: "ШАРБАНЕ",
    },
    {
        label: "УЖИЦЕ",
        value: "УЖИЦЕ",
    },
    {
        label: "БИОСКА",
        value: "БИОСКА",
    },
    {
        label: "БУАР",
        value: "БУАР",
    },
    {
        label: "ВРУТЦИ",
        value: "ВРУТЦИ",
    },
    {
        label: "ГОРЈАНИ",
        value: "ГОРЈАНИ",
    },
    {
        label: "ГОСТИНИЦА",
        value: "ГОСТИНИЦА",
    },
    {
        label: "ГУБИН ДО",
        value: "ГУБИН ДО",
    },
    {
        label: "ДОБРОДО",
        value: "ДОБРОДО",
    },
    {
        label: "ДРЕЖНИК",
        value: "ДРЕЖНИК",
    },
    {
        label: "ДРИЈЕТАЊ",
        value: "ДРИЈЕТАЊ",
    },
    {
        label: "ДУБОКО",
        value: "ДУБОКО",
    },
    {
        label: "ЗБОЈШТИЦА",
        value: "ЗБОЈШТИЦА",
    },
    {
        label: "ЗЛАКУСА",
        value: "ЗЛАКУСА",
    },
    {
        label: "КАРАН",
        value: "КАРАН",
    },
    {
        label: "КАЧЕР",
        value: "КАЧЕР",
    },
    {
        label: "КРВАВЦИ",
        value: "КРВАВЦИ",
    },
    {
        label: "КРЕМНА",
        value: "КРЕМНА",
    },
    {
        label: "ЛЕЛИЋИ",
        value: "ЛЕЛИЋИ",
    },
    {
        label: "ЉУБАЊЕ",
        value: "ЉУБАЊЕ",
    },
    {
        label: "МОКРА ГОРА",
        value: "МОКРА ГОРА",
    },
    {
        label: "НИКОЈЕВИЋИ",
        value: "НИКОЈЕВИЋИ",
    },
    {
        label: "ПОНИКОВИЦА",
        value: "ПОНИКОВИЦА",
    },
    {
        label: "ПОТОЧАЊЕ",
        value: "ПОТОЧАЊЕ",
    },
    {
        label: "ПОТПЕЋЕ",
        value: "ПОТПЕЋЕ",
    },
    {
        label: "РИБАШЕВИНА",
        value: "РИБАШЕВИНА",
    },
    {
        label: "СКРЖУТИ",
        value: "СКРЖУТИ",
    },
    {
        label: "СТАПАРИ",
        value: "СТАПАРИ",
    },
    {
        label: "ЦРВЕНИ КРСТ",
        value: "ЦРВЕНИ КРСТ",
    },
    {
        label: "БЕРЧИНАЦ",
        value: "БЕРЧИНАЦ",
    },
    {
        label: "ВЕЛЕ ПОЉЕ",
        value: "ВЕЛЕ ПОЉЕ",
    },
    {
        label: "ВРТИШТЕ",
        value: "ВРТИШТЕ",
    },
    {
        label: "ГОРЊИ КОМРЕН",
        value: "ГОРЊИ КОМРЕН",
    },
    {
        label: "ДОЊИ КОМРЕН",
        value: "ДОЊИ КОМРЕН",
    },
    {
        label: "КРАВЉЕ",
        value: "КРАВЉЕ",
    },
    {
        label: 'НИШ "ЦРВЕНИ КРСТ"',
        value: 'НИШ "ЦРВЕНИ КРСТ"',
    },
    {
        label: "ПАЛИГРАЦЕ",
        value: "ПАЛИГРАЦЕ",
    },
    {
        label: "ПАЉИНА",
        value: "ПАЉИНА",
    },
    {
        label: "РУЈНИК",
        value: "РУЈНИК",
    },
    {
        label: "СЕЧАНИЦА",
        value: "СЕЧАНИЦА",
    },
    {
        label: "СУПОВАЦ",
        value: "СУПОВАЦ",
    },
    {
        label: "ТРУПАЛЕ",
        value: "ТРУПАЛЕ",
    },
    {
        label: "ХУМ",
        value: "ХУМ",
    },
    {
        label: "ЧАМУРЛИЈА",
        value: "ЧАМУРЛИЈА",
    },
    {
        label: "ЦРНА ТРАВА",
        value: "ЦРНА ТРАВА",
    },
    {
        label: "БАЈИНЦИ",
        value: "БАЈИНЦИ",
    },
    {
        label: "БРОД",
        value: "БРОД",
    },
    {
        label: "ГОРЊЕ ГАРЕ",
        value: "ГОРЊЕ ГАРЕ",
    },
    {
        label: "ГРАДСКА",
        value: "ГРАДСКА",
    },
    {
        label: "ДАРКОВЦЕ",
        value: "ДАРКОВЦЕ",
    },
    {
        label: "ЈАБУКОВИК",
        value: "ЈАБУКОВИК",
    },
    {
        label: "КРИВИ ДЕЛ",
        value: "КРИВИ ДЕЛ",
    },
    {
        label: "МЛАЧИШТЕ",
        value: "МЛАЧИШТЕ",
    },
    {
        label: "ОСТРОЗУБ",
        value: "ОСТРОЗУБ",
    },
    {
        label: "ПАВЛИЧИНА",
        value: "ПАВЛИЧИНА",
    },
    {
        label: "ПРЕСЛАП",
        value: "ПРЕСЛАП",
    },
    {
        label: "РАЈЧЕТИНЕ",
        value: "РАЈЧЕТИНЕ",
    },
    {
        label: "РУПЉЕ",
        value: "РУПЉЕ",
    },
    {
        label: "ЧАЈЕТИНА",
        value: "ЧАЈЕТИНА",
    },
    {
        label: "АЛИН ПОТОК",
        value: "АЛИН ПОТОК",
    },
    {
        label: "БРАНЕШЦИ",
        value: "БРАНЕШЦИ",
    },
    {
        label: "ГОСТИЉЕ",
        value: "ГОСТИЉЕ",
    },
    {
        label: "ЖЕЉИНЕ",
        value: "ЖЕЉИНЕ",
    },
    {
        label: "ЉУБИШ",
        value: "ЉУБИШ",
    },
    {
        label: "МАЧКАТ",
        value: "МАЧКАТ",
    },
    {
        label: "МЕШНИК",
        value: "МЕШНИК",
    },
    {
        label: "РОЖАНСТВО",
        value: "РОЖАНСТВО",
    },
    {
        label: "СЕМЕГЊЕВО",
        value: "СЕМЕГЊЕВО",
    },
    {
        label: "СИРОГОЈНО",
        value: "СИРОГОЈНО",
    },
    {
        label: "СТУБЛО",
        value: "СТУБЛО",
    },
    {
        label: "ТРИПКОВА",
        value: "ТРИПКОВА",
    },
    {
        label: "ЧИЧКОВА",
        value: "ЧИЧКОВА",
    },
    {
        label: "ШЉИВОВИЦА",
        value: "ШЉИВОВИЦА",
    },
    {
        label: "ЧАЧАК",
        value: "ЧАЧАК",
    },
    {
        label: "АТЕНИЦА",
        value: "АТЕНИЦА",
    },
    {
        label: "БАЛУГА (ЉУБИЋСКА)",
        value: "БАЛУГА (ЉУБИЋСКА)",
    },
    {
        label: "БАЛУГА (ТРНАВСКА)",
        value: "БАЛУГА (ТРНАВСКА)",
    },
    {
        label: "БАЊИЦА",
        value: "БАЊИЦА",
    },
    {
        label: "БЕЧАЊ",
        value: "БЕЧАЊ",
    },
    {
        label: "ВИДОВА",
        value: "ВИДОВА",
    },
    {
        label: "ВИЉУША",
        value: "ВИЉУША",
    },
    {
        label: "ВРАНИЋИ",
        value: "ВРАНИЋИ",
    },
    {
        label: "ВУЈЕТИНЦИ",
        value: "ВУЈЕТИНЦИ",
    },
    {
        label: "ГОРИЧАНИ",
        value: "ГОРИЧАНИ",
    },
    {
        label: "ГОРЊА ГОРЕВНИЦА",
        value: "ГОРЊА ГОРЕВНИЦА",
    },
    {
        label: "ГОРЊА ТРЕПЧА",
        value: "ГОРЊА ТРЕПЧА",
    },
    {
        label: "ДОЊА ГОРЕВНИЦА",
        value: "ДОЊА ГОРЕВНИЦА",
    },
    {
        label: "ДОЊА ТРЕПЧА",
        value: "ДОЊА ТРЕПЧА",
    },
    {
        label: "ЖАОЧАНИ",
        value: "ЖАОЧАНИ",
    },
    {
        label: "ЗАБЛАЋЕ",
        value: "ЗАБЛАЋЕ",
    },
    {
        label: "ЈАНЧИЋИ",
        value: "ЈАНЧИЋИ",
    },
    {
        label: "ЈЕЖЕВИЦА",
        value: "ЈЕЖЕВИЦА",
    },
    {
        label: "ЈЕЗДИНА",
        value: "ЈЕЗДИНА",
    },
    {
        label: "КАТРГА",
        value: "КАТРГА",
    },
    {
        label: "КАЧУЛИЦЕ",
        value: "КАЧУЛИЦЕ",
    },
    {
        label: "КОЊЕВИЋИ",
        value: "КОЊЕВИЋИ",
    },
    {
        label: "КУКИЋИ",
        value: "КУКИЋИ",
    },
    {
        label: "КУЛИНОВЦИ",
        value: "КУЛИНОВЦИ",
    },
    {
        label: "МЕЂУВРШЈЕ",
        value: "МЕЂУВРШЈЕ",
    },
    {
        label: "МИЛИЋЕВЦИ",
        value: "МИЛИЋЕВЦИ",
    },
    {
        label: "МИОКОВЦИ",
        value: "МИОКОВЦИ",
    },
    {
        label: "МРЧАЈЕВЦИ",
        value: "МРЧАЈЕВЦИ",
    },
    {
        label: "МРШИНЦИ",
        value: "МРШИНЦИ",
    },
    {
        label: "ОСТРА",
        value: "ОСТРА",
    },
    {
        label: "ПАКОВРАЋЕ",
        value: "ПАКОВРАЋЕ",
    },
    {
        label: "ПАРМЕНАЦ",
        value: "ПАРМЕНАЦ",
    },
    {
        label: "ПРЕЉИНА",
        value: "ПРЕЉИНА",
    },
    {
        label: "ПРЕМЕЋА",
        value: "ПРЕМЕЋА",
    },
    {
        label: "ПРИЈЕВОР",
        value: "ПРИЈЕВОР",
    },
    {
        label: "ПРИСЛОНИЦА",
        value: "ПРИСЛОНИЦА",
    },
    {
        label: "РАКОВА",
        value: "РАКОВА",
    },
    {
        label: "РИЂАГЕ",
        value: "РИЂАГЕ",
    },
    {
        label: "РОШЦИ",
        value: "РОШЦИ",
    },
    {
        label: "СОКОЛИЋИ",
        value: "СОКОЛИЋИ",
    },
    {
        label: "СТАНЧИЋИ",
        value: "СТАНЧИЋИ",
    },
    {
        label: "ТРБУШАНИ",
        value: "ТРБУШАНИ",
    },
    {
        label: "ЧОКА",
        value: "ЧОКА",
    },
    {
        label: "ЈАЗОВО",
        value: "ЈАЗОВО",
    },
    {
        label: "ОСТОЈИЋЕВО",
        value: "ОСТОЈИЋЕВО",
    },
    {
        label: "ПАДЕЈ",
        value: "ПАДЕЈ",
    },
    {
        label: "САНАД",
        value: "САНАД",
    },
    {
        label: "ЧУКАРИЦА",
        value: "ЧУКАРИЦА",
    },
    {
        label: "ВЕЛИКА МОШТАНИЦА",
        value: "ВЕЛИКА МОШТАНИЦА",
    },
    {
        label: "ЖЕЛЕЗНИК",
        value: "ЖЕЛЕЗНИК",
    },
    {
        label: "ОСТРУЖНИЦА",
        value: "ОСТРУЖНИЦА",
    },
    {
        label: "РУШАЊ",
        value: "РУШАЊ",
    },
    {
        label: "СРЕМЧИЦА",
        value: "СРЕМЧИЦА",
    },
    {
        label: "УМКА",
        value: "УМКА",
    },
    {
        label: "ШАБАЦ",
        value: "ШАБАЦ",
    },
    {
        label: "БОГОСАВАЦ",
        value: "БОГОСАВАЦ",
    },
    {
        label: "БОЈИЋ",
        value: "БОЈИЋ",
    },
    {
        label: "БУКОР",
        value: "БУКОР",
    },
    {
        label: "ВАРНА",
        value: "ВАРНА",
    },
    {
        label: "ВОЛУЈАЦ",
        value: "ВОЛУЈАЦ",
    },
    {
        label: "ГОРЊА ВРАЊСКА",
        value: "ГОРЊА ВРАЊСКА",
    },
    {
        label: "ГРУШИЋ",
        value: "ГРУШИЋ",
    },
    {
        label: "ДЕСИЋ",
        value: "ДЕСИЋ",
    },
    {
        label: "ДОБРИЋ",
        value: "ДОБРИЋ",
    },
    {
        label: "ДУВАНИШТЕ",
        value: "ДУВАНИШТЕ",
    },
    {
        label: "ЖАБАР",
        value: "ЖАБАР",
    },
    {
        label: "ЗМИЊАК",
        value: "ЗМИЊАК",
    },
    {
        label: "ЈЕВРЕМОВАЦ",
        value: "ЈЕВРЕМОВАЦ",
    },
    {
        label: "ЈЕЛЕНЧА",
        value: "ЈЕЛЕНЧА",
    },
    {
        label: "ЛИПОЛИСТ",
        value: "ЛИПОЛИСТ",
    },
    {
        label: "МАЛА ВРАЊСКА",
        value: "МАЛА ВРАЊСКА",
    },
    {
        label: "МАОВИ",
        value: "МАОВИ",
    },
    {
        label: "МАЧВАНСКИ ПРИЧИНОВИЋ",
        value: "МАЧВАНСКИ ПРИЧИНОВИЋ",
    },
    {
        label: "МЕТЛИЋ",
        value: "МЕТЛИЋ",
    },
    {
        label: "МИОКУС",
        value: "МИОКУС",
    },
    {
        label: "МИШАР",
        value: "МИШАР",
    },
    {
        label: "МРЂЕНОВАЦ",
        value: "МРЂЕНОВАЦ",
    },
    {
        label: "ОРИД",
        value: "ОРИД",
    },
    {
        label: "ПЕТКОВИЦА",
        value: "ПЕТКОВИЦА",
    },
    {
        label: "ПЕТЛОВАЧА",
        value: "ПЕТЛОВАЧА",
    },
    {
        label: "ПОЦЕРСКИ МЕТКОВИЋ",
        value: "ПОЦЕРСКИ МЕТКОВИЋ",
    },
    {
        label: "ПОЦЕРСКИ ПРИЧИНОВИЋ",
        value: "ПОЦЕРСКИ ПРИЧИНОВИЋ",
    },
    {
        label: "ПРЕДВОРИЦА",
        value: "ПРЕДВОРИЦА",
    },
    {
        label: "РАДОВАШНИЦА",
        value: "РАДОВАШНИЦА",
    },
    {
        label: "РУМСКА",
        value: "РУМСКА",
    },
    {
        label: "СИНОШЕВИЋ",
        value: "СИНОШЕВИЋ",
    },
    {
        label: "СЛЕПЧЕВИЋ",
        value: "СЛЕПЧЕВИЋ",
    },
    {
        label: "ЦУЉКОВИЋ",
        value: "ЦУЉКОВИЋ",
    },
    {
        label: "ШЕВАРИЦЕ",
        value: "ШЕВАРИЦЕ",
    },
    {
        label: "ШТИТАР",
        value: "ШТИТАР",
    },
    {
        label: "ШИД",
        value: "ШИД",
    },
    {
        label: "АДАШЕВЦИ",
        value: "АДАШЕВЦИ",
    },
    {
        label: "БАТРОВЦИ",
        value: "БАТРОВЦИ",
    },
    {
        label: "БАЧИНЦИ",
        value: "БАЧИНЦИ",
    },
    {
        label: "БЕРКАСОВО",
        value: "БЕРКАСОВО",
    },
    {
        label: "БИНГУЛА",
        value: "БИНГУЛА",
    },
    {
        label: "ВАШИЦА",
        value: "ВАШИЦА",
    },
    {
        label: "ВИШЊИЋЕВО",
        value: "ВИШЊИЋЕВО",
    },
    {
        label: "ГИБАРАЦ",
        value: "ГИБАРАЦ",
    },
    {
        label: "ЂИПША",
        value: "ЂИПША",
    },
    {
        label: "ЕРДЕВИК",
        value: "ЕРДЕВИК",
    },
    {
        label: "ИЛИНЦИ",
        value: "ИЛИНЦИ",
    },
    {
        label: "ЈАМЕНА",
        value: "ЈАМЕНА",
    },
    {
        label: "КУКУЈЕВЦИ",
        value: "КУКУЈЕВЦИ",
    },
    {
        label: "ЉУБА",
        value: "ЉУБА",
    },
    {
        label: "МОЛОВИН",
        value: "МОЛОВИН",
    },
    {
        label: "МОРОВИЋ",
        value: "МОРОВИЋ",
    },
    {
        label: "ПРИВИНА ГЛАВА",
        value: "ПРИВИНА ГЛАВА",
    },
    {
        label: "СОТ",
        value: "СОТ",
    },
];
