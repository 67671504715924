import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import styled from "styled-components";
import { PreviewSection } from "./RenderOsnovniPodaci";
import React from "react";

export const RenderDokumenta = () => {
    const data = useSelector((state: RootState) => state.caseTip2.tempDokumenta);

    return (
        <PreviewSection>
            {data.map((dokument, index) => (
                <React.Fragment key={index}>
                    <div style={{ margin: "0px 10px" }}>
                        {Object.entries(dokument).map(([key, option]) => (
                            <PreviewComponent key={key}>{key === "name" ? <Value>{option}</Value> : null}</PreviewComponent>
                        ))}
                    </div>
                </React.Fragment>
            ))}
        </PreviewSection>
    );
};

const PreviewComponent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2px;
    margin-left: 20px;
`;

const Key = styled.span`
    margin-right: 5px;
`;

const Value = styled.span``;
