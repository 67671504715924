import { ReactNode, useEffect, useRef } from "react";
import styled from "styled-components";
import { themeColors } from "../../constants/constants";

import useMobileCheck from "../../hooks/useMobileChecker";

type ModalProps = {
    isOpen: boolean;
    children: ReactNode;
    setIsOpen: (value: boolean) => void;
};

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    box-shadow: "0px 4px 4px 0px #00000040";
`;

const ModalWrap = styled.div`
    display: flex;
    align-items: flex-start;
    border-radius: 4px;
    position: absolute;
    top: 120px;
`;

const ModalContent = styled.div`
    background-color: ${themeColors.modalContentBackgroundColor};
    max-width: 100%;
    overflow: visible;
    border-radius: 5px;
    box-shadow: "0px 4px 4px 0px #00000040";

    @media screen and (max-width: 900px) {
        margin-left: 700px;
    }
`;

const KatakomModal = ({ isOpen, children, setIsOpen }: ModalProps) => {
    const modalContentRef = useRef<HTMLDivElement>(null);
    const isMobile = useMobileCheck();

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("keydown", handleKeyPress);
            // Disable scrolling on the body
            document.body.style.overflow = "hidden";
            // Set focus when modal opens
            if (modalContentRef.current && !isMobile) {
                modalContentRef.current.focus();
            }
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
            document.body.style.overflow = "";
        };
    }, [isOpen, setIsOpen]);

    return (
        <>
            {isOpen && (
                <ModalContainer>
                    <ModalWrap>
                        <ModalContent ref={modalContentRef} tabIndex={0} aria-modal="true">
                            {children}
                        </ModalContent>
                    </ModalWrap>
                </ModalContainer>
            )}
        </>
    );
};

export default KatakomModal;
