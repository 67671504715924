//@ts-nocheck
import { cyrillicToLatinicMap, latinicToCyrillicMap, latinicToCyrillicMapExact } from "../constants/constants";

// This one is used for SEARCH filter so it understand Cyrilic Latinic and ABC.
export const translateToCyrilic = (word: string): string => {
    let cyrillicWord = "";
    let i = 0;

    while (i < word.length) {
        let letter = word[i];
        let nextTwoLetters = word.slice(i, i + 2);

        if (latinicToCyrillicMap[nextTwoLetters]) {
            cyrillicWord += latinicToCyrillicMap[nextTwoLetters];
            i += 2;
        } else if (latinicToCyrillicMap[letter]) {
            cyrillicWord += latinicToCyrillicMap[letter];
            i += 1;
        } else {
            cyrillicWord += letter;
            i += 1;
        }
    }

    return cyrillicWord;
};

export const translateToCyrilicExact = (word: string): string => {
    let cyrillicWord = "";
    let i = 0;

    while (i < word.length) {
        let letter = word[i];
        let nextTwoLetters = word.slice(i, i + 2);

        if (latinicToCyrillicMapExact[nextTwoLetters]) {
            cyrillicWord += latinicToCyrillicMapExact[nextTwoLetters];
            i += 2;
        } else if (latinicToCyrillicMapExact[letter]) {
            cyrillicWord += latinicToCyrillicMapExact[letter];
            i += 1;
        } else {
            cyrillicWord += letter;
            i += 1;
        }
    }

    return cyrillicWord;
};

export const translateToLatinic = (word) => {
    let latinicWord = "";
    let i = 0;

    while (i < word.length) {
        let letter = word[i];
        let nextTwoLetters = word.slice(i, i + 2);

        if (cyrillicToLatinicMap[nextTwoLetters]) {
            latinicWord += cyrillicToLatinicMap[nextTwoLetters];
            i += 2;
        } else if (cyrillicToLatinicMap[letter]) {
            latinicWord += cyrillicToLatinicMap[letter];
            i += 1;
        } else {
            latinicWord += letter;
            i += 1;
        }
    }

    return latinicWord;
};

export const mapKeyToDisplayString = (key: string) => {
    switch (key) {
        case "vrstaIspraveId":
            return "Врста акта";
        case "datumUgovora":
            return "Датум акта";
        case "datumPotvrde":
            return "Датум потврде";
        case "brojOvereUgovora":
            return "Број овере акта";
        case "godinaOvereUgovora":
            return "Година овере акта";
        case "upisnik":
            return "Уписник";
        case "maticniBrojSuda":
            return "Суд";
        case "brojPredmetaSuda":
            return "Број предмета суда";
        case "tip":
            return "Врста непокретности";
        case "opstinaNaziv":
            return "Општина";
        case "brojParcele":
            return "Број парцеле";
        case "podBrojParcele":
            return "Број подпарцеле";
        case "brojObjekta":
            return "Број објекта";
        case "evidencijskiBroj":
            return "Евиденцијски број";
        case "EvidencijskiBroj":
            return "Евиденцијски број";
        case "jmbg":
            return "ЈМБГ";
        case "vrstaLica":
            return "Својство лица";
        case "brojPotvrde":
            return "Број потврде";
        case "informacijeOLokacijiBroj":
            return "Информација о локацији";
        case "udeo":
            return "Удео";
        case "povrsina":
            return "Површина";
        case "brojDelaParcele":
            return "Број дела парцеле";
        case "ssn":
            return "SSN";
        case "brojPasosa":
            return "Број пасоша";
        case "brojLicneKarte":
            return "Број личне карте";
        case "maticniBroj":
            return "Матични број";
        case "pib":
            return "ПИБ";
        case "imePravnogLica":
            return "Име правног лица";
        case "email":
            return "И-пошта";
        case "drzavaNaziv":
            return "Држава";
        case "mestoNaziv":
            return "Место";
        case "ulicaNaziv":
            return "Улица";
        case "ulicaRucno":
            return "Улица";
        case "kucniBroj":
            return "Кућни број";
        case "postanskiBroj":
            return "Поштански број";
        case "brojUpisaURegistarDrzaveSedista":
            return "Број уписа у регистар државе седишта";
        case "nazivRegistra":
            return "Назив регистра";
        default:
            return false;
    }
};

export const mapKatastarKeyToDisplayString = (key: string) => {
    switch (key) {
        case "LiceVrsta":
            return "Врста лица";
        case "MaticniBroj":
            return "Матични број";
        case "Ime":
            return "Име";
        case "Prezime":
            return "Презиме";
        case "ImeRoditelja":
            return "Име родитеља";
        case "Naziv":
            return "Назив правног лица";
        case "Mesto":
            return "Место пребивалишта";
        case "Adresa":
            return "Адреса пребивалишта";
        case "BrojPutneIsprave":
            return "Број путне исправе";
        case "RegistarPutneIsprave":
            return "Регистар који је издао путну исправу";
        case "NepokretnostVrsta":
            return "Посебан део објекта";
        case "DatumAzurnosti":
            return "Датум ажурности података";
        case "Opstina":
            return "Општина";
        case "KatastarskaOpstina":
            return "Катастарска општина";
        case "VrstaPrava":
            return "Врста права";
        case "ObimPrava":
            return "Обим права";
        case "OblikSvojine":
            return "Облик својине";
        case "Udeo":
            return "Удео";
        case "Predbelezba":
            return "Предбележба";
        case "PredbelezbaObjekta":
            return "Предбележба објекта";
        case "ResenjeNijeKonacno":
            return "Решење није коначно";
        case "ZajednickaImovinaSupruznika":
            return "Заједничка имовина супружника";
        case "RokPredbelezbe":
            return "Рок предбележбе";
        case "DatumZakup":
            return "Датум закупа";

        case "Tereti":
            return "Терети";
        case "Teret":
            return "Терет";
        case "Parcele":
            return "Парцеле";
        case "Parcela":
            return "Парцела";
        case "Opis":
            return "Опис";
        case "Nepokretnost":
            return "Непокретност";
        case "ImaociPrava":
            return "Имаоци права";
        case "Lice":
            return "Лице";

        case "BrListaNepokretnosti":
            return "Број листа непокретности";
        case "BrParc":
            return "Број парцеле";
        case "PodBrParc":
            return "Подброј парцеле";
        case "ParcelaPovrsina":
            return "Површина парцеле";
        case "VrstaZemljista":
            return "Врста земљишта";
        case "Ulica":
            return "Улица";
        case "DeloviParcele":
            return "Делови парцеле";

        case "DeoParcele":
            return "Део парцеле";
        case "BrDelaParc":
            return "Број дела парцеле";
        case "ParcelaDeoPovrsina":
            return "Површина дела парцеле";
        case "Kultura":
            return "Култура";
        case "Objekat":
            return "Објекат";
        case "KucniBroj":
            return "Кућни број";
        case "KucniPodbroj":
            return "Кућни подброј";
        case "ObjekatPovrsina":
            return "Површина објекта";
        case "ObjekatPovrsinaKorisna":
            return "Корисна површина објекта";
        case "ObjekatPovrsinaGradjevinska":
            return "Градњевинска површина објекта";
        case "ObjekatKoriscenje":
            return "Начинкоришћења објекта ";
        case "ObjekatStatus":
            return "Правнистатус објекта";
        case "ObjekatOpis":
            return "Опис објекта";
        case "BrEtazaPodzemnih":
            return "Број подземних етажа";
        case "BrEtazaPrizemnih":
            return "Број приземних етажа";
        case "BrEtazaNadzemnih":
            return "Број надземних етажа";
        case "BrEtazaPotkrovnih":
            return "Број поткровних етажа";

        case "Objekti":
            return "Објекти";
        case "DeoObjekta":
            return "Део објекта";
        case "evidencijskiBroj":
            return "Евиденцијски број";
        case "EvidencijskiBroj":
            return "Евиденцијски број";
        case "BrojUlaza":
            return "Број улаза";
        case "BrojStana":
            return "Број стана";
        case "PodbrojStana":
            return "Подброј стана";
        case "BrojSprata":
            return "Број спрата";
        case "BrojSoba":
            return "Број соба";
        case "NacinUtvrdjivanjaKorPovrsine":
            return "Начин утврђивања корисне површине";
        case "DeloviObjekta":
            return "Делови објекта";
        case "ObjekatDeo":
            return "Део објекта";
        case "ObjekatDeoPovrsinaKorisna":
            return "Корисна површина дела објекта";
        case "ObjekatDeoPovrsinaGradjevinska":
            return "Градњевинска површина дела објекта";
        case "ObjekatDeoPovrsinaPodzemna":
            return "Површина подземног дела објекта";
        case "ObjekatDeoKoriscenje":
            return "Начин коришћења дела објекта";
        case "ObjekatDeoOpis":
            return "Опис дела објекта";
        case "ObjekatDeoUlica":
            return "Улица дела објекта";
        case "DatumUpisa":
            return "Датум уписа";
        case "DatumBrisanja":
            return "Датум брисања";
        case "TrajanjeTeretaOpis":
            return "Опис трајања терета";
        case "Vrsta":
            return "Врста";
        case "Poverilac":
            return "Поверилац";
        case "Povrsina":
            return "Површина";
        case "0":
            return "1";
        case "1":
            return "2";
        case "2":
            return "3";
        case "3":
            return "4";
        case "4":
            return "5";
        case "5":
            return "6";
        case "6":
            return "7";
        case "7":
            return "8";
        case "8":
            return "9";
        case "9":
            return "10";
        case "10":
            return "11";
        case "11":
            return "12";
        case "12":
            return "13";
        case "13":
            return "14";
        case "14":
            return "15";
        case "15":
            return "16";
        case "16":
            return "17";
        case "17":
            return "18";
        case "18":
            return "19";
        case "19":
            return "20";
        case "20":
            return "21";
        case "21":
            return "22";
        case "22":
            return "23";
        case "23":
            return "24";
        case "24":
            return "25";
        case "25":
            return "26";
        case "26":
            return "27";
        case "27":
            return "28";
        case "28":
            return "29";
        case "29":
            return "30";
        case "30":
            return "31";
        case "31":
            return "32";
        case "32":
            return "33";
        case "33":
            return "34";
        case "34":
            return "35";
        case "35":
            return "36";
        case "36":
            return "37";
        case "37":
            return "38";
        case "38":
            return "39";
        case "39":
            return "40";
        case "40":
            return "41";
        case "41":
            return "42";
        case "42":
            return "43";
        case "43":
            return "44";
        case "44":
            return "45";
        case "45":
            return "46";
        case "46":
            return "47";
        case "47":
            return "48";
        case "48":
            return "49";
        case "49":
            return "50";
        default:
            return false;
    }
};
