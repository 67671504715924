import React, { useState, ReactNode, useEffect } from "react";
import styled from "styled-components";
import { themeColors } from "../../constants/constants";
import { AppDispatch, RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { resetState } from "../../store/KatastarReducer";

const TabSwitcherContainer = styled.div`
    overflow: hidden;
    min-width: 100%;
    width: 100%;
    box-shadow: 5px 5px 10px 0px #00000040;
    border-radius: 5px;
    outline: none;
    border: none;
`;

const TabHeaders = styled.div`
    display: flex;
    justify-content: space-between;
    border-radius: 5px 5px 0 0;
    border: none;
    outline: none;
    overflow: hidden;
    height: 65px;
`;

const TabHeader = styled.div<{ active: boolean; disabled: boolean }>`
    flex: 1;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.active ? themeColors.tabHeaderBackgroundColorActive : themeColors.tabHeaderBackgroundColorNotActive};
    color: ${(props) => (props.active ? themeColors.tabHeaderColorActive : themeColors.tabHeaderColorNotActive)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    border-radius: 5px 5px 0 0;
    margin-right: 3px;
    font-size: 16px;
    font-weight: 500;

    &:last-child {
        margin-right: 0px;
    }

    @media screen and (max-width: 900px) {
        font-size: 12px;
    }
`;

const TabPane = styled.div<{ active: boolean }>`
    display: ${(props) => (props.active ? "flex" : "none")};
`;

interface TabSwitcherProps {
    tabs: string[];
    children: ReactNode[];
    activeTab: number;
    setActiveTab: (index: number) => void;
    defaultTab?: number;
    onTabChange?: (index: number) => void;
}

const TabSwitcher: React.FC<TabSwitcherProps> = ({ tabs, children, activeTab, setActiveTab }) => {
    const katastar = useSelector((state: RootState) => state.katastar);
    const dispatch: AppDispatch = useAppDispatch();

    const tabChangeHandler = (index: number) => {
        setActiveTab(index);
        if (index === 0) dispatch(resetState());
    };

    return (
        <TabSwitcherContainer>
            <TabHeaders>
                {tabs.map((tab, index) => (
                    <TabHeader
                        key={index}
                        active={index === activeTab}
                        disabled={index > activeTab}
                        onClick={() => {
                            if (index > activeTab) return;
                            tabChangeHandler(index);
                        }}
                    >
                        {tab}
                    </TabHeader>
                ))}
            </TabHeaders>
            <div className="tab-content">
                {children.map((child, index) => (
                    <TabPane key={index} active={index === activeTab}>
                        {child}
                    </TabPane>
                ))}
            </div>
        </TabSwitcherContainer>
    );
};

const TabSwitcher2Container = styled.div`
    white-space: nowrap;
`;

const TabHeaders2 = styled.div`
    display: flex;
    overflow: hidden;
    height: 20px;
    margin: 10px 15px;
`;

const TabHeader2 = styled.div<{ active: boolean; disabled: boolean }>`
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-bottom: ${(props) => (props.active ? `1px solid ${themeColors.tabHeader2Border}` : "none")};
    color: ${(props) => (props.active ? themeColors.tabHeader2ColorActive : themeColors.tabHeader2ColorNotActive)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    margin-right: 30px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 5px;
    transition: 150ms ease-in;

    &:last-child {
        margin-right: 0px;
    }
`;

const TabPane2 = styled.div<{ active: boolean }>`
    display: ${(props) => (props.active ? "flex" : "none")};
`;

const TabSwitcherLight: React.FC<TabSwitcherProps> = ({ tabs, children, activeTab, setActiveTab }) => {
    const tabChangeHandler = (index: number) => {
        setActiveTab(index);
    };

    return (
        <TabSwitcher2Container>
            <TabHeaders2>
                {tabs.map((tab, index) => (
                    <TabHeader2
                        key={index}
                        active={index === activeTab}
                        disabled={false}
                        onClick={() => {
                            tabChangeHandler(index);
                        }}
                    >
                        {tab}
                    </TabHeader2>
                ))}
            </TabHeaders2>
            <div className="tab-content">
                {children.map((child, index) => (
                    <TabPane2 key={index} active={index === activeTab}>
                        {child}
                    </TabPane2>
                ))}
            </div>
        </TabSwitcher2Container>
    );
};

export { TabSwitcher, TabSwitcherLight };
