import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { themeColors } from "../../../../../constants/constants";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import KatakomButton from "../../../../shared/KatakomButton";
import { useSidebar } from "../../../../widgets/sidebar/SidebarContext";
import { ButtonGroup, Card, CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper } from "../../../style";
import { RenderDokumenta } from "./RenderDokumenta";
import { RenderNepokretnosti } from "./RenderNepokretnosti";
import { RenderOsnovniPodaci } from "./RenderOsnovniPodaci";
import { RenderPodnosioci } from "./RenderPodnosioci";
import { addDocumentTip2, resetState } from "../../../../../store/CaseTip2Reducer";
import KatakomModal from "../../../../shared/KatakomModal";
import { SuccessMessageModal } from "../../../../customComponent/SuccessMessageModal";
import { ErrorMessageModal } from "../../../../customComponent/ErrorMessageModal";
import api from "../../../../../services/api";

const PreviewLayout: React.FC = () => {
    const slucaj = useSelector((state: RootState) => state.caseTip2);
    const tempDokumenta = useSelector((state: RootState) => state.caseTip2.tempDokumenta);
    const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
    const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);

    const dispatch: AppDispatch = useAppDispatch();

    let navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    };

    const submitFiles = async () => {
        const documents = [];
        try {
            for (const dok of tempDokumenta) {
                const response = await api.post(`api/v1/posalji-fajl`, {
                    base64Document: dok.file,
                    userId: "56252",
                });
                documents.push({ id_prijem_fajl_esalter: response?.data.id_prijem_fajl_esalter });
            }

            dispatch(addDocumentTip2(documents));
        } catch (e) {
            setErrorModalOpen(true);
        }
    };

    useEffect(() => {
        if (slucaj.dokumenta.length > 0) {
            handleFinish();
        }
    }, [slucaj.dokumenta]);

    const handleFinish = async () => {
        const slucajWithoutId = {
            ...slucaj,
        };
        slucajWithoutId.ucesnici = slucajWithoutId.ucesnici.map(createNewObjectWithoutId);
        slucajWithoutId.nepokretnostKN = slucajWithoutId.nepokretnostKN.map(createNewObjectWithoutId);
        slucajWithoutId.tempDokumenta = slucajWithoutId.tempDokumenta.map(createNewObjectWithoutId);

        try {
            await api.post(`api/v1/konverzija`, {
                ...slucajWithoutId.basicInfo,
                vrstaIspraveId: "60160",
                ucesnici: slucajWithoutId.ucesnici,
                nepokretnostKN: slucajWithoutId.nepokretnostKN,
                dokumenta: slucajWithoutId.dokumenta,
            });
            setSuccessModalOpen(true);
        } catch (e) {
            setErrorModalOpen(true);
        }
    };

    const { sidebar } = useSidebar();

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <KatakomModal isOpen={successModalOpen} setIsOpen={setSuccessModalOpen}>
                <SuccessMessageModal
                    closeModal={() => {
                        routeChange("/pocetna");
                        dispatch(resetState());
                        setSuccessModalOpen(false);
                    }}
                    message="Успешно сте послали захтев!"
                />
            </KatakomModal>

            <KatakomModal isOpen={errorModalOpen} setIsOpen={setErrorModalOpen}>
                <ErrorMessageModal
                    closeModal={() => {
                        routeChange("/pocetna");
                        dispatch(resetState());
                        setErrorModalOpen(false);
                    }}
                    message="Дошло је до грешке приликом слања захтева!"
                />
            </KatakomModal>

            <CardWrapper>
                <Card style={{ minHeight: "10vh" }}>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>ПРЕГЛЕД</CardTitle>
                        </CardHeader>
                        <PreviewTitle>oсновни подаци</PreviewTitle>
                        <RenderOsnovniPodaci />
                    </CardContainer>
                </Card>
            </CardWrapper>

            <CardWrapper>
                <Card style={{ minHeight: "10vh" }}>
                    <CardContainer>
                        <PreviewTitle>непокретности</PreviewTitle>
                        <RenderNepokretnosti />
                    </CardContainer>
                </Card>
            </CardWrapper>

            <CardWrapper>
                <Card style={{ minHeight: "10vh" }}>
                    <CardContainer>
                        <PreviewTitle>странке</PreviewTitle>
                        <RenderPodnosioci />
                    </CardContainer>
                </Card>
            </CardWrapper>

            <CardWrapper>
                <Card style={{ minHeight: "10vh" }}>
                    <CardContainer>
                        <PreviewTitle>документа</PreviewTitle>
                        <RenderDokumenta />
                    </CardContainer>
                </Card>
            </CardWrapper>

            <ButtonGroup>
                <KatakomButton
                    size="l"
                    label={"Назад"}
                    onClick={() => {
                        routeChange("/noviPredmetKonverzija");
                    }}
                />
                <KatakomButton size="l" label={"Пошаљи"} onClick={submitFiles} />
            </ButtonGroup>
        </LayoutWraper>
    );
};

function createNewObjectWithoutId(obj: any) {
    const newObj = { ...obj };
    delete newObj.id;
    return newObj;
}

export default PreviewLayout;

const PreviewTitle = styled.div`
    display: flex;
    font-size: 20px;
    font-weight: 500;
    padding: 0 0 0 30px;
    align-items: center;
    background-color: ${themeColors.previewTitleBackgroundColor};
    color: ${themeColors.previewTitleColor};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: calc(100% -30px);
    height: 36px;
`;
