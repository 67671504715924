import SettingsPage from "../Settings";
import AccountSettingsLayout from "./AccountSettingsLayout";

export const AccountSettings = () => {
    return (
        <SettingsPage>
            <AccountSettingsLayout />
        </SettingsPage>
    );
};
