import { ChangeEvent, FC, KeyboardEvent } from "react";
import styled from "styled-components";
import { themeColors } from "../../constants/constants";
import { getSize } from "../../utils/getSize";

interface InputProps {
    type: "text" | "number" | "email" | "password";
    value: string | number | null;
    name: string;
    label?: string;
    size?: "xs" | "s" | "m" | "l" | "xl" | "fullSize" | "special1";
    placeholder?: string;
    error: boolean;
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onEnter?: (() => void) | (() => Promise<void>);
    onFocus?: (() => void) | (() => Promise<void>);
    onBlur?: (() => void) | (() => Promise<void>);
    style?: React.CSSProperties;
    tabIndex?: number | undefined;
    theme?: "primary" | "secondary";
}

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* height: 89px; */
`;

const Input = styled.input<InputProps>`
    font-size: 16px;
    font-weight: 400;
    width: ${(props) => getSize(props.size)};
    color: ${(props) => (props.theme === "secondary" ? themeColors.katakomInputColorSecondary : themeColors.katakomInputColorDefault)};
    background: ${themeColors.katakomInputBackground};
    border: ${(props) => (props.theme === "secondary" ? `2px solid ${themeColors.katakomInputBorder}` : "none")};
    outline: none;
    border-radius: 4px;
    padding: ${(props) => (props.theme === "secondary" ? "10px" : "16px 15px")};

    box-shadow: ${(props) => (props.theme === "secondary" ? "none" : "5px 5px 10px 0px #00000040;")};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::placeholder {
        font-size: ${(props) => (props.theme === "secondary" ? "14px" : "16px")};
        font-weight: 400;
        color: ${(props) => (props.theme === "secondary" ? themeColors.katakomInputColorSecondary : themeColors.katakomInputColorDefault)};
    }

    &:focus {
        outline: none;
        background: ${themeColors.katakomInputBackgroundColorFocuse};
    }
`;

const Label = styled.label<{ size: string }>`
    font-size: 15px;
    font-weight: 400;
    color: ${themeColors.katakomInputLabelColor};
    width: ${(props) => props.size};
    height: 40px;
`;

const Error = styled.p`
    color: ${themeColors.katakomInputErrorColor};
    font-size: 16px;
    font-weight: 400;
    margin-left: 12px;
    margin-top: 4px;
`;

const KatakomInput: FC<InputProps> = ({
    type,
    value,
    name,
    label,
    placeholder,
    error,
    disabled,
    onChange,
    onFocus,
    onEnter,
    onBlur,
    size,
    style,
    tabIndex,
    theme,
}) => {
    const inputSize = getSize(size);

    const validateInput = (name: string, value: string): boolean => {
        if (name === "jmbg") {
            return /^\d{0,13}$/.test(value);
        } else if (name === "brojLicneKarte") {
            return /^\d{0,9}$/.test(value);
        }
        return true;
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && onEnter) {
            onEnter();
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const isValid = validateInput(name, value);

        if (isValid) {
            onChange(e);
        }
    };

    return (
        <InputWrapper style={{ ...style }}>
            {label ? (
                <Label htmlFor={name} size={inputSize}>
                    {label}
                </Label>
            ) : null}
            <Input
                type={type}
                id={name}
                value={value || ""}
                name={name}
                placeholder={placeholder}
                error={error}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
                style={{ width: inputSize }}
                tabIndex={tabIndex}
                autoCapitalize="off"
                theme={theme}
            />
            {error && <Error>Обавезно поље!</Error>}
        </InputWrapper>
    );
};

export default KatakomInput;
