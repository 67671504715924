import React from "react";
import styled from "styled-components";
import { themeColors, themeIcon } from "../../constants/constants";
import { useSidebar } from "../widgets/sidebar/SidebarContext";
import { CardWrapper, LayoutWraper } from "../pages/style";

import useMobileCheck from "../../hooks/useMobileChecker";
import { Link } from "react-router-dom";

const Card = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${themeColors.settingsWelcomCardBackgroundColor};
    height: calc(100vh - 120px);
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px 0px #00000040;
    position: relative;
    width: 100%;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url(${themeIcon}/Kbackground.png);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: contain;
        opacity: 1;
    }
`;

const LogoContainer = styled.div`
    margin-bottom: 0px;
    font-size: 42px;
    img {
        width: 120px;
    }
`;

const UserName = styled.div`
    font-size: 48px;
    color: ${themeColors.settingsWelcomUserNameColor};
`;

const SettingsWelcome: React.FC = () => {
    const { sidebar } = useSidebar();
    const isMobile = useMobileCheck();

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <Card>
                    {isMobile ? null : (
                        <>
                            <LogoContainer>
                                <img src={`${themeIcon}/profile.png`} alt="Welcome user" />
                            </LogoContainer>
                            <UserName>Подешавања налога!</UserName>
                        </>
                    )}
                </Card>
            </CardWrapper>
            <div style={{ position: "absolute", bottom: "40px", right: "50px", zIndex: 1, textDecoration: "none", outline: "none" }}>
                <Link to={"/privacePolicy"}>Politika privatnosti</Link>
            </div>
        </LayoutWraper>
    );
};

export default SettingsWelcome;
