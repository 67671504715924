import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import { useState } from "react";
import { deleteUcesnik } from "../../../../../store/CaseReducer";
import KatakomButton from "../../../../shared/KatakomButton";
import KatakomTable from "../../../../shared/KatakomTable";
import KatakomModal from "../../../../shared/KatakomModal";
import AplicantsModalComponent from "./AplicantsModalComponent";
import { Container, Row } from "../../../style";

type Props = {
    setCurrentStep: () => void;
};

const AddAplicants: React.FC<Props> = ({ setCurrentStep }) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const dispatch: AppDispatch = useAppDispatch();
    const ucesnici = useSelector((state: RootState) => state.case.ucesnici);
    const [editingUcesnik, setEditingUcesnik] = useState<any>(null);

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const handleEditUcesnik = (ucesnik: any) => {
        setEditingUcesnik(ucesnik);
        setModalIsOpen(true);
    };

    const handledeleteUcesnik = (ucesnik: any) => {
        dispatch(deleteUcesnik(ucesnik.id));
    };

    const columns = [
        { label: "Ид", value: "id" },
        { label: "Име", value: "ime/imePravnogLica" },
        { label: "Презиме", value: "prezime" },
        { label: "Врста лица", value: "vrstaLica" },
        { label: "ЈМБГ/Матични број", value: "jmbg/maticniBroj/brojUpisaURegistarDrzaveSedista/ssn" },
        { label: "Својство странке", value: "svojstvoLica" },
        { label: "", value: "edit" },
        { label: "", value: "delete" },
    ];

    return (
        <Container>
            <KatakomModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
                <AplicantsModalComponent
                    closeModal={handleCloseModal}
                    ucesnikId={editingUcesnik ? editingUcesnik.id : undefined}
                    setEditingUcesnik={setEditingUcesnik}
                />
            </KatakomModal>
            {ucesnici.length !== 0 ? (
                <div style={{ margin: "15px" }}>
                    <KatakomTable columns={columns} data={ucesnici} onEdit={handleEditUcesnik} onDelete={handledeleteUcesnik} />
                </div>
            ) : null}
            <Row style={{ display: "flex", justifyContent: "center" }}>
                <KatakomButton
                    label={"Додај странку"}
                    size="l"
                    style={{ marginTop: "30px" }}
                    onClick={() => {
                        setModalIsOpen(true);
                    }}
                ></KatakomButton>
            </Row>
        </Container>
    );
};

export default AddAplicants;
