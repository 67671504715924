import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import KatakomSelect, { SelectOption } from "../../../../shared/KatakomSelect";
import KatakomButton from "../../../../shared/KatakomButton";
import { katastarskeOpstineOptions } from "../../../../../register/register";
import KatakomModal from "../../../../shared/KatakomModal";
import { ModalContent, Row } from "../../../style";
import { InfoMessageModal } from "../../../../customComponent/InfoMessageModal";
import { AssetsAdvokati, addOrUpdateNepokretnostAdvokati } from "../../../../../store/CaseAdvokatiReducer";
import { defaultAssetsAdvokati } from "../../../../../register/defaultValues";

import useMobileCheck from "../../../../../hooks/useMobileChecker";

interface Props {
    closeModal: () => void;
    nepokretnostId?: number;
    setEditingNepokretnost: (id: AssetsAdvokati | null) => void;
    modalState?: boolean;
}

const AssetModalComponentAdvokatiUverenjeOImovinskomStanju: FC<Props> = ({
    closeModal,
    nepokretnostId,
    setEditingNepokretnost,
    modalState,
}) => {
    const dispatch: AppDispatch = useAppDispatch();
    const nepokretnostKN = useSelector((state: RootState) => state.caseAdvokati.nepokretnostKN);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [firstTimeEdit, setFirstTimeEdit] = React.useState<boolean>(true);
    const [validatingParcelaDisabled, setValidatingParcelaDisabled] = React.useState<boolean>(false);
    const [formData, setFormData] = useState<AssetsAdvokati>({ ...defaultAssetsAdvokati, tip: "" });
    const isMobile = useMobileCheck();

    useEffect(() => {
        if (nepokretnostId === undefined || (nepokretnostId !== undefined && !firstTimeEdit)) {
            setFormData({ ...defaultAssetsAdvokati, tip: formData.tip, id: nepokretnostId });
        }
        if (firstTimeEdit) {
            setFirstTimeEdit(false);
        }
    }, [formData.tip]);

    useEffect(() => {
        if (nepokretnostId !== undefined) {
            const existingNepokretnost = nepokretnostKN.find((nep) => nep.id === nepokretnostId);
            if (existingNepokretnost) {
                setFormData(existingNepokretnost);
            }
        } else {
            setFormData({ ...defaultAssetsAdvokati, tip: "" });
        }
    }, [nepokretnostId, nepokretnostKN]);

    const handleSave = async () => {
        dispatch(addOrUpdateNepokretnostAdvokati(formData));
        setEditingNepokretnost(null);
        closeModal();
    };

    const handleClose = () => {
        setEditingNepokretnost(null);
        closeModal();
    };

    useEffect(() => {
        if (modalState && !isMobile) {
            const select = document.getElementById("AssetModalComponentAdvoakti");
            select?.focus();
        }
    }, [modalState]);

    return (
        <ModalContent id="AssetModalComponentAdvoakti">
            <KatakomModal isOpen={openModal} setIsOpen={setOpenModal}>
                <InfoMessageModal
                    closeModal={() => {
                        setOpenModal(false);
                    }}
                    message="Парцела са унетим подацима не постоји у Катастру."
                />
            </KatakomModal>
            <Row>
                <KatakomSelect
                    options={katastarskeOpstineOptions}
                    value={formData.koId || ""}
                    name="koId"
                    size="xl"
                    label="Општина - Катастарска општина*"
                    error={false}
                    onChange={(option: SelectOption) => {
                        const opstinaNaziv = option.value.split("_")[0];
                        const koNaziv = option.value.split("_")[1];
                        const opstinaId = option.value.split("_")[2];
                        const koId = option.value.split("_")[3];

                        setFormData({ ...formData, opstinaNaziv, koNaziv, opstinaId, koId });
                    }}
                    style={{ margin: "0 0 0 0px" }}
                />
            </Row>

            <Row style={{ justifyContent: "flex-end", marginLeft: "-220px" }}>
                <KatakomButton
                    label={"Сачувај"}
                    disabled={!formData.koId || validatingParcelaDisabled}
                    tooltipMessage={validatingParcelaDisabled ? "Проверава се валидност парцеле..." : ""}
                    onClick={async () => {
                        await handleSave();
                    }}
                />
                <KatakomButton label={"Одустани"} onClick={handleClose} theme="secondary" style={{ marginLeft: "30px" }} />
            </Row>
        </ModalContent>
    );
};

export default AssetModalComponentAdvokatiUverenjeOImovinskomStanju;
