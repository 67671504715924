import { useSelector } from "react-redux";
import styled from "styled-components";
import { themeColors } from "../../../../../constants/constants";
import { RootState } from "../../../../../store";
import { mapKeyToDisplayString } from "../../../../../utils/translate";

export const RenderOsnovniPodaci = () => {
    const data = useSelector((state: RootState) => state.case.basicInfo);

    return (
        <PreviewSection>
            {Object.entries(data).map(([key, option]) => (
                <PreviewComponent key={key}>
                    <Key>{mapKeyToDisplayString(key)}:</Key>
                    <Value>{option?.label || option}</Value>
                </PreviewComponent>
            ))}
        </PreviewSection>
    );
};

export const PreviewSection = styled.div`
    display: flex;
    color: ${themeColors.previewSectionColor};
    flex-direction: column;
    width: 100%;
    margin: 16px 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
`;

const PreviewComponent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 5px;
`;

const Key = styled.span`
    margin-right: 5px;
`;

const Value = styled.span``;
