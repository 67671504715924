import { ChangeEvent } from "react";
import { katastarskeOpstineOptions, svojstvoLicaOption } from "../../../../../../register/register";
import { Aplicant } from "../../../../../../store/CaseReducer";
import KatakomInput from "../../../../../shared/KatakomInput";
import KatakomSelect, { SelectOption } from "../../../../../shared/KatakomSelect";
import { Row } from "../../../../style";

export const FizickoDomace = ({ formData, setFormData }: { formData: Aplicant; setFormData: (aplicant: Aplicant) => void }) => {
    return (
        <>
            <Row>
                <KatakomSelect
                    options={svojstvoLicaOption}
                    value={formData.svojstvoLica || ""}
                    name="svojstvoLica"
                    label="Својство лица*"
                    size="s"
                    error={false}
                    onChange={(option: SelectOption) => setFormData({ ...formData, svojstvoLica: String(option.value) })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.ime || ""}
                    name="ime"
                    label="Име*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, ime: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.prezime || ""}
                    name="prezime"
                    label="Презиме*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, prezime: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.imeRoditelja || ""}
                    name="imeRoditelja"
                    label="Име родитеља"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, imeRoditelja: e.target.value })}
                />
            </Row>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.jmbg || ""}
                    name="jmbg"
                    label="ЈМБГ*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, jmbg: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.brojLicneKarte || ""}
                    name="brojLicneKarte"
                    label="Број личне карте"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, brojLicneKarte: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.email || ""}
                    name="email"
                    label="Е-пошта"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, email: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomSelect
                    options={katastarskeOpstineOptions}
                    value={formData.mestoSifra || ""}
                    name="mestoSifra"
                    size="s"
                    label="Општина*"
                    error={false}
                    onChange={(option: SelectOption) => {
                        const opstinaNaziv = option.value.split("_")[0];
                        const mestoNaziv = option.value.split("_")[1];
                        const opstinaSifra = option.value.split("_")[2];
                        const mestoSifra = option.value.split("_")[3];
                        setFormData({ ...formData, opstinaNaziv, mestoNaziv, opstinaSifra, mestoSifra });
                    }}
                />
            </Row>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.ulicaRucno || ""}
                    name="ulicaRucno"
                    label="Улица*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, ulicaRucno: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.kucniBroj || ""}
                    name="kucniBroj"
                    label="Кућни број*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, kucniBroj: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.postanskiBroj || ""}
                    name="postanskiBroj"
                    label="Поштански број"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, postanskiBroj: e.target.value })}
                />
            </Row>
        </>
    );
};