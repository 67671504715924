import React from "react";

export const PrivacePolicy: React.FC = () => {
    return (
        <div style={{ width: "80%", margin: "30px 10%" }}>
            <h1>Politika privatnosti</h1>
            <p>Poslednje ažuriranje: 10. januar 2024.</p>
            <p>
                Ova Politika privatnosti opisuje naše politike i postupke u vezi sa prikupljanjem, korišćenjem i otkrivanjem vaših
                informacija kada koristite uslugu i informiše vas o vašim pravima na privatnost i načinu na koji zakon štiti vaša prava.
            </p>
            <p>
                Mi koristimo vaše lične podatke kako bismo obezbedili i poboljšali uslugu. Korišćenjem usluge, slažete se sa prikupljanjem i
                upotrebom informacija u skladu sa ovom Politikom privatnosti. Ova Politika privatnosti je kreirana uz pomoć
                <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank">
                    Generatora politike privatnosti
                </a>
                .
            </p>
            <h2>Tumačenje i definicije</h2>
            <h3>Tumačenje</h3>
            <p>
                Reči čiji je početni slovo veliko imaju značenje definisano pod sledećim uslovima. Sledeće definicije imaće isto značenje
                bez obzira da li se pojavljuju u jednini ili množini.
            </p>
            <h3>Definicije</h3>
            <p>U svrhe ove Politike privatnosti:</p>
            <ul>
                <li>
                    <p>
                        <strong>Nalog</strong> označava jedinstveni nalog koji je kreiran za vas kako biste pristupili našoj usluzi ili
                        delovima naše usluge.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Povezana</strong> stranka označava entitet koji kontroliše, koji je pod kontrolom ili je pod zajedničkom
                        kontrolom sa strankom, gde &quot;kontrola&quot; znači vlasništvo nad 50% ili više akcija, učešća ili drugih hartija
                        od vrednosti koje imaju pravo glasa za izbor direktora ili drugih upravljačkih organa.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Aplikacija</strong> se odnosi na KATAKOM, softverski program koji pruža Kompanija.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Kompanija</strong>(nazvana "Kompanija", "Mi", "Naša" ili "Naše" u ovom Sporazumu) se odnosi na KATAKOM.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Zemlja</strong> se odnosi na: Srbija
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Uređaj</strong> značava svaki uređaj koji može pristupiti usluzi, kao što su računar, mobilni telefon ili
                        digitalna tablica.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Lični podaci </strong>su sve informacije koje se odnose na identifikovano ili identifikovano lice.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Usluga</strong> se odnosi na Aplikaciju.
                    </p>
                </li>
            </ul>
            <h2>Obrada i korišćenje vaših ličnih podataka</h2>
            <h3>Vrste podataka prikupljenih</h3>
            <h4>Lični podaci</h4>
            <p>
                Prilikom korišćenja naše usluge, možemo vas zamoliti da nam pružite određene lične podatke koji se mogu koristiti za
                kontaktiranje ili identifikaciju vas. Lično prepoznatljive informacije mogu uključivati, ali nisu ograničene na:
            </p>
            <ul>
                <li>
                    <p>Email adresu</p>
                </li>
                <li>
                    <p>Ime i prezime</p>
                </li>
                <li>
                    <p>Broj telefona</p>
                </li>
                <li>
                    <p>Adresu, državu, provinciju, poštanski broj, grad</p>
                </li>
            </ul>
            <h4>Podaci o korišćenju</h4>
            <p>Podaci o korišćenju se automatski prikupljaju prilikom korišćenja usluge.</p>
            <p>
                Podaci o korišćenju mogu obuhvatati informacije kao što su Internet protokol adresa vašeg uređaja (npr. IP adresa), tip
                pregledača, verzija pregledača, stranice naše usluge koje posećujete, vreme i datum vaše posete, vreme provedeno na tim
                stranicama, jedinstveni identifikatori uređaja i druge dijagnostičke podatke.
            </p>
            <p>
                Kada pristupate usluzi putem mobilnog uređaja, možemo automatski prikupljati određene informacije, uključujući, ali ne
                ograničavajući se na, vrstu mobilnog uređaja koji koristite, jedinstveni identifikator vašeg mobilnog uređaja, IP adresu
                vašeg mobilnog uređaja, operativni sistem vašeg mobilnog uređaja, vrstu mobilnog pregledača koji koristite, jedinstvene
                identifikatore uređaja i druge dijagnostičke podatke.
            </p>
            <p>
                Takođe možemo prikupljati informacije koje vaš pregledač šalje prilikom posete naše usluge ili kada pristupate usluzi putem
                mobilnog uređaja.
            </p>
            <h3>Korišćenje vaših ličnih podataka</h3>
            <p>Kompanija može koristiti lične podatke u sledeće svrhe:</p>
            <ul>
                <li>
                    <p>Da obezbedi i održava našu uslugu, uključujući praćenje korišćenja naše usluge. </p>
                </li>
                <li>
                    <p>
                        Upravljanje vašim nalogom: za upravljanje vašom registracijom kao korisnika usluge. Lični podaci koje pružate
                        omogućavaju vam pristup različitim funkcionalnostima usluge koje su vam dostupne kao registrovanom korisniku.
                    </p>
                </li>
                <li>
                    <p>
                        Za izvršenje ugovora: razvoj, usklađivanje i izvršenje ugovora o kupovini proizvoda, predmeta ili usluga koje ste
                        kupili ili bilo kog drugog ugovora s nama putem usluge.
                    </p>
                </li>
                <li>
                    <p>
                        Kontaktiranje vas: Kontaktiranje vas putem e-maila, telefonskih poziva, SMS poruka ili drugih ekvivalentnih oblika
                        elektronske komunikacije, kao što su obaveštenja o ažuriranjima ili informativne komunikacije vezane za
                        funkcionalnosti, proizvode ili ugovorene usluge, uključujući bezbednosna ažuriranja, kada je to neophodno ili
                        razumno za njih{" "}
                    </p>
                </li>
                <li>
                    <p>
                        Da vam pružimo vesti, posebne ponude i opšte informacije o drugim proizvodima, uslugama i događajima koje nudimo, a
                        koji su slični onima koje ste već kupili ili za koje ste se raspitivali osim ako niste odlučili da ne primate takve
                        informacije.
                    </p>
                </li>
                <li>
                    <p>Da upravljate vašim zahtevima: Da prisustvujete i upravljate vašim zahtevima prema nama. </p>
                </li>
                <li>
                    <p>
                        Za poslovne transfere: Vaše podatke možemo koristiti za procenu ili sprovođenje spajanja, dezinvesticije,
                        restrukturiranja, reorganizacije, raspuštanja ili druge prodaje ili prenosa neke ili celokupne Naše imovine, bilo da
                        se radi o neograničenom poslovanju ili kao deo stečaja, likvidacije, ili sličnog postupka, u kojem su lični podaci o
                        korisnicima naših usluga koje mi držimo među prenesenim sredstvima.
                    </p>
                </li>
                <li>
                    <p>
                        Za druge svrhe: Vaše informacije možemo koristiti u druge svrhe, kao što su analiza podataka, utvrđivanje trendova
                        korišćenja, utvrđivanje efikasnosti naših promotivnih kampanja i za procenu i poboljšanje naše usluge, proizvoda,
                        usluga, marketinga i vašeg iskustva.
                    </p>
                </li>
            </ul>
            <p>Vaše lične podatke možemo da podelimo u sledećim situacijama:</p>
            <ul>
                <li>
                    Sa dobavljačima usluga: Vaše lične podatke možemo deliti sa dobavljačima usluga da bismo nadgledali i analizirali
                    korišćenje naše usluge, da bismo Vas kontaktirali
                </li>
                <li>
                    Za poslovne transfere: Možemo da delimo ili prenesemo Vaše lične podatke u vezi sa ili tokom pregovora o bilo kakvom
                    spajanju, prodaji imovine Kompanije, finansiranju ili sticanju celog ili dela našeg poslovanja drugoj kompaniji.
                </li>
                <li>
                    Sa filijalama: Vaše informacije možemo da delimo sa našim filijalama, u kom slučaju ćemo zahtevati od njih da poštuju
                    ovu Politiku privatnosti. Povezane kompanije uključuju našu matičnu kompaniju i sve druge podružnice, partnere u
                    zajedničkom ulaganju ili druge kompanije koje kontrolišemo ili koje su pod zajedničkom kontrolom sa nama.
                </li>
                <li>
                    Sa poslovnim partnerima: Vaše podatke možemo deliti sa našim poslovnim partnerima da bismo vam ponudili određene
                    proizvode, usluge ili promocije.
                </li>
                <li>
                    Sa drugim korisnicima: kada delite lične podatke ili na drugi način komunicirate na javnim površinama sa drugim
                    korisnicima, takve informacije mogu da vide svi korisnici i mogu se javno distribuirati napolju.{" "}
                </li>
                <li>Uz Vašu saglasnost: Vaše lične podatke možemo otkriti u bilo koju drugu svrhu uz Vašu saglasnost. </li>
            </ul>
            <h3>Zadržavanje Vaših ličnih podataka</h3>
            <p>
                Kompanija će zadržati Vaše lične podatke samo onoliko dugo koliko je neophodno za svrhe navedene u ovoj Politici
                privatnosti. Zadržaćemo i koristiti Vaše lične podatke u meri koja je neophodna da bismo ispunili naše zakonske obaveze (na
                primer, ako smo obavezni da zadržimo Vaše podatke u skladu sa važećim zakonima), rešavamo sporove i sprovodimo naše pravne
                sporazume i politike.
            </p>
            <p>
                Kompanija će takođe zadržati podatke o korišćenju za potrebe interne analize. Podaci o korišćenju se generalno čuvaju kraće
                vreme, osim kada se ovi podaci koriste za jačanje bezbednosti ili za poboljšanje funkcionalnosti Naše usluge, ili smo
                zakonski obavezni da čuvamo ove podatke u dužem vremenskom periodu.
            </p>
            <h3>Prenos Vaših ličnih podataka</h3>
            <p>
                Vaše informacije, uključujući lične podatke, obrađuju se u poslovnim kancelarijama Kompanije i na svim drugim mestima gde se
                nalaze strane uključene u obradu. To znači da se ove informacije mogu preneti na — i održavati na — računarima koji se
                nalaze van vaše države, provincije, zemlje ili druge državne jurisdikcije gde se zakoni o zaštiti podataka mogu razlikovati
                od onih u vašoj jurisdikciji.
            </p>
            <p>
                Vaš pristanak na ovu Politiku privatnosti praćen Vašim podnošenjem takvih informacija predstavlja Vašu saglasnost sa tim
                transferom.
            </p>
            <p>
                Kompanija će preduzeti sve razumno neophodne korake kako bi osigurala da se vaši podaci tretiraju bezbedno iu skladu sa ovom
                Politikom privatnosti i da se neće izvršiti prenos vaših ličnih podataka organizaciji ili zemlji osim ako ne postoje
                adekvatne kontrole, uključujući bezbednost Vaši podaci i drugi lični podaci.
            </p>
            <h3>Izbrišite svoje lične podatke</h3>
            <p>Imate pravo da izbrišete ili zahtevate da pomognemo u brisanju ličnih podataka koje smo prikupili o vama.</p>
            <p>Naša usluga vam može dati mogućnost da izbrišete određene informacije o vama iz usluge.</p>
            <p>
                Svoje informacije možete ažurirati, izmeniti ili izbrisati u bilo kom trenutku tako što ćete se prijaviti na svoj nalog, ako
                ga imate, i posetiti odeljak podešavanja naloga koji vam omogućava da upravljate svojim ličnim podacima. Takođe možete da
                nas kontaktirate da zatražite pristup, ispravite ili izbrišete bilo koje lične podatke koje ste nam dali.
            </p>
            <p>
                Imajte na umu, međutim, da ćemo možda morati da zadržimo određene informacije kada imamo zakonsku obavezu ili zakonsku
                osnovu za to.
            </p>
            <h3>Otkrivanje Vaših ličnih podataka</h3>
            <h4>Poslovne transakcije</h4>
            <p>
                Ako je Kompanija uključena u spajanje, akviziciju ili prodaju imovine, Vaši lični podaci mogu biti preneti. Obavestićemo vas
                pre nego što se vaši lični podaci prenesu i postanu predmet druge politike privatnosti.{" "}
            </p>
            <h4>Sprovođenje zakona</h4>
            <p>
                Pod određenim okolnostima, od kompanije se može zahtevati da otkrije Vaše lične podatke ako to zahteva zakon ili kao odgovor
                na validne zahteve javnih organa (npr. suda ili vladine agencije).{" "}
            </p>
            <h4>Drugi zakonski zahtevi</h4>
            <p>Kompanija može otkriti Vaše lične podatke u dobroj nameri verujući da je takva radnja neophodna za:</p>
            <ul>
                <li>Poštovanje zakonske obaveze</li>
                <li>Zaštita i odbrana prava ili imovine Kompanije</li>
                <li>Sprečiti ili istražiti moguće pogrešne radnje u vezi sa Uslugom</li>
                <li>Zaštitite ličnu bezbednost korisnika usluge ili javnosti</li>
                <li>Zaštita od pravne odgovornosti</li>
            </ul>
            <h3>Sigurnost Vaših ličnih podataka</h3>
            <p>
                Bezbednost Vaših ličnih podataka nam je važna, ali imajte na umu da nijedan metod prenosa preko Interneta ili metod
                elektronskog skladištenja nije 100% siguran. Iako nastojimo da koristimo komercijalno prihvatljiva sredstva za zaštitu vaših
                ličnih podataka, ne možemo garantovati njihovu apsolutnu sigurnost.
            </p>
            <h2>Privatnost dece</h2>
            <p>
                Naša usluga se ne obraća nikome mlađem od 13 godina. Mi svesno ne prikupljamo lične podatke od bilo koga mlađeg od 13
                godina. Ako ste roditelj ili staratelj i svesni ste da nam je Vaše dete dalo lične podatke, molimo Kontaktirajte nas. Ako
                postanemo svesni da smo prikupili lične podatke od bilo koga mlađeg od 13 godina bez provere saglasnosti roditelja,
                preduzimamo korake da uklonimo te informacije sa naših servera.{" "}
            </p>
            <p>
                Ako moramo da se oslonimo na saglasnost kao pravni osnov za obradu vaših informacija, a vaša zemlja zahteva saglasnost
                roditelja, možda ćemo zahtevati saglasnost vašeg roditelja pre nego što prikupimo i koristimo te informacije.{" "}
            </p>
            <h2>Veze ka drugim veb lokacijama</h2>
            <p>
                Naša usluga može sadržati veze ka drugim veb lokacijama kojima mi ne upravljamo. Ako kliknete na vezu treće strane, bićete
                preusmereni na sajt te treće strane. Preporučujemo vam da pregledate Politiku privatnosti svakog sajta koji posetite.
            </p>
            <p>
                Nemamo kontrolu i ne preuzimamo odgovornost za sadržaj, politiku privatnosti ili praksu bilo kojih sajtova ili usluga trećih
                strana.
            </p>
            <h2>Promene ove Politike privatnosti</h2>
            <p>
                Možemo s vremena na vreme ažurirati našu politiku privatnosti. Obavestićemo Vas o svim promenama objavljivanjem nove
                Politike privatnosti na ovoj stranici.
            </p>
            <p>
                Obavestićemo vas putem e-pošte i/ili istaknutog obaveštenja na našoj usluzi pre nego što promena stupi na snagu i ažurirati
                datum „Poslednje ažuriranje“ na vrhu ove Politike privatnosti.
            </p>
            <p>
                Savetujemo vam da periodično pregledate ovu Politiku privatnosti za bilo kakve promene. Promene ove Politike privatnosti
                stupaju na snagu kada se objave na ovoj stranici.
            </p>
            <h2>Kontaktirajte nas</h2>
            <p>Ako imate bilo kakva pitanja o ovoj Politici privatnosti, možete nas kontaktirati:</p>
            <ul>
                <li>Na mejl: office@katakom.rs</li>
            </ul>
        </div>
    );
};

export default PrivacePolicy;
