export const isEmailValid = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/;

    return emailRegex.test(email);
};

export const isPasswordValid = (password: string): boolean => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#?&\.])[A-Za-z\d@$!%*#?&\.]{8,}$/;

    return passwordRegex.test(password);
};


export const isJmbgValid = (jmbg: string): boolean => {
    const jmbgRegex = /^\d{13}$/;

    return jmbgRegex.test(jmbg);
};

export const isJmbgOrPibValid = (input: string): boolean => {
    const regex8 = /^\d{8}$/;
    const regex13 = /^\d{13}$/;

    return regex8.test(input) || regex13.test(input);
};

export const isBrojLicneKarteValid = (brojLicneKarte: string): boolean => {
    const brojLicneKarteRegex = /^\d{9}$/;

    return brojLicneKarteRegex.test(brojLicneKarte);
};

export const checkForAttributeWithoutValue = (data: any, attributeName: string) => {
    if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
            if (checkForAttributeWithoutValue(data[i], attributeName)) {
                return true;
            }
        }
    } else if (typeof data === "object" && data !== null) {
        if (attributeName in data) {
            return true;
        }
        for (const key in data) {
            if (checkForAttributeWithoutValue(data[key], attributeName)) {
                return true;
            }
        }
    }
    return false;
};

export const checkForAttributeWithValue = (data: any, targetValue: any, attributeName: string) => {
    if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
            if (checkForAttributeWithValue(data[i], targetValue, attributeName)) {
                return true;
            }
        }
    } else if (typeof data === "object" && data !== null) {
        if (attributeName in data && data[attributeName] === targetValue) {
            return true;
        }
        for (const key in data) {
            if (checkForAttributeWithValue(data[key], targetValue, attributeName)) {
                return true;
            }
        }
    }
    return false;
};


export const administratorList = ["nikolicmarko653@gmail.com", "igor.nikolic032@hotmail.com", "p.filip1110@gmail.com"];
export const advokatiList = ["sukara.igor@gmail.com", "gobeljicluka@gmail.com", "jovana.kovacevic@live.com"];
export const mobileList = ["google.test@katakom.rs"];
