import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import KatakomSelect, { SelectOption } from "../../../../shared/KatakomSelect";
import KatakomInput from "../../../../shared/KatakomInput";
import { ChangeEvent } from "react";
import KatakomButton from "../../../../shared/KatakomButton";
import { katastarskeOpstineOptions, vrstaNepokretnostiOptions } from "../../../../../register/register";
import KatakomModal from "../../../../shared/KatakomModal";
import { VrstaNepokretnosti } from "../../../../../enums";
import { ModalContent, Row } from "../../../style";
import { InfoMessageModal } from "../../../../customComponent/InfoMessageModal";
import { AssetsAdvokati, addOrUpdateNepokretnostAdvokati } from "../../../../../store/CaseAdvokatiReducer";
import { defaultAssetsAdvokati } from "../../../../../register/defaultValues";
import { checkForAttributeWithValue } from "../../../../../utils/utils";
import api from "../../../../../services/api";

import useMobileCheck from "../../../../../hooks/useMobileChecker";

interface Props {
    closeModal: () => void;
    nepokretnostId?: number;
    setEditingNepokretnost: (id: AssetsAdvokati | null) => void;
    modalState?: boolean;
}

const AssetModalComponentAdvokatGroup3and4: FC<Props> = ({ closeModal, nepokretnostId, setEditingNepokretnost, modalState }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const nepokretnostKN = useSelector((state: RootState) => state.caseAdvokati.nepokretnostKN);
    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [firstTimeEdit, setFirstTimeEdit] = React.useState<boolean>(true);
    const [validatingParcelaDisabled, setValidatingParcelaDisabled] = React.useState<boolean>(false);
    const [formData, setFormData] = useState<AssetsAdvokati>({ ...defaultAssetsAdvokati, tip: "" });
    const isMobile = useMobileCheck();

    useEffect(() => {
        if (nepokretnostId === undefined || (nepokretnostId !== undefined && !firstTimeEdit)) {
            setFormData({ ...defaultAssetsAdvokati, tip: formData.tip, id: nepokretnostId });
        }
    }, []);

    useEffect(() => {
        if (nepokretnostId !== undefined) {
            const existingNepokretnost = nepokretnostKN.find((nep) => nep.id === nepokretnostId);
            if (existingNepokretnost) {
                setFormData(existingNepokretnost);
            }
        } else {
            setFormData({ ...defaultAssetsAdvokati, tip: "" });
        }
    }, [nepokretnostId, nepokretnostKN]);

    useEffect(() => {
        setDisabled(isDisabled());
    }, [nepokretnostKN, formData]);

    const isDisabled = (): boolean => {
        if (Object.values(formData).length === 0) {
            return true;
        }

        if (!formData.koId || !formData?.brojParcele) {
            return true;
        }

        return false;
    };

    const handleSave = async () => {
        const parcelaValid = await validateParcelaExists();
        setValidatingParcelaDisabled(false);

        if (parcelaValid) {
            dispatch(addOrUpdateNepokretnostAdvokati(formData));
            setEditingNepokretnost(null);
            closeModal();
        } else {
            setOpenModal(true);
        }
    };

    const handleClose = () => {
        setEditingNepokretnost(null);
        closeModal();
    };

    const validateParcelaExists = async () => {
        setValidatingParcelaDisabled(true);
        let queryParams: any = {};

        if (formData.koId !== "") {
            queryParams.koid = formData.koId;
        }

        if (formData.brojParcele !== "") {
            queryParams.brojParcele = formData.brojParcele;
        }

        if (formData.podBrojParcele !== "") {
            queryParams.podbrojParcele = formData.podBrojParcele;
        } else {
            queryParams.podbrojParcele = "0";
        }

        try {
            const res = await api.get(`api/v1/pretraga-parcele-po-broju-parcele`, queryParams);
            if (res?.data?.Parcele?.Parcela?.ID || res?.data?.Parcele?.Parcela?.length > 0) {
                if (formData.brojObjekta && formData.brojObjekta !== "0") {
                    const brojObjektaExists = checkForAttributeWithValue(res?.data, formData.brojObjekta, "BrDelaParc");

                    if (brojObjektaExists) {
                        return true;
                    } else {
                        return false;
                    }
                }

                if (formData.evidencijskiBroj && formData.evidencijskiBroj !== "0") {
                    const evidencijskiBroj = checkForAttributeWithValue(res?.data, formData.evidencijskiBroj, "EvidencijskiBroj");

                    if (evidencijskiBroj) {
                        return true;
                    } else {
                        return false;
                    }
                }

                return true;
            } else {
                return false;
            }
        } catch (e) {
            setValidatingParcelaDisabled(false);
            setOpenModal(true);
        }
    };

    useEffect(() => {
        if (modalState && !isMobile) {
            const select = document.getElementById("AssetModalComponentAdvoakti");
            select?.focus();
        }
    }, [modalState]);

    return (
        <ModalContent id="AssetModalComponentAdvoakti">
            <KatakomModal isOpen={openModal} setIsOpen={setOpenModal}>
                <InfoMessageModal
                    closeModal={() => {
                        setOpenModal(false);
                    }}
                    message="Парцела са унетим подацима не постоји у Катастру."
                />
            </KatakomModal>
            <Row>
                <KatakomSelect
                    options={katastarskeOpstineOptions}
                    value={formData.koId || ""}
                    name="koId"
                    size="xl"
                    label="Општина - Катастарска општина*"
                    error={false}
                    onChange={(option: SelectOption) => {
                        const opstinaNaziv = option.value.split("_")[0];
                        const koNaziv = option.value.split("_")[1];
                        const opstinaId = option.value.split("_")[2];
                        const koId = option.value.split("_")[3];

                        setFormData({ ...formData, opstinaNaziv, koNaziv, opstinaId, koId });
                    }}
                    style={{ margin: "0 0 0 0px" }}
                />
            </Row>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.brojParcele || ""}
                    name="brojParcele"
                    label="Број парцеле*"
                    size="m"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, brojParcele: e.target.value })}
                    style={{ margin: "0 50px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.podBrojParcele || ""}
                    name="podBrojParcele"
                    label="Подброј парцеле"
                    size="m"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, podBrojParcele: e.target.value })}
                    style={{ margin: "0 30px 0 0" }}
                />
                <KatakomInput
                    type="text"
                    value={formData.udeo || ""}
                    name="udeo"
                    label="Удео"
                    size="m"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, udeo: e.target.value })}
                    style={{ margin: "0 0px 0 0" }}
                />
            </Row>

            <Row>
                <KatakomInput
                    type="text"
                    value={formData.brojVoda || ""}
                    name="brojVoda"
                    label="Број вода"
                    size="m"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, brojVoda: e.target.value })}
                    style={{ margin: "0 50px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.list || ""}
                    name="list"
                    label="Лист"
                    size="m"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, list: e.target.value })}
                    style={{ margin: "0 30px 0 0" }}
                />
                <KatakomInput
                    type="text"
                    value={formData.vlasnik || ""}
                    name="vlasnik"
                    label="Власник"
                    size="m"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, vlasnik: e.target.value })}
                    style={{ margin: "0 0px 0 0" }}
                />
            </Row>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.opisVoda || ""}
                    name="opisVoda"
                    label="Опис вода"
                    size="fullSize"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, opisVoda: e.target.value })}
                    style={{ margin: "0 0px 0 0 " }}
                />
            </Row>

            <Row style={{ justifyContent: "flex-end", marginLeft: "-220px" }}>
                <KatakomButton
                    label={"Сачувај"}
                    disabled={disabled || validatingParcelaDisabled}
                    tooltipMessage={validatingParcelaDisabled ? "Проверава се валидност парцеле..." : ""}
                    onClick={async () => {
                        await handleSave();
                    }}
                />
                <KatakomButton label={"Одустани"} onClick={handleClose} theme="secondary" style={{ marginLeft: "30px" }} />
            </Row>
        </ModalContent>
    );
};

export default AssetModalComponentAdvokatGroup3and4;
