import React from "react";
import PageLayout from "../../shared/PageLayout";
import PretragaNelegalnihObjekataLayout from "./PretragaNelegalnihObjekataLayout";

const PretragaNelegalnihObjekata: React.FC = () => {
    return (
        <PageLayout>
            <PretragaNelegalnihObjekataLayout />
        </PageLayout>
    );
};

export default PretragaNelegalnihObjekata;
