import React from "react";
import { EStatusSearch } from "./eStatusSearch";
import { useSidebar } from "../../widgets/sidebar/SidebarContext";
import { Card, CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper } from "../style";

const EStatusLayout: React.FC = () => {
    const { sidebar } = useSidebar();
    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <Card>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>E-статус предмета </CardTitle>
                        </CardHeader>
                        <EStatusSearch />
                    </CardContainer>
                </Card>
            </CardWrapper>
        </LayoutWraper>
    );
};

export default EStatusLayout;
