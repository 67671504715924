import React, { FC } from "react";
import KatakomButton from "../../../../shared/KatakomButton";
import { Row } from "../../../style";
import { KatastarElementBody, KatastarElementBodyRow, Key, Value } from "../style";

interface Props {
    closeModal: () => void;
    data: any;
}

const TeretiModalNew: FC<Props> = ({ closeModal, data }) => {
    const Teret = (teret: any) => {
        return (
            <KatastarElementBody>
                <KatastarElementBodyRow>
                    <Key>Датум уписа:</Key>
                    <Value>{teret.teret.DatumUpisa}</Value>
                </KatastarElementBodyRow>
                <KatastarElementBodyRow>
                    <Key>Врста:</Key>
                    <Value>{teret.teret.Vrsta}</Value>
                </KatastarElementBodyRow>
                <KatastarElementBodyRow>
                    <Key>Опис:</Key>
                    <Value>{teret.teret.Opis}</Value>
                </KatastarElementBodyRow>
                <div style={{ borderBottom: "1px solid black", paddingBottom: "20px" }}></div>
            </KatastarElementBody>
        );
    };

    const RenderTereta = () => {
        if (data?.Teret?.Vrsta || data?.Teret?.Opis) {
            return <Teret teret={data.Teret} />;
        }
        if (!data?.Teret || Object.keys(data.Teret).length === 0) {
            return (
                <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px", padding: "20px" }}>Не постоје терети!</div>
            );
        } else {
            {
                return data.Teret.map((teret: any) => {
                    return <Teret teret={teret} />;
                });
            }
        }
    };

    return (
        <React.Fragment>
            <RenderTereta />
            <Row style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
                <KatakomButton label={"Затвори"} onClick={closeModal} theme="secondary" />
            </Row>
        </React.Fragment>
    );
};

export default TeretiModalNew;
