import React, { useState } from "react";
import styled from "styled-components";
import { themeColors } from "../../constants/constants";

type ToggleButtonProps = {
    onClick: () => void;
    toggled: boolean;
    label: string;
};

const ToggleButtonContainer = styled.button<{ toggled: boolean }>`
    width: 60px;
    height: 20px;
    background-color: ${(props) => (props.toggled ? themeColors.toggleButtonContainerBackgroundColorToggled : themeColors.toggleButtonContainerBackgroundColorUnToggled)};
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s;

    &:hover::before {
        box-shadow: 0 0 2px 3px rgba(45, 45, 45, 0.2);
    }

    &::before {
        content: "";
        position: absolute;
        width: 36px;
        height: 36px;
        top: -8px;
        background-color: white;
        border-radius: 36px;
        left: ${(props) => (props.toggled ? "30px" : "0px")};
        transition: left 0.3s, box-shadow 0.3s;
    }
`;

const Label = styled.div`
    margin-left: 20px;
`;

const KatakomToggleButton: React.FC<ToggleButtonProps> = ({ onClick, toggled, label }) => {
    return (
        <>
            <ToggleButtonContainer onClick={onClick} toggled={toggled}>
                {toggled ? "" : ""}
            </ToggleButtonContainer>
            <Label>{label}</Label>
        </>
    );
};

export default KatakomToggleButton;
