import React, { useEffect } from "react";
import {
    KatastarElement,
    KatastarElementBody,
    KatastarElementBodyRow,
    KatastarElementHeader,
    Key,
    LocationTransparentIcon,
    PDFIcon,
    PreviewPDFIFrame,
    TabContainer,
    Value,
} from "./style";
import KatakomButton from "../../../shared/KatakomButton";
import KatakomTable from "../../../shared/KatakomTable";
import { AppDispatch, RootState, useAppDispatch } from "../../../../store";
import { useSelector } from "react-redux";
import KatakomModal from "../../../shared/KatakomModal";
import { pdf } from "@react-pdf/renderer";
import GenerateObjekatPDF from "../../../pdfs/ObjekatPDF";
import GenerateDeoObjekatPDF from "../../../pdfs/DeoObjektaPDF";
import KatakomInput from "../../../shared/KatakomInput";
import { translateToCyrilic, translateToLatinic } from "../../../../utils/translate";
import moment from "moment";
import { Column, Row } from "../../style";
import useMobileCheck from "../../../../hooks/useMobileChecker";
import KatakomModalNg from "../../../shared/KatakomModalNg";
import LicaModalNew from "./modals/LicaModalNew";
import TeretiModalNew from "./modals/TeretiModalNew";

interface ObjekatProps {
    data: any;
    redirectToRGZ: () => void;
    redirectToDRR: () => void;
    setActiveTab: (number: number) => void;
}

export const Objekat: React.FC<ObjekatProps> = ({ data, redirectToRGZ, setActiveTab, redirectToDRR }) => {
    const parcela = useSelector((state: RootState) => state.katastar.parcela);
    const deoParcele = useSelector((state: RootState) => state.katastar.deoParcele);
    const objekat = useSelector((state: RootState) => state.katastar.objekat);
    const [pdfSrc, setPdfSrc] = React.useState<string | null>(null);
    const [previewPDF, setPreviewPDF] = React.useState<boolean>(false);
    const isMobile = useMobileCheck();

    const dispatch: AppDispatch = useAppDispatch();

    const downloadPdf = async () => {
        const blob = await pdf(<GenerateObjekatPDF pdfData={parcela} deoParceleData={deoParcele} objekat={objekat} />).toBlob();
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        const date = moment().format("YYYYMMDD");
        a.download = `${date}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        // @ts-ignore
        if (window?.ReactNativeWebView) {
            const base64Data = await new Response(blob).arrayBuffer();
            const base64String = btoa(new Uint8Array(base64Data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
            const downloadInfo = {
                message: "DownloadFile",
                base64Data: base64String,
                fileName: `${date}.pdf`,
            };

            // @ts-ignore
            window.ReactNativeWebView.postMessage(JSON.stringify(downloadInfo));
        }
    };
    const previewPdf = async () => {
        const blob = await pdf(<GenerateObjekatPDF pdfData={parcela} deoParceleData={deoParcele} objekat={objekat} />).toBlob();
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        setPreviewPDF(true);
        setPdfSrc(url);
    };

    const downloadTablePdf = async (item: any) => {
        const blob = await pdf(
            <GenerateDeoObjekatPDF pdfData={parcela} deoParceleData={deoParcele} objekat={objekat} deoObjekta={item} />
        ).toBlob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        const date = moment().format("YYYYMMDD");
        a.download = `${date}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        // @ts-ignore
        if (window?.ReactNativeWebView) {
            const base64Data = await new Response(blob).arrayBuffer();
            const base64String = btoa(new Uint8Array(base64Data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
            const downloadInfo = {
                message: "DownloadFile",
                base64Data: base64String,
                fileName: `${date}.pdf`,
            };

            // @ts-ignore
            window.ReactNativeWebView.postMessage(JSON.stringify(downloadInfo));
        }
    };

    const previewTablePdf = async (item: any) => {
        const blob = await pdf(
            <GenerateDeoObjekatPDF pdfData={parcela} deoParceleData={deoParcele} objekat={objekat} deoObjekta={item} />
        ).toBlob();
        const url = URL.createObjectURL(blob);
        setPreviewPDF(true);
        setPdfSrc(url);
    };

    const [licaModalOpen, setLicaModalOpen] = React.useState<boolean>(false);
    const [teretiModalOpen, setTeretiModalOpen] = React.useState<boolean>(false);
    const [tableData, setTableData] = React.useState(
        Array.isArray(objekat?.DeloviObjekta?.ObjekatDeo)
            ? objekat?.DeloviObjekta?.ObjekatDeo.filter(Boolean)
            : [objekat?.DeloviObjekta?.ObjekatDeo].filter(Boolean)
    );
    const [searchFilter, setSearchFilter] = React.useState<string>("");

    useEffect(() => {
        setTableData(
            Array.isArray(objekat?.DeloviObjekta?.ObjekatDeo)
                ? objekat?.DeloviObjekta?.ObjekatDeo.filter(Boolean)
                : [objekat?.DeloviObjekta?.ObjekatDeo].filter(Boolean)
        );
    }, [objekat?.DeloviObjekta?.ObjekatDeo]);

    const filterData = (value: string) => {
        const filterRecursively = (obj: any, searchValue: string): boolean => {
            for (let key in obj) {
                if (
                    typeof obj[key] === "string" &&
                    translateToLatinic(obj[key].toLowerCase()).includes(translateToLatinic(translateToCyrilic(searchValue.toLowerCase())))
                ) {
                    return true;
                }
                if (typeof obj[key] === "object" && filterRecursively(obj[key], searchValue)) {
                    return true;
                }
            }
            return false;
        };

        const obj = Array.isArray(objekat?.DeloviObjekta?.ObjekatDeo)
            ? objekat?.DeloviObjekta?.ObjekatDeo
            : [objekat?.DeloviObjekta?.ObjekatDeo];

        const filteredData = obj.filter((item: any) => {
            return filterRecursively(item, value);
        });

        setTableData(filteredData.filter(Boolean));
    };

    if (Object.keys(objekat).length === 0) return null;
    return (
        <TabContainer>
            <KatakomModal isOpen={previewPDF} setIsOpen={setPreviewPDF}>
                <PreviewPDFIFrame>
                    {pdfSrc && (
                        <iframe
                            src={pdfSrc}
                            title="PDF Preview"
                            style={{ width: "100%", height: "80vh", border: "1px solid #ccc" }}
                        ></iframe>
                    )}
                    <Row style={{ justifyContent: "center" }}>
                        <KatakomButton
                            label={"Затвори"}
                            onClick={() => {
                                setPreviewPDF(false);
                            }}
                        ></KatakomButton>
                    </Row>
                </PreviewPDFIFrame>
            </KatakomModal>

            <KatastarElement>
                <KatastarElementHeader>
                    <div style={{ width: "66%" }}>део парцеле {objekat.BrDelaParc}</div>
                    <div
                        style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        onClick={() => {
                            redirectToRGZ();
                        }}
                    >
                        прикажи на мапи <LocationTransparentIcon />
                    </div>
                    <div
                        style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        onClick={() => {
                            redirectToDRR();
                        }}
                    >
                        прикажи на ДРР <LocationTransparentIcon />
                    </div>
                </KatastarElementHeader>
                <KatastarElementBody>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "68%" }}>
                            <KatastarElementBodyRow>
                                <Key>Улица: </Key>
                                <Value>{objekat?.Ulica}</Value>
                            </KatastarElementBodyRow>
                            <KatastarElementBodyRow>
                                <Key>Кућни број: </Key>
                                <Value>{objekat?.KucniBroj}</Value>
                            </KatastarElementBodyRow>
                            <KatastarElementBodyRow>
                                <Key>Кућни подброј: </Key>
                                <Value>{objekat?.KucniPodbroj}</Value>
                            </KatastarElementBodyRow>
                            <KatastarElementBodyRow>
                                <Key>Површина: </Key>
                                <Value>{objekat?.Povrsina}</Value>
                            </KatastarElementBodyRow>
                            <KatastarElementBodyRow>
                                <Key>Начин коришћења објекта: </Key>
                                <Value>{objekat?.NacinKoriscenja}</Value>
                            </KatastarElementBodyRow>
                            <KatastarElementBodyRow>
                                <Key>Статус објекта: </Key>
                                <Value>{objekat?.PravniStatus}</Value>
                            </KatastarElementBodyRow>
                        </div>

                        <div style={{ display: "flex", width: "32%", justifyContent: "center" }} onClick={() => {}}>
                            <div style={{ display: "flex", marginTop: 10 }}>
                                {isMobile ? null : (
                                    <div style={{ display: "flex", marginTop: 10 }}>
                                        <PDFIcon
                                            onClick={() => {
                                                if (!isMobile) {
                                                    previewPdf();
                                                } else {
                                                    downloadPdf();
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", padding: "10px" }} onClick={() => {}}>
                        {isMobile ? (
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <PDFIcon
                                    style={{ zIndex: 0 }}
                                    onClick={() => {
                                        downloadPdf();
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>

                    <KatastarElementBodyRow style={{ padding: "20px" }}>
                        <Column>
                            <Row>
                                <KatakomButton
                                    label={"Лица"}
                                    onClick={() => {
                                        setLicaModalOpen(true);
                                    }}
                                    size={isMobile ? "s" : "m"}
                                    style={{ marginRight: isMobile ? "30px" : "50px" }}
                                />
                                <KatakomButton
                                    label={"Терети"}
                                    onClick={() => {
                                        setTeretiModalOpen(true);
                                    }}
                                    size={isMobile ? "s" : "m"}
                                    theme="secondary"
                                />

                                {isMobile ? null : (
                                    <KatakomInput
                                        type={"text"}
                                        placeholder={"Претражите..."}
                                        name={"deoObjektaSearch"}
                                        error={false}
                                        onChange={(e) => {
                                            setSearchFilter(e.target.value);
                                            filterData(e.target.value);
                                        }}
                                        value={searchFilter}
                                        style={{ marginLeft: "20px" }}
                                    />
                                )}
                            </Row>

                            {isMobile ? (
                                <Row>
                                    <KatakomInput
                                        type={"text"}
                                        placeholder={"Претражите..."}
                                        name={"deoObjektaSearch"}
                                        error={false}
                                        onChange={(e) => {
                                            setSearchFilter(e.target.value);
                                            filterData(e.target.value);
                                        }}
                                        value={searchFilter}
                                    />
                                </Row>
                            ) : null}
                        </Column>
                    </KatastarElementBodyRow>

                    {tableData && tableData?.length !== 0 ? (
                        <>
                            <KatastarElementBodyRow style={{ padding: "20px" }}>
                                <Key style={{ minWidth: 600, maxWidth: 600 }}>Изаберите објекат:</Key>
                            </KatastarElementBodyRow>
                            <div style={{ margin: "15px" }}>
                                <KatakomTable
                                    columns={[
                                        { value: "Ulica", label: "Улица " },
                                        { value: "EvidencijskiBroj", label: "Евид. број " },
                                        { value: "BrojUlaza", label: "Број улаза" },
                                        { value: "BrojStana", label: "Број стана" },
                                        { value: "PodbrojStana", label: "Подброј стана" },
                                        { value: "NacinUtvrdjivanjaKorPovrsine", label: "Начин коришћења посебног дела" },
                                        { value: "PovrsinaKorisna", label: "Корисна површина (м2)" },
                                        {
                                            value: "katastarDownload",
                                            label: "ПДФ",
                                            func: (item, download) => {
                                                if (!isMobile && !download) {
                                                    previewTablePdf(item);
                                                } else {
                                                    downloadTablePdf(item);
                                                }
                                            },
                                        },
                                    ]}
                                    data={tableData}
                                />
                            </div>
                        </>
                    ) : null}
                </KatastarElementBody>
            </KatastarElement>
            <KatakomModalNg isOpen={licaModalOpen} setIsOpen={setLicaModalOpen}>
                <LicaModalNew
                    data={objekat.ImaociPrava}
                    closeModal={() => {
                        setLicaModalOpen(false);
                    }}
                />
            </KatakomModalNg>
            <KatakomModalNg isOpen={teretiModalOpen} setIsOpen={setTeretiModalOpen}>
                <TeretiModalNew
                    data={objekat.Tereti}
                    closeModal={() => {
                        setTeretiModalOpen(false);
                    }}
                />
            </KatakomModalNg>
        </TabContainer>
    );
};
