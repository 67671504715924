import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { themeIcon } from "../../../constants/constants";
import KatakomButton from "../../shared/KatakomButton";
import { Row } from "../style";
import KatakomToggleButton from "../../shared/KatakomToggleButton";
import api from "../../../services/api";
import { User } from "./userManagementList";
import { ROLE } from "../../../enums/role";
import KatakomModal from "../../shared/KatakomModal";
import { ErrorMessageModal } from "../../customComponent/ErrorMessageModal";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    userData: User | null;
}
const ModalOverlay = styled.div<{ isOpen: boolean }>`
    display: ${(props) => (props.isOpen ? "block" : "none")};
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
`;

const ModalContent = styled.div`
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.span`
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    &:hover,
    &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;

const FormGroup = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const Title = styled.h2`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Label = styled.label`
    width: 170px;
`;

const LargeLabel = styled.label`
    width: 300px;
`;

const Input = styled.input`
    flex-grow: 1;
    padding: 8px;
    margin-left: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    font-size: 14px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
    background: #eeeeee;
    border: none;
`;

const Select = styled.select`
    flex-grow: 1;
    padding: 8px;
    margin-left: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    font-size: 14px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
    background: #eeeeee;
    border: none;
`;

const ThemePicker = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;

    img {
        width: 100px;
        height: auto;
        margin-top: 15px;
        border-radius: 4px;
        margin-left: 10px;
        cursor: pointer;
    }
`;

const UserModal: React.FC<ModalProps> = ({ isOpen, onClose, userData }) => {
    const [formData, setFormData] = useState({ ...userData });
    const [errorModal, setErrorModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        setFormData({ ...userData });
    }, [userData]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        //@ts-ignore
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const checkIfLawyerExists = async () => {
        try {
            const res = await api.get(`api/v1/lawyers-check-if-exists?mb=${formData.mb}`);

            if (!res.data.isExists) {
                setErrorMessage("Aдвокат не постоји у бази података! Проверите унете вредности.");
                setErrorModal(true);
                return false;
            }
            return true;
        } catch (e) {
            setErrorMessage("Aдвокат не постоји у бази података! Проверите унете вредности.");
            setErrorModal(true);
            return false;
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Check if lawyer exists for role ADVOCATE
        if (formData.type === ROLE.ADVOKAT) {
            const lawyerExists = await checkIfLawyerExists();
            if (!lawyerExists) {
                onClose();
                return;
            }
        }

        try {
            await api.patch(`patch-user`, {
                office_id: formData.office_id ? String(formData.office_id) : null,
                email: formData.email,
                is_active: formData.is_active,
                type: formData.type,
                first_name: formData.first_name,
                last_name: formData.last_name,
                mb: formData.mb,
                theme_id: formData.theme_id || "theme-default",
                auto_colapse: formData.auto_colapse,
                is_2fa_enabled: formData.is_2fa_enabled,
            });
            onClose();
        } catch (error) {
            setErrorMessage("Error updating user. Please try again.");
            setErrorModal(true);
        }
    };

    return (
        <>
            <KatakomModal isOpen={errorModal} setIsOpen={setErrorModal}>
                <ErrorMessageModal closeModal={() => setErrorModal(false)} message={errorMessage} />
            </KatakomModal>

            <ModalOverlay isOpen={isOpen}>
                <ModalContent>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                    <Title>{`${formData.first_name} ${formData.last_name}`}</Title>
                    <FormGroup>
                        <Label htmlFor="email">И-мејл</Label>
                        <Input readOnly type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="first_name">Име</Label>
                        <Input type="text" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="last_name">Презиме</Label>
                        <Input type="text" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="type">Тип</Label>
                        <Select id="type" name="type" value={formData.type} onChange={handleChange}>
                            {Object.values(ROLE).map((role) => (
                                <option key={role} value={role}>
                                    {role}
                                </option>
                            ))}
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="is_active" style={{ marginRight: "10px" }}>
                            Активан
                        </Label>
                        <KatakomToggleButton
                            onClick={() => setFormData({ ...formData, is_active: !formData.is_active })}
                            //@ts-ignore
                            toggled={formData.is_active}
                            label={""}
                        />{" "}
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="office_id">Канцеларија ИД</Label>
                        <Input type="text" id="office_id" name="office_id" value={formData.office_id} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="maticni_broj">Матични број</Label>
                        <Input type="text" id="mb" name="mb" value={formData.mb} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="theme">Тема</Label>
                        <ThemePicker>
                            <img
                                src={`${themeIcon}/theme1.svg`}
                                alt="theme_default"
                                onClick={() => setFormData({ ...formData, theme_id: "theme-default" })}
                            />
                            <img
                                src={`${themeIcon}/theme2.svg`}
                                alt="theme_blue"
                                onClick={() => setFormData({ ...formData, theme_id: "theme-default" })}
                            />
                            <img
                                src={`${themeIcon}/theme3.svg`}
                                alt="theme_green"
                                onClick={() => setFormData({ ...formData, theme_id: "theme-default" })}
                            />
                        </ThemePicker>
                    </FormGroup>

                    <FormGroup>
                        <LargeLabel htmlFor="auto_colapse" style={{ marginRight: "10px" }}>
                            Аутоматско затварање менија
                        </LargeLabel>
                        <KatakomToggleButton
                            onClick={() => setFormData({ ...formData, auto_colapse: !formData.auto_colapse })}
                            //@ts-ignore
                            toggled={formData.auto_colapse}
                            label={""}
                        />{" "}
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="is_2fa_enabled" style={{ marginRight: "10px" }}>
                            2fa*
                        </Label>
                        <KatakomToggleButton
                            onClick={() => setFormData({ ...formData, is_2fa_enabled: !formData.is_2fa_enabled })}
                            //@ts-ignore
                            toggled={formData.is_2fa_enabled}
                            label={""}
                        />
                    </FormGroup>
                    <Row style={{ justifyContent: "center", marginTop: "20px" }}>
                        <KatakomButton label={"Сачувај"} onClick={handleSubmit} />
                    </Row>
                </ModalContent>
            </ModalOverlay>
        </>
    );
};

export default UserModal;
