import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import KatakomSelect, { SelectOption } from "../../../../shared/KatakomSelect";
import KatakomInput from "../../../../shared/KatakomInput";
import { ChangeEvent } from "react";
import KatakomButton from "../../../../shared/KatakomButton";
import { katastarskeOpstineOptions } from "../../../../../register/register";
import { defaultAssetsTip2 } from "../../../../../register/defaultValues";
import KatakomModal from "../../../../shared/KatakomModal";
import { ModalContent, Row } from "../../../style";
import { InfoMessageModal } from "../../../../customComponent/InfoMessageModal";
import { AssetsTip2, addOrUpdateNepokretnostTip2 } from "../../../../../store/CaseTip2Reducer";
import api from "../../../../../services/api";
import useMobileCheck from "../../../../../hooks/useMobileChecker";
import { SelectTable } from "../../../newCaseCommon/SelectTable";

interface Props {
    closeModal: () => void;
    nepokretnostId?: number;
    setEditingNepokretnost: (id: AssetsTip2 | null) => void;
    modalState: boolean;
}

const AssetModalComponentTip2: FC<Props> = ({ closeModal, nepokretnostId, setEditingNepokretnost, modalState }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const nepokretnostKN = useSelector((state: RootState) => state.caseTip2.nepokretnostKN);
    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [firstTimeEdit, setFirstTimeEdit] = React.useState<boolean>(true);
    const [validatingParcelaDisabled, setValidatingParcelaDisabled] = React.useState<boolean>(false);
    const isMobile = useMobileCheck();
    const [showSelectTable, setShowSelectTable] = useState<boolean>(false);
    const [tableData, setTableData] = useState([]);

    const [formData, setFormData] = useState<AssetsTip2>(defaultAssetsTip2);

    useEffect(() => {
        if (nepokretnostId === undefined || (nepokretnostId !== undefined && !firstTimeEdit)) {
            setFormData({ ...defaultAssetsTip2, id: nepokretnostId });
        }
        if (firstTimeEdit) {
            setFirstTimeEdit(false);
        }
    }, []);

    useEffect(() => {
        if (nepokretnostId !== undefined) {
            const existingNepokretnost = nepokretnostKN.find((nep) => nep.id === nepokretnostId);
            if (existingNepokretnost) {
                setFormData(existingNepokretnost);
            }
        } else {
            setFormData(defaultAssetsTip2);
        }
    }, [nepokretnostId, nepokretnostKN]);

    useEffect(() => {
        setDisabled(isDisabled());
    }, [nepokretnostKN, formData]);

    const isDisabled = (): boolean => {
        if (Object.values(formData).length === 0) {
            return true;
        }

        if (!formData.koId || !formData.brojParcele || !formData.udeo) {
            return true;
        }
        return false;
    };

    const handleSave = async () => {
        const povrsina = await validateParcelaExists();
        setValidatingParcelaDisabled(false);

        if (povrsina) {
            dispatch(addOrUpdateNepokretnostTip2({ ...formData, povrsina }));
            setEditingNepokretnost(null);
            closeModal();
        } else {
            setOpenModal(true);
        }
    };

    const handleClose = () => {
        setEditingNepokretnost(null);
        closeModal();
    };

    const validateParcelaExists = async () => {
        setValidatingParcelaDisabled(true);
        let queryParams: any = {};

        if (formData.koId !== "") {
            queryParams.koid = formData.koId;
        }

        if (formData.brojParcele !== "") {
            queryParams.brojParcele = formData.brojParcele;
        }

        if (formData.podBrojParcele !== "") {
            queryParams.podbrojParcele = formData.podBrojParcele;
        } else {
            queryParams.podbrojParcele = "0";
        }

        try {
            const res = await api.get(`api/v1/pretraga-parcele-po-broju-parcele`, queryParams);
            if (res?.data?.Parcele?.Parcela?.ID || res?.data?.Parcele?.Parcela?.length > 0) {
                return res?.data?.Parcele?.Parcela?.Povrsina ?? res?.data?.Parcele?.Parcela?.[0]?.Povrsina;
            } else {
                return false;
            }
        } catch (e) {
            setValidatingParcelaDisabled(false);
            setOpenModal(true);
        }
    };

    useEffect(() => {
        if (modalState && !isMobile) {
            const select = document.getElementById("AssetModalComponentTip2");
            select?.focus();
        }
    }, [modalState]);

    const showSelect = async () => {
        const selectData = await fatchSelectData();
        setTableData(selectData);

        if (formData.koId && formData.brojParcele) {
            setShowSelectTable(true);
        }
    };

    const fatchSelectData = async () => {
        let queryParams: any = {};

        if (formData.koId !== "") {
            queryParams.koid = formData.koId;
        }

        if (formData.brojParcele !== "") {
            queryParams.brojParcele = formData.brojParcele;
        }
        if (formData.podBrojParcele !== "") {
            queryParams.podbrojParcele = formData.podBrojParcele;
        } else {
            queryParams.podbrojParcele = "0";
        }

        let res = await api.get(`api/v1/pretraga-objekta-na-parceli`, queryParams);
        return res?.data;
    };

    return (
        <ModalContent id="AssetModalComponentTip2" tabIndex={0}>
            <KatakomModal isOpen={openModal} setIsOpen={setOpenModal}>
                <InfoMessageModal
                    closeModal={() => {
                        setOpenModal(false);
                    }}
                    message="Парцела са унетим подацима не постоји у Катастру."
                />
            </KatakomModal>
            <Row>
                <KatakomSelect
                    options={katastarskeOpstineOptions}
                    value={formData.koId || ""}
                    name="koId"
                    size="xl"
                    label="Општина - Катастарска општина*"
                    error={false}
                    onChange={(option: SelectOption) => {
                        const opstinaNaziv = option.value.split("_")[0];
                        const koNaziv = option.value.split("_")[1];
                        const opstinaId = option.value.split("_")[2];
                        const koId = option.value.split("_")[3];

                        setFormData({ ...formData, opstinaNaziv, koNaziv, opstinaId, koId });
                    }}
                />
            </Row>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.brojParcele || ""}
                    name="brojParcele"
                    label="Број парцеле*"
                    size="s"
                    error={false}
                    onFocus={() => {
                        setShowSelectTable(false);
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, brojParcele: e.target.value })}
                    style={{ margin: "0 50px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.podBrojParcele || ""}
                    name="podBrojParcele"
                    label="Подброј парцеле"
                    size="s"
                    error={false}
                    onFocus={() => {
                        setShowSelectTable(false);
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, podBrojParcele: e.target.value })}
                    style={{ margin: "0 50px 0 0" }}
                />
                <KatakomInput
                    type="text"
                    value={formData.brojDelaParcele || ""}
                    name="brojDelaParcele"
                    label="Број дела парцеле"
                    size="s"
                    error={false}
                    onFocus={async () => {
                        await showSelect();
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, brojDelaParcele: e.target.value })}
                    style={{ margin: "0 50px 0 0" }}
                />
                <KatakomInput
                    type="text"
                    value={formData.udeo || ""}
                    name="udeo"
                    label="Удео*"
                    size="s"
                    error={false}
                    onFocus={() => {
                        setShowSelectTable(false);
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, udeo: e.target.value })}
                />
            </Row>
            {showSelectTable ? (
                <Row>
                    <SelectTable
                        tableData={tableData}
                        onRowClick={(item) => {
                            setFormData({ ...formData, brojDelaParcele: item.BrDelaParc });
                            setShowSelectTable(false);
                        }}
                        type="objekat"
                    />
                </Row>
            ) : null}
            <Row style={{ justifyContent: "flex-end", marginLeft: "-220px" }}>
                <KatakomButton
                    label={"Сачувај"}
                    disabled={disabled || validatingParcelaDisabled}
                    tooltipMessage={validatingParcelaDisabled ? "Проверава се валидност парцеле..." : ""}
                    onClick={async () => {
                        await handleSave();
                    }}
                />
                <KatakomButton label={"Одустани"} onClick={handleClose} theme="secondary" style={{ marginLeft: "30px" }} />
            </Row>
        </ModalContent>
    );
};

export default AssetModalComponentTip2;
