import { createSlice } from "@reduxjs/toolkit";

export interface KatastarState {
    parcela: any;
    deoParcele: any;
    objekat: any;
}

const initialState: KatastarState = {
    parcela: {},
    deoParcele: {},
    objekat: {},
};

export const katastarSlice = createSlice({
    name: "katastar",
    initialState,
    reducers: {
        setParcela: (state, action) => {
            state.parcela = action.payload;
        },
        setDeoParcele: (state, action) => {
            state.deoParcele = action.payload;
        },
        setObjekat: (state, action) => {
            state.objekat = action.payload;
        },
        resetState: () => initialState,
    },
});

export const { resetState, setParcela, setDeoParcele, setObjekat } = katastarSlice.actions;
export default katastarSlice.reducer;
