import React, { ReactNode } from "react";
import PageLayout from "../../shared/PageLayout";
import SettingsWelcome from "../../customComponent/SettingsWelcome";
import { Link } from "react-router-dom";

interface SettingsPageProps {
    children?: ReactNode;
}

const SettingsPage: React.FC<SettingsPageProps> = ({ children }) => {
    return (
        <PageLayout color="secondary" content="settings">
            {children ? children : <SettingsWelcome />}
        </PageLayout>
    );
};

export default SettingsPage;
