import React from "react";

import { NelegalniObjektiDisplay } from "./NelegalniObjektiDisplay";
import { useSidebar } from "../../widgets/sidebar/SidebarContext";
import { Card, CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper } from "../style";

const PretragaNelegalnihObjekataLayout: React.FC = () => {
    const { sidebar } = useSidebar();

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <Card>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>ПРЕТРАГА НЕЛЕГАЛНИХ ОБЈЕКАТА</CardTitle>
                        </CardHeader>
                        <NelegalniObjektiDisplay />
                    </CardContainer>
                </Card>
            </CardWrapper>
        </LayoutWraper>
    );
};

export default PretragaNelegalnihObjekataLayout;
