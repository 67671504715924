import React, { ReactNode, useState, useEffect } from "react";
import { SidebarProvider } from "../widgets/sidebar/SidebarContext";
import Navbar from "../widgets/navbar/Navbar";
import Sidebar from "../widgets/sidebar/Sidebar";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import KatakomModal from "./KatakomModal";
import { Row } from "../pages/style";
import KatakomInput from "./KatakomInput";
import KatakomButton from "./KatakomButton";
import api from "../../services/api";
import Cookies from "js-cookie";
import { changeEmail, changeRole, setUser } from "../../store/UserReducer";
import { useLocation } from "react-router-dom";
import { ROLE } from "../../enums/role";

interface PageLayoutProps {
    children: ReactNode;
    color?: "primary" | "secondary";
    content?: string;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children, color, content }) => {
    const [loginAsModal, setLoginAsModal] = useState<boolean>(false);
    const [newUser, setNewUser] = useState<string>("");
    const location = useLocation();

    const role = useSelector((state: RootState) => state.user.role);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if ((role == ROLE.SUPERADMIN) && event.key === "l" && location.pathname === "/pocetna") {
                setLoginAsModal(true);
            }
        };

        document.addEventListener("keydown", handleKeyPress);
    }, []);

    const dispatch = useAppDispatch();

    const loginAs = async () => {
        const response = await api.get(`api/v1/login-as?email=${newUser}`);

        if (response?.data?.accessToken) {
            const userSettings = await api.get(`api/v1/get-user?email=${newUser}`);
            dispatch(
                setUser({
                    email: userSettings.data.email,
                    role: userSettings.data.type,
                    firstName: userSettings.data.first_name,
                    lastName: userSettings.data.last_name,
                    brojPretraga: userSettings.data.broj_pretraga || 0,
                    theme: userSettings.data.theme_id || "theme-default",
                })
            );
            Cookies.set("accessToken", response.data.accessToken);
            localStorage.setItem("email", newUser);
            window.location.reload();
        }
    };

    return (
        <>
            <KatakomModal isOpen={loginAsModal} setIsOpen={setLoginAsModal}>
                <div style={{ padding: "20px" }}>
                    <Row>
                        <KatakomInput
                            type={"text"}
                            value={newUser}
                            name={"newUser"}
                            size="xl"
                            label="Унесите е-пошту корисника"
                            error={false}
                            onChange={(e) => {
                                setNewUser(e.currentTarget.value);
                            }}
                            onEnter={async () => {
                                await loginAs();
                            }}
                        ></KatakomInput>
                    </Row>
                    <Row style={{ display: "flex", justifyContent: "center" }}>
                        <KatakomButton
                            label={"Одустани"}
                            onClick={() => {
                                setLoginAsModal(false);
                                setNewUser("");
                            }}
                            style={{ marginRight: "10px" }}
                            theme="secondary"
                        ></KatakomButton>
                        <KatakomButton
                            label={"Потврди"}
                            onClick={async () => {
                                loginAs();
                            }}
                        ></KatakomButton>
                    </Row>
                </div>
            </KatakomModal>
            <SidebarProvider>
                <Navbar />
                <Main>
                    <Sidebar color={color} sidebar={false} content={content} />
                    {children}
                </Main>
            </SidebarProvider>
        </>
    );
};

export default PageLayout;

const Main = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;
`;
