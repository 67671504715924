import React from "react";
import PageLayout from "../../shared/PageLayout";
import EStatusLayout from "./eStatusLayout";

const EStatus: React.FC = () => {
    return (
        <PageLayout>
            <EStatusLayout />
        </PageLayout>
    );
};

export default EStatus;
