import http from "./http";

export default {
    async get(url: string, queryParams: object = {}) {
        try {
            const response = await http.get(url, { params: { ...queryParams } });
            return response;
        } catch (err: any) {
            return err;
        }
    },
    async post(url: string, send: object) {
        try {
            const response = await http.post(url, send);
            return response;
        } catch (err: any) {
            return err;
        }
    },
    async put(url: string, send: object) {
        try {
            const response = await http.put(url, send);
            return response;
        } catch (err: any) {
            return err;
        }
    },
    async patch(url: string, send: object) {
        try {
            const response = await http.patch(url, send);
            return response;
        } catch (err: any) {
            return err;
        }
    },
    async delete(url: string) {
        try {
            await http.delete(url);
        } catch (err: any) {
            return err;
        }
    },
    async refreshToken() {
        const value = Number(localStorage.getItem("expired"));
        if (value && new Date(value) < new Date()) {
            const result = await http.get("/refresh");
            localStorage.setItem("accessToken", result.data.token);
            localStorage.setItem("expired", String(new Date().setSeconds(result.data.expired)));
        }
    },
};
