import { createSlice } from "@reduxjs/toolkit";

export interface KatastarState {
    caseStatusPreview: Partial<{
        id_prijem_esalter: number;
        id_dms: string;
        vrsta_isprave_id: string;
        sluzba_rgz: string;
        broj_predmeta: string;
        datum_predmeta: string;
        datum_prijema: string;
        status: string;
        broj_predmeta_suda: string;
        upisnik: string;
        mb: string;
        ucesnik: string;
    }>;
}

const initialState: KatastarState = {
    caseStatusPreview: {},
};

export const caseStatusSlice = createSlice({
    name: "caseStatus",
    initialState,
    reducers: {
        setCaseStatusPreview: (state, action) => {
            state.caseStatusPreview = action.payload;
        },
        resetState: () => initialState,
    },
});

export const { resetState, setCaseStatusPreview } = caseStatusSlice.actions;
export default caseStatusSlice.reducer;
