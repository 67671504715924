import styled from "styled-components";
import { themeColors } from "../../../constants/constants";

export const KatastarCard = styled.div<{ height?: string }>`
    display: flex;
    flex-direction: column;
    background-color: ${themeColors.katastarCardBackgroundColor};
    min-width: 100%;
    min-height: ${(props) => props.height || "25vh"};
    align-items: center;
    box-shadow: 0px 4px 4px 0px #00000040;
    position: relative;
`;

export const KatastarTitle = styled.div`
    display: flex;
    font-size: 20px;
    font-weight: 500;
    padding: 0 0 0 20px;
    align-items: center;
    background-color: ${themeColors.katastarTitleBackgroundColor};
    color: ${themeColors.katastarTitleColor};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: calc(100% - 20px);
    height: 36px;
`;
