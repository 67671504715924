import React, { useEffect, useState } from "react";
import KatakomInput from "../../../shared/KatakomInput";
import KatakomButton from "../../../shared/KatakomButton";
import { useNavigate } from "react-router-dom";
import { Card, CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper, Row } from "../../style";
import { useSidebar } from "../../../widgets/sidebar/SidebarContext";
import api from "../../../../services/api";
import KatakomModal from "../../../shared/KatakomModal";
import { SuccessMessageModal } from "../../../customComponent/SuccessMessageModal";
import { ErrorMessageModal } from "../../../customComponent/ErrorMessageModal";

import useMobileCheck from "../../../../hooks/useMobileChecker";
import { isPasswordValid } from "../../../../utils/utils";

const ChangePasswordLayout: React.FC = () => {
    const [password, setPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(true);
    const [tooltipMessage, setTooltipMessage] = useState<string | null>(null);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [errorModal, setErrorModal] = useState<boolean>(false);
    const isMobile = useMobileCheck();

    const { sidebar } = useSidebar();

    const isDisabled = () => {
        if (password === "" || newPassword === "" || confirmNewPassword === "") {
            setTooltipMessage("Сва обавезна поља морају бити попуњена!");
            return true;
        }


        if (newPassword !== confirmNewPassword) {
            setTooltipMessage("Шифре морају да се подударају!");
            return true;
        }


        if (!isPasswordValid(newPassword)) {
            setTooltipMessage("Шифра мора да садржи најмање 8 карактера,једно велико слово, један број и један специјални карактер!");
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (isDisabled()) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [password, newPassword, confirmNewPassword]);

    const navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    };

    const handleSubmit = () => {
        changePassword();
    };

    const changePassword = async () => {
        try {
            const response = await api.post(`change-password`, {
                email: localStorage.getItem("email"),
                oldPassword: password,
                newPassword: newPassword,
            });

            if (response.status !== 200) {
                setErrorModal(true);
            } else {
                setSuccessModal(true);
            }
        } catch (err) {
            setErrorModal(true);
        }
    };

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <KatakomModal isOpen={successModal} setIsOpen={setSuccessModal}>
                <SuccessMessageModal
                    closeModal={() => {
                        setSuccessModal(false);
                        routeChange("/podesavanja");
                    }}
                    message={"Успешно сте променили лозинку!"}
                ></SuccessMessageModal>
            </KatakomModal>
            <KatakomModal isOpen={errorModal} setIsOpen={setErrorModal}>
                <ErrorMessageModal
                    closeModal={() => {
                        setPassword("");
                        setNewPassword("");
                        setConfirmNewPassword("");
                        setErrorModal(false);
                    }}
                    message={"Дошло је до грешке приликом промене лозинке!"}
                ></ErrorMessageModal>
            </KatakomModal>

            <CardWrapper>
                <Card>
                    <CardContainer style={{ alignItems: "center" }}>
                        <CardHeader style={{ justifyContent: "center" }}>
                            <CardTitle style={{ marginLeft: 0 }}>ПРОМЕНИ ЛОЗИНКУ</CardTitle>
                        </CardHeader>
                        <Row style={{ justifyContent: "center" }}>
                            <KatakomInput
                                size={isMobile ? "m" : "l"}
                                type={"password"}
                                value={password}
                                name={"password"}
                                label="Тренутна лозинка"
                                error={false}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                            <KatakomInput
                                size={isMobile ? "m" : "l"}
                                type={"password"}
                                value={newPassword}
                                name={"newPassword"}
                                label="Нова лозинка"
                                error={false}
                                onChange={(e) => {
                                    setNewPassword(e.target.value);
                                }}
                            />
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                            <KatakomInput
                                size={isMobile ? "m" : "l"}
                                type={"password"}
                                value={confirmNewPassword}
                                name={"confirmNewPassword"}
                                label="Потврди нову лозинку"
                                error={false}
                                onChange={(e) => {
                                    setConfirmNewPassword(e.target.value);
                                }}
                            />
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                            <KatakomButton
                                label="Пошаљи"
                                onClick={handleSubmit}
                                style={{ margin: "0 25px" }}
                                disabled={disabled}
                                //@ts-ignore
                                tooltipMessage={tooltipMessage}
                            />
                            <KatakomButton
                                label="Одустани"
                                onClick={() => {
                                    routeChange("/podesavanja");
                                }}
                                style={{ margin: "0 25px" }}
                                theme="secondary"
                            />
                        </Row>
                    </CardContainer>
                </Card>
            </CardWrapper>
        </LayoutWraper>
    );
};

export default ChangePasswordLayout;
