import React from "react";
import PageLayout from "../../../shared/PageLayout";
import PretragaPoBrojuParaceleLayout from "./PretragaPoBrojuParaceleLayout";

const PretragaPoBrojuParacele: React.FC = () => {
    return (
        <PageLayout>
            <PretragaPoBrojuParaceleLayout />
        </PageLayout>
    );
};

export default PretragaPoBrojuParacele;
