import React from "react";
import PageLayout from "../../../shared/PageLayout";
import PretragaPoImenuLayout from "./PretragaPoImenuLayout";

const PretragaPoImenu: React.FC = () => {
    return (
        <PageLayout>
            <PretragaPoImenuLayout />
        </PageLayout>
    );
};

export default PretragaPoImenu;
