import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { sudOptions, upisnikOptions, vrstaUgovoraOptions } from "../register/register";
import { SelectOption } from "../components/shared/KatakomSelect";

export interface CaseState {
    basicInfo: BasicInfo;
    nepokretnostKN: Assets[];
    ucesnici: Aplicant[];
    dokumenta: Document[];
    tempDokumenta: LoadDocument[];
    loading: boolean;
    error: string | null;
}

export interface BasicInfo {
    datumUgovora: string;
    upisnik: SelectOption;
    maticniBrojSuda: SelectOption;
    vrstaIspraveId: SelectOption;
    brojOvereUgovora: number | null;
    brojPredmetaSuda: string;
    godinaOvereUgovora: number | null;
}

export interface Assets {
    id?: number;
    tip: string;
    podBrojParcele: string | number;
    opstinaNaziv: string;
    koNaziv: string;
    opstinaId: string | number;
    koId: string;
    brojParcele: string | number;
    brojObjekta: string | number;
    evidencijskiBroj: string | number;
}

export interface Aplicant {
    id?: number;
    vrstaLica?: VrstaLica;
    ime?: string;
    prezime?: string;
    imeRoditelja?: string;
    jmbg?: string;
    brojPasosa?: string;
    brojLicneKarte?: string;
    ssn?: string;
    maticniBroj?: string;
    pib?: string;
    imePravnogLica?: string;
    email?: string;
    drzavaNaziv?: string;
    flagKIM?: boolean;
    opstinaSifra?: string;
    opstinaNaziv?: string;
    mestoSifra?: string;
    mestoNaziv?: string;
    ulicaSifra?: string;
    ulicaNaziv?: string;
    ulicaRucno?: string;
    kucniBroj?: string;
    postanskiBroj?: string;
    flagUlicaRucno?: boolean;
    brojUpisaURegistarDrzaveSedista?: string;
    nazivRegistra?: string;
    flagPrebivalisteInostranstvo?: boolean;
    svojstvoLica?: string;
}

export interface LoadDocument {
    id: number;
    name: string;
    file: string;
    isSigned: boolean;
    category: string;
}

export interface Document {
    id_prijem_fajl_esalter: number;
}

export enum VrstaLica {
    FIZICKO_DOMACE = "физичко-домаће",
    FIZICKO_STRANAC = "физичко-странац",
    PRAVNO_DOMACE = "правно-домаће",
    PRAVNO_STRANAC = "правно-странац",
}

const initialState: CaseState = {
    basicInfo: {
        vrstaIspraveId: { value: vrstaUgovoraOptions[0].value, label: vrstaUgovoraOptions[0].label },
        datumUgovora: moment().format("DD-MM-YYYY"),
        brojOvereUgovora: null,
        godinaOvereUgovora: null,
        upisnik: { value: upisnikOptions[0].value, label: upisnikOptions[0].label },
        maticniBrojSuda: { value: sudOptions[0].value, label: sudOptions[0].label },
        brojPredmetaSuda: "",
    },
    nepokretnostKN: [],
    ucesnici: [],
    dokumenta: [],
    tempDokumenta: [],
    loading: false,
    error: null,
};

export const caseSlice = createSlice({
    name: "case",
    initialState,
    reducers: {
        resetState: () => initialState,
        updateBasicInfo: (state, action: PayloadAction<Partial<BasicInfo>>) => {
            state.basicInfo = { ...state.basicInfo, ...action.payload };
        },
        resetBasicInfo: (state) => {
            state.basicInfo = { ...initialState.basicInfo };
        },
        addOrUpdateNepokretnost: (state, action: PayloadAction<Partial<Assets>>) => {
            const formData = action.payload;
            if (formData.id === undefined) {
                // If id is not provided, generate a new id for the new "nepokretnost"
                //@ts-ignore
                const newId = state.nepokretnostKN.length === 0 ? 1 : state.nepokretnostKN[state.nepokretnostKN.length - 1].id + 1;
                //@ts-ignore
                state.nepokretnostKN.push({ ...formData, id: newId });
            } else {
                // If id is provided, update the existing "nepokretnost" data
                const existingNepokretnostIndex = state.nepokretnostKN.findIndex((nep) => nep.id === formData.id);
                if (existingNepokretnostIndex !== -1) {
                    state.nepokretnostKN[existingNepokretnostIndex] = { ...state.nepokretnostKN[existingNepokretnostIndex], ...formData };
                }
            }
        },
        deleteNepokretnost: (state, action: PayloadAction<number>) => {
            const nepokretnostId = action.payload;
            const updatedNepokretnosti = state.nepokretnostKN.filter((nep) => nep.id !== nepokretnostId);
            state.nepokretnostKN = updatedNepokretnosti;
        },
        addOrUpdateUcesnik: (state, action: PayloadAction<Partial<Assets>>) => {
            const formData = action.payload;
            if (formData.id === undefined) {
                //@ts-ignore
                const newId = state.ucesnici.length === 0 ? 1 : state.ucesnici[state.ucesnici.length - 1].id + 1;
                //@ts-ignore
                state.ucesnici.push({ ...formData, id: newId });
            } else {
                const existingUcesniciIndex = state.ucesnici.findIndex((pod) => pod.id === formData.id);
                if (existingUcesniciIndex !== -1) {
                    state.ucesnici[existingUcesniciIndex] = { ...state.ucesnici[existingUcesniciIndex], ...formData };
                }
            }
        },
        deleteUcesnik: (state, action: PayloadAction<number>) => {
            const ucesnikId = action.payload;
            const updatedUcesnici = state.ucesnici.filter((pod) => pod.id !== ucesnikId);
            state.ucesnici = updatedUcesnici;
        },
        addTempDocument: (state, action: PayloadAction<Partial<LoadDocument>>) => {
            const newId = state.tempDokumenta.length === 0 ? 1 : state.tempDokumenta[state.tempDokumenta.length - 1].id + 1;
            //@ts-ignore
            state.tempDokumenta.push({ ...action.payload, id: newId });
        },
        deleteTempDocument: (state, action: PayloadAction<number>) => {
            const documentId = action.payload;
            const updatedDocuments = state.tempDokumenta.filter((doc) => doc.id !== documentId);
            state.tempDokumenta = updatedDocuments;
        },
        addDocument: (state, action: PayloadAction<Document[]>) => {
            state.dokumenta = action.payload;
        },
    },
});

export const {
    resetState,
    updateBasicInfo,
    resetBasicInfo,
    addOrUpdateNepokretnost,
    deleteNepokretnost,
    addOrUpdateUcesnik,
    deleteUcesnik,
    addTempDocument,
    deleteTempDocument,
    addDocument,
} = caseSlice.actions;
export default caseSlice.reducer;
