import React, { useState } from "react";
import KatakomSelect, { SelectOption } from "../../../shared/KatakomSelect";
import { useSidebar } from "../../../widgets/sidebar/SidebarContext";
import { Card, CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper, Row } from "../../style";
import KatakomToggleButton from "../../../shared/KatakomToggleButton";
import api from "../../../../services/api";

const AccountSettingsLayout: React.FC = () => {
    const { sidebar } = useSidebar();
    const themeOptions: SelectOption[] = [
        { value: "theme-default", label: "Основна" },
        { value: "theme-blue", label: "Плава" },
        { value: "theme-green", label: "Zelena" },
    ];
    const [theme, setTheme] = useState(themeOptions.find((item) => item.value === localStorage.getItem("theme")));
    const [autoCollapsToggle, setAutoCollapsToggle] = useState<boolean>(localStorage.getItem("sidebarAutoCollaps") === "0" ? false : true);

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <Card>
                    <CardContainer style={{ alignItems: "center" }}>
                        <CardHeader style={{ justifyContent: "center" }}>
                            <CardTitle style={{ marginLeft: 0 }}>ПОДЕШАВАЊА НАЛОГА</CardTitle>
                        </CardHeader>

                        <Row style={{ marginLeft: "60px" }}>
                            <KatakomSelect
                                options={themeOptions}
                                //@ts-ignore
                                value={theme.value}
                                name={"themeOption"}
                                label="Изабери тему"
                                error={false}
                                onChange={async (option) => {
                                    api.patch(`api/v1/patch-user`, {
                                        email: localStorage.getItem("email"),
                                        theme_id: option.value,
                                    });
                                    setTheme(option);
                                    localStorage.setItem("theme", option.value);
                                    window.location.reload();
                                }}
                            />
                        </Row>
                        <Row style={{ marginLeft: "60px" }}>
                            <KatakomToggleButton
                                onClick={async () => {
                                    api.patch(`api/v1/patch-user`, {
                                        email: localStorage.getItem("email"),
                                        auto_colapse: !autoCollapsToggle,
                                    });
                                    localStorage.setItem("sidebarAutoCollaps", !autoCollapsToggle ? "1" : "0");
                                    setAutoCollapsToggle(!autoCollapsToggle);
                                }}
                                toggled={autoCollapsToggle}
                                label={"Аутоматско затварање менија"}
                            />
                        </Row>
                    </CardContainer>
                </Card>
            </CardWrapper>
        </LayoutWraper>
    );
};

export default AccountSettingsLayout;
