import React from "react";
import {
    KatastarElement,
    KatastarElementBody,
    KatastarElementBodyRow,
    KatastarElementHeader,
    Key,
    LocationIcon,
    LocationTransparentIcon,
    PreviewPDFIFrame,
    SearchIcon,
    TabContainer,
    Value,
} from "./style";
import KatakomButton from "../../../shared/KatakomButton";
import KatakomTable from "../../../shared/KatakomTable";
import { AppDispatch, RootState, useAppDispatch } from "../../../../store";
import { setDeoParcele } from "../../../../store/KatastarReducer";
import { useNavigate } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import GenerateDeoParcelePDF from "../../../pdfs/DeoParcelePDF";
import { useSelector } from "react-redux";
import moment from "moment";
import { Row } from "../../style";
import useMobileCheck from "../../../../hooks/useMobileChecker";
import LicaModalNew from "./modals/LicaModalNew";
import KatakomModalNg from "../../../shared/KatakomModalNg";
import TeretiModalNew from "./modals/TeretiModalNew";
import KatakomModal from "../../../shared/KatakomModal";

interface ParcelaProps {
    data: any;
    nelegalni: any;
    redirectToRGZ: () => void;
    redirectToDRR: () => void;
    redirectToNelegalni: () => void;
    setActiveTab: (number: number) => void;
    koid?: any;
    brOpstine?: any;
    brojParcele?: any;
    podbrojParcele?: any;
}

export const Parcela: React.FC<ParcelaProps> = ({
    data,
    nelegalni,
    redirectToRGZ,
    redirectToDRR,
    redirectToNelegalni,
    setActiveTab,
    koid,
    brOpstine,
    brojParcele,
    podbrojParcele,
}) => {
    const [licaModalOpen, setLicaModalOpen] = React.useState(false);
    const [teretiModalOpen, setTeretiModalOpen] = React.useState(false);
    const [pdfSrc, setPdfSrc] = React.useState<string | null>(null);
    const [previewPDF, setPreviewPDF] = React.useState<boolean>(false);
    const isMobile = useMobileCheck();

    let navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    };

    const dispatch: AppDispatch = useAppDispatch();

    const deoParcele = useSelector((state: RootState) => state.katastar.deoParcele);
    const parcela = useSelector((state: RootState) => state.katastar.parcela);
    const katastar = useSelector((state: RootState) => state.katastar);


    const downloadTablePdf = async (item: any) => {
        const blob = await pdf(<GenerateDeoParcelePDF pdfData={parcela} deoParceleData={item} />).toBlob();
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        const date = moment().format("YYYYMMDD");
        a.download = `${date}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        // @ts-ignore
        if (window?.ReactNativeWebView) {
            const base64Data = await new Response(blob).arrayBuffer();
            const base64String = btoa(new Uint8Array(base64Data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
            const downloadInfo = {
                message: "DownloadFile",
                base64Data: base64String,
                fileName: `${date}.pdf`,
            };

            // @ts-ignore
            window.ReactNativeWebView.postMessage(JSON.stringify(downloadInfo));
        }
    };

    const previewTablePdf = async (item: any) => {
        const blob = await pdf(<GenerateDeoParcelePDF pdfData={parcela} deoParceleData={item} />).toBlob();
        const url = URL.createObjectURL(blob);

        setPreviewPDF(true);
        setPdfSrc(url);
    };

    let tereti;
    if (Array.isArray(data?.Parcele?.Parcela)) {
        const t = data?.Parcele?.Parcela.map((item: any) => item.Tereti);
        const ft = t.filter((item: any) => Object.keys(item).length > 0);
        tereti = ft.map((item: any) => item.Teret).flat();
    } else {
        tereti = data?.Parcele?.Parcela?.Tereti;
    }

    return (
        <TabContainer>
            <KatakomModal isOpen={previewPDF} setIsOpen={setPreviewPDF}>
                <PreviewPDFIFrame>
                    {pdfSrc && (
                        <iframe
                            src={pdfSrc}
                            title="PDF Preview"
                            style={{ width: "100%", height: "80vh", border: "1px solid #ccc" }}
                        ></iframe>
                    )}
                    <Row style={{ justifyContent: "center" }}>
                        <KatakomButton
                            label={"Затвори"}
                            onClick={() => {
                                setPreviewPDF(false);
                            }}
                        ></KatakomButton>
                    </Row>
                </PreviewPDFIFrame>
            </KatakomModal>

            <KatastarElement>
                <KatastarElementHeader>непокретност</KatastarElementHeader>
                <KatastarElementBody>
                    <KatastarElementBodyRow>
                        <Key>Датум ажурности података: </Key>
                        <Value>{moment(data?.DatumAzurnosti).format('DD-MM-YYYY HH:mm') ?? ""}</Value>
                    </KatastarElementBodyRow>
                    <KatastarElementBodyRow>
                        <Key>Општина: </Key>
                        <Value>{data?.Opstina ?? ""}</Value>
                    </KatastarElementBodyRow>
                    <KatastarElementBodyRow>
                        <Key>Катастарска општина: </Key>
                        <Value>{data?.KatastarskaOpstina ?? ""}</Value>
                    </KatastarElementBodyRow>
                </KatastarElementBody>
            </KatastarElement>
            <KatastarElement>
                <KatastarElementHeader>
                    <div style={{ width: "50%" }}>парцела</div>
                    <div
                        style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        onClick={() => {
                            redirectToRGZ();
                        }}
                    >
                        прикажи на мапи <LocationTransparentIcon />
                    </div>
                    <div
                        style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        onClick={() => {
                            redirectToDRR();
                        }}
                    >
                        прикажи на ДРР <LocationTransparentIcon />
                    </div>
                </KatastarElementHeader>
                <KatastarElementBody>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "68%" }}>
                            <KatastarElementBodyRow>
                                <Key>Број парцеле: </Key>
                                <Value>{data?.Parcele?.Parcela?.BrParc ?? data?.Parcele?.Parcela?.[0]?.BrParc}</Value>
                            </KatastarElementBodyRow>
                            <KatastarElementBodyRow>
                                <Key>Површина: </Key>
                                <Value>{data?.Parcele?.Parcela?.Povrsina ?? data?.Parcele?.Parcela?.[0]?.Povrsina}</Value>
                            </KatastarElementBodyRow>
                            <KatastarElementBodyRow>
                                <Key>Врста земљишта: </Key>
                                <Value>{data?.Parcele?.Parcela?.VrstaZemljista ?? data?.Parcele?.Parcela?.[0]?.VrstaZemljista}</Value>
                            </KatastarElementBodyRow>
                            <KatastarElementBodyRow>
                                <Key>Улица: </Key>
                                <Value>{data?.Parcele?.Parcela?.Ulica ?? data?.Parcele?.Parcela?.[0]?.Ulica}</Value>
                            </KatastarElementBodyRow>

                            {!isMobile ? (
                                <KatastarElementBodyRow style={{ fontWeight: 700 }}>
                                    <Key style={{ minWidth: "180px", maxWidth: "250px" }}>Број нелегалних објеката на парцели: </Key>
                                    <Value>{nelegalni.total ?? "0"}</Value>
                                    <SearchIcon
                                        style={{ marginLeft: "10px" }}
                                        onClick={() => {
                                            if (nelegalni.total > 0) {
                                                navigate(
                                                    `/pretragaNelegalnihObjekata?koid=${koid}&brOpstine=${brOpstine}&brojParcele=${brojParcele}${
                                                        podbrojParcele && podbrojParcele != 0 ? `/${podbrojParcele}` : ""
                                                    }`
                                                );
                                            }
                                        }}
                                    />
                                    <LocationIcon
                                        style={{ marginLeft: "10px" }}
                                        onClick={() => {
                                            if (nelegalni.total > 0) {
                                                redirectToNelegalni();
                                            }
                                        }}
                                    />
                                </KatastarElementBodyRow>
                            ) : (
                                <KatastarElementBodyRow style={{ flexDirection: "column", fontWeight: 700 }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <Key style={{ minWidth: "180px", maxWidth: "250px" }}>Број нелегалних објеката на парцели: </Key>
                                        <Value>{nelegalni.total ?? "0"}</Value>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "left", width: "100%", marginTop: "10px" }}>
                                        <SearchIcon
                                            style={{ marginRight: "20px" }}
                                            onClick={() => {
                                                if (nelegalni.total > 0) {
                                                    navigate(
                                                        `/pretragaNelegalnihObjekata?koid=${koid}&brOpstine=${brOpstine}&brojParcele=${brojParcele}${
                                                            podbrojParcele && podbrojParcele != 0 ? `/${podbrojParcele}` : ""
                                                        }`
                                                    );
                                                }
                                            }}
                                        />
                                        <LocationIcon
                                            onClick={() => {
                                                if (nelegalni.total > 0) {
                                                    redirectToNelegalni();
                                                }
                                            }}
                                        />
                                    </div>
                                </KatastarElementBodyRow>
                            )}
                        </div>
                    </div>
                    <KatastarElementBodyRow style={{ padding: "20px" }}>
                        <KatakomButton
                            label={"Лица"}
                            onClick={() => {
                                setLicaModalOpen(true);
                            }}
                            size={isMobile ? "s" : "m"}
                            style={{ marginRight: isMobile ? "20px" : "50px" }}
                        />
                        <KatakomButton
                            label={"Терети"}
                            onClick={() => {
                                setTeretiModalOpen(true);
                            }}
                            size={isMobile ? "s" : "m"}
                            theme="secondary"
                        />
                    </KatastarElementBodyRow>
                    <KatastarElementBodyRow style={{ padding: "20px" }}>
                        <Key style={{ minWidth: 600, maxWidth: 600 }}>Изаберите део парцеле:</Key>
                    </KatastarElementBodyRow>

                    <div style={{ margin: "15px" }}>
                        <KatakomTable
                            columns={[
                                { value: "BrParc", label: "Број парцеле" },
                                { value: "PodBrParc", label: "Подброј парцеле" },
                                { value: "BrDelaParc", label: "Број дела парцеле" },
                                { value: "Povrsina", label: "Површина (м2)" },
                                { value: "Ulica", label: "Улица/Потес" },
                                { value: "VrstaZemljista", label: "Врста земљишта" },
                                { value: "Kultura", label: "Начин коришћења земљишта" },
                                {
                                    value: "katastarDownload",
                                    label: "ПДФ",
                                    func: (item, download) => {
                                        if (!download) {
                                            previewTablePdf(item);
                                        } else {
                                            downloadTablePdf(item);
                                        }
                                        dispatch(setDeoParcele(item));
                                    },
                                },
                            ]}
                            data={
                                !Array.isArray(data?.Parcele?.Parcela)
                                    ? Array.isArray(data?.Parcele?.Parcela?.DeloviParcele?.DeoParcele)
                                        ? data?.Parcele?.Parcela?.DeloviParcele?.DeoParcele.map((item: any) => {
                                              return {
                                                  ...item,
                                                  BrParc: data?.Parcele?.Parcela?.BrParc,
                                                  Ulica: data?.Parcele?.Parcela?.Ulica,
                                                  PodBrParc: data?.Parcele?.Parcela?.PodBrParc,
                                              };
                                          })
                                        : [
                                              {
                                                  ...data?.Parcele?.Parcela?.DeloviParcele?.DeoParcele,
                                                  BrParc: data?.Parcele?.Parcela?.BrParc,
                                                  Ulica: data?.Parcele?.Parcela?.Ulica,
                                                  PodBrParc: data?.Parcele?.Parcela?.PodBrParc,
                                              },
                                          ]
                                    : //FIXME: THIS IS THE STUPIEDES EDGA CASE THAT I NEEDED TO HANDLE AND DESTROY CODE SINCE FOR SOME REASON THERE CAN EXIST PARCELA WITH MULTIPLE PARTS WHICH JUST DOUBLES ALL THE DATA, AND WE NEED TO SHOW IT DOUBLED. WHEREVER U SEE THIS THAT'S THE REASOn
                                      data?.Parcele?.Parcela?.flatMap((parcela: any) => {
                                          return Array.isArray(parcela.DeloviParcele?.DeoParcele)
                                              ? parcela.DeloviParcele?.DeoParcele.map((item: any) => {
                                                    return {
                                                        ...item,
                                                        BrParc: parcela.BrParc,
                                                        Ulica: parcela.Ulica,
                                                        PodBrParc: parcela.PodBrParc,
                                                    };
                                                })
                                              : [
                                                    {
                                                        ...parcela.DeloviParcele?.DeoParcele,
                                                        BrParc: parcela.BrParc,
                                                        Ulica: parcela.Ulica,
                                                        PodBrParc: parcela.PodBrParc,
                                                    },
                                                ];
                                      })
                            }
                            enableClick={true}
                            onRowClick={(item: any) => {
                                setActiveTab(1);
                                dispatch(setDeoParcele(item));
                            }}
                        />
                    </div>
                </KatastarElementBody>
            </KatastarElement>
            <KatakomModalNg isOpen={licaModalOpen} setIsOpen={setLicaModalOpen}>
                <LicaModalNew
                    data={data?.Parcele?.Parcela?.ImaociPrava ?? data?.Parcele?.Parcela?.[0]?.ImaociPrava}
                    closeModal={() => {
                        setLicaModalOpen(false);
                    }}
                />
            </KatakomModalNg>
            <KatakomModalNg isOpen={teretiModalOpen} setIsOpen={setTeretiModalOpen}>
                <TeretiModalNew
                    data={tereti}
                    closeModal={() => {
                        setTeretiModalOpen(false);
                    }}
                />
            </KatakomModalNg>
        </TabContainer>
    );
};
