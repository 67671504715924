import React from "react";
import styled from "styled-components";
import { FaInfoCircle } from "react-icons/fa";
import { themeColors } from "../../constants/constants";

const InfoBoxContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: ${themeColors.infoBoxContainerBackgroundColor};
    color: ${themeColors.infoBoxContainerColor};
    padding: 10px;
    margin-top: 10px;
    padding-left: 25px;
`;

const InfoIcon = styled(FaInfoCircle)`
    font-size: 20px;
    margin-right: 10px;
`;

const InfoText = styled.span`
    font-size: 14px;
`;

interface Props {
    text: string;
}

export const KatakomInfoBox: React.FC<Props> = ({ text }) => {
    return (
        <InfoBoxContainer>
            <InfoIcon />
            <InfoText>{text}</InfoText>
        </InfoBoxContainer>
    );
};
