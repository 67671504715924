import React from "react";
import {
    KatastarElement,
    KatastarElementBody,
    KatastarElementBodyRow,
    KatastarElementHeader,
    Key,
    LocationTransparentIcon,
    PDFIcon,
    PreviewPDFIFrame,
    TabContainer,
    Value,
} from "./style";
import KatakomTable from "../../../shared/KatakomTable";
import { AppDispatch, RootState, useAppDispatch } from "../../../../store";
import { setObjekat } from "../../../../store/KatastarReducer";
import { useSelector } from "react-redux";
import { pdf } from "@react-pdf/renderer";
import GenerateDeoParcelePDF from "../../../pdfs/DeoParcelePDF";
import GenerateObjekatPDF from "../../../pdfs/ObjekatPDF";
import moment from "moment";

import KatakomModal from "../../../shared/KatakomModal";
import { Row } from "../../style";
import KatakomButton from "../../../shared/KatakomButton";
import useMobileCheck from "../../../../hooks/useMobileChecker";

interface DeoParceleProps {
    data: any;
    redirectToRGZ: () => void;
    redirectToDRR: () => void;
    setActiveTab: (number: number) => void;
}

export const DeoParcele: React.FC<DeoParceleProps> = ({ data, redirectToRGZ, setActiveTab, redirectToDRR }) => {
    const deoParcele = useSelector((state: RootState) => state.katastar.deoParcele);
    const parcela = useSelector((state: RootState) => state.katastar.parcela);
    const [pdfSrc, setPdfSrc] = React.useState<string | null>(null);
    const [previewPDF, setPreviewPDF] = React.useState<boolean>(false);
    const isMobile = useMobileCheck();

    const dispatch: AppDispatch = useAppDispatch();

    const downloadPdf = async () => {
        const blob = await pdf(<GenerateDeoParcelePDF pdfData={parcela} deoParceleData={deoParcele} />).toBlob();
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        const date = moment().format("YYYYMMDD");
        a.download = `${date}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        // @ts-ignore
        if (window?.ReactNativeWebView) {
            const base64Data = await new Response(blob).arrayBuffer();
            const base64String = btoa(new Uint8Array(base64Data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
            const downloadInfo = {
                message: "DownloadFile",
                base64Data: base64String,
                fileName: `${date}.pdf`,
            };

            // @ts-ignore
            window.ReactNativeWebView.postMessage(JSON.stringify(downloadInfo));
        }
    };
    const previewPdf = async () => {
        const blob = await pdf(<GenerateDeoParcelePDF pdfData={parcela} deoParceleData={deoParcele} />).toBlob();
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        setPreviewPDF(true);
        setPdfSrc(url);
    };

    const downloadTablePdf = async (item: any) => {
        const blob = await pdf(<GenerateObjekatPDF pdfData={parcela} deoParceleData={deoParcele} objekat={item} />).toBlob();
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        const date = moment().format("YYYYMMDD");
        a.download = `${date}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        // @ts-ignore
        if (window?.ReactNativeWebView) {
            const base64Data = await new Response(blob).arrayBuffer();
            const base64String = btoa(new Uint8Array(base64Data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
            const downloadInfo = {
                message: "DownloadFile",
                base64Data: base64String,
                fileName: `${date}.pdf`,
            };

            // @ts-ignore
            window.ReactNativeWebView.postMessage(JSON.stringify(downloadInfo));
        }
    };

    const previewTablePdf = async (item: any) => {
        const blob = await pdf(<GenerateObjekatPDF pdfData={parcela} deoParceleData={deoParcele} objekat={item} />).toBlob();
        const url = URL.createObjectURL(blob);

        setPreviewPDF(true);
        setPdfSrc(url);
    };

    if (Object.keys(deoParcele).length === 0) return null;
    return (
        <TabContainer>
            <KatakomModal isOpen={previewPDF} setIsOpen={setPreviewPDF}>
                <PreviewPDFIFrame>
                    {pdfSrc && (
                        <iframe
                            src={pdfSrc}
                            title="PDF Preview"
                            style={{ width: "100%", height: "80vh", border: "1px solid #ccc" }}
                        ></iframe>
                    )}
                    <Row style={{ justifyContent: "center" }}>
                        <KatakomButton
                            label={"Затвори"}
                            onClick={() => {
                                setPreviewPDF(false);
                            }}
                        ></KatakomButton>
                    </Row>
                </PreviewPDFIFrame>
            </KatakomModal>

            <KatastarElement>
                <KatastarElementHeader>
                    <div style={{ width: "66%" }}>део парцеле {deoParcele.BrDelaParc}</div>
                    <div
                        style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        onClick={() => {
                            redirectToRGZ();
                        }}
                    >
                        прикажи на мапи <LocationTransparentIcon />
                    </div>
                    <div
                        style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        onClick={() => {
                            redirectToDRR();
                        }}
                    >
                        прикажи на ДРР <LocationTransparentIcon />
                    </div>
                </KatastarElementHeader>
                <KatastarElementBody>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "68%" }}>
                            <KatastarElementBodyRow>
                                <Key>Врста земљишта: </Key>
                                <Value>{deoParcele?.VrstaZemljista}</Value>
                            </KatastarElementBodyRow>
                            <KatastarElementBodyRow>
                                <Key>Површина: </Key>
                                <Value>{deoParcele?.Povrsina}</Value>
                            </KatastarElementBodyRow>
                            <KatastarElementBodyRow>
                                <Key>Култура: </Key>
                                <Value>{deoParcele?.Kultura}</Value>
                            </KatastarElementBodyRow>
                            <KatastarElementBodyRow>
                                <Key>Улица: </Key>
                                <Value>{deoParcele?.Ulica}</Value>
                            </KatastarElementBodyRow>
                        </div>
                        <div style={{ display: "flex", width: "32%", justifyContent: "center" }} onClick={() => {}}>
                            {isMobile ? null : (
                                <div style={{ display: "flex", marginTop: 10 }}>
                                    <PDFIcon
                                        style={{ zIndex: 0 }}
                                        onClick={() => {
                                            if (!isMobile) {
                                                previewPdf();
                                            } else {
                                                downloadPdf();
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", padding: "10px" }} onClick={() => {}}>
                        {isMobile ? (
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <PDFIcon
                                    style={{ zIndex: 0 }}
                                    onClick={() => {
                                        downloadPdf();
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>

                    {deoParcele?.Objekti?.Objekat && Object.keys(deoParcele?.Objekti?.Objekat).length !== 0 ? (
                        <>
                            <KatastarElementBodyRow style={{ padding: "20px" }}>
                                <Key style={{ minWidth: 600, maxWidth: 600 }}>Изаберите објекат:</Key>
                            </KatastarElementBodyRow>
                            <div style={{ margin: "15px" }}>
                                <KatakomTable
                                    columns={[
                                        { value: "Ulica", label: "Улица/Потес" },
                                        { value: "KucniBroj", label: "Кућни број" },
                                        { value: "KucniPodbroj", label: "Кућни подброј" },
                                        { value: "Povrsina", label: "Површина (м2)" },
                                        { value: "NacinKoriscenja", label: "Начин коришћења објекта" },
                                        { value: "PravniStatus", label: "Статус објекта" },
                                        {
                                            value: "katastarDownload",
                                            label: "ПДФ",
                                            func: (item, download) => {
                                                if (!download) {
                                                    previewTablePdf(item);
                                                } else {
                                                    downloadTablePdf(item);
                                                }
                                                dispatch(setObjekat(item));
                                            },
                                        },
                                    ]}
                                    data={[deoParcele?.Objekti?.Objekat]}
                                    enableClick={true}
                                    onRowClick={(item: any) => {
                                        setActiveTab(2);
                                        dispatch(setObjekat(item));
                                    }}
                                />
                            </div>
                        </>
                    ) : null}
                </KatastarElementBody>
            </KatastarElement>
        </TabContainer>
    );
};
