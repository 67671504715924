import { VrstaNepokretnosti } from "../enums";
import { AssetsAdvokati } from "../store/CaseAdvokatiReducer";
import { Assets, VrstaLica } from "../store/CaseReducer";
import { AssetsTip2 } from "../store/CaseTip2Reducer";

export const defaultAplicant = {
    vrstaLica: VrstaLica.FIZICKO_DOMACE,
    svojstvoLica: "Поверилац",
    ime: "",
    prezime: "",
    imeRoditelja: "",
    jmbg: "",
    brojLicneKarte: "",
    brojPasosa: "",
    ssn: "",
    email: "",
    pib: "",
    maticniBroj: "",
    drzavaNaziv: "",
    flagKIM: false,
    opstinaSifra: "",
    opstinaNaziv: "",
    mestoSifra: "",
    mestoNaziv: "",
    ulicaSifra: "",
    ulicaNaziv: "",
    ulicaRucno: "",
    kucniBroj: "",
    postanskiBroj: "",
    flagUlicaRucno: true,
    brojUpisaURegistarDrzaveSedista: "",
    nazivRegistra: "",
    flagPrebivalisteInostranstvo: false,
};

export const defaultAssets: Assets = {
    tip: VrstaNepokretnosti.PARCELA,
    opstinaNaziv: "",
    koNaziv: "",
    opstinaId: "",
    koId: "",
    brojParcele: "",
    podBrojParcele: "",
    brojObjekta: "",
    evidencijskiBroj: "",
};

export const defaultAplicantTip2 = {
    vrstaLica: VrstaLica.FIZICKO_DOMACE,
    ime: "",
    prezime: "",
    imeRoditelja: "",
    jmbg: "",
    brojLicneKarte: "",
    brojPasosa: "",
    ssn: "",
    email: "",
    pib: "",
    maticniBroj: "",
    drzavaNaziv: "",
    flagKIM: false,
    opstinaSifra: "",
    opstinaNaziv: "",
    mestoSifra: "",
    mestoNaziv: "",
    ulicaSifra: "",
    ulicaNaziv: "",
    ulicaRucno: "",
    kucniBroj: "",
    postanskiBroj: "",
    flagUlicaRucno: true,
    brojUpisaURegistarDrzaveSedista: "",
    nazivRegistra: "",
    flagPrebivalisteInostranstvo: false,
};

export const defaultAssetsTip2: AssetsTip2 = {
    opstinaNaziv: "",
    koNaziv: "",
    opstinaId: "",
    koId: "",
    brojParcele: "",
    podBrojParcele: "",
    brojDelaParcele: "",
    udeo: "",
    povrsina: "",
};

export const defaultAplicantAdvokati = {
    vrstaLica: VrstaLica.FIZICKO_DOMACE,
    ime: "",
    prezime: "",
    imeRoditelja: "",
    jmbg: "",
    brojLicneKarte: "",
    brojPasosa: "",
    ssn: "",
    email: "",
    pib: "",
    maticniBroj: "",
    drzavaNaziv: "",
    flagKIM: false,
    opstinaSifra: "",
    opstinaNaziv: "",
    mestoSifra: "",
    mestoNaziv: "",
    ulicaSifra: "",
    ulicaNaziv: "",
    ulicaRucno: "",
    kucniBroj: "",
    postanskiBroj: "",
    flagUlicaRucno: true,
    brojUpisaURegistarDrzaveSedista: "",
    nazivRegistra: "",
    flagPrebivalisteInostranstvo: false,
};

export const defaultAssetsAdvokati: AssetsAdvokati = {
    tip: VrstaNepokretnosti.PARCELA,
    opstinaNaziv: "",
    koNaziv: "",
    opstinaId: "",
    koId: "",
    brojParcele: "",
    podBrojParcele: "",
    brojObjekta: "",
    evidencijskiBroj: "",
    udeo: "",
    brojVoda: "",
    opisVoda: "",
    vlasnik: "",
    list: "",
};
