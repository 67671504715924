import { ChangeEvent } from "react";
import KatakomInput from "../../../../../shared/KatakomInput";
import { Row } from "../../../../style";
import { AplicantTip2 } from "../../../../../../store/CaseTip2Reducer";

export const PravnoStranac = ({ formData, setFormData }: { formData: AplicantTip2; setFormData: (aplicant: AplicantTip2) => void }) => {
    return (
        <>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.imePravnogLica || ""}
                    name="imePravnogLica"
                    label="Име правног лица*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, imePravnogLica: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.brojUpisaURegistarDrzaveSedista || ""}
                    name="brojUpisaURegistarDrzaveSedista"
                    label="Број уписа у регистар државе седишта*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFormData({ ...formData, brojUpisaURegistarDrzaveSedista: e.target.value })
                    }
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.nazivRegistra || ""}
                    name="nazivRegistra"
                    label="Назив регистра*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, nazivRegistra: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.email || ""}
                    name="email"
                    label="Е-пошта"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, email: e.target.value })}
                />
            </Row>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.drzavaNaziv || ""}
                    name="drzavaNaziv"
                    label="Држава*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, drzavaNaziv: e.target.value })}
                    style={{ margin: "0 30px 0 0" }}
                />
                <KatakomInput
                    type="text"
                    value={formData.mestoNaziv || ""}
                    name="mestoNaziv"
                    label="Место*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, mestoNaziv: e.target.value })}
                    style={{ margin: "0 30px 0 0" }}
                />
                <KatakomInput
                    type="text"
                    value={formData.ulicaRucno || ""}
                    name="ulicaRucno"
                    label="Улица*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, ulicaRucno: e.target.value })}
                    style={{ margin: "0 30px 0 0" }}
                />
                <KatakomInput
                    type="text"
                    value={formData.kucniBroj || ""}
                    name="kucniBroj"
                    label="Кућни број*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, kucniBroj: e.target.value })}
                />
            </Row>
        </>
    );
};
