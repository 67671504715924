import React, { useState } from "react";
import styled from "styled-components";
import { themeColors, themeIcon } from "../../constants/constants";
import KatakomModal from "./KatakomModal";
import { InfoMessageModal } from "../customComponent/InfoMessageModal";
import { setCaseStatusPreview } from "../../store/CaseStatusReducer";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";

interface TableColumn {
    label: string;
    value: string;
    func?: (item: any, download?: any) => void;
}

interface TableProps {
    columns: TableColumn[];
    data: any[];
    onEdit?: (item: any) => void;
    onDelete?: (item: any) => void;
    enableClick?: boolean;
    onRowClick?: (item: any) => void;
    withoutHeader?: boolean;
    maxHeight?: string;
}

const TableContainer = styled.div<{ maxHeight?: string }>`
    border-radius: 4px;
    overflow-y: auto;
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : "")};
    width: 99%;
    margin-left: 5px;

    ::-webkit-scrollbar {
        background: ${themeColors.tableContainerScrollBackground};
        width: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border: 2px solid ${themeColors.tableContainerScrollThumbBackground};
        border-radius: 4px;
        background-color: ${themeColors.tableContainerScrollThumbBackground};
    }
`;

const Table = styled.table<{ enableClick?: boolean; withoutHeader?: boolean }>`
    border-collapse: collapse;
    border: ${(props) => (!props.withoutHeader ? `1px solid ${themeColors.tableBorder}` : "none")};
    width: 100%;
    font-size: 14px;

    @media screen and (max-width: 900px) {
        font-size: 12px;
    }

    th,
    td {
        padding: 8px 16px;
        text-align: center;
        min-width: 60px;
    }

    th {
        background-color: ${themeColors.tableThBackroundColor};
        color: ${themeColors.tableThColor};
        font-size: 14px;
        font-weight: 500;

        @media screen and (max-width: 900px) {
            font-size: 12px;
        }
    }

    tr:nth-child(even),
    tr:nth-child(odd) {
        background-color: ${themeColors.tableTrOddBackgroundColor};
        cursor: ${(props) => (props.enableClick ? "pointer" : "default")};

        &:hover {
            background-color: ${(props) => (props.enableClick ? `${themeColors.tableTrOddBackgroundColorHover}` : "none")};
        }
    }

    tr:nth-child(odd) {
        background-color: ${themeColors.tableTrOdd2BackgroundColor};
    }
`;

const ButtonCell = styled.td`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${themeColors.katakomTableButtonCellColor};
    height: 35px;
`;

const DropdownContainer = styled.div`
    position: absolute;
    background-color: ${themeColors.dropdownContainerBackgroundColor};
    border-radius: 4px;
    z-index: 1;
    box-shadow: 5px 5px 10px 0px #00000040;
    margin-top: -15px;
    width: 250px;
    overflow-y: visible;
`;

const DropdownItem = styled.div`
    display: flex;
    justify-content: flex-start;
    text-align: left;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    &:hover {
        background-color: ${themeColors.dropdownItemBackgroundColorHover};
    }
`;

const Icon = styled.img`
    margin-right: 10px;
    width: 17px;
`;

const KatakomTable: React.FC<TableProps> = ({ columns, data, onEdit, onDelete, enableClick, onRowClick, withoutHeader, maxHeight }) => {
    const [openModal, setOpenModal] = useState(false);
    const [openDropdownItem, setOpenDropdownItem] = useState<string | null>(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const user = useSelector((state: RootState) => state.user);

    const dispatch = useAppDispatch();

    const handleEdit = (item: any) => {
        if (onEdit) {
            onEdit(item);
        }
    };

    const handleDelete = (item: any) => {
        if (onDelete) {
            onDelete(item);
        }
    };

    const handleRowClick = (item: any) => {
        if (enableClick && onRowClick) {
            onRowClick(item);
        }
    };

    const toggleDropdown = (itemId: string) => {
        setOpenDropdownItem(openDropdownItem === itemId ? null : itemId);
    };

    const renderIcon = (column: string, item?: any, index?: any) => {
        switch (column) {
            case "edit":
                return <img src={`${themeIcon}/edit.png`} alt="Edit button" />;
            case "delete":
                return <img src={`${themeIcon}/delete.png`} alt="Delete button" />;
            case "isSigned":
                return item.isSigned ? (
                    <img src={`${themeIcon}/checkMark.png`} alt="Signed" />
                ) : (
                    <img src={`${themeIcon}/crossMark.png`} alt="Not signed" />
                );
            case "pdf":
                return <img src={`${themeIcon}/pdf.png`} alt="Pdf icon" />;
            case "download":
                return <img src={`${themeIcon}/downloadIcon.png`} alt="Download button" />;
            case "katastarDownload":
                return (
                    <ButtonCell
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown(item.ID ? item.ID : `${item.BrParc}_${item.PodBrParc}_${item.BrDelaParc}`);
                        }}
                    >
                        <img src={`${themeIcon}/threeDots.png`} alt="Katastar download button" />
                    </ButtonCell>
                );
            case "userControl":
                return (
                    <ButtonCell
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown(item.email);
                        }}
                    >
                        <img src={`${themeIcon}/threeDots.png`} alt="User control button" />
                    </ButtonCell>
                );
            case "lokacija":
                return <img src={`${themeIcon}/location.png`} alt="Lokacija" />;
            case "statusPreview":
                return <img src={`${themeIcon}/previewIcon.png`} alt="Preview" />;
            case "addFile":
                return <img style={{ width: "25px" }} src={`${themeIcon}/plus.svg`} alt="Add File" />;
            default:
                return null;
        }
    };

    const renderCellContent = (col: TableColumn, item: any, index: number) => {
        if (col.value === "katastarDownload") {
            return (
                <React.Fragment>
                    {renderIcon(col.value, item, index)}
                    {renderDropdown(col, item, index)}
                </React.Fragment>
            );
        } else if (col.value === "userControl") {
            return (
                <React.Fragment>
                    {renderIcon(col.value, item, index)}
                    {renderUserEditDropdown(col, item, index)}
                </React.Fragment>
            );
        } else {
            switch (col.value) {
                case "edit":
                    return <ButtonCell onClick={() => handleEdit(item)}>{renderIcon("edit")}</ButtonCell>;
                case "delete":
                    return <ButtonCell onClick={() => handleDelete(item)}>{renderIcon("delete")}</ButtonCell>;
                case "download":
                case "statusPreview":
                    return (
                        <ButtonCell
                            onClick={() => {
                                if (col.func) {
                                    if (col.value === "statusPreview") {
                                        dispatch(setCaseStatusPreview(item));
                                    }
                                    col.func(item);
                                }
                            }}
                        >
                            {renderIcon(col.value)}
                        </ButtonCell>
                    );
                case "lokacija":
                    return (
                        <ButtonCell>
                            <a
                                href={`https://portal.rgz.gov.rs/rgz-portal/map?cadmun=${item.maticniBrojKatastarskeOpstine}&parcelNumber=${item.brojParcele.split(",")[0]
                                    }&objectId=${item.objekatId}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {renderIcon(col.value, item)}
                            </a>
                        </ButtonCell>
                    );
                case "addFile":
                    return (
                        <ButtonCell
                            onClick={() => {
                                if (col.func) {
                                    col.func(item);
                                }
                            }}
                        >
                            {renderIcon(col.value)}
                        </ButtonCell>
                    );
                case "is_active":
                    return item.is_active ? <>Активан</> : <>Неактиван</>;
                default:
                    const subValues = col.value.split("/");
                    for (const subValue of subValues) {
                        if (item[subValue]) {
                            return item[subValue];
                        }
                    }
                    return item[col.value];
            }
        }
    };

    const renderUserEditDropdown = (col: any, item: any, index: number) => {
        if (openDropdownItem === item.email) {
            return (
                <DropdownContainer style={{ right: `0px` }}>
                    {user.role === 'superadmin' ? <><DropdownItem
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenDropdownItem(null);
                            col.func(item, "edit");
                        }}
                    >
                        <Icon src={`${themeIcon}/edit.svg`} alt="Edit user" />
                        Измени податке
                    </DropdownItem>

                        {item.is_active ? (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenDropdownItem(null);
                                    col.func(item, "activate");
                                }}
                            >
                                <Icon src={`${themeIcon}/pdf.png`} alt="Download" />
                                Деактивирај корисника
                            </DropdownItem>
                        ) : (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenDropdownItem(null);
                                    col.func(item, "activate");
                                }}
                            >
                                <Icon src={`${themeIcon}/pdf.png`} alt="Download" />
                                Активирај корисника
                            </DropdownItem>
                        )}
                    </> : null}

                    <DropdownItem
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenDropdownItem(null);
                            col.func(item, "statistika");
                        }}
                    >
                        <Icon src={`${themeIcon}/pdfs.svg`} alt="Search count" />
                        Статистика претрага
                    </DropdownItem>

                </DropdownContainer>
            );
        }
        return null;
    };

    const renderDropdown = (col: any, item: any, index: number) => {
        const itemId = item.ID ? item.ID : `${item.BrParc}_${item.PodBrParc}_${item.BrDelaParc}`;

        if (openDropdownItem === itemId) {
            return (
                <DropdownContainer style={{ right: `0px` }}>
                    <DropdownItem
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenDropdownItem(null);
                            col.func(item, false);
                        }}
                    >
                        <Icon src={`${themeIcon}/viewFile.svg`} alt="Preview" />
                        Погледај документ
                    </DropdownItem>
                    <DropdownItem
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenDropdownItem(null);
                            col.func(item, true);
                        }}
                    >
                        <Icon src={`${themeIcon}/pdf.png`} alt="Download" />
                        Преузми документ
                    </DropdownItem>
                    {/* <DropdownItem
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenDropdownItem(null);
                            handleDownloadAllPdf(col.func);
                        }}
                    >
                        <Icon src={`${themeIcon}/pdfs.svg`} alt="Download" />
                        Преузми сва документа
                    </DropdownItem> */}
                </DropdownContainer>
            );
        }
        return null;
    };
    const handleDownloadAllPdf = (func: any) => {
        data.forEach((item: any, index: number) => {
            setTimeout(() => {
                func(item, true);
            }, index * 500);
        });
    };
    //     try {
    //         const filesToDownload: any[] = type === "sent" ? sentFiles : type === "received" ? receivedFiles : [];

    //         const urls: { url: string; fileName: string }[] = [];

    //         for (const file of filesToDownload) {
    //             if (file.aws_key) {
    //                 const res = await api.get(`api/v1/get-files`, {
    //                     awsKey: file.aws_key,
    //                 });

    //                 if (res?.status === 200) {
    //                     urls.push({ url: res.data.url, fileName: file.imePredmeta });
    //                 }
    //             } else {
    //                 const res = await api.get(`api/v1/get-files`, {
    //                     idDms: file.id_dms,
    //                     idDokument: file.dokument_id,
    //                 });

    //                 if (res?.status === 200 && res.data.length > 0) {
    //                     for (const receivedFile of res.data) {
    //                         downloadReceivedFile({ ...receivedFile, imePredmeta: receivedFile.imePredmeta });
    //                     }
    //                 }
    //             }
    //         }

    //         const fileResponses = await Promise.all(urls.map((file) => fetch(file.url)));
    //         const fileBlobs = await Promise.all(fileResponses.map((response) => response.blob()));

    //         fileBlobs.forEach((blob, index) => {
    //             const a = document.createElement("a");
    //             a.href = window.URL.createObjectURL(blob);
    //             a.download = `${urls[index].fileName}.pdf`;
    //             document.body.appendChild(a);
    //             a.click();
    //             // Cleanup
    //             window.URL.revokeObjectURL(a.href);
    //             document.body.removeChild(a);
    //         });
    //     } catch (error) {
    //         console.error("Error downloading files:", error);
    //     }
    // };

    return (
        //@ts-ignore
        <TableContainer maxHeight={maxHeight} onScroll={(e) => setScrollLeft(e.target.scrollLeft)}>
            <KatakomModal isOpen={openModal} setIsOpen={setOpenModal}>
                <InfoMessageModal
                    closeModal={() => {
                        setOpenModal(false);
                    }}
                    message="За тражени предмет не постоје документа за преузимање"
                />
            </KatakomModal>
            <Table enableClick={enableClick} withoutHeader={withoutHeader}>
                {withoutHeader ? null : (
                    <thead style={{ position: "sticky", top: 0 }}>
                        <tr>
                            {columns.map((col, index) => (
                                <th key={index}>{col.label}</th>
                            ))}
                        </tr>
                    </thead>
                )}

                <tbody>
                    {data.map((item, index) => (
                        <tr
                            key={index}
                            onClick={(e) => {
                                //TODO: Terrible way for handling, it was a quick and dirty fix just to make it work needs refactoring
                                //@ts-ignore
                                if (e.target.alt !== "Katastar download button") {
                                    handleRowClick(item);
                                } else {
                                    toggleDropdown(item.ID ? item.ID : `${item.BrParc}_${item.PodBrParc}_${item.BrDelaParc}`);
                                }
                                e.stopPropagation();
                            }}
                        >
                            {columns.map((col, index) => (
                                <td key={index}>{renderCellContent(col, item, index)}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </TableContainer>
    );
};

export default KatakomTable;
