export const getThemeColors = (theme: string) => {
    switch (theme) {
        case "theme-default":
            return {
                primary: "#F7F7F7",
                secondary: "#393E46",
                tertiary: "#929AAB",
                quaternary: "#D4D4CE",
                quinary: "#EEEEEE",
                quaternaryFaded: "rgba(212, 212, 206, 0.5)",
                layoutUserNameColor: "#D4D4CE",
                katakomInputBackground: "#F7F7F7",
                siteUnderMaintenance: "#929AAB",
                advokatCustomSelectSearchInputColor: "#393E46",
                advokatCustomSelectSearchInputBackgroundColor: "#EEEEEE",
                advokatCustomSelectSelectDropdownBackgroundColor: "#EEEEEE",
                advokatCustomSelectScrollColorBorder: "#EEEEEE",
                advokatCustomSelectScrollColor: "#929AAB",
                advokatCustomSelectOptionContainerColor: "#393E46",
                advokatCustomSelectLabelColor: "#393E46",
                chatbotContainerBackgroundColor: "#F7F7F7",
                chatbotTitleBackgroundColor: "#393E46",
                chatbotTitleColor: "#F7F7F7",
                chatbotButtonBackgroundColor: "#D4D4CE",
                chatbotButtonColor: "#393E46",
                chatbotTextFieldBorderColor: "#D4D4CE",
                chatbotTextFieldEmptyColor: "#929AAB",
                chatbotLabelColor: "#393E46",
                chatBotParagraf: "#929AAB",
                errorModalBackgroundColor: "#F7F7F7",
                infoModalBackgroundColor: "#F7F7F7",
                successModalBackgroundColor: "#F7F7F7",
                waitingModalBackgroundColor: "#F7F7F7",
                settingsWelcomCardBackgroundColor: "#F7F7F7",
                settingsWelcomUserNameColor: "#D4D4CE",
                welcomUserCardBackgroundColor: "#F7F7F7",
                welcomUserNameColor: "D4D4CE",
                layoutWrapperBackgroundColor: "#D4D4CE",
                layoutCardBackgroundColor: "#F7F7F7",
                layoutCardTitleColor: "#393E46",
                katakomPaginationLiABorder: "#393E46",
                katakomPaginationLiABackgroundColor: "#393E46",
                katakomPaginationLiColor: "#F7F7F7",
                katakomPaginationLiDisabledColor: "#393E46",
                checkBoxBeforeBackgroundColor: "#393E46",
                checkBoxFocuseBackgroundColor: "#D4D4CE",
                dopunaModalLabel: "#929AAB",
                dopunaModalImageHover: "#EEEEEE",
                dopunaModalDeleteImageBackgroundColor: "#929AAB",
                statusPreviewModalSpan: "#393E46",
                statusPreviewModalButton: "#393E46",
                statusSearchSpan: "#393E46",
                katastarCardBackgroundColor: "#F7F7F7",
                katastarTitleColor: "#F7F7F7",
                katastarElementHeaderColor: "#F7F7F7",
                katastarElementBodyBackgroundColor: "#F7F7F7",
                katastarTitleBackgroundColor: "#393E46",
                licaCardContainerColor: "#393E46",
                licaButtonColor: "#393E46",
                katastarDataSectionColor: "#393E46",
                componentWrapperColor: "#393E46",
                expandButtonColor: "#393E46",
                katastarElementHeaderBackgroundColor: "#393E46",
                extendendComponentDivBorder: "#929AAB",
                licaButtonBackgroundColor: "#D4D4CE",
                pretragaPoImenuLoaderBorder: "#D4D4CE",
                pretragaPoImenuStyleRadioColor: "#D4D4CE",
                licaCardContainerBackgroundColor: "#EEEEEE",
                previewTitleColor: "#F7F7F7",
                notificationContainerScrollBackgroundColor: "#F7F7F7",
                notificationListRowColorReaded: "#F7F7F7",
                pdfBackgroundColor: "#F7F7F7",
                katastarElementBodyRowColor: "#393E46",
                aplicantsModalLabelColor: "#393E46",
                addDocuentLabelBackgroundColor: "#393E46",
                previewTitleBackgroundColor: "#393E46",
                previewSectionColor: "#393E46",
                addDocumentLabelBackgroundColor: "#393E46",
                notificationListColor: "#393E46",
                previewSectionTitleBorder: "#929AAB",
                addDocumentLabelColor: "#929AAB",
                addDocumentDeleteImageBackgroundColor: "#929AAB",
                notificationContainerBorder: "#929AAB",
                noNotificationParagraph: "#929AAB",
                noDataTableContainerBorder: "#D4D4CE",
                noDataTableTextColor: "#D4D4CE",
                notificationListRowBorder: "#D4D4CE",
                notificationContainerScrollThumbBackgroundColor: "rgba(212, 212, 206, 0.5)",
                notificationListRowColorUnReaded: "rgba(212, 212, 206, 0.5)",
                addDocumentImageHoverBackgroundColor: "#EEEEEE",
                notificationContainerScrollThumbBorder: "#EEEEEE",
                datePickerInputBackgroundColor: "#F7F7F7",
                datePickerInputColor: "#393E46",
                buttonWrapperDisabledColor: "#F7F7F7",
                buttonWrapperPrimaryColor: "#F7F7F7",
                buttonWrapperSecondaryBackgroundColor: "#F7F7F7",
                modalContentBackgroundColor: "#F7F7F7",
                modalWrapBackground: "#F7F7F7",
                selectButtonBackgrounColor: "#F7F7F7",
                selectDropdownBackgroundColor: "#F7F7F7",
                tableContainerScrollBackground: "#F7F7F7",
                tableTrOddBackgroundColor: "#F7F7F7",
                datePickerLabelColor: "#393E46",
                buttonWrapperSecondaryColor: "#393E46",
                buttonWrapperTertiaryColor: "#393E46",
                buttonWrapperPrimaryBackgroundColor: "#393E46",
                buttonWrapperSecondaryBorder: "#393E46",
                infoBoxContainerColor: "#393E46",
                katakomInputColorDefault: "#393E46",
                katakomInputLabelColor: "#393E46",
                searchInputColor: "#393E46",
                selectButtonColor: "#393E46",
                selectOptionContainerColor: "#393E46",
                katakomSelectLabelColor: "#393E46",
                tableThColor: "#393E46",
                katakomTableButtonCellColor: "#393E46",
                datePickerErrorColor: "#929AAB",
                tooltipBackground: "#929AAB",
                buttonWrapperDisabledBackgroundColor: "#929AAB",
                katakomInputColorSecondary: "#929AAB",
                katakomInputErrorColor: "#929AAB",
                selectOptionContainerBackgroundColor: "#929AAB",
                katakomSelectErrorColor: "#929AAB",
                arrowIconColor: "#929AAB",
                clearIconColor: "#929AAB",
                buttonWrapperTertiaryBackgroundColor: "#D4D4CE",
                buttonWrapperFocuseBeforeBackgroundColor: "#D4D4CE",
                katakomInputBorder: "#D4D4CE",
                tableContainerScrollThumbBackground: "#D4D4CE",
                tableBorder: "#D4D4CE",
                tableThBackroundColor: "#D4D4CE",
                tableTrOddBackgroundColorHover: "#D4D4CE",
                katakomInputBackgroundColorFocuse: "#EEEEEE",
                tableTrOdd2BackgroundColor: "#EEEEEE",
                dropdownContainerBackgroundColor: "#F7F7F7",
                tabHeaderBackgroundColorNotActive: "#F7F7F7",
                tabHeaderColorActive: "#F7F7F7",
                cardBackgroundColor: "#F7F7F7",
                navbarBackgroundColor: "#F7F7F7",
                notificationCircleColor: "#F7F7F7",
                userSettingsBackgroundColor: "#F7F7F7",
                userInfoColor: "#F7F7F7",
                progressBarStepColorNotActive: "#F7F7F7",
                progressBarStepColorActive: "#F7F7F7",
                sidebarNavColor: "#F7F7F7",
                sidebarLinkNotActive: "#F7F7F7",
                sidebarLinkBorder: "#F7F7F7",
                subnavLabelColor: "#F7F7F7",
                dropdownLinkColor: "#F7F7F7",
                dropdownLinkBorder: "#F7F7F7",
                snackbarContainerColor: "#F7F7F7",
                tabHeaderBackgroundColorActive: "#393E46",
                tabHeader2Border: "#393E46",
                tabHeader2ColorActive: "#393E46",
                toggleButtonContainerBackgroundColorToggled: "#393E46",
                backgroundCircleBackgroundColorFocused: "#393E46",
                userContainerBackgroundColor: "#393E46",
                navbarRowColor: "#393E46",
                sidebarNavBackgroundDefault: "#393E46",
                sidebarLinkSecondaryActive: "#393E46",
                dropdownLinkBackground: "#393E46",
                snackbarContainerBackgroundColor: "#393E46",
                tabHeaderColorNotActive: "#929AAB",
                tabHeader2ColorNotActive: "#929AAB",
                notificationCircleBackgroundColor: "#929AAB",
                navbarSeparatorBackgroundColor: "#929AAB",
                progressBarStepBackgroundColor: "#929AAB",
                sidebarNavBackgroundSecondary: "#929AAB",
                sidebarLinkActive: "#929AAB",
                dropdownItemBackgroundColorHover: "#D4D4CE",
                toggleButtonContainerBackgroundColorUnToggled: "#D4D4CE",
                loaderAnimationBorderTop: "#D4D4CE",
                iconContainerBackgroundColorFocuse: "#D4D4CE",
                backgroundCircleBackgroundColorNotFocused: "#D4D4CE",
                loaderAnimationBorder: "#EEEEEE",
                infoBoxContainerBackgroundColor: "rgba(212, 212, 206, 0.5)",
            };
        case "theme-blue":
            return {
                primary: "#F6F6F6", // bela
                secondary: "#023246", //tamno plava
                tertiary: "#287094", //plava
                quaternary: "#D4D4CE", //siva
                quinary: "#EEEEEE",
                quaternaryFaded: "rgba(212, 212, 206, 0.5)",
                layoutUserNameColor: "#D4D4CE",
                katakomInputBackground: "#F6F6F6",
                siteUnderMaintenance: "#287094",
                advokatCustomSelectSearchInputColor: "#023246",
                advokatCustomSelectSearchInputBackgroundColor: "#EEEEEE",
                advokatCustomSelectSelectDropdownBackgroundColor: "#EEEEEE",
                advokatCustomSelectScrollColorBorder: "#EEEEEE",
                advokatCustomSelectScrollColor: "#287094",
                advokatCustomSelectOptionContainerColor: "#023246",
                advokatCustomSelectLabelColor: "#023246",
                chatbotContainerBackgroundColor: "#F6F6F6",
                chatbotTitleBackgroundColor: "#023246",
                chatbotTitleColor: "#F6F6F6",
                chatbotButtonBackgroundColor: "#D4D4CE",
                chatbotButtonColor: "#023246",
                chatbotTextFieldBorderColor: "#D4D4CE",
                chatbotTextFieldEmptyColor: "#287094",
                chatbotLabelColor: "#023246",
                chatBotParagraf: "#287094",
                errorModalBackgroundColor: "#F6F6F6",
                infoModalBackgroundColor: "#F6F6F6",
                successModalBackgroundColor: "#F6F6F6",
                waitingModalBackgroundColor: "#F6F6F6",
                settingsWelcomCardBackgroundColor: "#F6F6F6",
                settingsWelcomUserNameColor: "#D4D4CE",
                welcomUserCardBackgroundColor: "#F6F6F6",
                welcomUserNameColor: "D4D4CE",
                layoutWrapperBackgroundColor: "#D4D4CE",
                layoutCardBackgroundColor: "#F6F6F6",
                layoutCardTitleColor: "#023246",
                katakomPaginationLiABorder: "#023246",
                katakomPaginationLiABackgroundColor: "#023246",
                katakomPaginationLiColor: "#F6F6F6",
                katakomPaginationLiDisabledColor: "#023246",
                checkBoxBeforeBackgroundColor: "#023246",
                checkBoxFocuseBackgroundColor: "#D4D4CE",
                dopunaModalLabel: "#287094",
                dopunaModalImageHover: "#EEEEEE",
                dopunaModalDeleteImageBackgroundColor: "#287094",
                statusPreviewModalSpan: "#023246",
                statusPreviewModalButton: "#023246",
                statusSearchSpan: "#023246",
                katastarCardBackgroundColor: "#F6F6F6",
                katastarTitleColor: "#F6F6F6",
                katastarElementHeaderColor: "#F6F6F6",
                katastarElementBodyBackgroundColor: "#F6F6F6",
                katastarTitleBackgroundColor: "#023246",
                licaCardContainerColor: "#023246",
                licaButtonColor: "#023246",
                katastarDataSectionColor: "#023246",
                componentWrapperColor: "#023246",
                expandButtonColor: "#023246",
                katastarElementHeaderBackgroundColor: "#023246",
                extendendComponentDivBorder: "#287094",
                licaButtonBackgroundColor: "#D4D4CE",
                pretragaPoImenuLoaderBorder: "#D4D4CE",
                pretragaPoImenuStyleRadioColor: "#D4D4CE",
                licaCardContainerBackgroundColor: "#EEEEEE",
                previewTitleColor: "#F6F6F6",
                notificationContainerScrollBackgroundColor: "#F6F6F6",
                notificationListRowColorReaded: "#F6F6F6",
                pdfBackgroundColor: "#F6F6F6",
                katastarElementBodyRowColor: "#023246",
                aplicantsModalLabelColor: "#023246",
                addDocuentLabelBackgroundColor: "#023246",
                previewTitleBackgroundColor: "#023246",
                previewSectionColor: "#023246",
                addDocumentLabelBackgroundColor: "#023246",
                notificationListColor: "#023246",
                previewSectionTitleBorder: "#287094",
                addDocumentLabelColor: "#287094",
                addDocumentDeleteImageBackgroundColor: "#287094",
                notificationContainerBorder: "#287094",
                noNotificationParagraph: "#287094",
                noDataTableContainerBorder: "#D4D4CE",
                noDataTableTextColor: "#D4D4CE",
                notificationListRowBorder: "#D4D4CE",
                notificationContainerScrollThumbBackgroundColor: "rgba(212, 212, 206, 0.5)",
                notificationListRowColorUnReaded: "rgba(212, 212, 206, 0.5)",
                addDocumentImageHoverBackgroundColor: "#EEEEEE",
                notificationContainerScrollThumbBorder: "#EEEEEE",
                datePickerInputBackgroundColor: "#F6F6F6",
                datePickerInputColor: "#023246",
                buttonWrapperDisabledColor: "#F6F6F6",
                buttonWrapperPrimaryColor: "#F6F6F6",
                buttonWrapperSecondaryBackgroundColor: "#F6F6F6",
                modalContentBackgroundColor: "#F6F6F6",
                modalWrapBackground: "#F6F6F6",
                selectButtonBackgrounColor: "#F6F6F6",
                selectDropdownBackgroundColor: "#F6F6F6",
                tableContainerScrollBackground: "#F6F6F6",
                tableTrOddBackgroundColor: "#F6F6F6",
                datePickerLabelColor: "#023246",
                buttonWrapperSecondaryColor: "#023246",
                buttonWrapperTertiaryColor: "#023246",
                buttonWrapperPrimaryBackgroundColor: "#023246",
                buttonWrapperSecondaryBorder: "#023246",
                infoBoxContainerColor: "#023246",
                katakomInputColorDefault: "#023246",
                katakomInputLabelColor: "#023246",
                searchInputColor: "#023246",
                selectButtonColor: "#023246",
                selectOptionContainerColor: "#023246",
                katakomSelectLabelColor: "#023246",
                tableThColor: "#023246",
                katakomTableButtonCellColor: "#023246",
                datePickerErrorColor: "#287094",
                tooltipBackground: "#287094",
                buttonWrapperDisabledBackgroundColor: "#287094",
                katakomInputColorSecondary: "#287094",
                katakomInputErrorColor: "#287094",
                selectOptionContainerBackgroundColor: "#287094",
                katakomSelectErrorColor: "#287094",
                arrowIconColor: "#287094",
                clearIconColor: "#287094",
                buttonWrapperTertiaryBackgroundColor: "#D4D4CE",
                buttonWrapperFocuseBeforeBackgroundColor: "#D4D4CE",
                katakomInputBorder: "#D4D4CE",
                tableContainerScrollThumbBackground: "#D4D4CE",
                tableBorder: "#D4D4CE",
                tableThBackroundColor: "#D4D4CE",
                tableTrOddBackgroundColorHover: "#D4D4CE",
                katakomInputBackgroundColorFocuse: "#EEEEEE",
                tableTrOdd2BackgroundColor: "#EEEEEE",
                dropdownContainerBackgroundColor: "#F6F6F6",
                tabHeaderBackgroundColorNotActive: "#F6F6F6",
                tabHeaderColorActive: "#F6F6F6",
                cardBackgroundColor: "#F6F6F6",
                navbarBackgroundColor: "#F6F6F6",
                notificationCircleColor: "#F6F6F6",
                userSettingsBackgroundColor: "#F6F6F6",
                userInfoColor: "#F6F6F6",
                progressBarStepColorNotActive: "#F6F6F6",
                progressBarStepColorActive: "#F6F6F6",
                sidebarNavColor: "#F6F6F6",
                sidebarLinkNotActive: "#F6F6F6",
                sidebarLinkBorder: "#F6F6F6",
                subnavLabelColor: "#F6F6F6",
                dropdownLinkColor: "#F6F6F6",
                dropdownLinkBorder: "#F6F6F6",
                snackbarContainerColor: "#F6F6F6",
                tabHeaderBackgroundColorActive: "#023246",
                tabHeader2Border: "#023246",
                tabHeader2ColorActive: "#023246",
                toggleButtonContainerBackgroundColorToggled: "#023246",
                backgroundCircleBackgroundColorFocused: "#023246",
                userContainerBackgroundColor: "#023246",
                navbarRowColor: "#023246",
                sidebarNavBackgroundDefault: "#023246",
                sidebarLinkSecondaryActive: "#023246",
                dropdownLinkBackground: "#023246",
                snackbarContainerBackgroundColor: "#023246",
                tabHeaderColorNotActive: "#287094",
                tabHeader2ColorNotActive: "#287094",
                notificationCircleBackgroundColor: "#287094",
                navbarSeparatorBackgroundColor: "#287094",
                progressBarStepBackgroundColor: "#287094",
                sidebarNavBackgroundSecondary: "#287094",
                sidebarLinkActive: "#287094",
                dropdownItemBackgroundColorHover: "#D4D4CE",
                toggleButtonContainerBackgroundColorUnToggled: "#D4D4CE",
                loaderAnimationBorderTop: "#D4D4CE",
                iconContainerBackgroundColorFocuse: "#D4D4CE",
                backgroundCircleBackgroundColorNotFocused: "#D4D4CE",
                loaderAnimationBorder: "#EEEEEE",
                infoBoxContainerBackgroundColor: "rgba(212, 212, 206, 0.5)",
            };
        case "theme-green":
            return {
                primary: "#FFFFFF", //Bela
                secondary: "#343a40", // tamno svia
                tertiary: "#009688", // zelena
                quaternary: "#ECECEC",
                quinary: "#ECECEC",
                quaternaryFaded: "rgba(212, 212, 206, 0.5)",
                layoutUserNameColor: "#ECECEC",
                katakomInputBackground: "#FFFFFF",
                siteUnderMaintenance: "#009688",
                advokatCustomSelectSearchInputColor: "#343a40",
                advokatCustomSelectSearchInputBackgroundColor: "#ECECEC",
                advokatCustomSelectSelectDropdownBackgroundColor: "#ECECEC",
                advokatCustomSelectScrollColorBorder: "#ECECEC",
                advokatCustomSelectScrollColor: "#009688",
                advokatCustomSelectOptionContainerColor: "#343a40",
                advokatCustomSelectLabelColor: "#343a40",
                chatbotContainerBackgroundColor: "#FFFFFF",
                chatbotTitleBackgroundColor: "#343a40",
                chatbotTitleColor: "#FFFFFF",
                chatbotButtonBackgroundColor: "#ECECEC",
                chatbotButtonColor: "#343a40",
                chatbotTextFieldBorderColor: "#ECECEC",
                chatbotTextFieldEmptyColor: "#009688",
                chatbotLabelColor: "#343a40",
                chatBotParagraf: "#009688",
                errorModalBackgroundColor: "#FFFFFF",
                infoModalBackgroundColor: "#FFFFFF",
                successModalBackgroundColor: "#FFFFFF",
                waitingModalBackgroundColor: "#FFFFFF",
                settingsWelcomCardBackgroundColor: "#FFFFFF",
                settingsWelcomUserNameColor: "#ECECEC",
                welcomUserCardBackgroundColor: "#FFFFFF",
                welcomUserNameColor: "ECECEC",
                layoutWrapperBackgroundColor: "#ECECEC",
                layoutCardBackgroundColor: "#FFFFFF",
                layoutCardTitleColor: "#343a40",
                katakomPaginationLiABorder: "#343a40",
                katakomPaginationLiABackgroundColor: "#343a40",
                katakomPaginationLiColor: "#FFFFFF",
                katakomPaginationLiDisabledColor: "#343a40",
                checkBoxBeforeBackgroundColor: "#343a40",
                checkBoxFocuseBackgroundColor: "#ECECEC",
                dopunaModalLabel: "#009688",
                dopunaModalImageHover: "#ECECEC",
                dopunaModalDeleteImageBackgroundColor: "#009688",
                statusPreviewModalSpan: "#343a40",
                statusPreviewModalButton: "#343a40",
                statusSearchSpan: "#343a40",
                katastarCardBackgroundColor: "#FFFFFF",
                katastarTitleColor: "#FFFFFF",
                katastarElementHeaderColor: "#FFFFFF",
                katastarElementBodyBackgroundColor: "#FFFFFF",
                katastarTitleBackgroundColor: "#343a40",
                licaCardContainerColor: "#343a40",
                licaButtonColor: "#343a40",
                katastarDataSectionColor: "#343a40",
                componentWrapperColor: "#343a40",
                expandButtonColor: "#343a40",
                katastarElementHeaderBackgroundColor: "#343a40",
                extendendComponentDivBorder: "#009688",
                licaButtonBackgroundColor: "#ECECEC",
                pretragaPoImenuLoaderBorder: "#ECECEC",
                pretragaPoImenuStyleRadioColor: "#ECECEC",
                licaCardContainerBackgroundColor: "#ECECEC",
                previewTitleColor: "#FFFFFF",
                notificationContainerScrollBackgroundColor: "#FFFFFF",
                notificationListRowColorReaded: "#FFFFFF",
                pdfBackgroundColor: "#FFFFFF",
                katastarElementBodyRowColor: "#343a40",
                aplicantsModalLabelColor: "#343a40",
                addDocuentLabelBackgroundColor: "#343a40",
                previewTitleBackgroundColor: "#343a40",
                previewSectionColor: "#343a40",
                addDocumentLabelBackgroundColor: "#343a40",
                notificationListColor: "#343a40",
                previewSectionTitleBorder: "#009688",
                addDocumentLabelColor: "#009688",
                addDocumentDeleteImageBackgroundColor: "#009688",
                notificationContainerBorder: "#009688",
                noNotificationParagraph: "#009688",
                noDataTableContainerBorder: "#ECECEC",
                noDataTableTextColor: "#ECECEC",
                notificationListRowBorder: "#ECECEC",
                notificationContainerScrollThumbBackgroundColor: "rgba(212, 212, 206, 0.5)",
                notificationListRowColorUnReaded: "rgba(212, 212, 206, 0.5)",
                addDocumentImageHoverBackgroundColor: "#ECECEC",
                notificationContainerScrollThumbBorder: "#ECECEC",
                datePickerInputBackgroundColor: "#FFFFFF",
                datePickerInputColor: "#343a40",
                buttonWrapperDisabledColor: "#FFFFFF",
                buttonWrapperPrimaryColor: "#FFFFFF",
                buttonWrapperSecondaryBackgroundColor: "#FFFFFF",
                modalContentBackgroundColor: "#FFFFFF",
                modalWrapBackground: "#FFFFFF",
                selectButtonBackgrounColor: "#FFFFFF",
                selectDropdownBackgroundColor: "#FFFFFF",
                tableContainerScrollBackground: "#FFFFFF",
                tableTrOddBackgroundColor: "#FFFFFF",
                datePickerLabelColor: "#343a40",
                buttonWrapperSecondaryColor: "#343a40",
                buttonWrapperTertiaryColor: "#343a40",
                buttonWrapperPrimaryBackgroundColor: "#343a40",
                buttonWrapperSecondaryBorder: "#343a40",
                infoBoxContainerColor: "#343a40",
                katakomInputColorDefault: "#343a40",
                katakomInputLabelColor: "#343a40",
                searchInputColor: "#343a40",
                selectButtonColor: "#343a40",
                selectOptionContainerColor: "#343a40",
                katakomSelectLabelColor: "#343a40",
                tableThColor: "#343a40",
                katakomTableButtonCellColor: "#343a40",
                datePickerErrorColor: "#009688",
                tooltipBackground: "#009688",
                buttonWrapperDisabledBackgroundColor: "#009688",
                katakomInputColorSecondary: "#009688",
                katakomInputErrorColor: "#009688",
                selectOptionContainerBackgroundColor: "#009688",
                katakomSelectErrorColor: "#009688",
                arrowIconColor: "#009688",
                clearIconColor: "#009688",
                buttonWrapperTertiaryBackgroundColor: "#ECECEC",
                buttonWrapperFocuseBeforeBackgroundColor: "#ECECEC",
                katakomInputBorder: "#ECECEC",
                tableContainerScrollThumbBackground: "#ECECEC",
                tableBorder: "#ECECEC",
                tableThBackroundColor: "#ECECEC",
                tableTrOddBackgroundColorHover: "#ECECEC",
                katakomInputBackgroundColorFocuse: "#ECECEC",
                tableTrOdd2BackgroundColor: "#ECECEC",
                dropdownContainerBackgroundColor: "#FFFFFF",
                tabHeaderBackgroundColorNotActive: "#FFFFFF",
                tabHeaderColorActive: "#FFFFFF",
                cardBackgroundColor: "#FFFFFF",
                navbarBackgroundColor: "#FFFFFF",
                notificationCircleColor: "#FFFFFF",
                userSettingsBackgroundColor: "#FFFFFF",
                userInfoColor: "#FFFFFF",
                progressBarStepColorNotActive: "#FFFFFF",
                progressBarStepColorActive: "#FFFFFF",
                sidebarNavColor: "#FFFFFF",
                sidebarLinkNotActive: "#FFFFFF",
                sidebarLinkBorder: "#FFFFFF",
                subnavLabelColor: "#FFFFFF",
                dropdownLinkColor: "#FFFFFF",
                dropdownLinkBorder: "#FFFFFF",
                snackbarContainerColor: "#FFFFFF",
                tabHeaderBackgroundColorActive: "#343a40",
                tabHeader2Border: "#343a40",
                tabHeader2ColorActive: "#343a40",
                toggleButtonContainerBackgroundColorToggled: "#343a40",
                backgroundCircleBackgroundColorFocused: "#343a40",
                userContainerBackgroundColor: "#343a40",
                navbarRowColor: "#343a40",
                sidebarNavBackgroundDefault: "#343a40",
                sidebarLinkSecondaryActive: "#343a40",
                dropdownLinkBackground: "#343a40",
                snackbarContainerBackgroundColor: "#343a40",
                tabHeaderColorNotActive: "#009688",
                tabHeader2ColorNotActive: "#009688",
                notificationCircleBackgroundColor: "#009688",
                navbarSeparatorBackgroundColor: "#009688",
                progressBarStepBackgroundColor: "#009688",
                sidebarNavBackgroundSecondary: "#009688",
                sidebarLinkActive: "#009688",
                dropdownItemBackgroundColorHover: "#ECECEC",
                toggleButtonContainerBackgroundColorUnToggled: "#ECECEC",
                loaderAnimationBorderTop: "#ECECEC",
                iconContainerBackgroundColorFocuse: "#ECECEC",
                backgroundCircleBackgroundColorNotFocused: "#ECECEC",
                loaderAnimationBorder: "#ECECEC",
                infoBoxContainerBackgroundColor: "rgba(212, 212, 206, 0.5)",
            };
        case "theme-appurs":
            return {
                primary: "#F7F7F7",
                secondary: "#393E46",
                tertiary: "#929AAB",
                quaternary: "#D4D4CE",
                quinary: "#EEEEEE",
                quaternaryFaded: "rgba(212, 212, 206, 0.5)",
                layoutUserNameColor: "#D4D4CE",
                katakomInputBackground: "#F7F7F7",
                siteUnderMaintenance: "#929AAB",
                advokatCustomSelectSearchInputColor: "#393E46",
                advokatCustomSelectSearchInputBackgroundColor: "#EEEEEE",
                advokatCustomSelectSelectDropdownBackgroundColor: "#EEEEEE",
                advokatCustomSelectScrollColorBorder: "#EEEEEE",
                advokatCustomSelectScrollColor: "#929AAB",
                advokatCustomSelectOptionContainerColor: "#393E46",
                advokatCustomSelectLabelColor: "#393E46",
                chatbotContainerBackgroundColor: "#F7F7F7",
                chatbotTitleBackgroundColor: "#393E46",
                chatbotTitleColor: "#F7F7F7",
                chatbotButtonBackgroundColor: "#D4D4CE",
                chatbotButtonColor: "#393E46",
                chatbotTextFieldBorderColor: "#D4D4CE",
                chatbotTextFieldEmptyColor: "#929AAB",
                chatbotLabelColor: "#393E46",
                chatBotParagraf: "#929AAB",
                errorModalBackgroundColor: "#F7F7F7",
                infoModalBackgroundColor: "#F7F7F7",
                successModalBackgroundColor: "#F7F7F7",
                waitingModalBackgroundColor: "#F7F7F7",
                settingsWelcomCardBackgroundColor: "#F7F7F7",
                settingsWelcomUserNameColor: "#D4D4CE",
                welcomUserCardBackgroundColor: "#F7F7F7",
                welcomUserNameColor: "D4D4CE",
                layoutWrapperBackgroundColor: "#D4D4CE",
                layoutCardBackgroundColor: "#F7F7F7",
                layoutCardTitleColor: "#393E46",
                katakomPaginationLiABorder: "#393E46",
                katakomPaginationLiABackgroundColor: "#393E46",
                katakomPaginationLiColor: "#F7F7F7",
                katakomPaginationLiDisabledColor: "#393E46",
                checkBoxBeforeBackgroundColor: "#393E46",
                checkBoxFocuseBackgroundColor: "#D4D4CE",
                dopunaModalLabel: "#929AAB",
                dopunaModalImageHover: "#EEEEEE",
                dopunaModalDeleteImageBackgroundColor: "#929AAB",
                statusPreviewModalSpan: "#393E46",
                statusPreviewModalButton: "#393E46",
                statusSearchSpan: "#393E46",
                katastarCardBackgroundColor: "#F7F7F7",
                katastarTitleColor: "#F7F7F7",
                katastarElementHeaderColor: "#F7F7F7",
                katastarElementBodyBackgroundColor: "#F7F7F7",
                katastarTitleBackgroundColor: "#393E46",
                licaCardContainerColor: "#393E46",
                licaButtonColor: "#393E46",
                katastarDataSectionColor: "#393E46",
                componentWrapperColor: "#393E46",
                expandButtonColor: "#393E46",
                katastarElementHeaderBackgroundColor: "#393E46",
                extendendComponentDivBorder: "#929AAB",
                licaButtonBackgroundColor: "#D4D4CE",
                pretragaPoImenuLoaderBorder: "#D4D4CE",
                pretragaPoImenuStyleRadioColor: "#D4D4CE",
                licaCardContainerBackgroundColor: "#EEEEEE",
                previewTitleColor: "#F7F7F7",
                notificationContainerScrollBackgroundColor: "#F7F7F7",
                notificationListRowColorReaded: "#F7F7F7",
                pdfBackgroundColor: "#F7F7F7",
                katastarElementBodyRowColor: "#393E46",
                aplicantsModalLabelColor: "#393E46",
                addDocuentLabelBackgroundColor: "#393E46",
                previewTitleBackgroundColor: "#393E46",
                previewSectionColor: "#393E46",
                addDocumentLabelBackgroundColor: "#393E46",
                notificationListColor: "#393E46",
                previewSectionTitleBorder: "#929AAB",
                addDocumentLabelColor: "#929AAB",
                addDocumentDeleteImageBackgroundColor: "#929AAB",
                notificationContainerBorder: "#929AAB",
                noNotificationParagraph: "#929AAB",
                noDataTableContainerBorder: "#D4D4CE",
                noDataTableTextColor: "#D4D4CE",
                notificationListRowBorder: "#D4D4CE",
                notificationContainerScrollThumbBackgroundColor: "rgba(212, 212, 206, 0.5)",
                notificationListRowColorUnReaded: "rgba(212, 212, 206, 0.5)",
                addDocumentImageHoverBackgroundColor: "#EEEEEE",
                notificationContainerScrollThumbBorder: "#EEEEEE",
                datePickerInputBackgroundColor: "#F7F7F7",
                datePickerInputColor: "#393E46",
                buttonWrapperDisabledColor: "#F7F7F7",
                buttonWrapperPrimaryColor: "#F7F7F7",
                buttonWrapperSecondaryBackgroundColor: "#F7F7F7",
                modalContentBackgroundColor: "#F7F7F7",
                modalWrapBackground: "#F7F7F7",
                selectButtonBackgrounColor: "#F7F7F7",
                selectDropdownBackgroundColor: "#F7F7F7",
                tableContainerScrollBackground: "#F7F7F7",
                tableTrOddBackgroundColor: "#F7F7F7",
                datePickerLabelColor: "#393E46",
                buttonWrapperSecondaryColor: "#393E46",
                buttonWrapperTertiaryColor: "#393E46",
                buttonWrapperPrimaryBackgroundColor: "#393E46",
                buttonWrapperSecondaryBorder: "#393E46",
                infoBoxContainerColor: "#393E46",
                katakomInputColorDefault: "#393E46",
                katakomInputLabelColor: "#393E46",
                searchInputColor: "#393E46",
                selectButtonColor: "#393E46",
                selectOptionContainerColor: "#393E46",
                katakomSelectLabelColor: "#393E46",
                tableThColor: "#393E46",
                katakomTableButtonCellColor: "#393E46",
                datePickerErrorColor: "#929AAB",
                tooltipBackground: "#929AAB",
                buttonWrapperDisabledBackgroundColor: "#929AAB",
                katakomInputColorSecondary: "#929AAB",
                katakomInputErrorColor: "#929AAB",
                selectOptionContainerBackgroundColor: "#929AAB",
                katakomSelectErrorColor: "#929AAB",
                arrowIconColor: "#929AAB",
                clearIconColor: "#929AAB",
                buttonWrapperTertiaryBackgroundColor: "#D4D4CE",
                buttonWrapperFocuseBeforeBackgroundColor: "#D4D4CE",
                katakomInputBorder: "#D4D4CE",
                tableContainerScrollThumbBackground: "#D4D4CE",
                tableBorder: "#D4D4CE",
                tableThBackroundColor: "#D4D4CE",
                tableTrOddBackgroundColorHover: "#D4D4CE",
                katakomInputBackgroundColorFocuse: "#EEEEEE",
                tableTrOdd2BackgroundColor: "#EEEEEE",
                dropdownContainerBackgroundColor: "#F7F7F7",
                tabHeaderBackgroundColorNotActive: "#F7F7F7",
                tabHeaderColorActive: "#F7F7F7",
                cardBackgroundColor: "#F7F7F7",
                navbarBackgroundColor: "#F7F7F7",
                notificationCircleColor: "#F7F7F7",
                userSettingsBackgroundColor: "#F7F7F7",
                userInfoColor: "#F7F7F7",
                progressBarStepColorNotActive: "#F7F7F7",
                progressBarStepColorActive: "#F7F7F7",
                sidebarNavColor: "#F7F7F7",
                sidebarLinkNotActive: "#F7F7F7",
                sidebarLinkBorder: "#F7F7F7",
                subnavLabelColor: "#F7F7F7",
                dropdownLinkColor: "#F7F7F7",
                dropdownLinkBorder: "#F7F7F7",
                snackbarContainerColor: "#F7F7F7",
                tabHeaderBackgroundColorActive: "#393E46",
                tabHeader2Border: "#393E46",
                tabHeader2ColorActive: "#393E46",
                toggleButtonContainerBackgroundColorToggled: "#393E46",
                backgroundCircleBackgroundColorFocused: "#393E46",
                userContainerBackgroundColor: "#393E46",
                navbarRowColor: "#393E46",
                sidebarNavBackgroundDefault: "#393E46",
                sidebarLinkSecondaryActive: "#393E46",
                dropdownLinkBackground: "#393E46",
                snackbarContainerBackgroundColor: "#393E46",
                tabHeaderColorNotActive: "#929AAB",
                tabHeader2ColorNotActive: "#929AAB",
                notificationCircleBackgroundColor: "#929AAB",
                navbarSeparatorBackgroundColor: "#929AAB",
                progressBarStepBackgroundColor: "#929AAB",
                sidebarNavBackgroundSecondary: "#929AAB",
                sidebarLinkActive: "#929AAB",
                dropdownItemBackgroundColorHover: "#D4D4CE",
                toggleButtonContainerBackgroundColorUnToggled: "#D4D4CE",
                loaderAnimationBorderTop: "#D4D4CE",
                iconContainerBackgroundColorFocuse: "#D4D4CE",
                backgroundCircleBackgroundColorNotFocused: "#D4D4CE",
                loaderAnimationBorder: "#EEEEEE",
                infoBoxContainerBackgroundColor: "rgba(212, 212, 206, 0.5)",
            };
        default:
            return {
                primary: "#F7F7F7",
                secondary: "#393E46",
                tertiary: "#929AAB",
                quaternary: "#D4D4CE",
                quinary: "#EEEEEE",
                quaternaryFaded: "rgba(212, 212, 206, 0.5)",
                layoutUserNameColor: "#D4D4CE",
                katakomInputBackground: "#F7F7F7",
                siteUnderMaintenance: "#929AAB",
                advokatCustomSelectSearchInputColor: "#393E46",
                advokatCustomSelectSearchInputBackgroundColor: "#EEEEEE",
                advokatCustomSelectSelectDropdownBackgroundColor: "#EEEEEE",
                advokatCustomSelectScrollColorBorder: "#EEEEEE",
                advokatCustomSelectScrollColor: "#929AAB",
                advokatCustomSelectOptionContainerColor: "#393E46",
                advokatCustomSelectLabelColor: "#393E46",
                chatbotContainerBackgroundColor: "#F7F7F7",
                chatbotTitleBackgroundColor: "#393E46",
                chatbotTitleColor: "#F7F7F7",
                chatbotButtonBackgroundColor: "#D4D4CE",
                chatbotButtonColor: "#393E46",
                chatbotTextFieldBorderColor: "#D4D4CE",
                chatbotTextFieldEmptyColor: "#929AAB",
                chatbotLabelColor: "#393E46",
                chatBotParagraf: "#929AAB",
                errorModalBackgroundColor: "#F7F7F7",
                infoModalBackgroundColor: "#F7F7F7",
                successModalBackgroundColor: "#F7F7F7",
                waitingModalBackgroundColor: "#F7F7F7",
                settingsWelcomCardBackgroundColor: "#F7F7F7",
                settingsWelcomUserNameColor: "#D4D4CE",
                welcomUserCardBackgroundColor: "#F7F7F7",
                welcomUserNameColor: "D4D4CE",
                layoutWrapperBackgroundColor: "#D4D4CE",
                layoutCardBackgroundColor: "#F7F7F7",
                layoutCardTitleColor: "#393E46",
                katakomPaginationLiABorder: "#393E46",
                katakomPaginationLiABackgroundColor: "#393E46",
                katakomPaginationLiColor: "#F7F7F7",
                katakomPaginationLiDisabledColor: "#393E46",
                checkBoxBeforeBackgroundColor: "#393E46",
                checkBoxFocuseBackgroundColor: "#D4D4CE",
                dopunaModalLabel: "#929AAB",
                dopunaModalImageHover: "#EEEEEE",
                dopunaModalDeleteImageBackgroundColor: "#929AAB",
                statusPreviewModalSpan: "#393E46",
                statusPreviewModalButton: "#393E46",
                statusSearchSpan: "#393E46",
                katastarCardBackgroundColor: "#F7F7F7",
                katastarTitleColor: "#F7F7F7",
                katastarElementHeaderColor: "#F7F7F7",
                katastarElementBodyBackgroundColor: "#F7F7F7",
                katastarTitleBackgroundColor: "#393E46",
                licaCardContainerColor: "#393E46",
                licaButtonColor: "#393E46",
                katastarDataSectionColor: "#393E46",
                componentWrapperColor: "#393E46",
                expandButtonColor: "#393E46",
                katastarElementHeaderBackgroundColor: "#393E46",
                extendendComponentDivBorder: "#929AAB",
                licaButtonBackgroundColor: "#D4D4CE",
                pretragaPoImenuLoaderBorder: "#D4D4CE",
                pretragaPoImenuStyleRadioColor: "#D4D4CE",
                licaCardContainerBackgroundColor: "#EEEEEE",
                previewTitleColor: "#F7F7F7",
                notificationContainerScrollBackgroundColor: "#F7F7F7",
                notificationListRowColorReaded: "#F7F7F7",
                pdfBackgroundColor: "#F7F7F7",
                katastarElementBodyRowColor: "#393E46",
                aplicantsModalLabelColor: "#393E46",
                addDocuentLabelBackgroundColor: "#393E46",
                previewTitleBackgroundColor: "#393E46",
                previewSectionColor: "#393E46",
                addDocumentLabelBackgroundColor: "#393E46",
                notificationListColor: "#393E46",
                previewSectionTitleBorder: "#929AAB",
                addDocumentLabelColor: "#929AAB",
                addDocumentDeleteImageBackgroundColor: "#929AAB",
                notificationContainerBorder: "#929AAB",
                noNotificationParagraph: "#929AAB",
                noDataTableContainerBorder: "#D4D4CE",
                noDataTableTextColor: "#D4D4CE",
                notificationListRowBorder: "#D4D4CE",
                notificationContainerScrollThumbBackgroundColor: "rgba(212, 212, 206, 0.5)",
                notificationListRowColorUnReaded: "rgba(212, 212, 206, 0.5)",
                addDocumentImageHoverBackgroundColor: "#EEEEEE",
                notificationContainerScrollThumbBorder: "#EEEEEE",
                datePickerInputBackgroundColor: "#F7F7F7",
                datePickerInputColor: "#393E46",
                buttonWrapperDisabledColor: "#F7F7F7",
                buttonWrapperPrimaryColor: "#F7F7F7",
                buttonWrapperSecondaryBackgroundColor: "#F7F7F7",
                modalContentBackgroundColor: "#F7F7F7",
                modalWrapBackground: "#F7F7F7",
                selectButtonBackgrounColor: "#F7F7F7",
                selectDropdownBackgroundColor: "#F7F7F7",
                tableContainerScrollBackground: "#F7F7F7",
                tableTrOddBackgroundColor: "#F7F7F7",
                datePickerLabelColor: "#393E46",
                buttonWrapperSecondaryColor: "#393E46",
                buttonWrapperTertiaryColor: "#393E46",
                buttonWrapperPrimaryBackgroundColor: "#393E46",
                buttonWrapperSecondaryBorder: "#393E46",
                infoBoxContainerColor: "#393E46",
                katakomInputColorDefault: "#393E46",
                katakomInputLabelColor: "#393E46",
                searchInputColor: "#393E46",
                selectButtonColor: "#393E46",
                selectOptionContainerColor: "#393E46",
                katakomSelectLabelColor: "#393E46",
                tableThColor: "#393E46",
                katakomTableButtonCellColor: "#393E46",
                datePickerErrorColor: "#929AAB",
                tooltipBackground: "#929AAB",
                buttonWrapperDisabledBackgroundColor: "#929AAB",
                katakomInputColorSecondary: "#929AAB",
                katakomInputErrorColor: "#929AAB",
                selectOptionContainerBackgroundColor: "#929AAB",
                katakomSelectErrorColor: "#929AAB",
                arrowIconColor: "#929AAB",
                clearIconColor: "#929AAB",
                buttonWrapperTertiaryBackgroundColor: "#D4D4CE",
                buttonWrapperFocuseBeforeBackgroundColor: "#D4D4CE",
                katakomInputBorder: "#D4D4CE",
                tableContainerScrollThumbBackground: "#D4D4CE",
                tableBorder: "#D4D4CE",
                tableThBackroundColor: "#D4D4CE",
                tableTrOddBackgroundColorHover: "#D4D4CE",
                katakomInputBackgroundColorFocuse: "#EEEEEE",
                tableTrOdd2BackgroundColor: "#EEEEEE",
                dropdownContainerBackgroundColor: "#F7F7F7",
                tabHeaderBackgroundColorNotActive: "#F7F7F7",
                tabHeaderColorActive: "#F7F7F7",
                cardBackgroundColor: "#F7F7F7",
                navbarBackgroundColor: "#F7F7F7",
                notificationCircleColor: "#F7F7F7",
                userSettingsBackgroundColor: "#F7F7F7",
                userInfoColor: "#F7F7F7",
                progressBarStepColorNotActive: "#F7F7F7",
                progressBarStepColorActive: "#F7F7F7",
                sidebarNavColor: "#F7F7F7",
                sidebarLinkNotActive: "#F7F7F7",
                sidebarLinkBorder: "#F7F7F7",
                subnavLabelColor: "#F7F7F7",
                dropdownLinkColor: "#F7F7F7",
                dropdownLinkBorder: "#F7F7F7",
                snackbarContainerColor: "#F7F7F7",
                tabHeaderBackgroundColorActive: "#393E46",
                tabHeader2Border: "#393E46",
                tabHeader2ColorActive: "#393E46",
                toggleButtonContainerBackgroundColorToggled: "#393E46",
                backgroundCircleBackgroundColorFocused: "#393E46",
                userContainerBackgroundColor: "#393E46",
                navbarRowColor: "#393E46",
                sidebarNavBackgroundDefault: "#393E46",
                sidebarLinkSecondaryActive: "#393E46",
                dropdownLinkBackground: "#393E46",
                snackbarContainerBackgroundColor: "#393E46",
                tabHeaderColorNotActive: "#929AAB",
                tabHeader2ColorNotActive: "#929AAB",
                notificationCircleBackgroundColor: "#929AAB",
                navbarSeparatorBackgroundColor: "#929AAB",
                progressBarStepBackgroundColor: "#929AAB",
                sidebarNavBackgroundSecondary: "#929AAB",
                sidebarLinkActive: "#929AAB",
                dropdownItemBackgroundColorHover: "#D4D4CE",
                toggleButtonContainerBackgroundColorUnToggled: "#D4D4CE",
                loaderAnimationBorderTop: "#D4D4CE",
                iconContainerBackgroundColorFocuse: "#D4D4CE",
                backgroundCircleBackgroundColorNotFocused: "#D4D4CE",
                loaderAnimationBorder: "#EEEEEE",
                infoBoxContainerBackgroundColor: "rgba(212, 212, 206, 0.5)",
            };
    }
};
