import { useEffect, useState } from "react";
import KatakomInput from "../../shared/KatakomInput";
import KatakomButton from "../../shared/KatakomButton";
import { Column, Row } from "../style";
import api from "../../../services/api";
import useMobileCheck from "../../../hooks/useMobileChecker";
import KatakomSelect, { SelectOption } from "../../shared/KatakomSelect";
import { sluzbeOptions } from "../../../register/register";
import { translateToCyrilic, translateToCyrilicExact } from "../../../utils/translate";

export const EStatusSearch = () => {
    const [brojPredmeta, setBrojPredmeta] = useState<string>("");
    const [sluzbaId, setSluzbaId] = useState<string>("");
    const [data, setData] = useState<any>({});
    const isMobile = useMobileCheck();

    const fatchData = async () => {
        let queryParams: any = {};

        if (brojPredmeta !== "") {
            queryParams.brojPredmeta = brojPredmeta;
        }

        if (sluzbaId !== "") {
            queryParams.sluzbaId = sluzbaId;
        }

        try {
            const res = await api.get(`api/v1/status-predmeta`, {
                ...queryParams,
            });

            setData(res?.data);
        } finally {
        }
    };

    const handleSearchClick = () => {
        fatchData();
    };

    const columns = [
        { label: "Статус", value: "CaseStatus" },
        { label: "TaxAmountToPay", value: "TaxAmountToPay" },
        { label: "Published", value: "Published" },
        { label: "SolutionDocumentID", value: "SolutionDocumentID" },
        { label: "ReadyForPublish", value: "ReadyForPublish" },
    ];

    return (
        <>
            <Column>
                <Column>
                    {isMobile ? (
                        <Column style={{ marginTop: "0px", alignItems: "center" }}>
                            <KatakomInput
                                type={"text"}
                                value={brojPredmeta}
                                name={"brojPredmeta"}
                                size="m"
                                label="Број предмета"
                                error={false}
                                onChange={(e) => {
                                    setBrojPredmeta(e.target.value);
                                }}
                                onEnter={handleSearchClick}
                                style={{ margin: "20px 0 0 0" }}
                            />
                            <KatakomSelect
                                options={sluzbeOptions}
                                value={sluzbaId || ""}
                                name="sluzbaId"
                                size="m"
                                label="Служба"
                                error={false}
                                onChange={(option: SelectOption) => {
                                    setSluzbaId(option.value);
                                }}
                                style={{ margin: "20px 0 0 0 " }}
                            />
                            <KatakomButton style={{ margin: "30px 0" }} size="xl" label={"ПРЕТРАЖИ"} onClick={handleSearchClick} />
                        </Column>
                    ) : (
                        <Row style={{ marginTop: "0px" }}>
                            <KatakomInput
                                type={"text"}
                                value={brojPredmeta}
                                name={"brojPredmeta"}
                                size="m"
                                label="Број предмета"
                                error={false}
                                onChange={(e) => {
                                    setBrojPredmeta(e.target.value);
                                }}
                                onEnter={handleSearchClick}
                                style={{ margin: "20px 0 0 20px" }}
                            />
                            <KatakomSelect
                                options={sluzbeOptions}
                                value={sluzbaId || ""}
                                name="sluzbaId"
                                size="m"
                                label="Служба"
                                error={false}
                                onChange={(option: SelectOption) => {
                                    setSluzbaId(option.value);
                                }}
                                style={{ margin: "20px 20px 0 20px" }}
                            />
                            <KatakomButton label={"ПРЕТРАЖИ"} onClick={handleSearchClick} />
                        </Row>
                    )}
                    {data?.CaseStatus ? <EStatusCard data={data} /> : null}
                </Column>
            </Column>
        </>
    );
};

const EStatusCard = (data: any) => {
    const p = data?.data?.TaxAmountToPay;
    const price = p ? (Number(p) !== 0 ? String(p).slice(0, -2) + "," + String(p).slice(-2) : 0) : 0;
    return (
        <div style={{ marginBottom: "40px" }}>
            <div style={{ marginLeft: "40px", marginTop: "40px" }}>Статус: {translateToCyrilicExact(data.data.CaseStatus)}</div>
            <div style={{ marginLeft: "40px", marginTop: "40px" }}>Преостала такса: {String(price)}</div>
            <div style={{ marginLeft: "40px", marginTop: "40px" }}>
                Право на жалбу: {translateToCyrilic(data.data.ReadyForPublish ? "Да" : "Не")}
            </div>
        </div>
    );
};
