import { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import KatakomTable from "../../../../shared/KatakomTable";
import { themeColors } from "../../../../../constants/constants";
import { Container, Row } from "../../../style";
import { addTempDocumentTip2, deleteTempDocumentTip2 } from "../../../../../store/CaseTip2Reducer";
import GeneratePDFTip2 from "../../../../pdfs/Tip2PDF";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import api from "../../../../../services/api";

import KatakomModal from "../../../../shared/KatakomModal";
import { PreviewPDFIFrame } from "../../../katastar/renderKatastarData/style";
import KatakomButton from "../../../../shared/KatakomButton";
import useMobileCheck from "../../../../../hooks/useMobileChecker";

const Label = styled.label`
    font-size: 16px;
    font-weight: 500;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin: 30px 0 0 0;
    width: 200px;
    background-color: ${themeColors.addDocumentLabelBackgroundColor};
    color: white;
    box-shadow: 5px 5px 10px 0px #00000040;
    border-radius: 4px;
    cursor: pointer;
    transition: border 0.3s;

    input[type="file"] {
        display: none;
    }
`;

const Input = styled.input``;

const AddDocumentsTip2 = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const tempDokumenta = useSelector((state: RootState) => state.caseTip2.tempDokumenta);
    const slucaj = useSelector((state: RootState) => state.caseTip2);
    const [pdfSrc, setPdfSrc] = useState<string | null>(null);
    const [previewPDF, setPreviewPDF] = useState<boolean>(false);
    const isMobile = useMobileCheck();

    const downloadPdf = async () => {
        const blob = await pdf(<GeneratePDFTip2 pdfData={slucaj} />).toBlob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `potvrda_o_konverziji_${slucaj.basicInfo.brojPotvrde}_${
            slucaj.ucesnici?.[0]?.imePravnogLica ?? slucaj.ucesnici?.[0]?.ime + "_" + slucaj.ucesnici?.[0]?.prezime
        }.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };
    const previewPdf = async () => {
        const blob = await pdf(<GeneratePDFTip2 pdfData={slucaj} />).toBlob();
        const url = URL.createObjectURL(blob);

        setPreviewPDF(true);
        setPdfSrc(url);
    };

    const handleDeleteDocument = (dokument: any) => {
        dispatch(deleteTempDocumentTip2(dokument.id));
    };

    // Function to handle file selection
    const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        if (selectedFiles) {
            const newFiles = Array.from(selectedFiles).filter((file) => file.type === "application/pdf");
            await Promise.all(
                newFiles.map((file) => {
                    return new Promise<string>(async (resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async () => {
                            const base64String = reader.result?.toString().split(",")[1];
                            if (base64String) {
                                try {
                                    const isSigned = await hasDigitalSignature(base64String);
                                    dispatch(
                                        addTempDocumentTip2({
                                            name: file.name,
                                            file: base64String,
                                            isSigned: Boolean(isSigned),
                                            category: "Kategorija 1",
                                        })
                                    );
                                    resolve(base64String);
                                } catch (error) {
                                    reject(error);
                                }
                            } else {
                                reject(new Error("Error while reading file"));
                            }
                        };
                        reader.onerror = () => {
                            reject(new Error("Error while reading file"));
                        };
                    });
                })
            );
        }
    };

    const hasDigitalSignature = async (base64EncodedFile: string) => {
        const response = await api.post(`api/v1/has-digital-signature`, {
            base64Document: base64EncodedFile,
        });

        return response?.data;
    };

    const columns = [
        { label: "Ид", value: "id" },
        { label: "Документ", value: "name" },
        { label: "Категорија документа", value: "category" },
        { label: "Електронски потпис", value: "isSigned" },
        { label: "", value: "delete" },
    ];

    return (
        <Container>
            <KatakomModal isOpen={previewPDF} setIsOpen={setPreviewPDF}>
                <PreviewPDFIFrame>
                    {pdfSrc && (
                        <iframe
                            src={pdfSrc}
                            title="PDF Preview"
                            style={{ width: "100%", height: "80vh", border: "1px solid #ccc" }}
                        ></iframe>
                    )}
                    <Row style={{ justifyContent: "center" }}>
                        <KatakomButton
                            label={"Затвори"}
                            onClick={() => {
                                setPreviewPDF(false);
                            }}
                        ></KatakomButton>
                    </Row>
                </PreviewPDFIFrame>
            </KatakomModal>
            {tempDokumenta.length !== 0 ? (
                <div style={{ margin: "15px" }}>
                    <KatakomTable columns={columns} data={tempDokumenta} onDelete={handleDeleteDocument} />
                </div>
            ) : null}

            <Row style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Label>
                    Додај фајл
                    <Input type="file" onChange={handleFileUpload} accept=".pdf" multiple />
                </Label>
                <div style={{ display: "flex", marginTop: 10 }}>
                    <button
                        style={{
                            outline: "none",
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "inherit",
                            fontSize: "16px",
                        }}
                        onClick={() => {
                            if (!isMobile) {
                                previewPdf();
                            } else {
                                downloadPdf();
                            }
                        }}
                    >
                        Преузми документ
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default AddDocumentsTip2;
