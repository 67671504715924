import React from "react";
import PageLayout from "../../shared/PageLayout";
import NewCaseAdvokatiLayout from "./NewCaseAdvokatiLayout";

const NewCaseAdvokati: React.FC = () => {
    return (
        <PageLayout>
            <NewCaseAdvokatiLayout />
        </PageLayout>
    );
};

export default NewCaseAdvokati;
