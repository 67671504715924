import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ROLE } from "../enums/role";

export interface UserInitialState {
    email: string;
    role: string;
    theme: string;
    notificationCounter?: number;
    firstName: string;
    lastName: string;
    brojPretraga: number;
}

const initialState: UserInitialState = {
    email: "",
    firstName: "",
    lastName: "",
    notificationCounter: 0,
    role: "regular",
    theme: "theme-default",
    brojPretraga: 0,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<UserInitialState>) => {
            state.email = action.payload.email;
            state.role = action.payload.role;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.brojPretraga = action.payload.brojPretraga;
        },
        changeEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        changeRole: (state, action: PayloadAction<ROLE>) => {
            state.role = action.payload;
        },
        changeTheme: (state, action: PayloadAction<string>) => {
            state.theme = action.payload;
        },
        updateNotificationCounter: (state, action: PayloadAction<number>) => {
            state.notificationCounter = action.payload;
        },
    },
});

export const { setUser, changeEmail, changeRole, changeTheme, updateNotificationCounter } = userSlice.actions;
export default userSlice.reducer;
