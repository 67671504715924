import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import { useEffect, useState } from "react";
import KatakomButton from "../../../../shared/KatakomButton";
import KatakomTable from "../../../../shared/KatakomTable";
import KatakomModal from "../../../../shared/KatakomModal";
import AplicantsModalComponent from "./AplicantsModalComponentAdvokati";
import { Container, Row } from "../../../style";
import { deleteUcesnikAdvokati } from "../../../../../store/CaseAdvokatiReducer";
import AplicantsModalComponentAdvokatiUverenjeOImovinskomStanju from "./AplicantsModalComponentAdvokatiUverenjeOImovinskomStanju";

const AddAplicantsAdvokati = () => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const dispatch: AppDispatch = useAppDispatch();
    const ucesnici = useSelector((state: RootState) => state.caseAdvokati.ucesnici);
    const [editingUcesnik, setEditingUcesnik] = useState<any>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const vrstaIsprave = useSelector((state: RootState) => state.caseAdvokati.basicInfo.vrstaIspraveId);

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const handleEditUcesnik = (ucesnik: any) => {
        setEditingUcesnik(ucesnik);
        setModalIsOpen(true);
    };

    const handledeleteUcesnik = (ucesnik: any) => {
        dispatch(deleteUcesnikAdvokati(ucesnik.id));
    };

    const columns = [
        { label: "Ид", value: "id" },
        { label: "Име", value: "ime/imePravnogLica" },
        { label: "Презиме", value: "prezime" },
        { label: "Врста лица", value: "vrstaLica" },
        { label: "ЈМБГ/Матични број", value: "jmbg/maticniBroj/brojUpisaURegistarDrzaveSedista/ssn" },
        { label: "", value: "edit" },
        { label: "", value: "delete" },
    ];

    return (
        <Container>
            <KatakomModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
                {vrstaIsprave == "6105" ? (
                    <AplicantsModalComponentAdvokatiUverenjeOImovinskomStanju
                        closeModal={handleCloseModal}
                        ucesnikId={editingUcesnik ? editingUcesnik.id : undefined}
                        setEditingUcesnik={setEditingUcesnik}
                        modalState={modalIsOpen}
                    />
                ) : (
                    <AplicantsModalComponent
                        closeModal={handleCloseModal}
                        ucesnikId={editingUcesnik ? editingUcesnik.id : undefined}
                        setEditingUcesnik={setEditingUcesnik}
                        modalState={modalIsOpen}
                    />
                )}
            </KatakomModal>
            {ucesnici.length !== 0 ? (
                <div style={{ margin: "15px" }}>
                    <KatakomTable columns={columns} data={ucesnici} onEdit={handleEditUcesnik} onDelete={handledeleteUcesnik} />
                </div>
            ) : null}

            <Row style={{ display: "flex", justifyContent: "center" }}>
                <KatakomButton
                    label={"Додај странку"}
                    size="l"
                    style={{ marginTop: "30px" }}
                    onClick={() => {
                        setModalIsOpen(true);
                    }}
                ></KatakomButton>
            </Row>
        </Container>
    );
};

export default AddAplicantsAdvokati;
