import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmailValid, isPasswordValid } from "../../../../utils/utils";
import KatakomButton from "../../../shared/KatakomButton";
import KatakomInput from "../../../shared/KatakomInput";
import KatakomSelect from "../../../shared/KatakomSelect";
import { useSidebar } from "../../../widgets/sidebar/SidebarContext";
import { Card, CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper, Row } from "../../style";
import api from "../../../../services/api";
import KatakomModal from "../../../shared/KatakomModal";
import { SuccessMessageModal } from "../../../customComponent/SuccessMessageModal";
import { ErrorMessageModal } from "../../../customComponent/ErrorMessageModal";
import useMobileCheck from "../../../../hooks/useMobileChecker";
import { ROLE } from "../../../../enums/role";

const CreateAccountLayout: React.FC = () => {
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
    const [tip, setTip] = useState<string>("");
    const [kancelarija, setKancelarija] = useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(true);
    const [tooltipMessage, setTooltipMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("Дошло је до грешке приликом креирања налога");

    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [errorModal, setErrorModal] = useState<boolean>(false);
    const isMobile = useMobileCheck();

    const { sidebar } = useSidebar();
    const navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    };

    const isDisabled = () => {
        if (newPassword === "" || confirmNewPassword === "") {
            setTooltipMessage("Морате унети обавезна поља");
            return true;
        }

        if (!isPasswordValid(newPassword)) {
            setTooltipMessage("Шифра мора да садржи најмање 8 карактера,једно велико слово, један број и један специјални карактер!");
            return true;
        }
        if (newPassword !== confirmNewPassword) {
            setTooltipMessage("Шифре се не подударају");
            return true;
        }

        if (!isEmailValid(email)) {
            setTooltipMessage("Е-пошта није у исправном формату.");
            return true;
        }

        if (!firstName || !lastName || !email || !tip || !kancelarija) {
            setTooltipMessage("Морате унети обавезна поља");
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (isDisabled()) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [newPassword, confirmNewPassword, firstName, lastName, email, tip, kancelarija]);

    const handleSubmit = () => {
        createAccount();
    };

    const resetState = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setNewPassword("");
        setConfirmNewPassword("");
        setKancelarija("");
        setTip("");
    };

    const checkIfAllowed = async () => {
        try {
            const res = await api.get(`api/v1/lawyers-check-if-exists?mb=${kancelarija}`);

            if (!res.data.isExists) {
                setErrorMessage("Aдвокат не постоји у бази података! Проверите унете вредности.");
                setErrorModal(true);
                return false;
            }
            return true;
        } catch (e) {
            setErrorMessage("Aдвокат не постоји у бази података! Проверите унете вредности.");
            setErrorModal(true);
            console.log("error", e);
            return false;
        }
    };

    const createAccount = async () => {
        try {
            if (kancelarija && tip === ROLE.ADVOKAT) {
                const isAllowed = await checkIfAllowed();
                if (!isAllowed) {
                    return;
                }
            }

            const res = await api.post(`register`, {
                firstName,
                lastName,
                email,
                password: newPassword,
                type: tip,
                mb: kancelarija,
            });

            if (res.status !== 200) {
                setErrorModal(true);
            } else {
                setSuccessModal(true);
            }
        } catch (err) {
            setErrorModal(true);
        }
    };

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <KatakomModal isOpen={successModal} setIsOpen={setSuccessModal}>
                <SuccessMessageModal
                    closeModal={() => {
                        setSuccessModal(false);
                        resetState();
                        routeChange("/podesavanja");
                    }}
                    message={"Успешно сте креирали налог!"}
                />
            </KatakomModal>
            <KatakomModal isOpen={errorModal} setIsOpen={setErrorModal}>
                <ErrorMessageModal
                    closeModal={() => {
                        setErrorModal(false);
                    }}
                    message={errorMessage}
                />
            </KatakomModal>

            <CardWrapper>
                <Card>
                    <CardContainer style={{ alignItems: "center" }}>
                        <CardHeader style={{ justifyContent: "center" }}>
                            <CardTitle style={{ marginLeft: 0 }}>КРЕИРАЈ НОВИ НАЛОГ</CardTitle>
                        </CardHeader>
                        <Row style={{ justifyContent: "center" }}>
                            <KatakomInput
                                size={isMobile ? "m" : "l"}
                                type={"text"}
                                value={firstName}
                                name={"firstName"}
                                label="Име"
                                error={false}
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                }}
                            />
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                            <KatakomInput
                                size={isMobile ? "m" : "l"}
                                type={"text"}
                                value={lastName}
                                name={"lastName"}
                                label="Презиме"
                                error={false}
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                }}
                            />
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                            <KatakomInput
                                size={isMobile ? "m" : "l"}
                                type={"text"}
                                value={email}
                                name={"email"}
                                label="Е-пошта"
                                error={false}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                            <KatakomInput
                                size={isMobile ? "m" : "l"}
                                type={"password"}
                                value={newPassword}
                                name={"newPassword"}
                                label="Шифра"
                                error={false}
                                onChange={(e) => {
                                    setNewPassword(e.target.value);
                                }}
                            />
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                            <KatakomInput
                                size={isMobile ? "m" : "l"}
                                type={"password"}
                                value={confirmNewPassword}
                                name={"confirmNewPassword"}
                                label="Потврди шифру"
                                error={false}
                                onChange={(e) => {
                                    setConfirmNewPassword(e.target.value);
                                }}
                            />
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                            <KatakomSelect
                                size={isMobile ? "m" : "l"}
                                value={tip}
                                name={"tip"}
                                label="Тип"
                                error={false}
                                onChange={(e) => {
                                    setTip(e.value);
                                    if (e.value === ROLE.MGSI) {
                                        setKancelarija("55419");
                                    }
                                    if (e.value == ROLE.ADMIN || e.value === ROLE.MOBILE) {
                                        setKancelarija("1");
                                    }
                                    if (e.value === ROLE.ADVOKAT) {
                                        setKancelarija("");
                                    }
                                    if (e.value === ROLE.IZVRSITELJ) {
                                        setKancelarija("5");
                                    }
                                }}
                                options={[
                                    { value: ROLE.ADVOKAT, label: "Адвокат" },
                                    { value: ROLE.MOBILE, label: "Мобилни" },
                                    { value: ROLE.IZVRSITELJ, label: "Извршитељ" },
                                    { value: ROLE.BANK, label: "Банка" },
                                    { value: ROLE.GEODET, label: "Геодет" },
                                    { value: ROLE.NEKRETNINE, label: "Агенција некретнина" },
                                    { value: ROLE.INFOCENTAR, label: "Инфо центар" },
                                    { value: ROLE.OPSTINA, label: "Општина" },
                                ]}
                            />
                        </Row>
                        {tip === ROLE.ADVOKAT ? (
                            <Row style={{ justifyContent: "center" }}>
                                <KatakomInput
                                    size={isMobile ? "m" : "l"}
                                    type={"text"}
                                    value={kancelarija}
                                    name={"kancelarija"}
                                    label="Матични број"
                                    error={false}
                                    onChange={(e) => {
                                        setKancelarija(e.target.value);
                                    }}
                                />
                            </Row>
                        ) : null}
                        <Row style={{ justifyContent: "center", margin: "80px 0 130px 0" }}>
                            <KatakomButton
                                label="Пошаљи"
                                onClick={handleSubmit}
                                style={{ margin: "0 25px" }}
                                disabled={disabled}
                                tooltipMessage={tooltipMessage}
                            />
                            <KatakomButton
                                label="Одустани"
                                onClick={() => {
                                    routeChange("/podesavanja");
                                }}
                                style={{ margin: "0 25px" }}
                                theme="secondary"
                            />
                        </Row>
                    </CardContainer>
                </Card>
            </CardWrapper>
        </LayoutWraper>
    );
};

export default CreateAccountLayout;
