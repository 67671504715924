//@ts-nocheck
import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { themeColors, themeIcon } from "../../constants/constants";
import moment from "moment";
import { applyConditionsAndFilter, conditionsForTereti, conditionsForZabelezbe } from "./pdfUtils";
import { getSluzbaNaziv } from "../../register/sluzba";

const styles = StyleSheet.create({
    document: {
        flexDirection: "column",
        backgroundColor: themeColors.pdfBackgroundColor,
        fontFamily: "Montserrat",
        fontWeight: 400,
        lineHeight: 1.5,
    },
    header: {
        fontSize: 6,
    },
    logoHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    page: {
        flexDirection: "column",
        fontSize: 12,
        backgroundColor: themeColors.pdfBackgroundColor,
        padding: 20,
    },
    body: {
        display: "flex",
        fontSize: 12,
        flexDirection: "column",
        alignItems: "center",
        marginTop: 30,
    },
    title: {
        fontSize: 14,
        fontWeight: 600,
        padding: "3px 0px",
    },
    section: {
        fontSize: 12,
        fontWeight: 600,
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        padding: "3px 0px",
        marginBottom: 5,
        marginTop: 5,
        paddingLeft: 4,
    },
    subSection: {
        fontSize: 10,
        fontWeight: 600,
        borderTop: "1px dotted black",
        borderBottom: "1px dotted black",
        padding: "3px 0px",
        marginBottom: 5,
        marginTop: 5,
        paddingLeft: 4,
    },
    dataWrapper: {},
    key: { fontWeight: 600, minWidth: 250, maxWidth: 250, marginLeft: 4, fontSize: 10 },
    value: { fontWeight: 400, fontSize: 10, minWidth: 300, maxWidth: 300 },
    row: { display: "flex", flexDirection: "row", padding: "1px 0" },
    footnoteWrapper: {
        marginTop: 15,
    },
    footnote: {
        fontWeight: 400,
        fontSize: 8,
    },
    footnoteBold: {
        fontWeight: 600,
        fontSize: 8,
    },
    divider: {
        borderTop: "1px dotted black",
        margin: "6px 0px",
    },
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Regular.ttf`,
    fontWeight: 400,
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Medium.ttf`,
    fontWeight: 500,
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-SemiBold.ttf`,
    fontWeight: 600,
});

Font.register({
    family: "Montserrat",
    src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Bold.ttf`,
    fontWeight: 700,
});

interface GenerateObjekatPDFProps {
    pdfData: any;
    deoParceleData: any;
    objekat: any;
    deoObjekta: any;
}

const GenerateDeoObjekatPDF: React.FC<GenerateObjekatPDFProps> = ({ pdfData, deoParceleData, objekat, deoObjekta }) => {
    const parcela: any = Array.isArray(pdfData.Parcele.Parcela)
        ? pdfData.Parcele.Parcela.find((item: any) => item.PodBrParc === deoParceleData.PodBrParc)
        : pdfData.Parcele.Parcela;

    const sluzba = getSluzbaNaziv(pdfData.OpstinaID, pdfData.KatastarskaOpstinaID)

    const teretiParcele = applyConditionsAndFilter(parcela, conditionsForTereti);
    const zabelezbeParcele = applyConditionsAndFilter(parcela, conditionsForZabelezbe);

    const teretiObjekta = applyConditionsAndFilter(objekat, conditionsForTereti);
    const zabelezbeObjekta = applyConditionsAndFilter(objekat, conditionsForZabelezbe);

    const teretiDeoObjekta = applyConditionsAndFilter(deoObjekta, conditionsForTereti);
    const zabelezbeDeoObjekta = applyConditionsAndFilter(deoObjekta, conditionsForZabelezbe);

    return (
        <Document style={styles.document}>
            <Page style={styles.page}>
                <Text style={styles.header} fixed>
                    {moment().format("DD-MM-YYYY:HH:MM:SS")}
                </Text>
                <View style={styles.logoHeader}>
                    <Image style={{ width: "100px", margin: "10px 0px -5px -20px" }} src={`${themeIcon}/grbSrbije.png`} />
                    <Text>
                        Република Србија
                        {"\n"}
                        Републички геодетски завод,
                        {"\n"}
                        Геодетско-катастарски информациони систем
                        {"\n"}
                    </Text>
                </View>
                <View style={styles.subSection}>
                    <Text style={styles.value}>katastar.rgz.gov.rs/eKatastar | {moment(new Date()).format('DD.MM.YYYY HH:mm:ss')}</Text>
                </View>
                {!!parcela.BrListaNepokretnosti && parcela.BrListaNepokretnosti != 0 ? (
                    <View style={styles.row}>
                        <Text style={styles.key}>*Број листа непокретности: {parcela.BrListaNepokretnosti}</Text>
                    </View>
                ) : null}

                <View style={styles.title}>
                    <Text>Подаци катастра непокретности</Text>
                </View>
                <View>
                    <View style={styles.section}>
                        <Text>Подаци о непокретности</Text>{" "}
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.key}>Матични број општине:</Text>
                        <Text style={styles.value}>{pdfData.OpstinaID}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Општина:</Text>
                        <Text style={styles.value}>{`${pdfData?.Opstina}`}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Матични број катастарске општине:</Text>
                        <Text style={styles.value}>{pdfData.KatastarskaOpstinaID}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Катастарска општина:</Text>
                        <Text style={styles.value}>{pdfData.KatastarskaOpstina}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Датум ажурности:</Text>
                        <Text style={styles.value}>{moment(pdfData.DatumAzurnosti).format('DD-MM-YYYY HH:mm')}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Служба:</Text>
                        <Text style={styles.value}>{sluzba}</Text>
                    </View>
                </View>
                <View>
                    <View style={styles.title}>
                        <Text>1. Подаци о парцели - А лист</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Потес / Улица:</Text>
                        <Text style={styles.value}>{parcela.Ulica}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Број парцеле:</Text>
                        <Text style={styles.value}>{parcela.BrParc}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Подброј парцеле:</Text>
                        <Text style={styles.value}>{parcela.PodBrParc}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Површина m²:</Text>
                        <Text style={styles.value}>{parcela.Povrsina}</Text>
                    </View>

                    <View style={styles.section}>
                        <Text>Подаци о делу парцеле</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.key}>Број дела: </Text>
                        <Text style={styles.value}>{deoParceleData.BrDelaParc}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Врста земљишта: </Text>
                        <Text style={styles.value}>{deoParceleData.VrstaZemljista}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Култура: </Text>
                        <Text style={styles.value}>{deoParceleData.Kultura}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Површина m²: </Text>
                        <Text style={styles.value}>{deoParceleData.Povrsina}</Text>
                    </View>
                </View>
                <View>
                    <View style={styles.subSection}>
                        <Text>Имаоци права на парцели - Б лист</Text>
                    </View>

                    {Array.isArray(parcela?.ImaociPrava?.Lice) ? (
                        parcela?.ImaociPrava?.Lice.map((lice: any, _index: any) => {
                            return (
                                <View>
                                    <View style={styles.row}>
                                        <Text style={styles.key}>Назив:</Text>
                                        <Text style={styles.value}>
                                            {
                                                typeof lice?.RokPredbelezbe === 'string'
                                                    ? `ПРЕДЛЕЖБА СА РОКОМ ЗА ОПРАВДАЊЕ ДО: ${moment(lice?.RokPredbelezbe).format('DD-MM-YYYY')} ГОДИНЕ\n` : ''
                                            }
                                            {lice?.Naziv ?? `${lice?.Prezime} (${lice?.ImeRoditelja}) ${lice?.Ime}`}
                                        </Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.key}>Адреса:</Text>
                                        <Text style={styles.value}>
                                            {lice.Mesto} {lice.Adresa !== " /" ? `, ${lice.Adresa.slice(0, -1)}` : null}
                                        </Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.key}>Матични број лица:</Text>
                                        <Text>{lice?.MaticniBroj}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.key}>Врста права:</Text>
                                        <Text style={styles.value}>{lice?.VrstaPrava}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.key}>Облик својине:</Text>
                                        <Text style={styles.value}>{lice?.OblikSvojine}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.key}>Удео:</Text>
                                        <Text style={styles.value}>{lice?.Udeo}</Text>
                                    </View>
                                    {_index !== parcela?.ImaociPrava?.Lice?.length - 1 ? <View style={styles.divider}></View> : null}
                                </View>
                            );
                        })
                    ) : //FIXME: THIS IS THE STUPIEDES EDGA CASE THAT I NEEDED TO HANDLE AND DESTROY CODE SINCE FOR SOME REASON THERE CAN EXIST PARCELA WITH MULTIPLE PARTS WHICH JUST DOUBLES ALL THE DATA, AND WE NEED TO SHOW IT DOUBLED. WHEREVER U SEE THIS THAT'S THE REASOn
                        !Array.isArray(parcela) ? (
                            <View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Назив:</Text>
                                    <Text style={styles.value}>
                                        {
                                            typeof parcela?.ImaociPrava?.Lice?.RokPredbelezbe === 'string'
                                                ? `ПРЕДЛЕЖБА СА РОКОМ ЗА ОПРАВДАЊЕ ДО: ${moment(parcela?.ImaociPrava?.Lice?.RokPredbelezbe).format('DD-MM-YYYY')} ГОДИНЕ\n` : ''
                                        }
                                        {parcela?.ImaociPrava?.Lice?.Naziv ??
                                            `${parcela?.ImaociPrava?.Lice?.Prezime} (${parcela?.ImaociPrava?.Lice?.ImeRoditelja}) ${parcela?.ImaociPrava?.Lice?.Ime}`}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Адреса:</Text>
                                    <Text style={styles.value}>
                                        {parcela?.ImaociPrava?.Lice?.Mesto}{" "}
                                        {parcela?.ImaociPrava?.Lice?.Adresa !== " /"
                                            ? `, ${parcela?.ImaociPrava?.Lice?.Adresa.slice(0, -1)}`
                                            : null}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Матични број лица:</Text>
                                    <Text>{parcela?.ImaociPrava?.Lice?.MaticniBroj}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Врста права:</Text>
                                    <Text style={styles.value}>{parcela?.ImaociPrava?.Lice?.VrstaPrava}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Облик својине:</Text>
                                    <Text style={styles.value}>{parcela?.ImaociPrava?.Lice?.OblikSvojine}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Удео:</Text>
                                    <Text style={styles.value}>{parcela?.ImaociPrava?.Lice?.Udeo}</Text>
                                </View>
                            </View>
                        ) : Array.isArray(parcela?.[0]?.ImaociPrava?.Lice) ? (
                            parcela?.[0]?.ImaociPrava?.Lice.map((lice: any, _index: any) => {
                                return (
                                    <View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Назив:</Text>
                                            <Text style={styles.value}>
                                                {
                                                    typeof lice?.RokPredbelezbe === 'string'
                                                        ? `ПРЕДЛЕЖБА СА РОКОМ ЗА ОПРАВДАЊЕ ДО: ${moment(lice?.RokPredbelezbe).format('DD-MM-YYYY')} ГОДИНЕ\n` : ''
                                                }
                                                {lice?.Naziv ?? `${lice?.Prezime} (${lice?.ImeRoditelja}) ${lice?.Ime}`}
                                            </Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Адреса:</Text>
                                            <Text style={styles.value}>
                                                {lice.Mesto} {lice.Adresa !== " /" ? `, ${lice.Adresa.slice(0, -1)}` : null}
                                            </Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Матични број лица:</Text>
                                            <Text>{lice?.MaticniBroj}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Врста права:</Text>
                                            <Text style={styles.value}>{lice?.VrstaPrava}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Облик својине:</Text>
                                            <Text style={styles.value}>{lice?.OblikSvojine}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Удео:</Text>
                                            <Text style={styles.value}>{lice?.Udeo}</Text>
                                        </View>
                                        {_index !== parcela?.[0]?.ImaociPrava?.Lice?.length - 1 ? <View style={styles.divider}></View> : null}
                                    </View>
                                );
                            })
                        ) : (
                            <View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Назив:</Text>
                                    <Text style={styles.value}>
                                        {
                                            typeof parcela?.[0]?.ImaociPrava?.Lice?.RokPredbelezbe === 'string'
                                                ? `ПРЕДЛЕЖБА СА РОКОМ ЗА ОПРАВДАЊЕ ДО: ${moment(parcela?.[0]?.ImaociPrava?.Lice?.RokPredbelezbe).format('DD-MM-YYYY')} ГОДИНЕ\n` : ''
                                        }
                                        {parcela?.[0]?.ImaociPrava?.Lice?.Naziv ??
                                            `${parcela?.[0]?.ImaociPrava?.Lice?.Prezime} (${parcela?.[0]?.ImaociPrava?.Lice?.ImeRoditelja}) ${parcela?.[0]?.ImaociPrava?.Lice?.Ime}`}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Адреса:</Text>
                                    <Text style={styles.value}>
                                        {parcela?.[0]?.ImaociPrava?.Lice?.Mesto}{" "}
                                        {parcela?.[0]?.ImaociPrava?.Lice?.Adresa !== " /"
                                            ? `, ${parcela?.[0]?.ImaociPrava?.Lice?.Adresa.slice(0, -1)}`
                                            : null}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Матични број лица:</Text>
                                    <Text>{parcela?.[0]?.ImaociPrava?.Lice?.MaticniBroj}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Врста права:</Text>
                                    <Text style={styles.value}>{parcela?.[0]?.ImaociPrava?.Lice?.VrstaPrava}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Облик својине:</Text>
                                    <Text style={styles.value}>{parcela?.[0]?.ImaociPrava?.Lice?.OblikSvojine}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Удео:</Text>
                                    <Text style={styles.value}>{parcela?.[0]?.ImaociPrava?.Lice?.Udeo}</Text>
                                </View>
                            </View>
                        )}
                </View>
                <View>
                    <View style={styles.subSection}>
                        <Text>Терети на парцели - Г лист</Text>
                    </View>
                    {teretiParcele?.length > 0 ? (
                        <>
                            {teretiParcele.map((teret: any, index: any) => (
                                <React.Fragment key={index}>
                                    <>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Врста терета:</Text>
                                            <Text style={styles.value}>{teret?.Vrsta}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Датум уписа:</Text>
                                            <Text style={styles.value}>
                                                {typeof teret.DatumUpisa === "string" ? moment(teret.DatumUpisa).format('DD-MM-YYYY HH:mm') : null}
                                            </Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Трајање терета:</Text>
                                            <Text style={styles.value}>
                                                {typeof teret.TrajanjeTereta === "string" ? teret.TrajanjeTereta : null}
                                            </Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Датум престанка:</Text>
                                            <Text style={styles.value}>
                                                {typeof teret.DatumPrestanka === "string" ? teret.DatumPrestanka : null}
                                            </Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Опис терета:</Text>
                                            <Text style={styles.value}>{teret.Opis}</Text>
                                        </View>
                                        {teretiParcele?.length !== index + 1 ? <View style={styles.divider}></View> : null}
                                    </>
                                </React.Fragment>
                            ))}
                        </>
                    ) : (
                        <View style={styles.row}>
                            <Text style={styles.value}>*** Нема терета ***</Text>
                        </View>
                    )}
                </View>
                <View>
                    <View style={styles.subSection}>
                        <Text>Напомена (терет парцела)</Text>
                    </View>

                    {zabelezbeParcele?.length > 0 ? (
                        <>
                            {zabelezbeParcele.map((teret: any, index: any) => (
                                <React.Fragment key={index}>
                                    <>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Датум:</Text>
                                            <Text style={styles.value}>
                                                {typeof teret.DatumUpisa === "string" ? moment(teret.DatumUpisa).format('DD-MM-YYYY HH:mm') : null}
                                            </Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Опис:</Text>
                                            <Text style={styles.value}>{teret.Opis}</Text>
                                        </View>
                                        {zabelezbeParcele?.length !== index + 1 ? <View style={styles.divider}></View> : null}
                                    </>
                                </React.Fragment>
                            ))}
                        </>
                    ) : (
                        <View style={styles.row}>
                            <Text style={styles.value}>*** Нема терета ***</Text>
                        </View>
                    )}
                    <View style={styles.title}>
                        <Text> 2. Подаци о зградама и другим грађевинским објектима - В1 лист</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.key}>Број објекта: </Text>
                        <Text style={styles.value}>{deoParceleData.BrDelaParc}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Назив улице: </Text>
                        <Text style={styles.value}>{objekat.Ulica}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Кућни број: </Text>
                        <Text style={styles.value}>{objekat.KucniBroj}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Кућни подброј: </Text>
                        <Text style={styles.value}>{objekat.KucniPodbroj}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Површина m²: </Text>
                        <Text style={styles.value}>{objekat.Povrsina}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Корисна површина m²: </Text>
                        <Text style={styles.value}>{objekat.PovrsinaKorisna}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Грађевинска површина m²: </Text>
                        <Text style={styles.value}>
                            {objekat.PovrsinaGradjevinska > 0 ? objekat.PovrsinaGradjevinska : "ПОВРШИНА НИЈЕ ЕВИДЕНТИРАНА"}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Начин коришћења и назив објекта: </Text>
                        <Text style={styles.value}>{objekat.NacinKoriscenja}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Правни статус објекта: </Text>
                        <Text style={styles.value}>{objekat.PravniStatus}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Број етажа под земљом: </Text>
                        <Text style={styles.value}>{objekat.BrEtazaPodzemnih !== "" ? objekat.BrEtazaPodzemnih : ""}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Број етажа у приземљу: </Text>
                        <Text style={styles.value}>{objekat.BrEtazaPrizemnih !== "" ? objekat.BrEtazaPrizemnih : ""}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Број етажа над земљом: </Text>
                        <Text style={styles.value}>{objekat.BrEtazaNadzemnih !== "" ? objekat.BrEtazaNadzemnih : ""}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Број етажа у поткровљу: </Text>
                        <Text style={styles.value}>{objekat.BrEtazaPotkrovnih !== "" ? objekat.BrEtazaPotkrovnih : ""}</Text>
                    </View>

                    <View>
                        <View style={styles.subSection}>
                            <Text>Имаоци права на објекту</Text>
                        </View>

                        {Array.isArray(objekat?.ImaociPrava?.Lice) ? (
                            objekat?.ImaociPrava?.Lice.map((lice: any, _index: any) => {
                                return (
                                    <View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Назив:</Text>
                                            <Text style={styles.value}>
                                                {
                                                    typeof lice?.RokPredbelezbe === 'string'
                                                        ? `ПРЕДЛЕЖБА СА РОКОМ ЗА ОПРАВДАЊЕ ДО: ${moment(lice?.RokPredbelezbe).format('DD-MM-YYYY')} ГОДИНЕ\n` : ''
                                                }
                                                {lice?.Naziv ?? `${lice?.Prezime} (${lice?.ImeRoditelja}) ${lice?.Ime}`}
                                            </Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Адреса:</Text>
                                            <Text style={styles.value}>
                                                {lice.Mesto} {lice.Adresa !== " /" ? `, ${lice.Adresa.slice(0, -1)}` : null}
                                            </Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Матични број лица:</Text>
                                            <Text style={styles.value}>{lice?.MaticniBroj}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Врста права:</Text>
                                            <Text style={styles.value}>{lice?.VrstaPrava}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Облик својине:</Text>
                                            <Text style={styles.value}>{lice?.OblikSvojine}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Удео:</Text>
                                            <Text style={styles.value}>{lice?.Udeo}</Text>
                                        </View>

                                        {_index !== objekat?.ImaociPrava?.Lice?.length - 1 ? <View style={styles.divider}></View> : null}
                                    </View>
                                );
                            })
                        ) : (
                            <View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Назив:</Text>
                                    <Text style={styles.value}>
                                        {
                                            typeof objekat?.ImaociPrava?.Lice?.RokPredbelezbe === 'string'
                                                ? `ПРЕДЛЕЖБА СА РОКОМ ЗА ОПРАВДАЊЕ ДО: ${moment(objekat?.ImaociPrava?.Lice?.RokPredbelezbe).format('DD-MM-YYYY')} ГОДИНЕ\n` : ''
                                        }
                                        {objekat?.ImaociPrava?.Lice?.Naziv ??
                                            `${objekat?.ImaociPrava?.Lice?.Prezime} (${objekat?.ImaociPrava?.Lice?.ImeRoditelja}) ${objekat?.ImaociPrava?.Lice?.Ime}`}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Адреса:</Text>
                                    <Text style={styles.value}>
                                        {objekat?.ImaociPrava?.Lice?.Mesto}{" "}
                                        {objekat?.ImaociPrava?.Lice?.Adresa !== " /"
                                            ? `, ${objekat?.ImaociPrava?.Lice?.Adresa.slice(0, -1)}`
                                            : null}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Матични број лица:</Text>
                                    <Text style={styles.value}>{objekat?.ImaociPrava?.Lice?.MaticniBroj}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Врста права:</Text>
                                    <Text style={styles.value}>{objekat?.ImaociPrava?.Lice?.VrstaPrava}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Облик својине:</Text>
                                    <Text style={styles.value}>{objekat?.ImaociPrava?.Lice?.OblikSvojine}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Удео:</Text>
                                    <Text style={styles.value}>{objekat?.ImaociPrava?.Lice?.Udeo}</Text>
                                </View>
                            </View>
                        )}
                    </View>

                    <View>
                        <View style={styles.subSection}>
                            <Text>Терети на објекту - Г лист</Text>
                        </View>
                        {teretiObjekta?.length > 0 ? (
                            <>
                                {teretiObjekta.map((teret: any, index: any) => (
                                    <React.Fragment key={index}>
                                        <>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Врста терета:</Text>
                                                <Text style={styles.value}>{teret?.Vrsta}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Датум уписа:</Text>
                                                <Text style={styles.value}>
                                                    {typeof teret.DatumUpisa === "string" ? moment(teret.DatumUpisa).format('DD-MM-YYYY HH:mm') : null}
                                                </Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Трајање терета:</Text>
                                                <Text style={styles.value}>
                                                    {typeof teret.TrajanjeTereta === "string" ? teret.TrajanjeTereta : null}
                                                </Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Датум престанка:</Text>
                                                <Text style={styles.value}>
                                                    {typeof teret.DatumPrestanka === "string" ? teret.DatumPrestanka : null}
                                                </Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Опис терета:</Text>
                                                <Text style={styles.value}>{teret.Opis}</Text>
                                            </View>
                                            {teretiObjekta?.length !== index + 1 ? <View style={styles.divider}></View> : null}
                                        </>
                                    </React.Fragment>
                                ))}
                            </>
                        ) : (
                            <View style={styles.row}>
                                <Text style={styles.value}>*** Нема терета ***</Text>
                            </View>
                        )}
                    </View>
                    <View>
                        <View style={styles.subSection}>
                            <Text>Напомена (терет објекат)</Text>
                        </View>
                        {zabelezbeObjekta?.length > 0 ? (
                            <>
                                {zabelezbeObjekta.map((teret: any, index: any) => (
                                    <React.Fragment key={index}>
                                        <>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Датум:</Text>
                                                <Text style={styles.value}>
                                                    {typeof teret.DatumUpisa === "string" ? moment(teret.DatumUpisa).format('DD-MM-YYYY HH:mm') : null}
                                                </Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Опис:</Text>
                                                <Text style={styles.value}>{teret.Opis}</Text>
                                            </View>
                                            {zabelezbeObjekta?.length !== index + 1 ? <View style={styles.divider}></View> : null}
                                        </>
                                    </React.Fragment>
                                ))}
                            </>
                        ) : (
                            <View style={styles.row}>
                                <Text style={styles.value}>*** Нема терета ***</Text>
                            </View>
                        )}
                    </View>
                </View>

                <View>
                    <View style={styles.title}>
                        <Text> 3. Подаци о посебном делу објекта - В2 лист</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.key}>Број објекта: </Text>
                        <Text style={styles.value}>{deoParceleData.BrDelaParc}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Назив улице: </Text>
                        <Text style={styles.value}>{deoObjekta.Ulica}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Број улаза: </Text>
                        <Text style={styles.value}>{deoObjekta.BrojUlaza}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Евид. број: </Text>
                        <Text style={styles.value}>{deoObjekta.EvidencijskiBroj}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Начин коришћења: </Text>
                        <Text style={styles.value}>{deoObjekta.NacinKoriscenja}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Број посебног дела: </Text>
                        <Text style={styles.value}>{deoObjekta?.BrojStana}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Подброј посебног дела: </Text>
                        <Text style={styles.value}>{deoObjekta?.PodbrojPosebnogDela}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Спратност: </Text>
                        <Text style={styles.value}>{deoObjekta.BrojSprata}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Собност: </Text>
                        <Text style={styles.value}>{deoObjekta.BrojSoba}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Грађевинска пов. m²: </Text>
                        <Text style={styles.value}>
                            {deoObjekta.PovrsinaGradjevinska > 0 ? deoObjekta.PovrsinaGradjevinska : "ПОВРШИНА НИЈЕ ЕВИДЕНТИРАНА"}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Корисна пов. m²: </Text>
                        <Text style={styles.value}>{deoObjekta.PovrsinaKorisna}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.key}>Начин утврђивања кор.пов.: </Text>
                        <Text style={styles.value}>{deoObjekta.NacinUtvrdjivanjaKorPovrsine}</Text>
                    </View>

                    <View>
                        <View style={styles.subSection}>
                            <Text>Имаоци права на посебном делу објекту</Text>
                        </View>

                        {Array.isArray(deoObjekta?.ImaociPrava?.Lice) ? (
                            deoObjekta?.ImaociPrava?.Lice.map((lice: any, _index: any) => {
                                return (
                                    <View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Назив:</Text>
                                            <Text style={styles.value}>
                                                {
                                                    typeof lice?.RokPredbelezbe === 'string'
                                                        ? `ПРЕДЛЕЖБА СА РОКОМ ЗА ОПРАВДАЊЕ ДО: ${moment(lice?.RokPredbelezbe).format('DD-MM-YYYY')} ГОДИНЕ\n` : ''
                                                }
                                                {lice?.Naziv ?? `${lice?.Prezime} (${lice?.ImeRoditelja}) ${lice?.Ime}`}
                                            </Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Матични број лица:</Text>
                                            <Text style={styles.value}>{lice?.MaticniBroj}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Адреса:</Text>
                                            <Text style={styles.value}>
                                                {lice.Mesto}{" "}
                                                {lice.Adresa !== " /"
                                                    ? `, ${lice.Adresa.slice(0, -1)} ${deoObjekta?.BrojStana ?? ""}`
                                                    : null}
                                            </Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Врста права:</Text>
                                            <Text style={styles.value}>{lice?.VrstaPrava}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Облик својине:</Text>
                                            <Text style={styles.value}>{lice?.OblikSvojine}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.key}>Удео:</Text>
                                            <Text style={styles.value}>{lice?.Udeo}</Text>
                                        </View>

                                        {_index !== deoObjekta?.ImaociPrava?.Lice?.length - 1 ? <View style={styles.divider}></View> : null}
                                    </View>
                                );
                            })
                        ) : (
                            <View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Назив:</Text>
                                    <Text style={styles.value}>
                                        {
                                            typeof deoObjekta?.ImaociPrava?.Lice?.RokPredbelezbe === 'string'
                                                ? `ПРЕДЛЕЖБА СА РОКОМ ЗА ОПРАВДАЊЕ ДО: ${moment(deoObjekta?.ImaociPrava?.Lice?.RokPredbelezbe).format('DD-MM-YYYY')} ГОДИНЕ\n` : ''
                                        }
                                        {deoObjekta?.ImaociPrava?.Lice?.Naziv ??
                                            `${deoObjekta?.ImaociPrava?.Lice?.Prezime} (${deoObjekta?.ImaociPrava?.Lice?.ImeRoditelja}) ${deoObjekta?.ImaociPrava?.Lice?.Ime}`}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Адреса:</Text>
                                    <Text style={styles.value}>
                                        {deoObjekta?.ImaociPrava?.Lice?.Mesto}{" "}
                                        {deoObjekta?.ImaociPrava?.Lice?.Adresa !== " /"
                                            ? `, ${deoObjekta?.ImaociPrava?.Lice?.Adresa.slice(0, -1)} ${deoObjekta?.BrojStana ?? ""}`
                                            : null}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Матични број лица:</Text>
                                    <Text style={styles.value}>{deoObjekta?.ImaociPrava?.Lice?.MaticniBroj}</Text>{" "}
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Врста права:</Text>
                                    <Text style={styles.value}>{deoObjekta?.ImaociPrava?.Lice?.VrstaPrava}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Облик својине:</Text>
                                    <Text style={styles.value}>{deoObjekta?.ImaociPrava?.Lice?.OblikSvojine}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.key}>Удео:</Text>
                                    <Text style={styles.value}>{deoObjekta?.ImaociPrava?.Lice?.Udeo}</Text>
                                </View>
                            </View>
                        )}
                    </View>

                    <View>
                        <View style={styles.subSection}>
                            <Text>Терети посебног дела - Г лист</Text>
                        </View>
                        {teretiDeoObjekta?.length > 0 ? (
                            <>
                                {teretiDeoObjekta.map((teret: any, index: any) => (
                                    <React.Fragment key={index}>
                                        <>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Врста терета:</Text>
                                                <Text style={styles.value}>{teret?.Vrsta}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Датум уписа:</Text>
                                                <Text style={styles.value}>
                                                    {typeof teret.DatumUpisa === "string" ? moment(teret.DatumUpisa).format('DD-MM-YYYY HH:mm') : null}
                                                </Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Трајање терета:</Text>
                                                <Text style={styles.value}>
                                                    {typeof teret.TrajanjeTereta === "string" ? teret.TrajanjeTereta : null}
                                                </Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Датум престанка:</Text>
                                                <Text style={styles.value}>
                                                    {typeof teret.DatumPrestanka === "string" ? teret.DatumPrestanka : null}
                                                </Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Опис терета:</Text>
                                                <Text style={styles.value}>{teret.Opis}</Text>
                                            </View>
                                            {teretiDeoObjekta?.length !== index + 1 ? <View style={styles.divider}></View> : null}
                                        </>
                                    </React.Fragment>
                                ))}
                            </>
                        ) : (
                            <View style={styles.row}>
                                <Text style={styles.value}>*** Нема терета ***</Text>
                            </View>
                        )}
                    </View>
                    <View>
                        <View style={styles.subSection}>
                            <Text>Напомена (терет посебног дела)</Text>
                        </View>
                        {zabelezbeDeoObjekta?.length > 0 ? (
                            <>
                                {zabelezbeDeoObjekta.map((teret: any, index: any) => (
                                    <React.Fragment key={index}>
                                        <>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Датум:</Text>
                                                <Text style={styles.value}>
                                                    {typeof teret.DatumUpisa === "string" ? moment(teret.DatumUpisa).format('DD-MM-YYYY HH:mm') : null}
                                                </Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.key}>Опис:</Text>
                                                <Text style={styles.value}>{teret.Opis}</Text>
                                            </View>
                                            {zabelezbeDeoObjekta?.length !== index + 1 ? <View style={styles.divider}></View> : null}
                                        </>
                                    </React.Fragment>
                                ))}
                            </>
                        ) : (
                            <View style={styles.row}>
                                <Text style={styles.value}>*** Нема терета ***</Text>
                            </View>
                        )}
                    </View>
                </View>

                <View wrap={false} style={styles.footnoteWrapper}>
                    <Text style={styles.footnote}>* Извод из базе података катастра непокретности.</Text>
                    <Text style={styles.footnoteBold}>
                        НАПОМЕНА: Сходно члану 18. Закона о републичким административним таксама и члану 6. Уредбе о условима издавања
                        извода из листа непокретности и листа вода из ГКИС-а, без накнаде се издаје извод из листа непокретности: органима,
                        организацијама и институцијама Републике Србије, аутономних покрајина, односно јединица локалне самоуправе,
                        организацијама обавезног социјалног осигурања, установама основаних од стране Републике Србије, аутономних
                        покрајина, односно јединица локалне самоуправе, Црквама и верским заједницама, Црвеном крсту Србије,
                        дипломатско-конзуларним представништвима страних држава, под условом узајамности, јавним бележницима и геодетским
                        организацијама, и исти се дигитално преузимају преко сервисне магистрале државних органа и електронских сервиса
                        РГЗ-а.
                    </Text>
                </View>
            </Page>
        </Document>
    );
};

export default GenerateDeoObjekatPDF;
