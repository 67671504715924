import React, { useState } from "react";
import styled from "styled-components";
import { themeColors, themeIcon } from "../../../constants/constants";
import { useSidebar } from "../sidebar/SidebarContext";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ROLE } from "../../../enums/role";

const NavbarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    background-color: ${themeColors.navbarBackgroundColor};
    position: fixed;
    padding: 0 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    z-index: 100;
    @media screen and (max-width: 900px) {
        padding: 0 10px;
    }
`;

const IconsGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 60px;
`;

const IconContainer = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    margin: 8px;

    @media screen and (max-width: 900px) {
        margin: 0px;
    }

    img {
        width: 40px;
    }

    &:focus {
        border-radius: 10px;
        background-color: ${themeColors.iconContainerBackgroundColorFocuse};
    }
`;

const SettingsContainer = styled.button`
    position: relative;
    border: none;
    cursor: pointer;
    outline: none;
    margin: 8px;

    img {
        width: 25px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const LogoContainer = styled.div`
    margin-left: 10px;
    cursor: pointer;
    img {
        width: 60px;
    }
`;

const BackgroundCircle = styled.div<{ isFocused: boolean }>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${(props) =>
        props.isFocused ? themeColors.backgroundCircleBackgroundColorFocused : themeColors.backgroundCircleBackgroundColorNotFocused};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const NotificationCircle = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    font-size: 15px;
    color: ${themeColors.notificationCircleColor};
    background-color: ${themeColors.notificationCircleBackgroundColor};
    position: absolute;
    margin-top: -25px;
    margin-left: 5px;
`;

const Spacing = styled.div`
    margin-right: 30px;
`;

const UserSettings = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 75px;
    width: 300px;
    background-color: ${themeColors.userSettingsBackgroundColor};
    border-radius: 5px;
    box-shadow: -5px 5px 10px 0px #00000040;
    padding-bottom: 10px;
`;

const UserContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${themeColors.userContainerBackgroundColor};
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-bottom: 15px;
`;

const UserImg = styled.div`
    display: flex;
    flex-direction: column;
    margin: 17px;
    img {
        width: 55px;
    }
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin: 17px 0px;
    font-size: 14px;
    color: ${themeColors.userInfoColor};
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 20px;
    font-size: 14px;
    color: ${themeColors.navbarRowColor};
    cursor: pointer;

    img {
        width: 15px;
        padding-right: 8px;
    }
`;

const Separator = styled.div`
    height: 2px;
    background-color: ${themeColors.navbarSeparatorBackgroundColor};
    margin: 15px 20px;
`;

const Navbar: React.FC = () => {
    let navigate = useNavigate();
    const { showSidebar } = useSidebar();
    const location = useLocation();
    const [isUserInFocus, setIsUserInFocus] = useState(false);
    const [isNotificationInFocus, setIsNotificationInFocus] = useState(false);
    const isNotificationPath = location.pathname === "/obavestenja";
    const user = useSelector((state: RootState) => state.user);

    const routeChange = (path: string) => {
        navigate(path);
    };

    const logout = () => {
        routeChange("/login");
        Cookies.remove("accessToken");
        localStorage.removeItem("isVisible");
        localStorage.removeItem("email");
    };

    return (
        <NavbarContainer>
            <IconsGroup>
                <IconContainer
                    onClick={(e) => {
                        e.currentTarget.blur();
                        showSidebar();
                    }}
                    tabIndex={150}
                >
                    <img src={`${themeIcon}/menu.png`} alt="Menu" />
                </IconContainer>
                <LogoContainer
                    onClick={() => {
                        routeChange("/pocetna");
                    }}
                    tabIndex={151}
                >
                    <img src={`${themeIcon}/logo.png`} alt="Logo" />
                </LogoContainer>
            </IconsGroup>
            <IconsGroup>
                <SettingsContainer
                    onClick={() => {
                        if (window.location.hash === "#/obavestenja") {
                            // If it is, go back in history
                            window.history.back();
                        } else {
                            // If it's not, change the route to "/obavestenja"
                            routeChange("/obavestenja");
                        }
                    }}
                    onFocus={() => setIsNotificationInFocus(true)}
                    onBlur={() => setIsNotificationInFocus(false)}
                    tabIndex={152}
                >
                    <BackgroundCircle isFocused={isNotificationInFocus || isNotificationPath} />
                    {user.notificationCounter && user.notificationCounter > 0 ? (
                        <NotificationCircle>{Number(user.notificationCounter) > 99 ? "99" : user.notificationCounter}</NotificationCircle>
                    ) : null}
                    {isNotificationInFocus || isNotificationPath ? (
                        <img src={`${themeIcon}/notificationLight.png`} alt="Notification button" />
                    ) : (
                        <img src={`${themeIcon}/notificationDark.png`} alt="Notification button" />
                    )}
                </SettingsContainer>
                <Spacing />
                <SettingsContainer onClick={() => setIsUserInFocus(!isUserInFocus)} tabIndex={153}>
                    <BackgroundCircle isFocused={isUserInFocus} />
                    {isUserInFocus ? (
                        <img src={`${themeIcon}/userLight.png`} alt="User button" />
                    ) : (
                        <img src={`${themeIcon}/userDark.png`} alt="User button" />
                    )}
                </SettingsContainer>

                {isUserInFocus ? (
                    <UserSettings>
                        <UserContainer>
                            <UserImg>
                                <img src={`${themeIcon}/userAvatar.png`} alt="User avatar" />
                            </UserImg>
                            <UserInfo>
                                <div style={{ fontSize: "16px", marginBottom: "2px" }}>{`${user.firstName} ${user.lastName}`}</div>
                                <div style={{ fontSize: "14px", marginBottom: "4px" }}>Број претрага: {user.brojPretraga}</div>
                                <div style={{ fontSize: "12px" }}>{user.email}</div>
                            </UserInfo>
                        </UserContainer>
                        <Row
                            onClick={() => {
                                routeChange("/podesavanja");
                            }}
                        >
                            <img src={`${themeIcon}/userSettings.png`} alt="User settings" />
                            Подешавање профила
                        </Row>
                        {user.role === ROLE.ADMIN || user.role === ROLE.SUPERADMIN || user.role === ROLE.ADMIN_BANK ? (
                            <Row
                                onClick={() => {
                                    routeChange("/upravljanjeKorisnicima");
                                }}
                            >
                                <img src={`${themeIcon}/userAnalytics.png`} alt="User analytics" />
                                Управљање корисницима
                            </Row>
                        ) : null}
                        <Separator />
                        <Row onClick={logout}>
                            <img src={`${themeIcon}/userLogout.png`} alt="User logout" />
                            Одјавите се
                        </Row>
                    </UserSettings>
                ) : null}
            </IconsGroup>
        </NavbarContainer>
    );
};

export default Navbar;
