import KatakomInput from "../../../shared/KatakomInput";
import DatePicker from "../../../shared/DataPicker";
import KatakomSelect from "../../../shared/KatakomSelect";
import { sudOptions, upisnikOptions, vrstaUgovoraOptions } from "../../../../register/register";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../store";
import { updateBasicInfo } from "../../../../store/CaseReducer";
import { Container, Row } from "../../style";

type Props = {
    setCurrentStep: () => void;
};

const AddBasicInfo: React.FC<Props> = ({ setCurrentStep }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const basicInfo = useSelector((state: RootState) => state.case.basicInfo);

    return (
        <Container>
            <Row>
                <DatePicker
                    value={basicInfo.datumUgovora}
                    name="date"
                    label="Датум"
                    error={false}
                    disabled={false}
                    onChange={(e) => {
                        const selectedDate = e.target.value;
                        dispatch(updateBasicInfo({ datumUgovora: selectedDate }));
                    }}
                    style={{ margin: "0 1% 0 3%" }}
                />
                <KatakomSelect
                    label="Уписник"
                    value={basicInfo.upisnik.value}
                    name={"upisnik"}
                    error={false}
                    style={{ margin: "0 1% 0 3%" }}
                    options={upisnikOptions}
                    onChange={(e) => {
                        dispatch(updateBasicInfo({ upisnik: e }));
                    }}
                />
                <KatakomInput
                    label="Број предмета извршитеља"
                    type={"text"}
                    value={basicInfo.brojOvereUgovora}
                    name={"brojOvereUgovora"}
                    error={false}
                    onChange={(e) => {
                        dispatch(updateBasicInfo({ brojOvereUgovora: Number(e.target.value) }));
                    }}
                    style={{ margin: "0 1% 0 3%" }}
                    onEnter={() => {
                        setCurrentStep();
                    }}
                />
            </Row>
            <Row>
                <KatakomInput
                    label="Година"
                    type={"text"}
                    value={basicInfo.godinaOvereUgovora}
                    name={"godinaOvereUgovora"}
                    error={false}
                    onChange={(e) => {
                        dispatch(updateBasicInfo({ godinaOvereUgovora: Number(e.target.value) }));
                    }}
                    style={{ margin: "0 1% 0 3%" }}
                    onEnter={() => {
                        setCurrentStep();
                    }}
                />
                <KatakomSelect
                    label="Врста акта"
                    value={basicInfo.vrstaIspraveId.value}
                    name={"vrstaIspraveId"}
                    error={false}
                    style={{ margin: "0 1% 0 3%" }}
                    options={vrstaUgovoraOptions}
                    onChange={(e) => {
                        dispatch(updateBasicInfo({ vrstaIspraveId: e }));
                    }}
                />
                <KatakomSelect
                    options={sudOptions}
                    label="Суд"
                    value={basicInfo.maticniBrojSuda.value}
                    name={"maticniBrojSuda"}
                    error={false}
                    style={{ margin: "0 1% 0 3%" }}
                    onChange={(e) => {
                        dispatch(updateBasicInfo({ maticniBrojSuda: e }));
                    }}
                />
            </Row>
            <Row>
                <KatakomInput
                    label="Број предмета суда"
                    type={"text"}
                    value={basicInfo.brojPredmetaSuda}
                    name={"brojPredmetaSuda"}
                    error={false}
                    onChange={(e) => {
                        dispatch(updateBasicInfo({ brojPredmetaSuda: e.target.value }));
                    }}
                    style={{ margin: "0 1% 0 3%" }}
                    onEnter={() => {
                        setCurrentStep();
                    }}
                />
            </Row>
        </Container>
    );
};

export default AddBasicInfo;
