import { mandatoryDocuments } from "../register/register";
import { CaseAdvokatiState } from "../store/CaseAdvokatiReducer";
import { CaseState } from "../store/CaseReducer";
import { CaseTip2State } from "../store/CaseTip2Reducer";

export const checkStepValidation = (currentStep: number, slucaj: CaseState) => {
    const isDisabled = () => {
        if (
            currentStep >= 1 &&
            (slucaj.basicInfo.datumUgovora === "" ||
                slucaj.basicInfo.upisnik.value === "" ||
                !slucaj.basicInfo.brojOvereUgovora ||
                !slucaj.basicInfo.godinaOvereUgovora ||
                slucaj.basicInfo.vrstaIspraveId.value === "" ||
                !slucaj.basicInfo.maticniBrojSuda.value ||
                slucaj.basicInfo.brojPredmetaSuda === "")
        ) {
            return true;
        }
        if (currentStep >= 2 && (slucaj.nepokretnostKN.length === 0 || slucaj.ucesnici.length === undefined)) {
            return true;
        }
        if (currentStep >= 3 && slucaj.ucesnici.length === 0) {
            return true;
        }
        if (
            (currentStep >= 4 && slucaj.tempDokumenta.length === 0) ||
            (slucaj.tempDokumenta.some((dok) => dok.isSigned === false) && currentStep === 4)
        ) {
            return true;
        }

        return false;
    };

    const disabled = isDisabled();
    const tooltipMessage =
        currentStep === 4 && slucaj.tempDokumenta.some((dok) => dok.isSigned === false)
            ? "Сви фајлови морају бити електронски потписани"
            : currentStep === 4 && slucaj.tempDokumenta.length === 0
            ? "Морате додати најмање један фајл"
            : slucaj.nepokretnostKN.length === 0 && currentStep === 2
            ? "Морате унети барем једну непокретност"
            : slucaj.ucesnici.length === 0 && currentStep === 3
            ? "Морате унети барем једну странку"
            : "Сва обавезна поља морају бити попуњена";

    return { disabled, tooltipMessage };
};

export const checkStepValidationTip2 = (currentStep: number, slucaj: CaseTip2State) => {
    const isDisabled = () => {
        if (
            currentStep >= 1 &&
            (slucaj.basicInfo.datumPotvrde === "" ||
                slucaj.basicInfo.brojPotvrde === "" ||
                slucaj.basicInfo.informacijeOLokacijiBroj === "")
        ) {
            return true;
        }
        if (currentStep >= 2 && (slucaj.nepokretnostKN.length === 0 || slucaj.ucesnici.length === undefined)) {
            return true;
        }
        if (currentStep >= 3 && slucaj.ucesnici.length === 0) {
            return true;
        }
        if (
            (currentStep >= 4 && slucaj.tempDokumenta.length === 0) ||
            (slucaj.tempDokumenta.some((dok) => dok.isSigned === false) && currentStep === 4)
        ) {
            return true;
        }

        return false;
    };

    const disabled = isDisabled();
    const tooltipMessage =
        currentStep === 4 && slucaj.tempDokumenta.some((dok) => dok.isSigned === false)
            ? "Сви фајлови морају бити електронски потписани"
            : currentStep === 4 && slucaj.tempDokumenta.length === 0
            ? "Морате додати најмање један фајл"
            : slucaj.nepokretnostKN.length === 0 && currentStep === 2
            ? "Морате унети барем једну непокретност"
            : slucaj.ucesnici.length === 0 && currentStep === 3
            ? "Морате унети барем једну странку"
            : "Сва обавезна поља морају бити попуњена";

    return { disabled, tooltipMessage };
};

export const checkStepValidationAdvokati = (currentStep: number, slucaj: CaseAdvokatiState) => {
    const hasAllRequiredDocs = () => {
        //@ts-ignore
        const requiredDocuments = mandatoryDocuments[slucaj.basicInfo.vrstaIspraveId.toString()].docs;

        if (!requiredDocuments || requiredDocuments.length === 0) {
            return false;
        }
        const uploadedDocsSet = new Set(
            slucaj.tempDokumenta.map((doc) => {
                return doc.vrstaIspraveId.toString();
            })
        );

        return !requiredDocuments.every((docId: string) => uploadedDocsSet.has(docId));
    };

    const isDisabled = () => {
        if (currentStep >= 2 && (slucaj.nepokretnostKN.length === 0 || slucaj.ucesnici.length === undefined)) {
            return true;
        }
        if (currentStep >= 3 && slucaj.ucesnici.length === 0) {
            return true;
        }
        if (
            ((slucaj.tempDokumenta.length > 0 && slucaj.tempDokumenta.some((dok) => dok.isSigned === false)) || hasAllRequiredDocs()) &&
            currentStep === 4
        ) {
            return true;
        }

        return false;
    };

    const disabled = isDisabled();
    const tooltipMessage =
        currentStep === 4 && slucaj.tempDokumenta.some((dok) => dok.isSigned === false)
            ? "Сви фајлови морају бити електронски потписани"
            : currentStep === 4 && hasAllRequiredDocs()
            ? getMissingDocumentsMessage(slucaj.basicInfo.vrstaIspraveId)
            : slucaj.nepokretnostKN.length === 0 && currentStep === 2
            ? "Морате унети барем једну непокретност"
            : slucaj.ucesnici.length === 0 && currentStep === 3
            ? "Морате унети барем једну странку"
            : "Сва обавезна поља морају бити попуњена";

    return { disabled, tooltipMessage };
};

export const getPrilogName = (prilogId: string) => {
    switch (prilogId) {
        case "27":
            return "Пуномоћје";
        case "401":
            return "Исправа за упис/промену података у катастру";
        case "404":
            return "Захтев (као друга исправа)";
        case "405":
            return "Захтев ( као основна исправа)";
        case "601":
            return "Очитана лична карта странке";
        default:
            return "";
    }
};

function getMissingDocumentsMessage(vrstaIspraveId: string) {
    switch (vrstaIspraveId) {
        case "6031":
        case "6032":
        case "6033":
        case "6016":
        case "6021":
        case "6022":
        case "6023":
        case "6024":
        case "6026":
        case "6003":
        case "6004":
        case "6005":
        case "6006":
        case "6007":
        case "6017":
        case "6008":
        case "6009":
        case "6018":
        case "6012":
        case "6013":
        case "6011":
        case "6301":
        case "6302":
        case "6303":
        case "6304":
        case "6305":
            return "Zahtev (Osnovna isprava); \n Пуномоћје; Zahtev(Druga isprava); \n Исправа за упис/промену података у катастру";
        case "6025":
            return "Zahtev (Osnovna isprava); \n Пуномоћје; Zahtev(Druga isprava); \n Очитана лична карта странке";
        case "6106":
        case "6107":
        case "6108":
        case "6105":
        case "6103":
        case "6121":
        case "6122":
        case "6123":
        case "6124":
        case "6125":
        case "6206":
            return "Zahtev (Osnovna isprava); Пуномоћје; Zahtev(Druga isprava)";
        default:
            return "Морате унети све неопходне фајлове";
    }
}
