import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export interface BasicInfoTip2 {
    datumPotvrde: string;
    brojPotvrde: string;
    informacijeOLokacijiBroj: string;
}
export interface CaseTip2State {
    basicInfo: BasicInfoTip2;
    nepokretnostKN: AssetsTip2[];
    ucesnici: AplicantTip2[];
    dokumenta: DocumentTip2[];
    tempDokumenta: LoadDocumentTip2[];
    loading: boolean;
    error: string | null;
}

export interface AssetsTip2 {
    id?: number;
    podBrojParcele: string | number;
    opstinaNaziv: string;
    koNaziv: string;
    opstinaId: string | number;
    koId: string;
    brojParcele: string | number;
    brojDelaParcele: string | number;
    udeo: string | number;
    povrsina: string;
}

export interface AplicantTip2 {
    id?: number;
    vrstaLica?: VrstaLica;
    ime?: string;
    prezime?: string;
    imeRoditelja?: string;
    jmbg?: string;
    brojPasosa?: string;
    brojLicneKarte?: string;
    ssn?: string;
    maticniBroj?: string;
    pib?: string;
    imePravnogLica?: string;
    email?: string;
    drzavaNaziv?: string;
    flagKIM?: boolean;
    opstinaSifra?: string;
    opstinaNaziv?: string;
    mestoSifra?: string;
    mestoNaziv?: string;
    ulicaSifra?: string;
    ulicaNaziv?: string;
    ulicaRucno?: string;
    kucniBroj?: string;
    postanskiBroj?: string;
    flagUlicaRucno?: boolean;
    brojUpisaURegistarDrzaveSedista?: string;
    nazivRegistra?: string;
    flagPrebivalisteInostranstvo?: boolean;
}

export interface LoadDocumentTip2 {
    id: number;
    name: string;
    file: string;
    isSigned: boolean;
    category: string;
}

export interface DocumentTip2 {
    id_prijem_fajl_esalter: number;
}

export enum VrstaLica {
    FIZICKO_DOMACE = "физичко-домаће",
    FIZICKO_STRANAC = "физичко-странац",
    PRAVNO_DOMACE = "правно-домаће",
    PRAVNO_STRANAC = "правно-странац",
}

const initialState: CaseTip2State = {
    basicInfo: {
        datumPotvrde: moment().format("DD-MM-YYYY"),
        brojPotvrde: "",
        informacijeOLokacijiBroj: "",
    },
    nepokretnostKN: [],
    ucesnici: [],
    dokumenta: [],
    tempDokumenta: [],
    loading: false,
    error: null,
};

export const caseTip2Slice = createSlice({
    name: "caseTip2",
    initialState,
    reducers: {
        resetState: () => initialState,
        updateBasicInfo: (state, action: PayloadAction<Partial<BasicInfoTip2>>) => {
            state.basicInfo = { ...state.basicInfo, ...action.payload };
        },
        resetBasicInfo: (state) => {
            state.basicInfo = { ...initialState.basicInfo };
        },
        addOrUpdateNepokretnostTip2: (state, action: PayloadAction<Partial<AssetsTip2>>) => {
            const formData = action.payload;
            if (formData.id === undefined) {
                // If id is not provided, generate a new id for the new "nepokretnost"
                //@ts-ignore
                const newId = state.nepokretnostKN.length === 0 ? 1 : state.nepokretnostKN[state.nepokretnostKN.length - 1].id + 1;
                //@ts-ignore
                state.nepokretnostKN.push({ ...formData, id: newId });
            } else {
                // If id is provided, update the existing "nepokretnost" data
                const existingNepokretnostIndex = state.nepokretnostKN.findIndex((nep) => nep.id === formData.id);
                if (existingNepokretnostIndex !== -1) {
                    state.nepokretnostKN[existingNepokretnostIndex] = { ...state.nepokretnostKN[existingNepokretnostIndex], ...formData };
                }
            }
        },
        deleteNepokretnostTip2: (state, action: PayloadAction<number>) => {
            const nepokretnostId = action.payload;
            const updatedNepokretnosti = state.nepokretnostKN.filter((nep) => nep.id !== nepokretnostId);
            state.nepokretnostKN = updatedNepokretnosti;
        },
        addOrUpdateUcesnikTip2: (state, action: PayloadAction<Partial<AssetsTip2>>) => {
            const formData = action.payload;
            if (formData.id === undefined) {
                //@ts-ignore
                const newId = state.ucesnici.length === 0 ? 1 : state.ucesnici[state.ucesnici.length - 1].id + 1;
                //@ts-ignore
                state.ucesnici.push({ ...formData, id: newId });
            } else {
                const existingUcesniciIndex = state.ucesnici.findIndex((pod) => pod.id === formData.id);
                if (existingUcesniciIndex !== -1) {
                    state.ucesnici[existingUcesniciIndex] = { ...state.ucesnici[existingUcesniciIndex], ...formData };
                }
            }
        },
        deleteUcesnikTip2: (state, action: PayloadAction<number>) => {
            const ucesnikId = action.payload;
            const updatedUcesnici = state.ucesnici.filter((pod) => pod.id !== ucesnikId);
            state.ucesnici = updatedUcesnici;
        },
        addTempDocumentTip2: (state, action: PayloadAction<Partial<LoadDocumentTip2>>) => {
            const newId = state.tempDokumenta.length === 0 ? 1 : state.tempDokumenta[state.tempDokumenta.length - 1].id + 1;
            //@ts-ignore
            state.tempDokumenta.push({ ...action.payload, id: newId });
        },
        deleteTempDocumentTip2: (state, action: PayloadAction<number>) => {
            const documentId = action.payload;
            const updatedDocuments = state.tempDokumenta.filter((doc) => doc.id !== documentId);
            state.tempDokumenta = updatedDocuments;
        },
        addDocumentTip2: (state, action: PayloadAction<DocumentTip2[]>) => {
            state.dokumenta = action.payload;
        },
    },
});

export const {
    resetState,
    updateBasicInfo,
    resetBasicInfo,
    addOrUpdateNepokretnostTip2,
    deleteNepokretnostTip2,
    addOrUpdateUcesnikTip2,
    deleteUcesnikTip2,
    addTempDocumentTip2,
    deleteTempDocumentTip2,
    addDocumentTip2,
} = caseTip2Slice.actions;
export default caseTip2Slice.reducer;
