type Sluzba = { SluzbaId: number; SluzbaNaziv: string; office: number };
type Nadleznost = { OpstinaId: number; KOId: number | null; SluzbaId: number; OpstinaNaziv: string; KONaziv: string | null };

const sluzbe: Sluzba[] = [
    { "SluzbaId": 1, "SluzbaNaziv": "БЕОГРАД-РАКОВИЦА", "office": 232 },
    { "SluzbaId": 2, "SluzbaNaziv": "БЕОГРАД-ПАЛИЛУЛА", "office": 15 },
    { "SluzbaId": 3, "SluzbaNaziv": "БЕОГРАД-ВОЖДОВАЦ", "office": 231 },
    { "SluzbaId": 4, "SluzbaNaziv": "БЕОГРАД-ЗВЕЗДАРА", "office": 230 },
    { "SluzbaId": 5, "SluzbaNaziv": "БЕОГРАД-НОВИ БЕОГРАД", "office": 225 },
    { "SluzbaId": 6, "SluzbaNaziv": "БЕОГРАД-САВСКИ ВЕНАЦ", "office": 229 },
    { "SluzbaId": 7, "SluzbaNaziv": "БЕОГРАД-СТАРИ ГРАД", "office": 233 },
    { "SluzbaId": 8, "SluzbaNaziv": "БЕОГРАД-ВРАЧАР", "office": 228 },
    { "SluzbaId": 9, "SluzbaNaziv": "БАРАЈЕВО", "office": 7 },
    { "SluzbaId": 10, "SluzbaNaziv": "БЕОГРАД-ГРОЦКА", "office": 73 },
    { "SluzbaId": 11, "SluzbaNaziv": "БЕОГРАД-МЛАДЕНОВАЦ", "office": 85 },
    { "SluzbaId": 12, "SluzbaNaziv": "БЕОГРАД-ЧУКАРИЦА", "office": 224 },
    { "SluzbaId": 13, "SluzbaNaziv": "ЛАЗАРЕВАЦ", "office": 80 },
    { "SluzbaId": 14, "SluzbaNaziv": "БЕОГРАД-ОБРЕНОВАЦ", "office": 87 },
    { "SluzbaId": 15, "SluzbaNaziv": "СОПОТ", "office": 22 },
    { "SluzbaId": 16, "SluzbaNaziv": "БЕОГРАД-СУРЧИН", "office": 223 },
    { "SluzbaId": 17, "SluzbaNaziv": "ЗЕМУН", "office": 16 },
    { "SluzbaId": 18, "SluzbaNaziv": "ГОЛУБАЦ", "office": 201 },
    { "SluzbaId": 19, "SluzbaNaziv": "КУЧЕВО", "office": 27 },
    { "SluzbaId": 20, "SluzbaNaziv": "МАЛО ЦРНИЋЕ", "office": 203 },
    { "SluzbaId": 21, "SluzbaNaziv": "ПЕТРОВАЦ", "office": 29 },
    { "SluzbaId": 22, "SluzbaNaziv": "ПОЖАРЕВАЦ", "office": 30 },
    { "SluzbaId": 23, "SluzbaNaziv": "СМЕДЕРЕВО", "office": 33 },
    { "SluzbaId": 24, "SluzbaNaziv": "СМЕДЕРЕВСКА ПАЛАНКА", "office": 34 },
    { "SluzbaId": 25, "SluzbaNaziv": "ВЕЛИКА ПЛАНА", "office": 37 },
    { "SluzbaId": 26, "SluzbaNaziv": "ВЕЛИКО ГРАДИШТЕ", "office": 38 },
    { "SluzbaId": 27, "SluzbaNaziv": "ЖАБАРИ", "office": 17 },
    { "SluzbaId": 28, "SluzbaNaziv": "ЖАГУБИЦА", "office": 18 },
    { "SluzbaId": 29, "SluzbaNaziv": "АРАНЂЕЛОВАЦ", "office": 20 },
    { "SluzbaId": 30, "SluzbaNaziv": "БАТОЧИНА", "office": 26 },
    { "SluzbaId": 31, "SluzbaNaziv": "КНИЋ", "office": 24 },
    { "SluzbaId": 32, "SluzbaNaziv": "КРАГУЈЕВАЦ", "office": 25 },
    { "SluzbaId": 33, "SluzbaNaziv": "КРАЉЕВО", "office": 44 },
    { "SluzbaId": 34, "SluzbaNaziv": "ЛАПОВО", "office": 202 },
    { "SluzbaId": 35, "SluzbaNaziv": "РАЧА", "office": 31 },
    { "SluzbaId": 36, "SluzbaNaziv": "ТОПОЛА", "office": 36 },
    { "SluzbaId": 37, "SluzbaNaziv": "ВРЊАЧКА БАЊА", "office": 54 },
    { "SluzbaId": 38, "SluzbaNaziv": "БОР", "office": 151 },
    { "SluzbaId": 39, "SluzbaNaziv": "АЛЕКСИНАЦ", "office": 55 },
    { "SluzbaId": 40, "SluzbaNaziv": "БАБУШНИЦА", "office": 56 },
    { "SluzbaId": 41, "SluzbaNaziv": "БЕЛА ПАЛАНКА", "office": 57 },
    { "SluzbaId": 42, "SluzbaNaziv": "БОЈНИК", "office": 58 },
    { "SluzbaId": 43, "SluzbaNaziv": "БОСИЛЕГРАД", "office": 59 },
    { "SluzbaId": 44, "SluzbaNaziv": "БУЈАНОВАЦ", "office": 60 },
    { "SluzbaId": 45, "SluzbaNaziv": "ЦРНА ТРАВА", "office": 205 },
    { "SluzbaId": 46, "SluzbaNaziv": "ДИМИТРОВГРАД", "office": 61 },
    { "SluzbaId": 47, "SluzbaNaziv": "ДОЉЕВАЦ", "office": 62 },
    { "SluzbaId": 48, "SluzbaNaziv": "ГАЏИН ХАН", "office": 63 },
    { "SluzbaId": 49, "SluzbaNaziv": "ЛЕБАНЕ", "office": 64 },
    { "SluzbaId": 50, "SluzbaNaziv": "ЛЕСКОВАЦ", "office": 65 },
    { "SluzbaId": 51, "SluzbaNaziv": "МЕДВЕЂА", "office": 66 },
    { "SluzbaId": 52, "SluzbaNaziv": "МЕРОШИНА", "office": 67 },
    { "SluzbaId": 53, "SluzbaNaziv": "НИШ", "office": 68 },
    { "SluzbaId": 54, "SluzbaNaziv": "ПИРОТ", "office": 69 },
    { "SluzbaId": 55, "SluzbaNaziv": "ПРЕШЕВО", "office": 70 },
    { "SluzbaId": 56, "SluzbaNaziv": "РАЖАЊ", "office": 49 },
    { "SluzbaId": 57, "SluzbaNaziv": "СУРДУЛИЦА", "office": 71 },
    { "SluzbaId": 58, "SluzbaNaziv": "СВРЉИГ", "office": 72 },
    { "SluzbaId": 59, "SluzbaNaziv": "ТРГОВИШТЕ", "office": 204 },
    { "SluzbaId": 60, "SluzbaNaziv": "ВЛАДИЧИН ХАН", "office": 74 },
    { "SluzbaId": 61, "SluzbaNaziv": "ВЛАСОТИНЦЕ", "office": 75 },
    { "SluzbaId": 62, "SluzbaNaziv": "ВРАЊЕ", "office": 76 },
    { "SluzbaId": 63, "SluzbaNaziv": "БЕЧЕЈ", "office": 84 },
    { "SluzbaId": 64, "SluzbaNaziv": "БЕОЧИН", "office": 207 },
    { "SluzbaId": 65, "SluzbaNaziv": "НОВИ САД 1", "office": 90 },
    { "SluzbaId": 66, "SluzbaNaziv": "НОВИ САД 2", "office": 226 },
    { "SluzbaId": 67, "SluzbaNaziv": "НОВИ САД 3", "office": 234 },
    { "SluzbaId": 68, "SluzbaNaziv": "СРБОБРАН", "office": 210 },
    { "SluzbaId": 69, "SluzbaNaziv": "СРЕМСКИ КАРЛОВЦИ", "office": 212 },
    { "SluzbaId": 70, "SluzbaNaziv": "ТЕМЕРИН", "office": 100 },
    { "SluzbaId": 71, "SluzbaNaziv": "ТИТЕЛ", "office": 211 },
    { "SluzbaId": 72, "SluzbaNaziv": "ЖАБАЉ", "office": 77 },
    { "SluzbaId": 73, "SluzbaNaziv": "СУБОТИЦА", "office": 99 },
    { "SluzbaId": 74, "SluzbaNaziv": "МАЛИ ИЂОШ", "office": 209 },
    { "SluzbaId": 75, "SluzbaNaziv": "БАЧКА ТОПОЛА", "office": 82 },
    { "SluzbaId": 76, "SluzbaNaziv": "СОМБОР", "office": 95 },
    { "SluzbaId": 77, "SluzbaNaziv": "ОЏАЦИ", "office": 92 },
    { "SluzbaId": 78, "SluzbaNaziv": "АПАТИН", "office": 79 },
    { "SluzbaId": 79, "SluzbaNaziv": "КУЛА", "office": 88 },
    { "SluzbaId": 80, "SluzbaNaziv": "БАЧКА ПАЛАНКА", "office": 81 },
    { "SluzbaId": 81, "SluzbaNaziv": "БАЧКИ ПЕТРОВАЦ", "office": 83 },
    { "SluzbaId": 82, "SluzbaNaziv": "БАЧ", "office": 206 },
    { "SluzbaId": 83, "SluzbaNaziv": "ВРБАС", "office": 102 },
    { "SluzbaId": 84, "SluzbaNaziv": "СРЕМСКА МИТРОВИЦА", "office": 97 },
    { "SluzbaId": 85, "SluzbaNaziv": "ИРИГ", "office": 208 },
    { "SluzbaId": 86, "SluzbaNaziv": "ШИД", "office": 78 },
    { "SluzbaId": 87, "SluzbaNaziv": "РУМА", "office": 94 },
    { "SluzbaId": 88, "SluzbaNaziv": "ИНЂИЈА", "office": 86 },
    { "SluzbaId": 89, "SluzbaNaziv": "СТАРА ПАЗОВА", "office": 98 },
    { "SluzbaId": 90, "SluzbaNaziv": "ПЕЋИНЦИ", "office": 93 },
    { "SluzbaId": 91, "SluzbaNaziv": "КИКИНДА", "office": 107 },
    { "SluzbaId": 92, "SluzbaNaziv": "АДА", "office": 217 },
    { "SluzbaId": 93, "SluzbaNaziv": "СЕНТА", "office": 114 },
    { "SluzbaId": 94, "SluzbaNaziv": "ЧОКА", "office": 213 },
    { "SluzbaId": 95, "SluzbaNaziv": "НОВИ КНЕЖЕВАЦ", "office": 104 },
    { "SluzbaId": 96, "SluzbaNaziv": "КАЊИЖА", "office": 106 },
    { "SluzbaId": 97, "SluzbaNaziv": "ЗРЕЊАНИН", "office": 116 },
    { "SluzbaId": 98, "SluzbaNaziv": "НОВА ЦРЊА", "office": 214 },
    { "SluzbaId": 99, "SluzbaNaziv": "НОВИ БЕЧЕЈ", "office": 110 },
    { "SluzbaId": 100, "SluzbaNaziv": "ЖИТИШТЕ", "office": 103 },
    { "SluzbaId": 101, "SluzbaNaziv": "СЕЧАЊ", "office": 113 },
    { "SluzbaId": 102, "SluzbaNaziv": "ПАНЧЕВО", "office": 111 },
    { "SluzbaId": 103, "SluzbaNaziv": "КОВАЧИЦА", "office": 112 },
    { "SluzbaId": 104, "SluzbaNaziv": "ОПОВО", "office": 215 },
    { "SluzbaId": 105, "SluzbaNaziv": "ВРШАЦ", "office": 115 },
    { "SluzbaId": 106, "SluzbaNaziv": "БЕЛА ЦРКВА", "office": 109 },
    { "SluzbaId": 107, "SluzbaNaziv": "ПЛАНДИШТЕ", "office": 216 },
    { "SluzbaId": 108, "SluzbaNaziv": "КОВИН", "office": 108 },
    { "SluzbaId": 109, "SluzbaNaziv": "АЛИБУНАР", "office": 105 },
    { "SluzbaId": 110, "SluzbaNaziv": "ШАБАЦ", "office": 1 },
    { "SluzbaId": 111, "SluzbaNaziv": "ВЛАДИМИРЦИ", "office": 14 },
    { "SluzbaId": 112, "SluzbaNaziv": "БОГАТИЋ", "office": 2 },
    { "SluzbaId": 113, "SluzbaNaziv": "КОЦЕЉЕВА", "office": 3 },
    { "SluzbaId": 114, "SluzbaNaziv": "ЛОЗНИЦА", "office": 6 },
    { "SluzbaId": 115, "SluzbaNaziv": "КРУПАЊ", "office": 4 },
    { "SluzbaId": 116, "SluzbaNaziv": "МАЛИ ЗВОРНИК", "office": 200 },
    { "SluzbaId": 117, "SluzbaNaziv": "ЉУБОВИЈА", "office": 11 },
    { "SluzbaId": 118, "SluzbaNaziv": "ВАЉЕВО", "office": 13 },
    { "SluzbaId": 119, "SluzbaNaziv": "МИОНИЦА", "office": 8 },
    { "SluzbaId": 120, "SluzbaNaziv": "ОСЕЧИНА", "office": 9 },
    { "SluzbaId": 121, "SluzbaNaziv": "ЛАЈКОВАЦ", "office": 5 },
    { "SluzbaId": 122, "SluzbaNaziv": "УБ", "office": 12 },
    { "SluzbaId": 123, "SluzbaNaziv": "ЉИГ", "office": 10 },
    { "SluzbaId": 124, "SluzbaNaziv": "МАЈДАНПЕК", "office": 154 },
    { "SluzbaId": 125, "SluzbaNaziv": "ДОЊИ МИЛАНОВАЦ", "office": 227 },
    { "SluzbaId": 126, "SluzbaNaziv": "НЕГОТИН", "office": 155 },
    { "SluzbaId": 127, "SluzbaNaziv": "КЛАДОВО", "office": 152 },
    { "SluzbaId": 128, "SluzbaNaziv": "ЗАЈЕЧАР", "office": 91 },
    { "SluzbaId": 129, "SluzbaNaziv": "СОКО БАЊА", "office": 89 },
    { "SluzbaId": 130, "SluzbaNaziv": "БОЉЕВАЦ", "office": 150 },
    { "SluzbaId": 131, "SluzbaNaziv": "КЊАЖЕВАЦ", "office": 153 },
    { "SluzbaId": 132, "SluzbaNaziv": "ЈАГОДИНА", "office": 23 },
    { "SluzbaId": 133, "SluzbaNaziv": "РЕКОВАЦ", "office": 32 },
    { "SluzbaId": 134, "SluzbaNaziv": "СВИЛАЈНАЦ", "office": 35 },
    { "SluzbaId": 135, "SluzbaNaziv": "ДЕСПОТОВАЦ", "office": 21 },
    { "SluzbaId": 136, "SluzbaNaziv": "ПАРАЋИН", "office": 28 },
    { "SluzbaId": 137, "SluzbaNaziv": "ЋУПРИЈА", "office": 19 },
    { "SluzbaId": 138, "SluzbaNaziv": "ЧАЧАК", "office": 136 },
    { "SluzbaId": 139, "SluzbaNaziv": "ГУЧА-ЛУЧАНИ", "office": 141 },
    { "SluzbaId": 140, "SluzbaNaziv": "ИВАЊИЦА", "office": 142 },
    { "SluzbaId": 141, "SluzbaNaziv": "ГОРЊИ МИЛАНОВАЦ", "office": 140 },
    { "SluzbaId": 142, "SluzbaNaziv": "УЖИЦЕ", "office": 149 },
    { "SluzbaId": 143, "SluzbaNaziv": "ПОЖЕГА", "office": 145 },
    { "SluzbaId": 144, "SluzbaNaziv": "КОСЈЕРИЋ", "office": 143 },
    { "SluzbaId": 145, "SluzbaNaziv": "БАЈИНА БАШТА", "office": 139 },
    { "SluzbaId": 146, "SluzbaNaziv": "ЧАЈЕТИНА", "office": 137 },
    { "SluzbaId": 147, "SluzbaNaziv": "АРИЉЕ", "office": 138 },
    { "SluzbaId": 148, "SluzbaNaziv": "ПРИЈЕПОЉЕ", "office": 147 },
    { "SluzbaId": 149, "SluzbaNaziv": "СЈЕНИЦА", "office": 148 },
    { "SluzbaId": 150, "SluzbaNaziv": "ПРИБОЈ", "office": 146 },
    { "SluzbaId": 151, "SluzbaNaziv": "НОВА ВАРОШ", "office": 144 },
    { "SluzbaId": 152, "SluzbaNaziv": "НОВИ ПАЗАР", "office": 47 },
    { "SluzbaId": 153, "SluzbaNaziv": "РАШКА", "office": 50 },
    { "SluzbaId": 154, "SluzbaNaziv": "ТУТИН", "office": 52 },
    { "SluzbaId": 155, "SluzbaNaziv": "КРУШЕВАЦ", "office": 45 },
    { "SluzbaId": 156, "SluzbaNaziv": "ЋИЋЕВАЦ", "office": 40 },
    { "SluzbaId": 157, "SluzbaNaziv": "ВАРВАРИН", "office": 53 },
    { "SluzbaId": 158, "SluzbaNaziv": "ТРСТЕНИК", "office": 51 },
    { "SluzbaId": 159, "SluzbaNaziv": "АЛЕКСАНДРОВАЦ", "office": 41 },
    { "SluzbaId": 160, "SluzbaNaziv": "БРУС", "office": 43 },
    { "SluzbaId": 161, "SluzbaNaziv": "ПРОКУПЉЕ", "office": 48 },
    { "SluzbaId": 162, "SluzbaNaziv": "КУРШУМЛИЈА", "office": 46 },
    { "SluzbaId": 163, "SluzbaNaziv": "БЛАЦЕ", "office": 42 },
    { "SluzbaId": 164, "SluzbaNaziv": "ЖИТОРАЂА", "office": 39 },
    { "SluzbaId": 165, "SluzbaNaziv": "КОСОВСКА МИТРОВИЦА", "office": 122 },
    { "SluzbaId": 166, "SluzbaNaziv": "ЗУБИН ПОТОК", "office": 135 },
    { "SluzbaId": 167, "SluzbaNaziv": "ЛЕПОСАВИЋ", "office": 123 },
    { "SluzbaId": 168, "SluzbaNaziv": "ПРИШТИНА", "office": 128 },
    { "SluzbaId": 169, "SluzbaNaziv": "КОСОВСКА КАМЕНИЦА", "office": 121 },
    { "SluzbaId": 170, "SluzbaNaziv": "ШТРПЦЕ", "office": 96 },
    { "SluzbaId": 301, "SluzbaNaziv": "ОКВ Београд", "office": 301 },
    { "SluzbaId": 302, "SluzbaNaziv": "ОКВ Нови Сад", "office": 302 },
    { "SluzbaId": 303, "SluzbaNaziv": "ОКВ Панчево", "office": 303 },
    { "SluzbaId": 304, "SluzbaNaziv": "ОКВ Крагујевац", "office": 304 },
    { "SluzbaId": 305, "SluzbaNaziv": "ОКВ Ваљево", "office": 305 },
    { "SluzbaId": 306, "SluzbaNaziv": "ОКВ Краљево", "office": 306 },
    { "SluzbaId": 307, "SluzbaNaziv": "ОКВ Ужице", "office": 307 },
    { "SluzbaId": 308, "SluzbaNaziv": "ОКВ Врање", "office": 308 },
    { "SluzbaId": 309, "SluzbaNaziv": "ОКВ Ниш", "office": 309 }
]


const sluzbeNadleznosti: Nadleznost[] = [
        {
            "OpstinaId": 70211,
            "OpstinaNaziv": "RAKOVICA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 1
        },
        {
            "OpstinaId": 70203,
            "OpstinaNaziv": "PALILULA (BEOGRAD)",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 2
        },
        {
            "OpstinaId": 70106,
            "OpstinaNaziv": "VOŽDOVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 3
        },
        {
            "OpstinaId": 70149,
            "OpstinaNaziv": "ZVEZDARA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 4
        },
        {
            "OpstinaId": 70181,
            "OpstinaNaziv": "NOVI BEOGRAD",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 5
        },
        {
            "OpstinaId": 70220,
            "OpstinaNaziv": "SAVSKI VENAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 6
        },
        {
            "OpstinaId": 70246,
            "OpstinaNaziv": "STARI GRAD",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 7
        },
        {
            "OpstinaId": 70114,
            "OpstinaNaziv": "VRAČAR",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 8
        },
        {
            "OpstinaId": 70092,
            "OpstinaNaziv": "BARAJEVO",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 9
        },
        {
            "OpstinaId": 70122,
            "OpstinaNaziv": "GROCKA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 10
        },
        {
            "OpstinaId": 70173,
            "OpstinaNaziv": "MLADENOVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 11
        },
        {
            "OpstinaId": 70254,
            "OpstinaNaziv": "ČUKARICA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 12
        },
        {
            "OpstinaId": 70165,
            "OpstinaNaziv": "LAZAREVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 13
        },
        {
            "OpstinaId": 70190,
            "OpstinaNaziv": "OBRENOVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 14
        },
        {
            "OpstinaId": 70238,
            "OpstinaNaziv": "SOPOT",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 15
        },
        {
            "OpstinaId": 71293,
            "OpstinaNaziv": "SURČIN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 16
        },
        {
            "OpstinaId": 70157,
            "OpstinaNaziv": "ZEMUN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 17
        },
        {
            "OpstinaId": 70475,
            "OpstinaNaziv": "GOLUBAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 18
        },
        {
            "OpstinaId": 70696,
            "OpstinaNaziv": "KUČEVO",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 19
        },
        {
            "OpstinaId": 70807,
            "OpstinaNaziv": "MALO CRNIĆE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 20
        },
        {
            "OpstinaId": 70912,
            "OpstinaNaziv": "PETROVAC ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 21
        },
        {
            "OpstinaId": 70947,
            "OpstinaNaziv": "POŽAREVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 22
        },
        {
            "OpstinaId": 71340,
            "OpstinaNaziv": "KOSTOLAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 22
        },
        {
            "OpstinaId": 71099,
            "OpstinaNaziv": "SMEDEREVO",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 23
        },
        {
            "OpstinaId": 71102,
            "OpstinaNaziv": "SMEDEREVSKA PALANKA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 24
        },
        {
            "OpstinaId": 70386,
            "OpstinaNaziv": "VELIKA PLANA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 25
        },
        {
            "OpstinaId": 70394,
            "OpstinaNaziv": "VELIKO GRADIŠTE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 26
        },
        {
            "OpstinaId": 70521,
            "OpstinaNaziv": "ŽABARI",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 27
        },
        {
            "OpstinaId": 70530,
            "OpstinaNaziv": "ŽAGUBICA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 28
        },
        {
            "OpstinaId": 70033,
            "OpstinaNaziv": "ARANĐELOVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 29
        },
        {
            "OpstinaId": 70076,
            "OpstinaNaziv": "BATOČINA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 30
        },
        {
            "OpstinaId": 70599,
            "OpstinaNaziv": "KNIĆ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 31
        },
        {
            "OpstinaId": 70645,
            "OpstinaNaziv": "KRAGUJEVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 32
        },
        {
            "OpstinaId": 70653,
            "OpstinaNaziv": "KRALJEVO",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 33
        },
        {
            "OpstinaId": 71277,
            "OpstinaNaziv": "LAPOVO",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 34
        },
        {
            "OpstinaId": 71013,
            "OpstinaNaziv": "RAČA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 35
        },
        {
            "OpstinaId": 71153,
            "OpstinaNaziv": "TOPOLA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 36
        },
        {
            "OpstinaId": 70459,
            "OpstinaNaziv": "VRNJAČKA BANJA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 37
        },
        {
            "OpstinaId": 70327,
            "OpstinaNaziv": "BOR",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 38
        },
        {
            "OpstinaId": 70025,
            "OpstinaNaziv": "ALEKSINAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 39
        },
        {
            "OpstinaId": 70050,
            "OpstinaNaziv": "BABUŠNICA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 40
        },
        {
            "OpstinaId": 70084,
            "OpstinaNaziv": "BELA PALANKA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 41
        },
        {
            "OpstinaId": 70297,
            "OpstinaNaziv": "BOJNIK",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 42
        },
        {
            "OpstinaId": 70335,
            "OpstinaNaziv": "BOSILEGRAD",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 43
        },
        {
            "OpstinaId": 70351,
            "OpstinaNaziv": "BUJANOVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 44
        },
        {
            "OpstinaId": 71226,
            "OpstinaNaziv": "CRNA TRAVA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 45
        },
        {
            "OpstinaId": 70505,
            "OpstinaNaziv": "DIMITROVGRAD",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 46
        },
        {
            "OpstinaId": 70513,
            "OpstinaNaziv": "DOLJEVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 47
        },
        {
            "OpstinaId": 70467,
            "OpstinaNaziv": "GADŽIN HAN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 48
        },
        {
            "OpstinaId": 70718,
            "OpstinaNaziv": "LEBANE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 49
        },
        {
            "OpstinaId": 70726,
            "OpstinaNaziv": "LESKOVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 50
        },
        {
            "OpstinaId": 70815,
            "OpstinaNaziv": "MEDVEĐA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 51
        },
        {
            "OpstinaId": 70823,
            "OpstinaNaziv": "MEROŠINA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 52
        },
        {
            "OpstinaId": 71331,
            "OpstinaNaziv": "MEDIJANA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 53
        },
        {
            "OpstinaId": 71323,
            "OpstinaNaziv": "PALILULA (NIŠ)",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 53
        },
        {
            "OpstinaId": 71307,
            "OpstinaNaziv": "PANTELEJ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 53
        },
        {
            "OpstinaId": 71315,
            "OpstinaNaziv": "CRVENI KRST",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 53
        },
        {
            "OpstinaId": 71285,
            "OpstinaNaziv": "NIŠKA BANJA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 53
        },
        {
            "OpstinaId": 70939,
            "OpstinaNaziv": "PIROT",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 54
        },
        {
            "OpstinaId": 70963,
            "OpstinaNaziv": "PREŠEVO",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 55
        },
        {
            "OpstinaId": 71005,
            "OpstinaNaziv": "RAŽANJ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 56
        },
        {
            "OpstinaId": 71137,
            "OpstinaNaziv": "SURDULICA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 57
        },
        {
            "OpstinaId": 71064,
            "OpstinaNaziv": "SVRLJIG",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 58
        },
        {
            "OpstinaId": 71161,
            "OpstinaNaziv": "TRGOVIŠTE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 59
        },
        {
            "OpstinaId": 70416,
            "OpstinaNaziv": "VLADIČIN HAN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 60
        },
        {
            "OpstinaId": 70424,
            "OpstinaNaziv": "VLASOTINCE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 61
        },
        {
            "OpstinaId": 70432,
            "OpstinaNaziv": "VRANJE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 62
        },
        {
            "OpstinaId": 71358,
            "OpstinaNaziv": "VRANJSKA BANJA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 62
        },
        {
            "OpstinaId": 80110,
            "OpstinaNaziv": "BEČEJ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 63
        },
        {
            "OpstinaId": 80101,
            "OpstinaNaziv": "BEOČIN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 64
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 802166,
            "KONaziv": "NOVI SAD II",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 802182,
            "KONaziv": "NOVI SAD IV",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 802077,
            "KONaziv": "BEGEČ",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 802255,
            "KONaziv": "FUTOG",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 802298,
            "KONaziv": "VETERNIK",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 804576,
            "KONaziv": "KOVILJ",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 804541,
            "KONaziv": "BUDISAVA",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 802166,
            "KONaziv": "NOVI SAD II",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 802182,
            "KONaziv": "NOVI SAD IV",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 802077,
            "KONaziv": "BEGEČ",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 802255,
            "KONaziv": "FUTOG",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 802298,
            "KONaziv": "VETERNIK",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 804576,
            "KONaziv": "KOVILJ",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 804541,
            "KONaziv": "BUDISAVA",
            "SluzbaId": 65
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 802158,
            "KONaziv": "NOVI SAD I",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 802174,
            "KONaziv": "NOVI SAD III",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 804649,
            "KONaziv": "KAĆ",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 802085,
            "KONaziv": "ČENEJ",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 802263,
            "KONaziv": "STEPANOVIĆEVO",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 802123,
            "KONaziv": "KISAČ",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 802239,
            "KONaziv": "RUMENKA",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 802158,
            "KONaziv": "NOVI SAD I",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 802174,
            "KONaziv": "NOVI SAD III",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 804649,
            "KONaziv": "KAĆ",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 802085,
            "KONaziv": "ČENEJ",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 802263,
            "KONaziv": "STEPANOVIĆEVO",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 802123,
            "KONaziv": "KISAČ",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 80284,
            "OpstinaNaziv": "NOVI SAD",
            "KOId": 802239,
            "KONaziv": "RUMENKA",
            "SluzbaId": 66
        },
        {
            "OpstinaId": 80519,
            "OpstinaNaziv": "PETROVARADIN",
            "KOId": 803120,
            "KONaziv": "LEDINCI",
            "SluzbaId": 67
        },
        {
            "OpstinaId": 80519,
            "OpstinaNaziv": "PETROVARADIN",
            "KOId": 805203,
            "KONaziv": "BUKOVAC",
            "SluzbaId": 67
        },
        {
            "OpstinaId": 80519,
            "OpstinaNaziv": "PETROVARADIN",
            "KOId": 803308,
            "KONaziv": "SREMSKA KAMENICA",
            "SluzbaId": 67
        },
        {
            "OpstinaId": 80519,
            "OpstinaNaziv": "PETROVARADIN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 67
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 803120,
            "KONaziv": "LEDINCI",
            "SluzbaId": 67
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 805203,
            "KONaziv": "BUKOVAC",
            "SluzbaId": 67
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 803308,
            "KONaziv": "SREMSKA KAMENICA",
            "SluzbaId": 67
        },
        {
            "OpstinaId": 89010,
            "OpstinaNaziv": "НОВИ САД",
            "KOId": 805378,
            "KONaziv": "ПЕТРОВАРАДИН",
            "SluzbaId": 67
        },
        {
            "OpstinaId": 80390,
            "OpstinaNaziv": "SRBOBRAN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 68
        },
        {
            "OpstinaId": 80411,
            "OpstinaNaziv": "SREMSKI KARLOVCI",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 69
        },
        {
            "OpstinaId": 80446,
            "OpstinaNaziv": "TEMERIN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 70
        },
        {
            "OpstinaId": 80454,
            "OpstinaNaziv": "TITEL",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 71
        },
        {
            "OpstinaId": 80136,
            "OpstinaNaziv": "ŽABALJ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 72
        },
        {
            "OpstinaId": 80438,
            "OpstinaNaziv": "SUBOTICA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 73
        },
        {
            "OpstinaId": 80241,
            "OpstinaNaziv": "MALI IĐOŠ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 74
        },
        {
            "OpstinaId": 80071,
            "OpstinaNaziv": "BAČKA TOPOLA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 75
        },
        {
            "OpstinaId": 80381,
            "OpstinaNaziv": "SOMBOR",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 76
        },
        {
            "OpstinaId": 80306,
            "OpstinaNaziv": "ODŽACI",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 77
        },
        {
            "OpstinaId": 80047,
            "OpstinaNaziv": "APATIN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 78
        },
        {
            "OpstinaId": 80233,
            "OpstinaNaziv": "KULA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 79
        },
        {
            "OpstinaId": 80063,
            "OpstinaNaziv": "BAČKA PALANKA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 80
        },
        {
            "OpstinaId": 80080,
            "OpstinaNaziv": "BAČKI PETROVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 81
        },
        {
            "OpstinaId": 80055,
            "OpstinaNaziv": "BAČ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 82
        },
        {
            "OpstinaId": 80462,
            "OpstinaNaziv": "VRBAS",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 83
        },
        {
            "OpstinaId": 80403,
            "OpstinaNaziv": "SREMSKA MITROVICA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 84
        },
        {
            "OpstinaId": 80187,
            "OpstinaNaziv": "IRIG",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 85
        },
        {
            "OpstinaId": 80497,
            "OpstinaNaziv": "ŠID",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 86
        },
        {
            "OpstinaId": 80357,
            "OpstinaNaziv": "RUMA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 87
        },
        {
            "OpstinaId": 80179,
            "OpstinaNaziv": "INĐIJA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 88
        },
        {
            "OpstinaId": 80420,
            "OpstinaNaziv": "STARA PAZOVA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 89
        },
        {
            "OpstinaId": 80322,
            "OpstinaNaziv": "PEĆINCI",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 90
        },
        {
            "OpstinaId": 80209,
            "OpstinaNaziv": "KIKINDA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 91
        },
        {
            "OpstinaId": 80012,
            "OpstinaNaziv": "ADA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 92
        },
        {
            "OpstinaId": 80365,
            "OpstinaNaziv": "SENTA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 93
        },
        {
            "OpstinaId": 80489,
            "OpstinaNaziv": "ČOKA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 94
        },
        {
            "OpstinaId": 80276,
            "OpstinaNaziv": "NOVI KNEŽEVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 95
        },
        {
            "OpstinaId": 80195,
            "OpstinaNaziv": "KANJIŽA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 96
        },
        {
            "OpstinaId": 80152,
            "OpstinaNaziv": "ZRENJANIN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 97
        },
        {
            "OpstinaId": 80250,
            "OpstinaNaziv": "NOVA CRNJA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 98
        },
        {
            "OpstinaId": 80268,
            "OpstinaNaziv": "NOVI BEČEJ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 99
        },
        {
            "OpstinaId": 80144,
            "OpstinaNaziv": "ŽITIŠTE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 100
        },
        {
            "OpstinaId": 80373,
            "OpstinaNaziv": "SEČANJ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 101
        },
        {
            "OpstinaId": 80314,
            "OpstinaNaziv": "PANČEVO",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 102
        },
        {
            "OpstinaId": 80217,
            "OpstinaNaziv": "KOVAČICA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 103
        },
        {
            "OpstinaId": 80292,
            "OpstinaNaziv": "OPOVO",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 104
        },
        {
            "OpstinaId": 80128,
            "OpstinaNaziv": "VRŠAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 105
        },
        {
            "OpstinaId": 80098,
            "OpstinaNaziv": "BELA CRKVA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 106
        },
        {
            "OpstinaId": 80349,
            "OpstinaNaziv": "PLANDIŠTE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 107
        },
        {
            "OpstinaId": 80225,
            "OpstinaNaziv": "KOVIN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 108
        },
        {
            "OpstinaId": 80039,
            "OpstinaNaziv": "ALIBUNAR",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 109
        },
        {
            "OpstinaId": 71269,
            "OpstinaNaziv": "ŠABAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 110
        },
        {
            "OpstinaId": 70408,
            "OpstinaNaziv": "VLADIMIRCI",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 111
        },
        {
            "OpstinaId": 70289,
            "OpstinaNaziv": "BOGATIĆ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 112
        },
        {
            "OpstinaId": 70637,
            "OpstinaNaziv": "KOCELJEVA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 113
        },
        {
            "OpstinaId": 70734,
            "OpstinaNaziv": "LOZNICA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 114
        },
        {
            "OpstinaId": 70661,
            "OpstinaNaziv": "KRUPANJ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 115
        },
        {
            "OpstinaId": 70793,
            "OpstinaNaziv": "MALI ZVORNIK",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 116
        },
        {
            "OpstinaId": 70777,
            "OpstinaNaziv": "LJUBOVIJA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 117
        },
        {
            "OpstinaId": 70360,
            "OpstinaNaziv": "VALJEVO",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 118
        },
        {
            "OpstinaId": 70831,
            "OpstinaNaziv": "MIONICA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 119
        },
        {
            "OpstinaId": 70882,
            "OpstinaNaziv": "OSEČINA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 120
        },
        {
            "OpstinaId": 70700,
            "OpstinaNaziv": "LAJKOVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 121
        },
        {
            "OpstinaId": 71218,
            "OpstinaNaziv": "UB",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 122
        },
        {
            "OpstinaId": 70769,
            "OpstinaNaziv": "LJIG",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 123
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 714364,
            "KONaziv": "VLAOLE",
            "SluzbaId": 124
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 727709,
            "KONaziv": "DEBELI LUG",
            "SluzbaId": 124
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 714437,
            "KONaziv": "JASIKOVO",
            "SluzbaId": 124
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 714496,
            "KONaziv": "LESKOVO",
            "SluzbaId": 124
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 727733,
            "KONaziv": "MAJDANPEK",
            "SluzbaId": 124
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 727768,
            "KONaziv": "RUDNA GLAVA",
            "SluzbaId": 124
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 727792,
            "KONaziv": "CRNAJKA",
            "SluzbaId": 124
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 727717,
            "KONaziv": "DONJI MILANOVAC",
            "SluzbaId": 125
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 727687,
            "KONaziv": "GOLUBINJE",
            "SluzbaId": 125
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 727679,
            "KONaziv": "BOLJETIN",
            "SluzbaId": 125
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 727750,
            "KONaziv": "MOSNA",
            "SluzbaId": 125
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 727725,
            "KONaziv": "KLOKOČEVAC",
            "SluzbaId": 125
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 727784,
            "KONaziv": "TOPOLNICA",
            "SluzbaId": 125
        },
        {
            "OpstinaId": 70785,
            "OpstinaNaziv": "MAJDANPEK",
            "KOId": 727741,
            "KONaziv": "MIROČ",
            "SluzbaId": 125
        },
        {
            "OpstinaId": 70840,
            "OpstinaNaziv": "NEGOTIN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 126
        },
        {
            "OpstinaId": 70572,
            "OpstinaNaziv": "KLADOVO",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 127
        },
        {
            "OpstinaId": 70556,
            "OpstinaNaziv": "ZAJEČAR",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 128
        },
        {
            "OpstinaId": 71129,
            "OpstinaNaziv": "SOKO BANJA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 129
        },
        {
            "OpstinaId": 70319,
            "OpstinaNaziv": "BOLJEVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 130
        },
        {
            "OpstinaId": 70602,
            "OpstinaNaziv": "KNJAŽEVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 131
        },
        {
            "OpstinaId": 71048,
            "OpstinaNaziv": "JAGODINA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 132
        },
        {
            "OpstinaId": 71030,
            "OpstinaNaziv": "REKOVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 133
        },
        {
            "OpstinaId": 71056,
            "OpstinaNaziv": "SVILAJNAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 134
        },
        {
            "OpstinaId": 70491,
            "OpstinaNaziv": "DESPOTOVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 135
        },
        {
            "OpstinaId": 70904,
            "OpstinaNaziv": "PARAĆIN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 136
        },
        {
            "OpstinaId": 71200,
            "OpstinaNaziv": "ĆUPRIJA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 137
        },
        {
            "OpstinaId": 71242,
            "OpstinaNaziv": "ČAČAK",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 138
        },
        {
            "OpstinaId": 70742,
            "OpstinaNaziv": "LUČANI",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 139
        },
        {
            "OpstinaId": 70564,
            "OpstinaNaziv": "IVANJICA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 140
        },
        {
            "OpstinaId": 70483,
            "OpstinaNaziv": "GORNJI MILANOVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 141
        },
        {
            "OpstinaId": 71145,
            "OpstinaNaziv": "UŽICE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 142
        },
        {
            "OpstinaId": 71366,
            "OpstinaNaziv": "SEVOJNO",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 142
        },
        {
            "OpstinaId": 70955,
            "OpstinaNaziv": "POŽEGA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 143
        },
        {
            "OpstinaId": 70629,
            "OpstinaNaziv": "KOSJERIĆ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 144
        },
        {
            "OpstinaId": 70068,
            "OpstinaNaziv": "BAJINA BAŠTA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 145
        },
        {
            "OpstinaId": 71234,
            "OpstinaNaziv": "ČAJETINA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 146
        },
        {
            "OpstinaId": 70041,
            "OpstinaNaziv": "ARILJE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 147
        },
        {
            "OpstinaId": 70980,
            "OpstinaNaziv": "PRIJEPOLJE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 148
        },
        {
            "OpstinaId": 71072,
            "OpstinaNaziv": "SJENICA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 149
        },
        {
            "OpstinaId": 70971,
            "OpstinaNaziv": "PRIBOJ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 150
        },
        {
            "OpstinaId": 70866,
            "OpstinaNaziv": "NOVA VAROŠ",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 151
        },
        {
            "OpstinaId": 70874,
            "OpstinaNaziv": "NOVI PAZAR",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 152
        },
        {
            "OpstinaId": 71021,
            "OpstinaNaziv": "RAŠKA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 153
        },
        {
            "OpstinaId": 71188,
            "OpstinaNaziv": "TUTIN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 154
        },
        {
            "OpstinaId": 70670,
            "OpstinaNaziv": "KRUŠEVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 155
        },
        {
            "OpstinaId": 71196,
            "OpstinaNaziv": "ĆIĆEVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 156
        },
        {
            "OpstinaId": 70378,
            "OpstinaNaziv": "VARVARIN",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 157
        },
        {
            "OpstinaId": 71170,
            "OpstinaNaziv": "TRSTENIK",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 158
        },
        {
            "OpstinaId": 70017,
            "OpstinaNaziv": "ALEKSANDROVAC",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 159
        },
        {
            "OpstinaId": 70343,
            "OpstinaNaziv": "BRUS",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 160
        },
        {
            "OpstinaId": 70998,
            "OpstinaNaziv": "PROKUPLJE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 161
        },
        {
            "OpstinaId": 70688,
            "OpstinaNaziv": "KURŠUMLIJA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 162
        },
        {
            "OpstinaId": 70262,
            "OpstinaNaziv": "BLACE",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 163
        },
        {
            "OpstinaId": 70548,
            "OpstinaNaziv": "ŽITORAĐA",
            "KOId": null,
            "KONaziv": null,
            "SluzbaId": 164
        }
    ]


export const getSluzbaNaziv = (opstinaId: any, KOid: any) => {
    // First, look for the KOid
    if (KOid) {
        const sluzba = sluzbeNadleznosti.find(item => item.KOId == KOid);
        if (sluzba) {
            const foundSluzba = sluzbe.find(s => s.SluzbaId === sluzba.SluzbaId);
            return foundSluzba ? foundSluzba.SluzbaNaziv : null;
        }
    }

    // If no KOid or no match, look for the OpstinaId
    const opstina = sluzbeNadleznosti.find(item => item.OpstinaId == opstinaId);
    if (opstina) {
        const foundSluzba = sluzbe.find(s => s.SluzbaId === opstina.SluzbaId);
        return foundSluzba ? foundSluzba.SluzbaNaziv : null;
    }

    return null;
};
