import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import KatakomInput from "../../shared/KatakomInput";
import { Row } from "../style";
import KatakomButton from "../../shared/KatakomButton";
import KatakomTable from "../../shared/KatakomTable";
import { NoDataTable } from "./NoDataTable";

interface Props {
    tableData: any;
    onRowClick: (item: any) => void;
    type: string;
}

export const SelectTable: FC<Props> = ({ tableData, onRowClick, type }) => {
    const [data, setData] = useState<any[]>([]);
    const [brojObjekta, setBrojObjekta] = useState<string>("");
    const [ulica, setUlica] = useState<string>("");
    const [kucniBroj, setKucniBroj] = useState<string>("");
    const [povrsina, setPovrsina] = useState<string>("");
    const [evidencijskiBroj, setEvidencijskiBroj] = useState<string>("");
    const [brojStana, setBrojStana] = useState<string>("");
    const [brojUlaza, setBrojUlaza] = useState<string>("");

    useEffect(() => {
        setData(tableData);
    }, [tableData]);

    useEffect(() => {
        const inputsArray = [brojObjekta, ulica, kucniBroj, povrsina, evidencijskiBroj, brojStana, brojUlaza];
        const areAllInputsEmpty = inputsArray.every((input) => !input);

        if (areAllInputsEmpty) {
            setData(tableData);
        }
    }, [brojObjekta, ulica, kucniBroj, povrsina, evidencijskiBroj, brojStana, brojUlaza, tableData]);

    const handleSearch = () => {
        let filteredData = [...tableData];

        if (type === "objekat") {
            filteredData = filteredData.filter((item) => {
                return (
                    (!brojObjekta || item?.BrDelaParc == brojObjekta) &&
                    (!ulica || item?.Ulica.toLowerCase().includes(ulica.toLowerCase())) &&
                    (!kucniBroj || item?.KucniBroj == kucniBroj) &&
                    (!povrsina || item?.Povrsina == povrsina)
                );
            });
        } else {
            filteredData = filteredData.filter((item) => {
                return (
                    (!evidencijskiBroj || item?.evidencijskiBroj == evidencijskiBroj) &&
                    (!brojStana || item?.brojStana == brojStana) &&
                    (!brojUlaza || item?.brojUlaza == brojUlaza) &&
                    (!povrsina || item?.povrsina == povrsina)
                );
            });
        }

        setData(filteredData);
    };

    let columns;

    if (type === "objekat") {
        columns = [
            { label: "Број објекта", value: "BrDelaParc" },
            { label: "Улица", value: "Ulica" },
            { label: "Кућни број", value: "KucniBroj" },
            { label: "Кућни подброј", value: "KucniPodbroj" },
            { label: "Површина", value: "Povrsina" },
        ];
    } else {
        columns = [
            { label: "Евиденцијски број", value: "evidencijskiBroj" },
            { label: "Број стана", value: "brojStana" },
            { label: "Подброј стана", value: "podbrojStana" },
            { label: "Број улаза", value: "brojUlaza" },
            { label: "Спрат шифра", value: "brojSprata" },
            { label: "Површина", value: "povrsina" },
        ];
    }

    if (type === "objekat") {
        return (
            <div style={{ maxWidth: "930px" }}>
                <Row style={{ marginLeft: "2px" }}>
                    <KatakomInput
                        type="text"
                        value={brojObjekta}
                        name="brojObjekta"
                        label=""
                        placeholder="Број објекта"
                        size="xs"
                        error={false}
                        theme="secondary"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setBrojObjekta(e.target.value);
                        }}
                        style={{ margin: "0 22px 0 0 " }}
                        tabIndex={0}
                    />
                    <KatakomInput
                        type="text"
                        value={ulica}
                        name="ulica"
                        label=""
                        placeholder="Улица"
                        size="m"
                        error={false}
                        theme="secondary"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setUlica(e.target.value);
                        }}
                        style={{ margin: "0 22px 0 0" }}
                    />
                    <KatakomInput
                        type="text"
                        value={kucniBroj}
                        name="kucniBroj"
                        label=""
                        placeholder="Кућни број"
                        size="xs"
                        error={false}
                        theme="secondary"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setKucniBroj(e.target.value);
                        }}
                        style={{ margin: "0 22px 0 0" }}
                    />

                    <KatakomInput
                        type="text"
                        value={povrsina}
                        name="povrsina"
                        label=""
                        placeholder="Површина"
                        size="xs"
                        error={false}
                        theme="secondary"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setPovrsina(e.target.value);
                        }}
                        style={{ margin: "0 22px 0 0" }}
                    />
                    <KatakomButton label={"Претражи"} theme="tertiary" onClick={handleSearch} />
                </Row>

                {data === null || data === undefined || data.length === 0 ? (
                    <NoDataTable />
                ) : (
                    <KatakomTable columns={columns} data={data ?? []} onRowClick={onRowClick} enableClick={true} />
                )}
            </div>
        );
    } else {
        return (
            <div style={{ maxWidth: "930px" }}>
                <Row style={{ marginLeft: "2px" }}>
                    <KatakomInput
                        type="text"
                        value={evidencijskiBroj}
                        name="brojParcele"
                        label=""
                        placeholder="Евиденцијски број"
                        size="m"
                        error={false}
                        theme="secondary"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setEvidencijskiBroj(e.target.value);
                        }}
                        style={{ margin: "0 22px 0 0 " }}
                        tabIndex={0}
                    />
                    <KatakomInput
                        type="text"
                        value={brojStana}
                        name="Број стана"
                        label=""
                        placeholder="Број стана"
                        size="xs"
                        error={false}
                        theme="secondary"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setBrojStana(e.target.value);
                        }}
                        style={{ margin: "0 22px 0 0" }}
                    />
                    <KatakomInput
                        type="text"
                        value={brojUlaza}
                        name="brojUlaza"
                        label=""
                        placeholder="Број улаза"
                        size="xs"
                        error={false}
                        theme="secondary"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setBrojUlaza(e.target.value);
                        }}
                        style={{ margin: "0 22px 0 0" }}
                    />

                    <KatakomInput
                        type="text"
                        value={povrsina}
                        name="podBrojParcele"
                        label=""
                        placeholder="Површина"
                        size="xs"
                        error={false}
                        theme="secondary"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setPovrsina(e.target.value);
                        }}
                        style={{ margin: "0 22px 0 0" }}
                    />
                    <KatakomButton
                        label={"Претражи"}
                        theme="tertiary"
                        onClick={() => {
                            handleSearch();
                        }}
                    />
                </Row>
                {data === null || data === undefined || data.length === 0 ? (
                    <NoDataTable />
                ) : (
                    <KatakomTable columns={columns} data={data ?? []} onRowClick={onRowClick} enableClick={true} maxHeight="300px" />
                )}
            </div>
        );
    }
};
