import React, { useEffect } from "react";
import KatakomInput from "../../../shared/KatakomInput";
import KatakomButton from "../../../shared/KatakomButton";
import styled from "styled-components";
import { KatastarCard } from "../style";
import KatakomSelect, { SelectOption } from "../../../shared/KatakomSelect";
import { katastarskeOpstineOptions } from "../../../../register/register";
import { useSidebar } from "../../../widgets/sidebar/SidebarContext";
import { CardContainer, CardHeader, CardTitle, CardWrapper, Column, LayoutWraper, Row } from "../../style";
import { RenderKatastarData } from "../renderKatastarData/RenderKatastarData";
import api from "../../../../services/api";

import useMobileCheck from "../../../../hooks/useMobileChecker";
import { backendUrl } from "../../../../constants/constants";
import axios from "axios";
import Cookies from "js-cookie";
import { setUser } from "../../../../store/UserReducer";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";

const PretragaPoListuNepokretnostiLayout: React.FC = () => {
    const [brojListaNepokretnosti, setBrojListaNepokretnosti] = React.useState<string>("");
    const [data, setData] = React.useState<any>(null);
    const [detrimendHeight, setDetrimendHeight] = React.useState<string>("calc(100vh - 120px)");
    const [koid, setKoid] = React.useState<string>("");
    const [matBrOpstine, setMatBrOpstine] = React.useState<string>("");
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [nelegalni, setNelegalni] = React.useState({});
    const [podbrojParcele, setPodbrojParcele] = React.useState();
    const [brojParcele, setBrojParcele] = React.useState();
    const isMobile = useMobileCheck();
    const user = useSelector((state: RootState) => state.user);

    const dispatch = useDispatch();

    useEffect(() => {
        if (data) {
            setDetrimendHeight("20vh");
        } else {
            setDetrimendHeight("calc(100vh - 120px)");
        }
    }, [data]);

    const fatchData = async () => {
        let queryParams: any = {};
        let nelegalniQueryParams: any = {};

        if (koid !== "") {
            queryParams.koid = koid;
        }

        if (brojListaNepokretnosti !== "") {
            queryParams.brojLN = brojListaNepokretnosti;
        }

        const res = await api.get(`api/v1/pretraga-parcele-po-listu-nepokretnosti`, queryParams);

        if (koid !== "") {
            nelegalniQueryParams.koId = koid;
        }

        if (res?.data?.OpstinaID !== "") {
            nelegalniQueryParams.matBrOpstine = res?.data?.OpstinaID;
        }

        if (res?.data?.Parcele?.Parcela?.BrParc !== "") {
            nelegalniQueryParams.brojParcele = res?.data?.Parcele?.Parcela?.BrParc;
            setBrojParcele(res?.data?.Parcele?.Parcela?.BrParc);
        }

        if (res?.data?.Parcele?.Parcela?.PodBrParc !== "") {
            if (res?.data?.Parcele?.Parcela?.PodBrParc !== "0") {
                nelegalniQueryParams.brojParcele += "/" + res?.data?.Parcele?.Parcela?.PodBrParc;
                setPodbrojParcele(res?.data?.Parcele?.Parcela?.PodBrParc);
            }
        }

        const nelegalniRes = await api.get(`api/v1/pretraga-nelegalnih-objekata`, nelegalniQueryParams);

        setNelegalni({
            total: nelegalniRes?.data?.meta?.total,
            objectIds: nelegalniRes?.data?.data?.map((item: any) => item.objekatId),
        });
        if (res?.data.length === 0) {
            setData(null);
            return;
        }
        setData(res?.data);

        const u = {
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            email: user.email,
            theme: user.theme,
            brojPretraga: user.brojPretraga + 1,
        };

        dispatch(setUser(u));
    };

    const redirectToRGZ = () => {
        let parcelNumber;
        if (podbrojParcele) {
            parcelNumber = brojParcele + "/" + podbrojParcele;
        } else {
            parcelNumber = brojParcele;
        }

        window.open(`https://portal.rgz.gov.rs/rgz-portal/map?cadmun=${koid}&parcelNumber=${parcelNumber}`, "_blank");
    };

    const redirectToDRR = () => {
        let parcelNumber;
        if (podbrojParcele) {
            parcelNumber = brojParcele + "/" + podbrojParcele;
        } else {
            parcelNumber = brojParcele;
        }

        window.open(`https://drr.geosrbija.rs/drr/map?cadmun=${koid}&parcelNumber=${parcelNumber}`, "_blank");
    };

    const redirectToNelegalni = () => {
        window.open(
            //@ts-ignore
            `https://portal.rgz.gov.rs/rgz-portal/map?cadmun=${koid}&parcelNumber=${brojParcele}&objectId=${nelegalni.objectIds[0]}`,
            "_blank"
        );
    };

    const { sidebar } = useSidebar();

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <KatastarCard height={detrimendHeight}>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>ПРЕТРАГА ПО ЛИСТУ НЕПОКРЕТНОСТИ</CardTitle>
                        </CardHeader>

                        {isMobile ? (
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", margin: "auto" }}>
                                <Row>
                                    <KatakomSelect
                                        options={katastarskeOpstineOptions}
                                        value={koid}
                                        name="koId"
                                        size="m"
                                        label="Општина - Катастарска општина*"
                                        error={false}
                                        onChange={(option: SelectOption) => {
                                            const koId = option.value.split("_")[3];
                                            const brOpstine = option.value.split("_")[2];

                                            setKoid(koId);
                                            setMatBrOpstine(brOpstine);
                                        }}
                                    />
                                </Row>
                                <Row>
                                    <KatakomInput
                                        type={"number"}
                                        value={brojListaNepokretnosti}
                                        name={""}
                                        label="Број листа непокретности*"
                                        error={false}
                                        onChange={(e) => {
                                            setBrojListaNepokretnosti(e.target.value);
                                        }}
                                        onEnter={fatchData}
                                    />
                                </Row>

                                <Row>
                                    <KatakomButton
                                        label={"ПРЕТРАЖИ"}
                                        onClick={fatchData}
                                        size="xl"
                                        style={{ margin: "auto", marginBottom: "20px" }}
                                    />
                                </Row>
                            </div>
                        ) : (
                            <Row style={{ marginTop: "0px" }}>
                                <KatakomSelect
                                    options={katastarskeOpstineOptions}
                                    value={koid}
                                    name="koId"
                                    size="m"
                                    label="Општина - Катастарска општина*"
                                    error={false}
                                    onChange={(option: SelectOption) => {
                                        const koId = option.value.split("_")[3];
                                        setKoid(koId);
                                    }}
                                    style={{ margin: "20px 20px 0px 20px" }}
                                />
                                <KatakomInput
                                    type={"number"}
                                    value={brojListaNepokretnosti}
                                    name={""}
                                    label="Број листа непокретности*"
                                    error={false}
                                    onChange={(e) => {
                                        setBrojListaNepokretnosti(e.target.value);
                                    }}
                                    style={{ margin: "20px 40px 0px 20px" }}
                                    onEnter={fatchData}
                                />
                                <KatakomButton label={"ПРЕТРАЖИ"} onClick={fatchData} />
                            </Row>
                        )}

                        {data !== null && !data ? <DataNotFound>*Нема података за унету вредност</DataNotFound> : null}
                    </CardContainer>
                </KatastarCard>
            </CardWrapper>

            {data ? (
                <RenderKatastarData
                    data={data}
                    nelegalni={nelegalni}
                    redirectToRGZ={redirectToRGZ}
                    redirectToDRR={redirectToDRR}
                    redirectToNelegalni={redirectToNelegalni}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    koid={koid}
                    brOpstine={matBrOpstine}
                    brojParcele={brojParcele}
                    podbrojParcele={podbrojParcele}
                />
            ) : null}
        </LayoutWraper>
    );
};

const DataNotFound = styled.div`
    display: flex;
    margin: 20px 50px 0px 30px;
    font-size: 15px;
    font-weight: 600;
`;

export default PretragaPoListuNepokretnostiLayout;
