import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import { HashRouter as Router } from "react-router-dom";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import CaseStatus from "./components/pages/caseStatus/CaseStatus";
import PretragaPoBrojuParacele from "./components/pages/katastar/pretragaPoBrojuParcele/PretragaPoBrojuParacele";
import PretragaPoListuNepokretnosti from "./components/pages/katastar/pretragaPoListuNepokretnosti/PretragaPoListuNepokretnosti";
import PretragaPoMaticnom from "./components/pages/katastar/pretragaPoMaticnom/PretragaPoMaticnom";
import LandingPage from "./components/pages/landing/Landing";
import LoginPage from "./components/pages/login/Login";
import NewCase from "./components/pages/newCase/NewCase";
import Preview from "./components/pages/newCase/caseSteps/preview/Preview";
import PretragaNelegalnihObjekata from "./components/pages/pretragaNelegalnihObjekata/PretragaNelegalnihObjekata";
import SettingsPage from "./components/pages/settings/Settings";
import { ChangePassword } from "./components/pages/settings/changePassword/ChangePassword";
import { useJwtValidation } from "./hooks/useJwtValidation";
import { CreateAccount } from "./components/pages/settings/createAccount/CreateAccount";
import NewCaseTip2 from "./components/pages/newCaseTip2/NewCaseTip2";
import PreviewTip2 from "./components/pages/newCaseTip2/caseSteps/preview/Preview";
import { AccountSettings } from "./components/pages/settings/accountSettings/AccountSettings";
import NewCaseAdvokati from "./components/pages/newCaseAdvokati/NewCaseAdvokati";
import PreviewAdvokati from "./components/pages/newCaseAdvokati/caseSteps/preview/Preview";
import PrivacePolicy from "./components/pages/privacePolicy/privacePolicy";
import EStatus from "./components/pages/eStatus/eStatus";
import { SiteUnderMaintenance } from "./SiteUnderMaintenace";
import Notifications from "./components/pages/notifications/notifications";
import api from "./services/api";
import { AppDispatch, useAppDispatch } from "./store";
import { updateNotificationCounter } from "./store/UserReducer";
import PretragaPoImenu from "./components/pages/katastar/pretragaPoImenu/PretragaPoImenu";
import UserManagement from "./components/pages/userManagement/userManagement";

function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useJwtValidation();

    if (!auth) {
        return <Navigate to="/login" />;
    }

    return children;
}

function LoginRedirect({ children }: { children: JSX.Element }) {
    let auth = useJwtValidation();

    if (auth) {
        return <Navigate to="/pocetna" />;
    }

    return children;
}

const App: React.FC = () => {
    const [notificationCounter, setNotificationCounter] = useState<number>(0);
    const dispatch: AppDispatch = useAppDispatch();

    useEffect(() => {
        const fetchNotificationCounter = async () => {
            try {
                const response = await api.get(`api/v1/get-notifications-count`);
                setNotificationCounter(response.data.count);
            } catch (error) {
                console.error("Error fetching notification counter:", error);
            }
        };

        fetchNotificationCounter();

        const intervalId = setInterval(fetchNotificationCounter, 60000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        dispatch(updateNotificationCounter(notificationCounter));
    }, [notificationCounter]);

    if (false) return <SiteUnderMaintenance />;

    return (
        <Router>
            <Routes>
                <Route path="*" element={<Navigate to="/pocetna" replace />} />

                <Route path="/api-docs" element={<SwaggerUI url={`${process.env.PUBLIC_URL}/openapi.yaml`} />} />
                <Route
                    path="/login"
                    element={
                        <LoginRedirect>
                            <LoginPage />
                        </LoginRedirect>
                    }
                />
                <Route path="/" element={<Navigate to="/pocetna" />} />
                <Route
                    path="/pocetna"
                    element={
                        <RequireAuth>
                            <LandingPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/statusZahteva"
                    element={
                        <RequireAuth>
                            <CaseStatus />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/eStatus"
                    element={
                        <RequireAuth>
                            <EStatus />
                        </RequireAuth>
                    }
                />
                {/* KATASTAR RUTE */}
                <Route
                    path="/pretraga"
                    element={
                        <RequireAuth>
                            <LandingPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/pretraga/pretragaPoImenu"
                    element={
                        <RequireAuth>
                            <PretragaPoImenu />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/pretraga/pretragaPoMaticnom"
                    element={
                        <RequireAuth>
                            <PretragaPoMaticnom />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/pretraga/pretragaPoListuNepokretnosti"
                    element={
                        <RequireAuth>
                            <PretragaPoListuNepokretnosti />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/pretraga/pretragaPoParceli"
                    element={
                        <RequireAuth>
                            <PretragaPoBrojuParacele />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/pretragaNelegalnihObjekata"
                    element={
                        <RequireAuth>
                            <PretragaNelegalnihObjekata />
                        </RequireAuth>
                    }
                />
                {/* IZVRSITELJ RUTE */}
                <Route
                    path="/noviPredmet"
                    element={
                        <RequireAuth>
                            <NewCase />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/noviPredmet/pregled"
                    element={
                        <RequireAuth>
                            <Preview />
                        </RequireAuth>
                    }
                />
                {/* ADVOKAT RUTE */}
                <Route
                    path="/noviPredmetKonverzija"
                    element={
                        <RequireAuth>
                            <NewCaseTip2 />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/noviPredmetKonverzija/pregled"
                    element={
                        <RequireAuth>
                            <PreviewTip2 />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/noviPredmetAdvokati"
                    element={
                        <RequireAuth>
                            <NewCaseAdvokati />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/noviPredmetAdvokati/pregled"
                    element={
                        <RequireAuth>
                            <PreviewAdvokati />
                        </RequireAuth>
                    }
                />

                {/* PODESAVANJA RUTE */}
                <Route
                    path="/podesavanja"
                    element={
                        <RequireAuth>
                            <SettingsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/podesavanja/promeniLozinku"
                    element={
                        <RequireAuth>
                            <ChangePassword />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/podesavanja/kreirajNalog"
                    element={
                        <RequireAuth>
                            <CreateAccount />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/podesavanja/podesavanjaNaloga"
                    element={
                        <RequireAuth>
                            <AccountSettings />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/privacePolicy"
                    element={
                        <RequireAuth>
                            <PrivacePolicy />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/obavestenja"
                    element={
                        <RequireAuth>
                            <Notifications />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/upravljanjeKorisnicima"
                    element={
                        <RequireAuth>
                            <UserManagement />
                        </RequireAuth>
                    }
                />
            </Routes>
        </Router>
    );
};

export default App;
