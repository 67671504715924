import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web

import UserReducer from "./UserReducer";
import CaseReducer from "./CaseReducer";
import KatastarReducer from "./KatastarReducer";
import PDFReducer from "./PDFReducer";
import CaseTip2Reducer from "./CaseTip2Reducer";
import CaseStatusReducer from "./CaseStatusReducer";
import CaseAdvokatiReducer from "./CaseAdvokatiReducer";
import CaseDopunaReducer from "./CaseDopunaReducer";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["user"],
};

const persistedReducer = persistReducer(
    persistConfig,
    combineReducers({
        user: UserReducer,
        case: CaseReducer,
        caseTip2: CaseTip2Reducer,
        caseAdvokati: CaseAdvokatiReducer,
        caseDopuna: CaseDopunaReducer,
        katastar: KatastarReducer,
        pdf: PDFReducer,
        caseStatus: CaseStatusReducer,
    })
);

export const store = configureStore({
    reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
