import { FC } from "react";
import styled from "styled-components";
import { themeColors, themeIcon } from "../../constants/constants";

interface Props {
    message: string;
}

export const WaitingModal: FC<Props> = ({ message }) => {
    return (
        <ModalContainer>
            <ModalIcon />
            <ModalTitle>{message}</ModalTitle>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
    width: 500px;
    height: 400px;
    background-color: ${themeColors.waitingModalBackgroundColor};
    display: flex;
    flex-direction: column;
    text-align: center;
    position: absolute;
    top: 20%;
    overflow: hidden;
    margin-left: -200px;

    &:before {
        content: "";
        position: absolute;
        top: -30px;
        right: 0;
        bottom: 0;
        left: -80px;
        width: 250px;
        height: 320px;
        background-image: url(${themeIcon}/exclamationMarkBackground.png);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: contain;
        opacity: 1;
    }

    @media screen and (max-width: 900px) {
        margin-left: -500px;
    }
`;

const ModalIcon = styled.div`
    width: 100px;
    height: 100px;
    margin: 30px auto;
    background-image: url(${themeIcon}/exclamation.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;
`;

const ModalTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
`;
