import { svojstvoLicaOption } from "../../../../../../register/register";
import { Aplicant } from "../../../../../../store/CaseReducer";
import KatakomSelect, { SelectOption } from "../../../../../shared/KatakomSelect";
import { Row } from "../../../../style";

export const RepublikaSrpska = ({ formData, setFormData }: { formData: Aplicant; setFormData: (aplicant: Aplicant) => void }) => {
    return (
        <>
            <Row>
                <KatakomSelect
                    options={svojstvoLicaOption}
                    value={formData.svojstvoLica || ""}
                    name="svojstvoLica"
                    label="Својство лица*"
                    size="s"
                    error={false}
                    onChange={(option: SelectOption) => setFormData({ ...formData, svojstvoLica: String(option.value) })}
                    style={{ margin: "0 30px 0 0 " }}
                />
            </Row>
        </>
    );
};
