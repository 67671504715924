export enum VrstaIspraveId {
    RESENJE_O_IZVRSENJU = "Решење о извршењу",
    ZAKLJUCAK_O_PREDAJI_NEPOKRETNOSTI = "Закључак о предаји непокретности",
    ZAKLJUCAK_O_SPROVODJENJU_NA_NEPOKRETNOSTI = "Закључак о спровођењу на непокретности",
    RESENJE_O_PRIVREMENOJ_MERI = "Решење о привременој мери",
    RESENJE_O_PRETHODNOJ_MERI = "Решење о претходној мери",
    ZAKLJUCAK_O_STAVLJANJU_VAN_SNAGE_ZAKLJUCKA_O_SPROVODJENJU_NA_NEPOKRETNOSTI = "Закључак о стављању ван снаге закључка о спровођењу на непокретности",
    RESENJE_O_UKIDANJU_PRIVREMENE_MERE = "Решење о укидању привремене мере",
    RESENJE_O_OBUSTAVI_POSTUPKA = "Решење о обустави поступка",
    RESENJE_O_UKIDANJU_PRETHODNE_MERE = "Решење о укидању претходне мере",
}

export enum VrstaNepokretnosti {
    PARCELA = "Парцела",
    OBJEKAT = "Објекат",
    POSEBAN_DEO = "Посебан део",
}
