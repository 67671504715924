import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { themeColors } from "../../../../../constants/constants";
import { RootState } from "../../../../../store";
import { mapKeyToDisplayString } from "../../../../../utils/translate";
import { PreviewSection } from "./RenderOsnovniPodaci";

const PreviewSectionTitle = styled.div`
    border-bottom: 2px solid ${themeColors.previewSectionTitleBorder};
    margin-bottom: 10px;
    width: 95%;
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 5px;
`;

export const RenderPodnosioci = () => {
    const data = useSelector((state: RootState) => state.caseTip2.ucesnici);

    return (
        <PreviewSection>
            {data.map((podnosioc, index) => (
                <React.Fragment key={index}>
                    <div style={{ margin: "10px 10px" }}>
                        <PreviewSectionTitle>
                            <span>{index + 1}. </span>
                            {podnosioc.ime ? <Value>{podnosioc.ime} </Value> : null}
                            {podnosioc.prezime ? <Value>{podnosioc.prezime}</Value> : null}
                            {podnosioc.imePravnogLica ? <Value>{podnosioc.imePravnogLica}</Value> : null}
                        </PreviewSectionTitle>
                        {Object.entries(podnosioc).map(([key, option]) =>
                            key !== "ime" && key !== "prezime" && key !== "imePravnogLica" && mapKeyToDisplayString(key) && option ? (
                                <PreviewComponent key={key}>
                                    <Key>{mapKeyToDisplayString(key)}:</Key>
                                    <Value>{option?.label || option}</Value>
                                </PreviewComponent>
                            ) : null
                        )}
                    </div>
                </React.Fragment>
            ))}
        </PreviewSection>
    );
};

const PreviewComponent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 5px;
    margin-left: 20px;
`;

const Key = styled.span`
    margin-right: 5px;
`;

const Value = styled.span``;
