import { ChangeEvent } from "react";
import { AplicantTip2 } from "../../../../../../store/CaseTip2Reducer";
import KatakomInput from "../../../../../shared/KatakomInput";
import { Row } from "../../../../style";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

export const FizickoStranac = ({ formData, setFormData }: { formData: AplicantTip2; setFormData: (aplicant: AplicantTip2) => void }) => {
    const vrstaIspraveId = useSelector((state: RootState) => state.caseAdvokati.basicInfo.vrstaIspraveId);

    if (vrstaIspraveId === "6105") {
        return (
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.brojPasosa || ""}
                    name="brojPasosa"
                    label="Број пасоша*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, brojPasosa: e.target.value })}
                />
            </Row>
        );
    }

    return (
        <>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.ime || ""}
                    name="ime"
                    label="Име*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, ime: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.prezime || ""}
                    name="prezime"
                    label="Презиме*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, prezime: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.imeRoditelja || ""}
                    name="imeRoditelja"
                    label="Име родитеља*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, imeRoditelja: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.brojPasosa || ""}
                    name="brojPasosa"
                    label="Број пасоша*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, brojPasosa: e.target.value })}
                />
            </Row>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.ssn || ""}
                    name="ssn"
                    label="SSN(Social Security Number)"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, ssn: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.drzavaNaziv || ""}
                    name="drzavaNaziv"
                    label="Држава*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, drzavaNaziv: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.mestoNaziv || ""}
                    name="mestoNaziv"
                    label="Место*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, mestoNaziv: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.ulicaRucno || ""}
                    name="ulicaRucno"
                    label="Улица*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, ulicaRucno: e.target.value })}
                />
            </Row>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.kucniBroj || ""}
                    name="kucniBroj"
                    label="Кућни број*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, kucniBroj: e.target.value })}
                />
            </Row>
        </>
    );
};
