import SettingsPage from "../Settings";
import CreateAccountLayout from "./CreateAccountLayout";

export const CreateAccount = () => {
    return (
        <SettingsPage>
            <CreateAccountLayout />
        </SettingsPage>
    );
};
