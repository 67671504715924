import React, { FC } from "react";
import KatakomButton from "../../../../shared/KatakomButton";
import { Row } from "../../../style";
import { KatastarElementBody, KatastarElementBodyRow, Key, Value } from "../style";

interface Props {
    closeModal: () => void;
    data: any;
}

const LicaModalNew: FC<Props> = ({ closeModal, data }) => {
    const Lice = (lice: any) => {
        return (
            <KatastarElementBody>
                <KatastarElementBodyRow>
                    <Key>Назив:</Key>
                    <Value>{lice?.lice?.Naziv ?? `${lice?.lice?.Ime} ${lice.lice.Prezime}`}</Value>
                </KatastarElementBodyRow>
                <KatastarElementBodyRow>
                    <Key>Адреса:</Key>
                    <Value>
                        {lice.lice.Adresa !== " /" ? `${lice.lice.Adresa.slice(0, -1)}, ` : null} {lice.lice.Mesto}
                    </Value>
                </KatastarElementBodyRow>
                <KatastarElementBodyRow>
                    <Key>Матични број:</Key>
                    <Value>{lice.lice.MaticniBroj}</Value>
                </KatastarElementBodyRow>
                <div style={{ borderBottom: "1px solid black", paddingBottom: "20px" }}></div>
            </KatastarElementBody>
        );
    };

    const RenderLica = () => {
        if (data?.Lice?.Naziv || data?.Lice?.Ime) {
            return <Lice lice={data.Lice} />;
        }
        if (Object.keys(data.Lice).length === 0) {
            return <div>*За тражено лице не постоје вредности!</div>;
        } else {
            {
                return data.Lice.map((lice: any) => {
                    return <Lice lice={lice} />;
                });
            }
        }
    };

    return (
        <React.Fragment>
            <RenderLica />
            <Row style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
                <KatakomButton label={"Затвори"} onClick={closeModal} theme="secondary" />
            </Row>
        </React.Fragment>
    );
};

export default LicaModalNew;
