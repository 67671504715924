import SettingsPage from "../Settings";
import ChangePasswordLayout from "./ChangePasswordLayout";

export const ChangePassword = () => {
    return (
        <SettingsPage>
            <ChangePasswordLayout />
        </SettingsPage>
    );
};
