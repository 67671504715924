import React, { useState, useEffect } from "react";
import WelcomeUser from "../../customComponent/WelcomeUser";
import PageLayout from "../../shared/PageLayout";
import Snackbar from "../../widgets/snackbar/Snackbar";

import useMobileCheck from "../../../hooks/useMobileChecker";

const LandingPage: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(localStorage.getItem("isVisible") === null);
    const isMobile = useMobileCheck();

    useEffect(() => {
        if (isVisible) {
            localStorage.setItem("isVisible", "false");
        }
    }, [isVisible]);

    return (
        <PageLayout>
            {isMobile ? null : <Snackbar message="Успешно сте се улоговали!" isVisible={isVisible} />}
            <WelcomeUser />
        </PageLayout>
    );
};

export default LandingPage;
