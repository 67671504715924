import React, { useState } from "react";
import ProgressBar from "../../widgets/progressbar/ProgressBar";
import KatakomButton from "../../shared/KatakomButton";
import AddBasicInfo from "./caseSteps/AddBasicInfo";
import AddAssets from "./caseSteps/addAssets/AddAssets";
import AddAplicants from "./caseSteps/addAplicants/AddAplicants";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useNavigate } from "react-router-dom";
import AddDocuments from "./caseSteps/addDocument/AddDocuments";
import { useSidebar } from "../../widgets/sidebar/SidebarContext";
import { ButtonGroup, Card, CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper } from "../style";
import { checkStepValidation } from "../../../utils/checkStepValidation";
import useMobileCheck from "../../../hooks/useMobileChecker";
import { pdf } from "@react-pdf/renderer";
import KatakomModal from "../../shared/KatakomModal";
import { SuccessMessageModal } from "../../customComponent/SuccessMessageModal";
import { ErrorMessageModal } from "../../customComponent/ErrorMessageModal";
import moment from "moment";
import GeneratePDFIzvrsitelj from "../../pdfs/IzvrsiteljPDF";

const NewCaseLayout: React.FC = () => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const steps = ["основни подаци", "непокретности", "странке", "документа"];
    const slucaj = useSelector((state: RootState) => state.case);
    const isMobile = useMobileCheck();
    const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
    const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);

    let navigate = useNavigate();
    const { sidebar } = useSidebar();
    const { disabled, tooltipMessage } = checkStepValidation(currentStep, slucaj);

    const routeChange = (path: string) => {
        navigate(path);
    };

    const incrementStep = () => {
        if (currentStep < steps.length) {
            setCurrentStep(currentStep + 1);
        }
        if (currentStep === steps.length) {
            routeChange("/noviPredmet/pregled");
        }
        if (currentStep === 3) {
            downloadPdf();
        }
    };

    const decrementStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleStepClick = (stepIndex: number) => {
        setCurrentStep(stepIndex);
    };

    const componentToRender = () => {
        switch (currentStep) {
            case 1:
                return <AddBasicInfo setCurrentStep={() => setCurrentStep(currentStep + 1)} />;
            case 2:
                return <AddAssets setCurrentStep={() => setCurrentStep(currentStep + 1)} />;
            case 3:
                return <AddAplicants setCurrentStep={() => setCurrentStep(currentStep + 1)} />;
            case 4:
                return <AddDocuments setCurrentStep={() => setCurrentStep(currentStep + 1)} />;
            default:
                return <AddBasicInfo setCurrentStep={() => setCurrentStep(currentStep + 1)} />;
        }
    };

    const downloadPdf = async () => {
        try {
            const blob = await pdf(<GeneratePDFIzvrsitelj pdfData={slucaj} />).toBlob();
            // Create a URL for the blob
            const url = URL.createObjectURL(blob);

            const fileName = slucaj.ucesnici?.[0]?.ime
                ? slucaj.ucesnici?.[0]?.ime + " " + slucaj.ucesnici?.[0]?.prezime
                : slucaj.ucesnici?.[0]?.imePravnogLica;

            const a = document.createElement("a");
            a.href = url;
            a.download = `${fileName}_${moment().format("DD_MM_YYYY")}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            setSuccessModalOpen(true);
        } catch (e) {
            setErrorModalOpen(true);
        }
    };

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <KatakomModal isOpen={successModalOpen} setIsOpen={setSuccessModalOpen}>
                    <SuccessMessageModal
                        closeModal={() => {
                            setSuccessModalOpen(false);
                        }}
                        message="Документ успешно преузет!"
                    />
                </KatakomModal>

                <KatakomModal isOpen={errorModalOpen} setIsOpen={setErrorModalOpen}>
                    <ErrorMessageModal
                        closeModal={() => {
                            setErrorModalOpen(false);
                        }}
                        message="Дошло је до грешке приликом преузимања документа!"
                    />
                </KatakomModal>
                <Card>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>НОВИ ПРЕДМЕТ/ЗАХТЕВ</CardTitle>
                        </CardHeader>
                        {isMobile ? null : (
                            <>
                                <ProgressBar
                                    steps={steps}
                                    currentStep={currentStep}
                                    onStepClick={handleStepClick}
                                    validateStep={checkStepValidation}
                                    slucaj={slucaj}
                                />
                            </>
                        )}

                        {componentToRender()}
                        <ButtonGroup>
                            <KatakomButton size="l" label={"Назад"} onClick={decrementStep} />
                            <KatakomButton
                                size="l"
                                label={"Следеће"}
                                onClick={incrementStep}
                                disabled={disabled}
                                tooltipMessage={tooltipMessage}
                            />
                        </ButtonGroup>
                    </CardContainer>
                </Card>
            </CardWrapper>
        </LayoutWraper>
    );
};

export default NewCaseLayout;
