import React, { useEffect, useState } from "react";
import { useSidebar } from "../../widgets/sidebar/SidebarContext";
import { Card, CardContainer, CardHeader, CardTitle, CardWrapper, LayoutWraper } from "../style";
import UserManagementList from "./userManagementList";

const UserManagementLayout: React.FC = () => {
    const { sidebar } = useSidebar();

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <Card>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>Управљање корисницима</CardTitle>
                        </CardHeader>
                        <UserManagementList />
                    </CardContainer>
                </Card>
            </CardWrapper>
        </LayoutWraper>
    );
};

export default UserManagementLayout;
