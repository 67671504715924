import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface BasicInfoAdvokati {
    vrstaIspraveId: string;
}
export interface CaseAdvokatiState {
    basicInfo: BasicInfoAdvokati;
    nepokretnostKN: AssetsAdvokati[];
    ucesnici: AplicantAdvokati[];
    dokumenta: DocumentAdvokati[];
    tempDokumenta: LoadDocumentAdvokati[];
    loading: boolean;
    error: string | null;
}

export interface AssetsAdvokati {
    udeo?: string | number;
    povrsina?: string;
    id?: number;
    tip: string;
    podBrojParcele: string | number;
    opstinaNaziv: string;
    koNaziv: string;
    opstinaId: string | number;
    koId: string;
    brojParcele: string | number;
    brojDelaParcele?: string | number;
    brojObjekta: string | number;
    evidencijskiBroj: string | number;
    brojVoda?: string;
    opisVoda?: string;
    vlasnik?: string;
    list?: string;
}

export interface AplicantAdvokati {
    id?: number;
    vrstaLica?: VrstaLica;
    ime?: string;
    prezime?: string;
    imeRoditelja?: string;
    jmbg?: string;
    brojPasosa?: string;
    brojLicneKarte?: string;
    ssn?: string;
    maticniBroj?: string;
    pib?: string;
    imePravnogLica?: string;
    email?: string;
    drzavaNaziv?: string;
    flagKIM?: boolean;
    opstinaSifra?: string;
    opstinaNaziv?: string;
    mestoSifra?: string;
    mestoNaziv?: string;
    ulicaSifra?: string;
    ulicaNaziv?: string;
    ulicaRucno?: string;
    kucniBroj?: string;
    postanskiBroj?: string;
    flagUlicaRucno?: boolean;
    brojUpisaURegistarDrzaveSedista?: string;
    nazivRegistra?: string;
    flagPrebivalisteInostranstvo?: boolean;
}

export interface LoadDocumentAdvokati {
    id: number;
    name: string;
    file: string;
    isSigned: boolean;
    category: string;
    vrstaIspraveId: string;
    index: string;
}

export interface DocumentAdvokati {
    id_prijem_fajl_esalter: number;
}

export enum VrstaLica {
    FIZICKO_DOMACE = "физичко-домаће",
    FIZICKO_STRANAC = "физичко-странац",
    PRAVNO_DOMACE = "правно-домаће",
    PRAVNO_STRANAC = "правно-странац",
}

const initialState: CaseAdvokatiState = {
    basicInfo: {
        vrstaIspraveId: "6031",
    },
    nepokretnostKN: [],
    ucesnici: [],
    dokumenta: [],
    tempDokumenta: [],
    loading: false,
    error: null,
};

export const caseAdvokatiSlice = createSlice({
    name: "caseAdvokati",
    initialState,
    reducers: {
        resetState: () => initialState,
        updateBasicInfo: (state, action: PayloadAction<Partial<BasicInfoAdvokati>>) => {
            state.basicInfo = { ...state.basicInfo, ...action.payload };
        },
        resetBasicInfo: (state) => {
            state.basicInfo = { ...initialState.basicInfo };
        },
        addOrUpdateNepokretnostAdvokati: (state, action: PayloadAction<Partial<AssetsAdvokati>>) => {
            const formData = action.payload;
            if (formData.id === undefined) {
                // If id is not provided, generate a new id for the new "nepokretnost"
                //@ts-ignore
                const newId = state.nepokretnostKN.length === 0 ? 1 : state.nepokretnostKN[state.nepokretnostKN.length - 1].id + 1;
                //@ts-ignore
                state.nepokretnostKN.push({ ...formData, id: newId });
            } else {
                // If id is provided, update the existing "nepokretnost" data
                const existingNepokretnostIndex = state.nepokretnostKN.findIndex((nep) => nep.id === formData.id);
                if (existingNepokretnostIndex !== -1) {
                    state.nepokretnostKN[existingNepokretnostIndex] = { ...state.nepokretnostKN[existingNepokretnostIndex], ...formData };
                }
            }
        },
        deleteNepokretnostAdvokati: (state, action: PayloadAction<number>) => {
            const nepokretnostId = action.payload;
            const updatedNepokretnosti = state.nepokretnostKN.filter((nep) => nep.id !== nepokretnostId);
            state.nepokretnostKN = updatedNepokretnosti;
        },
        addOrUpdateUcesnikAdvokati: (state, action: PayloadAction<Partial<AssetsAdvokati>>) => {
            const formData = action.payload;
            if (formData.id === undefined) {
                //@ts-ignore
                const newId = state.ucesnici.length === 0 ? 1 : state.ucesnici[state.ucesnici.length - 1].id + 1;
                //@ts-ignore
                state.ucesnici.push({ ...formData, id: newId });
            } else {
                const existingUcesniciIndex = state.ucesnici.findIndex((pod) => pod.id === formData.id);
                if (existingUcesniciIndex !== -1) {
                    state.ucesnici[existingUcesniciIndex] = { ...state.ucesnici[existingUcesniciIndex], ...formData };
                }
            }
        },
        deleteUcesnikAdvokati: (state, action: PayloadAction<number>) => {
            const ucesnikId = action.payload;
            const updatedUcesnici = state.ucesnici.filter((pod) => pod.id !== ucesnikId);
            state.ucesnici = updatedUcesnici;
        },
        addTempDocumentAdvokati: (state, action: PayloadAction<Partial<LoadDocumentAdvokati>>) => {
            const newId = state.tempDokumenta.length === 0 ? 1 : state.tempDokumenta[state.tempDokumenta.length - 1].id + 1;
            //@ts-ignore
            state.tempDokumenta.push({ ...action.payload, id: newId });
        },
        deleteTempDocumentAdvokati: (state, action: PayloadAction<{ vrstaIspraveId: string; name: string }>) => {
            const { vrstaIspraveId, name } = action.payload;
            const updatedDocuments = state.tempDokumenta.filter((doc) => {
                return !(doc.vrstaIspraveId === vrstaIspraveId && doc.name === name);
            });
            state.tempDokumenta = updatedDocuments;
        },

        addDocumentAdvokati: (state, action: PayloadAction<DocumentAdvokati[]>) => {
            state.dokumenta = action.payload;
        },
    },
});

export const {
    resetState,
    updateBasicInfo,
    resetBasicInfo,
    addOrUpdateNepokretnostAdvokati,
    deleteNepokretnostAdvokati,
    addOrUpdateUcesnikAdvokati,
    deleteUcesnikAdvokati,
    addTempDocumentAdvokati,
    deleteTempDocumentAdvokati,
    addDocumentAdvokati,
} = caseAdvokatiSlice.actions;
export default caseAdvokatiSlice.reducer;
