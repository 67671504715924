import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../../store";
import KatakomSelect, { SelectOption } from "../../../../shared/KatakomSelect";
import KatakomInput from "../../../../shared/KatakomInput";
import { ChangeEvent } from "react";
import KatakomButton from "../../../../shared/KatakomButton";
import { katastarskeOpstineOptions, vrstaNepokretnostiOptions } from "../../../../../register/register";
import { Assets, addOrUpdateNepokretnost } from "../../../../../store/CaseReducer";
import { defaultAssets } from "../../../../../register/defaultValues";
import KatakomModal from "../../../../shared/KatakomModal";
import { VrstaNepokretnosti } from "../../../../../enums";
import { ModalContent, Row } from "../../../style";
import { InfoMessageModal } from "../../../../customComponent/InfoMessageModal";
import api from "../../../../../services/api";

interface Props {
    closeModal: () => void;
    nepokretnostId?: number;
    setEditingNepokretnost: (id: Assets | null) => void;
    modalState: boolean;
}

const AssetModalComponent: FC<Props> = ({ closeModal, nepokretnostId, setEditingNepokretnost }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const nepokretnostKN = useSelector((state: RootState) => state.case.nepokretnostKN);
    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [firstTimeEdit, setFirstTimeEdit] = React.useState<boolean>(true);
    const [validatingParcelaDisabled, setValidatingParcelaDisabled] = React.useState<boolean>(false);

    const [formData, setFormData] = useState<Assets>(defaultAssets);

    useEffect(() => {
        if (nepokretnostId === undefined || (nepokretnostId !== undefined && !firstTimeEdit)) {
            setFormData({ ...defaultAssets, tip: formData.tip, id: nepokretnostId });
        }
        if (firstTimeEdit && formData.tip !== VrstaNepokretnosti.PARCELA) {
            setFirstTimeEdit(false);
        }
    }, [formData.tip]);

    useEffect(() => {
        if (nepokretnostId !== undefined) {
            const existingNepokretnost = nepokretnostKN.find((nep) => nep.id === nepokretnostId);
            if (existingNepokretnost) {
                setFormData(existingNepokretnost);

                if (
                    existingNepokretnost.tip !== VrstaNepokretnosti.OBJEKAT &&
                    existingNepokretnost.tip !== VrstaNepokretnosti.POSEBAN_DEO
                ) {
                    setFormData((prevData) => ({
                        ...prevData,
                        brojObjekta: "",
                        evidencijskiBroj: "",
                    }));
                } else if (existingNepokretnost.tip === VrstaNepokretnosti.POSEBAN_DEO) {
                    setFormData((prevData) => ({
                        ...prevData,
                        brojObjekta: existingNepokretnost.brojObjekta || "",
                    }));
                }
            }
        } else {
            setFormData(defaultAssets);
        }
    }, [nepokretnostId, nepokretnostKN]);

    useEffect(() => {
        setDisabled(isDisabled());
    }, [nepokretnostKN, formData]);

    const isDisabled = (): boolean => {
        if (Object.values(formData).length === 0) {
            return true;
        }

        if (formData.tip === VrstaNepokretnosti.PARCELA && (!formData.koId || !formData?.brojParcele)) {
            return true;
        }

        if (formData.tip === VrstaNepokretnosti.OBJEKAT && (!formData.koId || !formData.brojParcele || !formData.brojObjekta)) {
            return true;
        }

        if (
            formData.tip === VrstaNepokretnosti.POSEBAN_DEO &&
            (!formData.koId || !formData.brojParcele || !formData.brojObjekta || !formData.evidencijskiBroj)
        ) {
            return true;
        }
        return false;
    };

    const handleSave = async () => {
        if (formData.tip !== VrstaNepokretnosti.OBJEKAT && formData.tip !== VrstaNepokretnosti.POSEBAN_DEO) {
            formData.brojObjekta = "";
        }

        if (formData.tip !== VrstaNepokretnosti.POSEBAN_DEO) {
            formData.evidencijskiBroj = "";
        }

        const povrsina = await validateParcelaExists();
        setValidatingParcelaDisabled(false);

        if (povrsina) {
            dispatch(addOrUpdateNepokretnost(formData));
            setEditingNepokretnost(null);
            closeModal();
        } else {
            setOpenModal(true);
        }
    };

    const handleClose = () => {
        setEditingNepokretnost(null);
        closeModal();
    };

    const validateParcelaExists = async () => {
        setValidatingParcelaDisabled(true);
        let queryParams: any = {};

        if (formData.koId !== "") {
            queryParams.koid = formData.koId;
        }

        if (formData.brojParcele !== "") {
            queryParams.brojParcele = formData.brojParcele;
        }

        if (formData.podBrojParcele !== "") {
            queryParams.podbrojParcele = formData.podBrojParcele;
        } else {
            queryParams.podbrojParcele = "0";
        }

        if (formData.brojObjekta !== "") {
            queryParams.brojObjekta = formData.brojObjekta;
        }

        if (formData.evidencijskiBroj !== "") {
            queryParams.evidencijskiBroj = formData.evidencijskiBroj;
        }

        try {
            const res = await api.get(`api/v1/pretraga-parcele-po-broju-parcele`, queryParams);
            if (res?.data?.Parcele?.Parcela?.ID || res?.data?.Parcele?.Parcela?.length > 0) {
                return res?.data?.Parcele?.Parcela?.Povrsina ?? res?.data?.Parcele?.Parcela?.[0]?.Povrsina;
            } else {
                return false;
            }
        } catch (e) {
            setValidatingParcelaDisabled(false);
            setOpenModal(true);
        }
    };

    return (
        <ModalContent>
            <KatakomModal isOpen={openModal} setIsOpen={setOpenModal}>
                <InfoMessageModal
                    closeModal={() => {
                        setOpenModal(false);
                    }}
                    message="Парцела са унетим подацима не постоји у Катастру."
                />
            </KatakomModal>
            <Row>
                <KatakomSelect
                    options={vrstaNepokretnostiOptions}
                    value={formData.tip || ""}
                    name="tip"
                    size="xl"
                    label="Врста непокретности*"
                    error={false}
                    onChange={(option: SelectOption) => setFormData({ ...formData, tip: option.value })}
                />
                <KatakomSelect
                    options={katastarskeOpstineOptions}
                    value={formData.koId || ""}
                    name="koId"
                    size="xl"
                    label="Општина - Катастарска општина*"
                    error={false}
                    onChange={(option: SelectOption) => {
                        const opstinaNaziv = option.value.split("_")[0];
                        const koNaziv = option.value.split("_")[1];
                        const opstinaId = option.value.split("_")[2];
                        const koId = option.value.split("_")[3];

                        setFormData({ ...formData, opstinaNaziv, koNaziv, opstinaId, koId });
                    }}
                    style={{ margin: "0 0 0 30px" }}
                />
            </Row>
            <Row>
                <KatakomInput
                    type="text"
                    value={formData.brojParcele || ""}
                    name="brojParcele"
                    label="Број парцеле*"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, brojParcele: e.target.value })}
                    style={{ margin: "0 30px 0 0 " }}
                />
                <KatakomInput
                    type="text"
                    value={formData.podBrojParcele || ""}
                    name="podBrojParcele"
                    label="Подброј парцеле"
                    size="s"
                    error={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, podBrojParcele: e.target.value })}
                    style={{ margin: "0 36px 0 0" }}
                />
                {formData.tip === VrstaNepokretnosti.OBJEKAT || formData.tip === VrstaNepokretnosti.POSEBAN_DEO ? (
                    <KatakomInput
                        type="text"
                        value={formData.brojObjekta || ""}
                        name="brojObjekta"
                        label="Број објекта*"
                        size="s"
                        error={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, brojObjekta: e.target.value })}
                        style={{ margin: "0 24px 0 0" }}
                    />
                ) : null}
                {formData.tip === VrstaNepokretnosti.POSEBAN_DEO ? (
                    <KatakomInput
                        type="text"
                        value={formData.evidencijskiBroj || ""}
                        name="evidencijskiBroj"
                        label="Евиденцијски број посебног дела објекта*"
                        size="s"
                        error={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, evidencijskiBroj: e.target.value })}
                    />
                ) : null}
            </Row>
            <Row style={{ justifyContent: "flex-end", marginLeft: "-220px" }}>
                <KatakomButton
                    label={"Сачувај"}
                    disabled={disabled || validatingParcelaDisabled}
                    tooltipMessage={validatingParcelaDisabled ? "Проверава се валидност парцеле..." : ""}
                    onClick={async () => {
                        await handleSave();
                    }}
                />
                <KatakomButton label={"Одустани"} onClick={handleClose} theme="secondary" style={{ marginLeft: "30px" }} />
            </Row>
        </ModalContent>
    );
};

export default AssetModalComponent;
