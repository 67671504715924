import React, { useEffect, useState } from "react";
import KatakomInput from "../../../shared/KatakomInput";
import KatakomButton from "../../../shared/KatakomButton";
import styled from "styled-components";
import { KatastarCard } from "../style";
import KatakomSelect, { SelectOption } from "../../../shared/KatakomSelect";
import { katastarskeOpstineOptions } from "../../../../register/register";
import { useSidebar } from "../../../widgets/sidebar/SidebarContext";
import { CardContainer, CardHeader, CardTitle, CardWrapper, Column, LayoutWraper, Row } from "../../style";
import { RenderKatastarData } from "../renderKatastarData/RenderKatastarData";

import api from "../../../../services/api";
import useMobileCheck from "../../../../hooks/useMobileChecker";
import { RowWrapper } from "../pretragaPoImenu/PretragaPoImenuLayout";
import Cookies from "js-cookie";
import axios from "axios";
import { backendUrl } from "../../../../constants/constants";
import { setUser } from "../../../../store/UserReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";

const PretragaPoBrojuParaceleLayout: React.FC = () => {
    const [koid, setKoid] = React.useState<string>("");
    const [matBrOpstine, setMatBrOpstine] = React.useState<string>("");
    const [brojParcele, setBrojParcele] = React.useState<string>("");
    const [data, setData] = React.useState<any>(null);
    const [detrimendHeight, setDetrimendHeight] = React.useState<string>("calc(100vh - 120px)");
    const [nelegalni, setNelegalni] = React.useState({});
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const isMobile = useMobileCheck();
    const user = useSelector((state: RootState) => state.user);

    const dispatch = useDispatch();

    const { sidebar } = useSidebar();

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (data) {
            setDetrimendHeight("20vh");
        } else {
            setDetrimendHeight("calc(100vh - 120px)");
        }
    }, [data]);

    const fatchData = async () => {
        setActiveTab(0);
        let queryParams: any = {};
        let nelegalniQueryParams: any = {};

        queryParams.shouldCount = true;

        if (koid !== "") {
            queryParams.koid = koid;
            nelegalniQueryParams.koId = koid;
        }

        if (matBrOpstine !== "") {
            nelegalniQueryParams.matBrOpstine = matBrOpstine;
        }

        let [brojParceleValue, podbrojParceleValue] = brojParcele.split("/");

        if (!podbrojParceleValue) {
            podbrojParceleValue = "0";
        }

        queryParams.brojParcele = brojParceleValue;
        queryParams.podbrojParcele = podbrojParceleValue;

        nelegalniQueryParams.brojParcele = brojParceleValue;
        if (podbrojParceleValue !== "0") {
            nelegalniQueryParams.brojParcele += "/" + podbrojParceleValue;
        }

        const res = await api.get(`api/v1/pretraga-parcele-po-broju-parcele`, queryParams);
        const nelegalniRes = await api.get(`api/v1/pretraga-nelegalnih-objekata`, nelegalniQueryParams);

        setNelegalni({
            total: nelegalniRes?.data?.meta?.total,
            objectIds: nelegalniRes?.data?.data?.map((item: any) => item.objekatId),
        });

        if (res?.data.length === 0) {
            setData(null);
            return;
        }
        setData(res?.data);

        const u = {
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            email: user.email,
            theme: user.theme,
            brojPretraga: user.brojPretraga + 1,
        };

        dispatch(setUser(u));
    };

    const redirectToRGZ = () => {
        window.open(`https://portal.rgz.gov.rs/rgz-portal/map?cadmun=${koid}&parcelNumber=${brojParcele}`, "_blank");
    };

    const redirectToDRR = () => {
        window.open(`https://drr.geosrbija.rs/drr/map?cadmun=${koid}&parcelNumber=${brojParcele}`, "_blank");
    };

    const redirectToNelegalni = () => {
        window.open(
            //@ts-ignore
            `https://portal.rgz.gov.rs/rgz-portal/map?cadmun=${koid}&parcelNumber=${brojParcele}&objectId=${nelegalni.objectIds[0]}`,
            "_blank"
        );
    };

    return (
        <LayoutWraper sidebarOpen={sidebar}>
            <CardWrapper>
                <KatastarCard height={detrimendHeight}>
                    <CardContainer>
                        <CardHeader>
                            <CardTitle>ПРЕТРАГА ПО БРОЈУ ПАРЦЕЛЕ</CardTitle>
                        </CardHeader>
                        {isMobile ? (
                            <Column style={{ marginTop: "0px", justifyContent: "center", alignItems: "center" }}>
                                <KatakomSelect
                                    options={katastarskeOpstineOptions}
                                    value={koid}
                                    name="koId"
                                    size="m"
                                    label="Општина - Катастарска општина*"
                                    error={false}
                                    onChange={(option: SelectOption) => {
                                        const koId = option.value.split("_")[3];
                                        const brOpstine = option.value.split("_")[2];
                                        setKoid(koId);
                                        setMatBrOpstine(brOpstine);
                                    }}
                                    style={{ margin: "20px 0px 0px 0px" }}
                                />
                                <KatakomInput
                                    type={"text"}
                                    value={brojParcele}
                                    name={""}
                                    size="m"
                                    label="Број парцеле* (број/подброј)"
                                    error={false}
                                    onChange={(e) => {
                                        setBrojParcele(e.target.value);
                                    }}
                                    onEnter={fatchData}
                                    style={{ margin: "20px 0 0 0" }}
                                />
                                <KatakomButton
                                    style={{ marginTop: "30px", marginBottom: "30px" }}
                                    size={"xl"}
                                    label={"ПРЕТРАЖИ"}
                                    onClick={fatchData}
                                />
                            </Column>
                        ) : (
                            <RowWrapper style={{ marginTop: "20px" }}>
                                <KatakomSelect
                                    options={katastarskeOpstineOptions}
                                    value={koid}
                                    name="koId"
                                    size="m"
                                    label="Општина - Катастарска општина*"
                                    error={false}
                                    onChange={(option: SelectOption) => {
                                        const koId = option.value.split("_")[3];
                                        const brOpstine = option.value.split("_")[2];
                                        setKoid(koId);
                                        setMatBrOpstine(brOpstine);
                                    }}
                                />
                                <KatakomInput
                                    type={"text"}
                                    value={brojParcele}
                                    name={""}
                                    size="s"
                                    label="Број парцеле* (број/подброј)"
                                    error={false}
                                    onChange={(e) => {
                                        setBrojParcele(e.target.value);
                                    }}
                                    onEnter={fatchData}
                                />
                                <KatakomButton
                                    size={screenWidth < 1450 ? "s" : "m"}
                                    label={"ПРЕТРАЖИ"}
                                    onClick={fatchData}
                                    style={{ marginTop: "40px" }}
                                />
                            </RowWrapper>
                        )}

                        {data !== null && !data ? <DataNotFound>*Нема података за унету вредност</DataNotFound> : null}
                    </CardContainer>
                </KatastarCard>
            </CardWrapper>

            {data ? (
                <RenderKatastarData
                    data={data}
                    nelegalni={nelegalni}
                    redirectToRGZ={redirectToRGZ}
                    redirectToDRR={redirectToDRR}
                    redirectToNelegalni={redirectToNelegalni}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    koid={koid}
                    brOpstine={matBrOpstine}
                    brojParcele={brojParcele.split("/")[0]}
                    podbrojParcele={brojParcele.split("/")[1] || "0"}
                />
            ) : null}
        </LayoutWraper>
    );
};

export const DataNotFound = styled.div`
    display: flex;
    margin: 20px 50px 0px 30px;
    font-size: 15px;
    font-weight: 600;
`;

export default PretragaPoBrojuParaceleLayout;
