import { ReactNode, useEffect, useRef } from "react";
import styled from "styled-components";
import { themeColors } from "../../constants/constants";
import useMobileCheck from "../../hooks/useMobileChecker";

type ModalProps = {
    isOpen: boolean;
    children: ReactNode;
    setIsOpen: (value: boolean) => void;
};

const ModalContainer = styled.div`
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    box-shadow: 0px 4px 4px 0px #00000040;
`;

const ModalWrap = styled.div`
    display: flex;
    padding: 10px;
    max-height: 60vh;
    min-width: 250px;
    min-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 4px;
    background: ${themeColors.modalWrapBackground};
    padding-bottom: 20px;
`;

const ModalContent = styled.div`
    max-width: 100%;
    min-width: 100%;
    width: 100%;
`;

const KatakomModalNg = ({ isOpen, children, setIsOpen }: ModalProps) => {
    const modalContentRef = useRef<HTMLDivElement>(null);
    const isMobile = useMobileCheck();

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("keydown", handleKeyPress);
            document.body.style.overflow = "hidden";
            if (modalContentRef.current && !isMobile) {
                modalContentRef.current.focus();
            }
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
            document.body.style.overflow = "";
        };
    }, [isOpen, setIsOpen, isMobile]);

    return (
        <>
            {isOpen && (
                <ModalContainer>
                    <ModalWrap>
                        <ModalContent ref={modalContentRef} tabIndex={0} aria-modal="true">
                            {children}
                        </ModalContent>
                    </ModalWrap>
                </ModalContainer>
            )}
        </>
    );
};

export default KatakomModalNg;
