import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentAdvokati, LoadDocumentAdvokati } from "./CaseAdvokatiReducer";

export interface CaseDopunaState {
    dokumenta: DocumentAdvokati[];
    tempDokumenta: LoadDocumentAdvokati[];
    loading: boolean;
    error: string | null;
}

const initialState: CaseDopunaState = {
    dokumenta: [],
    tempDokumenta: [],
    loading: false,
    error: null,
};

export const caseDopunaSlice = createSlice({
    name: "caseDopuna",
    initialState,
    reducers: {
        resetState: () => initialState,
        addTempDocumentDopuna: (state, action: PayloadAction<Partial<LoadDocumentAdvokati>>) => {
            const newId = state.tempDokumenta.length === 0 ? 1 : state.tempDokumenta[state.tempDokumenta.length - 1].id + 1;
            //@ts-ignore
            state.tempDokumenta.push({ ...action.payload, id: newId });
        },
        deleteTempDocumentDopuna: (state, action: PayloadAction<{ vrstaIspraveId: string; name: string }>) => {
            const { vrstaIspraveId, name } = action.payload;
            const updatedDocuments = state.tempDokumenta.filter((doc) => {
                return !(doc.vrstaIspraveId === vrstaIspraveId && doc.name === name);
            });
            state.tempDokumenta = updatedDocuments;
        },
        addDocumentDopuna: (state, action: PayloadAction<DocumentAdvokati[]>) => {
            state.dokumenta = action.payload;
        },
    },
});

export const { resetState, addTempDocumentDopuna, deleteTempDocumentDopuna, addDocumentDopuna } = caseDopunaSlice.actions;
export default caseDopunaSlice.reducer;
