import KatakomInput from "../../../shared/KatakomInput";
import DatePicker from "../../../shared/DataPicker";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../store";
import { Container, Row } from "../../style";
import { updateBasicInfo } from "../../../../store/CaseTip2Reducer";

import useMobileCheck from "../../../../hooks/useMobileChecker";

const AddBasicInfoTip2 = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const basicInfo = useSelector((state: RootState) => state.caseTip2.basicInfo);
    const isMobile = useMobileCheck();

    return (
        <Container style={{ alignItems: isMobile ? "center" : "" }}>
            {isMobile ? (
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Row>
                        <DatePicker
                            value={basicInfo.datumPotvrde}
                            name="date"
                            label="Датум"
                            error={false}
                            disabled={false}
                            onChange={(e) => {
                                const selectedDate = e.target.value;
                                dispatch(updateBasicInfo({ datumPotvrde: selectedDate }));
                            }}
                        />{" "}
                    </Row>

                    <Row>
                        <KatakomInput
                            label="Број потврде"
                            type={"text"}
                            value={basicInfo.brojPotvrde}
                            name={"brojPotvrde"}
                            error={false}
                            onChange={(e) => {
                                dispatch(updateBasicInfo({ brojPotvrde: e.target.value }));
                            }}
                        />
                    </Row>
                    <Row>
                        <KatakomInput
                            label="Информације о локацији"
                            type={"text"}
                            value={basicInfo.informacijeOLokacijiBroj}
                            name={"informacijeOLokacijiBroj"}
                            error={false}
                            onChange={(e) => {
                                dispatch(updateBasicInfo({ informacijeOLokacijiBroj: e.target.value }));
                            }}
                        />
                    </Row>
                </div>
            ) : (
                <Row>
                    <DatePicker
                        value={basicInfo.datumPotvrde}
                        name="date"
                        label="Датум"
                        error={false}
                        disabled={false}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            dispatch(updateBasicInfo({ datumPotvrde: selectedDate }));
                        }}
                        style={{ margin: "0 1% 0 3%" }}
                    />{" "}
                    <KatakomInput
                        label="Број потврде"
                        type={"text"}
                        value={basicInfo.brojPotvrde}
                        name={"brojPotvrde"}
                        error={false}
                        onChange={(e) => {
                            dispatch(updateBasicInfo({ brojPotvrde: e.target.value }));
                        }}
                        style={{ margin: "0 1% 0 3%" }}
                    />
                    <KatakomInput
                        label="Информације о локацији"
                        type={"text"}
                        value={basicInfo.informacijeOLokacijiBroj}
                        name={"informacijeOLokacijiBroj"}
                        error={false}
                        onChange={(e) => {
                            dispatch(updateBasicInfo({ informacijeOLokacijiBroj: e.target.value }));
                        }}
                        style={{ margin: "0 1% 0 3%" }}
                    />
                </Row>
            )}
        </Container>
    );
};

export default AddBasicInfoTip2;
