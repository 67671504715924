import Axios, { InternalAxiosRequestConfig } from "axios";
import Cookies from "js-cookie";

const http = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

http.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const token = Cookies.get("accessToken");
    if (!token) return config;

    config.headers.Authorization = `${token}`;

    return config;
});

http.interceptors.response.use(
    (value) => {
        return Promise.resolve(value);
    },
    (error) => {
        const { isAxiosError = false, response = null } = error;

        // if (isAxiosError && response && response.status === 401) {
        //     window.location.href = "/login";
        //     return Promise.reject(error);
        // }
        // if (isAxiosError && response && response.status === 403) {
        //     window.location.href = "/login";
        //     return Promise.reject(error);
        // }
        return Promise.reject(error);
    }
);

let counter = 1;

http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // if (error.response.status >= 500 && counter < Number(process.env.REACT_APP_RETRY)) {
        //     counter++;
        //     return http.request(error.config);
        // }
        // counter = 1;
        return Promise.reject(error);
    }
);

export default http;
