import React, { useState } from "react";
import styled from "styled-components";
import KatakomButton from "../../shared/KatakomButton";
import KatakomToggleButton from "../../shared/KatakomToggleButton";
import api from "../../../services/api";
import { CreateRole, ROLE } from "../../../enums/role";
import KatakomModal from "../../shared/KatakomModal";
import { ErrorMessageModal } from "../../customComponent/ErrorMessageModal";
import { SuccessMessageModal } from "../../customComponent/SuccessMessageModal";
import { isPasswordValid } from "../../../utils/utils";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const ModalOverlay = styled.div<{ isOpen: boolean }>`
    display: ${(props) => (props.isOpen ? "block" : "none")};
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
`;

const ModalContent = styled.div`
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.span`
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    &:hover,
    &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;

const FormGroup = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const Label = styled.label`
    width: 170px;
`;

const Input = styled.input`
    flex-grow: 1;
    padding: 8px;
    margin-left: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    font-size: 14px;
    background: #eeeeee;
    border: none;
`;

const Select = styled.select`
    flex-grow: 1;
    padding: 8px;
    margin-left: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    font-size: 14px;
    background: #eeeeee;
    border: none;
`;

const CreateUserModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        email: "",
        first_name: "",
        last_name: "",
        type: ROLE.ADVOKAT,
        is_active: true,
        password: "",
        confirmPassword:"",
        office_id: "",
        mb: "1",
        is_2fa_enabled: false,
    });

    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [errorModal, setErrorModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        //@ts-ignore
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const checkIfLawyerExists = async () => {
        try {
            const res = await api.get(`api/v1/lawyers-check-if-exists?mb=${formData.mb}`);
            if (!res.data.isExists) {
                setErrorMessage("Адвокат не постоји у бази података.");
                setErrorModal(true);
                return false;
            }
            return true;
        } catch (e) {
            setErrorMessage("Грешка у верификацији адвоката.");
            setErrorModal(true);
            return false;
        }
    };

    const validatePassword = () => {
        if (!isPasswordValid(formData.password)) {
            setErrorMessage("Шифра мора да садржи најмање 8 карактера,једно велико слово, један број и један специјални карактер!");
            setErrorModal(true);
            return false;
        }
        if (formData.password !== formData.confirmPassword) {
            setErrorMessage("Шифре се не поклапају!");
            setErrorModal(true);
            return false;
        }
        return true;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validatePassword()) return;

        if (formData.type === ROLE.ADVOKAT) {
            const lawyerExists = await checkIfLawyerExists();
            if (!lawyerExists) return;
        }

        try {
            const resp = await api.post('register', {
                firstName: formData.first_name,
                lastName: formData.last_name,
                email: formData.email,
                password: formData.password,
                type: formData.type,
                mb: formData.mb
            });  
            setSuccessModal(true);
            onClose();
        } catch (error) {
            setErrorMessage("Грешка приликом регистрације корисника.");
            setErrorModal(true);
        }
    };

    return (
        <>
            <KatakomModal isOpen={errorModal} setIsOpen={setErrorModal}>
                <ErrorMessageModal closeModal={() => setErrorModal(false)} message={errorMessage} />
            </KatakomModal>
            <KatakomModal isOpen={successModal} setIsOpen={setSuccessModal}>
                <SuccessMessageModal closeModal={() => setSuccessModal(false)} message="Корисник успешно креиран!" />
            </KatakomModal>

            <ModalOverlay isOpen={isOpen}>
                <ModalContent>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                    <h2>Нови Корисник</h2>
                        <FormGroup>
                            <Label htmlFor="email">И-мејл</Label>
                            <Input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="first_name">Име</Label>
                            <Input type="text" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="last_name">Презиме</Label>
                            <Input type="text" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                        <Label htmlFor="password">Password</Label>
                        <Input type="password" id="password" name="password" value={formData.password} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="confirmPassword">Confirm Password</Label>
                        <Input type="password" id="confirmPassword" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
                    </FormGroup>
                        <FormGroup>
                            <Label htmlFor="type">Тип</Label>
                            <Select id="type" name="type" value={formData.type} onChange={handleChange}>
                                {Object.values(CreateRole).map((role) => (
                                    <option key={role} value={role}>
                                        {role}
                                    </option>
                                ))}
                            </Select>
                        </FormGroup>
                        {formData.type === ROLE.ADVOKAT ? (
                             <FormGroup>
                             <Label htmlFor="mb">Матични број</Label>
                             <Input type="text" id="mb" name="mb" value={formData.mb} onChange={handleChange} />
                         </FormGroup>
                        ) : null}
                       
                        <FormGroup>
                            <Label htmlFor="is_active">Активан</Label>
                            <KatakomToggleButton
                                toggled={formData.is_active}
                                onClick={() => setFormData({ ...formData, is_active: !formData.is_active })} label={""}                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="is_2fa_enabled">2FA</Label>
                            <KatakomToggleButton
                                toggled={formData.is_2fa_enabled}
                                onClick={() => setFormData({ ...formData, is_2fa_enabled: !formData.is_2fa_enabled })} label={""}                            />
                        </FormGroup>
                        <KatakomButton label="Креирај" type="submit" onClick={handleSubmit} />
                </ModalContent>
            </ModalOverlay>
        </>
    );
};

export default CreateUserModal;
