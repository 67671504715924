import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import KatakomButton from "../../shared/KatakomButton";
import KatakomSelect, { SelectOption } from "../../shared/KatakomSelect";
import api from "../../../services/api";

interface UserStatisticsModalProps {
    isOpen: boolean;
    onClose: () => void;
    userId: number;
}

const ModalOverlay = styled.div<{ isOpen: boolean }>`
    display: ${(props) => (props.isOpen ? "block" : "none")};
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
`;

const ModalContent = styled.div`
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.span`
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    &:hover,
    &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;

const Title = styled.h2`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FormGroup = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const Label = styled.label`
    width: 170px;
`;

const UserStatisticsModal: React.FC<UserStatisticsModalProps> = ({ isOpen, onClose, userId }) => {
    const [startMonth, setStartMonth] = useState(moment().month() + 1);
    const [endMonth, setEndMonth] = useState(moment().month() + 1);
    const [year, setYear] = useState(moment().year());
    const [statistics, setStatistics] = useState<any>(null);

    useEffect(() => {
        if (isOpen) {
            fetchStatistics(startMonth, endMonth, year);
        }
    }, [isOpen, startMonth, endMonth, year]);

    const fetchStatistics = async (selectedStartMonth: number, selectedEndMonth: number, selectedYear: number) => {
        try {
            const apiUrl = `/api/v1/get-user-search-statistics?startMonth=${selectedStartMonth}&endMonth=${selectedEndMonth}&userId=${userId}`;
            const res = await api.get(apiUrl);
            setStatistics(res.data);
        } catch (error) {
            console.error("Error fetching user search statistics:", error);
        }
    };
    const monthOptions: SelectOption[] = [
        { value: "1", label: "Јануар" },
        { value: "2", label: "Фебруар" },
        { value: "3", label: "Март" },
        { value: "4", label: "Април" },
        { value: "5", label: "Мај" },
        { value: "6", label: "Јун" },
        { value: "7", label: "Јул" },
        { value: "8", label: "Август" },
        { value: "9", label: "Септембар" },
        { value: "10", label: "Октобар" },
        { value: "11", label: "Новембар" },
        { value: "12", label: "Децембар" },
    ];

    const handleStartMonthChange = (option: SelectOption) => {
        setStartMonth(parseInt(option.value));
    };

    const handleEndMonthChange = (option: SelectOption) => {
        setEndMonth(parseInt(option.value));
    };

    return (
        <ModalOverlay isOpen={isOpen}>
            <ModalContent>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                <Title>Статистика претрага</Title>
                <FormGroup>
                    <KatakomSelect
                        label="Од"
                        options={monthOptions}
                        value={startMonth.toString()}
                        name="startMonth"
                        error={false}
                        onChange={handleStartMonthChange}
                    />
                </FormGroup>
                <FormGroup>
                    <KatakomSelect
                        label="До"
                        options={monthOptions}
                        value={endMonth.toString()}
                        name="endMonth"
                        error={false}
                        onChange={handleEndMonthChange}
                    />
                </FormGroup>
                <FormGroup>
                    {statistics ? (
                        <div>
                            <p>Број претрага за изабран период: {statistics.range[0]?.range_counter || 0}</p>
                            <p>Број годишњих претрага: {statistics.yearly[0]?.yearly_counter || 0}</p>
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}
                </FormGroup>
                <KatakomButton label="Close" onClick={onClose} />
            </ModalContent>
        </ModalOverlay>
    );
};

export default UserStatisticsModal;
