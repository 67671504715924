import React from "react";
import PageLayout from "../../../shared/PageLayout";
import PretragaPoMaticnomLayout from "./PretragaPoMaticnomLayout";

const PretragaPoMaticnom: React.FC = () => {
    return (
        <PageLayout>
            <PretragaPoMaticnomLayout />
        </PageLayout>
    );
};

export default PretragaPoMaticnom;
