import React from "react";
import PageLayout from "../../../../shared/PageLayout";
import PreviewLayout from "./PreviewLayout";

const Preview: React.FC = () => {
    return (
        <PageLayout>
            <PreviewLayout />
        </PageLayout>
    );
};

export default Preview;
