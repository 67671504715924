import React from "react";
import PageLayout from "../../shared/PageLayout";
import UserManagementLayout from "./userManagementLayout";

const UserManagement: React.FC = () => {
    return (
        <PageLayout>
            <UserManagementLayout />
        </PageLayout>
    );
};

export default UserManagement;
