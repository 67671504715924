export enum ROLE {
    ADMIN = "admin",
    SUPERADMIN = "superadmin",
    ADVOKAT = "advokat",
    MGSI = "mgsi",
    MOBILE = "mobile",
    INFOCENTAR = "infocentar",
    BANK = "bank",
    ADMIN_BANK = "admin_bank",
    GEODET = "geodet",
    NEKRETNINE = "nekretnine",
    IZVRSITELJ = "izvrsitelj",
    OPSTINA = "opstina",
}

export enum CreateRole {
    ADVOKAT = "advokat",
    MOBILE = "mobile",
    INFOCENTAR = "infocentar",
    BANK = "bank",
    ADMIN_BANK = "admin_bank",
    GEODET = "geodet",
    NEKRETNINE = "nekretnine",
    IZVRSITELJ = "izvrsitelj",
    OPSTINA = "opstina",
}
